<form [formGroup]="form">
  <h1 mat-dialog-title fxLayoutAlign="center">{{ "login.lost_credentials" | transloco }}</h1>

  <div mat-dialog-content [ngSwitch]="status">
    <content *ngSwitchCase="EidosResetCredentialsStatus.Entered">
      <label class="credentials-reset-dialog-message">{{ "login.reset_credentials.message" | transloco }}</label>
      <mat-form-field appearance="outline" style="width: 100%"> 
        <mat-label>{{ "login.reset_credentials.tooltip" | transloco }}</mat-label>
        <input type="text" formControlName="username" matInput required>
        <mat-error *ngIf="form.controls.username.invalid">{{ "login.reset_credentials.tooltip" | transloco }}</mat-error>
      </mat-form-field>
    </content>
    <content *ngSwitchCase="EidosResetCredentialsStatus.Requested">
      <div class="reset-credentials-icon">
        <mat-spinner class="request-spinner" style="margin:0 auto;" [diameter]="60"></mat-spinner>
      </div>
      <p class="reset-credentials-message">{{ "login.reset_credentials.submitting" | transloco }}</p>
    </content>
    <content *ngSwitchCase="EidosResetCredentialsStatus.Ok">
      <div class="reset-credentials-icon">
        <eidos-icon [iconConfiguration]="okIcon"></eidos-icon>
      </div>
      <div class="reset-credentials-message">{{ "login.reset_credentials.requested" | transloco }}</div>
    </content>
    <content *ngSwitchCase="EidosResetCredentialsStatus.Error">
      <div class="reset-credentials-icon">
        <eidos-icon [iconConfiguration]="clearIcon"></eidos-icon>
      </div>
      <p class="reset-credentials-message">{{ "login.reset_credentials.failed" | transloco }}</p>
    </content>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end">
    <button mat-button type="button" (click)="onCancelClick()">{{ "cancel" | transloco }}</button>
    <button *ngIf="status === EidosResetCredentialsStatus.Ok" mat-flat-button type="submit" color="primary" (click)="onCancelClick()">{{ "close" | transloco }}</button>
    <button *ngIf="status === EidosResetCredentialsStatus.Entered" mat-flat-button type="submit" color="primary" (click)="onSubmit()" [disabled]="form.invalid">{{ "submit" | transloco }}</button>
    <button *ngIf="status === EidosResetCredentialsStatus.Error" mat-flat-button type="submit" color="primary" (click)="onRetry()">{{ "retry" | transloco }}</button>
  </div>
</form>
