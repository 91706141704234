<div *ngIf="this.data.isValid()" class="core-register-account">
  <!-- h1>REGISTRATION FORM</h1>

  <div class="container">
    <div class="row row-cols-5 justify-content-md-center">
      <div class="col" *ngFor="let f of data.fields">
        <label>{{f.caption}}</label><br />
        <input type="text" name="f.name" [(ngModel)]="f.value">
      </div>
    </div> -->
  <dx-tab-panel
    [ngClass]="{ 'only-one-panel': tabs.length === 1 }"
    [dataSource]="tabs"
    [animationEnabled]="true"
    [swipeEnabled]="true"
    [(selectedIndex)]="selectedTabIndex"
  >
    <div *dxTemplate="let tab of 'title'">
      <span>{{ tab.caption ?? '' }}</span>
    </div>
    <div *dxTemplate="let tab of 'item'">
      <eidos-light-form
        [fields]="formFields[tab.id]"
        [scrollable]="true"
        submitButtonText="SUBMIT"
        submitButtonClass="ms-2"
        submitButtonIcon=""
        createButtonClass="d-none"
        colsClasses="row-cols-5"
        title="Registration Form"
        [(formValues)]="formValues"
        [reverseButtons]="true"
        (onFormSubmit)="handleFormSubmit()"
      >
      </eidos-light-form>
    </div>
  </dx-tab-panel>

    <!--
  name= '';
  isKey = false;
  insertVisibility = false;
  updateVisibility = false;
  insertReadOnly = false;
  updateReadOnly = false;
  variant = '';
  decimalDigit = 0;
  isMoney = false;
  isImage = false;
  fileName = '';
  numberMin = 0;
  numberMax = 0;
  isRequired = false;
  order = 0;
  isHidden = false;
  defaultValue = '';
  htmlTemplate = '';
  drawingWidth = 0;
  drawingHeight = 0;
  caption = '';

  <ng-container *ngIf="useTabs(); else noTabs">
    <ng-container *ngFor="let t of data.formStruct.tabs">
      <div>CAP {{t.caption}}</div>
    </ng-container>
  </ng-container>

  <ng-template #noTabs>
    <ng-container *ngFor="let t of data.formStruct.tabs">
      <div>{{t.caption}}</div>
    </ng-container>
  </ng-template>
  -->
    <!-- <button (click)="onSubmit()">SUBMIT</button> -->
</div>
