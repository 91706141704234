import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IEidosGridButtonConfiguration } from '@common/models/eidos-grid.models';
import { EidosBaseComponent } from '../../eidos-base.component';

@Component({
  selector: 'eidos-grid-button-cell-template',
  templateUrl: './eidos-grid-button-cell-template.component.html',
  styleUrls: ['./eidos-grid-button-cell-template.component.scss']
})
export class EidosGridButtonCellTemplateComponent extends EidosBaseComponent {
  /**
   * Button click event emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosGridButtonCellTemplateComponent
   */
  @Output() public onButtonClick: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Column data
   *
   * @type {*}
   * @memberof EidosGridButtonCellTemplateComponent
   */
  @Input() data: any;
  /**
   * Buttons configuration
   *
   * @type {IEidosGridButtonConfiguration[]}
   * @memberof EidosGridButtonCellTemplateComponent
   */
  @Input() buttonsConfig: IEidosGridButtonConfiguration[] = [];

  constructor() {
    super();
  }
  /**
   * Is visible computed value
   *
   * @param {(boolean | (() => boolean))} visibleProp
   * @return {*}
   * @memberof EidosGridButtonCellTemplateComponent
   */
  public isVisible(visibleProp: boolean | ((value?: any) => boolean)) {

    let visible = false;

    if (visibleProp !== undefined) {
      if (typeof visibleProp === "function") {
        visible = visibleProp(this.data);
      } else {
        visible = !!visibleProp;
      }
    }

    return visible;
  }
  /**
   * Is disabled computed value
   *
   * @param {(boolean | (() => boolean))} disabledProp
   * @return {*}
   * @memberof EidosGridButtonCellTemplateComponent
   */
  public isDisabled(disabledProp: boolean | ((value?: any) => boolean)) {

    let disabled = false;

    if (disabledProp !== undefined) {
      if (typeof disabledProp === "function") {
        disabled = disabledProp(this.data);
      } else {
        disabled = !!disabledProp;
      }
    }

    return disabled;
  }
  public btnEvaluateText(btn:IEidosGridButtonConfiguration) {
    if(!btn.textFunction) return btn.text;
    const o = this.data.data;
    if(btn.textFunction in o) return o[btn.textFunction]();
    return btn.text;
  }
  /**
   * Button click internal handler
   *
   * @param {IEidosGridButtonConfiguration} btn
   * @param {*} event
   * @memberof EidosGridButtonCellTemplateComponent
   */
  public _onButtonClick(event: any, btn: IEidosGridButtonConfiguration) {
    event.event.stopImmediatePropagation();

    let evn: any = {
      'btnName': btn.name,
      'event': this.data,
    };

    // If button has a click handler, execute it. Otherwise, emit the event.
    if (btn.onClick) {
      btn.onClick(this.data);
    } else {
      this.onButtonClick.emit(evn);
    }
  }
}
