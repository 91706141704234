// Modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { ErrorModule } from '@error/error.module';
import { EidosModule } from '@eidos/eidos.module';

// Guards
import { EidosExternalAppGuard } from '@common/guards/eidos-external-app.guard';
import { CoreDeveloperGuard } from '@app/core/guards/core-developer.guard';
import { EidosTokenGuard } from '@common/guards/eidos-token.guard';

// Components
import { EidosExternalAppComponent } from '@common/eidos-external-app/eidos-external-app.component';

// Pages
import { EidosMainPageComponent } from '@common/pages/eidos-main-page/eidos-main-page.component';
import { EidosLoginTokenComponent } from '@common/pages/eidos-login-token/eidos-login-token.component';
import { EidosLoginComponent } from '@common/pages/eidos-login/eidos-login.component';
import { EidosHomePageComponent } from '@common/pages/eidos-home-page/eidos-home-page.component';
import { EidosConfigurationViewerComponent } from '@common/pages/eidos-configuration-viewer/eidos-configuration-viewer.component';
import { EidosResetCredentialsComponent } from '@common/pages/eidos-reset-credentials/eidos-reset-credentials.component';

// Paths
import { CoreModuleName, CoreRoute, EidosRoute } from '@common/models/core-constant.model';
import { CoreRegisterAccountComponent } from './core/components/core-register-account/core-register-account.component';
import { CoreProductionGuard } from './core/guards/core-production.guard';
import { CoreTokenGuard } from './core/guards/core-token.guard';

function externalAppParameters(url: UrlSegment[]) {
  return url.length > 1 && url[0].path.toLowerCase() === 'app' ? ({ consumed: url }) : null;
}

function eidosRewriter(url: UrlSegment[]) {
  return url.length > 1 && url[0].path === "eidos" && Object.values(EidosRoute).map(route => route.toString()).indexOf(url[1].path) === -1 ? { consumed: url } : null;
}

const routes: Routes = [
  {
    // Redirect to error
    path: 'error',
    loadChildren: () => ErrorModule,
    pathMatch: 'prefix'
  },
  {
    // Redirect to home
    path: '',
    redirectTo: CoreModuleName.Reservation,
    pathMatch: 'full'
  },
  {
    path: '',
    component: EidosMainPageComponent,
    canActivate: [CoreTokenGuard],
    pathMatch: 'prefix',
    children: [
      {
        matcher: eidosRewriter,
        redirectTo: CoreRoute.Home,
        pathMatch: 'full'
      },
      {
        path: CoreRoute.Token+'/:token',
        component: EidosLoginTokenComponent,
        pathMatch: 'prefix'
      },
      {
        path: CoreRoute.Login,
        component: EidosLoginComponent,
        pathMatch: 'full'
      },
      {
        path: CoreRoute.Home,
        component: EidosHomePageComponent,
        canActivate: [EidosTokenGuard],
        pathMatch: 'full'
      },
      {
        path: CoreRoute.Readme,
        component: EidosConfigurationViewerComponent,
        canActivate: [CoreDeveloperGuard],
        pathMatch: 'full'
      },
      {
        path: CoreRoute.Reset+'/:resetId',
        component: EidosResetCredentialsComponent,
        pathMatch: 'prefix'
      },
      {
        matcher: externalAppParameters,
        component: EidosExternalAppComponent,
        canActivate: [EidosExternalAppGuard],
        pathMatch: 'prefix'
      },
      {
        path: CoreModuleName.Eidos,
        canActivate: [EidosTokenGuard],
        loadChildren: () => EidosModule,
      },
      {
        path: CoreModuleName.Reservation,
        loadChildren: () => import(/* webpackChunkName: "reservation" */ '@reservation/reservation.module')
          .then(
            m => m.ReservationModule,
            () => ErrorModule),
      },
      {
        path: CoreModuleName.ReservationSetup,
        loadChildren: () => import(/* webpackChunkName: "res-setup" */ '@res-setup/res-setup.module')
          .then(
            m => m.ResSetupModule,
            () => ErrorModule),
      },
      {
        path: 'test',
        canActivate: [CoreProductionGuard],
        loadChildren: () => import(/* webpackChunkName: "test" */ '@test/test.module')
          .then(
            m => m.TestModule,
            () => ErrorModule),
      }
    ],
  },
  {
    // Account registration
    path: 'account/register',
    component: CoreRegisterAccountComponent,
    pathMatch: 'full'
  },
  {
    // Redirect to 404
    path: '**',
    redirectTo: CoreRoute.NotFound,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes
    // ,{ enableTracing: true } // <-- debugging purposes only
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
