import { BehaviorSubject, combineLatest, debounceTime, filter, lastValueFrom } from "rxjs";
import { ReservationApiService } from "../services/reservation-api.service";
import { ReservationService } from "../services/reservation.service";
import { IReservationBkgPromoItemManagePramaters, IReservationPromoApplyPramaters, ResPromoApply, ResPromoSelectSection } from "./res-promo.model";
import _ from "lodash";
import { EidosConfigService } from "@app/core/config/eidos-config.service";

export class ResPromoUtility {
    promos: ResPromoApply[] = [];
    promosChanged = new BehaviorSubject(false);

    isProductionEnvironment = false;
    constructor(public reservationService: ReservationService, public reservationApiService: ReservationApiService, eidosConfigService:EidosConfigService ) {

      this.isProductionEnvironment = !!eidosConfigService.currentConfig.getValue().production

      combineLatest([
            reservationService.currentCurrency.asObservable(),
            reservationService.currentPriceType.asObservable(),
            reservationService.preBookingUtility.productIDsBS.asObservable(),
            reservationService.currentCruiseSuiteCategory.asObservable(),
            reservationService.currentRequestedPromoIds.asObservable(),
        ]).pipe(
            filter(([currency, priceType, voyagesIDs]) =>
                currency !== undefined
                && priceType !== undefined
                &&  voyagesIDs.length !== 0
            ),
            debounceTime(1000)
        ).subscribe({
            next: async ([currency, priceType, voyageIDs, _ ,requestedPromoIds]) => {
                const currencyParam = currency;
                const priceTypeParam = priceType;
                const voyageIDsParam = voyageIDs.toString();
                this.getApplyPromo(currencyParam!, priceTypeParam!.PriceTypeID, voyageIDsParam, requestedPromoIds);
            }
        })

    }

    getApplyPromoPromise(currency: string, pricesTypeId: number, voyageIds:string, requstedPromoIds:number[]):Promise<ResPromoApply[]> {
      const params: IReservationPromoApplyPramaters = {
        Section: ResPromoSelectSection.Inventory,
        CurrencyCod: currency,
        PriceTypeID: pricesTypeId,
        VoyageID: voyageIds,
        PromoID: requstedPromoIds.join(',')
      }
      return lastValueFrom(this.reservationService.getApplyPromo(params))
    }
    getApplyPromo(currency: string, pricesTypeId: number, voyageIds:string, requstedPromoIds:number[]) {
        const params: IReservationPromoApplyPramaters = {
            Section: ResPromoSelectSection.Inventory,
            CurrencyCod: currency,
            PriceTypeID: pricesTypeId,
            VoyageID: voyageIds,
            PromoID: requstedPromoIds.join(',')
        }
        this.reservationService.getApplyPromo(params).subscribe({
            next: getApplyPromoResponse => {
                const changed = !getApplyPromoResponse.filter(p=>p.isSelected).every(p=> !!this.promos.filter(p=>p.isPreSelect).find(p2=> p.promoID === p2.promoID))
                if(changed) {
                  this.promosChanged.next(true);
                }
                this.promos = getApplyPromoResponse;
            }
        });
    }
    getBookingPromo(bkgID: number) {
      const params: IReservationPromoApplyPramaters = {
          Section: ResPromoSelectSection.Book,
          BkgID: bkgID,
      }
      this.reservationService.getApplyPromo(params).subscribe({
          next: getApplyPromoResponse => {
            const changed = !getApplyPromoResponse.filter(p=>p.isSelected && p.promoTypeID!==2).every(p=> !!this.promos.filter(p=>p.isPreSelect).find(p2=> p.promoID === p2.promoID))
            if(changed) this.promosChanged.next(true);
            this.promos = getApplyPromoResponse;
          }
      });
  }

  getSelectedPromo() {
    return this.promos.filter(p=>p.promoTypeID!==2 && p.isSelected);
  }
  applyChanges(bkgID:number):Promise<boolean> {

    return new Promise<boolean>((resolve)=>{
      const params:IReservationBkgPromoItemManagePramaters={BkgID:bkgID,Promo:[]};
      this.reservationService.promoUtility
        .promos
        .filter(p=>p.isSelected!==p.isPreSelect) //Only changed
        .forEach(p=>{
          params.Promo.push({
            PromoID:p.promoID,
            Remove: !p.isSelected && p.isPreSelect ? 'Y' : 'N' //IS REMOVE?
          })
      })

      this.reservationApiService.bkgPromoItemsManage(params).subscribe({
          next: response => {
              resolve(response)
          }
      });

    });
  }
}
