import { Component, OnInit } from '@angular/core';

import { EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';

@Component({
  selector: 'error-page-not-found',
  templateUrl: './error-page-not-found.component.html',
  styleUrls: ['./error-page-not-found.component.scss']
})
export class ErrorPageNotFoundComponent implements OnInit {

  constructor(
    private logService: EidosLogService
  ) { }

  ngOnInit(): void {
    this.logService.logDebug(EidosLogSeverity.Log, 'Hello ErrorPageNotFoundComponent!');
  }
}
