import { TemplateRef } from '@angular/core';
import { GridColumnConfig } from '@common/models/core-entity-search.models';
import { DateTime } from 'luxon';

import { EidosFilterConfiguration } from '@common/models/eidos-filters.models';
import { IEidosGridConfiguration, IEidosGridSearchPanelConfiguration } from '@common/models/eidos-grid.models';
import { ICoreFormCommandsSettings } from './eidos-form.models';
import { IEidosInputDateConfig, IEidosInputNumberPopupOption } from './eidos-inputs.models';

/**
 * Represents the CoreSearch standard paging configuration
 *
 * @export
 * @class CoreSearchStandardPagingConfiguration
 */
export class CoreSearchStandardPagingConfiguration {
  /**
   * Enables the paging
   *
   * @type {boolean}
   * @memberof CoreSearchStandardPagingConfiguration
   */
  enabled: boolean;
  /**
   * Specifies the page size
   *
   * @type {number}
   * @memberof CoreSearchStandardPagingConfiguration
   */
  pageSize: number;

  constructor(enabled: boolean = false, pageSize: number = 10) {
    this.enabled = enabled;
    this.pageSize = pageSize;
  }
}

/**
 * Generic JSON configuration parser. It includes a method to map the parsed JSON in a TypeScript interface
 *
 * @export
 * @abstract
 * @class JSONToConfigurationParser
 * @template JC Json Configuration Interface
 * @template MC Mapped configuration interface
 */
export abstract class JSONToConfigurationParser<JC, MC> {
  public abstract mapConfigToModel(value: JC): MC;
}

/**
 * Search configuration
 */
export type ICoreSearchConfiguration =
  | ICoreSearchTabConfiguration
  | Array<ICoreSearchTabConfiguration>;

/**
 * Search tab base configuration
 *
 * @export
 * @interface ICoreSearchTabBaseConfiguration
 */
export interface ICoreSearchTabBaseConfiguration {
  /**
   * Tab identifier
   *
   * @type {string}
   * @memberof ICoreSearchTabBaseConfiguration
   */
  identifier?: string;
  /**
   * Tab title
   *
   * @type {string}
   * @memberof ICoreSearchTabBaseConfiguration
   */
  title?: string;
}

/**
 * Search tab configuration
 *
 * @export
 * @interface ICoreSearchTabConfiguration
 */
export interface ICoreSearchTabConfiguration
  extends ICoreSearchTabBaseConfiguration {
  /**
   * Tab icon code
   *
   * @type {string}
   * @memberof ICoreSearchTabConfiguration
   */
  icon?: string;
  /**
   * Tab search handler API
   *
   * @type {string}
   * @memberof ICoreSearchTabConfiguration
   */
  searchAPI: string;
  /**
   * Move more filters to sidebar flag
   *
   * @type {boolean}
   * @memberof ICoreSearchTabConfiguration
   */
  isSidebarSearch?: boolean;
  searchHandler?: (value: any) => void;
  /**
   * Tab filters form config
   *
   * @type {Array<EidosFilterConfiguration>}
   * @memberof ICoreSearchTabConfiguration
   */
  filtersConfig: Array<EidosFilterConfiguration>;
  /**
   * Tab search results config
   *
   * @type {Array<CoreSearchResultsViewConfig>}
   * @memberof ICoreSearchTabConfiguration
   */
  resultsConfig: Array<CoreSearchResultsViewConfig>;
  /**
   * Is tab a generic search flag
   *
   * @type {boolean}
   * @memberof ICoreSearchTabConfiguration
   */
  isGenericTab?: boolean;
  /**
   * Map a entity to a tab identifier
   * (To values just in a generic tab)
   *
   * @memberof ICoreSearchTabConfiguration
   */
  resultSpecificMap?: (result: any) => string | undefined;
  /**
   * Map a entity to its id
   *
   * @memberof ICoreSearchTabConfiguration
   */
  resultIdMap?: (result: any) => string | undefined;

  // Campi aggiunti
  // Servono x identificare la Riga della Setup.GridView
  EntityType?: string;
  //GVName?: string;
  GridViewName?: string;
  //GVLabel?: string;
  Label?: string;

  button?: any;

  commandsSettings?: ICoreFormCommandsSettings;
}

export type CoreSearchResultsViewType =
  | 'generic'
  | 'tiles'
  | 'grid'
  | 'map'
  | 'rows';

export type CoreSearchResultsViewConfig =
  | ICoreSearchResultsViewConfig
  | ICoreSearchResultsRowTilesViewConfig
  | ICoreSearchResultsGridViewConfig;

/**
 * Configuration of search results view
 *
 * @interface ICoreSearchResultsViewConfig
 */
interface ICoreSearchResultsViewConfig {
  /**
   * View id
   *
   * @type {CoreSearchResultsViewType}
   * @memberof ICoreSearchResultsViewConfig
   */
  id: CoreSearchResultsViewType;
  /**
   * View icon code
   *
   * @type {string}
   * @memberof ICoreSearchResultsViewConfig
   */
  icon: string;
  /**
   * Custom template HTML code
   *
   * @type {string}
   * @memberof ICoreSearchResultsViewConfig
   */
  customTemplate?: string;
  /**
   * Custom template of the view
   *
   * @type {TemplateRef<any>}
   * @memberof ICoreSearchResultsViewTemplatedConfig
   */
  template?: TemplateRef<any>;
}
/**
 * Configuration of search results view in row tiles
 *
 * @export
 * @interface ICoreSearchResultsRowTilesViewConfig
 * @extends {ICoreSearchResultsViewTemplatedConfig}
 */
export interface ICoreSearchResultsRowTilesViewConfig
  extends ICoreSearchResultsViewConfig {
  /**
   * Attributes of the entity to be searched
   *
   * @type {Array<string>}
   * @memberof ICoreSearchResultsRowTilesViewConfig
   */
  searchExpr?: Array<string>;
  /**
   * Results search mode
   *
   * @type {('contains' | 'startswith' | 'equals')}
   * @memberof ICoreSearchResultsRowTilesViewConfig
   */
  searchMode?: 'contains' | 'startswith' | 'equals';
}

/**
 * Configuration of search results view in grid
 *
 * @export
 * @interface ICoreSearchResultsGridViewConfig
 * @extends {ICoreSearchResultsViewConfig}
 */
export interface ICoreSearchResultsGridViewConfig
  extends ICoreSearchResultsViewConfig {
  /**
   * Grid view configuration
   *
   * @type {IEidosGridConfiguration}
   * @memberof ICoreSearchResultsGridViewConfig
   */
  config: IEidosGridConfiguration;
  collapseRowsAfterRowClick?: boolean;
}

export class SearchElementsConfigurationParser {

  public static mapConfigToNumberPopupElements(
    elementsConfig: Array<any>
  ): Array<IEidosInputNumberPopupOption> {
    return elementsConfig.map((element) => {
      let mappedConfig: IEidosInputNumberPopupOption = {
        property: element.property,
        min: element.min,
        max: element.max,
        default: element.default,
        label: SearchElementsConfigurationParser.mapConfigToLabel(
          element.label
        ),
      };

      return mappedConfig;
    });
  }

  public static mapConfigToDatePicker(
    filterConfig: any
  ): IEidosInputDateConfig {
    let mappedConfig: IEidosInputDateConfig = {
      name: filterConfig.property,
      type: 'date',
      hidden: () => !!filterConfig.hidden,
      displayFormat: filterConfig.displayFormat,
      pickerType: filterConfig.pickerType,
      dateValueType: filterConfig.type,
      layoutClasses: filterConfig.layoutClasses,
      formatDateToApi: !!filterConfig.formatDateToApi,
      placeholder: SearchElementsConfigurationParser.mapConfigToLabel(
        filterConfig.placeholder
      ),
    };

    if (filterConfig.disabledDates) {
      let testNow = filterConfig.disabledDates[0]; // TODO handle the array

      try {
        let date: Date;

        switch (testNow.value) {
          case 'today':
            date = new Date();
            break;
          default:
            date = DateTime.fromFormat(testNow.value, 'dd/MM/yyyy').toJSDate();
        }

        // Default: all dates are enabled
        let disabledFunction: (value: any) => boolean = () => false;

        switch (testNow.operator) {
          case '<':
            disabledFunction = (value: any) =>
              value.date.valueOf() < date.valueOf();
            break;
          case '<=':
            disabledFunction = (value: any) =>
              value.date.valueOf() <= date.valueOf();
            break;
          case '>':
            disabledFunction = (value: any) =>
              value.date.valueOf() > date.valueOf();
            break;
          case '>=':
            disabledFunction = (value: any) =>
              value.date.valueOf() >= date.valueOf();
            break;
          case '<>':
            disabledFunction = (value: any) =>
              value.date.valueOf() != date.valueOf();
            break;
          case '=':
            disabledFunction = (value: any) =>
              value.date.valueOf() === date.valueOf();
            break;
        }

        mappedConfig.disabledDates = disabledFunction;
      } catch (e: unknown) {
        throw e;
      }
    }

    return mappedConfig;
  }

  public static mapConfigToGridConfig(
    gridConfig: any
  ): IEidosGridConfiguration {
    let mappedConfig: IEidosGridConfiguration = {
      rowClickable: gridConfig.rowClickable,
      collapseRowsAfterRowClick: gridConfig.collapseRowsAfterRowClick,
      selectionMode: gridConfig.selectionMode,
      itemsKey: gridConfig.itemsKey,
      showFilterRow: gridConfig.showFilterRow,
      showHeaderFilter: gridConfig.showHeaderFilter,
      showColumnChooser: gridConfig.showColumnChooser,
      showColumnFixing: gridConfig.showColumnFixing,
      showBorders: gridConfig.showBorders,
      hideSelectorButton: gridConfig.hideSelectorButton,
      hidePager: gridConfig.hidePager,
      disablePaging: gridConfig.hidePager,
      rowAlternationEnabled: gridConfig.rowAlternationEnabled,
      pageSize: 25,

      allowedPageSizes: '',

      masterDetailEnabled: gridConfig.masterDetailEnabled ?? false,
      masterDetailTemplate: gridConfig.masterDetailTemplate ?? '',
      masterDetailGridKey: gridConfig.masterDetailGridKey,

      columns: gridConfig.columns.map((column: GridColumnConfig) => {
        return {
          visible: column.visible !== undefined ? column.visible : true,
          dataField: column.dataField,
          caption: column.caption.value,
          alignment: column.align,
          format: column.format,
          headerFilter: column.headerFilter,
          dataType: column.dataType,
          fieldType: column.fieldType,
          width: column.width,
          command: column.command,
          allowFiltering: column.disableFilter === undefined ? undefined : !column.disableFilter,
          buttonsConfig: !column.buttonConfig ? [] : [
            {
              text: column.buttonConfig.text,
              textFunction: column.buttonConfig.textFunction,
              stylingMode: column.buttonConfig.stylingMode,
              tooltip: column.buttonConfig.tooltip,
              icon: column.buttonConfig.icon,
              visible: column.buttonConfig.hidden === false ? false : true,
            }
          ]
        };
      })
    };

    if (gridConfig.masterDetailGridConfig) {
      mappedConfig.masterDetailGridConfig =
        SearchElementsConfigurationParser.mapConfigToGridConfig(
          gridConfig.masterDetailGridConfig
        );
    }

    if (gridConfig.searchPanel) {
      try {
        let mappedSearchPanel: IEidosGridSearchPanelConfiguration = {
          width: gridConfig.searchPanel.width,
          placeholder: SearchElementsConfigurationParser.mapConfigToLabel(
            gridConfig.searchPanel.placeHolder
          ),
        };

        mappedConfig.searchPanel = mappedSearchPanel;
      } catch (e: unknown) {
        throw e;
      }
    }

    return mappedConfig;
  }

  public static mapConfigToDimension(dimensionConfig: any): string {
    let parsedDimension: string = '';

    switch (dimensionConfig.dimensionType) {
      case '%':
      case 'px':
        parsedDimension =
          dimensionConfig.value.toString() + dimensionConfig.dimensionType;
    }
    return parsedDimension;
  }

  public static mapConfigToLabel(labelConfig: any): string {
    let parsedLabel: string = '';

    if (!!labelConfig) {
      switch (labelConfig.labelType) {
        case 'literal':
          parsedLabel = labelConfig.value;
          break;
        case 'multilanguage':
          // TODO translate the label
          parsedLabel = labelConfig.value;
          break;
      }
    }

    return parsedLabel;
  }
}
export interface ICoreSearchResultSelection {
  id: string | Array<string>;
  items: any | Array<any>;
  additionalProperties?: { [key: string]: string };
}
