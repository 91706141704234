<div class="eidos-iframe-container">

  <iframe
    #iframeMyBiz
    *ngIf="appMyBiz"
    [hidden]="!appMyBiz.isVisible"
    [id]="'iframe-'+appMyBiz.source"
    loading="eager"
    frameborder="0"
    scrolling="auto"
    class="eidos-iframe"
    [src]="appMyBiz.safeUrl"
    (load)="applicationOnAfterLoad(appMyBiz)">
  </iframe>

  <iframe
    #iframeEmbeddedUrl
    *ngIf="appEmbedded"
    [hidden]="!appEmbedded.isVisible"
    [id]="'iframe-'+appEmbedded.source"
    loading="eager"
    frameborder="0"
    class="eidos-iframe"
    [src]="appEmbedded.safeUrl"
    (load)="applicationOnAfterLoad(appEmbedded)">
  </iframe>

  <iframe #iframeExternalApp *ngFor="let app of apps"
    [hidden]="!app.isVisible"
    [id]="'iframe-'+app.source"
    loading="eager"
    frameborder="0"
    scrolling="auto"
    class="eidos-iframe"
    [src]="app.safeUrl"
    (load)="applicationDynamicOnAfterLoad(app)">
  </iframe>
</div>
