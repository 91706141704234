import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'eidos-debug-dialog',
  styleUrls: [
    'eidos-debug-dialog.component.scss'
  ],
  templateUrl: 'eidos-debug-dialog.component.html'
})

export class EidosDebugDialogComponent implements OnInit, OnDestroy {
  /**
   * Properties of object to display
   *
   * @memberof EidosDebugDialogComponent
   */
  public debugInfoEntries = Object.entries(this.debugInfo["data"] || {});
  /**
   * Stringified object
   *
   * @memberof EidosDebugDialogComponent
   */
  get debugInfoAsJSON() {
    return JSON.stringify(this.debugInfo["data"]);
  }

  constructor(
    private dialogRef: MatDialogRef<EidosDebugDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public debugInfo: any) {
  }

  ngOnInit(): void {
  }

  /**
   * Close the dialog
   *
   * @memberof EidosDebugDialogComponent
   */
  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }
}
