import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { EidosEntityService } from '@common/services/eidos-entity.service';
import { EidosBaseComponent } from '@common/components/eidos-base.component';
import { CoreDrawableComponentInterface } from '../core-drawable-component.interface';
import {
  CoreFormMode,
  ICoreFormCommandsSettings,
} from '@app/core/models/eidos-form.models';
import { EidosFilterComponent } from '../entities/filters/eidos-filter/eidos-filter.component';
import { EidosFilterFormCommandsComponent } from './eidos-filter-form-commands/eidos-filter-form-commands.component';

@Component({
  selector: 'eidos-filter-form',
  templateUrl: './eidos-filter-form.component.html',
  styleUrls: ['./eidos-filter-form.component.scss'],
})
export class EidosFilterFormComponent<S extends EidosEntityService>
  extends EidosBaseComponent
  implements AfterContentInit, AfterViewInit, CoreDrawableComponentInterface {
  /**
   * Horizontal align CSS class getter
   *
   * @readonly
   * @type {string}
   * @memberof EidosFilterFormComponent
   */
  public get horizontalAlignCSS(): string {
    return (
      'eidos-filter-form-wrapper-' + (this.formHorizontalAlign ?? 'center')
    );
  }
  /**
   * Horizontal align
   *
   * @type {('center' | 'left' | 'right')}
   * @memberof EidosFilterFormComponent
   */
  @Input()
  public formHorizontalAlign: 'center' | 'left' | 'right' = 'center';
  /**
   * Core form commands settings
   *
   * @type {ICoreFormCommandsSettings}
   * @memberof EidosFilterFormComponent
   */
  @Input()
  public commandsSettings?: ICoreFormCommandsSettings;
  /**
   * Form mode
   *
   * @type {CoreFormMode}
   * @memberof EidosFilterFormComponent
   */
  @Input() public mode: CoreFormMode = CoreFormMode.Search;
  /**
   * Form CSS class
   *
   * @type {string}
   * @memberof EidosFilterFormComponent
   */
  @Input()
  public cssClass: string = '';
  /**
   * Form ID class
   *
   * @type {string}
   * @memberof EidosFilterFormComponent
   */
  @Input()
  public cssId: string = '';
  /**
   * Current filters (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterFormComponent
   */
  protected _filters: any;
  @Input()
  public get filters(): any {
    return this._filters;
  }
  public set filters(value: any) {
    this._filters = value;
    this.filtersChange.emit(this._filters);
  }
  @Output()
  public filtersChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Filters configuration
   *
   * @type {Array<any>}
   * @memberof EidosFilterFormComponent
   */
  @Input() filtersConfiguration: Array<any> = [];
  /**
   * Gets always shown filters
   *
   * @type {Array<any>}
   * @memberof EidosFilterFormComponent
   */
  public alwaysShownFilters: Array<any> = [];
  /**
   * Gets filters to show on more
   *
   * @type {Array<any>}
   * @memberof EidosFilterFormComponent
   */
  public moreFilters: Array<any> = [];
  /**
   * Search event emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosFilterFormComponent
   */
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Enable multiselect event emitter
   *
   * @type {EventEmitter<boolean>}
   * @memberof EidosFilterFormComponent
   */
  @Output() onEnableMultiselect: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Submit multiselection event emitter
   *
   * @type {EventEmitter<void>}
   * @memberof EidosFilterFormComponent
   */
  @Output() onSubmitMultiselect: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Reset filters event emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosFilterFormComponent
   */
  @Output() onResetFilters: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Show more filters flag
   *
   * @type {boolean}
   * @memberof EidosFilterFormComponent
   */
  public showMoreFilters: boolean = false;
  /**
   * Has more filters flag
   *
   * @type {boolean}
   * @memberof EidosFilterFormComponent
   */
  public hasMoreFilters: boolean = false;
  /**
   * Dirty bit
   *
   * @type {boolean}
   * @memberof EidosFilterFormComponent
   */
  public isDirty: boolean = false;
  /**
   * Extended filter template
   *
   * @type {TemplateRef<any>}
   * @memberof EidosFilterFormComponent
   */
  @Input() public filterTemplate?: TemplateRef<any>;
  /**
   * Service to call search handlers
   *
   * @type {S}
   * @memberof EidosFilterFormComponent
   */
  @Input()
  public searchService?: S;
  /**
   *  One filter is 'search&Category' type
   *
   * @type {boolean}
   * @memberof EidosFilterFormComponent
   */
  public isSearchWithCategoryConfig: any = {};

  @Output()
  public onFilterCategoryBtnClick: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public filterCategoryBtnText: string = '';

  @Input()
  public filterCategoryBtnIcon: string = '';

  @Input()
  public BtnEntity: string = '';

  @ViewChild(EidosFilterComponent)
  public eidosFilter?: EidosFilterComponent;

  @ViewChild(EidosFilterFormCommandsComponent)
  public eidosFilterFormCommand?: EidosFilterFormCommandsComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.filtersConfiguration.forEach((filterConfig) => {
      if (
        this.filters &&
        this.filters[filterConfig.property] === undefined &&
        filterConfig.defaultValue != undefined
      ) {
        this.filters[filterConfig.property] = filterConfig.defaultValue;
      }
    });
    this.isSearchWithCategoryConfig = this.filtersConfiguration.find(
      (f) => f.type.toLowerCase() == 'search&category'
    );

    if (!this.commandsSettings) {
      this.commandsSettings = {
        mode: this.mode
      }
    }
  }

  ngAfterContentInit(): void {
    this.hasMoreFilters = this.filtersConfiguration.some(
      (config) => config.showOnMoreFilters
    );
    if (this.hasMoreFilters) {
      this.alwaysShownFilters = this.filtersConfiguration.filter(
        (filterConfig) => !filterConfig.showOnMoreFilters
      );
      this.moreFilters = this.filtersConfiguration.filter(
        (filterConfig) => !!filterConfig.showOnMoreFilters
      );
    } else {
      this.alwaysShownFilters = this.filtersConfiguration;
    }
  }

  ngAfterViewInit(): void {
    this.isDirty = false;
  }
  /**
   * Reset dirty bit end emit search event
   *
   * @memberof EidosFilterFormComponent
   */
  public search(event: any) {
    this.onSearch.emit(event);
    this.isDirty = false;
  }
  /**
   * Reset dirty bit end emit reset filters event
   *
   * @memberof EidosFilterFormComponent
   */
  public resetFilters() {
    this.onResetFilters.emit();
    this.filtersConfiguration
      .filter(fc => !fc.disabled)
      .map(fc => [fc.property, fc.otherProperty])
      .forEach(([prop1, prop2]) => {
        if (prop1 in this.filters) {
          this.filters[prop1] = undefined;
        }
        if (prop2 in this.filters) {
          this.filters[prop2] = undefined;
        }
      });
    this.filtersChange.emit(this.filters);
  }

  public keyDown(event: any, scope: any = this) {
    if (event.event.keyCode == 13) {
      event.component.blur();
      scope.search();
    }
  }

  public _buttonClick() {
    this.onFilterCategoryBtnClick.emit({
      BtnEntity: this.BtnEntity,
    });
  }
}
