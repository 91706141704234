import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
/**
 * Sanitize url arg to safely display it in DOM
 *
 * @export
 * @class SafeHtmlPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'safeHtml',
  standalone: true,
})
export class CoreSafeHtmlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer) {
  }
  /**
   * Transform and sanitize the URL
   *
   * @param {string} url
   * @return {*} 
   * @memberof SafeHtmlPipe
   */
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustHtml(url);
  }
}
