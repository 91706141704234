<dx-number-box [ngClass]="cssClass" [id]="cssId" [format]="!!format ? format : ''"
  placeholder="{{ placeholder | transloco }}" [max]="max!" [min]="min!" [disabled]="disabled"
  (onKeyDown)="keyDown($event)"
  [showSpinButtons]="showSpinButtons" (valueChange)="valueChanged($event)" [(value)]="value" [showClearButton]="showClearButton">
  <dx-validator [validationGroup]="validationGroupName">
    <dxi-validation-rule *ngIf="required"
      type="required" [message]="requiredMessage ?? 'Field is required'">
    </dxi-validation-rule>
  </dx-validator>
</dx-number-box>
