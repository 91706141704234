import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IEidosFiltersRegistry } from "@common/models/eidos-filters.models";
import { EidosFilterComponent } from "@common/components/entities/filters/eidos-filter/eidos-filter.component";
import { CorePopupListSelectorConfig } from "@app/core/components/core-popup-list-selector/core-popup-list-selector.component";

@Component({
  selector: 'eidos-filter-popup-list-selector',
  templateUrl: './eidos-filter-popup-list-selector.component.html',
  styleUrls: ['./eidos-filter-popup-list-selector.component.scss']
})
export class EidosFilterPopupListSelectorComponent extends EidosFilterComponent {

  protected popupListSelectorConfig?: CorePopupListSelectorConfig;
  /**
   * Current filter value (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterPopupListSelectorComponent
   */
  _value: any;
  /**
   * Filter value getter
   * Returns the value joined to the filter if required
   *
   * @readonly
   * @type {*}
   * @memberof EidosFilterPopupListSelectorComponent
   */
  public get value(): any {
    return this._value;
  }
  /**
   * Filter value setter
   * Split the value to pass to the input if required
   *
   * @memberof EidosFilterPopupListSelectorComponent
   */
  @Input() public set value(val: any) {
    this._value = val;
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<IEidosFiltersRegistry["popupListSelector"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["popupListSelector"]["valueType"]>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["popupListSelector"]["config"]}
   * @memberof EidosFilterPopupListSelectorComponent
   */
  @Input() public config: IEidosFiltersRegistry["popupListSelector"]["config"];
  /**
   * Checks if the input value must be joined to a string
   *
   * @readonly
   * @private
   * @type {boolean}
   * @memberof EidosFilterPopupListSelectorComponent
   */
  // private get _joinTheResult(): boolean {
  //   return (this.config?.joinResult && this.config?.maxItems !== 1) ?? false;
  // }

  constructor() {
    super();
  }

  ngOnInit() {
    this.popupListSelectorConfig = new CorePopupListSelectorConfig(this.config);
  }
}
