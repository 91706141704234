/**
 * Eidos reset credentials request status
 *
 * @export
 * @enum {number}
 */
export enum EidosResetCredentialsStatus {
    Entered,
    Requested,
    Ok,
    Error
}

/**
* Data of EidosResetCredentialsSnackbarComponent
*
* @interface EidosResetCredentialsSnackbarData
*/
export interface EidosResetCredentialsSnackbarData {
    /**
     * Response HTML from API reset request
     *
     * @type {string}
     * @memberof EidosResetCredentialsSnackbarData
     */
    response: string;
}

/**
 * Core confirmation dialog interface
 *
 * @export
 * @interface IEidosConfirmationDialogComponentConfig
 */
export interface IEidosConfirmationDialogComponentConfig {
    /**
     * Title to display in the dialog
     *
     * @type {string}
     * @memberof IEidosConfirmationDialogComponentConfig
     */
    title: string;
    /**
     * Message to display in the dialog
     *
     * @type {string}
     * @memberof IEidosConfirmationDialogComponentConfig
     */
    message: string;
    /**
     * Label (transloco) for confirm button
     *
     * @type {string}
     * @memberof IEidosConfirmationDialogComponentConfig
     */
    confirmationLabel: string;
    /**
     * Label (transloco) for cancel button
     *
     * @type {string}
     * @memberof IEidosConfirmationDialogComponentConfig
     */
    cancelLabel: string;
    /**
     * On confirm handler
     *
     * @memberof IEidosConfirmationDialogComponentConfig
     */
    onConfirm?: () => void;
    /**
     * On cancel handler
     *
     * @memberof IEidosConfirmationDialogComponentConfig
     */
    onCancel?: () => void;
}