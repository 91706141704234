<ng-template #corePopupListTemplate>
  <div class="core-popup-list-selector-modal-wrapper gap-1">
    <div class="core-popup-list-selector-modal-title">
      <h1>{{ config.title }}</h1>
      <span>{{ config.subtitle }}</span>
    </div>
    <h4 class="mt-2" *ngIf="config.internalDisabled">SELECTED ITEMS</h4>
    <div style="overflow-y: auto;">
      <dx-list *ngIf="!config.internalDisabled; else selectedItemsTemplate"
        [dataSource]="config.items"
        [disabled]="config.internalDisabled"
        [(selectedItems)]="internalValue"
        [displayExpr]="config.selectorDisplayField"
        [searchExpr]="config.searchField"
        [searchEnabled]="config.searchEnabled"
        [selectionMode]="config.selectionMode"
        [showSelectionControls]="config.showSelectionControls"
        (onSelectionChanged)="checkMaxItems()"
        [scrollingEnabled]="true"
        [selectAllMode]="config.selectionModeAll"
        [showScrollbar]="config.showScrollbar">
      </dx-list>
      <ng-template #selectedItemsTemplate>
        <div class="dx-item dx-list-item" *ngFor="let item of internalValue">
          <div class="dx-item-content dx-list-item-content">
            {{getLabelOfItem(item)}}
          </div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="!config.internalDisabled" style="margin-top: auto;">
      <div class="d-flex justify-content-between align-items-center mt-2 gap-2">
        <dx-button type="danger" stylingMode="contained"
          text="CANCEL" (onClick)="closePopupSelector()">
        </dx-button>
        <div class="d-flex justify-content-end">
          <div *ngIf="maxSelectedItemsOver">
            <label>Can't add another element because the max selectable items limit ({{ this.config.maxItems }}) has been reached</label>
          </div>
          <dx-button
            stylingMode="contained"
            type="success"
            [disabled]="maxSelectedItemsOver"
            text="OK"
            (onClick)="onSave($event)">
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="core-popup-list-selector-wrapper px-1 d-flex justify-content-between align-items-center">
  <div *ngIf="value.length == 0">
    <label>No selection</label>
  </div>
  <div *ngIf="value.length > 0 && (!config.showMoreLabelAfterTotItems || value.length <= config.showMoreLabelAfterTotItems)">
    <span class="m-1 px-1 core-popup-list-selector-item" *ngFor="let item of value; let index = index">
      <label>{{ getLabelOfItem(item) }}</label>
      <i class="fa fa-close mx-1" style="cursor: pointer;"
       *ngIf="!config.internalDisabled" (click)="removeItem(index); onSave();"></i>
    </span>
  </div>
  <div *ngIf="value.length > 0 && !!config.showMoreLabelAfterTotItems && value.length > config.showMoreLabelAfterTotItems">
    <span class="m-1 px-1 core-popup-list-selector-item">
      <label>{{ getLabelOfItem(value[0]) }} and {{value.length - 1 }} more</label>
    </span>
  </div>
  <dx-button
    stylingMode="contained" type="normal"
    text="..." (onClick)="openPopupSelector()">
  </dx-button>
</div>