import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { SafeBaseObject } from '@app/core/models/base-object.models';
import { DateTime } from 'luxon';

import { AppModule } from '@app/app.module';
import { EidosConfigService } from '@app/core/config/eidos-config.service';
import { CoreFormatService } from '@app/core/services/core-format.service';

import { lastValueFrom, map } from "rxjs";
import { IResPackageSetupRetailPrice } from '../components/res-package-setup/res-package-setup.service';
import { IReservationSuiteSelectionDialogComponentConfigSuites } from '../components/res-suite-selection-dialog/res-suite-selection-dialog.component';
import { ReservationApiService } from '../services/reservation-api.service';
import { ReservationService } from '../services/reservation.service';
import { IReservationApiParametersGuest } from './res-booking.model';
import { IReservationPackageMeal, IReservationPriceType } from './res-cached-data.models';
import { ReservationApiBoolean } from '../../res-common/models/res-constant.model';
import { IReservationApiCruiseItinerary, ReservationCruise, ReservationCruiseItinerary } from './res-cruise.models';
import { IReservationApiPackageEcmData, IReservationApiPackageInfo, IReservationApiPackageInfoParameters, ReservationPackageEcmData } from './res-package-detail.model';
import { ReservationCamp, ReservationCampPrices, ReservationCampSuiteCategory, ResGenericItineraryDay, ResGenericItineraryDayDetails, ResGenericItineraryDayDetailsMedia, ResProductService } from './res-product.models';
import { ResProductPromoPrice } from './res-promo.model';
import { IResPackageSelectParams } from './res-search.models';
import { ReservationAccomodation } from './res-service.models';
import { CoreModuleName } from '@app/core/models/core-constant.model';
import { ResVilla } from '@app/res-common/models/rescom.model';
import { IResInputCalendarRangeValue } from '@app/res-common/components/entities/inputs/res-input-calendar/res-input-calendar.component';

export enum ResProductType {
  EMPTY = 'EMPTY',
  CRUISE = 'CRU',
  PACKAGE = 'PKG',
  SANCTUARY = 'CMP',
  SERVICE = 'SRV',
  BASEPACKAGE = 'PKG',
  VILLA = 'VLL',
}
export class ResProductSuite extends SafeBaseObject {

  shipID = 0
  suiteID = 0
  suiteNo = ''
  suiteCategoryID = 0
  suiteCategoryCod = ''
  deckID = 0

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?: any) {
    if (!data) return
  }

}
export class ResProductEcm extends SafeBaseObject {
  productCod = ''
  dayID = 0
  productID = 0
  fldValue = ''
  entID = 0
  fldCod = ''
  context = ''

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?: any) {
    if (!data) return

  }
}
export class ResProductPrice extends SafeBaseObject {
  productCod = ''
  productID = 0
  travelStartDate = DateTime.invalid('empty')
  travelEndDate = DateTime.invalid('empty')
  sellingPrice = 0
  currency = ''
  onHoldQty = 0
  nightFrom = 0
  nightTo = 0

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?: any) {
    if (!data) return

  }
}
export class ResProductCompany extends SafeBaseObject {
  productCod = ''
  productID = 0
  companyID = 0
  companyName = ''

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?: any) {
    if (!data) return

  }
}
export class ResProductSuiteCategory extends SafeBaseObject {
  productCod = ''
  productID = 0
  categoryCod = ''
  category = ''

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?: any) {
    if (!data) return

  }
}

export class ResSearchProduct extends SafeBaseObject {
  num = 0
  product: ResProduct[] = []
  typeID = 0
  typeName = ''
  order = 0
  collapsed = false

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?: any) {
    if (!data) return
    const all:ResProduct[] = data.Product.map((p: any) => new ResProduct(p))
    const main = all.filter(p=>p.mainPackageID===0 || !all.find(a=>a.productID===p.mainPackageID))
    main.forEach(p=>{
      p.children = all.filter(c=>c.mainPackageID === p.productID)
      if(p.children.length>0) {
        console.log(p.children)
      }
    })
    if (data.Product) this.product = main
  }
}

export class ResSearchFilterFlags extends SafeBaseObject {
  TM = true

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }
  override updateData(data?: any) {
    if (!data) return
    this.addBooleanProperty('TM',data,'TM')
  }
}
export class ResSearchFilterValue extends SafeBaseObject {
  productCod = 'CRU'
  productCodes:string[]=[]

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }
  override updateData(data?: any) {
    if (!data) return
    this.productCodes = this.productCod.split(',')
  }
}
export class ResSearchFilterPreferences extends SafeBaseObject {
  values = new ResSearchFilterValue()

  flags = new ResSearchFilterFlags()

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }
  override updateData(data?: any) {
    if (!data) return
    if(data.Values) this.values = new ResSearchFilterValue(data.Values)
    if(data.Flags) this.flags = new ResSearchFilterFlags(data.Flags)
  }
}
export class ResProductPriceType extends SafeBaseObject {
  productCod = ''
  productID = 0
  currency = ''
  priceTypeID = 0
  priceTypeName = ''
  guestFrom = 0
  guestTo = 0
  sellingPrice = 0
  priceLessPortCharge = 0
  priceCombo = 0
  portCharge = 0
  companyID = 0
  companyName = ''
  isDefaultFilter = false
  onRequest = false
  onRequestMsg = ''
  markup = 0
  roe = 0

  constructor(data?: any) {
    super();
    if (!data) return

    super.updateData(data)
    this.updateData(data)
  }

  override updateData(data?: any) {
    if (!data) return
  }
}
export class ResProduct extends SafeBaseObject {
  productCod = ''
  productID = 0
  typeID = -9999
  typeName = ''
  cod = ''
  name = ''
  description = ''
  escortInc = false
  guideInc = false
  driverInc = false
  isAK = false
  occupancy = 0
  cityIDFrom = 0
  cityFrom = ''
  cityIDTo = 0
  cityTo = ''
  duration = 0
  durationUDM = ''
  minPax = 0
  maxPax = 0
  packageInclusion = ''
  packageExclusion = ''
  packageImportantNotes = ''
  currency = ''
  sellingPriceCurrency?:string
  priceTypeID = 0
  priceLabel = ''
  guestFrom = 0
  guestTo = 0
  sellingPrice = 0
  priceTypeName = ''
  meal = ''
  kidFriendly = ''
  kidFriendlyTo = ''
  physicalLevel = ''
  itinerary = ''
  availabilityMessage = ''
  availabilityBackgroundColor = ''
  onHoldQty = 0
  onHoldQtySingle = 0
  onHoldQtyTriple = 0

  isTM = false
  cruiseLineID = 0
  cruiseLine = ''
  deckPlanPath = ''
  ship = ''
  shipID = 0
  portCharge = 0
  embarkDate = DateTime.invalid('empty');
  debarkDate = DateTime.invalid('empty');
  hasPromo: boolean = false;
  hasBundle: boolean = false;
  destinationName = ''
  itineraries: Array<ReservationCruiseItinerary> = []
  priceCombo: number = 0
  voyageClass = ''
  voyageIDNext: number = 0
  voyageIDPrev: number = 0
  netFareAvailable: boolean = false
  destinationID: number = 0
  internalNotes = ''

  images: string[] = []
  maps: string[] = []
  descriptionsHtml: string[] = []
  titles: string[] = []
  subDestinationID: number = 0
  subDestinationName: string = ''
  deckPlanStartFrom = DateTime.invalid('empty');

  mainImage = ''
  mainMap = ''
  mainTitle = ''
  supplierID = ''
  supplierName = ''

  ecm: ResProductEcm[] = []
  prices: ResProductPrice[] = []
  companies: ResProductCompany[] = []
  suiteCategories: ResProductSuiteCategory[] = []
  pro: ResProductSuiteCategory[] = []

  requestedPromoID?: number
  internalType: string = ''

  ecmData = new ReservationPackageEcmData();
  promoPrices: ResProductPromoPrice[] = [];
  priceTypes: ResProductPriceType[] = [];
  mainPackageID = 0

  selectable: boolean = false;

  travelStartDate = DateTime.invalid('empty')
  travelEndDate = DateTime.invalid('empty')
  combos: any[] = []
  isCheckboxComboChecked = false
  selectedPriceType?: ResProductPriceType;
  selectedPromo?: ResProductPromoPrice;
  // private reservationApiService: ReservationApiService;
  dateFrom: DateTime = DateTime.invalid('empty');
  dateTo: DateTime = DateTime.invalid('empty');
  charterPossible = false;
  isCharter = false
  pABkgNum = 0

  productAsCruise = new ReservationCruise()
  productAsPackage = new ResPackage()
  productAsCamp = new ReservationCamp()

  children:ResProduct[]=[]
  viewChild = false
  footnotes = new ResSearchPackageFootnote()

  get showExtenYourJourney() {
    return this.productCod !== ResProductType.SERVICE
  }
  get hasAvailabilityIcon() {
    return this.productCod === ResProductType.CRUISE
  }

  get hasItinerary() {
    return !(this.productCod === ResProductType.SANCTUARY || this.productCod === ResProductType.SERVICE)
  }

  get hasPortCharges() {
    return !!this.priceTypes[0]?.portCharge
  }

  constructor(data?: any) {
    super();
    if (!data) return
    // this.reservationApiService = AppModule.injector.get(ReservationApiService);
    this.defineInternalType(data);

    super.updateData(data);
    this.updateData(data);

    if ('Ecm' in data && data.Ecm) {
      this.ecmData = ReservationPackageEcmData.mapEcmData(data.Ecm);
    }
    
    this.productAsCruise = this.asCruise()
    this.productAsPackage = this.asPackage()
    this.productAsCamp = this.asCamp()

    this.footnotes = new ResSearchPackageFootnote(data.Footnotes)
  }

  getStartDate(): DateTime | undefined {
    if (this.travelStartDate && !this.travelStartDate.isValid) return undefined
    return this.travelStartDate;
  }

  getEndDate(): DateTime | undefined {
    if (this.travelEndDate && !this.travelEndDate.isValid) return undefined
    return this.travelEndDate;
  }

  defineInternalType(data?: any) {
    switch(data.productCod) {
      case ResProductType.CRUISE:
        this.internalType = 'voyage'
        break;
      case ResProductType.PACKAGE:
        this.internalType = 'package'
        break;
      case ResProductType.SANCTUARY:
        this.internalType = 'camp'
        break;
      case ResProductType.VILLA:
        this.internalType = 'villa'
        break;
      case ResProductType.SERVICE:
        this.internalType = 'service'
        break;
    }
  }

  castProduct() {
    switch(this.productCod) {
      case ResProductType.SANCTUARY:
        return this.asCamp();
      case ResProductType.SERVICE:
        return this.asServiceOption();
      case ResProductType.PACKAGE:
        return this.asPackage();
      case ResProductType.BASEPACKAGE:
        return this.asBasePackage();
      case ResProductType.VILLA:
        return this.asVilla();
      default:
        return this.asCruise();
    }
  }

  asCruise() {
    const reservationCruise = new ReservationCruise();
    reservationCruise.availabilityMessage = this.availabilityMessage;
    reservationCruise.cruiseLine = this.cruiseLine;
    reservationCruise.cruiseLineCod = this.cod;
    reservationCruise.cruiseLineID = this.cruiseLineID;
    reservationCruise.currency = this.currency;
    reservationCruise.debarkPortID = this.cityIDTo;
    reservationCruise.debarkDate = this.debarkDate && this.debarkDate.isValid ? this.debarkDate : this.travelEndDate;
    reservationCruise.debarkPort = this.cityTo;
    reservationCruise.debarkIataCod = '';
    // reservationCruise.debarkFrom = '';
    // reservationCruise.debarkTo = '';
    reservationCruise.description = this.description;
    reservationCruise.destinationID = this.destinationID;
    // reservationCruise.detail = [];
    reservationCruise.duration = this.duration;
    reservationCruise.daysToSailing = 0;
    reservationCruise.embarkPortID = this.cityIDFrom;
    reservationCruise.embarkDate = this.embarkDate && this.embarkDate.isValid ? this.embarkDate : this.travelStartDate;
    reservationCruise.embarkPort = this.cityFrom;
    reservationCruise.embarkIataCod = '';
    // reservationCruise.embarkFrom = '';
    // reservationCruise.embarkTo = '';
    // reservationCruise.departTime = '';
    reservationCruise.features = [];
    // reservationCruise.gty = '';
    reservationCruise.itineraries = this.itineraries;
    reservationCruise.minGuest = this.guestFrom;
    reservationCruise.maxGuest = this.guestTo;
    reservationCruise.packageID = this.productCod === 'PKG' ? this.productID : 0;
    reservationCruise.packageTravelDateID = 0;
    reservationCruise.pathDeckplan = '';
    reservationCruise.paxNum = 0; // ??? c'è solo minPax e maxPax
    // reservationCruise.price = 0;
    reservationCruise.priceTypeID = this.priceTypeID;
    reservationCruise.priceTypeName = this.priceTypeName;
    reservationCruise.sectionID = 0;
    reservationCruise.sellingPrice = this.sellingPrice;
    reservationCruise.priceCategoryID = 0;
    reservationCruise.priceCategoryCod = '';
    reservationCruise.priceCategory = '';
    reservationCruise.pricePerGuest = 0;
    reservationCruise.priceChild = 0;
    reservationCruise.priceCombo = this.priceCombo;
    reservationCruise.priceExtraChild = 0;
    reservationCruise.sellingPriceCurrency = '';
    reservationCruise.services = [];
    reservationCruise.ship = this.ship;
    reservationCruise.shipCod = ''; // ???
    reservationCruise.shipID = this.shipID;
    reservationCruise.suiteCapacity = '';
    reservationCruise.suiteCapacityID = 0;
    reservationCruise.suiteCategory = '';
    reservationCruise.suiteCategoryCode = '';
    reservationCruise.suiteCategoryID = 0;
    // reservationCruise.suiteNo = '';
    reservationCruise.suiteLabel = '';
    reservationCruise.voyageID = this.productID;
    reservationCruise.voyageClass = this.voyageClass;
    reservationCruise.voyIDNext = this.voyageIDNext;
    reservationCruise.voyIDPrev = this.voyageIDPrev;
    reservationCruise.voyageName = '';
    reservationCruise.voyageNumber = this.cod; // ???
    reservationCruise.paymentDueDate = new Date()
    reservationCruise.fullBookingSchedulingFlag = false;
    reservationCruise.amount = 0;
    reservationCruise.isFinalPayment = false;
    reservationCruise.ecmData = this.ecmData;
    reservationCruise.portCharge = this.portCharge;
    reservationCruise.destinationName = this.destinationName;
    reservationCruise.subDestinationID = this.subDestinationID;
    reservationCruise.subDestinationName = this.subDestinationName;
    reservationCruise.deckPlanStartFrom = this.deckPlanStartFrom;
    reservationCruise.hasPromo = this.hasPromo;
    reservationCruise.netFareAvailable = this.netFareAvailable;
    reservationCruise.hasBundle = this.hasBundle;
    reservationCruise.promoPrices = this.promoPrices;
    reservationCruise.selectable = this.selectable
    return reservationCruise;
  }

  asPackage() {
    const resPackage = new ResPackage();
    resPackage.packageID = this.productCod === 'PKG' ? this.productID : 0;
    resPackage.packageName = this.name;
    resPackage.isTM = this.isTM;
    resPackage.packageShortName = '';
    resPackage.packageLongName = this.name;
    resPackage.packageExclusion = this.packageExclusion;
    resPackage.packageInclusion = this.packageInclusion;
    resPackage.packageImportantNotes = this.packageImportantNotes;
    resPackage.internalNotes = ''
    resPackage.packageTypeID = this.typeID;
    resPackage.packageTypeName = this.typeName;
    resPackage.currency = this.currency;
    resPackage.sellingPriceCurrency = this.sellingPriceCurrency ?? this.currency;
    resPackage.start = this.embarkDate;
    resPackage.end = this.debarkDate;
    resPackage.minPax = this.minPax;
    resPackage.maxPax = this.maxPax;
    resPackage.defaultMargin = 1;
    resPackage.nrDecimal = 10;
    resPackage.occupancy = this.occupancy;
    resPackage.childReduction = 0;
    resPackage.escortInc = this.escortInc;
    resPackage.onlyEven = false;
    resPackage.driverInc = this.driverInc;
    resPackage.guideInc = this.guideInc;
    resPackage.isAK = this.isAK;
    resPackage.transportNeeded = false;
    resPackage.duration = this.duration;
    resPackage.durationMin = 0
    resPackage.durationUDM = this.durationUDM;
    resPackage.cityIDFrom = this.cityIDFrom;
    resPackage.cityIDTo = this.cityIDTo;
    resPackage.cityFrom = this.cityFrom;
    resPackage.cityTo = this.cityTo;
    resPackage.voyageID = this.productID;
    resPackage.voyageNumber = '';
    resPackage.voyageDay = 0;
    resPackage.countryCod = '';
    resPackage.countryID = 0;
    resPackage.cancellationPolicyID = 0;
    resPackage.brandName = '';
    resPackage.webSiteVisibilityFrom = DateTime.now();
    resPackage.webSiteVisibilityTo = DateTime.fromJSDate(new Date('2029-12-31'));
    resPackage.travelPartnerVisibilityFrom = DateTime.now();
    resPackage.travelPartnerVisibilityTo = DateTime.fromJSDate(new Date('2029-12-31'));
    resPackage.status = 'B';
    resPackage.category = '';
    resPackage.physicalLevel = this.physicalLevel;
    resPackage.companyID = [];
    resPackage.siteID = 0;
    resPackage.availabilityMessage = this.availabilityMessage;
    resPackage.availabilityBackgroundColor = this.availabilityBackgroundColor;
    resPackage.packagePicture = '';
    resPackage.packageECMDescription = '';
    resPackage.packageBrochure = '';
    resPackage.priceTypeID = this.priceTypeID;
    resPackage.priceTypeName = Number(this.priceTypeName);
    resPackage.guestFrom = this.guestFrom;
    resPackage.guestTo = this.guestTo;
    resPackage.sellingPrice = this.sellingPrice;
    resPackage.meal = this.meal;
    resPackage.categoryCod = [];
    resPackage.physicalLevelCod = 'ALL';
    resPackage.kidFriendly = this.kidFriendly;
    resPackage.kidFriendlyTo = this.kidFriendlyTo;
    resPackage.hideGuest = false;
    resPackage.isBooked = false;
    resPackage.hasBkg = false;
    resPackage.prices = [];
    resPackage.options = [];
    resPackage.travelDates = [];
    resPackage.formattedTravels = '';
    resPackage.ecmData = this.ecmData;
    resPackage.itineraryDays = [];
    resPackage.priceTypes = [];
    resPackage.brands = [];
    resPackage.companies = [];
    resPackage.categories = [];
    resPackage.ports = [];
    resPackage.depScheduling = [];
    // resPackage.cancPolicy
    resPackage.imageSrc = '';
    resPackage.internalType = this.internalType;
    resPackage.hasBundle = this.hasBundle;
    resPackage.ecmLink = '';
    resPackage.extendJournyes = [];
    resPackage.selectable = this.selectable
    resPackage.mainPackage = false;
    resPackage.showConnected = false;
    resPackage.footnotes = this.footnotes;
    return resPackage;
  }

  asBasePackage() {
    const resBasePackage: ReservationBasePackage = new ReservationBasePackage();
    resBasePackage.sectionID = 0;
    resBasePackage.packageID = 0;
    resBasePackage.packageTravelDateID = 0;
    resBasePackage.packageTypeID = 0;
    resBasePackage.packageTypeName = '';
    resBasePackage.packageShortName = '';
    resBasePackage.packageName = this.name;
    resBasePackage.isTM = false;
    resBasePackage.escortInc = false;
    resBasePackage.guideInc = false;
    resBasePackage.driverInc = false;
    resBasePackage.occupancy = 0;
    resBasePackage.cityID = 0;
    resBasePackage.duration = this.duration;
    resBasePackage.durationUDM = this.durationUDM;
    resBasePackage.minPax = this.minPax;
    resBasePackage.maxPax = 0;
    resBasePackage.guestFrom = 0;
    resBasePackage.guestTo = 0;
    resBasePackage.packagePicture = '';
    resBasePackage.packageECMDescription = '';
    resBasePackage.packageBrochure = '';
    resBasePackage.packageInclusion = '';
    resBasePackage.packageExclusion = '';
    resBasePackage.packageImportantNotes = '';
    resBasePackage.internalNotes = '';
    resBasePackage.cancellationPolicyID = 0;
    resBasePackage.currency = '';
    resBasePackage.calendarDate = [];
    resBasePackage.itinerariesSimple = [];
    resBasePackage.itineraryMedia = [];
    resBasePackage.priceType = [];
    resBasePackage.servicesMedia = [];
    // resBasePackage.ecmData = ;
    resBasePackage.internalType = 'base-package';
    resBasePackage.serviceOptions = [];
    resBasePackage.serviceImages = [];
    resBasePackage.suggestedItems = [];
    resBasePackage.companies = [];
    resBasePackage.categories = [];
    resBasePackage.isRetail = false;
    resBasePackage.voyageID = 0;
    resBasePackage.voyageNumber = '';
    resBasePackage.voyageDay = 0;
    resBasePackage.selectable = this.selectable
    return resBasePackage;
  }

  asCamp() {
    const resCamp = new ReservationCamp();
    resCamp.internalType = this.internalType;
    resCamp.shipID = this.shipID;
    resCamp.shipCod = '';
    resCamp.ship = this.ship;
    resCamp.description = this.description;
    resCamp.cityID = 0;
    resCamp.geoTreeName = '';
    resCamp.minPax = this.minPax;
    resCamp.maxPax = this.maxPax;
    resCamp.currency = this.currency;
    resCamp.priceTypeID = this.priceTypeID;
    resCamp.availabilityMessage = this.availabilityMessage;
    resCamp.availabilityBackgroundColor = this.availabilityBackgroundColor;
    resCamp.onHoldQty = this.onHoldQty;
    resCamp.isTM = this.isTM;
    resCamp.isAK = this.isAK;
    resCamp.cruiseLineID = this.cruiseLineID;
    resCamp.cruiseLine = this.cruiseLine;
    resCamp.deckPlanPath = this.deckPlanPath;
    resCamp.deckPlanOn = false;
    resCamp.gridOn = false;
    resCamp.deck = '1';
    resCamp.media = [];
    resCamp.images = [];
    resCamp.titles = [];
    resCamp.descriptionsHtml = [];
    resCamp.mainImage = this.mainImage;
    resCamp.mainTitle = this.mainTitle;
    resCamp.mainDescriptionsHtml = '';
    resCamp.suiteCategories = [];
    resCamp.ecmData = new ReservationPackageEcmData();
    resCamp.duration = this.duration;
    resCamp.travelStartDate = DateTime.invalid('empty');
    resCamp.travelEndDate = DateTime.invalid('empty');
    resCamp.selectable = this.selectable
    return resCamp;
  }

  asVilla() {
    const resVilla = new ResVilla();
    resVilla.internalType = this.internalType;
    resVilla.villaID = this.productID;
    resVilla.description = this.description;
    resVilla.cityID = 0;
    return resVilla;
  }

  asServiceOption() {
    const resServiceOption = new ResProductService();
    resServiceOption.internalType = this.internalType;
    resServiceOption.serviceTypeID = this.typeID;
    resServiceOption.serviceTypeName = this.typeName;
    resServiceOption.serviceID = this.productID;
    resServiceOption.shipCod = '';
    resServiceOption.ship = this.ship;
    resServiceOption.description = this.description;
    resServiceOption.cityID = 0;
    resServiceOption.geoTreeName = '';
    resServiceOption.minPax = this.minPax;
    resServiceOption.maxPax = this.maxPax;
    resServiceOption.currency = this.currency;
    resServiceOption.priceTypeID = this.priceTypeID;
    resServiceOption.availabilityMessage = this.availabilityMessage;
    resServiceOption.availabilityBackgroundColor = this.availabilityBackgroundColor;
    resServiceOption.deckPlanPath = this.deckPlanPath;
    resServiceOption.deckPlanOn = false;
    resServiceOption.gridOn = false;
    resServiceOption.deck = '1';
    resServiceOption.media = [];
    resServiceOption.images = [];
    resServiceOption.titles = [];
    resServiceOption.descriptionsHtml = [];
    resServiceOption.mainImage = this.mainImage;
    resServiceOption.mainTitle = this.mainTitle;
    resServiceOption.mainDescriptionsHtml = '';
    resServiceOption.options =[];
    resServiceOption.ecmData = new ReservationPackageEcmData();
    resServiceOption.duration = this.duration;
    const dtStart = this.prices.length>0 ? this.prices[0].travelStartDate : DateTime.now().startOf('day')
    const dtEnd = this.prices.length>1 ? this.prices[this.prices.length-1].travelStartDate : dtStart.plus(0)
    resServiceOption.travelStartDate = dtStart;
    resServiceOption.travelEndDate = dtEnd;
    resServiceOption.selectable = this.selectable
    return  resServiceOption
  }
  get displayName(): string {
    return this.name ?? this.cod ?? `${this.productID}` ?? '' + ' -';
  }

  get formattedDuration(): string {
    return this.duration + (this.durationUDM ? ' ' + this.durationUDM : '');
  }

  get bookableMessage(): string {
    if (this.guestFrom && this.guestTo && this.guestFrom !== this.guestTo) {
      return `Bookable from ${this.guestFrom} to ${this.guestTo} guests`;
    } else {
      return `Bookable for ${this.guestFrom} guests`;
    }
  }

  get productAvailabilityMessage(): string {
    if (this.guestFrom && this.guestTo) {
      return 'AVAILABLE';
    } else {
      return 'PENDING';
    }
  }

  get ports(): string {
    if (this.itineraries && this.itineraries.length > 2) {
      return ((this?.itineraries?.length ?? 0) - 2).toString() + ' ports';
    } else {
      return '';
    }
  }

  get hasSpecialOptions(): boolean {
    return this.escortInc || this.guideInc || this.driverInc || this.isAK;
  }

  // loadEcmData(): Promise<ReservationPackageEcmData> {
  //   if (this.ecmData.loaded) return Promise.resolve(this.ecmData);

  //   return lastValueFrom(this.reservationApiService.getVoyageEcmData(this.productID).pipe(
  //     map<Array<IReservationApiPackageEcmData>, ReservationPackageEcmData>((ecmData) => this.ecmData = ReservationPackageEcmData.mapEcmData(ecmData))
  //   ));
  // }

  override updateData(data?: any) {
    if (!data) return

    if (data.Ecm) this.ecm = data.Ecm.map((ecm: any) => new ResProductEcm(ecm))
    if (data.Prices) this.prices = data.Prices.map((price: any) => new ResProductPrice(price))
    if (data.Companies) this.companies = data.Companies.map((c: any) => new ResProductCompany(c))
    if (data.SuiteCategories) this.suiteCategories = data.SuiteCategories.map((sc: any) => new ResProductPrice(sc))
    if (data.Combos) this.combos = data.Combos.map((sc: any) => sc)
    if (data.PromoPrice) this.promoPrices = data.PromoPrice.map((pprice: any) => new ResProductPromoPrice(pprice));
    if (data.PriceTypes) this.priceTypes = data.PriceTypes.map((pt: any) => new ResProductPriceType(pt));
    if (data.Itineraries) this.itineraries = data.Itineraries.map((itinerary: IReservationApiCruiseItinerary) => new ReservationCruiseItinerary(itinerary));

    const prefix = this.productCod === 'SRV' ? this.typeName.toLowerCase() : this.productCod.toLowerCase()
    this.images = this.ecm.filter(ecm => ecm.fldCod.toLowerCase().includes('image')).map(ecm => ecm.fldValue)
    this.mainImage = this.images.length > 0 ? this.images[0] : `/assets/images/${prefix}-placeholder.jpg`

    this.maps = this.ecm.filter(ecm => ecm.fldCod.toLowerCase().includes('mappng')).map(ecm => ecm.fldValue)
    switch(prefix) {
      case 'cru':
        this.mainMap = this.maps.length > 0 ? this.maps[0] : `/assets/images/${prefix}-map-placeholder.jpg`
        break;
      default:
        this.mainMap = this.maps.length > 0 ? this.maps[0] : `/assets/images/${prefix}-map-placeholder.jpg`
        break;
    }

    this.descriptionsHtml = this.ecm.filter(ecm => ecm.fldCod.toLowerCase().includes('description')).map(ecm => ecm.fldValue)
    this.titles = this.ecm.filter(ecm => ecm.fldCod.toLowerCase().includes('name')).map(ecm => ecm.fldValue)

    this.mainTitle = this.titles.length > 0 ? this.titles[0] : (this.description !== '' ? this.description : this.name)

    if (this.typeID == -9999) {
      switch (this.productCod) {
        case ResProductType.CRUISE:
          this.typeName = 'Cruise'
          this.selectable = this.priceTypes.length > 0
          break;

        case ResProductType.PACKAGE:
          this.typeName = 'Tour package'
          break;

        case ResProductType.SANCTUARY:
          this.typeName = 'Camp'
          break;

        case ResProductType.SERVICE:
          this.typeName = 'Service'
          break;

        case ResProductType.VILLA:
          this.typeName = 'Villa'
          break;
      }
    }
  }

}

export enum ResPacakgeTypeEnum {
  TailorMade = 10,
  Lec = 11,
  Sanctuary = 12,
  LuxurySmallGroupJourneysCruise = 16,
  CanalBargeCruises = 19,
}
export class ResPackage extends SafeBaseObject {

  static INVALID_PACKAGE_ID = 0;

  // From IReservationApiPackageSetup
  packageID: number = ResPackage.INVALID_PACKAGE_ID;
  packageName: string = '';
  isTM = false;
  packageShortName: string = '';
  packageLongName: string = '';
  packageExclusion: string = '';
  packageInclusion: string = '';
  packageImportantNotes: string = '';
  internalNotes: string = '';
  packageTypeID?: number
  packageTypeName?: string
  currency: string = 'USD';
  sellingPriceCurrency?:string
  start: DateTime = DateTime.now();
  end: DateTime = DateTime.fromJSDate(new Date('2029-12-31'));
  minPax?: number;
  maxPax?: number;
  defaultMargin: number = 1;
  nrDecimal: number = 0;
  occupancy: number = 100;
  childReduction?: number;
  escortInc: boolean = false;
  onlyEven: boolean = false;
  driverInc: boolean = false;
  guideInc: boolean = false;
  isAK: boolean = false;
  transportNeeded: boolean = false;
  duration: number = 0;
  durationMin?: number = 0;
  durationUDM: string = '';
  cityIDFrom: number = 0;
  cityIDTo: number = 0;
  cityFrom: string = '';
  cityTo: string = '';
  voyageID = 0;
  voyageNumber = '';
  voyageDay = 0;
  countryCod: string = '';
  countryID: number = 0;
  cancellationPolicyID: number = 0;
  brandName: string = '';
  webSiteVisibilityFrom = DateTime.now();
  webSiteVisibilityTo = DateTime.fromJSDate(new Date('2029-12-31'));
  travelPartnerVisibilityFrom = DateTime.now();
  travelPartnerVisibilityTo = DateTime.fromJSDate(new Date('2029-12-31'));
  status: string = 'B';
  category: string = '';
  physicalLevel: string = '';
  companyID: number[] = [];
  siteID = 0;
  mainPackageID?:number

  // From IReservationApiPackageSetup
  availabilityMessage: string = '';
  availabilityBackgroundColor: string = '';
  packagePicture?: string;
  packageECMDescription?: string;
  packageBrochure?: string;
  priceTypeID?: number;
  priceTypeName?: number;
  guestFrom?: number;
  guestTo?: number;
  sellingPrice?: number;
  meal: string = '';
  categoryCod: string[] = [];
  physicalLevelCod: string = 'ALL';
  kidFriendly: string = '';
  kidFriendlyTo: string = '';
  hideGuest: boolean = false;
  isBooked: boolean = false;
  hasBkg: boolean = false;

  prices: ResPackagePrice[] = [];
  options: ResPackageOption[] = [];
  travelDates: ResPackageTravelDate[] = [];
  formattedTravels: string = '';
  ecmData: ReservationPackageEcmData = new ReservationPackageEcmData();
  itineraryDays: ResPackageItineraryDay[] = [];
  priceTypes: ResPackagePriceType[] = [];
  brands: { brandID: number; brandName?: string; }[] = [];
  companies: { companyID: number; companyName?: string; }[] = [];
  categories: { categoryCod: string; category?: string; }[] = [];
  ports: ResPackagePort[] = [];
  depScheduling: ResPackageDepScheduling[] = [];
  cancPolicy?: ResPackageCancPolicy
  imageSrc: SafeUrl = '';
  internalType = 'package';
  hasBundle = false;

  ecmLink: string = '';
  extendJournyes: ReservationExtendJournyes[] = [];
  selectable = false
  displayPackageName = ''
  mainPackage = false

  infoPackageKey = ''

  //@TODO uniformare per ResGenericProduct
  itineraryDaysNew: ResGenericItineraryDay[]=[]
  isAnchor = false
  isOverNight = false
  hasMediaData = false
  hasImages = false
  hasMap = false
  imagesUrl:SafeUrl[] = []
  mapUrl:SafeUrl = ''

  marketingStatus: string[] = []
  highlightsNum = 0
  highlightsTypeCod = 0
  intAirNotes = ''
  // intAirRouting = ''
  // accessibility = ''
  // firstGrpEvent = ''
  // lastGrpEvent = ''
  // gTYDept = ''
  includesInternalAir = false
  mainDestinationID = 0
  activityTypeCod = ''
  showConnected = false
  priceNotes = ''
  jetID = 0
  jet = ''
  footnotes = new ResSearchPackageFootnote()
  // CAN BE REMOVED - NOT USED
  // isLec() {
  //   return this.isLec
  // }
  previewTravelDates() {
    if (this.travelDates.length == 0) return 'No Dates'
    //    return this.travelDates[0].travelStartDate.toFormat('d LLL yy')+(this.travelDates.length>1 ? ' ...' :'');
    return this.travelDates[0].travelStartDate.toFormat('d LLL yy');
  }

  availableTravelDates() {
    if (this.travelDates.length == 0) return 'No Dates'
    return this.travelDates[0].travelStartDate.toFormat('d LLL yy') + ' - ' + this.travelDates[0].travelEndDate.toFormat('d LLL yy');
  }
  isLecOrSimilar() {
    return this.packageTypeID === ResPacakgeTypeEnum.Lec || this.packageTypeID === ResPacakgeTypeEnum.LuxurySmallGroupJourneysCruise || this.packageTypeID === ResPacakgeTypeEnum.CanalBargeCruises;
  }

  // CAN BE REMOVED - REPLACED BY !isLecOrSimilar()
  // isNotLecOrSimilar() {
  //   return this.packageTypeID !== ResPacakgeTypeEnum.Lec && this.packageTypeID !== ResPacakgeTypeEnum.LuxurySmallGroupJourneysCruise && this.packageTypeID !== ResPacakgeTypeEnum.CanalBargeCruises;
  // }
  hasStandardFlow() {
    return !this.isLecOrSimilar() && !this.isBookableWithChooseDate();
  }
  isBookableWithChooseDate() {
    return this.packageTypeID === ResPacakgeTypeEnum.Sanctuary
  }
  asReservationBasePackage(packageID: number): Promise<ReservationBasePackage> {
    const reservationApiService = AppModule.injector.get(ReservationApiService);
    return new Promise<ReservationBasePackage>(resolve => {
      var prm: IResPackageSelectParams = {
        PackageID: packageID.toString(),
        IsRetail: 'Y',
        PriceTypeID: this.priceTypeID,
        Currency: this.currency
      };
      reservationApiService.getPackageOpt(prm).subscribe({
        next: res => resolve(res)
      })
    })
  }

  get disallowActionForBookedPackage(): boolean {
    return this.isValid && this.isBooked && this.status !== 'B';
  }

  get voyage() {
    return { voyageID: this.voyageID, voyageNumber: this.voyageNumber, voyageDay: this.voyageDay }
  }

  constructor(data?: IReservationApiPackageSetup | IReservationApiPackageHeader) {
    super();
    if (data) {
      super.updateData(data)
      const sanitizer = AppModule.injector.get(DomSanitizer);
      this.imageSrc = '/assets/svg/extend-journey-logo-AK-gray.svg';
      //this.updateData(data);
      if (data.ExtendJournyes) {
        this.extendJournyes = data.ExtendJournyes.map(ej => {
          ej.TypeFrom = ej.TypeFrom ?? 'PKG';
          return new ReservationExtendJournyes(ej)
        })
      }

      if ('PackagePrice' in data) {
        this.prices = data.PackagePrice?.map((price) => new ResPackagePrice(price)) ?? []
      }
      if ('Options' in data) {
        this.options = (data.Options ?? [])
          .filter(option => !option.DefaultPackageOptionID) // Take only options w/o DefaultPackageOptionID (-> Default options, they do not have a default and they are not upgrade)
          .map(option =>
            new ResPackageOption(
              option,
              (data.Options ?? []).filter(option => !!option.DefaultPackageOptionID) // Pass options w DefaultPackageOptionID (-> Upgrades) to create default<->upgrade relationship
            )
          )
      }
      if ('TravelDates' in data) {
        this.travelDates = data.TravelDates?.map((travelDate) => new ResPackageTravelDate(travelDate, this)) ?? []
      }
      if ('ItineraryDays' in data) {
        this.itineraryDays = data.ItineraryDays?.map((day) => new ResPackageItineraryDay(day)) ?? []
      }
      if ('PriceTypes' in data) {
        this.priceTypes = data.PriceTypes?.map((pt) => new ResPackagePriceType(pt)) ?? []
      }
      if ('PriceType' in data) {
        this.priceTypes = data.PriceType?.map((pt) => new ResPackagePriceType(pt)) ?? []
      }
      if ('Brands' in data) {
        this.brands = data.Brands?.map((b) => {
          return {
            brandID: b.BrandID,
            brandName: b.BrandName
          }
        }) ?? [];
      }
      if ('Categories' in data) {
        this.categories = data.Categories?.map((b) => {
          return {
            categoryCod: b.CategoryCod,
            category: b.Category
          }
        }) ?? [];
      }
      if ('Companies' in data) {
        this.companies = data.Companies?.map((b) => {
          return {
            companyID: b.CompanyID,
            companyName: b.CompanyName
          }
        }) ?? [];
      }
      if ('Ports' in data) {
        this.ports = data.Ports?.map((p) => new ResPackagePort(p)) ?? [];
      }
      if ('EcmData' in data && data.EcmData) {
        this.ecmData = ReservationPackageEcmData.mapEcmData(data.EcmData);
      }
      this.ecmLink = `${AppModule.injector.get(EidosConfigService).currentModulesConfig.getValue().find(m => m.moduleName === CoreModuleName.Reservation).crossReferenceURL.ecm}/main?EntCod=Package&EntIns=${this.packageID}`
      if (this.ecmData.imageSrc) {
        this.imageSrc = sanitizer.bypassSecurityTrustResourceUrl(this.ecmData.imageSrc);
      }

      const isMainPackageIcon = this.mainPackage ? '<span style="background-color: #dbb5a4; padding: 5px; float: right; font-size: 0.5rem; font-weight: bold; margin: -6px 0 0 -10px; border-radius: 5px">MAIN</span>' : ''
      this.infoPackageKey = `${this.packageID}${isMainPackageIcon}`

      if ('MarketingStatus' in data) {
        this.marketingStatus = data.MarketingStatus && data.MarketingStatus?.length > 0 ? data.MarketingStatus.split(',') : []
      }
    }
  }

  override updateData(data: any): void {
    if(!data) return
  }

  getStartDate(): DateTime | undefined {
    if (this.start && !this.start.isValid) return undefined
    return this.start;
  }
  getEndDate(): DateTime | undefined {
    if (this.end && !this.end.isValid) return undefined
    return this.end;
  }
  get hasSpecialOptions(): boolean {
    return this.escortInc || this.guideInc || this.driverInc || this.isAK;
  }

  get isValid(): boolean {
    return !!this.packageID && this.packageID !== ResPackage.INVALID_PACKAGE_ID;
  }

  get formattedDuration(): string {
    return this.duration + (this.durationUDM ? ' ' + this.durationUDM : '');
  }

  get formattedOccupancy(): string {
    if (!this.occupancy) return '';
    return this.occupancy + '%';
  }

  get formattedDefaultMargin(): string {
    if (!this.defaultMargin) return '';
    return this.defaultMargin + '%';
  }

  get formattedChildReduction(): string {
    if (!this.childReduction) return '';
    return this.childReduction + '%';
  }

  get formattedEscortInc(): string {
    return this.escortInc ? '✔️' : '❌';
  }

  get formattedDriverInc(): string {
    return this.driverInc ? '✔️' : '❌';
  }

  get formattedGuideInc(): string {
    return this.guideInc ? '✔️' : '❌';
  }

  get formattedOnlyEven(): string {
    return this.onlyEven ? '✔️' : '❌';
  }

  get formattedHasBkg(): string {
    return this.hasBkg ? '✔️' : '❌';
  }

  get formattedTransportNeeded(): string {
    return this.transportNeeded ? '✔️' : '❌';
  }

  get lastsDays(): boolean {
    return this.durationUDM?.toLowerCase() === 'day'
  }

  get lastsHours(): boolean {
    return this.durationUDM?.toLowerCase() === 'hour'
  }

  get isOperational(): boolean {
    return !!this.itineraryDays.length && this.itineraryDays.every((day) => day.isOperational);
  }

  get isProposal(): boolean {
    return !!this.itineraryDays.length && this.itineraryDays.every((day) => day.isProposal);
  }

  get allBrandsNameAsString(): string {
    return this.brands.map((b) => b.brandName).join(', ');
  }
  get allCompaniesNameAsString(): string {
    return this.companies.map((c) => c.companyName).join(', ');
  }

  getBackgroundImageUrl(which: string): string {
    return `url("${(this.imageSrc as any).changingThisBreaksApplicationSecurity ?? '/assets/images/reservation/' + which + '.jpg'}")`;
  }
  displayName(): string {
    return this.packageName ?? this.packageID ?? '';
  }

  get proposalItineraryDays(): ResPackageItineraryDay[] {
    return this.itineraryDays.filter((day) => day.isProposal);
  }

  get operationalItineraryDays(): ResPackageItineraryDay[] {
    return this.itineraryDays.filter((day) => day.isOperational);
  }
}
export class ResPackageDayByDayItemDetails extends SafeBaseObject {
  packageItineraryDayByDayDetailID = 0
  packageItineraryDayByDayID = 0
  packageItineraryDayID = 0
  packageID = 0
  sequence = 0
  cityIDFrom = 0
  cityIDTo = 0
  cityIDExtension = 0
  movingTypeID = 0
  timeFrom = new Date()
  timeTo = new Date()
  genericTimeFrom = 0
  genericTimeTo = 0
  description = ''
  title = ''
  durationHour = 0
  durationMin = 0
  day = 0
  status = 'A'
  flagPrevDay = false
  hideInWebsite = false
  numAKP = 0


  constructor(data?:any){
    super()
    this.updateData(data)
  }
  override updateData(data?:any) {
    if(!data) return
    super.updateData(data)
    let dateFrom = new Date();
    let [hoursFrom, minutesFrom, secondsFrom] = data.TimeFrom?.split(':').map(Number) ?? [0,0,0];
    dateFrom.setHours(hoursFrom);
    dateFrom.setMinutes(minutesFrom);
    dateFrom.setSeconds(secondsFrom);
    this.timeFrom = dateFrom

    let dateTo = new Date();
    let [hoursTo, minutesTo, secondsTo] = data.TimeTo?.split(':').map(Number) ?? [0,0,0];
    dateTo.setHours(hoursTo);
    dateTo.setMinutes(minutesTo);
    dateTo.setSeconds(secondsTo);
    this.timeTo = dateTo
  }
}
export class ResPackageDayByDayItem extends SafeBaseObject {
  packageItineraryDayByDayID=0
  packageID=0
  day=0
  mealID=''
  mealIds:number[]=[]
  itineraryTypeID=0
  itineraryDayTitle=''
  companyID=0
  sequence=0
  isCruise=false
  geoID=0
  voyageID=0
  packageLinkID=0
  details:ResPackageDayByDayItemDetails[]=[]
  voyageNumber = ''

  constructor(data?:any){
    super()
    this.updateData(data)
  }
  override updateData(data?:any) {
    if(!data) return
    super.updateData(data)
    this.mealIds = this.mealID.split(',').filter(item=>item!=='').map(item=>+item)
  }

  loadDetails(data:any[]) {
    this.details = data.filter(item=>item.PackageItineraryDayByDayID == this.packageItineraryDayByDayID).map(item=> new ResPackageDayByDayItemDetails(item))
    this.details.forEach((item,idx)=> {
      item.sequence=idx+1;
      item.day = this.day
      item.packageItineraryDayID = item.packageItineraryDayByDayDetailID
  })
  }
  static createItems(items:any[],allDetails:any[]) {
    return items.map(p => {
      const item = new ResPackageDayByDayItem(p)
      item.loadDetails(allDetails)
      return item
    })
  }
}

export class ResPackageDayByDay {
  proposal:ResPackageDayByDayItem[]=[]
  operational:ResPackageDayByDayItem[]=[]
  others:ResPackageDayByDayItem[]=[]

  constructor(data?:any)   {
    if(!data) return
    const allItems:any[] = data.DT0
    const allDetails:any[] = data.DT1

    this.proposal = ResPackageDayByDayItem.createItems(allItems.filter(item=>item.ItineraryTypeID===1), allDetails)
    this.operational = ResPackageDayByDayItem.createItems(allItems.filter(item=>item.ItineraryTypeID===2),allDetails)
    this.others = ResPackageDayByDayItem.createItems(allItems.filter(item=>item.ItineraryTypeID!==1 && item.ItineraryTypeID!==2),allDetails)
  }

}

export interface IResAPIPackageDayByDayItem {
  PackageItineraryDayByDayID?: number;
  PackageID?: number;
  Day?: number;
  MealID?: string;
  ItineraryTypeID?: number;
  ItineraryDayTitle?: string;
  CompanyID?: number;
  Sequence?: number;
  IsCruise?: string;
  GeoID?: number;
  VoyageID?: number;
  PackageLinkID?: number;
  Status?: string;
}

export interface IResAPIPackageDayByDayItemDetails {
  PackageItineraryDayByDayDetailID?: number;
  PackageItineraryDayByDayID?: number;
  PackageID?: number;
  Sequence?: number;
  CityIDFrom?: number;
  CityIDTo?: number;
  CityIDExtension?: number;
  MovingTypeID?: number;
  TimeFrom?: Date;
  TimeTo?:  Date;
  GenericTimeFrom?: number;
  GenericTimeTo?: number;
  Description?: string;
  Status?: string;
  Day?: number;
  DurationHour?: number;
  DurationMin?: number;
  FlagPrevDay?: string;
  HideInWebsite?: string;
}
export interface IResApiManageDayByDayItinerary {
  PackageID?: number;
  PkgDayByDay?: IResAPIPackageDayByDayItem[] ;
  PkgDayByDayDet?: IResAPIPackageDayByDayItemDetails[]
}


export class MarketingStatus extends SafeBaseObject {
  cod = ''
  created = DateTime.invalid('empty')
  createdBy = ''
  desc = ''
  packageLookupID = 0
  position = 0
  scope = ''
  status = ''
  updated = DateTime.invalid('empty')
  updatedBy = ''

  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);

  }
}
export class HlTypes extends SafeBaseObject {
  cod = ''
  created = DateTime.invalid('empty')
  createdBy = ''
  desc = ''
  packageLookupID = 0
  position = 0
  scope = ''
  status = ''
  updated = DateTime.invalid('empty')
  updatedBy = ''

  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);

  }
}
export class PackageHighlight extends SafeBaseObject {
  packageHighlightsID = 0
  packageID = 0
  packageHighlightsTypeID = 0
  packageHighlightsDesc = ''
  position = 0
  status = 'A'
  scope = ''
  cityID = 0
  kicker = ''
  fileID = 0
  fileGuid = ''
  fileName = ''

  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);
  }
}
export class PackageHighlightType extends SafeBaseObject {
  packageHighlightsDesc = ''
  packageHighlightsTypeID = 0
  scope = ''
  packageHighlightsTypeDesc = ''
  kicker = ''

  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);
  }
}
export class IPackageHighlight  {
  PackageHighlightsID?: number;
  packageID?: number;
  PackageHighlightsTypeID?: number = 0
  PackageHighlightsDesc?: string = ''
  Position?: number = 0
  Status?: string = 'A' // A for create or edit o D for delete
  Scope?: string = 'Package'
  CityID?: number = 0
  Kicker?: string = ''
  FileID?: number = 0
  FileGuid?: string = ''
  FileName?: string = ''
  FileStatus?: string = ''
}

export interface IPackageItineraryDay2AKP  {
  PackageItineraryDay2AKPID?: number;
	PackageID: number;
	PackageItineraryDayID?: number;
	ProjectID?: number;
	LocationID?: number;
  Status?: string
}
export interface IPackageDayLocationsByProject  {
	ProjectID?: number;
	LocationID?: number;
  Location?: string;
}
export interface IPackageDayProjects  {
	ProjectID?: number;
	Project?: string;
}

export class PackageItineraryDay2AKP extends SafeBaseObject {
  packageItineraryDay2AKPID = 0
	packageID = 0
	packageItineraryDayID = 0
	projectID = 0
  project = ''
	locationID = 0
  location = ''
  status = 'A'
  locations:PackageDayLocation[] = []
  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);
  }
}
export class PackageDayLocation extends SafeBaseObject {
  projectID = 0
  locationID = 0
  location = ''
  description	= ''
  countryID	= 0
  country	= ''
  city = ''
  address1 = ''
  address2	= ''
  zip1 = ''
  zip2 = ''
  latitude = ''
  longitude	 = ''
  created	= DateTime.invalid('empty')
  startDate	= DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')
  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);
  }
}
export class PackageDayProject extends SafeBaseObject {
  projectID	= 0
  project	= ''
  description	= ''
  startDate =	DateTime.invalid('empty')
  endDate	= DateTime.invalid('empty')
  projectLeadID	= 0
  projectLead = ''
  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);
  }
}

export interface IReservationApiPackageHeader {
  PackageID?: number;
  PackageTypeID?: number;
  PackageTypeName?: string;
  PackageShortName?: string;
  PackageLongName?: string;
  PackageName?: string;
  EscortInc?: string;
  GuideInc?: string;
  DriverInc?: string;
  Occupancy?: number;
  CityID?: number;
  Duration?: number;
  DurationUDM?: string;
  MinPax?: number;
  MaxPax?: number;
  PackagePicture?: string;
  PackageECMDescription?: string;
  PackageBrochure?: string;
  PackageInclusion?: string;
  PackageExclusion?: string;
  CancellationPolicyID?: number;
  Currency?: string;
  PriceTypeID?: number;
  PriceTypeName?: string;
  GuestFrom?: number;
  GuestTo?: number;
  SellingPrice?: number;
  Meal?: string;
  CategoryCod?: string;
  Status: string;
  PhysicalLevelCod?: string;
  KidFriendly?: string;
  HideGuest?: ReservationApiBoolean;
  HasBkg?: ReservationApiBoolean;
  HasBundle?: string;
  ExtendJournyes?: IReservationExtendJournyes[];
  VoyageID?: number;
  VoyageNumber?: string;
  VoyageDay?: number;
  FormattedTravels: string
}

export interface IReservationApiPackageSetup {
  PackageID?: number;
  Start?: string;
  End?: string;
  MinPax?: number;
  MaxPax?: number;
  DefaultMargin?: number;
  Occupancy?: number;
  ChildReduction?: number;
  OnlyEven?: string;
  EscortInc?: string;
  DriverInc?: string;
  GuideInc?: string;
  NrDecimal?: number;
  TransportNeeded?: string;
  Duration?: number;
  DurationMin?: number;
  CancellationPolicyID?: number;
  CityID?: number;
  City?: string;
  Currency?: string;
  CountryCod?: string;
  CountryID?: number;
  DurationUDM?: string;
  PackageShortName?: string;
  PackageLongName?: string;
  PackageImportantNotes: string;
  InternalNotes: string;
  PackageExclusion?: string;
  PackageInclusion?: string;
  PackageTypeID?: number;
  PackageTypeName?: string;
  PackageName?: string;
  WebSiteVisibilityFrom?: string;
  WebSiteVisibilityTo?: string;
  ItineraryDays?: IReservationApiPackageItineraryDay[];
  PackagePrice?: IReservationApiPackagePrice[];
  Options?: IReservationApiPackageOption[];
  TravelDates?: IReservationApiPackageTravelDate[];
  PriceTypes?: IReservationApiPackagePriceType[];
  PriceType?: IReservationApiPackagePriceType[];
  EcmData?: Array<IReservationApiPackageEcmData>;
  Brands?: {
    BrandID: number;
    BrandName: string;
  }[];
  Categories?: {
    CategoryCod: string;
    Category: string;
  }[];
  Companies?: {
    CompanyID: number;
    CompanyName: string;
  }[];
  Ports: IReservationApiPackageLinkedPort[];
  Meal?: string;
  CategoryCod?: string;
  Category?: string;
  Status: string;
  PhysicalLevelCod?: string;
  PhysicalLevel?: string;
  KidFriendly?: string;
  HideGuest?: ReservationApiBoolean;
  IsBooked?: ReservationApiBoolean;
  HasBundle?: string;
  ExtendJournyes?: IReservationExtendJournyes[];
  MarketingStatus?: string;
  HighlightsNum?: number;
  HighlightsTypeCod?: number;
  IntAirNotes?: string;
  // IntAirRouting?: string;
  // Accessibility?: string;
  // FirstGrpEvent?: string;
  // LastGrpEvent?: string;
  // GTYDept?: string;
  IncludesInternalAir?: string
  MainDestinationID?: number
  ActivityTypeCod?: string
  PriceNotes?: string
  JetID?: number
  Jet?: string
}

export class ResPackagePrice extends SafeBaseObject {
  agencyID: number = 0;
  packageID: number = 0;
  packagePriceID: number = 0;
  packageTravelDateID: number = 0;
  guestFrom: number = 0;
  guestTo: number = 0;
  validityStartDate: DateTime = DateTime.min();
  validityEndDate: DateTime = DateTime.min();
  sellingPriceCurrency: string = '';
  sellingPrice: number = 0;
  childSellingPrice: number = 0;
  childReduction: number = 0;
  forcedPrice: 'Y' | 'N' = 'N';
  priceSingle: number | undefined;
  priceDouble: number | undefined;
  priceExtraGuest: number | undefined;
  priceChild: number | undefined;
  isEmpty = false;

  constructor(data?: IReservationApiPackagePrice) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiPackagePrice): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('validityStartDate', data, 'ValidityStartDate');
    this.addDateTimeProperty('validityEndDate', data, 'ValidityEndDate');
    this.isEmpty = this.priceSingle === 0 && this.priceDouble === 0 && this.priceExtraGuest === 0 && this.priceChild === 0;
  }
}
export class BookingCreatedBy extends SafeBaseObject {
  displayName = ''
  login = ''

  constructor(data: any) {
    super();
    if (!data) return

    super.updateData(data);
    this.updateData(data);
    this.displayName = data.Display_name
  }
}
export interface IReservationApiPackagePrice {
  PackagePriceID?: number;
  PackageID?: number;
  PackageTravelDateID?: number;
  PriceTypeID?: number;
  GuestFrom?: number;
  GuestTo?: number;
  Margin?: number;
  SellingPriceCurrency?: string;
  SellingPrice?: number;
  ChildSellingPrice?: number;
  ChildReduction?: number;
  ValidityStartDate?: string;
  ValidityEndDate?: string;
  AgencyID?: number;
  ForcedPrice: 'Y' | 'N';
  PriceSingle: number | undefined;
  PriceDouble: number | undefined;
  PriceExtraGuest: number | undefined;
  PriceChild: number | undefined;
}

export class ResPackageUpgradePrice extends SafeBaseObject {

  packageOptionUpgradeID?: number = -1;
  packageID: number = -1;
  packageTravelDateID: number = -1;
  packageOptionID: number = -1;
  priceTypeID: number = -1;
  agencyID?: number;
  day: number = -1;
  serviceID?: number = -1;
  optionID?: number = -1;
  sellingPriceCurrency?: string = '';
  upgradeAmount?: number = -1;
  validityStartDate: DateTime = DateTime.min();
  validityEndDate: DateTime = DateTime.min();

  constructor(data?: IReservationApiPackageUpgradePrice) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiPackageUpgradePrice): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('validityStartDate', data, 'ValidityStartDate');
    this.addDateTimeProperty('validityEndDate', data, 'ValidityEndDate');
  }
}

export interface IReservationApiPackageUpgradePrice {
  PackageOptionUpgradeID?: number;
  PackageID?: number;
  PackageTravelDateID?: number;
  PackageOptionID?: number;
  PriceTypeID?: number;
  AgencyID?: number;
  Day?: number;
  ServiceID?: number;
  OptionID?: number;
  SellingPriceCurrency?: string;
  UpgradeAmount?: number;
}

export interface IReservationApiPackageConfiguratedPrice {
  PackageID: number;
  PackageTravelDateID: number;
  PriceTypeID?: number;
  AgencyID?: number;
  HasBkg?: string
}

export class ResPackageOption extends SafeBaseObject {
  packageID?: number;
  packageOptionID?: number;
  serviceID?: number;
  serviceLongName: string = '';
  serviceShortName: string = '';
  serviceTypeID?: number;
  serviceTypeName: string = '';
  serviceTermsInclusions: string = '';
  serviceTermsExclusions: string = '';
  optionID?: number;
  minPax: number = 0;
  maxPax: number = 0;
  optionName: string = '';
  optionShortName: string = '';
  optionDesc: string = '';
  optionPrice?: any[];
  sortSequence: number = 0;
  priceSetupID?: number;
  priceTypeID?: number;
  day?: number;
  status: string = 'A';
  city: string = '';
  cityID: number = -1;
  quantity: number = 0;
  hideInBkg: boolean = false;
  supplier = ''
  /**
   * Id of the option which is upgraded by this
   *
   * @type {number}
   * @memberof ResPackageOption
   */
  defaultPackageOptionID?: number;
  upgrades: ResPackageOption[] = [];
  canBeUpgraded: boolean = false;
  voyageID = 0

  constructor(data?: IReservationApiPackageOption, upgrades: IReservationApiPackageOption[] = []) {
    super();
    if (data) {
      this.updateData(data, upgrades);
    }
  }

  override updateData(data: IReservationApiPackageOption, upgrades: IReservationApiPackageOption[] = []): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('hideInBkg', data, 'HideInBkg');
    this.upgrades = upgrades.filter(u => u.DefaultPackageOptionID === data.PackageOptionID && !u.VoyageID).map(u => new ResPackageOption(u, upgrades.filter(u => u.DefaultPackageOptionID === data.PackageOptionID && !u.VoyageID)));
  }

  get fullDesc(): string {
    return `${this.serviceTypeName} - ${this.serviceLongName} - ${this.optionName}`;
  }

  get seats(): string {
    return (this.maxPax ?? 0) + ' seats';
  }

  get available(): boolean {
    return this.status?.toLowerCase() === 'a';
  }

  get deleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }
}

interface IReservationApiPackageOption {
  PackageID?: number;
  PackageOptionID?: number;
  ServiceID?: number;
  ServiceLongName?: string;
  ServiceTypeID?: number;
  ServiceTypeName?: string;
  ServiceShortName?: string;
  ServiceTermsInclusions?: string;
  ServiceTermsExclusions?: string;
  OptionID?: number;
  MinPax?: number;
  MaxPax?: number;
  CityId?: number;
  City?: string;
  OptionName?: string;
  OptionShortName?: string;
  OptionDesc?: string;
  OptionPrice?: any[];
  SortSequence?: number;
  PriceTypeID?: number;
  PriceSetupID?: number;
  Status?: string;
  Day?: number;
  Quantity?: number;
  HideInBkg: ReservationApiBoolean;
  CanBeUpgraded?: ReservationApiBoolean;
  DefaultPackageOptionID?: number;
  Supplier?: string
  VoyageID?: number
}

export class ResPackageSingleSupp extends SafeBaseObject {
  packageSnglSuppID = 0
  packageID = 0
  packageTravelDateID = 0
  priceTypeID = 0
  agencyID = 0
  currency = ''
  suiteCategoryID = 0
  suiteCategory = ''
  defaultFormula = false
  snglSuppType = ''
  snglSuppValue = 0
  snglSuppCommPerc = 0
  discountType = ''
  discountValue = 0
  startDate = DateTime.invalid('empty')
  endDate = DateTime.invalid('empty')
  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  override updateData(_?: any): void {
  }
}
export interface IReservationApiGetPackageParams {
  CompanyID?: number;
  PackageID?: number;
  PackageTypeID?: number;
  PackageName?: string;
  Currency?: string;
  // TravelStartDate?: string;
  // TravelEndDate?: string;
  MinPax?: number;
  MaxPax?: number;
  Occupancy?: number;
  CityID?: string;

  // new field after refactor
	CityIDFrom?: number;
	CityIDTo?: number;
	TravelStartDate?: string;
	TravelEndDate?: string;
	PageNum?: number;
	PageSize?: number;
	Username?: string;
	CompaniesID?: string;
	Status?: string;
  PromoID?: number;
}

export interface IReservationApiManagePackageParams {
  PackageID?: number;
  PackageName?: string;
  PackageShortName?: string;
  PackageLongName?: string;
  IsAK?: string;
  Start?: DateTime;
  End?: DateTime;
  MinPax?: number;
  MaxPax?: number;
  TravelStartDate?: string;
  TravelEndDate?: string;
  Duration?: number;
  DurationMin?: number;
  DurationUDM?: string;
  Occupancy?: number;
  VoyageID?: number;
  VoyageDay?: number;
  /**
   * IDs joined with comma
   *
   * @type {string}
   * @memberof IReservationApiManagePackageParams
   */
  BrandID?: string;
  CompanyID?: string;
  ChildReduction?: number;
  EscortInc?: ReservationApiBoolean;
  DriverInc?: ReservationApiBoolean;
  GuideInc?: ReservationApiBoolean;
  TransportNeeded?: ReservationApiBoolean;
  PackageInclusion?: string;
  PackageImportantNotes?: string;
  InternalNotes?: string;
  PackageExclusion?: string;
  PackageTypeID?: number;
  CancellationPolicyID?: number;
  CityIDFrom?: number;
  CityIDTo?: number;
  Currency?: string;
  DefaultMargin?: number;
  OnlyEven?: string;
  NrDecimal?: number;
  Status?: string;
  Meal?: string;
  CategoryCod?: string;
  PhysicalLevelCod?: string;
  KidFriendly?: string
  KidFriendlyTo?: string
  HideGuest?: ReservationApiBoolean;
  WebSiteVisibilityFrom: DateTime;
  WebSiteVisibilityTo: DateTime;
  TravelPartnerVisibilityFrom: DateTime;
  TravelPartnerVisibilityTo: DateTime;
  MainPackageID?: number;
  MarketingStatus?: string | string[];
  HighlightsNum?: number;
  HighlightsTypeCod?: number;
  IntAirNotes?: string;
  // IntAirRouting?: string;
  // Accessibility?: string;
  // FirstGrpEvent?: string;
  // LastGrpEvent?: string;
  // GTYDept?: string;
  IncludesInternalAir?: string
  MainDestinationID?: number
  ActivityTypeCod?: string
  PriceNotes?: string
  JetID?: number
}

export interface IReservationApiManagePackageOptionsParams {
  PackageOptionID?: number;
  PackageID?: number;
  ServiceID?: number;
  OptionID?: number;
  StartDate?: DateTime;
  EndDate?: DateTime;
  SortSequence?: number;
  PriceSetupID?: number;
  Day?: number;
  Status?: string;
  DefaultPackageOptionID?: number;
  Quantity: number;
  HideInBkg: ReservationApiBoolean
}

export enum ResPackageOptionAvailability {
  None,
  Loading,
  Ok,
  Warning,
  Error
}

export interface IReservationAvailibilityPackageOptions {
  serviceID: number;
  optionID: number;
  date: DateTime;
  day: number;
  availability: ResPackageOptionAvailability;
  priceTypeID: number;
}

export enum ReservationPackageSetupActionType {
  SavePrices,
  CloneDate,
  CloneOnAllPriceType,
  SaveSingleSupp
}
export enum ReservationPackageSetupEventType {

}
export interface IReservationPackageSetupEvent {
  type: ReservationPackageSetupEventType
}
export class ReservationPackageOptionAvailibility {
  serviceID: number = -1;
  optionID: number = -1;
  day: number = -1;
  priceTypeAvs: ReservationPackageOptionPriceTypeAvailibility[] = [];

  get isAvailable(): boolean {
    return this.priceTypeAvs.every(av => av.availability === ResPackageOptionAvailability.Ok);
  }

  get isNotAvailable(): boolean {
    return this.priceTypeAvs.every(av => av.availability === ResPackageOptionAvailability.Error);
  }

  get availability(): ResPackageOptionAvailability {
    if (this.isAvailable) {
      return ResPackageOptionAvailability.Ok;
    }

    if (this.isNotAvailable) {
      return ResPackageOptionAvailability.Error;
    }

    if (this.priceTypeAvs.some(av => av.availability === ResPackageOptionAvailability.Ok) && this.priceTypeAvs.some(av => av.availability === ResPackageOptionAvailability.Error)) {
      return ResPackageOptionAvailability.Warning;
    }

    return ResPackageOptionAvailability.None;
  }

  constructor(serviceID: number, optionID: number, day: number, avs: ReservationPackageOptionPriceTypeAvailibility[]) {
    this.serviceID = serviceID;
    this.optionID = optionID;
    this.day = day;
    this.priceTypeAvs = avs;
  }
}

export class ReservationPackageOptionPriceTypeAvailibility extends SafeBaseObject {
  serviceID: number = -1;
  optionID: number = -1;
  date: DateTime = DateTime.min();
  onHoldQty: number = -1;
  priceTypeID: number = -1;
  initialQty: number = -1;
  loading: boolean = false;

  get availability(): ResPackageOptionAvailability {

    if (this.loading) {
      return ResPackageOptionAvailability.Loading;
    }

    if (this.priceTypeID !== -1 && this.onHoldQty >= 0) {
      return this.onHoldQty === 0 ? ResPackageOptionAvailability.Error : ResPackageOptionAvailability.Ok;
    } else {
      return ResPackageOptionAvailability.None;
    }
  }

  constructor(data?: IReservationApiPackageOptionPriceTypeAvailibility) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiPackageOptionPriceTypeAvailibility): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty("date", data, "Date");
  }
}

export interface IReservationApiPackageOptionPriceTypeAvailibility {
  ServiceID: number;
  OptionID: number;
  Date: string;
  InitialQty: number;
  OnHoldQty: number;
  PriceTypeID: number;
}

export interface IReservationApiPackageOptionPriceTypeAvailibilityParams {
  ServiceID: number;
  OptionID: number;
  Date: string;
  PriceTypeID?: number;
}
export interface IReservationExtendJournyes {
  ExtendJourneyID: number
  PackageID: number;
  PackageTravelDateIDFrom: number;
  PackageIDTo: number;
  TypeTo: string;
  TypeFrom: string;
  Extend: string;
  PackageTypeID: number;
  PackageTravelDateIDTo: number;
  PrePostFlag: string;
  TravelStartDate?: DateTime;
  TravelEndDate?: DateTime;
  StartDate?: DateTime;
  EndDate?: DateTime;
  Stopovers?: boolean;
}
export class ReservationExtendJournyes extends SafeBaseObject {
  extendJourneyID = 0
  typeFrom = ''
  idFrom = 0
  packageTravelDateIDFrom?: number;
  typeTo = '';
  extend = '';
  idTo = 0;
  packageTypeID?: number;
  packageTravelDateIDTo?: number;
  travelStartDate = DateTime.invalid('not defined');
  travelEndDate = DateTime.invalid('not defined');
  prePostFlag = '';
  startDate = DateTime.invalid('not defined');
  endDate = DateTime.invalid('not defined');
  status = 'A';
  packageID = 0;
  stopovers = false;

  get describeTour() {

    return this.extend
      +
      (this.travelStartDate.isValid ?
        ' (' + this.travelStartDate.toFormat('d LLL yy') + ')'
        :
        (this.packageTravelDateIDTo ? ' (' + this.packageTravelDateIDTo + ')' : '')
      )
  }
  constructor(data?: IReservationExtendJournyes) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }

  override updateData(data: IReservationExtendJournyes): void {
    this.addMangledProperty(data);
    this.idFrom = data.PackageID;
    this.idTo = data.PackageIDTo;
    this.packageID = data.PackageIDTo;

  }
}
export class ResPackageTravelDate extends SafeBaseObject {

  private _package?: ResPackage;

  packageID: number = 0;
  packageTravelDateID: number = 0;
  travelStartDate: DateTime = DateTime.local();
  travelEndDate: DateTime = DateTime.local();
  travelTime: DateTime | undefined = DateTime.local();
  voyageID: string = '';
  voyageNumber: string = '';
  voyages: { voyageID: string, voyageNumber: string }[] = [];
  tours: ReservationExtendJournyes[] = [];
  toursTailorMade: ReservationExtendJournyes[] = [];
  notes: string = '';
  status: string = 'A';
  sellingPrice: number = 0;
  currency: string = '';
  isBooked: boolean = false;
  hasBkg: boolean = false;
  formattedTravelStartDate: string = '';
  formattedTravelEndDate: string = '';

  initialQtySingle: number = 0;
  onHoldQtySingle: number = 0;
  initialQty: number = 0;
  onHoldQty: number = 0;
  initialQtyTriple: number = 0;
  onHoldQtyTriple: number = 0;

  availabilityBackgroundColor: string = '';
  availabilityMessage: string = '';
  marketingStatus: string[] = [];
  packageFootnoteID: number = 0;
  availabilityStatusCod = '';

  _afternoon: boolean;
  get afternoon(): boolean {
    return this._afternoon;
  }
  set afternoon(value: boolean) {
    if (this._afternoon === value) return
    this._afternoon = value;
    this.travelTime = value ? DateTime.fromObject({ hour: 13, minute: 0 }) : DateTime.fromObject({ hour: 8, minute: 0 });
  }

  get isOneDayTravelDate(): boolean {
    return this.travelStartDate.toISODate() === this.travelEndDate.toISODate();
  }

  describePackage: string;
  describePackageHtml: SafeHtml;

  get disallowActionForBookedPackage(): boolean {

    return this.isBooked;
    //return !!this._package?.disallowActionForBookedPackage;
  }

  private coreFormatService: CoreFormatService;
  private sanitaizer: DomSanitizer;

  constructor(data?: IReservationApiPackageTravelDate, pkg?: ResPackage) {
    super();
    if (data) super.updateData(data);
    this.coreFormatService = AppModule.injector.get(CoreFormatService);
    this.sanitaizer = AppModule.injector.get(DomSanitizer);
    this._package = pkg;
    if (data) this.updateData(data);

    this._afternoon = (this.travelTime?.hour ?? 0) >= 13;
    this.describePackage = this.describe();
    this.describePackageHtml = this.describeHtml();

    if(data?.MarketingStatus) data.MarketingStatus?.length > 0 ? this.marketingStatus = data.MarketingStatus.split(',') : []

  }

  override updateData(data: IReservationApiPackageTravelDate): void {
    // this.addMangledProperty(data);
    // this.addDateTimeProperty("travelStartDate", data, "TravelStartDate");
    // this.addDateTimeProperty("travelEndDate", data, "TravelEndDate");

    this.formatDateTimeProperties(this.coreFormatService);
    if (data.TravelTime) this.travelTime = DateTime.fromFormat(data.TravelTime, 'HH:mm:ss');
    if (this.voyageID) this.voyages = this.voyageID.split(',').map((voyageID, index) => ({ voyageID: voyageID.trim(), voyageNumber: this.voyageNumber?.split(',')[index].trim() }));
    if (this._package) {
      this.tours = this._package.extendJournyes.filter(ej => ej.typeTo == 'PKG' && ej.packageTypeID !== ResPacakgeTypeEnum.TailorMade && ej.packageTravelDateIDFrom === this.packageTravelDateID);
      this.toursTailorMade = this._package.extendJournyes.filter(ej => ej.typeTo == 'PKG' && ej.packageTypeID === ResPacakgeTypeEnum.TailorMade);
    }
  }

  get notSaved(): boolean {
    return this.packageTravelDateID === 0;
  }

  get available(): boolean {
    return this.status?.toLowerCase() === 'a';
  }

  get deleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }
  describe(): string {
    return this.travelStartDate.toFormat('dd MMM yyyy') + ' - ' + this.coreFormatService.CurrencyAmount(this.sellingPrice, this.currency);
  }
  describeHtml(tagContainer: string = 'span', cssContainer: string = 'text-nowrap'): SafeHtml {
    const html =
      `<${tagContainer} class="${cssContainer} mr-3">
      ${this.travelStartDate.toFormat('dd MMM yyyy')}
    </${tagContainer}>
     -
    <${tagContainer} class="${cssContainer} mr-3">
     ${this.coreFormatService.CurrencyAmount(this.sellingPrice, this.currency)}
    </${tagContainer}>
    <${tagContainer} class="${cssContainer} mr-3" style="font-size:0.8rem;color:#800;">
      (Per Person)
    </${tagContainer}>`;
    return this.sanitaizer.bypassSecurityTrustHtml(html);
  }
  describeTravel(): string {
    return 'From ' + this.travelStartDate.toFormat('dd MMM yyyy') + ' To ' + this.travelEndDate.toFormat('dd MMM yyyy');
  }

  setDescribePackage(tagContainer: string = 'span', cssContainer: string = 'text-nowrap') {
    this.describePackage = this.describe();
    this.describePackageHtml = this.describeHtml(tagContainer, cssContainer)
  }
  setPackage(p: ResPackage) {
    this._package = p;
  }
}

export interface IReservationApiPackageTravelDate {
  PackageID?: number;
  PackageTravelDateID?: number;
  TravelStartDate?: string;
  TravelEndDate?: string;
  TravelTime?: string;
  Notes?: string;
  Status?: string;
  SellingPrice?: number;
  Currency?: string;
  InitialQtySingle?: number;
  OnHoldQtySingle?: number;
  InitialQty?: number;
  OnHoldQty?: number;
  InitialQtyTriple?: number;
  OnHoldQtyTriple?: number;
  AvailabilityBackgroundColor?: string;
  AvailabilityMessage?: string;
  VoyageID?: number;
  IsBooked?: ReservationApiBoolean;
  HasBkg?: ReservationApiBoolean;
  MarketingStatus?: string;
  PackageFootnoteID?: number;
  AvailabilityStatusCod?: string;
}

export class ResPackagePriceType extends SafeBaseObject {
  packageID: number = ResPackage.INVALID_PACKAGE_ID;
  isAgencyMandatory: boolean = false;
  defaultPriceType: boolean = false;
  companyID = 999;
  priceTypeID?: number;
  priceTypeName: string = '';
  priceTypeCod: string = '';

  constructor(data?: IReservationApiPackagePriceType) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiPackagePriceType): void {
    this.addMangledProperty(data);
    this.addBooleanProperty('isAgencyMandatory', data, 'IsAgencyMandatory');
    this.defaultPriceType = !!data.DefaultPrice;
  }
}

interface IReservationApiPackagePriceType extends IReservationPriceType {
  PackageID?: number;
  DefaultPrice?: number;
}

export interface IReservationApiManagePackageTravelDatesParams {
  PackageTravelDates: IReservationApiPackageTravelDate[];
}

export class ResPackageOptionCost extends SafeBaseObject {

  packageOptionCostID?: number;
  packageOptionID: number = -1
  packageTravelDateID: number = -1;
  packageID?: number;
  itineraryDate?: DateTime;
  travelStartDate?: DateTime;
  serviceID?: number;
  optionID?: number;
  optionName?: string;
  priceTypeID?: number;
  costCurrency?: string;
  cost?: number;
  retailPrices?: IResPackageSetupRetailPrice;
  costTax?: number;
  rOE?: number;
  margin?: number;
  guideCost?: number;
  escortCost?: number;
  driverCost?: number;
  validityStartDate?: DateTime;
  validityEndDate?: DateTime;
  priceSetupID?: number;
  packageCurrency?: string;
  forcedCost: 'Y' | 'N' = 'N';
  status: 'A' | 'D' = 'A';
  quantity?: number = 0;
  agencyID?: number;
  supplierName?: string;
  serviceTypeName?: string;

  constructor(data?: IReservationApiPackageOptionCost) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiPackageOptionCost): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('travelStartDate', data, 'TravelStartDate');
    this.addDateTimeProperty('itineraryDate', data, 'ItineraryDate');
    this.addDateTimeProperty('validityStartDate', data, 'ValidityStartDate');
    this.addDateTimeProperty('validityEndDate', data, 'ValidityEndDate');
    this.forcedCost = data.ForcedCost === 'Y' ? 'Y' : 'N';
    this.status = data.Status === 'D' ? 'D' : 'A';

    this.retailPrices = {
      priceSingle: 0,
      priceDouble: 0,
      priceExtra: 0,
      priceChild: 0,
    }
    if (data.PriceSingle) this.retailPrices.priceSingle = +(data.PriceSingle ?? 0)
    if (data.PriceDouble) this.retailPrices.priceDouble = +(data.PriceDouble ?? 0)
    if (data.PriceExtraGuest) this.retailPrices.priceExtra = +(data.PriceExtraGuest ?? 0)
    if (data.PriceChild) this.retailPrices.priceChild = +(data.PriceChild ?? 0)
  }
}

export interface IReservationApiPackageOptionCost {
  PackageOptionCostID?: number;
  PackageOptionID?: number;
  PackageTravelDateID?: number;
  PackageID?: number;
  ItineraryDate?: string;
  TravelStartDate?: string;
  ServiceID?: number;
  OptionID?: number;
  OptionName?: string;
  PriceTypeID?: number;
  CostCurrency?: string;
  PriceSingle?: number | string;
  PriceDouble?: number | string;
  PriceExtraGuest?: number | string;
  PriceChild?: number | string;
  Cost?: number | string;
  CostTax?: number | string;
  ROE?: number | string;
  Margin?: number | string;
  GuideCost?: number | string;
  EscortCost?: number | string;
  DriverCost?: number | string;
  ValidityStartDate?: string;
  ValidityEndDate?: string;
  PriceSetupID?: number;
  PackageCurrency?: string;
  ForcedCost: 'Y' | 'N';
  Status: 'A' | 'D';
  Quantity?: number;
  AgencyID: number | undefined;
}

export interface IReservationApiPackageOptionPriceParams {
  PackagePriceID?: number;
  PackageID?: number;
  PackageTravelDateID?: number;
  PriceTypeID?: number;
  GuestFrom?: number;
  GuestTo?: number;
  Margin?: number;
  SellingPriceCurrency?: string;
  SellingPrice?: string;
  ChildSellingPrice?: string;
  ChildReduction?: number;
  ValidityStartDate?: DateTime;
  ValidityEndDate?: DateTime;
  AgencyID?: number;
  ForcedPrice: ReservationApiBoolean;
  PriceSingle?: number | undefined,
  PriceDouble?: number | undefined,
  PriceExtraGuest?: number | undefined,
  PriceChild?: number | undefined
}

export interface IReservationApiPackageUpgradePriceParams {
  PackageOptionUpgradeID: number | undefined;
  PackageID: number;
  PackageTravelDateID: number;
  PackageOptionID: number;
  PriceTypeID: number;
  AgencyID?: number;
  ValidityStartDate: string;
  ValidityEndDate: string;
  SellingPriceCurrency: string;
  Day: number;
  ServiceID: number;
  OptionID: number;
  PriceSingle: number;
  PriceDouble?: number;
  PriceExtra?: number;
  PriceChild?: number;
  Status: 'A' | 'D';
}

export class ResPackageItineraryDay extends SafeBaseObject {
  packageID?: number;
  packageItineraryDayID?: number;
  cities: number[] = [];
  city?: string;
  itineraryDayDesc?: string;
  itineraryDaySequence?: number;
  genericTimeFrom?: number
  genericTimeTo?: number
  timeFrom?: DateTime;
  timeTo?: DateTime;
  itineraryTypeID?: number;
  itineraryTypeName?: string;
  day?: number;
  itineraryDayText?: string;
  mealID?: string;
  meals: number[] = [];
  status?: string;
  voyageID?: number;
  voyageNumber?: string;
  isCruise?: string;

  PROPOSAL_ITINERARY_TYPE_ID = 1;
  OPERATIONAL_ITINERARY_TYPE_ID = 2;

  // fe logic
  cityID?: number;

  constructor(data?: IReservationApiPackageItineraryDay) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiPackageItineraryDay): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('timeFrom', data, 'TimeFrom');
    this.addDateTimeProperty('timeTo', data, 'TimeTo');
    this.cities = (data.ItineraryDaysCities ?? []).map(c => c.CityID);
    this.meals = data.MealID ? data.MealID.split(',').map(id => +id) : [];
    if (this.isOperational) this.cityID = this.cities[0];
  }

  get notSaved(): boolean {
    return !this.packageItineraryDayID;
  }

  get isProposal(): boolean {
    return this.itineraryTypeID === this.PROPOSAL_ITINERARY_TYPE_ID;
  }

  get isOperational(): boolean {
    return this.itineraryTypeID === this.OPERATIONAL_ITINERARY_TYPE_ID;
  }

  get deleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }

  get available(): boolean {
    return this.status?.toLowerCase() === 'a';
  }
}

export interface IReservationApiPackageItineraryDay {
  PackageID?: number;
  PackageItineraryDayID?: number;
  CityID?: number | string;
  City?: string;
  ItineraryDayDesc?: string;
  ItineraryDaySequence?: number;
  GenericTimeFrom?: number
  GenericTimeTo?: number
  TimeFrom?: string;
  TimeTo?: string;
  ItineraryTypeID?: number;
  ItineraryDayText?: string;
  Day: number;
  Status?: string;
  ItineraryDaysCities?: {
    PackageID: number;
    PackageItineraryDayID: number;
    CityID: number;
    City: string;
  }[];
  MealID?: string;
  VoyageID?: number;
}

export class ResPackagePort extends SafeBaseObject {
  packageToPortID: number = 0;
  packageID: number = 0;
  cityID: number = 0;
  status: string = '';
  city: string = '';

  constructor(data?: IReservationApiPackageLinkedPort) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiPackageLinkedPort): void {
    this.addMangledProperty(data);
  }

  get deleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }

  get available(): boolean {
    return this.status?.toLowerCase() === 'a';
  }
}

export interface IReservationApiPackageLinkedPort {
  PackageToPortID?: number;
  PackageID?: number;
  CityID?: number;
  City?: string;
  Status?: string;
}

export interface IReservationApiManagePackageItineraryDaysParams {
  PackageItineraryDay: IReservationApiPackageItineraryDay[];
  PackageID: number;
}

export interface IReservationApiPricesCloneDatesParams {
  PackageOptionCost: IReservationApiPackageOptionCost[]
  PackagePrice: IReservationApiPackageOptionPriceParams[]
  PackageOptionUpgrade: IReservationApiPackageUpgradePriceParams[]
  PackageTravelDate: string
  PriceTypes: string
}
export interface IReservationApiManagePackageLinkedPortsParams {
  PackageToPort: IReservationApiPackageLinkedPort[];
}

export interface IReservationApiManagePackageLinkedVoyagesParams {
  PackageVoyage: IReservationApiLinkedVoyage[];
}
export interface IReservationPackageExtend {
  PackageExtendID?: number;
  ExtendType: string;
  ID: number;
  PrePost?: string;
  StartDate?: Date;
  EndDate?: Date;
  Status: string;
}
export interface IReservationApiManagePackageLinkedToursParams {
  PackageID: number;
  PackageExtend: IReservationApiPackageExtendParams[];
}
export interface IReservationApiLinkedVoyage {
  PackageID: number;
  PackageTravelDateID: number;
  VoyageID: string;
  Status: string;
}

export interface IReservationPackageOptionSpecialItem {
  ServiceID?: number;
  OptionID?: number;
  OptionName: string;
  OptionCod: string;
}

export interface IReservationApiBookPackageParams {
  Currency: string;
  PriceTypeCod?: string;
  Action: string;
  OwnerType?: string;
  IndividualID?: number;
  BrandID?: number;
  PackageID?: number;
  Adult?: number;
  Child?: number;
  PackageTravelDateID?: number;
  IsRetail: ReservationApiBoolean;
  Accomodations:ReservationAccomodation[]
  AgencyID?: number
  BkgID?: number
  NewSuite: IReservationSuiteSelectionDialogComponentConfigSuites[]
  Guests: Array<IReservationApiParametersGuest>;
}

export class ResPackageDepScheduling extends SafeBaseObject {
  depositSchedulingID: number = 0;
  packageID: number = 0;
  paymentDueDays: number = 0;
  isFinalPayment: boolean = false;
  amount: number = 0;
  percentage: number = 0;
  currency: string = '';
  status: string = '';

  constructor(data?: IReservationApiPackageDepScheduling) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiPackageDepScheduling): void {
    this.addMangledProperty(data);
  }

  get deleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }

  get available(): boolean {
    return this.status?.toLowerCase() === 'a';
  }
}

export interface IReservationApiPackageDepScheduling {
  DepositSchedulingID?: number;
  PackageID?: number;
  PaymentDueDays?: number;
  IsFinalPayment?: string;
  Amount?: number;
  Percentage?: number;
  Currency?: string;
  Status?: string;
}

export interface IReservationApiManagePackageDepSchedulingParams {
  SchedulingPackage: IReservationApiPackageDepScheduling[];
}

export class ResPackageCancPolicy extends SafeBaseObject {
  cancellationPolicyID: number = 0;
  packageID: number = 0;
  status: string = '';
  details: ResPackageCancPolicyDetail[] = [];

  constructor(data?: IReservationApiPackageCancPolicy) {
    super();
    if (data) this.updateData(data);

    if (data?.Details) this.details = data.Details.map((d) => new ResPackageCancPolicyDetail(d));
  }

  override updateData(data: IReservationApiPackageCancPolicy): void {
    this.addMangledProperty(data);
  }

  get deleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }

  get available(): boolean {
    return this.status?.toLowerCase() === 'a';
  }
}

export interface IReservationApiPackageCancPolicy {
  CancellationPolicyID?: number;
  PackageID?: number;
  Status?: string;
  Details?: IReservationApiPackageCancPolicyDetail[];
}

export class ResPackageCancPolicyDetail extends SafeBaseObject {
  cancellationPolicyDetailID: number = 0;
  cancellationPolicyID: number = 0;
  packageID: number = 0;
  daysFrom: number = 0;
  daysTo: number = 0;
  amountPerc: number = 0;
  fixedAmount: number = 0;
  status: string = '';

  constructor(data?: IReservationApiPackageCancPolicyDetail) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiPackageCancPolicyDetail): void {
    this.addMangledProperty(data);
  }

  get deleted(): boolean {
    return this.status?.toLowerCase() === 'd';
  }

  get available(): boolean {
    return this.status?.toLowerCase() === 'a';
  }
}

export interface IReservationApiPackageCancPolicyDetail {
  CancellationPolicyDetailID?: number;
  CancellationPolicyID?: number;
  PackageID?: number;
  DaysFrom?: number;
  DaysTo?: number;
  AmountPerc?: number;
  FixedAmount?: number;
  Status?: string;
}

export interface IReservationApiManangePackageCanPolicyParams {
  PolicyPackage: IReservationApiPackageCancPolicy;
  PolicyPackageDett: IReservationApiPackageCancPolicyDetail[];
}

export class ReservationPackageTravelDateDetail extends SafeBaseObject {

  packageID = 0;
  packageTravelDateID = 0;
  pkgDuration: number = 0;
  travelStartDate?: DateTime;
  travelEndDate?: DateTime;
  travelTime?: DateTime;
  notes = '';
  onHoldQty: number = -1;
  onHoldQtySingle = -1
  onHoldQtyTriple = -1
  initialQty: number = -1;
  availabilityBackgroundColor: string = '';
  availabilityMessage: string = '';
  sellingPriceCurrency?:string

  services: Array<ReservationProductService> = [];
  pricesPerPax: Array<ReservationProductPrice> = [];
  pricesRetail: ReservationProductPrice[] = [];
  allData: any;

  get formattedTravelStartDate(): string {
    return this.coreFormatService.DateToDefaultDateFormat(this.travelStartDate ?? '' as any);
  }

  get formattedTravelEndDate(): string {
    return this.coreFormatService.DateToDefaultDateFormat(this.travelEndDate ?? '' as any);
  }

  get formattedTravelStartTime(): string {
    return this.travelTime?.toFormat('hh:mm a') ?? ""
  }

  get formattedTravelEndTime(): string {
    return this.travelTime?.plus({ hours: this.pkgDuration })?.toFormat('hh:mm a') ?? ""
  }

  get formattedDates(): string {
    return `${(this.formattedTravelStartDate.split(' ')[2] === this.formattedTravelEndTime.split(' ')[2]) ? this.formattedTravelStartDate.slice(0, -5) : this.formattedTravelStartDate} ${this.formattedTravelStartTime ? '(' + this.formattedTravelStartTime + ')' : ''} - ${this.formattedTravelEndDate} ${this.formattedTravelEndTime ? '(' + this.formattedTravelEndTime + ')' : ''}`;
  }

  private coreFormatService: CoreFormatService;

  constructor(data?: IReservationApiProductCalendar, pkgDuration?: number, allData?: any) {
    super();
    this.coreFormatService = AppModule.injector.get(CoreFormatService);
    this.allData = allData?.body
    this.pkgDuration = pkgDuration ?? 0;
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: any) {
    super.updateData(data);
    this.addDateTimeProperty('travelStartDate', data, 'TravelStartDate');
    this.addDateTimeProperty('travelEndDate', data, 'TravelEndDate');
    this.addDateTimeProperty('travelTime', data, 'TravelTime');

    const prices = data.Prices ?? this.allData?.DT3;
    const services = data.Services ?? this.allData?.DT5;
    this.services = services?.map((s : any) => new ReservationProductService(s)) ?? [];
    // Per pax prices have real GuestFrom and GuestTo properties
    this.pricesPerPax = prices?.filter((p: any) => p.GuestFrom > 0 && p.GuestTo > 0).map((p: any) => new ReservationProductPrice(p)) ?? [];
    if(this.pricesPerPax.length>0) this.sellingPriceCurrency = this.pricesPerPax[0].sellingPriceCurrency
    // Retail price row has GuestFrom == GuestTo == 0 property
    this.pricesRetail = prices?.filter((p: any) => p.GuestFrom === 0 && p.GuestTo === 0)
      // Discard any rows without real configured prices
      .filter((p: any) => !!p.PriceSingle || !!p.PriceDouble || !!p.PriceExtraGuest || !!p.PriceChild || !!p.InternalAir || !!p.PermitPrice)
      // Associa le travelDates ai prezzi
      .filter((p:any) => p.PackageTravelDateID === data.PackageTravelDateID)
      .map((p: any) => new ReservationProductPrice(p)) ?? [];
  }
}
class ReservationProductCalendarMedia extends SafeBaseObject {
  public packageID = 0;
  public packageDayID = 0;
  public fldValue = '';
  public entID = 0;
  public fldCod = '';

  constructor(data?: IReservationApiProductCalendarMedia) {
    super();
    if (data) {
      super.updateData(data);
    }
  }
}

export class ReservationProductItineraryBaseData extends SafeBaseObject {
  public packageDayID = 0;
  public packageID = 0;
  public day = 0;
  public itineraryDaySequence = 0;
  public timeFrom?: DateTime;
  public timeTo?: DateTime
  public genericTimeFrom?: number
  public genericTimeTo?: number
  public itineraryDayDesc = '';
  public itineraryDayText = '';
  public itineraryPicture = '';
  public itineraryECMDesc = '';
  public cityID = 0;
  public city = '';
  public itineraryTypeID = 0;
  public itineraryTypeName: string = '';
  public mealID = '';
  public meals: Array<IReservationPackageMeal> = [];
  public serviceOptions: Array<ReservationProductServiceOptions> = [];
  constructor(data?: IReservationApiProductItinerary) {
    super();
    if (data) {
      super.updateData(data);

      this.addDateTimeProperty('timeFrom', data, 'TimeFrom');
      this.addDateTimeProperty('timeTo', data, 'TimeTo');
    }

  }

  get isProposal(): boolean {
    return this.itineraryTypeID === 1;
  }

  get isOperational(): boolean {
    return this.itineraryTypeID === 2;
  }
}

export class ReservationProductItineraryTourGuide extends SafeBaseObject {
  public packageID = 0
  public peopleID = 0
  public fullName? = ''
  public gender = ''
  public age = 0
  public birthDate = DateTime.invalid('empty')
  public country = ''
  public address1 = ''
  public bio = ''
  public travelStartDate = DateTime.invalid('empty')
  public travelEndDate = DateTime.invalid('empty')
  public description = ''
  public image1 = ''

  constructor(data?: IReservationApiProductTourGuide) {
    super();
    if (data) {
      super.updateData(data);
    }
  }
}

export class ReservationProductItineraryAmbassador extends SafeBaseObject {
  public packageID = 0
  public fullName? = ''
  public gender = ''
  public age = 0
  public birthDate = DateTime.invalid('empty')
  public country = ''
  public address1 = ''
  public peopleTypeDesc = ''
  public peopleDesc = ''
  public description = ''
  public image1 = ''

  constructor(data?: IReservationApiProductAmbassador) {
    super();
    if (data) {
      super.updateData(data);
    }
  }
}

class ReservationProductPrice extends SafeBaseObject {
  packageID = 0;
  priceTypeID = 0;
  brandID = 0;
  agencyID = 0;
  agencyName = '';
  guestFrom = 0;
  guestTo = 0;
  sellingPriceCurrency = '';
  sellingPrice = 0;
  childSellingPrice = 0;
  childReduction = 0;
  packageTravelDateID = 0;
  priceTypeName = '';
  priceTypeCod = '';
  formattedSellingPrice = '';

  priceSingle: number | string = 'N/A'
  priceDouble: number | string = 'N/A'
  priceExtraGuest: number | string = 'N/A'
  priceChild: number | string = 'N/A'
  internalAir: number | string = 'N/A'
  permitPrice: number | string = 'N/A'

  constructor(data?: any) {
    super();
    if (data) {
      super.updateData(data);
      super.updateData(data);
      this.priceSingle = data.PriceSingle ?? 'N/A'
      this.priceDouble = data.PriceDouble ?? 'N/A'
      this.priceExtraGuest = data.PriceExtraGuest ?? 'N/A'
      this.priceChild = data.PriceChild ?? 'N/A'
      this.internalAir = data.InternalAir ?? 'N/A'
      this.permitPrice = data.PermitPrice ?? 'N/A'
    }
  }
}

// class ReservationPackageRetailPrice extends SafeBaseObject {
//   packageID: number = 0;
//   brandID: number = 0;
//   agencyID: number = 0;
//   agencyName: string = '';
//   priceSingle: number = 0;
//   priceDouble: number = 0;
//   priceExtraGuest: number = 0;
//   priceChild: number = 0;
//   packageTravelDateID: number = 0;
//   priceTypeName: string = '';
//   priceTypeID: number = 0;
//   priceTypeCod: string = '';
//   sellingPrice = 0;
//   sellingPriceCurrency = '';


//   guestFrom = 0;
//   guestTo = 0;
//   childSellingPrice = 0;
//   childReduction = 0;


//   constructor(data?: IReservationApiProductPrice) {
//     super();
//     if (data) {
//       super.updateData(data);
//     }
//   }
// }


class ReservationProductPriceType extends SafeBaseObject {
  public priceTypeID = 0;
  public packageID = 0;
  public priceTypeName = '';
  public defaultPrice = 0;
  public isAgencyMandatory = 0;

  constructor(data?: IReservationApiProductPriceType) {
    super();
    if (data) {
      super.updateData(data);
    }
  }
}
export class ReservationProductService extends SafeBaseObject {
  public travelStartDate?: DateTime;
  public packageTravelDateID = 0;
  public packageID = 0;
  public serviceID = 0;
  public serviceLongName = '';
  public serviceDescription = '';
  public servicePicture = '';
  public serviceTypeID = 0;
  public serviceTypeName = '';

  constructor(data?: IReservationApiProductService) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data);
    }
  }
  override updateData(data: IReservationApiProductService) {
    this.addDateTimeProperty('travelStartDate', data, 'TravelStartDate');
  }
}
class ReservationProductServiceMedia extends SafeBaseObject {
  public serviceID = 0;
  public fldValue = '';
  public entID = 0;
  public fldCod = '';

  constructor(data?: IReservationApiProductServiceMedia) {
    super();
    if (data) {
      super.updateData(data);
    }
  }
}

export class ReservationBasePackage extends SafeBaseObject {
  sectionID = 0;
  packageID = 0;
  packageTravelDateID = 0;
  packageTypeID = 0;
  packageTypeName = '';
  packageShortName = '';
  packageName = '';
  isTM = false;
  escortInc = false;
  guideInc = false;
  driverInc = false;
  occupancy = 0;
  cityID = 0;
  duration = 0;
  durationUDM = '';
  minPax = 0;
  maxPax = 0;
  guestFrom = 0;
  guestTo = 0;
  packagePicture = '';
  packageECMDescription = '';
  packageBrochure = '';
  packageInclusion = '';
  packageExclusion = '';
  packageImportantNotes = '';
  internalNotes = '';
  cancellationPolicyID = 0;
  currency = '';
  calendarDate: Array<ReservationPackageTravelDateDetail> = [];
  itinerariesSimple: Array<ReservationProductItineraryBaseData> = [];
  itineraryMedia: Array<ReservationProductCalendarMedia> = [];
  priceType: Array<ReservationProductPriceType> = [];
  servicesMedia: Array<ReservationProductServiceMedia> = [];
  ecmData: ReservationPackageEcmData;
  internalType = 'base-package';

  serviceOptions: Array<ReservationProductServiceOptions> = [];
  serviceImages: Array<ReservationProductServiceImages> = [];
  suggestedItems: Array<ReservationProductSuggestedItems> = [];
  companies: { companyID: number; companyName: string; }[] = [];
  categories: { categoryCod: string; category: string; }[] = [];
  isRetail: boolean = false;
  voyageID = 0;
  voyageNumber = '';
  voyageDay = 0;
  isBooked = false
  status = ''
  selectable = false
  keySuiteID = 0
  roomID = 0
  productType = ''
  isOverNight = false
  isAnchor = false
  ambassadors: Array<ReservationProductItineraryAmbassador> = [];
  tourGuides: Array<ReservationProductItineraryTourGuide> = [];

  //new model data (@TODO when we are ready remenber to remove old data)
  itineraryDaysNew: ResGenericItineraryDay[]=[]
  itineraryDaysDetailsMedia: ResGenericItineraryDayDetailsMedia[] = []

  constructor(data?: any) {
    super();
    this.ecmData = new ReservationPackageEcmData();
    this.updateData(data);
  }

  override updateData(data: any): void {
    if (!data) return

    const pkg = data.body?.DT0[0] || data.body
    super.updateData(pkg);

    const priceTypes =  pkg.PriceType || data.body.DT4
    const ecmData =  pkg.EcmData || data.body.DT6
    const calendarDate =  pkg.CalendarDate || data.body.DT1
    const itineraries =  pkg.Itineraries || data.body.DT2

    if (priceTypes) this.priceType = priceTypes.map((pt: IReservationApiProductPriceType) => new ReservationProductPriceType(pt));
    if (ecmData) this.ecmData = ReservationPackageEcmData.mapEcmData(ecmData);
    if (calendarDate) this.calendarDate = calendarDate.map((c: any) => new ReservationPackageTravelDateDetail(c, data.Duration, data));
    if (itineraries) this.itinerariesSimple = itineraries.map((i: IReservationApiProductItinerary) => new ReservationProductItineraryBaseData(i));
    this.ecmData.loaded = false;
    if (this.packageID > 0) {
      this.loadEcmData(this.packageID).then(_ => {
        if (calendarDate) this.calendarDate = calendarDate.map((c: IReservationApiProductCalendar) => new ReservationPackageTravelDateDetail(c, data.Duration, data));
        if (this.itinerariesSimple.length === 0) this.itinerariesSimple = itineraries.map((i: IReservationApiProductItinerary) => new ReservationProductItineraryBaseData(i));
      })
    }

    //New RS

    const itineraryDaysMedia =  data.body.DT15
    if(itineraryDaysMedia) {
      this.itineraryDaysDetailsMedia = itineraryDaysMedia.map((itineraryDayMedia: any) => new ResGenericItineraryDayDetailsMedia(itineraryDayMedia));
    }

    const itineraryDays =  data.body.DT13
    if(itineraryDays) {
      this.itineraryDaysNew = itineraryDays.map((itineraryDay: any) => new ResGenericItineraryDay(itineraryDay))
    }

    const itineraryDaysDetails =  data.body.DT14
    if(itineraryDaysDetails) {
      itineraryDaysDetails.forEach((itineraryDayDetails: any) => {
        const dayItinerary = new ResGenericItineraryDayDetails(itineraryDayDetails);
        if(this.itineraryDaysDetailsMedia) {
          const foundPackageItineraryDayByDayDetailImg = this.itineraryDaysDetailsMedia.find(itineraryDayDetailsMedia => itineraryDayDetailsMedia.packageItineraryDayByDayDetailID == dayItinerary.packageItineraryDayByDayDetailID && itineraryDayDetailsMedia.fldCod.includes('Image'))?.fldValue
          dayItinerary.packageItineraryDayByDayDetailImg = foundPackageItineraryDayByDayDetailImg ?? "assets/svg/no_image_available.svg"
        }
        const itineraryDayFound = this.itineraryDaysNew.find(itineraryDay => itineraryDay.packageItineraryDayByDayID === dayItinerary.packageItineraryDayByDayID);
        if(itineraryDayFound) {
          itineraryDayFound.itinearyDayDetails.push(dayItinerary)
        }
      });
    }
    const ambassadores =  data.body.DT16
    if(ambassadores) {
      this.ambassadors = ambassadores.map((i: IReservationApiProductAmbassador) => new ReservationProductItineraryAmbassador(i));
    }
    const guides =  data.body.DT17
    if(guides) {
      this.tourGuides = guides.map((i: IReservationApiProductTourGuide) => new ReservationProductItineraryTourGuide(i));
    }
  }
  get sellingPriceCurrency(): string | undefined {
    if(this.calendarDate.length>0) {
      if(this.calendarDate[0].pricesRetail.length>0) return this.calendarDate[0].pricesRetail[0].sellingPriceCurrency
      return this.calendarDate[0].sellingPriceCurrency
    }
    return undefined
  }

  get hasMediaData() {
    return this.ecmData.loaded
  }
  get hasImages() {
    return this.ecmData.images.length > 0
  }
  get hasMap() {
    return !!this.ecmData.imageMapSafe
  }
  get mapUrl() {
    return this.ecmData.imageMapSafe
  }
  get imagesUrl() {
    return this.ecmData.imagesSafe
  }
  get hasHtmlDescription() {
    return !!this.ecmData.descriptionSafe
  }
  get descriptionHtml() {
    return this.ecmData.descriptionSafe
  }
  isLecOrSimilar() {
    return this.packageTypeID === ResPacakgeTypeEnum.Lec || this.packageTypeName === 'Luxury Expedition Cruises'
    || this.packageTypeID === ResPacakgeTypeEnum.LuxurySmallGroupJourneysCruise
    || this.packageTypeID === ResPacakgeTypeEnum.CanalBargeCruises;
  }
  hasStandardFlow() {
    return !this.isLecOrSimilar() && !this.isBookableWithChooseDate();
  }
  isBookableWithChooseDate() {
    return this.packageTypeID === ResPacakgeTypeEnum.Sanctuary
  }
  getPriceTypeID() {
    if (this.priceType.length > 0) return this.priceType[0].priceTypeID;
    if (this.configuredPriceTypes.length > 0) return this.configuredPriceTypes[0].priceTypeID;
    return 3;
  }
  loadEcmData(packageID?: number): Promise<ReservationPackageEcmData> {
    if (this.ecmData.loaded) return Promise.resolve(this.ecmData);
    const reservationApiService = AppModule.injector.get(ReservationApiService);
    const params = { PackageID: packageID ?? this.packageID, PackageTravelDateID: (this.packageTravelDateID !== 0 ? this.packageTravelDateID : undefined) } as IReservationApiPackageInfoParameters
    return lastValueFrom(reservationApiService.getPackageInfo(params).pipe(
      map<IReservationApiPackageInfo, ReservationPackageEcmData>((data) => {
        this.ecmData = ReservationPackageEcmData.mapEcmData(data.EcmData);
        if (data.EcmData) this.ecmData = ReservationPackageEcmData.mapEcmData(data.EcmData);
        if (data.CalendarDate) this.calendarDate = data.CalendarDate.map(c => new ReservationPackageTravelDateDetail(c, 0));
        //@TODO remove form calendarDates these just booked
        if (data.Itineraries) this.itinerariesSimple = data.Itineraries.map(i => new ReservationProductItineraryBaseData(i));

        if (data.ServiceOptions) this.serviceOptions = data.ServiceOptions.map(i => new ReservationProductServiceOptions(i));
        if (data.ServiceImages) this.serviceImages = data.ServiceImages.map(i => new ReservationProductServiceImages(i));
        if (data.SuggestedItems) this.suggestedItems = data.SuggestedItems.map(i => new ReservationProductSuggestedItems(i));
        if (data.Categories) this.categories = data.Categories?.map((b) => {
          return {
            categoryCod: b.CategoryCod,
            category: b.Category
          }
        }) ?? [];
        if (data.Companies) this.companies = data.Companies?.map((b) => {
          return {
            companyID: b.CompanyID,
            companyName: b.CompanyName
          }
        }) ?? [];
        this.serviceOptions.forEach(so => so.images = (this.serviceImages.find(si => si.serviceID === so.serviceID) ?? new ReservationProductServiceImages()));
        this.itinerariesSimple.forEach(is => is.serviceOptions = this.serviceOptions.filter(so => so.day === is.day));
        const reservationService = AppModule.injector.get(ReservationService);
        reservationService.bookingUtility.ecmDataChanged$.next({});
        return this.ecmData;
      })
    ));
  }
  get formattedOccupancy(): string {
    if (!this.occupancy) return '';
    return this.occupancy + '%';
  }

  get formattedEscortInc(): string {
    return this.escortInc ? '✔️' : '❌';
  }

  get formattedDriverInc(): string {
    return this.driverInc ? '✔️' : '❌';
  }

  get formattedGuideInc(): string {
    return this.guideInc ? '✔️' : '❌';
  }

  static CreateFromPackage(data: IReservationApiProductPackage): ReservationBasePackage {
    const pkg = new ReservationBasePackage(data);
    return pkg;
  };
  get disallowActionForBookedPackage(): boolean {
    return this.isValid() && this.isBooked && this.status !== 'B';
  }
  isValid(): boolean {
    if (this.packageID == 0) return false;
    return true;
  }

  displayName(): string {
    return this.packageName ?? `${this.packageID}` ?? '';
  }
  displayBrand(): string {
    return ''; //`${this.brandID}` ?? '';
  }
  getStartDate(): DateTime | undefined {
    if (!this.calendarDate.length) return undefined;
    if (this.calendarDate[0].travelStartDate && !this.calendarDate[0].travelStartDate.isValid) return undefined
    return this.calendarDate[0].travelStartDate;
  }
  getEndDate(): DateTime | undefined {
    if (!this.calendarDate.length) return undefined;
    if (this.calendarDate[0].travelEndDate && !this.calendarDate[0].travelEndDate.isValid) return undefined
    return this.calendarDate[this.calendarDate.length - 1].travelEndDate;
  }

  get services(): Array<ReservationProductService> {
    return this.calendarDate.map(c => c.services).flat().filter((s, i, a) => a.findIndex(s2 => s2.serviceID == s.serviceID) == i);
  }

  get serviceTypes(): Array<{ serviceTypeID: number, serviceTypeName: string }> {
    return this.services.filter((s, i, a) => a.findIndex(s2 => s2.serviceTypeID == s.serviceTypeID) == i).map(s => ({ serviceTypeID: s.serviceTypeID, serviceTypeName: s.serviceTypeName }));
  }

  get configuredPriceTypes(): Array<{ priceTypeID: number, priceTypeName: string, priceTypeCod: string, agencyName: string | undefined, brandID: number }> {
    return this.calendarDate.map(c => this.isRetail ? c.pricesRetail : c.pricesPerPax).flat().map(p => ({ priceTypeID: p.priceTypeID, priceTypeName: p.priceTypeName, priceTypeCod: p.priceTypeCod, agencyName: p.agencyName, brandID: p.brandID })).filter((s, i, a) => a.findIndex(s2 => s2.priceTypeID == s.priceTypeID) == i)
  }

  get lastsDays(): boolean {
    return this.durationUDM?.toLowerCase() == 'day';
  }

  get lastsHours(): boolean {
    return this.durationUDM?.toLowerCase() == 'hour';
  }

  sortItineraries(a: ReservationProductItineraryBaseData, b: ReservationProductItineraryBaseData) {
    const timeFrom = a.timeFrom?.toMillis() ?? a.genericTimeFrom ?? 0
    const timeTo = b.timeTo?.toMillis() ?? b.genericTimeTo ?? 0

    return a.day - b.day || timeFrom - timeTo;
  }

  sortCalendarDates(a: ReservationPackageTravelDateDetail, b: ReservationPackageTravelDateDetail) {
    return (a.travelStartDate?.toMillis() ?? 0) - (b.travelStartDate?.toMillis() ?? 0) || (a.travelTime?.hour ?? 0) - (b.travelTime?.hour ?? 0) || (a.travelTime?.minute ?? 0) - (b.travelTime?.minute ?? 0)
  }
}

interface IReservationApiProductServiceMedia {
  ServiceID: number;
  FldValue: string;
  EntID: number;
  FldCod: string;
}
interface IReservationApiProductCalendarMedia {
  PackageID: number;
  PackageDayID: number;
  FldValue: string;
  EntID: number;
  FldCod: string;
}

export interface IReservationApiProductPackage {
  SectionID: number;
  PackageID: number;
  PackageTypeID: number;
  PackageTypeName: string;
  PackageShortName: string;
  PackageName: string;
  IsTM: string;
  EscortInc: boolean;
  GuideInc: boolean;
  DriverInc: boolean;
  Occupancy: number;
  CityID: number;
  Duration: number;
  DurationUDM: string;
  GuestFrom: number;
  GuestTo: number;
  MinPax: number;
  MaxPax: number;
  PackagePicture: string;
  PackageECMDescription: string;
  PackageBrochure: string;
  PackageInclusion: string;
  PackageExclusion: string;
  PackageImportantNotes: string;
  InternalNotes: string;
  CancellationPolicyID: number;
  Currency: string;
  CalendarDate: Array<IReservationApiProductCalendar>;
  Itineraries: Array<IReservationApiProductItinerary>;
  Prices: Array<IReservationApiProductPrice>;
  PriceType: Array<IReservationApiProductPriceType>;
  Services: Array<IReservationApiProductService>;
  EcmData: Array<IReservationApiPackageEcmData>;
  HasBundle?: string;
}

export interface IReservationApiProductCalendar {
  PackageID: number;
  PackageTravelDateID: number;
  TravelStartDate?: string;
  TravelEndDate?: string;
  TravelTime?: string;
  Notes: string;
  Services: Array<IReservationApiProductService>;
  Prices: Array<IReservationApiProductPrice>;
  OnHoldQty?: number;
  InitialQty?: number;
  AvailabilityBackgroundColor?: string;
  AvailabilityMessage?: string;
}
export interface IReservationApiProductItinerary {
  PackageID: number;
  PackageDayID?: number;
  Day: number;
  ItineraryDaySequence: number;
  TimeFrom: string;
  TimeTo: string;
  GenericTimeFrom: number;
  GenericTimeTo: number;
  ItineraryDayDesc: string;
  ItineraryDayText: string;
  ItineraryPicture: string;
  ItineraryECMDesc: string;
  CityID: number;
  City: string;
  ItineraryTypeID: number;
  ItineraryTypeName: string;
  MealID: number;
}
export interface IReservationApiProductAmbassador {
  PackageID: number;
  FullName?: string;
  Gender: string;
  Age: number;
  BirthDate: string;
  Country: string;
  Address1: string;
  PeopleTypeDesc: string;
  PeopleDesc: string;
  Description: string;
  Image1: string;
}
export interface IReservationApiProductTourGuide {
  PackageID: number;
  PeopleID: number;
  FullName?: string;
  Gender: string;
  Age: number;
  BirthDate: string;
  Country: string;
  Address1: string;
  Bio: string;
  TravelStartDate: string;
  TravelEndDate: string;
  Description: string;
  Image1: string;
}
export interface IReservationApiServiceOptions {
  PackageOptionID: number;
  PackageID: number;
  Day: string;
  ServiceTypeID: number;
  ServiceTypeName: string;
  ServiceID: number;
  ServiceName: string;
  ServiceDescription: string;
  ServiceImage: string;
  OptionID: number;
  OptionName: string;
  OptionDescription: string;
  OptionImage1: string;
  OptionImage2: string;
  OptionImage3: string;
  OptionImage4: string;
}
export class ReservationProductServiceOptions extends SafeBaseObject {
  packageOptionID = 0;
  packageID = 0;
  day = 0;
  serviceTypeID = 0;
  serviceTypeName = '';
  serviceID = 0;
  serviceName = '';
  serviceDescription = '';
  serviceImage = '';
  optionID = 0;
  optionName = '';
  optionDescription = '';
  optionImage1 = '';
  optionImage2 = '';
  optionImage3 = '';
  optionImage4 = '';

  hasImage = false;
  images = new ReservationProductServiceImages();
  constructor(data?: IReservationApiServiceOptions) {
    super()
    this.updateData(data);
    this.hasImage = !!this.serviceImage
      || !!this.optionImage1
      || !!this.optionImage2
      || !!this.optionImage3
      || !!this.optionImage4;
  }
}
export interface IReservationApiServiceImages {
  ServiceTypeID: number;
  ServiceTypeName: string;
  ServiceID: number;
  ServiceName: string;
  ServiceDescription: string;
  ServiceImage1: string;
  ServiceImage2: string;
  ServiceImage3: string;
  Lobby1: string;
  Lobby2: string;
  Lobby3: string;
  Restaurant1: string;
  Restaurant2: string;
  Restaurant3: string;
  Pool1: string;
  Pool2: string;
  Pool3: string;
  Spa1: string;
  Spa2: string;
  Spa3: string;
  Terrace1: string;
  Terrace2: string;
  Terrace3: string;
  Fitness1: string;
  Fitness2: string;
  Fitness3: string;
  Bar1: string;
  Bar2: string;
  Bar3: string;
}
export class ReservationProductServiceImages extends SafeBaseObject {
  serviceTypeID = 0;
  serviceTypeName = '';
  serviceID = 0;
  serviceName = '';
  serviceDescription = '';
  serviceImage1 = '';
  serviceImage2 = '';
  serviceImage3 = '';
  lobby1 = '';
  lobby2 = '';
  lobby3 = '';
  restaurant1 = '';
  restaurant2 = '';
  restaurant3 = '';
  pool1 = '';
  pool2 = '';
  pool3 = '';
  spa1 = '';
  spa2 = '';
  spa3 = '';
  terrace1 = '';
  terrace2 = '';
  terrace3 = '';
  fitness1 = '';
  fitness2 = '';
  fitness3 = '';
  bar1 = '';
  bar2 = '';
  bar3 = '';
  constructor(data?: IReservationApiServiceImages) {
    super()
    this.updateData(data);
  }

}
export interface IReservationApiSuggestedItems {
  PackageID: number;
  ExtendType: string;
  ID: number;
  Extend: string;
  PrePostFlag: string;
  CityFrom: string;
  CityTo: string;
  PackageImage: string;
}
export class ReservationProductSuggestedItems extends SafeBaseObject {
  ID = 0;
  packageID = 0;
  extendType = '';
  extend = '';
  prePostFlag = '';
  cityFrom = '';
  cityTo = '';
  packageImage = '';

  constructor(data?: IReservationApiSuggestedItems) {
    super()
    this.updateData(data);
  }

}


interface IReservationApiProductPrice {
  PackageID: number;
  PriceTypeID: number;
  BrandID: number;
  AgencyID: number;
  AgencyName: string;
  GuestFrom: number;
  GuestTo: number;
  SellingPriceCurrency: string;
  SellingPrice: number;
  ChildSellingPrice: number;
  ChildReduction: number;
  PriceSingle: number;
  PriceDouble: number;
  PriceChild: number;
  PriceExtraGuest: number;
  PackageTravelDateID: number;
  PriceTypeName: string;
  PriceTypeCod: string;
}
interface IReservationApiProductPriceType {
  PriceTypeID: number;
  PackageID: number;
  PriceTypeName: string;
  DefaultPrice: number;
  IsAgencyMandatory: number;
}
interface IReservationApiProductService {
  TravelStartDate?: DateTime;
  PackageTravelDateID: number;
  PackageID: number;
  ServiceID: number;
  ServiceLongName: string;
  ServiceDescription: string;
  ServicePicture: string;
  ServiceTypeID: number;
  ServiceTypeName: string;
}

export interface IReservationApiManagePackageAvailabilityParams {
  PackageID: number;
  PkgAvailab: IReservationApiManagePackageAvailability[];
}

export interface IReservationApiManagePackageAvailability {
  PackageTravelDateID: number;
  PackageID: number;
  InitialQtySingle: number;
  InitialQty: number;
  InitialQtyTriple: number;
  AvailabilityStatusCod: string;
}

export class ResPackageBookingList extends SafeBaseObject {
  packageID: number = 0;
  packageTravelDateID: number = 0;
  bkgID: number = 0;
  bkgStatus: string = '';
  guests: string = '';
  agency: string = '';
  agencyCod: string = '';
  passportExpireDate: DateTime = DateTime.local();
  passportIssueDate: DateTime = DateTime.local();
  passportIssuePlace: string = '';
  passportNumber: string = '';
  chkAirDet: boolean = false;
  travelStartDate: DateTime = DateTime.local();
  travelEndDate: DateTime = DateTime.local();
  directID: number | null = null;
  hasPreference: boolean = false;
  preferences: string[] = [];

  constructor(data: IReservationApiPackageBookingList) {
    super();
    this.updateData(data);
  }

  override updateData(data: IReservationApiPackageBookingList) {
    this.addMangledProperty(data, true)
    this.formatDateTimeProperties()
  }
}

export interface IReservationApiPackageBookingList {
  PackageID: number;
  PackageTravelDateID: number;
  BkgID: number;
  BkgStatus: string;
  Guests: string;
  Agency: string;
  AgencyCod: string;
  PassportExpireDate: string;
  PassportIssueDate: string;
  PassportIssuePlace: string;
  PassportNumber: string;
  ChkAirDet: boolean;
  TravelEndDate: string;
  TravelStartDate: string;
  HasPreference: boolean;
}

export interface IReservationApiDeletePackageParams {
  BkgID: number;
  SectionID: number;
  PackageID: number;
  PackageTravelDateID: number;
  GuestCod?: string;
}
export interface IReservationApiPackageExtendParams {
  ExtendJourneyID?: number;
  TypeFrom: string;
  IDFrom: number;
  PackageTravelDateIDFrom?: number | null;
  TypeTo: string;
  IDTo: number;
  PackageTravelDateID?: number;
  PrePostFlag?: string;
  StartDate?: DateTime;
  EndDate?: DateTime;
  Status: string;
}
export interface IReservationApiLikedPackageUpdateParams {
  PackageID: number;
  PackageExtend: IReservationApiPackageExtendParams[];
}
export class ResPackageUpgrade extends SafeBaseObject {
  packageID: number = 0;
  packageName: string = '';
  packageTravelDateID: number = 0;
  bkgID: number = 0;
  sectionID: number = 0;
  day: number = 0;
  itineraryDate: DateTime = DateTime.local();
  travelStartDate: DateTime = DateTime.local();
  travelEndDate: DateTime = DateTime.local();
  serviceID: number = 0;
  optionID: number = 0;
  serviceTypeID: number = 0;
  serviceTypeName: string = '';
  serviceName: string = '';
  optionName: string = '';
  itemID: number = 0;
  guestCod: string = '';
  isUpgrade: boolean = false;
  isDefault: boolean = false;
  serviceIDUpgrade: number = 0;
  optionIDUpgrade: number = 0;
  serviceUpgradeName: string = '';
  optionUpgradeName: string = '';
  upgradeAmount: number = 0;
  sellingPriceCurrency: string = '';

  formattedUpgradeAmount: string = '';

  // fe logic
  upgrades: ResPackageUpgrade[] = [];
  upgradeID: string = '';
  upgradeName: string = '';
  voyageID = 0

  constructor(data: IReservationApiPackageUpgrade | ResPackageUpgrade) {
    super();
    this.updateData(data);
  }

  override updateData(data: IReservationApiPackageUpgrade | ResPackageUpgrade) {
    this.addMangledProperty(data)
    this.addDateTimeProperty('itineraryDate', data, 'ItineraryDate')
    this.addDateTimeProperty('travelStartDate', data, 'TravelStartDate')
    this.addDateTimeProperty('travelEndDate', data, 'TravelEndDate')
    this.addCurrencyAmountProperty('formattedUpgradeAmount', data, 'UpgradeAmount', this.sellingPriceCurrency)
    this.formatDateTimeProperties()
    this.setUpgradeData()
  }

  setUpgradeData() {
    this.upgradeID = this.isUpgrade ? `${this.serviceIDUpgrade}_${this.optionIDUpgrade}` : `${this.serviceID}_${this.optionID}`
    const { name, suffix } = (() => {
      if (this.isUpgrade) return {
        name: `${this.optionUpgradeName} (${this.serviceUpgradeName})`,
        suffix: ` ${this.formattedUpgradeAmount.includes('-') ? '- ' + this.formattedUpgradeAmount.replace('-', '') : '+ ' + this.formattedUpgradeAmount}`
      }
      return {
        name: `${this.optionName} (${this.serviceName})`,
        suffix: ''
      }
    })()
    this.upgradeName = `${name}${suffix}${this.isDefault ? ' (DEFAULT)' : ''}`
  }

  clone(): ResPackageUpgrade {
    return new ResPackageUpgrade(this)
  }

  isRelatedTo(upgrade: ResPackageUpgrade): boolean {
    return this.serviceID === upgrade.serviceID && this.optionID === upgrade.optionID
  }

}

export interface IReservationApiPackageUpgrade {
  PackageID: number;
  PackageName: string;
  IsTM: string;
  PackageTravelDateID: number;
  BkgID: number;
  SectionID: number;
  Day: number;
  ItineraryDate: string;
  ServiceID: number;
  OptionID: number;
  ServiceTypeID: number;
  ServiceTypeName: string;
  ServiceName: string;
  OptionName: string;
  ItemID: number;
  GuestCod: string;
  IsUpgrade: string;
  IsDefault: string;
  ServiceIDUpgrade: number;
  OptionIDUpgrade: number;
  ServiceUpgradeName: string;
  OptionUpgradeName: string;
  UpgradeAmount: number;
  SellingPriceCurrency: string;
}

export interface IReservationApiManagePackageUpgradeParams {
  BkgID: number;
  PackageID: number;
  PackageUpgrade: IReservationApiManagePackageUpgrade[];
}

export interface IReservationApiManagePackageUpgrade {
  ItemID: number;
  ServiceIDUpgrade: number;
  OptionIDUpgrade: number;
  UpgradeAmount: number;
  IsDefault: string;
}

export interface IReservationCruisePriceType {
  CompanyID?: number;
  CompanyName?: string;
  Currency?: string;
  EndDate?: DateTime;
  PriceChild?: number;
  PriceDouble?: number;
  PriceExtraGuest?: number;
  PriceSingle?: number;
  PriceTypeID?: number;
  PriceTypeName?: string;
  StartDate?: DateTime;
  SuiteCategory?: string;
  SuiteCategoryID?: number;
  VoyageID?: number
}

export class ResCruisePriceType extends SafeBaseObject {
  companyID = 0;
  companyName = '';
  currency = '';
  endDate = '';
  priceChild = 0;
  priceDouble = 0;
  priceExtraGuest = 0;
  priceSingle = 0;
  priceTypeID = 0;
  priceTypeName = '';
  startDate = '';
  suiteCategory = '';
  suiteCategoryID = 0;
  voyageID = 0;

  constructor(data: IReservationCruisePriceType) {
    super();
    this.updateData(data);
  }

  override updateData(data: IReservationCruisePriceType) {
    this.addMangledProperty(data)
    this.formatDateTimeProperties()
  }
}

export interface IResProductPropertyAvailabilityParams {
  PropertyGroupID?: number
  PropertyID?: number
  CheckIN: DateTime
  CheckOUT: DateTime
}
export class ResProductPropertyAvailability extends SafeBaseObject {
  date = DateTime.invalid('init')
  isEmpty = false
  suiteCategoryKey = ''
  maxCapacity = 0
  propertyGroupID = 0
  propertyGroup = ''
  propertyID = 0
  property = ''
  suiteCategoryID = 0
  suiteCategory = ''
  suiteCapacityID = 0
  suiteCapacity = ''
  cnt = 0

  constructor(data: any) {
    super();
    super.updateData(data);
    this.updateData(data);
  }

  override updateData(_data: any) {
    this.suiteCategoryKey = `${this.suiteCategoryID}-${this.suiteCapacityID}`
  }

}

export class ResProductCategorySlotAvailability {
  from:DateTime
  to:DateTime
  availability:number
  reservedAccomodations:ReservationAccomodation[] = []
  reservedTransfers:ReservationAccomodation[] = []
  onRequest = false
  roe = 1
  sellingPrice = 0
  cost = 0
  markup = 0
  isEmpty = false

  constructor(category:ResProductPropertyAvailability,price:ReservationCampPrices) {
    this.from = category.date
    this.to = category.date
    this.availability = category.cnt
    this.onRequest = price.onRequest
    this.roe = price.roe
    this.sellingPrice = price.sellingPrice
    this.cost = price.cost
    this.markup = price.markup
    this.isEmpty = category.isEmpty
  }
}
export class ResProductCategoryAvailability {
  suiteCategoryKey = ''

  property = ''
  propertyID = 0

  categoryID = 0
  category = ''

  capacityID = 0
  capacity = ''
  maxCapacity = 0

  isTransfer = false

  prices:ReservationCampPrices[]=[]


  availability:ResProductCategorySlotAvailability[] = []
  checkAvailability = true
  selectedGroup = -1
  availableGroups:{code:number,description:string}[]=[]
  constructor() {
  }
}
export class ResProductSuiteAvailability {
  property = ''
  propertyID = 0
  propertyGroupID = 0
  propertyGroup = ''
  categories: ResProductCategoryAvailability[] = []
  constructor(data: ResProductPropertyAvailability[],suites:ReservationCampSuiteCategory[]) {
    if(data.length===0) return
    this.property = data[0].property
    this.propertyID = data[0].propertyID
    this.propertyGroupID = data[0].propertyGroupID
    this.propertyGroup = data[0].propertyGroup

    //calculate one availability for each category
    const categories = new Set<string>(data.map(c => c.suiteCategoryKey))
    categories.forEach(c => {
      const availabilities = data.filter(a => a.suiteCategoryKey === c).sort((a, b) => a.date.toMillis() - b.date.toMillis())
      const item = new ResProductCategoryAvailability()
      const category = data.find(d => d.suiteCategoryKey === c)
      item.suiteCategoryKey = c
      if (category) {
        item.property = category.property
        item.propertyID = category.propertyID
        item.categoryID = category.suiteCategoryID
        item.category = category.suiteCategory
        item.capacityID = category.suiteCapacityID
        item.capacity = category.suiteCapacity
        item.maxCapacity = category.maxCapacity
        suites.forEach(s=>
          s.prices.forEach(p=>
            item.prices.push(p)
          )
        )
        this.categories.push(item)
      }

      availabilities.forEach(a=>{
        const prices = suites.find(s=>s.suiteCategoryKey==a.suiteCategoryKey)?.prices;
        const price = prices ? (prices.find(p=>a.date.equals(p.byDay)) ?? new ReservationCampPrices()): new ReservationCampPrices();

        item.availability.push(new ResProductCategorySlotAvailability(a,price))
      })
      // //catgerory without availability
      // if(availabilities.length===0) return

      // //calculate availability slot
      // //every slot containg availability date range (continuosly) with equal disponibility
      // let idxFrom=0
      // while(idxFrom<availabilities.length-1) {
      //   const from = availabilities[idxFrom].date
      //   const cnt = availabilities[idxFrom].cnt
      //   const idxTo = idxFrom + this.getRangeEndIndex(availabilities.slice(idxFrom+1),from,cnt, checkIn, checkOut)

      //   item.availability.push(new ResProductCategorySlotAvailability(from,availabilities[idxTo].date,cnt))
      //   idxFrom = idxTo + 1
      // }

    })
  }
  getRangeEndIndex(a: ResProductPropertyAvailability[], from: DateTime, count: number, checkIn: DateTime, checkOut: DateTime) {
    if (a.length === 0) return 0
    let lastValid = 0
    a.every((e, idx) => {
      if (from < checkIn && e.date > checkIn) return false
      if (from < checkOut && e.date > checkOut) return false
      if (e.date.diff(from, 'days').days > idx + 1 || e.cnt !== count) return false
      lastValid = idx
      return true
    })
    return lastValid
  }
}
export class ResProductAvailability {
  static emptyValue() {
    const range: IResInputCalendarRangeValue = {
      startDate: DateTime.invalid('empty'),
      endDate: DateTime.invalid('empty')
    }
    const v = new ResProductAvailability(0,[],range,[])
    v.empty=true
    return v
  }
  empty = false
  checkIn:DateTime
  checkOut:DateTime
  productSuiteAvailability:ResProductSuiteAvailability
  linkedAvailability:ResProductSuiteAvailability[] = []
  reservedCounter:number[] = []
  // get reservedCounter() {
  //   return this.productAvailability.categories.reduce((accAll,curCategory)=>
  //     accAll+curCategory.availability.reduce((acc,curDay)=>
  //       acc+curDay.reservedAccomodations.length+curDay.reservedTransfers.length,
  //       0),
  //   0)
  // }

  get allAvailableCategories() {
    return this.productSuiteAvailability.categories.concat(this.linkedAvailability.flatMap(c=>c.categories))
  }

  constructor(master:number, availability:ResProductPropertyAvailability[], rangeDate:IResInputCalendarRangeValue,suites:ReservationCampSuiteCategory[]) {
    this.checkIn = rangeDate.startDate
    this.checkOut = rangeDate.endDate

    this.productSuiteAvailability = new ResProductSuiteAvailability(availability.filter(a=>a.propertyID===master),suites)

    const others = new Set<number>(availability.filter(a => a.propertyID !== master).map(a => a.propertyID))

    others.forEach(id=>
        this.linkedAvailability.push(new ResProductSuiteAvailability(availability.filter(a=>a.propertyID===id),suites))
      )
  }

}

export interface IResPackageSetupSingleSupp {
  PackageSnglSuppID: number,
  SuiteCategoryID: number,
  PromoID?: number,
  DefaultFormula: string,
  SnglSuppType: string,
  SnglSuppValue: number,
  SnglSuppCommPerc: number,
  StartDate?: Date,
  EndDate?: Date,
  Status: string,
}
export interface IResPackageSetupSingleSuppManage {
  PackageID: number,
  PackageTravelDateID: number,
  PriceTypeID: number,
  Currency: string,
  AgencyID?: number,
  NewStartDate?: Date,
  SnglSupp: IResPackageSetupSingleSupp[]
}


export interface IResPackageOptionToSuiteCategory {
  DisplaySequence?: number,
  // EndDate?: DateTime,
  EndDate_Remap?: DateTime,
  OptionID?: number,
  OptionID_Remap?: number,
  OptionName?: string,
  OptionName_Remap?: string,
  PackageID?: number,
  PackageOption2SuiteCategoryID?: number,
  PackageTravelDateID?: number,
  PriceSetupID?: number,
  PriceSetupID_Remap?: number,
  ServiceID?: number,
  ServiceID_Remap?: number,
  ServiceName?: string,
  ServiceName_Remap?: string,
  ServiceTypeID?: number,
  // StartDate?: DateTime,
  StartDate_Remap?: DateTime,
  SuiteCategory?: string,
  SuiteCategoryID?: number,
  CityID?: number,
  City?: string,
}

export class ResPackageOptionToSuiteCategory extends SafeBaseObject {
  displaySequence = 0
  // endDate = DateTime.invalid('empty')
  endDateRemap = DateTime.invalid('empty')
  optionID = 0
  optionIDRemap?: number = 0
  optionName = ''
  optionNameRemap = ''
  packageID = 0
  packageOption2SuiteCategoryID = 0
  packageTravelDateID = 0
  priceSetupID = 0
  priceSetupIDRemap = 0
  serviceID = 0
  serviceIDRemap?: number = 0
  serviceName = ''
  serviceNameRemap = ''
  serviceTypeID = 0
  // startDate = DateTime.invalid('empty')
  startDateRemap = DateTime.invalid('empty')
  suiteCategory = ''
  suiteCategoryID = 0
  cityID = 0
  city = ''

  constructor(data?: IResPackageOptionToSuiteCategory) {
    super();
    this.updateData(data);
  }
}

export interface IResApiPackageOptionToSuiteCategory {
  PackageID?: number,
  PackageOption2SuiteCategoryID?: number,
  SuiteCategoryID?: number,
  ServiceID?: number,
  OptionID?: number,
  StartDate?: DateTime,
  EndDate?: DateTime,
  Status?: string,
  CityID?: number,
}

export class ResPackageDepartureFootnotes extends SafeBaseObject {
  packageFootnoteID = 0;
  packageFootnote = '';
  packageFootnoteDesc = '';
  order = 0;

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackageExportListByBooking extends SafeBaseObject {
  packageID = 0;
  packageTravelDateID = 0;
  packageName = '';
  travelStartDate = DateTime.invalid('empty');
  travelEndDate =  DateTime.invalid('empty');
  bkgID = 0;
  ownerType = 0;
  agencyCod = 0;
  agency = '';
  bkgStatus = '';
  directID = 0;
  guests = '';
  passportNumber = 0;
  passportIssuePlace = '';
  passportIssueDate =  DateTime.invalid('empty');
  passportExpireDate =  DateTime.invalid('empty');

  get passportIssueDateFormatted() {
    return this.passportIssueDate.isValid ? this.passportIssueDate.toFormat('dd/LL/yyyy') : '';
  }
  get passportExpireDateFormatted() {
    return this.passportExpireDate.isValid ? this.passportExpireDate.toFormat('dd/LL/yyyy') : '';
  }
  get travelStartDateFormatted() {
    return this.travelStartDate.isValid ? this.travelStartDate.toFormat('dd/LL/yyyy') : '';
  }
  get travelEndDateFormatted() {
    return this.travelEndDate.isValid ? this.travelEndDate.toFormat('dd/LL/yyyy') : '';
  }

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackageAvailabilityStatus extends SafeBaseObject {
  packageLookupID = 0;
  scope = '';
  position = 0;
  cod = '';
  desc = '';

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export interface IResApiPackageConnectedPackages {
  PackageID: number;
  Type?: string;
  PackageTravelDateID?: number;
}

export class ResPackageConnectedPackages extends SafeBaseObject {
  packageTravelDateID = 0;
  packageID = 0;
  packageName = '';
  travelStartDate = DateTime.invalid('empty');
  travelEndDate =  DateTime.invalid('empty');
  travelTime = 0;
  notes = '';
  initialQtyDouble = 0;
  onHoldQtyDouble = 0;
  initialQtySingle = 0;
  onHoldQtySingle = 0;
  initialQtyTriple = 0;
  onHoldQtyTriple = 0;
  voyageID = 0;
  voyageNumber = 0;
  status = '';
  marketingStatus = '';
  availabilityStatusCod = ''
  labelNotes = '';

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}
export class TourBook extends SafeBaseObject {
  blbID = 0
  blbGuID = ''
  created = DateTime.invalid('empty')
  docType = ''
  voyageID = 0

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export interface IResApiPackageClone {
  PackageID?: number;
  PackageName?: string;
  PackageCloneTravelDate?: IResApiPackageCloneTravelDate[];
}

export interface IResApiPackageCloneTravelDate {
  PackageTravelDateID?: number;
  IsTravelToDelete?: string;
  IsPriceToImport?: string;
}

export class ResPackageCommunicationEmail extends SafeBaseObject {
  address = '';
  cityID = 0;
  countryCod = '';
  email = '';
  escalationLevel1 = '';
  escalationLevel1Email = '';
  escalationLevel2 = '';
  escalationLevel2Email = '';
  mainContact = '';
  packageID = 0;
  packageSupplierCommEmailID = 0;
  serviceID = 0;
  serviceName = '';
  serviceType = '';
  supplierID = 0;
  supplierName = '';
  zip = '';
  status = 'A';

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackageSupplierRequestSupplier extends SafeBaseObject {
  escalationLevel1 = '';
  escalationLevel1Email = '';
  escalationLevel2 = '';
  escalationLevel2Email = '';
  mainContact = '';
  mainContactEmail = '';
  packageID = 0
  packageSupplierCommEmailID = 0
  serviceID = 0
  serviceName = ''
  serviceType = ''
  supplierAddress = ''
  supplierCity = 0
  supplierCountry = ''
  supplierDateIssued = DateTime.invalid('empty');
  supplierFax = ''
  supplierID = 0
  supplierName = ''
  supplierZip = ''

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}
export class ResPackageSupplierRequestService extends SafeBaseObject {
  bkgID = 0;
  bkgPackageIDs = '';
  serviceID = 0;
  optionID = 0;
  cost = 0;
  costCurrency = '';
  itineraryDate = DateTime.invalid('empty');
  quantity = 0;
  sellingPrice = 0;
  sellingPriceCurrency = '';
  serviceName = '';
  serviceStatus = '';

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}
export class ResPackageSupplierRequestGuest extends SafeBaseObject {
  birthDate = DateTime.invalid('empty');
  bkgID = 0;
  disability = false;
  guestCod = 0
  guestID = 0;
  firstName = '';
  lastName = '';
  memberType = ''
  smoker = false
  title = ''
  directID = 0;

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}
export class ResPackageSupplierRequestUpdate extends SafeBaseObject {
  requestID = 0
  supplierDateIssued = DateTime.invalid('empty');

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackageSupplierRequestData extends SafeBaseObject {
  supplier?: ResPackageSupplierRequestSupplier
  serviceDays: ResPackageSupplierRequestService[] = []
  guests: ResPackageSupplierRequestGuest[] = []
  lastUpdate?: ResPackageSupplierRequestUpdate

  constructor(data?: any) {
    super();
    this.updateData(data);

    this.supplier = new ResPackageSupplierRequestSupplier(data.DT0[0]);
    data.DT1.forEach((serviceDay:any) => {
      const mappedServiceDay = new ResPackageSupplierRequestService(serviceDay);
      this.serviceDays.push(mappedServiceDay)
    })
    data.DT2.forEach((guest:any) => {
      const mappedGuest = new ResPackageSupplierRequestGuest(guest);
      this.guests.push(mappedGuest)
    })

    this.lastUpdate = new ResPackageSupplierRequestUpdate(data.DT3[0]);
  }
}

export interface IResApiPackageCommunicationEmail {
  ItineraryDate?: DateTime;
  ServiceID?: number;
  BkgID?: number;
  PackageID?: number;
  PkgSuppl?: IResApiPackageCommunicationEmailList[];
}
export interface IResApiSupplierRequest {
  BkgID: number,
  RequestHdr: SupplierRequestHdr
  RequestSrv: SupplierRequestSrv[]
	RequestGst: SupplierRequestGst[]
}

export interface SupplierRequestHdr {
	BkgID?: number
	PackageID?: number
	PackageSupplierCommEmailID?: number,
	ServiceID?: number,
	SupplierID?: number,
	SupplierCityID?: number,
	SupplierCountryCod?: string,
	SupplierAddress?: string,
	SupplierZip?: string,
	MainContact?: string,
	MainContactEmail?: string,
	EscalationLevel1?: string,
	EscalationLevel1Email?: string,
	EscalationLevel2?: string,
	EscalationLevel2Email?: string,
	SupplierFax?: string,
	SupplierDateIssued?: DateTime,
	Status?: string
  Comments?: string;
  BkgNotes?: string
}


export interface SupplierRequestSrv {
	BkgID?: number,
	BkgPackageIDs?: string,
	ServiceID?: number,
	OptionID?: number,
	ServiceName?: string,
	ItineraryDate?: DateTime,
	ServiceStatus?: string,
	Quantity?: number,
	SellingPrice?: number,
	SellingPriceCurrency?: string,
	Cost?: number,
	CostCurrency?: string,
	Status?: string
}


export interface SupplierRequestGst {
	BkgID?: number,
	GuestID?: number,
	GuestCod?: number,
	DirectID?: number,
	Title ?: string,
	LastName?: string,
	FirstName?: string,
	BirthDate?: DateTime,
	Smoker?: string,
	Disability?: string,
	MemberType?: string,
	Status?: string
}
export interface IResApiPackageSupplierRequest {
  PackageID?: number;
  ServiceID?: number;
  OptionID?: number;
  ItineraryDate?: DateTime;
  BkgID?: number;
}

export interface IResApiPackageCommunicationEmailList {
  PackageSupplierCommEmailID?: number | null;
  ServiceID?: number;
  SupplierID?: number;
  MainContact?: string;
  Email?: string;
  EscalationLevel1?: string;
  EscalationLevel1Email?: string;
  EscalationLevel2?: string;
  EscalationLevel2Email?: string;
  Status?: string;
}

export interface IResApiGetPackageTravelDateGuide {
  PackageTravelDate2GuideID?: number;
	PackageID?: number;
	PackageTravelDateID?: number;
	PeopleID?: number;
}

export class ResPackageTravelDateGuide extends SafeBaseObject {
  packageTravelDate2GuideID = 0;
  packageID = 0;
  packageTravelDateID = 0;
  travelStartDate = DateTime.invalid('empty');
  travelEndDate = DateTime.invalid('empty');
  travelDateStatus = '';
  peopleID = 0;
  fullName = '';
  gender = '';
  age = 0;
  birthDate = DateTime.invalid('empty');
  country = '';
  address1 = '';
  bio = '';

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export interface IResManagePackageTravelDateGuide {
  PackageTravelDate2GuideID?: number;
	PackageID?: number;
	PackageTravelDateCod?: string | null;
	PeopleID?: number;
	Status?: string;
}

export class ResPackageTravelDatesToGuide extends SafeBaseObject {
  packageID = 0;
  packageTravelDate2GuideID = 0;
  packageTravelDateID = 0;
  peopleID = 0;
  travelEndDate = DateTime.invalid('empty');
  travelStartDate = DateTime.invalid('empty');

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackageFootnotes extends SafeBaseObject {
  day = 0;
  packageFootnote = '';
  city = '';
  serviceName = '';
  packageFootnoteDesc = '';
  packageFootNoteID	= 0;
  packageID	= 0;
  serviceID	= 0;
  cityID = 0;

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackageType extends SafeBaseObject {

  packageTypeID = 0;
  packageTypeName = '';
  packageTypeIsDefault = false;
  needSuite = false;
  needVoyage = false;
  needJet = false;
  search = false;
  setup = false;
  order = 0;

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackageSuiteConfig extends SafeBaseObject {
  packageID = 0;
  voyageID  = 0;
  suiteCategoryID  = 0;
  suiteCategoryCod = '';
  suiteCategory = '';
  suiteTotal  = 0;
  suiteAvailable  = 0;
  suiteClosed = false

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackageJets extends SafeBaseObject {
  jetID = 0
  jet = ''
  jetCod = ''

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackagePriceHeader extends SafeBaseObject {
  duration = 0;
  needVoyage = false;
  packageID = 0;
  packageName = '';
  packageTypeID = 0;
  packageTypeName = '';

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

export class ResPackagePriceSetup extends SafeBaseObject {
  priceID = 0;
  packageID = 0;
  packageName = '';
  duration = 0;
  priceTypeID = 0;
  priceTypeName = '';
  currency = '';
  packageTravelDateID = 0;
  travelStartDate = DateTime.invalid('empty');
  travelEndDate = DateTime.invalid('empty');
  validityStartDate = DateTime.invalid('empty');
  validityEndDate = DateTime.invalid('empty');
  agencyID = 0;
  forcedPrice = false;
  retailPrice = 0;
  doubleOccupancy = 0;
  internalAir = null;
  flagInternalAir = false;
  singleSupplement = 0;
  tripleOccupancy = null;
  soloTraveller = 0;
  priceChild = 0;
  permitPrice = null;
  suiteCategory = '';
  suiteCategoryID = 0;
  status = 'A';

  formattedRetailPrice = '';
  formattedDoubleOccupancy = '';
  formattedInternalAir = '';
  formattedSingleSupplement = '';
  formattedTripleOccupancy = '';
  formattedPriceChild = '';
  formattedPermitPrice = '';

  // fe logic
  edited = false
  new = false

  constructor(data?: any) {
    super();
    this.updateData(data);
    this.formatDateTimeProperties()
    this.formatCurrencyAmountProperties(['retailPrice', 'doubleOccupancy', 'internalAir', 'singleSupplement', 'tripleOccupancy', 'priceChild', 'permitPrice'], this.currency, {internalAir: 'N/A', tripleOccupancy: 'On request', permitPrice: 'N/A'})

    if(!this.priceID) {
      this.priceID = Date.now() + Math.random()
      this.new = true
    }
  }
}

export class ResPackageSetupTravelDate extends SafeBaseObject {
  packageID = 0;
  packageTravelDateID = 0;
  travelStartDate = DateTime.invalid('empty');
  suiteCategoryID = 0;
  type = '';

  constructor(data?: any) {
    super();
    this.updateData(data);
    this.formatDateTimeProperties()
  }
}

export class ResPackagePriceValidityPeriod extends SafeBaseObject {
  validityStartDate = DateTime.invalid('empty');
  validityEndDate = DateTime.invalid('empty');

  formattedValidityStartDate = '';
  formattedValidityEndDate = '';

  current = false

  constructor(data?: any) {
    super();
    this.updateData(data);
    this.formatDateTimeProperties()
  }
}

export interface IResApiManagePackagePricesSetup {
  PackageID: number;
  CompanyID: number;
  PriceTypeID: number;
  Type: 'UPD' | 'NEW';
  Price: IResApiManagePackagePrice[];
}

export interface IResApiManagePackagePrice {
  PackageID: number;
  PackageTravelDateID: number;
  SuiteCategoryID: number;
  PriceID: number;
  PriceTypeID: number;
  Currency: string;
  ValidityStartDate: DateTime;
  ValidityEndDate: DateTime;
  AgencyID: number;
  ForcedPrice?: boolean;
  RetailPrice: number;
  DoubleOccupancy: number;
  InternalAir?: number;
  FlagInternalAir: string;
  SingleSupplement: number;
  TripleOccupancy?: number;
  SoloTraveller: number;
  PriceChild: number;
  PermitPrice?: number;
  Status: string;
}

export class ResSearchPackageFootnote extends SafeBaseObject {
  days = '';
  footnoteDesc = '';
  packageFootnote = '';
  packageFootnoteID = 0;
  productID = 0;
  productCod = '';
  serviceID = 0;
  serviceName = '';

  constructor(data?: any) {
    super();
    this.updateData(data);
  }
}

