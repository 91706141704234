<form [formGroup]="form">
  <h1 mat-dialog-title fxLayoutAlign="center">{{ 'impersonate_user.title' | transloco }}</h1>

  <div mat-dialog-content>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'impersonate_user.user' | transloco }}</mat-label>
      <input formControlName="usernameToImpersonate" matInput required>
      <mat-error *ngIf="form.controls.usernameToImpersonate.invalid">{{ 'fill_field' | transloco }}</mat-error>
    </mat-form-field>

  </div>

  <div mat-dialog-actions fxLayoutAlign="end">

    <button mat-button type="button" (click)="onCancelClick()">{{ 'cancel' | transloco }}</button>

    <button mat-flat-button type="submit" color="primary" (click)="onSaveClick()" [disabled]="form.invalid">{{ 'submit' | transloco }}</button>

  </div>
</form>
