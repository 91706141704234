<dx-drop-down-button [dropDownOptions]="{ width: 280 }" [items]="actions" template="buttonTemplate"
  itemTemplate="itemTemplate" [useItemTextAsTitle]="false" [(opened)]="opened" class="dropdown-icon">

  <div class="no-padding" [class.dx-state-disabled]="!!action.isDisabled ? action.isDisabled() : false" *dxTemplate="let action of 'itemTemplate'">

    <content [ngSwitch]="action.id">
      <ng-template [ngSwitchCase]="'booking'">
        <content (click)="$event.stopImmediatePropagation()">
          <label>Go to booking</label>
          <div class="dx-field mt-1 go-to-booking-field" [title]="action.tooltip ?? ''">
            <input placeholder="Id" [(ngModel)]="goToBookingID" (keydown.enter)="goToBooking(action)"/>
            <dx-button [disabled]="!legalBookingId"
              (click)="goToBooking(action)">
              Go
            </dx-button>
          </div>
        </content>
      </ng-template>
      <ng-template [ngSwitchDefault]>
        <button class="dropdown-button"
          (click)="!!action.onClick ? action.onClick() : execAction(action); $event.stopImmediatePropagation()"
          [title]="action.tooltip ?? ''"
          [disabled]="!!action.isDisabled ? action.isDisabled() : false">
          <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
          <span>{{ "action_menu." + action.labelCod | transloco }}</span>
        </button>
      </ng-template>
    </content>

  </div>
</dx-drop-down-button>
