import { AfterViewInit, Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { IEidosIcon } from '@common/config/environment.interface';
import { EidosIconType } from '@common/models/core-constant.model';
import { EidosBaseComponent } from '@common/components/eidos-base.component';
import { IEidosObject } from '@common/models/eidos-object.interface';
import { EidosObjectEventType } from '@common/models/eidos-object.model';
import { EidosObjectService } from '@eidos/services/eidos-object.service';

@Component({
  selector: 'eidos-dashboard-item',
  templateUrl: './eidos-dashboard-item.component.html',
  styleUrls: ['./eidos-dashboard-item.component.scss']
})
export class EidosDashboardItemComponent extends EidosBaseComponent implements AfterViewInit {
  /**
   * Object definition
   *
   * @type {*}
   * @memberof EidosDashboardItemComponent
   */
  @Input()
  public eidosObject: IEidosObject | undefined;
  /**
   * Object filtered flag
   *
   * @memberof EidosDashboardItemComponent
   */
  public isFiltered: boolean;
  /**
   * Filter icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosDashboardItemComponent
   */
  public filterIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "filter_alt",
    iconSize: 1
  };
  public filterIconLabel = "Object filtered";

  constructor(
    private eidosObjectService: EidosObjectService
  ) {
    super();
    this.isFiltered = false;
  }

  ngAfterViewInit(): void {
    this.eidosObjectService.objectEventEmitter
      .pipe(takeUntil(this.subscription))
      .subscribe(ev => {
        if (!this.eidosObject) return;

        if (ev.id === this.eidosObject.eidosObjectId) {
          switch (ev.type) {
            case EidosObjectEventType.LoadComplete:
            case EidosObjectEventType.ReloadComplete:
              if (ev.config?.filters) this.isFiltered = ev.config.filters.length > 0;
              break;
          }
        }
      });
  }
}
