<mat-list-item>
  <div>
    <div mat-line *ngIf="typeOf(entry[1]) !== 'object'"><b>{{ entry[0] + ' : '}}</b>{{getTruncatedValue()}}</div>
    <div *ngIf="entry[1] && typeOf(entry[1]) === 'object'">
      <b>{{ entry[0] + ' : '}}</b><span *ngIf="mismatchMessage" class="mismatch-message">{{ mismatchMessage }}</span>
      <ng-container *ngIf="fakeConfig != undefined">
        <eidos-configuration-item-viewer *ngFor="let subEntry of objectEntries(entry[1])" [fakeConfig]="fakeConfig[fakeConfigProp]" [entry]="subEntry"></eidos-configuration-item-viewer>
      </ng-container>
    </div>
  </div>
</mat-list-item>