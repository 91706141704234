import { MyBizApiResponse } from "./mybiz.model";

export class LoginResponse {
  public status = 'err';
  public landingPage: string | null = null;
  public message = '';
  constructor(response: MyBizApiResponse) {

    switch (response.type) {
      case 'err':
        this.status = 'error';
        this.message = response.error || '';
        this.landingPage = null;
        break;
      default:
        this.status = 'ok';
        this.message = response.error || '';
        this.landingPage = null;
        break;
    };
  }

  isOk(): boolean {
    return this.status === 'ok';
  }
}
