import { AppModule } from "@app/app.module";

import { IDynamicApiErrorResponse, IDynamicApiResponse } from "@app/core/models/dynamic-api-response.model";

import { EidosLogSeverity, LocalStorageKeys } from "@app/core/models/core-constant.model";
import { EidosLogService } from "@app/core/services/eidos-log.service";
import { RescomService } from "@app/res-common/services/rescom.service";
import { DateTime, Interval } from "luxon";
import { BehaviorSubject } from "rxjs";
import { ReservationAirService } from "../services/reservation-air.service";
import { ReservationApiService } from "../services/reservation-api.service";
import { ReservationEntityService } from "../services/reservation-entity.service";
import { ReservationService } from "../services/reservation.service";
import { IResApiParametersQuoteCreate, IResBookingCreateApiResponse, IReservationApiParametersBookCamp, IReservationApiParametersGuest, IReservationApiParametersPackage, IReservationApiParametersRoomConfHdr, IReservationApiParametersSuite, ResGuestType } from "./res-booking.model";
import { ReservationCruise } from "./res-cruise.models";
import { IResApiGuestCheckBookingExistsResult, ResOwner } from "./res-owner.model";
import { ResProductAvailability, ResProductType, ReservationBasePackage } from "./res-package.model";
import { ResPromo } from "./res-promo.model";
import { ReservationAccomodation, ReservationBookVilla } from "./res-service.models";

export type ResPreBookingUtilityBkgCreationActionParam = 'Quote' | 'Option';

export interface ResAddPackages {
  packageID: number;
  packageTravelDateID:number;
  guestCod: number;
  retailPrice:boolean;
}
export type ResPreBookingUtilityBkgCreationParams = {
	action: ResPreBookingUtilityBkgCreationActionParam
	unchecked?: boolean
	owner: ResOwner
	currency: string
	priceTypeID: number
	loyaltyDiscountId?: number
	suites: IReservationApiParametersSuite[]
	voyages: { voyageID: number; packageID?: number; serviceID?: number; optionID?: number }[]
	packages: ResAddPackages[]
	cloneBooking?: boolean
	promos: ResPromo[]
	bookCamp: IReservationApiParametersBookCamp[]
	accomodations: ReservationAccomodation[]
	transfers: ReservationAccomodation[]
	bkgID?: number
	companyID?: number
	bookVilla?: ReservationBookVilla
}

export class ResPreBookingUtility {

  private _guestCodAdultPlaceholder = 1;
  private _guestCodChildPlaceholder = 2;
  private _guestCodChild2Placeholder = 2;
  private _guestCodInfantPlaceholder = 2;
  /**
   * In loading flag
   *
   * @memberof ResPreBookingUtility
   */
  loading: boolean = false;

  productIDsBS = new BehaviorSubject<number[]>([]);
  productIDs: any[] = [];
  products: any[] = [];
  private currentPromos:BehaviorSubject<Array<ResPromo>> = new BehaviorSubject([] as Array<ResPromo>);
  private currentPackage:BehaviorSubject<ReservationBasePackage> = new BehaviorSubject(new ReservationBasePackage());
  private rescomService: RescomService

  constructor(
    public resService: ReservationService,
  ) {
    this.rescomService = AppModule.injector.get(RescomService);
  }
  clearSelectedPromo() {
    localStorage.setItem(LocalStorageKeys.OBJECT_DATA.replace('{id}', 'PRE-BKG-PROMOS'), JSON.stringify([]));
    this.cachePromo([]);
    this.currentPromos.next([])
  }
  private cachePromo(promos:Array<ResPromo>): void {
    localStorage.setItem(LocalStorageKeys.OBJECT_DATA.replace('{id}', 'PRE-BKG-PROMOS'), JSON.stringify(promos));
  }

  retrivePromoCache() {
    const p:Array<ResPromo> = [] as ResPromo[];
    const rawDefinition = localStorage.getItem(LocalStorageKeys.OBJECT_DATA.replace('{id}', 'PRE-BKG-PROMOS'));
    if(!rawDefinition) return [] as ResPromo[];
    (JSON.parse(rawDefinition) as Array<any>).forEach(obj=>p.push(obj as ResPromo))
    this.currentPromos.next(p)
    return p;
  }
  storePromos(promos:Array<ResPromo>) {
    this.cachePromo(promos);
    this.currentPromos.next(promos);
  }
  //check if category promo is elegible for automatic apply
  checkPromoConstraintsForCategoryPromo(p:ResPromo,suiteCategoryID:number[],voyageID:number[]) {
    if(p.isCategoryPromo) return false;
    if(!suiteCategoryID.includes(p.suiteCategoryID)) return false;

    let ok=false;
    p.voyageID.split(',').forEach(v=>{
      if(ok) return;
      ok = voyageID.includes(+v)
    });
    return ok;
  }
  getSelectedPromo(suiteCategoryID:number[],voyageID:number[]) {

    const all = this.currentPromos.getValue();
    //1] retrive all selected promo
    const p = all.filter(p=>p.promoActionTypeID!==2 && p.isSelected);

    //2] add category promo
    const validCategoryPromo = all.filter(cp=>this.checkPromoConstraintsForCategoryPromo(cp,suiteCategoryID,voyageID))
    validCategoryPromo.forEach(cp=>{
        if(!p.find(item=>item.suiteCategoryID===cp.suiteCategoryID))
          p.push(cp);
      });

    return p;

  }
  storePackage(pkg:ReservationBasePackage) {
    this.currentPackage.next(pkg);
  }
  getCurrentPackage() {
    return this.currentPackage.getValue();
  }

  currentSuiteAllocations:BehaviorSubject<ResProductAvailability> = new BehaviorSubject(ResProductAvailability.emptyValue())
  setSuiteAllocations(suiteAllocations:ResProductAvailability) {
    this.currentSuiteAllocations.next(suiteAllocations)
  }
  currentTransferAllocations:BehaviorSubject<ResProductAvailability> = new BehaviorSubject(ResProductAvailability.emptyValue())
  setTansferAllocations(transferAllocations:ResProductAvailability) {
    this.currentTransferAllocations.next(transferAllocations)
  }
  /**
   * Set configured guest code placeholders
   *
   * @param {number} [guestCodAdultPlaceholder]
   * @param {number} [guestCodChildPlaceholder]
   * @memberof ResPreBookingUtility
   */
  setGuestPlaceholders(guestCodAdultPlaceholder?: number, guestCodChildPlaceholder?: number) {
    if (guestCodAdultPlaceholder) {
      this._guestCodAdultPlaceholder = guestCodAdultPlaceholder;
    }
    if (guestCodChildPlaceholder) {
      this._guestCodChildPlaceholder = guestCodChildPlaceholder;
    }
  }
  /**
   * Get current selected products
   *
   * @param {ResProductType} productCode
   * @param {number[]} [productIDs=[]]
   * @param {*} [filters]
   * @return {*}  {Promise<any[]>}
   * @memberof ResPreBookingUtility
   */
  getProducts(productCode: ResProductType, productIDs: number[] = [], filters?: any): Promise<ReservationCruise[]> {
    return new Promise<any[]>((resolve) => {
      if (productIDs.length === 0) {
        resolve([]);
      } else if (productIDs.every(pID => this.productIDs.includes(pID)) && this.products.length > 0) {
        resolve(this.products);
      } else {
        this._loadProducts(productCode, filters).then(isLoadOk => {
          resolve(isLoadOk ? this.products : []);
        });
      }
    });
  }
  /**
   * Load selected products from server
   *
   * @private
   * @param {ResProductType} productCode
   * @param {*} [filters]
   * @return {*}  {Promise<boolean>}
   * @memberof ResPreBookingUtility
   */
  private _loadProducts(productCode: ResProductType, filters?: any): Promise<boolean> {
    const reservationEntityService = AppModule.injector.get(ReservationEntityService);

    switch (productCode) {
      case 'CRU':
        this.loading = true;
        return new Promise<boolean>((resolve) => {
          reservationEntityService.getCruisesPaged(filters).subscribe({
            next: (response) => {
              this.productIDs = response.content.map(item => item.voyageID);
              this.productIDsBS.next(this.productIDs);
              this.products = response.content;
              this.loading = false;
              resolve(true);
            },
            error: (error: IDynamicApiResponse) => {
              this.rescomService.utilityDialogs.error({
                message: '',
                error: error.Errors?.map((e: IDynamicApiErrorResponse) => e.ErrorDesc),
                title: `Error while retrieving cruise`,
              });
              this.loading = false;
              resolve(false);
            },
          });
        });
      case 'PKG':
        this.loading = true;
        return new Promise<boolean>((resolve) => {
          reservationEntityService.getCruisesPaged(filters).subscribe({
            next: (response) => {
              this.productIDs = response.content.map(item => item.voyageID);
              this.productIDsBS.next(this.productIDs);
              this.products = response.content;
              this.loading = false;
              resolve(true);
            },
            error: (error: IDynamicApiResponse) => {
              this.rescomService.utilityDialogs.error({
                message: '',
                error: error.Errors?.map((e: IDynamicApiErrorResponse) => e.ErrorDesc),
                title: `Error while retrieving cruise`,
              });
              this.loading = false;
              resolve(false);
            },
          });
        });
      default:
        return new Promise<boolean>((resolve) => resolve(false));
    }
  }
  /**
   * Create a quote or booking (normal, GTY, or WL)
   *
   * @param {ResPreBookingUtilityBkgCreationParams} params
   * @return {*}  {Promise<IResBookingCreateApiResponse>}
   * @memberof ResPreBookingUtility
   */
  async createBookingOrQuote(params: ResPreBookingUtilityBkgCreationParams): Promise<IResBookingCreateApiResponse> {

    return new Promise<IResBookingCreateApiResponse>( async (resolve, reject) => {
      const airService = AppModule.injector.get(ReservationAirService);
      const reservationApiService = AppModule.injector.get(ReservationApiService);
      const coreLogService = AppModule.injector.get(EidosLogService);

      const {
        owner,
        action,
        unchecked,
        currency,
        priceTypeID,
        loyaltyDiscountId,
        suites,
        voyages,
        packages,
        bookCamp,
        accomodations,
        transfers,
        bkgID,
        companyID,
        bookVilla
      } = params;

      let guests: Array<IReservationApiParametersGuest> = [];

      let savedBkgListCurrent: IResApiGuestCheckBookingExistsResult[] = [];
      let savedBkgListPrevious: IResApiGuestCheckBookingExistsResult[] = [];
      let savedBkgListNext: IResApiGuestCheckBookingExistsResult[] = [];
      let savedError: IDynamicApiResponse | undefined;

      if (owner.isDirect() && owner.individualID) {
        const ok = await new Promise((resolve) => {
          reservationApiService.guestCheckBookingExists({
            IndividualID: owner.individualID!,
            VoyageTable: voyages.map(v => ({ VoyageID: v.voyageID })),
          }).subscribe({
            next: async (bkgList) => {

              savedBkgListCurrent = bkgList.current;
              savedBkgListPrevious = bkgList.previous;
              savedBkgListNext = bkgList.next;

              if (!savedBkgListCurrent.length && !savedBkgListNext.length && !savedBkgListPrevious.length ) return resolve(true)

              const { pluralLabel, anotherBookingLabel } = (() => {
                const totalBookings = bkgList.current.length + bkgList.next.length + bkgList.previous.length
                return totalBookings > 1 ? { pluralLabel: 's', anotherBookingLabel: 'other bookings' } : { pluralLabel: '', anotherBookingLabel: 'another booking'}
              })()

              let titleVoyages = ``;
              let bookingMessage = `${owner.displayName()} has ${anotherBookingLabel} on the following voyage${pluralLabel}:<br/><br/>`;

              if (savedBkgListCurrent.length > 0) {
                bookingMessage += `<span style="font-weight:bold">Current</span> <br/>  ${savedBkgListCurrent.map(b => `Booking: ${b.BkgID}, Voyage number: ${b.VoyageNumber}`).join('<br/>')}<br/>`;
              }
              if (savedBkgListPrevious.length > 0) {
                bookingMessage += `<span style="font-weight:bold">Previous </span><br/> ${savedBkgListPrevious.map(b => `Booking: ${b.BkgID}, Voyage number: ${b.VoyageNumber}`).join(' ')} <br/>`;
              }
              if (savedBkgListNext.length > 0) {
                bookingMessage += `<span style="font-weight:bold">Next</span> <br/> ${savedBkgListNext.map(b => `Booking: ${b.BkgID}, Voyage number: ${b.VoyageNumber}`).join(' ')}<br/>`;
              }

              if (savedBkgListCurrent.length > 0 && savedBkgListNext.length < 1  && savedBkgListPrevious.length < 1) {
                titleVoyages = `SAME`;
              } else if (savedBkgListPrevious.length > 0 && savedBkgListNext.length < 1) {
                titleVoyages = `PREVIOUS`
              } else if (savedBkgListNext.length > 0 && savedBkgListPrevious.length < 1) {
                titleVoyages = `NEXT`;
              } else if (savedBkgListPrevious.length > 0 && savedBkgListNext.length > 0) {
                titleVoyages += ` PREVIOUS / NEXT`;
              }

              bookingMessage += `<br/><span style="font-weight:bold">If an EI certificate is applied to one of these booking${pluralLabel}, no other${pluralLabel} can be applied</span><br/><br/>
              Do you want to continue?`;

              let titleMessage = `BOOKING${pluralLabel.toUpperCase()} ON ${titleVoyages} VOYAGE${pluralLabel.toUpperCase()}`

              const confirm = await this.rescomService.utilityDialogs.confirm({
              message: bookingMessage,
              title: titleMessage,
              });

              resolve(confirm);
            },
            error: (err: IDynamicApiResponse) => {
              coreLogService.logDebug(EidosLogSeverity.Error, 'API:IDynamicApiResponse', err);
              savedError = err
              resolve(false)
            },
          });
        });

        if (!ok) {
          const hasCurrent = savedBkgListCurrent.length > 0;
          const hasPrevious = savedBkgListPrevious.length > 0;
          const hasNext = savedBkgListNext.length > 0;

          if (hasCurrent || hasPrevious || hasNext) {
            return reject({});
          } else {
            return reject(savedError);
          }
        }

      }

      const rooms:IReservationApiParametersRoomConfHdr[] =[]
      voyages.forEach(v=> {
        accomodations.forEach((a,idx)=>{
          rooms.push({
            RoomID: a.RoomID,
            AdultNum: a.Adult,
            ChildNum: a.Child,
            Child2Num: a.Child2,
            InfantNum: a.Infant,
            VoyageID: v.voyageID,
            SuiteCapacityCod:undefined,
            ServiceTypeID: a.ServiceTypeID,
            accomodationIdx:idx,
          })
        })
      })
      packages.forEach(p=> {
        accomodations.forEach((a,idx)=>{
          if(rooms.find(r=>r.RoomID===a.RoomID)) return
          rooms.push({
            RoomID: a.RoomID,
            AdultNum: a.Adult,
            ChildNum: a.Child,
            Child2Num: a.Child2,
            InfantNum: a.Infant,
            PackageID: p.packageID,
            SuiteCapacityCod:undefined,
            ServiceTypeID: a.ServiceTypeID,
            accomodationIdx:idx,
          })
        })
      })
      bookCamp.forEach(c=> {
        accomodations.filter(a=>a.RoomID===c.RoomID).forEach((a,idx)=>{
          rooms.push({
            RoomID: a.RoomID,
            AdultNum: a.Adult,
            ChildNum: a.Child,
            Child2Num: a.Child2,
            InfantNum: a.Infant,
            CampID: c.PropertyID,
            SuiteCapacityCod:undefined,
            ServiceTypeID: a.ServiceTypeID,
            accomodationIdx:idx,
          })
        })
      })
      //add tranferts
      voyages.forEach(v=> {
        transfers.forEach((a,idx)=>{
          rooms.push({
            RoomID: a.RoomID,
            AdultNum: a.Adult,
            ChildNum: a.Child,
            Child2Num: a.Child2,
            InfantNum: a.Infant,
            VoyageID: v.voyageID,
            SuiteCapacityCod:undefined,
            ServiceTypeID: a.ServiceTypeID,
            accomodationIdx:idx,
          })
        })
      })
      packages.forEach(p=> {
        transfers.forEach((a,idx)=>{
          rooms.push({
            RoomID: a.RoomID,
            AdultNum: a.Adult,
            ChildNum: a.Child,
            Child2Num: a.Child2,
            InfantNum: a.Infant,
            PackageID: p.packageID,
            SuiteCapacityCod:undefined,
            ServiceTypeID: a.ServiceTypeID,
            accomodationIdx:idx,
          })
        })
      })
      bookCamp.forEach(c=> {
        transfers.filter(a=>a.RoomID===c.RoomID).forEach((a,idx)=>{
          rooms.push({
            RoomID: a.RoomID,
            AdultNum: a.Adult,
            ChildNum: a.Child,
            Child2Num: a.Child2,
            InfantNum: a.Infant,
            CampID: c.PropertyID,
            SuiteCapacityCod:undefined,
            ServiceTypeID: a.ServiceTypeID,
            accomodationIdx:idx,
          })
        })
      })



      const suitesWithoutAccomodation = suites.filter(s=>s.RoomID===0)
      const suitesWithAccomodation = suites.filter(s=>s.RoomID!==0)
      suitesWithoutAccomodation.forEach((s,idx)=>{
        const ns = Object.assign({}, s)
        if(idx<accomodations.length) {
          ns.RoomID=accomodations[idx].RoomID
        } else {
          ns.RoomID=accomodations[0]?.RoomID ?? 1
        }
        suitesWithAccomodation.push(ns)
      })


      //Add placeholder for each accomodations
      let tempGuestCod=0
      accomodations.forEach(a=>{
        let adult = 0
        let child = 0
        let child2 = 0
        let infant = 0
        a.Guests.forEach(g=> {
          let guestType = ResGuestType.Adult
          switch(g.guestType) {
            case 'Infant':
              guestType = ResGuestType.Infant
              infant++
              break;
            case 'Child':
              guestType = ResGuestType.Child
              child++
              break;
            case 'Child1':
              guestType = ResGuestType.Child2
              child2++
              break;
            default:
              adult++
              break
          }
          tempGuestCod++
          guests.push({
            DirectID: g.individualID,
            LastName: g.lastName,
            FirstName: g.firstName,
            GuestType: guestType,
            RoomID: a.RoomID,
            TempGuestCod: tempGuestCod,
          })

        })

        //add missing Adult
        if(adult<a.Adult) {
          //no guests case
          if (guests.length===0 && owner.isDirect() && !params.cloneBooking) {
            adult++
            tempGuestCod++
            guests.push({
              DirectID: owner?.individualID,
              LastName: owner?.lastName,
              FirstName: owner?.firstName,
              GuestType: ResGuestType.Adult,
              RoomID: a.RoomID,
              TempGuestCod: tempGuestCod,
            })
          }

          [...Array(a.Adult-adult).keys()].map((x) => {
            tempGuestCod++
            guests.push({
                DirectID: this._guestCodAdultPlaceholder,
                LastName: 'Quote',
                FirstName: (x + adult + 1).toString(),
                GuestType: ResGuestType.Adult,
                RoomID: a.RoomID,
                TempGuestCod: tempGuestCod,
              })
            });
        }

        //add missing Child
        if(child<a.Child) {
          [...Array(a.Child-child).keys()].map((x) => {
            tempGuestCod++
            guests.push({
              DirectID: this._guestCodChildPlaceholder,
              LastName: 'Quote',
              FirstName: (x + child + 1).toString(),
              GuestType: ResGuestType.Child,
              RoomID: a.RoomID,
              TempGuestCod: tempGuestCod,
            })
          });
        }

        //add missing Child2
        if(child2<a.Child2) {
          [...Array(a.Child2-child2).keys()].map((x) => {
            tempGuestCod++
            guests.push({
              DirectID: this._guestCodChild2Placeholder,
              LastName: 'Quote',
              FirstName: (x + child2 + 1).toString(),
              GuestType: ResGuestType.Child2,
              RoomID: a.RoomID,
              TempGuestCod: tempGuestCod,
            })
          });
        }
        //add missing Infant
        if(infant<a.Infant) {
          [...Array(a.Infant-infant).keys()].map((x) => {
            tempGuestCod++
            guests.push({
              DirectID: this._guestCodInfantPlaceholder,
              LastName: 'Quote',
              FirstName: (x + infant + 1).toString(),
              GuestType: ResGuestType.Infant,
              RoomID: a.RoomID,
              TempGuestCod: tempGuestCod,
            })

          });
        }
      })

      let guestAdult = accomodations.reduce((acc,cur)=>acc+cur.Adult,0);
      let guestChild = accomodations.reduce((acc,cur)=>acc+cur.Child+cur.Child2+cur.Infant,0);

      const quotePkgOptsParams: Array<IReservationApiParametersPackage> = voyages
        .filter(v => suites.find(s => s.VoyageID === v.voyageID) != undefined)
        .flatMap(v =>
          suitesWithAccomodation.filter(s => s.VoyageID === v.voyageID)
                    .map(currentSuite => {
                      return {
                        VoyageID: v.voyageID,
                        PackageID: v.packageID,
                        ServiceID: v.serviceID,
                        OptionID: v.optionID,
                        BkgCurrency: currency,
                        PriceTypeID: priceTypeID,
                        PriceCategoryID: currentSuite.SuiteCategoryID,
                        SuiteCategoryID: currentSuite.SuiteCategoryID,
                        SuiteID: currentSuite!.SuiteNumber,
                        SuiteCapacityID: currentSuite.SuiteCapacityID,
                        Adult: guestAdult,
                        Child: guestChild,
                    }})
        );

      const suiteDataList: Array<IReservationApiParametersSuite> | undefined = action === 'Quote' ? undefined : []
      if(!!suiteDataList) {
        rooms.forEach(r=>{
          const currentSuite = suitesWithAccomodation.filter(s=>s.VoyageID).find(s => s.RoomID === r.RoomID);
          if(currentSuite) {
            suiteDataList.push({
              VoyageID: r.VoyageID!,
              SuiteCategoryID: currentSuite.SuiteCategoryID,
              SuiteCategoryCod: currentSuite.SuiteCategoryCod,
              SuiteCapacityID: currentSuite.SuiteCapacityID,
              SuiteNumber: currentSuite.IsGty !=='Y' ? currentSuite.SuiteNumber : undefined,
              IsGty: currentSuite.IsGty,
              RoomID: currentSuite.RoomID
            })

          }
        })
      }
      // voyages.filter(v => suites.find(s => s.VoyageID === v.voyageID) != undefined)
      //   .map(v => {
      //     const currentSuite = suites.find(s => s.VoyageID === v.voyageID);
      //     return {
      //       VoyageID: v.voyageID,
      //       SuiteCategoryID: currentSuite!.SuiteCategoryID,
      //       SuiteCategoryCod: currentSuite!.SuiteCategoryCod,
      //       SuiteCapacityID: currentSuite!.SuiteCapacityID,
      //       SuiteNumber: currentSuite!.SuiteNumber,
      //       IsGty: currentSuite!.IsGty,
      //       RoomID: currentSuite!.RoomID
      //     };
      //   });

      params.bookCamp.filter(bc=>(bc.ServiceID ?? 0) > 0).forEach(bc=> {
        const interval = Interval.fromDateTimes(bc.TravelStartDate,DateTime.fromJSDate(bc.TravelEndDate).plus({day: 1}))

        interval.splitBy({day: 1}).forEach(dt=>{
          quotePkgOptsParams.push({
            ServiceID:bc.ServiceID,
            OptionID:bc.OptionID,
            ItineraryDate: dt.start.toJSDate(),
            Alternative: bc.OnRequest ? 'Y' : 'N',
            Price: bc.SellingPrice,
            ROE: bc.ROE,
            Markup: bc.Markup,
          })
        })

      })
      const bkgCamps = params.bookCamp.filter(bc=>(bc.ServiceID ?? 0) === 0)

      let quoteParams: IResApiParametersQuoteCreate = {
        PriceTypeID: priceTypeID,
        BkgCurrency: currency,
        OrganizationID: 1,
        AgencyID: owner.agencyID,
        AgencyContacts: owner.agencyContacts,
        OwnerType: owner.ownerType,
        AgentID: owner.agentID ?? owner.individualID,
        AgentContacts: owner.displayContacts().join(', '),
        CommunicationEmail: owner.email,
        Action: action,
        Unchecked: unchecked ? 'Y' : 'N',
        PackageOptions: quotePkgOptsParams,
        NewSuite: suiteDataList,
        GrpId: this.resService.environmentParameters.group.getValue(),
        Guests: guests,
        AirPortFrom: airService.selectedAirportFrom,
        AirClassFrom: airService.selectedAirClassFrom,
        AirPortTo: airService.selectedAirportTo,
        AirClassTo: airService.selectedAirClassTo,
        PromoID: params.promos.filter(p=>!!p.promoID).map(p=>p.promoID).join(','),
        Package: packages.map(p=>{
          return {
              PackageID:p.packageID,
              PackageTravelDateID:p.packageTravelDateID,
              GuestCod: p.guestCod,
              RetailPrice: p.retailPrice ? 'Y':  'N',
            }
          }),
        BookCamp: bkgCamps,
        RoomConfHdr: rooms,
        BkgID: bkgID,
        CompanyID: companyID
      };

      if (loyaltyDiscountId && loyaltyDiscountId > 0) {
        quoteParams.LoyaltyDiscountId = loyaltyDiscountId;
      }
      // debugger
      if (bookVilla) {
				quoteParams.BookVilla = accomodations.map(a => ({
					VillaID: bookVilla?.villaID,
					DateFrom: bookVilla?.dateFrom,
					DateTo: bookVilla?.dateTo,
					RoomID: a.RoomID,
				}))
			}

      reservationApiService.createBooking(quoteParams).subscribe({
        next: (response: IResBookingCreateApiResponse) => {
          this.resService.resetPreBookingDefaults()
          resolve(response);
        },
        error: (error: IDynamicApiResponse) => {
          reject(error);
        }
      });
    });
  }
}
