import { Component, TemplateRef, ViewChild } from '@angular/core';
import { IEidosIcon } from '@app/core/config/environment.interface';
import { EidosModalService } from '@app/core/components/eidos-modal/eidos-modal.service';
import { takeUntil } from 'rxjs';
import { EidosBaseComponent } from '../eidos-base.component';
import { IEidosLightFormField } from '../eidos-light-form/eidos-light-form.component';
import { SafeHtml } from '@angular/platform-browser';
import { AppModule } from '@app/app.module';

export enum IEidosModalType {
  Basic = 'basic',
  Structured = 'structured',
  Custom = 'custom'
}

export interface IEidosModalConfig {
  modalID?: number
  modalKey: string;
  type: IEidosModalType;
  title?: () => string; // for basic and structured type
  subtitle?: () => string; // for structured type
  body?: () => string // for basic type
  buttonsConfig?: IEidosModalButtonConfig[]; // for basic and structured type
  template?: () => TemplateRef<any>; // for structured and custom type
  width?: string;
  height?: string;
  position?: 'center' | 'left' | 'right';
  cssClass?: string;
  closeOnOutsideClick?: boolean
  open?: boolean
  formFields?: IEidosLightFormField[]; // for structured type
  formValues?: any; // for structured type
  doNotUseStackLogic?: boolean; // if true, when the modal will be closed, stack logic will not be applied
  iframeUrl?: SafeHtml;
  onOpened?: (e: any) => void;
  onClosed?: (e: any) => void;
}

export class EidosModalConfig {
  modalID?: number
  modalKey: string;
  type: IEidosModalType;
  title?: () => string; // for basic and structured type
  subtitle?: () => string; // for structured type
  body?: () => string // for basic type
  buttonsConfig?: IEidosModalButtonConfig[]; // for basic and structured type
  template?: () => TemplateRef<any>; // for structured and custom type
  width?: string;
  height?: string;
  position?: 'center' | 'left' | 'right';
  cssClass?: string;
  closeOnOutsideClick?: boolean
  open?: boolean
  formFields?: IEidosLightFormField[]; // for structured type
  formValues?: any; // for structured type
  doNotUseStackLogic?: boolean; // if true, when the modal will be closed, stack logic will not be applied
  iframeUrl?: SafeHtml;
  onOpened?: (e: any) => void;
  onClosed?: (e: any) => void;

  eidosModalService: EidosModalService;

  constructor(config: IEidosModalConfig) {
    this.modalID = config.modalID;
    this.modalKey = config.modalKey;
    this.type = config.type;
    this.title = config.title;
    this.subtitle = config.subtitle;
    this.body = config.body;
    this.buttonsConfig = config.buttonsConfig;
    this.template = config.template;
    this.width = config.width;
    this.height = config.height;
    this.position = config.position;
    this.cssClass = config.cssClass;
    this.closeOnOutsideClick = config.closeOnOutsideClick;
    this.open = config.open;
    this.formFields = config.formFields;
    this.formValues = config.formValues;
    this.doNotUseStackLogic = config.doNotUseStackLogic;
    this.iframeUrl = config.iframeUrl;
    this.onOpened = config.onOpened;
    this.onClosed = config.onClosed;

    this.eidosModalService = AppModule.injector.get(EidosModalService);
  }

  openModal() {
    this.eidosModalService.openModal(this);
  }

  closeModal() {
    this.eidosModalService.closeModal(this.modalKey);
  }
}

export interface IEidosModalButtonConfig {
  text?: () => string;
  size?: string;
  stylingMode?: string;
  position: 'center' | 'left' | 'right';
  onClick?: () => void;
  disabled?: () => boolean;
  hidden?: () => boolean;
  iconConfiguration?: IEidosIcon;
}

export interface IEidosModalInfoData {
  title: string;
  subtitle?: string;
  message?: string;
  width?: string;
  height?: string;
  position?: 'center' | 'left' | 'right';
  iframeUrl?: string;
  closeCallback?: () => void;
}

export interface IEidosModalInfoConfirm extends IEidosModalInfoData {
  captionOk?: string;
  captionCancel?: string;
}
export interface IEidosModalInfoSendEmail extends IEidosModalInfoConfirm {
  to: string;
  cc?: string;
  ccn?: string;
  subject: string;
  message: string;
  template: string;
  attachments: Array<File>;
}
// USAGE EXAMPLES:

// @ViewChild('guestCrudTemplate') guestCrudTemplate!: TemplateRef<any>;

// openGuestCrud() {
//   this.eidosModalService.openModal(this.modalConfigExample);
// }

// closeGuestCrud() {
//   this.eidosModalService.closeModal(this.modalConfigExample.modalKey);
// }

// CUSTOM MODAL:

// modalConfigExample: IEidosModalConfig = {
//   modalKey: 'guestCrudModal',
//   type: IEidosModalType.Custom,
//   width: '80%',
//   height: '100%',
//   position: 'right',
//   template: () => this.guestCrudTemplate,
//   onClosed: () => this.isEditingGuest = undefined,
// }

// BASIC MODAL:

// modalConfigExample: IEidosModalConfig = {
//   modalKey: 'guestCrudModal',
//   type: IEidosModalType.Custom,
//   width: '40%',
//   height: '40%',
//   position: 'center',
//   title: () => 'Title of the modal',
//   body: () => 'Body of the modal',
//   onClosed: () => console.log('Modal closed'),
// }



@Component({
  selector: 'eidos-modal',
  templateUrl: './eidos-modal.component.html',
  styleUrls: ['./eidos-modal.component.scss']
})
export class EidosModalComponent extends EidosBaseComponent {
  IEidosModalType = IEidosModalType

  @ViewChild('basicTemplate') basicTemplate!: TemplateRef<any>;
  @ViewChild('structuredTemplate') structuredTemplate!: TemplateRef<any>;

  openModals: IEidosModalConfig[] = [];

  constructor(protected eidosModalService: EidosModalService) {
    super();

    this.eidosModalService.openModals.pipe(takeUntil(this.subscription)).subscribe((openModals: IEidosModalConfig[]) => {
      this.openModals = openModals;
    });
  }

  getModalIndexByID(modalID: number): number {
    return this.openModals.findIndex((modal: IEidosModalConfig) => modal.modalID === modalID);
  }

  onModalClosed(modalID: number, e: any) {
    const modalIndex = this.getModalIndexByID(modalID);
    if (modalIndex == -1) return

    this.openModals[modalIndex]?.onClosed?.(e);

    this.eidosModalService.closeModalByID(modalID);
  }

  getButtonsConfig(modal: IEidosModalConfig, position: 'center' | 'left' | 'right'): IEidosModalButtonConfig[] {
    return modal.buttonsConfig?.filter((button: IEidosModalButtonConfig) => button.position === position) || [];
  }

}
