<dx-date-box [ngClass]="cssClass" [id]="cssId"  width="100%"
  placeholder="{{ placeholder ?? '' | transloco }}"
  [disabledDates]="disabledDates ?? []" [type]="dateValueType"
  [min]="min ?? 0" [max]="max ?? 8640000000000000" 
  [pickerType]="dateValueType === 'time' ? 'list' : pickerType"
  [showClearButton]="showClearButton"
  [displayFormat]="displayFormat" [disabled]="disabled"
  (valueChange)="onSelfChangeValue($event);"
  (onFocusIn)="onFocusIn"(onFocusOut)="onFocusOut" (onClosed)="onClosed($event)"
  [(value)]="internalValue" >
  <dx-validator [validationGroup]="validationGroupName">
      <dxi-validation-rule *ngIf="required"
        type="required" [message]="requiredMessage ?? 'Field is required'">
      </dxi-validation-rule>
    </dx-validator>
</dx-date-box>