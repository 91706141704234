import { SafeBaseObject } from "@app/core/models/base-object.models";
import { DateTime } from "luxon";
import { ResBooking } from "./res-booking.model";

/**
 * Possible checkout layout
 *
 * CRYSTAL (default)
 * AANDK.UK: Abercrombie & Kent
 *
 */
type ResPaymentCheckoutLayout = 'CRYSTAL' | 'AANDK.UK' | 'PHILANTROPY' | 'SANCTUARYPA';

export enum ResRequestPaymentStatus {
  OK = "OK",
  KO = "KO",
  In_Progress = "PR", // Payment in progress
  Expired = "EX",
  Refunded = "RF",
  Unreferenced_Refunded = "UR",
  Pending = "PN", // No answer from payment iframe
  Aborted = "AB", // Go back: Payment iframe closed
  Voided = "VD", // OK but further voided
  Error = 'ER' //Error in procedure
}

export const PAYMENT_RESULTS_MESSAGE: { [key: string]: string } = {
  OkPayEn: "'The payment has been successfully submitted. Check status on history view.",
  OK: "The payment has been confirmed.",
  Error: "An error is occurred in payment request, please retry.", //Error in procedure
  AbortedByUser: "The payment has been canceled by user.", // Go back: Payment iframe closed
  AbortedByTimeout: "The payment has been canceled due to operation timeout.", // Go back: Timeout expired
  Voided: "The payment has been voided." // OK but further voided -> WTF?
}

export interface IResPaymentResultEvent {
  status: ResRequestPaymentStatus,
  message?: string
}

/**
 * After payment redirection status
 * AKA Possible values of p/P parameter in checkout/payment page
 *
 * @export
 * @enum {number}
 */
export enum ResPaymentRedirectionStatus {
  KO = "0",
  OK = "1"
}

/**
 * Payment request info response from API
 *
 * @export
 * @interface IResPaymentApiRequestInfo
 */
export interface IResPaymentApiRequestInfo {
  Info: Array<{
    Amount: number;
    BkgID: number;
    CurrencyCod: string;
    ItemName: string;
    PayGUID: string;
    RequestID: string;
    VoaygeID: string;
    Result: string;
    Message: string;
    Description?: string;
    IsPostponed?: boolean;
    IsFloatingDeposit?: boolean;
    OrderID?: string;
    SendPaymentEmail?: string;
    Status?: string;
    Url?: string;
    CheckoutLayout?: ResPaymentCheckoutLayout
  }>;
  HasError: boolean;
}
export interface IResPaymentApiEnquireInfo {
  result: string;
  message: string;
  status: string;
  hasError: boolean;
}
/**
 * Payment request response from API
 *
 * @export
 * @interface IResPaymentApiRequest
 * @extends {IResPaymentApiRequestInfo}
 */
export interface IResPaymentApiRequest extends IResPaymentApiRequestInfo {
  /**
   * Checkout layout
   *
   * @type {ResPaymentCheckoutLayout}
   * @memberof IResPaymentApiRequest
   */
  CheckoutLayout?: ResPaymentCheckoutLayout
  /**
   * Details to build the form to pay in a new page
   *
   * @type {{
   *     Url: string;
   *     Fields: { [key: string]: string };
   *     Method?: string;
   *     RequestID: string;
   *   }}
   * @memberof IResPaymentApiRequest
   */
  FormModel?: {
    Url: string;
    Fields: { [key: string]: string };
    Method?: string;
    RequestID: string;
  };
  /**
   * Details to build iframe to pay in iframe
   *
   * @type {{
   *     Url: string;
   *   }}
   * @memberof IResPaymentApiRequest
   */
  IframeModel?: {
    Url: string;
    ForceSelfPage?: string
  }
}

/**
 * Payment request confirmation model
 * (used for postponed payment)
 *
 * @export
 * @interface IResPaymentRequestConfirmation
 */
export interface IResPaymentRequestConfirmation {
  /**
   * Payment request ID
   *
   * @type {string}
   * @memberof IResPaymentRequestConfirmation
   */
  requestID: string;
  /**
   * Payment request result
   *
   * @type {"OK" | "WRN" | "ERR"}
   * @memberof IResPaymentRequestConfirmation
   */
  result: "OK" | "WRN" | "ERR";
  /**
   * Booking ID
   *
   * @type {number}
   * @memberof IResPaymentRequestConfirmation
   */
  bkgID: number;
  /**
   * Warning or Error message
   *
   * @type {string}
   * @memberof IResPaymentRequestConfirmation
   */
  message: string;
  /**
   * Is the payment postponed flag
   *
   * @type {boolean}
   * @memberof IResPaymentRequestConfirmation
   */
  isPostponed: boolean;
  /**
   * Checkout page layout
   *
   * @type {ResPaymentCheckoutLayout}
   * @memberof IResPaymentRequest
   */
  checkoutLayout?: ResPaymentCheckoutLayout;
}

/**
 * Payment request model
 *
 * @export
 * @interface IResPaymentRequest
 */
export interface IResPaymentRequest extends IResPaymentRequestConfirmation {
  /**
   * URL to be placed in
   * - iframe when mode == "iframe"
   * - payment form when mode == "form"
   *
   * @type {string}
   * @memberof IResPaymentRequest
   */
  url: string;
  /**
   * Payment amount
   *
   * @type {number}
   * @memberof IResPaymentRequest
   */
  amount: number;
  /**
   * Payment currency
   *
   * @type {string}
   * @memberof IResPaymentRequest
   */
  currency?: string;
  /**
   * Payment mode
   *
   * @type {"form" | "iframe" | "unknown"
   * }
   * @memberof IResPaymentRequest
   */
  mode: "form" | "iframe" | "_self" | "unknown";
  /**
   * Fields to be placed in payment form when mode == "form"
   *
   * @type {{ key: string, value: string }[]}
   * @memberof IResPaymentRequest
   */
  fields?: { key: string, value: string }[];
  /**
   * Method to be placed in payment form when mode == "form"
   *
   * @type {string}
   * @memberof IResPaymentRequest
   */
  method?: string;
}

/**
 * Payment request parameters
 *
 * @export
 * @interface IResPaymentRequestParams
 */
export interface IResPaymentRequestParams {
  /**
   * Booking ID
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  BkgID: number;
  /**
   * Booking voyage codes in format "..., ..."
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  Voyage: string;
  /**
   * Payer first name
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldName?: string,
  /**
   * Payer last name
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldLastName?: string,
  /**
   * Payer email
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldEmail?: string,
  /**
   * Payer address first line
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldAddress1?: string,
  /**
   * Payer address second line
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldAddress2?: string,
  /**
   * Payer city
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldCity?: string,
  /**
   * Payer ZIP code
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldPostalCode?: string,
  /**
   * Payer state
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldState?: string,
  /**
   * Payer country code
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldCountry?: string,
  /**
   * Payer birth date
   *
   * @type {DateTime}
   * @memberof IResPaymentRequestParams
   */
  CardHldBirthDate?: DateTime,
  /**
   * Payer phone
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CardHldPhone?: string,
  /**
   * Currency
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CurrencyCod: string,
  /**
   * Payment amount
   *
   * @type {number}
   * @memberof IResPaymentRequestParams
   */
  Amount: number,
  /**
   * The payment will be in iframe flag
   *
   * @type {boolean}
   * @memberof IResPaymentRequestParams
   */
  PaymentInIframe?: boolean,
  /**
   * Email to send the payment link
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  SendPaymentEmail: string,
  /**
   * Postponed payment flag
   *
   * @type {boolean}
   * @memberof IResPaymentRequestParams
   */
  PostponedPayment?: boolean;
  /**
   * Keep data (tokenize card) flag
   *
   * @type {boolean}
   * @memberof IResPaymentRequestParams
   */
  KeepData?: boolean;
  /**
   * Payment status
   *
   * @type {ResRequestPaymentStatus}
   * @memberof IResPaymentRequestParams
   */
  Status?: ResRequestPaymentStatus;
  /**
   * Payment response
   *
   * @type {ResRequestPaymentStatus}
   * @memberof IResPaymentRequestParams
   */
  Response?: ResRequestPaymentStatus;
  /**
   * Enable send CUSTOM FIELD to gateway (reuqire by amex)
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  UseAmexData?:boolean;
  /**
   * Passenger name (AMEX reuired)
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
   PassengerName?: string;
  /**
   * Departure Date (AMEX reuired: YYYMMDD)
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  DepartureDate?: string;
  /**
   * Passanger name (AMEX reuired)
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
   ArrivalDate?: string;
  /**
   * Cruise Ship name (AMEX reuired)
   *
   * @type {string}
   * @memberof IResPaymentRequestParams
   */
  CruiseShipName?: string;
  PayerGroupID?: number;
  ReasonCode: string;
  PaxGroupID?: number;
}
/**
 * Interface of a payment action (repay/refund/void) params
 *
 * @export
 * @interface IResPaymentActionParams
 */
export interface IResPaymentActionParams {
  OrderID: number;
  Reason: string;
  Amount?: number;
  Data?: string;
  BkgId?: number;
}
/**
 * Interface of transfer cash between bookings
 *
 * @export
 * @interface IResTransferCashBookingParams
 */
export interface IResTransferCashBookingParams {
  BkgIDFrom: number;
  BkgIDTo?: number;
  Amount: number;
}
/**
 * Interface of transfer cash between groups
 *
 * @export
 * @interface IResTransferCashGroupParams
 */
export interface IResTransferCashGroupParams {
  GrpIDFrom?: number;
  GrpIDTo?: number;
  Amount: number;
}
export type IResTransferCashParams = IResTransferCashBookingParams | IResTransferCashGroupParams;

/**
 * Reservation payment model
 *
 * @export
 * @class ResPayment
 * @extends {SafeBaseObject}
 */
export class ResPayment extends SafeBaseObject {

  public static INVALID_PAYMENT_ORDERID = -1;

  public static INVALID_PAYMENT_REQUEST(): IResPaymentRequest {
    return {
      result: 'ERR',
      bkgID: ResBooking.INVALID_BOOKING,
      requestID: '',
      message: '',
      isPostponed: false,
      mode: 'unknown',
      url: '',
      amount: 0
    }
  };

  public amount: number = 0;
  public bkgID: number = -1;
  public orderID: number = ResPayment.INVALID_PAYMENT_ORDERID;
  public cardType: string = "";
  public commission: boolean = false;
  public creditCardTypeID: number = -1;
  public currencyCod: string = "";
  public isCreditCard: boolean = false;
  public linkPayment: boolean = false;
  public linkExpireDate: DateTime = DateTime.min();
  public payerName: string = "";
  public paymentTransactionStatus: string = "";
  public cardNumber: string = '';
  public reason: string = "";
  public receiptCreditCardAuthorisation: number = -1;
  public receiptCreditCardEndDate: DateTime = DateTime.min();
  public receiptCreditCardIssue: DateTime = DateTime.min();
  public receiptCreditCardNameOnCard: string = "";
  public receiptCreditCardStartDate: DateTime = DateTime.min();
  public receiptCreditCardValidation: number = -1;
  public receiptDate: DateTime = DateTime.min();
  public receiptDescription: string = "";
  public receiptID: number = -1;
  public receiptNumber: number = -1;
  public receiptTotal: number = -1;
  public receiptTypeID: number = -1;
  public receiptTypeName: string = "";
  public sectionID: number = -1;
  public sendPaymentEmail: string = "";
  public url: string = "";
  public refundAvailable?: boolean;
  public voidAvailable?: boolean;
  public repayAvailable?: boolean;
  public maxRefundAmount: number = 0;
  public manualRefundAvailable?: string = "";
  public canCommissionRefund?: string = "";
  public unreferencedRefundAvailable:boolean = false;
  public mouseOverDescription:string = "";

  public get mappedPaymentTransactionStatus(): string {
    if (["UR", "RF", "VD"].includes(this.paymentTransactionStatus)) {
      return "OK";
    }

    const key = Object.keys(ResRequestPaymentStatus).find(x => (<any>ResRequestPaymentStatus)[x] == this.paymentTransactionStatus);
    return key?.replace('_', ' ') ?? "";
  }

  public get isValidForRefund(): boolean {
    return this.orderID !== ResPayment.INVALID_PAYMENT_ORDERID && !!this.refundAvailable;
  }

  public get isValidForVoid(): boolean {
    return this.orderID !== ResPayment.INVALID_PAYMENT_ORDERID && !!this.voidAvailable;
  }

  public get isValidForRepay(): boolean {
    return this.orderID !== ResPayment.INVALID_PAYMENT_ORDERID && !!this.repayAvailable;
  }

  public get isValidForUnrefRefund(): boolean {
    return this.orderID !== ResPayment.INVALID_PAYMENT_ORDERID && this.unreferencedRefundAvailable;
  }


  public get reasonOrDesc(): string {
    return this.receiptDescription ?? this.reason;
  }

  constructor(data?: IReservationApiPayment) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

  public override updateData(data: IReservationApiPayment): void {
    this.addMangledProperty(data);

    this.addDateTimeProperty("receiptCreditCardEndDate", data, "ReceiptCreditCardEndDate");
    this.addDateTimeProperty("receiptCreditCardIssue", data, "ReceiptCreditCardIssue");
    this.addDateTimeProperty("receiptCreditCardStartDate", data, "ReceiptCreditCardStartDate");
    this.addDateTimeProperty("receiptDate", data, "ReceiptDate");
    this.addDateTimeProperty("linkExpireDate", data, "LinkExpireDate");

    this.addBooleanProperty("commission", data, "Commission");
    this.addBooleanProperty("iSCreditCard", data, "IsCreditCard");
    this.addBooleanProperty("linkPayment", data, "LinkPayment");
    this.addBooleanProperty("refundAvailable", data, "RefundAvailable");
    this.addBooleanProperty("voidAvailable", data, "VoidAvailable");
    this.addBooleanProperty("repayAvailable", data, "RepayAvailable");
  }
}
export class ResNewPayment extends SafeBaseObject {
  bkgID = 0
  paymentDueDate = DateTime.invalid('empty')
  amount = 0
  isFinalPayment = false
  hasInsurance	= false
  reason = ''
  reasonCode = ''
  payer = new ResPaymentPayer()
  isSelected = false
  isDefault = false
  payerGroupID = 0
  payerGroupName = ''
  payerGroupType = ''

  constructor(data?:any){
    super()
    this.updateData(data)
  }
  override updateData(data?:any) {
    if(!data) return
    super.updateData(data)
  }
}
export class ResPaymentPayer extends SafeBaseObject {
  payerGroupID = 0
  payerGroupName = ''
  payerGroupType = ''

  lastName = ''
  firstName = ''
  email = ''
  birthDate = DateTime.invalid('empty')
  address1 = ''
  address2 = ''
  phone = ''
  zip1 = ''
  city = ''
  countryCod = ''
  stateCod = ''

  constructor(data?:any){
    super()
    this.updateData(data)
  }
  override updateData(data?:any) {
    if(!data) return
    super.updateData(data)
  }
}
/**
 * Reservation payment API model
 *
 * @export
 * @interface IReservationApiPayment
 */
export interface IReservationApiPayment {
  BkgID?: number;
  CardType?: string;
  Commission?: string;
  CreditCardTypeID?: number;
  CurrencyCod?: string;
  Guid?: string;
  IsCreditCard?: string;
  LinkPayment?: string;
  PayerName?: string;
  CardNumber?: string
  PaymentTransactionStatus?: string;
  ReceiptCreditCard?: number;
  ReceiptCreditCardAuthorisation?: number;
  ReceiptCreditCardEndDate?: string;
  ReceiptCreditCardIssue?: string;
  ReceiptCreditCardNameOnCard?: string;
  ReceiptCreditCardStartDate?: string;
  ReceiptCreditCardValidation?: number;
  ReceiptDate?: string;
  ReceiptDescription?: string;
  ReceiptID?: number;
  ReceiptNumber?: number;
  ReceiptTotal?: number;
  ReceiptTypeID?: number;
  ReceiptTypeName?: string;
  SectionID?: number;
  SendPaymentEmail?: string;
  Url?: string;
  RefundAvailable?: string;
  VoidAvailable?: string;
  RepayAvailable?: string;
  Reason?: string;
  ReceiptTime?: string;
  RequestID?: string;
  Amount?: number;
  LinkExpireDate?: string;
  OrderID?: number;
  MaxRefundAmount?: number;
  MouseOverDescription?: string;
}
