<h1 mat-dialog-title fxLayoutAlign="center">Financial Split</h1>

<div *ngIf="!loading; else loadingTemplate">
  <div mat-dialog-content>
    <dx-data-grid class="res-data-grid" height="100%" width="100%" [dataSource]="financialSplit" [showBorders]="true"
      [showRowLines]="true">

      <dxi-column dataField="payer"></dxi-column>
      <dxi-column dataField="totalCharge" width="150px" cellTemplate="AmountTemplete"></dxi-column>
      <dxi-column dataField="netCommission" width="150px" cellTemplate="AmountTemplete"></dxi-column>
      <dxi-column dataField="vatCommission" width="150px" cellTemplate="AmountTemplete"></dxi-column>
      <dxi-column dataField="totalCommission" width="150px" cellTemplate="AmountTemplete"></dxi-column>
      <dxi-column dataField="totalPaid" width="150px" cellTemplate="AmountTemplete"></dxi-column>
      <dxi-column dataField="totalDue" width="150px" cellTemplate="AmountTemplete"></dxi-column>

      <div *dxTemplate="let cellInfo of 'AmountTemplete'" style="text-align: right">
        {{ cellInfo.value | formatCurrency : data.bkgCurrency }}
      </div>

    </dx-data-grid>
  </div>

  <div class="d-flex justify-content-center my-3">
    <res-button stylingMode="contained" size="normal" text="close" (btnClick)="close()"></res-button>
  </div>
</div>


<ng-template #loadingTemplate>
  <eidos-progress-spinner></eidos-progress-spinner>
</ng-template>