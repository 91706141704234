import { BaseObject, SafeBaseObject } from '@common/models/base-object.models';
import { DateTime } from 'luxon';
import { IReservationApiParametersBookCamp, IReservationApiParametersSuite } from './res-booking.model';

export class ReservationOptionCost extends BaseObject {
  cost: number;
  currency: string;
  date: DateTime;
  dateCost: DateTime;
  optionCostPerDayID: number;
  optionID: number;
  optionName: string;
  serviceID: number;
  supplierID: number;

  constructor(data: IReservationApiOptionCost) {
    super();
    this.cost = 0;
    this.currency = '';
    this.date = DateTime.min();
    this.dateCost = DateTime.min();
    this.optionCostPerDayID = 0;
    this.optionID = 0;
    this.optionName = '';
    this.serviceID = 0;
    this.supplierID = 0;
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiOptionCost): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    this.addDateTimeProperty('dateCost', data, 'DateCost');
  }
}
export class ReservationOptionPrice extends BaseObject {
  price: number;
  currency: string;
  date: DateTime;
  datePrice: DateTime;
  pricePerDateID: number;
  optionID: number;
  optionName: string;
  serviceID: number;
  priceTypeID: number
  margin: number;
  rOE: number;
  snglSuppCommPerc: number;
  snglSuppType: string;
  snglSuppValue: number;


  constructor(data: IReservationApiOptionPrice) {
    super();
    this.price = 0;
    this.currency = '';
    this.date = DateTime.min();
    this.datePrice = DateTime.min();
    this.pricePerDateID = 0;
    this.optionID = 0;
    this.optionName = '';
    this.serviceID = 0;
    this.priceTypeID = 0;
    this.margin = 0;
    this.rOE = 0;
    this.snglSuppCommPerc = 0;
    this.snglSuppType = '';
    this.snglSuppValue = 0;
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiOptionPrice): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    this.addDateTimeProperty('datePrice', data, 'DatePrice');
  }

  get marginPercentage(): string {
    return this.margin != null ? `${this.margin}%` : '';
  }
}



export interface IReservationApiOptionCost {
  Cost?: number;
  Currency?: string;
  Date?: string;
  DateCost?: number;
  OptionCostPerDayID?: number;
  OptionID?: number;
  OptionName?: string;
  ServiceID?: number;
  SupplierID?: number;
}
export interface IReservationApiOptionPrice {
  Price?: number;
  Currency?: string;
  Date?: DateTime;
  DatePrice?: DateTime;
  PricePerDateID?: number;
  OptionID?: number;
  OptionName?: string;
  ServiceID?: number;
  PriceTypeID?: number;
  ROE?: number;
  SnglSuppCommPerc?: number;
  SnglSuppType?: string;
  SnglSuppValue?: number;
}

export interface IReservationGetBkgServicesParams {
  BkgID?: number;
  SectionID?: number;
  ServiceTypeID?: number;
  SearchText?: string;
  SupplierName?: string;
  OptionName?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  CountryCod?: string;
  CityID?: number;
  CityIDPost?: number;

  PageNum?: number
  PageSize?: number
}

export interface IReservationGetBkgOptionsParams {
  BkgID?: number;
  SectionID?: number;
  ServiceID?: number;
  SearchText?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  SupplierName?: string;
  OptionName?: string;
}
export interface IReservationAddServiceOptionParams {
  BkgID?: number;
  VersionID?: number;
  PackageOptions?: IReservationAddServiceOptionPackageParams[];
  ServiceIncluded?: IReservationAddServiceOptionPackageParams[];
  LoyaltyRedemption?: IReservationAddServiceOptionLoyaltyRedemption[];
  Certificate?: IReservationAddServiceOptionCertificate[];
  GuestCod?: string;
  Alternative?: string;
  NewSuite?: IReservationApiParametersSuite[]
  BookCamp?: IReservationApiParametersBookCamp[]
  KeySuiteID?: number;
  RoomID?: number;
  SuiteNum?: string;
}

export interface IReservationAddServiceOptionLoyaltyRedemption {
  OptionCod?: string;
  IndividualID?: number;
  TierID?: number;
  Note?: string;
}

export interface IReservationAddServiceOptionCertificate {
  CertificateID: number;
  BkgID: number;
  GuestCod: string;
  Amount: number;
  CurrencyCod: string;
}

export interface IReservationEditServiceOptionParams {
  BkgID?: number;
  SectionID?: number;
  Items?: IReservationEditServiceOptionItem[];
}

export interface IReservationEditServiceOptionItem {
  ItemID?: number;
  ItineraryDate?: DateTime;
  SellingPrice?: string;
  Commissionable?: string;
  ForcedSupplierID?: number;
}

export interface IReservationDeleteOptionPackageParams {
  BkgID?: number;
  GuestCod?: string;
  VersionID?: number;
  PackageOptions?: IReservationEditServiceOptionItem[];
}

export interface IReservationDeleteServiceOptionItem {
  ServiceID?: number;
  OptionID?: number;
  ItineraryDate?: DateTime;
  GuestCod?: string;
}

export interface IReservationAddServiceOptionPackageParams {
  ServiceID?: number;
  OptionID?: number;
  VoyageID?: number
  PackageID?: number
  ItineraryDate?: DateTime;
  Price?: number;
  Markup?: number;
  SBCMessage?: string;
  Quantity?: number;
  GuestTo?: string
  Alternative?: string
}
export interface IReservationUpdateMessageParams {
  BkgID?: number;
  OptionID?: number;
  ItemID?: number;
  GuestCod?: string;
  SectionID?: number;
  Message?: string;
}




export interface IReservationApiCloneServices {
  ServiceID: number
}

export interface IReservationApiGetOptions {
  ServiceID?: number;
  OptionID?: number;
  MinPax?: number;
  MaxPax?: number;
  OptionName?: string;
  OptionShortName?: string;
  OptionDesc?: string;
  Commissionable?: string;
  Refundable?: string;
  IsSBC?: string;
  IsIncluded?: string;
  IsActive?: string;
}

export interface IReservationApiGetOptionCost {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
}


export interface IReservationApiManageV2OptionCost {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
  CostSetup?: IReservationApiCostPriceSetup[]
}
export interface IReservationApiManageV2OptionPrice {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
  PriceTypesID?: string;
  PriceTypeID?: number;
  PriceSetup?: IReservationApiCostPriceSetup[];
  CurrencySetup?: IReservationCurrencySetup[];
  PriceSetupID?: number;
  CurrencyCod?: string;
}

export interface IReservationApiCostPriceSetup {
  PerDayID?: number;
  OptionID?: number;
  ServiceID?: number;
  DateStart?: string; // date per availability
  DateEnd?: string; // date per availability
  DateFrom?: DateTime; // date per cost
  DateTo?: DateTime; // date per cost
  Cost?: string;
  Currency?: string;
  PriceTypeID?: number;
  PriceSetUpID?: number;
  Margin?: string;
  ROE?: string;
  Price?: string;
  Status?: 'A' | 'D';
  SnglSuppCommPerc?: number;
  SnglSuppType?: string;
  SnglSuppValue?: number;
}

export interface IReservationCurrencySetup {
  Currency: string;
  Margin: number;
  ROE: number;
  FixedPrice: number;
}

export interface IReservationApiGetOptionPrice {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
  CurrencyCod?: string;
  PriceTypeID?: number;
}
export interface IReservationSuiteType {
  VoyageID: number;
  SuiteNumber?: number | undefined;
  SuiteCategoryID?: number;
  SuiteCategoryCod?: string | undefined;
  SuiteCapacityID?: number;
  IsGty: string;
}

export interface IReservationCheckComboAvailabilityParams {
  BkgID?: number;
  VersionID?: number;
  NewSuite: Array<IReservationSuiteType>
}

export class ReservationOptionAvailability extends BaseObject {
  availabilitybyOptionID: number;
  date: DateTime;
  propertyID: number;
  optionID: number;
  serviceID: number;
  initialQTY: number;
  onHoldQTY: number;
  initialAllotmentQTY: number;
  onHoldAllotmentQTY: number;
  priceTypeID: number;
  agencyID: number;

  constructor(data: IReservationApiOptionPrice) {
    super();
    this.availabilitybyOptionID = 0
    this.date = DateTime.min();
    this.propertyID = 0;
    this.optionID = 0;
    this.serviceID = 0;
    this.initialQTY = 0;
    this.onHoldQTY = 0;
    this.initialAllotmentQTY = 0;
    this.onHoldAllotmentQTY = 0;
    this.priceTypeID = 0;
    this.agencyID = 0;
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiOptionPrice): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
  }
}

export interface IReservationApiOptionAvailability {
  AvailabilitybyOptionID?: number;
  Date?: DateTime;
  PropertyID?: number;
  OptionID?: number;
  ServiceID?: number;
  InitialQTY?: number;
  OnHoldQTY?: number;
  InitialAllotmentQTY?: number;
  OnHoldAllotmentQTY?: number;
  PriceTypeID?: number;
  AgencyID?: number;
  Status?: 'A' | 'D';
}
export interface IReservationApiOptionAllocation {
  AllocationbyOptionID?: number;
  Date?: DateTime;
  PropertyID?: number;
  OptionID?: number;
  ServiceID?: number;
  InitialAllotmentQTY?: number;
  OnHoldAllotmentQTY?: number;
  Status?: string;
  PackageID?: number;
  PackageTravelDateID?: number
  Reason?: string;
}

export interface IReservationApiGetOptionAvailability {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
}
export interface IReservationApiGetOptionAllocation {
  ServiceID?: number;
  OptionID?: number;
  Year?: number;
}
export interface IReservationApiGetAllocationByOption {
  ServiceID: number;
  OptionID: number;
  Year: number;
  PackageID?: number;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  OnlyPkg?: string;
}

export interface IReservationApiManageAllocationByOption {
  ServiceID?: number;
	OptionID?: number;
	PackageID?: number;
	AllocationbyOptionIDs?: string;
	AllocationQTY?: number;
	Status?: string;
}

export interface IReservationApiGetOptionAllocationDetails {
  ServiceID?: number;
  OptionID?: number;
  PackageID?: number;
  DateFrom?: DateTime;
  DateTo?: DateTime;
}

export interface IReservationApiManageOptionAvailability {
  Availabilities: IReservationApiOptionAvailability[],
}
export interface IReservationApiManageOptionAllocation {
  Allocations: IReservationApiOptionAllocation[],
}

export interface IResApiReplaceOptionsParams {
  Opt2Replace: IResApiReplaceOption[]
  ReplaceByDate: 'Y' | 'N';
}

export interface IResApiReplaceOption {
  BkgID: number;
  ServiceID_OLD: number;
  OptionID_OLD: number;
  ServiceID_NEW: number;
  OptionID_NEW: number;
  ManageAvail: boolean;
  ItineraryDate?: DateTime;
}

export interface IResApiReplaceAvailability {
  IsAvl: number;
  BkgID: number;
  ServiceID_OLD: number;
  OptionID_OLD: number;
}

export interface IResApiReplaceStatus {
  BkgID: number;
  ServiceID: number;
  OptionID: number;
  Status: 'OK' | 'KO'
  StatusMessage: string;
}

export interface IResApiGetReplaceOptionsHistoryParams {
  BkgID?: number;
  ServiceID_OLD?: number;
  OptionID_OLD?: number;
  ServiceID_NEW?: number;
  OptionID_NEW?: number;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  Status?: 'OK' | 'KO';
}

export class ResReplaceHistory extends SafeBaseObject {
  iD: number = 0;
  [key: string]: any;

  constructor(data?: IResApiReplaceHistory) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IResApiReplaceHistory): void {
    this.addMangledProperty(data);
  }
}

export interface IResApiReplaceHistory {
  ID: number;
}


export interface IReservationGetAllocations{
ServiceID?: number;
OptionID?: number;
Date: DateTime;
InitialAllotmentQTY: number;
OnHoldAllotmentQTY: number;
ServiceName: string;
OptionName: string;
PackageNames: string;
}

export class ReservationGetAllocations extends SafeBaseObject {
  serviceID = 0;
  optionID = 0;
  date = DateTime.invalid('empty');
  initialAllotmentQTY = 0;
  onHoldAllotmentQTY = 0;
  serviceName = '';
  optionName = '';
  packageNames = ''

  constructor(data?: IReservationGetAllocations) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationGetAllocations): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    if(data.PackageNames) {
      this.packageNames = data.PackageNames.replace(/\|/g, '\n');
    }
  }
}

export interface IReservationOptionPriceAllocation {
  PackageID: number;
  PackageName: string;
  DateFrom: DateTime;
  DateTo: DateTime;
  InitialAllotmentQTY: number;
  OnHoldAllotmentQTY: number;
  AllocationbyOptionIDs: string;
  CanEdit: string;
  CanDelete: string;
}

export class ReservationOptionPriceAllocation extends SafeBaseObject {
  packageID = 0;
  packageName = '';
  dateFrom = DateTime.invalid('empty');
  dateTo = DateTime.invalid('empty');
  initialAllotmentQTY = 0;
  onHoldAllotmentQTY = 0;
  allocationbyOptionIDs = '';
  canEdit = false;
  canDelete = false;

  constructor(data?: IReservationOptionPriceAllocation) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationOptionPriceAllocation): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('dateFrom', data, 'DateFrom');
    this.addDateTimeProperty('dateTo', data, 'DateTo');
    this.addBooleanProperty('canEdit', data, 'CanEdit');
  }
}

export interface IReservationOptionPriceAllocationDetail {
  PackageID: number;
  PackageName: string;
  Date: DateTime;
  InitialAllotmentQTY: number;
  OnHoldAllotmentQTY: number;
  AllocationbyOptionID: number;
  CanEdit: string;
  CanDelete: string;
}

export class ReservationOptionPriceAllocationDetail extends SafeBaseObject {
  packageID = 0;
  packageName = '';
  date = DateTime.invalid('empty');
  initialAllotmentQTY = 0;
  onHoldAllotmentQTY = 0;
  allocationbyOptionID = 0;
  checked?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;

  constructor(data?: IReservationOptionPriceAllocationDetail) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationOptionPriceAllocationDetail): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('date', data, 'Date');
    this.addBooleanProperty('canEdit', data, 'CanEdit');
    this.addBooleanProperty('canDelete', data, 'CanDelete');
  }
}
