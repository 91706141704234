<div *ngIf="!!from && !!to; else missingConfigTemplate" class="eidos-daterange-filter-input-wrapper"
  [ngStyle]="{'flex-direction': layoutDirection === 'horizontal' ? 'row' : 'column'}">
  <dx-date-box [ngClass]="cssClass" [id]="cssId" [placeholder]="from.placeholder ?? ''"
    [(value)]="internalValue" [disabledDates]="from.disabledDates ?? []" width="100%" [(max)]="internalOtherValue " [min]="min ?? 0"
    [type]="from.dateValueType" [pickerType]="from.pickerType" [displayFormat]="from.displayFormat" [disabled]="disabled" [showClearButton]="showClearButton"
    (valueChange)="onRangeValuesChanged(value, otherValue)" (onFocusIn)="onFocusIn"
    (onFocusOut)="onFocusOut">
    <dx-validator [validationGroup]="validationGroupName">
      <dxi-validation-rule *ngIf="required"
        type="required" [message]="requiredMessage ?? 'Field is required'">
      </dxi-validation-rule>
    </dx-validator>
  </dx-date-box>
  <dx-date-box [ngClass]="cssClass" [id]="cssId" [placeholder]="to.placeholder ?? ''"
    [(value)]="internalOtherValue" [disabledDates]="to.disabledDates ?? []" width="100%" [(min)]="internalValue" [max]="max ?? 8640000000000000"
    [type]="to.dateValueType" [pickerType]="to.pickerType" [displayFormat]="to.displayFormat" [disabled]="disabled" [showClearButton]="showOtherClearButton != undefined ? showOtherClearButton : showClearButton"
    (valueChange)="onRangeValuesChanged(value, otherValue)" (onFocusIn)="onFocusIn"
    (onFocusOut)="onFocusOut">
    <dx-validator [validationGroup]="validationGroupName">
      <dxi-validation-rule *ngIf="required"
        type="required" [message]="requiredMessage ?? 'Field is required'">
      </dxi-validation-rule>
    </dx-validator>
  </dx-date-box>
</div>
<ng-template #missingConfigTemplate>Missing one of the date box configuration!</ng-template>