import { Component } from '@angular/core';
import { EidosEntityService } from '@app/core/services/eidos-entity.service';
import { EidosFilterFormComponent } from '../../eidos-filter-form/eidos-filter-form.component';

@Component({
  selector: 'eidos-search-sidebar-filters',
  templateUrl: './eidos-search-sidebar-filters.component.html',
  styleUrls: ['./eidos-search-sidebar-filters.component.scss']
})
export class EidosSearchSidebarFiltersComponent<S extends EidosEntityService> extends EidosFilterFormComponent<S> {

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
