import { Component, Input } from '@angular/core';
import { CoreFormatService } from '@app/core/services/core-format.service';
import { IEidosInputDateConfig } from '@common/models/eidos-inputs.models';
import { DateTime } from 'luxon';
import { EidosInputBaseComponent } from '../eidos-input-base.component';

@Component({
  selector: 'eidos-input-datetimerange',
  templateUrl: './eidos-input-datetimerange.component.html',
  styleUrls: ['./eidos-input-datetimerange.component.scss']
})
export class EidosInputDateTimeRangeComponent extends EidosInputBaseComponent<DateTime | undefined>  {
  /**
   * Internal value getter, return the value as DX expected object
   *
   * @type {(Date | string | number)}
   * @memberof EidosInputDateTimeRangeComponent
   */
  public get internalValue(): any { 
    return this.value != undefined ? this.value.toJSDate() : this.value;
  }
  /**
   * Internal value setter, set the value as DX expected object
   *
   * @memberof EidosInputDateTimeRangeComponent
   */
  public set internalValue(val: Date | string | number) {
    if (!!val) { 
      if (typeof val === "string") {
        this.value = DateTime.fromISO(val)
      } else if (typeof val === "number") {
        this.value = DateTime.fromMillis(val);
      } else {
        this.value = DateTime.fromJSDate(val);
      }

    } else {
      this.value = undefined;
    }
  }
  /**
   * Internal otherValue getter, return the otherValue as DX expected object
   *
   * @type {(Date | string | number)}
   * @memberof EidosInputDateTimeRangeComponent
   */
  public get internalOtherValue(): any {
    return this.otherValue != undefined ? this.otherValue.toJSDate() : this.otherValue;
  }
  /**
   * Internal value setter, set the otherValue as DX expected object
   *
   * @memberof EidosInputDateTimeRangeComponent
   */
  public set internalOtherValue(val: Date | string | number  | null) {
    if (!!val) {
      if (typeof val === "string") {
        this.otherValue = DateTime.fromISO(val);
      } else if (typeof val === "number") {
        this.otherValue = DateTime.fromMillis(val);
      } else {
        this.otherValue = DateTime.fromJSDate(val);
      }
    } else {
      this.otherValue = undefined;
    }
  }

  /**
  * Min date allowed in the picker
  *
  * @type {Date}
  * @memberof EidosInputDateTimeComponent
  */
  @Input() public min?: Date = new Date(1900, 0, 1);
  /**
   * Max date allowed in the picker
   *
   * @type {Date}
   * @memberof EidosInputDateTimeComponent
   */
  @Input() public max?: Date = new Date(2999, 11, 31);
  /**
   * Flag to show clear button on second date picker
   *
   * @type {boolean}
   * @memberof EidosInputDateTimeRangeComponent
   */
  @Input() public showOtherClearButton?: boolean 
  /**
   * From date filter config
   *
   * @type {IEidosInputDateConfig}
   * @memberof EidosInputDateTimeRangeComponent
   */
  @Input() public from?: IEidosInputDateConfig = {
    type: 'date',
    displayFormat: this.coreFormatService.DateFmtWithMonthName(),
    pickerType: 'calendar',
    dateValueType: 'date'
  };
  /**
   * To date filter config
   *
   * @type {IEidosInputDateConfig}
   * @memberof EidosInputDateTimeRangeComponent
   */
  @Input() public to?: IEidosInputDateConfig = {
    type: 'date',
    displayFormat: this.coreFormatService.DateFmtWithMonthName(),
    pickerType: 'calendar',
    dateValueType: 'date'
  };
  /**
   * Layout direction of daterange inputs
   *
   * @type {('horizontal' | 'vertical')}
   * @memberof EidosInputDateTimeRangeComponent
   */
  @Input() public layoutDirection?: 'horizontal' | 'vertical' = 'horizontal';

  constructor(private coreFormatService: CoreFormatService) {
    super();
  }

  onRangeValuesChanged(value: DateTime | undefined, otherValue: DateTime | undefined) {
    !!this.from?.onValueChange && this.from.onValueChange(this, value);
    !!this.to?.onValueChange && this.to.onValueChange(this, otherValue);
    this.onSelfChangeValue({ value, otherValue });
  }
}
