<dx-toolbar class="px-3 eidos-topbar d-flex align-items-center" id="eidos-main-header">

  <dxi-item location="before">
    <eidos-icon
      *ngIf="!hideMyBizMenu"
      [iconConfiguration]="menuIcon"
      (click)="toggleEidosMenu()"
      [badge]="unreadNotifications"
      [tooltip]="menuIconTooltip"
      [clickable]="true"></eidos-icon>
    <!-- <eidos-icon class="eidos-company-logo" [iconConfiguration]="companyLogo"></eidos-icon> -->

    <span *ngIf="!hideCompanyName" class="eidos-company-text">{{user?.nome_azienda}}</span>
  </dxi-item>

  <dxi-item location="center" [ngStyle]="{'cursor': 'pointer'}">
    <eidos-icon
      id="mainLogo"
      [iconConfiguration]="logo"
      (click)="navigateToHome()"
      [tooltip]="'topbar.go_home'"
      [tooltipPosition]="logoIconTooltipPosition"></eidos-icon>
  </dxi-item>

  <dxi-item location="after" class="d-flex align-items-center">
    <eidos-user></eidos-user>
    <eidos-action-menu></eidos-action-menu>
  </dxi-item>
</dx-toolbar>
