import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { EidosMenu } from '@common/models/eidos-menu.model';
import { EidosSimpleDataResponse, MyBizApiResponse, MyBizLabels, MyBizVersionApiResponse, UploadFileOptions, UploadResponse } from '@common/models/mybiz.model';
import { EidosBaseApiService } from './eidos-base-api.service';
import { EidosSecurityService } from './eidos-security.service';
import { LoginResponse } from '@common/models/login-response.model';
import { EidosLanguage } from '@common/models/eidos-language.model';
import { EidosCompany } from '@common/models/eidos-company.model';
import { EidosAuthType, EidosLogSeverity, CoreRoute, SecurityCheckRequestType } from '@common/models/core-constant.model';
import { EidosLogService } from './eidos-log.service';
import { EidosObjectApiResponse, EidosObjectDataApiResponse } from '@common/models/eidos-object.model';
import { IEidosResetCredentialsRequest } from '@common/models/mybiz.interface';
import { CoreRegisterAccountConfig, CoreRegisterAccountParameters, CoreRegisterAccountResult } from '../models/core-register-account-config.model';
import { CoreFormatService } from './core-format.service';
import { CoreBaseApiCommandParams } from '../models/core-base-api.model';
import { EidosOrganization } from '../models/eidos-organization.model';;

@Injectable({
  providedIn: 'root'
})
export class EidosApiService extends EidosBaseApiService {


  /**
   * Current authentication type
   *
   * @private
   * @memberof EidosApiService
   */
  private authType = EidosAuthType.Form;
  /**
   * Windows auth API base url
   *
   * @private
   * @type {(string | undefined)}
   * @memberof EidosApiService
   */
  private windowsAuthUrl: string | undefined;
  /**
   * Microsoft auth API url
   *
   * @private
   * @type {(string | undefined)}
   * @memberof EidosApiService
   */
  private microsoftAuthUrl = '';

  /**
   * Google auth API url
   *
   * @private
   * @type {(string | undefined)}
   * @memberof EidosApiService
   */
  private googleAuthUrl = '';

  constructor(
    public coreFormatService: CoreFormatService,
    public configService: EidosConfigService,
    public eidosSecurityService: EidosSecurityService,
    public http: HttpClient,
    public eidosLogService: EidosLogService,
    public dialog: MatDialog
  ) {
    super(coreFormatService, configService, eidosLogService, dialog, eidosSecurityService, http);

    this.configService.currentConfig.subscribe(config => {
      this.baseUrl = config.baseUrl;
      this.urlAPI = config.urlAPI;
      this.windowsAuthUrl = config.windowsAuth?.authorizationUrl;
      this.microsoftAuthUrl = config.microsoftAuth?.authorizationUrl ?? '';
      this.googleAuthUrl = config.googleAuth?.authorizationUrl ?? '';
    });

    this.eidosSecurityService.checkRequest.subscribe(
      check => {
        switch (check) {
          case SecurityCheckRequestType.User:
            this.getUserInfo().subscribe(res => this.eidosLogService.logDebug(EidosLogSeverity.Log, res));
            break;
          case SecurityCheckRequestType.Authentication:
            this.amIAuthenticated().subscribe(res => this.eidosLogService.logDebug(EidosLogSeverity.Log, "Authentication check required. Result:" + res));
            break;
        }
      });
  }

  /**
    * Call APIs to log a client side error
   *
   * @template T
   * @param {T} error
   * @return {*}
   * @memberof EidosApiService
   */
  public logClientSideError<T extends Error>(error: T) {

    const other: any = Object.assign({}, error);

    if (!!other.name) {
      delete other.name;
    }

    if (!!other.message) {
      delete other.message;
    }

    if (!!other.stack) {
      delete other.stack;
    }

    const commandParams = new CoreBaseApiCommandParams({
      type: 'POST',
      url: 'general/log',
      data: {
        message: error.message,
        stack: error.stack ?? error.message,
        username: this.eidosSecurityService.currentLoggedUser.getValue().username,
        info: JSON.stringify(other),
        path: ""
      }
    });

    return this.sendCommand(commandParams)
      .pipe(
        map<HttpResponse<any>, boolean>(
          response => {
            return !!response;
          }),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }

  /**
    * Call APIs to log into Eidos
    *
    * @param {EidosAuthType} authType Authentication type
    * @param {string} [username]
    * @param {string} [password]
    * @return {*}  {Observable<LoginResponse>}
    * @memberof EidosApiService
    */
  public login(authType: EidosAuthType, username?: string, password?: string, querystring?: string): Observable<LoginResponse | MyBizApiResponse> {

    this.authType = authType;

    let commandParams = new CoreBaseApiCommandParams({
      type: 'POST',
      url: 'account/login'
    });

    switch (this.authType) {
      case EidosAuthType.Form:

        commandParams.data = { username: username, password: password };

        return this.sendCommand(commandParams)
          .pipe(
            map<HttpResponse<any>, MyBizApiResponse>(
              response => {
                return new MyBizApiResponse(response);
              }),
            map<MyBizApiResponse, LoginResponse>(
              response => {
                let loginResponse = new LoginResponse(response);
                if (loginResponse.isOk()) {
                  loginResponse.landingPage = this.config?.landingPage ?? CoreRoute.Home;
                  this.eidosSecurityService.setToken(response.data);
                }
                return loginResponse;
              }),
            switchMap(response => {
              if (response.isOk()) return this.getUserInfo();
              return of(response);
            }),
            catchError(
              (
                error: HttpErrorResponse) => {
                return this.handleGenericError(error);
              }
            )
          );
      case EidosAuthType.Windows:

        commandParams.data = { username: null, password: null };
        commandParams.customBaseUrl = this.windowsAuthUrl ?? '';
        commandParams.withCredentials = true;

        return this.sendCommand(commandParams)
          .pipe(
            map<HttpResponse<any>, MyBizApiResponse>(
              response => {
                return new MyBizApiResponse(response);
              }),
            map<MyBizApiResponse, LoginResponse>(
              response => {
                let loginResponse = new LoginResponse(response);
                if (loginResponse.isOk()) {
                  loginResponse.landingPage = this.config?.landingPage ?? CoreRoute.Home;
                  this.eidosSecurityService.setToken(response.data);
                }
                return loginResponse;
              }),
            switchMap(response => {
              if (response.isOk()) return this.getUserInfo();
              return of(response);
            }),
            catchError(
              (
                error: HttpErrorResponse) => {
                return this.handleGenericError(error);
              }
            )
          );
      case EidosAuthType.Microsoft:
        return this.loginOauth('microsoftid', this.microsoftAuthUrl, querystring);
      case EidosAuthType.Google:
        return this.loginOauth('google', this.googleAuthUrl, querystring);
    }
  }

  /**
    * Call APIs to log into Eidos by OAuth
    *
    * @param {string} [provider] provider
    * @return {*}  {Observable<LoginResponse>}
    * @memberof EidosApiService
    */
  loginOauth(provider: string, url: string, querystring?:string ): Observable<any> {
    const oauthUrl = (url === '' ? 'general/oauth/' : url) + provider + querystring
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url:  oauthUrl})).pipe(
      map<any, MyBizApiResponse>(
        response => {
          return new MyBizApiResponse(response);
        }),
      catchError(
        (
          error: HttpErrorResponse) => {
          return this.handleGenericError(error);
        }
      )
    );
  }
  /**
   * Call APIs to retrieve info of current logged user
   *
   * @return {*}
   * @memberof EidosApiService
   */
  public getUserInfo() {

    let commandParams = new CoreBaseApiCommandParams({
      type: 'GET',
      url: 'account/getuserinfo'
    });

    switch (this.authType) {
      case EidosAuthType.Form:
        return this.sendCommand(commandParams)
          .pipe(
            map<any, MyBizApiResponse>(
              response => {
                return new MyBizApiResponse(response);
              }),
            map<MyBizApiResponse, LoginResponse>(
              response => {
                let loginResponse = new LoginResponse(response);

                if (loginResponse.isOk()) {
                  loginResponse.landingPage = this.config?.landingPage ?? CoreRoute.Home;
                  this.eidosSecurityService.setRawUser(response.data);
                }
                return loginResponse;
              }),
            catchError(
              (
                error: HttpErrorResponse) => {
                return this.handleGenericError(error);
              }
            )
          );
      case EidosAuthType.Windows:

        commandParams.customBaseUrl = this.windowsAuthUrl ?? '';
        commandParams.withCredentials = true;

        return this.sendCommand(commandParams)
          .pipe(
            map<any, MyBizApiResponse>(
              response => {
                return new MyBizApiResponse(response);
              }),
            map<MyBizApiResponse, LoginResponse>(
              response => {
                let loginResponse = new LoginResponse(response);

                if (loginResponse.isOk()) {
                  loginResponse.landingPage = this.config?.landingPage ?? CoreRoute.Home;
                  this.eidosSecurityService.setRawUser(response.data);
                }
                return loginResponse;
              }),
            catchError(
              (
                error: HttpErrorResponse) => {
                return this.handleGenericError(error);
              }
            )
          );
      default:
        return throwError(() => "Auth not implemented");
    }
  }

  /**
   * Call APIs to check for user autentication
   *
   * @return {*}
   * @memberof EidosApiService
   */
  public amIAuthenticated(): Observable<boolean> {

    let commandParams = new CoreBaseApiCommandParams({
      type: 'GET',
      url: 'account/amiauthenticated'
    });

    switch (this.authType) {
      case EidosAuthType.Windows:

        commandParams.customBaseUrl = this.windowsAuthUrl ?? '';
        commandParams.withCredentials = true;

        return this.sendCommand(commandParams)
          .pipe(
            map<any, MyBizApiResponse>(response => new MyBizApiResponse(response)),
            map<MyBizApiResponse, boolean>(
              response => {
                if (response.data.authenticated !== false && typeof (response.data.authenticated) !== "undefined") {
                  this.eidosSecurityService.authenticated.next(true);
                  return true;
                } else {
                  this.eidosSecurityService.clearAuthentication();
                  return false;
                }
              }),
            catchError((error: HttpErrorResponse) => this.handleGenericError(error))
          );
      default:
        return this.sendCommand(commandParams)
          .pipe(
            map<any, MyBizApiResponse>(response => new MyBizApiResponse(response)),
            map<MyBizApiResponse, boolean>(
              response => {
                if (response.data.authenticated !== false && typeof (response.data.authenticated) !== "undefined") {
                  this.eidosSecurityService.authenticated.next(true);
                  return true;
                } else {
                  this.eidosSecurityService.clearAuthentication();
                  return false;
                }
              }),
            catchError((error: HttpErrorResponse) => this.handleGenericError(error))
          );
    }
  }

  /**
   * Call APIs to log out from Eidos
   *
   * @return {*}
   * @memberof EidosApiService
   */
  public logout() {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/logout' }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(
          response => {
            return new MyBizApiResponse(response);
          }),
        map<MyBizApiResponse, LoginResponse>(
          response => {
            return new LoginResponse(response);
          }),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }

  mbIsValid(val: any): boolean {
    return !(typeof val === "undefined" || val === null);
  }

  /**
   * API call to retrieve a generic object
   *
   * @param {number} objectId
   * @return {*}  {Observable<EidosObjectApiResponse>}
   * @memberof EidosApiService
   */
  getObject(objectId: number): Observable<EidosObjectApiResponse> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'eobject/get/' + objectId }))
      .pipe(
        map<MyBizApiResponse, EidosObjectApiResponse>(
          response => new EidosObjectApiResponse(response)),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }
  /**
   * API call to retrieve MyBiz version
   *
   * @return {*}  {Observable<EidosMyBizVersionApiResponse>}
   * @memberof EidosApiService
   */
  getMyBizVersion(): Observable<MyBizVersionApiResponse> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'eobject/version' }))
      .pipe(
        map<MyBizApiResponse, MyBizVersionApiResponse>(
          response => new MyBizVersionApiResponse(response)),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }
  /**
   * API call to retrieve data of a generic MyBiz object
   *
   * @param {number} objectId
   * @param {*} [dataRequest]
   * @return {*}  {Observable<EidosObjectDataApiResponse>}
   * @memberof EidosApiService
   */
  getDataObject(objectId: number, dataRequest?: any): Observable<EidosObjectDataApiResponse> {
    let dataPayload = { obejctId: objectId };
    const payload = dataRequest ? Object.assign(dataPayload, dataRequest) : dataPayload;
    return this.sendCommand(new CoreBaseApiCommandParams({
      type: 'POST',
      url: 'eobject/getdata/' + objectId,
      data: payload
    }))
      .pipe(
        map<any, MyBizApiResponse>(
          response => new MyBizApiResponse(response)
        ),
        map<MyBizApiResponse, EidosObjectDataApiResponse>(
          response => new EidosObjectDataApiResponse(response)),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }

  getRegisterAccountConfig(): Observable<CoreRegisterAccountConfig> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'general/register/account/config' }))
      .pipe(
        map<any, CoreRegisterAccountConfig>(
          response => new CoreRegisterAccountConfig(response)
        ),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }
  submitRegisterAccount(params: CoreRegisterAccountParameters): Observable<CoreRegisterAccountResult> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'POST', url: 'general/register/account/save', data: params }))
      .pipe(
        map<any, CoreRegisterAccountResult>(
          () => new CoreRegisterAccountResult()
        ),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }
  /**
   * Get MyBiz icon url for img src
   *
   * @param {string} imageCod
   * @return {*}  {Observable<string>}
   * @memberof EidosApiService
   */
  getMyBizImageUrl(imageCod: string): Observable<string> {
    return of(`${this.urlAPI}/object/immagine/${imageCod}`);
  }

  getMyBizImage(imageCod: string): Observable<string> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'object/immagine/' + imageCod }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(
          response => {
            return new MyBizApiResponse(response);
          }),
        map<MyBizApiResponse, any>(
          response => {
            return response.data;
          }),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }

  loadMenu(): Observable<EidosMenu> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'menu/get' }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        map<MyBizApiResponse, EidosMenu>(response => { return new EidosMenu(response.data); }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }

  getMyBizLabels(): Observable<MyBizLabels> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'general/fe-labels' }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        map<MyBizApiResponse, MyBizLabels>(response => { return new MyBizLabels(response); }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<MyBizApiResponse> {
    return this.sendCommand(new CoreBaseApiCommandParams({
      type: 'POST',
      url: 'account/changepassword',
      data: { old_password: oldPassword, new_password: newPassword }
    }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }

  /**
   * Requests a user credentials reset
   *
   * @param {string} username
   * @return {*}  {Observable<MyBizApiResponse>}
   * @memberof EidosApiService
   */
  resetCredentials(payload: IEidosResetCredentialsRequest): Observable<MyBizApiResponse> {
    payload.resetUrl = this.baseUrl + CoreRoute.ResetCredentials + "/";
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'POST', url: 'account/pwd/reset', data: payload }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }

  /**
   * Confirms a user credentials reset
   *
   * @param {string} resetId
   * @return {*}  {Observable<MyBizApiResponse>}
   * @memberof EidosApiService
   */
  confirmResetCredentials(resetId: string): Observable<MyBizApiResponse> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/pwd/reset/' + resetId }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }

  impersonate(user: string): Observable<MyBizApiResponse> {

    const command = new CoreBaseApiCommandParams({
      type: 'GET', url: 'account/impersonate', data: {
        targetusername: user
      }
    });

    return this.sendCommand(command)
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => new MyBizApiResponse(response)),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }
  endImpersonate(): Observable<MyBizApiResponse> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/endimpersonate' }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        catchError(
          (error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }

  getCompanies(): Observable<Array<EidosCompany>> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/aziende' }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        map<MyBizApiResponse, Array<EidosCompany>>(response => {
          let companies = Array<EidosCompany>();
          response.data.forEach((item: any) => companies.push(new EidosCompany(item)));
          return companies;
        }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }
  setCurrentCompany(company: string): Observable<MyBizApiResponse> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/aziende/' + company }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }

  getOrganizations(): Observable<Array<EidosOrganization>> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/organizations' }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        map<MyBizApiResponse, Array<EidosOrganization>>(response => {
          let organizations = Array<EidosOrganization>();
          response.data.forEach((item: any) => organizations.push(new EidosOrganization(item)));
          return organizations;
        }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error)),
        catchError((_error: HttpErrorResponse) => []), //ignore errors
      );
  }
  setCurrentOrganization(organization?: string): Observable<MyBizApiResponse> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/organization/' + (organization ?? 'empty') }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }
  getLanguages(): Observable<Array<EidosLanguage>> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/lingue' }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        map<MyBizApiResponse, Array<EidosLanguage>>(response => {
          let languages = Array<EidosLanguage>();
          response.data.forEach((item: any) => languages.push(new EidosLanguage(item)));
          return languages;
        }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }

  setCurrentLanguage(codLanguage: string): Observable<MyBizApiResponse> {
    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'GET', url: 'account/lingue/' + codLanguage }))
      .pipe(
        map<HttpResponse<any>, MyBizApiResponse>(response => { return new MyBizApiResponse(response); }),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }
  /**
   * Calls API to exec a SP and returns data as JSON
   * Requires MyBiz authentication
   *
   * @template T
   * @param {string} JSONName
   * @param {*} [parameters]
   * @param {string} [db]
   * @param {string} [schema]
   * @param {string} [sp]
   * @return {Observable<any[]>}
   * @memberof EidosApiService
   */
  public getJSON<T extends { HasError: boolean }>(JSONName: string, parameters?: any, db?: string, schema?: string, sp?: string): Observable<any[]> {
    if (!parameters) {
      parameters = {} as any;
    }
    if (db) {
      parameters.db = db;
    }
    if (schema) {
      parameters.schema = schema;
    }
    if (sp) {
      parameters.sp = sp;
    }

    return this.sendCommand(new CoreBaseApiCommandParams({ type: 'POST', url: 'json/get/' + JSONName, data: parameters }))
      .pipe(
        map<T, any>((response: T) => !response.HasError && (JSONName in response) ? (<any>response)[JSONName] : []),
        catchError((error: HttpErrorResponse) => this.handleGenericError(error))
      );
  }


  /**
    * Call APIs to uploaf file
    *
    * @param {Formdata} data
    * @param {object} options
    * @return {*}  {Observable<LoginResponse>}
    * @memberof EidosApiService
    */
  public uploadFiles(files: Array<File>, options?: UploadFileOptions): Observable<UploadResponse> {

    const formData = new FormData();
    Object.entries(Object.assign({ overwrite: true, versionSuffix: 'ver.#', versionMinDigit: 2 }, options))
      .forEach(
        ([key, value]) => {
          formData.append(key, value);
        });

    files.forEach(file => formData.append("fileData", file));

    return this.sendFileRequest({ type: "POST", url: "io/file/upload", data: formData, reportProgress: true })
      .pipe(
        map<any, MyBizApiResponse>(response => new MyBizApiResponse(response)),
        map<MyBizApiResponse, UploadResponse>(
          response => new UploadResponse(response)),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }
  getSimpleEidosData<T extends EidosSimpleDataResponse>(objectKey: string, parameters: { [Key: string]: any }, withConfigurationData = false): Observable<T> {

    const commandParams = new CoreBaseApiCommandParams({
      type: 'POST',
      url: `io/object/${withConfigurationData ? 'config' : 'read'}`,
      data: {
        ObjectKey: objectKey,
        Type: "Simple",
        Parameters: parameters
      }
    });

    return this.sendCommand(commandParams)
      .pipe(
        map<any, EidosSimpleDataResponse>(response => new EidosSimpleDataResponse(response)),
        map<EidosSimpleDataResponse, T>(response => response as T),
        catchError(
          (
            error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }
}
