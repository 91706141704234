import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { IEidosIcon } from '@common/config/environment.interface';
import { EidosIconType } from '@common/models/core-constant.model';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosDebugDialogComponent } from '@common/components/eidos-dialogs/eidos-debug-dialog/eidos-debug-dialog.component';
import { IEidosDashboardEmptyCell } from '@common/models/eidos-dashboard.interface';

import { EidosObjectBaseComponent } from '@eidos/components/objects/eidos-object-base/eidos-object-base.component';


@Component({
  selector: 'eidos-object-empty',
  templateUrl: './eidos-object-empty.component.html',
  styleUrls: ['./eidos-object-empty.component.scss']
})
export class EidosObjectEmptyComponent extends EidosObjectBaseComponent implements OnInit {
  /**
   * Eidos debug mode flag
   *
   * @type {boolean}
   * @memberof EidosObjectEmptyComponent
   */
  public inDebugMode: boolean = false;
  /**
   * Eidos empty cell to display debug info
   *
   * @type {(IEidosDashboardEmptyCell | undefined)}
   * @memberof EidosObjectEmptyComponent
   */
  @Input()
  public emptyCell: IEidosDashboardEmptyCell | undefined;

  /**
   * Empty icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosObjectEmptyComponent
   */
  public emptyIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "code_off",
    iconSize: 1,
  };

  constructor(
    private dialog: MatDialog
    , private utilityService: EidosUtilityService
  ) {
    super();
  }

  ngOnInit(): void {
    this.utilityService.inDebugMode
      .pipe(takeUntil(this.subscription))
      .subscribe(debug => this.inDebugMode = debug);
  }
  /**
   * Open the modal to display debug info
   *
   * @memberof EidosObjectEmptyComponent
   */
  public openDebugObjInfo(): void {
    this.dialog.open(EidosDebugDialogComponent, {
      width: '320px',
      data: {
        data: this.eidosObject ?? this.emptyCell
      }
    });
  }
}
