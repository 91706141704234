<content *ngIf="visible">
  <button class="eidos-button" [ngClass]="cssClasses" (click)="!isDisabled && btnClick.emit($event)" [disabled]="isDisabled ? 'disabled' : ''">
    <div style="display: flex; flex-direction: row; align-items: center;">
      <eidos-icon *ngIf="iconConfiguration && iconPosition === 'left'" [iconConfiguration]="iconConfiguration" class="eidos-button-icon-left"></eidos-icon>
      <div>{{ text }}</div>
      <eidos-icon *ngIf="iconConfiguration && iconPosition === 'right'" [iconConfiguration]="iconConfiguration" class="eidos-button-icon-right"></eidos-icon>
    </div>
    <div class="eidos-button-badge" *ngIf="badgeConfiguration">
      <ng-container *ngTemplateOutlet="badgeConfiguration['text'] ? text : icon">
      </ng-container>
      <ng-template #text>
        {{ badgeConfiguration.text }}
      </ng-template>

      <ng-template #icon>
        <eidos-icon *ngIf="badgeConfiguration?.icon" [iconConfiguration]="badgeConfiguration.icon" (mouseenter)="onMouseEnter($event)"
        (mouseleave)="onMouseLeave()"></eidos-icon>
        <dx-tooltip *ngIf="badgeConfiguration?.tooltipText" [target]="tooltipTarget" [(visible)]="tooltipDefaultVisible"
          [position]="badgeConfiguration.tooltipPosition ?? 'top'" contentTemplate="content">
          <div *dxTemplate="let data = data of 'content'">{{ badgeConfiguration.tooltipText }}</div>
        </dx-tooltip>
      </ng-template>
    </div>
  </button>
</content>
