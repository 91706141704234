<content *ngIf="userAvatar" class="d-inline-flex">
  <span
    *ngIf="userAvatar.profilePicture"
    class="eidos-user-avatar eidos-profile-picture"
    [ngStyle]="{ 'background-image': getProfileImage()}">
  </span>

  <span
    *ngIf="!userAvatar.profilePicture"
    class="eidos-user-avatar eidos-profile-badge"
    [ngStyle]="{'background-color': getBadgePrimaryColor(), 'color': getBadgeSecondaryColor() }">{{ userAvatar.badgeSign }}
  </span>

  <eidos-icon
    *ngIf="userAvatar.isImpersonated"
    class="eidos-profile-end-impersonation"
    [iconConfiguration]="userImpersonationIcon"
    (click)="endImpersonation()"
    [tooltip]="'topbar.end_impersonation'"
    [clickable]="true">
  </eidos-icon>
  <eidos-icon
    *ngIf="inDebugMode"
    class="eidos-debug-icon"
    [iconConfiguration]="debugIcon"
    (click)="endDebugMode()"
    [tooltip]="'topbar.debug_active'"
    [clickable]="true">
  </eidos-icon>
</content>
