<div class="eidos-upload-file">
  <div>
    <input type="file"
      class="file-input"
      [accept]="requiredFileType"
      [multiple]="multiple"
      (change)="onFileSelected($event)" #fileUpload>

    <div class="file-upload">

      {{fileName || caption}}

      <button *ngIf="!uploadProgress"
        class="upload-btn"
        (click)="fileUpload.click()">
          <i class="fas fa-upload"></i>
      </button>
      <button *ngIf="uploadProgress"
        class="upload-btn"
        (click)="cancelUpload()">
        <i class="fas fa-trash"></i>
      </button>

    </div>
  </div>
  <div class="progress">
    <mat-progress-bar *ngIf="uploadProgress"
      class="progress-bar"
      mode="determinate"
      [value]="uploadProgress">
    </mat-progress-bar>
  </div>

</div>

