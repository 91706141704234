<content class="eidos-sidebar-filter-form-wrapper"
  *ngIf="filtersConfiguration && !!filters; else missingConfigTemplate">
  <div class="row eidos-filter-form-wrapper mt-3" *ngFor="let filterConfiguration of moreFilters"
    [hidden]="!!filterConfiguration.hidden && filterConfiguration.hidden()" [ngClass]="horizontalAlignCSS"
    [ngClass]="cssClass" [id]="cssId">
    <div class="eidos-filter-wrapper col-12">
      <ng-container [ngTemplateOutlet]="filterTemplate ?? eidosFilterTemplate" [ngTemplateOutletContext]="{
            filters: filters,
            filterConfiguration: filterConfiguration, 
            keydown: keyDown,
            scope: this,
            searchService: searchService
          }">
      </ng-container>
    </div>
  </div>
  <ng-template #propertyMissingTemplate>The filter property is missing in the filter object</ng-template>
</content>
<ng-template #missingConfigTemplate>Missing filter form or object configuration!</ng-template>

<ng-template #eidosFilterTemplate let-filterConfiguration="filterConfiguration" let-filters="filters"
  let-searchService="searchService" let-keydown="keydown" let-scope="scope">  
  <eidos-filter [(value)]="filters[filterConfiguration.property]"
    [(otherValue)]="filters[filterConfiguration.otherProperty]" (onKeyDown)="keydown($event, scope)"
    [searchService]="searchService" [(otherValue)]="filters[filterConfiguration.otherProperty ?? '']"
    [config]="filterConfiguration">
  </eidos-filter>
</ng-template>