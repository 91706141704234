<div class="row" [hidden]="loading"
  [ngClass]="!!isSidebarSearch ? 'eidos-search-paginator-sidebar-wrapper' : 'eidos-search-paginator-standard-wrapper'">
  <ng-container
    [ngTemplateOutlet]="!!isSidebarSearch ? sidebarSearchPaginatorTemplate : standardSearchPaginatorTemplate">
  </ng-container>
</div>

<ng-template #sidebarSearchPaginatorTemplate>
  <div class="eidos-search-results-viewtabs row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <ng-container [ngTemplateOutlet]="eidosSearchResultsViewtabs"></ng-container>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="resultsPageIndex > 0 && pageTotal > 0">
      <span>Records: <b>{{recordsCount}}</b></span>
    </div>
  </div>
  <div class="eidos-search-results-paginator-wrapper col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <ng-container [ngTemplateOutlet]="eidosSearchResultsPaginator"></ng-container>
  </div>
</ng-template>

<ng-template #standardSearchPaginatorTemplate>
  <div class="eidos-search-results-paginator-wrapper col-12">
    <ng-container [ngTemplateOutlet]="eidosSearchResultsViewtabs"></ng-container>
    <div class="d-flex flex-row align-items-center ml-3" style="margin-left: 30px;">
      <span *ngIf="resultsPageIndex > 0 && pageTotal > 0">Records: <b>{{recordsCount}}</b></span>
      <ng-container [ngTemplateOutlet]="eidosSearchResultsPaginator"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #eidosSearchResultsViewtabs>
  <dx-tabs *ngIf="viewTabs.length > 1" width="auto" [dataSource]="viewTabs" [selectedIndex]="0"
    (onItemClick)="selectView($event)">
  </dx-tabs>
</ng-template>

<ng-template #eidosSearchResultsPaginator>
  <div class="eidos-search-results-paginator" *ngIf="resultsPageIndex > 0 && pageTotal > 0">
    <span style="margin-right: 5px">Page <b>{{resultsPageIndex}}</b>/<b>{{pageTotal}}</b></span>
    <i class="eidos-search-command-page dx-icon-chevrondoubleleft" *ngIf="resultsPageIndex > 1"
      (click)="resultsPageIndex = 1">
    </i>
    <i class="eidos-search-command-page dx-icon-chevronleft" *ngIf="resultsPageIndex > 1"
      (click)="resultsPageIndex = resultsPageIndex -1">
    </i>
    <span *ngIf="(resultsPageIndex-1) > 1">...</span>
    <span (click)="resultsPageIndex = resultsPageIndex-1;" *ngIf="resultsPageIndex > 1"
      class="eidos-search-command-page-number">{{resultsPageIndex-1}}</span>
    <span class="eidos-search-command-page-number eidos-search-command-current-page-number">{{resultsPageIndex}}</span>
    <span (click)="resultsPageIndex = i;" class="eidos-search-command-page-number"
      *ngFor="let i of nextPagesIndexes">{{i}}</span>
    <span *ngIf="pageTotal > (resultsPageIndex + 3)">...</span>
    <i class="eidos-search-command-page dx-icon-chevronright" *ngIf="resultsPageIndex < pageTotal"
      (click)="resultsPageIndex = resultsPageIndex + 1">
    </i>
    <i class="eidos-search-command-page dx-icon-chevrondoubleright" *ngIf="resultsPageIndex < pageTotal"
      (click)="resultsPageIndex = pageTotal">
    </i>
  </div>
</ng-template>