<content *ngIf="maxItems === 1; else multiItemInput">
  <dx-select-box [dataSource]="items" [(value)]="value" [ngClass]="cssClass" [id]="cssId"
    placeholder="{{ placeholder ?? '' | transloco }}"
    [searchEnabled]="searchEnabled" [showClearButton]="showClearButton" [disabled]="disabled"
    [minSearchLength]="minSearchLength" [searchExpr]="searchFields" [displayExpr]="displayField" [valueExpr]="valueField ?? 'this'"
    (onValueChanged)="onSelfChangeValue($event)"
    [noDataText]="noDataText" width="100%" [itemTemplate]="'item'"
    (onFocusIn)="(!!onFocusIn && onFocusIn())" (onFocusOut)="(!!onFocusOut && onFocusOut())"
    [acceptCustomValue]="acceptCustomValue" (onCustomItemCreating)="customItemCreate($event)">
      <dx-validator [validationGroup]="validationGroupName">
        <dxi-validation-rule *ngIf="required"
          type="required" [message]="requiredMessage ?? 'Field is required'">
        </dxi-validation-rule>
      </dx-validator>

      <div *dxTemplate="let data of 'item'">
        <div *ngIf="!isItemObject(data)" title="{{data}}">{{data}}</div>
        <div *ngIf="isItemObject(data)" title="{{ isDisplayFieldComputed ? $any(displayField)(data) : data[$any(displayField)] }}">
          {{ isDisplayFieldComputed ? $any(displayField)(data) : data[$any(displayField)] }}
        </div>
      </div>
  </dx-select-box>
</content>
<ng-template #multiItemInput>
  <dx-tag-box  [dataSource]="items" [(value)]="value" [ngClass]="cssClass" [id]="cssId"
    placeholder="{{ placeholder ?? '' | transloco }}" height="auto" width="100%"
    [searchEnabled]="searchEnabled" [showClearButton]="showClearButton" [disabled]="disabled"
    [minSearchLength]="minSearchLength" [searchExpr]="searchFields" [displayExpr]="isDisplayFieldTagDefined ? displayFieldTag! : displayField" [valueExpr]="valueField ?? 'this'"
    (valueChange)="checkMaxTagsReached($event); onSelfChangeValue({ value: value });"
    [noDataText]="noDataText"
    tagTemplate="customTag"
    itemTemplate="customItem"
    groupTemplate="customGroup"
    [acceptCustomValue]="acceptCustomValue" (onCustomItemCreating)="customItemCreate($event)" (onFocusIn)="(!!onFocusIn && onFocusIn())" (onFocusOut)="(!!onFocusOut && onFocusOut())">
    <div *dxTemplate="let item of 'customTag'" class="dx-tag-content">
      <span [matTooltip]="getDisplayFieldTagTooltip(item)" [innerText]="displayTag(item)">
      </span>
      <div class="dx-tag-remove-button"></div>
    </div>
    <div *dxTemplate="let data of 'customGroup'">
      <ng-container [ngTemplateOutlet]="groupTemplate ?? defaultItemTemplateTag" [ngTemplateOutletContext]="{data: data, prop: isDisplayFieldTagDefined ? displayFieldTag! : displayField}"></ng-container>
    </div>
    <div *dxTemplate="let data of 'customItem'">
      <ng-container [ngTemplateOutlet]="itemTemplate ?? defaultItemTemplate" [ngTemplateOutletContext]="{data: data, prop: displayField}"></ng-container>
    </div>

    <dx-validator [validationGroup]="validationGroupName">
      <dxi-validation-rule *ngIf="required" type="required" [message]="requiredMessage ?? 'Field is required'">
      </dxi-validation-rule>
    </dx-validator>

  </dx-tag-box>

  <ng-template #defaultItemTemplateTag let-data="data" let-prop="prop">
    <content *ngIf="isDisplayFieldTagComputed else indexedDefaultItemTemplate">
      <p>{{prop(data)}}</p>
    </content>
    <ng-template #indexedDefaultItemTemplate>
      <p>{{data[prop]}}</p>
    </ng-template>
  </ng-template>

  <ng-template #defaultItemTemplate let-data="data" let-prop="prop">
    <content *ngIf="isDisplayFieldComputed else indexedDefaultItemTemplate">
      <p>{{prop(data)}}</p>
    </content>
    <ng-template #indexedDefaultItemTemplate>
      <p>{{data[prop]}}</p>
    </ng-template>
  </ng-template>

</ng-template>
