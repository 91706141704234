<form [formGroup]="form">
  <h1 mat-dialog-title fxLayoutAlign="center">{{ 'settings.title' | transloco }}</h1>

  <div mat-dialog-content>
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{ 'settings.language' | transloco }}</mat-label>
      <mat-select formControlName="language" name="language">
        <mat-option *ngFor="let language of availableLanguages$|async" [value]="language.code">
          {{language.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{ 'settings.company' | transloco }}</mat-label>
      <mat-select formControlName="company" name="company">
        <mat-option *ngFor="let company of availableCompanies$|async" [value]="company.code">
          {{company.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="enableOrganization" appearance="outline" style="width: 100%">
      <mat-label>{{ 'settings.organization' | transloco }}</mat-label>
      <mat-select formControlName="organization" name="organization">
        <mat-option *ngFor="let organization of availableOrganizations$|async" [value]="organization.code">
          {{organization.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div mat-dialog-actions fxLayoutAlign="end">
    <button mat-button type="button" (click)="onCancelClick()">{{ 'cancel' | transloco }}</button>
    <button mat-flat-button type="submit" color="primary" (click)="onSaveClick()"
      [disabled]="form.invalid">{{ 'submit' | transloco }}</button>
  </div>
</form>
