import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EidosBaseComponent } from '@common/components/eidos-base.component';

@Component({
  selector: 'eidos-filter-form-show-more',
  templateUrl: './eidos-filter-form-show-more.component.html',
  styleUrls: ['./eidos-filter-form-show-more.component.scss']
})
export class EidosFilterFormShowMoreComponent extends EidosBaseComponent {
  /**
   * More filters flag (2-way binding)
   *
   * @type {boolean}
   * @memberof EidosFilterFormShowMoreComponent
   */
  protected _moreFilters: boolean = false;
  @Input() public get moreFilters(): boolean {
    return this._moreFilters;
  }
  public set moreFilters(val: boolean) {
    this._moreFilters = val;
    this.moreFiltersChange.emit(this._moreFilters);
  }
  @Output() public moreFiltersChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * More filters label
   *
   * @type {string}
   * @memberof EidosFilterFormShowMoreComponent
   */
  public moreFiltersLabel: string = "filter_form.show_more_filters";
  /**
   * Less filters label
   *
   * @type {string}
   * @memberof EidosFilterFormShowMoreComponent
   */
  public lessFiltersLabel: string = "filter_form.show_less_filters";

  constructor() {
    super();
  }

  public toggle() {
    this.moreFilters ? this.moreFilters = false : this.moreFilters = true;
  }
}
