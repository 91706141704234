import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IUserPasswordRequirements } from '@common/models/user-info.interface';

@Component({
  selector: 'eidos-change-password-dialog',
  styleUrls: [
    'eidos-change-password-dialog.component.scss'
  ],
  templateUrl: 'eidos-change-password-dialog.component.html'
})
export class EidosChangePasswordDialogComponent implements OnInit, OnDestroy {
  /**
   * User's password requirements
   * 
   * @type {IUserPasswordRequirements}
   * @memberof EidosChangePasswordDialogComponent
   */
  public requirements: IUserPasswordRequirements = {
    minPasswordLength: 0,
    minPasswordLowerCaseChars: 0,
    minPasswordUpperCaseChars: 0,
    minPasswordSpecialChars: 0,
    minPasswordNumericalChars: 0
  };
  /**
   * API call error
   *
   * @type {string}
   * @memberof EidosChangePasswordDialogComponent
   */
  public responseError: string = "";
  /**
   * Loading request
   *
   * @type {boolean}
   * @memberof EidosChangePasswordDialogComponent
   */
  public loading: boolean = false;

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('newPassword')!.value;
    let confirmPass = group.get('retypedNewPassword')!.value;
    return pass === confirmPass ? null : { notSame: true }
  }

  public form = new UntypedFormGroup({
    oldPassword: new UntypedFormControl('', Validators.required),
    newPassword: new UntypedFormControl('', Validators.required),
    retypedNewPassword: new UntypedFormControl('', Validators.required)
  }, { validators: this.checkPasswords });

  constructor(
    private dialogRef: MatDialogRef<EidosChangePasswordDialogComponent>
    , private utilityService: EidosUtilityService
    , private eidosLogService: EidosLogService
    , private translocoService: TranslocoService
    , private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    const user = this.utilityService.getCurrentUser();
    if (user) {
      this.requirements = user?.passwordRequirements;
      if (user.mustChangePassword) {
        this.requirements.mustChangePassword = true;
      }

      // Min length validation
      if (this.requirements.minPasswordLength > 0) {
        this.form.controls['newPassword'].addValidators(
          Validators.minLength(this.requirements.minPasswordLength)
        );

        this.form.controls['retypedNewPassword'].addValidators(
          Validators.minLength(this.requirements.minPasswordLength)
        );
      }

      // Min numeric chars validation
      if (this.requirements.minPasswordNumericalChars > 0) {

        this.form.controls['newPassword'].addValidators(
          minPasswordChars(this.requirements.minPasswordNumericalChars, /(\d+)/, "minPasswordNumericalChars")
        );

        this.form.controls['retypedNewPassword'].addValidators(
          minPasswordChars(this.requirements.minPasswordNumericalChars, /(\d+)/, "minPasswordNumericalChars")
        );
      }

      // Min lower case chars validation
      if (this.requirements.minPasswordLowerCaseChars > 0) {

        this.form.controls['newPassword'].addValidators(
          minPasswordChars(this.requirements.minPasswordLowerCaseChars, /[a-z]/, "minPasswordLowerCaseChars")
        );

        this.form.controls['retypedNewPassword'].addValidators(
          minPasswordChars(this.requirements.minPasswordLowerCaseChars, /[a-z]/, "minPasswordLowerCaseChars")
        );
      }

      // Min upper case chars validation
      if (this.requirements.minPasswordUpperCaseChars > 0) {

        this.form.controls['newPassword'].addValidators(
          minPasswordChars(this.requirements.minPasswordUpperCaseChars, /[A-Z]/, "minPasswordUpperCaseChars")
        );

        this.form.controls['retypedNewPassword'].addValidators(
          minPasswordChars(this.requirements.minPasswordUpperCaseChars, /[A-Z]/, "minPasswordUpperCaseChars")
        );
      }

      // Min special chars validation
      if (this.requirements.minPasswordSpecialChars > 0) {

        this.form.controls['newPassword'].addValidators(
          minPasswordChars(this.requirements.minPasswordSpecialChars, /[^a-zA-Z0-9]/, "minPasswordSpecialChars")
        );

        this.form.controls['retypedNewPassword'].addValidators(
          minPasswordChars(this.requirements.minPasswordSpecialChars, /[^a-zA-Z0-9]/, "minPasswordSpecialChars")
        );
      }

    }
  }

  /**
   * Cancel button handler
   *
   * @memberof EidosChangePasswordDialogComponent
   */
  onCancelClick(): void {
    this.loading = false;
    if (!this.requirements.mustChangePassword) {
      this.dialogRef.close();
    }
  }

  /**
   * Set password handler
   *
   * @return {*}  {void}
   * @memberof EidosChangePasswordDialogComponent
   */
  onSetPasswordClick(): void {
    let op = this.form.get('oldPassword');
    let np = this.form.get('newPassword');
    let rp = this.form.get('retypedNewPassword');

    if (rp?.value !== np?.value) return;

    let opwd = op?.value;
    let npwd = np?.value;

    this.eidosLogService.logDebug(EidosLogSeverity.Log, opwd);
    this.eidosLogService.logDebug(EidosLogSeverity.Log, npwd);

    this.loading = true;

    this.utilityService.changePassword(opwd, npwd).subscribe(result => {
      this.loading = false;
      if (!result) {
        this.snackBar.open(this.translocoService.translate("change_password.successful"));
        this.dialogRef.close();
      } else {
        // Log failure
        this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Change password fail');
        this.responseError = result;
      }
    });
  }

  ngOnDestroy() {
  }
}

function minPasswordChars(threshold: number, re: RegExp, validatorName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = !((control.value.match(re) || []).length >= threshold);
    if (forbidden) {
      const validationError: ValidationErrors = {};
      validationError[validatorName] = { value: control.value };
      return validationError;
    } else {
      return null;
    }
  };
}