<div class="eidos-context-menu">
  <!-- <mat-icon (click)="openContextMenu($event)" [matMenuTriggerFor]="actionsMenu">more_vert</mat-icon> -->
  <mat-icon [matMenuTriggerFor]="actionsMenu">more_vert</mat-icon>

  <mat-menu #actionsMenu="matMenu">
    <button *ngFor="let action of actions" mat-menu-item (click)="execAction(action)" [disabled]="!action.enabled">
      <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
      <span>{{ 'context_menu.' + action.caption | transloco }}</span>
    </button>
  </mat-menu>

</div>
