import { CoreModuleName } from "@app/core/models/core-constant.model";

export enum ModuleEnum {
  EntityMaster = 'entityMaster',
  Reservation = 'reservation',
  Generic = '',
}

export function CalcModuleType(value: string | null): ModuleEnum {
  let _ret: ModuleEnum = ModuleEnum.Generic;
  if (value == null || value == '') {
    _ret = ModuleEnum.Generic
  } else {
    switch (value.toLowerCase()) {
      case 'entitymaster':
        _ret = ModuleEnum.EntityMaster
        break;
      case CoreModuleName.Reservation:
        _ret = ModuleEnum.Reservation
        break;
    }
  }

  return _ret;
}

export type ModuleType = ModuleEnum
