import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, Observable } from 'rxjs';

import { EidosConfigService } from '../config/eidos-config.service';
import { DynamicApiResponse } from '../models/dynamic-api-response.model';
import { ICoreSearchConfiguration, ICoreSearchTabConfiguration } from '../models/eidos-search.model';
import { CoreFormatService } from './core-format.service';
import { EidosBaseApiService } from './eidos-base-api.service';
import { EidosLogService } from './eidos-log.service';
import { EidosSecurityService } from './eidos-security.service';
import { CoreBaseApiCommandParams } from '../models/core-base-api.model';

@Injectable({
  providedIn: 'root'
})
export class EidosEntityService extends EidosBaseApiService {
  /**
   * Entity search configuration for every type dictionary
   *
   * @protected
   * @type {{ [key: string]: {[keyType: string]: ICoreSearchConfiguration} }}
   * @memberof EidosEntityService
   */
  protected _entitySearchConfiguration: { [key: string]: { [keyType: string]: ICoreSearchConfiguration } } = {};

  constructor(
    public coreFormatService: CoreFormatService,
    public configService: EidosConfigService,
    public eidosLogService: EidosLogService,
    public dialog: MatDialog,
    public eidosSecurityService: EidosSecurityService,
    public http: HttpClient
  ) {
    super(coreFormatService, configService, eidosLogService, dialog, eidosSecurityService, http);
  }
  /**
   * Retrieves search configuration of the specified entity from API
   *
   * @param {string} entity
   * @return {*} 
   * @memberof EidosEntityService
   */
  protected getEntitySearchConfigurationApi(entity: string) {
    return this.sendCommand(new CoreBaseApiCommandParams({
      type: 'POST', url: 'entity/config',
      data: { entity: entity }, useVersion: true
    }))
      .pipe(
        map<HttpResponse<DynamicApiResponse>, DynamicApiResponse>(
          response => new DynamicApiResponse(response)),
        map<DynamicApiResponse, ICoreSearchConfiguration>(
          response => {
            if (response.body.length > 0) {
              return response.body.length === 1 ? response.body[0] as ICoreSearchTabConfiguration : <Array<ICoreSearchTabConfiguration>>response.body;
            } else {
              return [];
            }
          }),
        catchError(
          (error: HttpErrorResponse) => {
            return this.handleGenericError(error);
          }
        )
      );
  }
  /**
   * Retrieves search configuration of the specified entity from a local json
   *
   * @protected
   * @param {string} entity
   * @param {string} [searchType="search"]
   * @param {string} [module="core"]
   * @return {*}  {Observable<Object>}
   * @memberof EidosEntityService
   */
  protected getEntitySearchConfigurationJSON(entity: string, searchType: string = "search", module: string = "core"): Observable<Object> {
    return this.http.get(`assets/json/entities/${module}/${entity}/${searchType}.json`).pipe(
      map<Object, Object>(
        response => response
      ),
      catchError(
        (error: HttpErrorResponse) => {
          return this.handleGenericError(error);
        }
      )
    );
  }
}
