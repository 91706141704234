import { SafeBaseObject } from '@app/core/models/base-object.models';
import { DateTime } from 'luxon';

/**
 * TODO
 *
 * @export
 * @interface IReservationCashReceiptPaymentReason
 */
export interface IReservationCashReceiptPaymentReason {
  Msg: string;
}

export class ResCashReceiptCashAccount extends SafeBaseObject {
  gLAccountID: number = 0;
  gLAccountDESC: string = '';

  constructor(data?: IReservationCashReceiptCashAccount) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationCashReceiptCashAccount): void {
    this.addMangledProperty(data);
  }
}
export interface IReservationCashReceiptCashAccount {
  GLAccountID: number;
  GLAccountDESC: string;
}

/**
 * TODO
 *
 * @export
 * @interface IReservationCashReceiptCheckNO
 */
export interface IReservationCashReceiptCheckNO {
  Msg: string;
}

export class ResCashReceipt extends SafeBaseObject {
  public batchAmount: number;
  public batchID: string;
  public cashAccount: string;
  public currencyCod: string;
  public numberOfLines: number;
  public paymentType: string;
  public payments: ResCashReceiptPayment[] = [];
  public receiptDate: DateTime;
  public result: string;
  public sendInvoice: boolean;

  constructor(data?: IReservationApiCashReceipt) {
    super();
    this.batchAmount = 0;
    this.batchID = '';
    this.cashAccount = '';
    this.currencyCod = '';
    this.numberOfLines = 0;
    this.paymentType = '';
    this.receiptDate = DateTime.min();
    this.result = '';
    this.sendInvoice = false;
    if (data) this.updateData(data);
  }

  public get isValid(): boolean {
    return !!this.batchID;
  }

  override updateData(data: IReservationApiCashReceipt): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('receiptDate', data, 'ReceiptDate');
    this.addBooleanProperty('sendInvoice', data, 'SendInvoice');
    this.payments = data.Payments?.map((p) => new ResCashReceiptPayment(p)) || [];
  }
}

export interface IReservationApiCashReceipt {
  BatchAmount?: number;
  BatchID?: string;
  CashAccount?: string;
  CurrencyCod?: string;
  NumberOfLines?: number;
  PaymentType?: string;
  Payments?: IReservationApiCashReceiptPayment[];
  ReceiptDate?: string;
  Result?: string;
  SendInvoice?: string;
}

class ResCashReceiptPayment extends SafeBaseObject {
  public amount: number;
  public batchID: number;
  public bkgID: number;
  public checkNo: string;
  public emailTo: string;
  public reason: string;
  public reference: string;
  public result: string;
  public sequence: number;

  constructor(data?: IReservationApiCashReceiptPayment) {
    super();
    this.amount = 0;
    this.batchID = 0;
    this.bkgID = 0;
    this.checkNo = '';
    this.emailTo = '';
    this.reason = '';
    this.reference = '';
    this.result = '';
    this.sequence = 0;
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiCashReceiptPayment): void {
    this.addMangledProperty(data);
  }
}

interface IReservationApiCashReceiptPayment {
  Amount?: number;
  BatchID?: number;
  BkgID?: number;
  CheckNo?: string;
  EmailTo?: string;
  Reason?: string;
  Reference?: string;
  Result?: string;
  Sequence?: number;
}

export interface IReservationApiCreateCashReceipt extends IReservationApiCashReceiptPayment {
  CurrencyCod?: string;
  ReceiptTypeID?: number;
}