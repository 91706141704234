import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IEidosFiltersRegistry } from "@common/models/eidos-filters.models";
import { EidosFilterComponent } from "@common/components/entities/filters/eidos-filter/eidos-filter.component";
import { CoreFormatService } from "@app/core/services/core-format.service";

@Component({
  selector: 'eidos-filter-daterange',
  templateUrl: './eidos-filter-daterange.component.html',
  styleUrls: ['./eidos-filter-daterange.component.scss']
})
export class EidosFilterDaterangeComponent extends EidosFilterComponent {
  /**
   * Current filter value (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterDaterangeComponent
   */
  public _value: any;
  public get value(): any {
    return !!this.config?.from?.formatDateToApi ? CoreFormatService.FormatDateToApi(this._value) : this._value;
  }
  @Input() public set value(val: any) {
    // if (val !== undefined) {
      if (typeof val === "string" && this.config.to.formatDateToApi) {
        this._value = CoreFormatService.ApiFormatToDate(val);
      } else {
        this._value = val;
      }
      this.valueChange.emit(this.value);
    // }
  }
  @Output() 
  public valueChange: EventEmitter<IEidosFiltersRegistry["daterange"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["daterange"]["valueType"]>();
  /**
   * Current second filter (for range) value (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterDaterangeComponent
   */
  public _otherValue: any;
  public get otherValue(): any {
    return !!this.config?.to?.formatDateToApi ? CoreFormatService.FormatDateToApi(this._otherValue) : this._otherValue;
  }
  @Input() 
  public set otherValue(val: any) {
    // if (val !== undefined) {
      if (typeof val === "string" && this.config.to.formatDateToApi) {
        this._otherValue = CoreFormatService.ApiFormatToDate(val);
      } else {
        this._otherValue = val;
      }
      this.otherValueChange.emit(this.otherValue);
    // }
  }
  @Output() 
  public otherValueChange: EventEmitter<IEidosFiltersRegistry["daterange"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["daterange"]["valueType"]>();
  /**
   * Current real input value (2-way binding) to pass to the filter value setter
   *
   * @type {*}
   * @memberof EidosFilterDaterangeComponent
   */
  public get realValue(): any {
    return this._value;
  }
  public set realValue(val: any) {
    // Calls the setter to retrieve the value in the correct format
    this.value = val;
    this.realValueChange.emit(this._value);
  }
  @Output() public realValueChange: EventEmitter<any> = new EventEmitter<any>();
  /**
  * Current real input other value (2-way binding) to pass to the filter value setter
  *
  * @type {*}
  * @memberof EidosFilterDaterangeComponent
  */
  public get realOtherValue(): any {
    return this._otherValue;
  }
  public set realOtherValue(val: any) {
    // Calls the setter to retrieve the value in the correct format
    this.otherValue = val;
    this.realOtherValueChange.emit(this._value);
  }
  @Output() public realOtherValueChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["daterange"]["config"]}
   * @memberof EidosFilterDaterangeComponent
   */
  @Input() public config: IEidosFiltersRegistry["daterange"]["config"];

  constructor() {
    super();
  }
  /**
   * Exec configured OnValueChange function
   *
   * @memberof EidosFilterDaterangeComponent
   */
  public emitOnValueChange(): void {
    if (!!this.config.onValueChange) {
      const value: { [key: string]: IEidosFiltersRegistry["daterange"]["valueType"] | undefined } = {};
      value[this.config.property] = this.realValue;
      value[this.config.otherProperty!] = this.realOtherValue;
      this.config.onValueChange(this, value);
    }
    this.value = this.realValue;
    this.otherValue = this.realOtherValue;
    // this.otherValueChange.emit(this._otherValue);
  }
}
