// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material.module';
import { ErrorRoutingModule } from '@error/error-routing.module';

// Pages
import { ErrorComponent } from '@error/error.component';
import { ErrorPageNotFoundComponent } from '@error/pages/error-page-not-found/error-page-not-found.component';
import { ErrorModuleNotLicensed } from '@error/pages/error-module-not-licensed/error-module-not-licensed.component';
import { ErrorPaymentComponent } from '@error/pages/error-payment/error-payment.component';

@NgModule({
  declarations: [
    ErrorComponent,
    ErrorPageNotFoundComponent,
    ErrorModuleNotLicensed,
    ErrorPaymentComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ErrorRoutingModule
  ]
})
export class ErrorModule { 
}
