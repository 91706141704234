import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IEidosGridLinkConfiguration } from '@common/models/eidos-grid.models';

@Component({
  selector: 'eidos-grid-link-cell-template',
  templateUrl: './eidos-grid-link-cell-template.component.html',
  styleUrls: ['./eidos-grid-link-cell-template.component.scss']
})
export class EidosGridLinkCellTemplateComponent implements OnInit {

  constructor(private router: Router) { }

  public link: IEidosGridLinkConfiguration = { visible: true, disabled: false, tooltip: '', text: '', link: "", target: "_black" };

  private _data: any;
  @Input() public set data(value: any) {
    this._data = value;
    this.link = this.abc(this._data);
  }
  @Input() public internalRouting: boolean = false;

  public get style(): string {
    let s: string = '';
    if (this.link?.visible) {
      s = "display:block;";
    }
    else {
      s = "display:none;";
    }
    return s;
  }

  ngOnInit(): void {
  }

  public abc(data: any): IEidosGridLinkConfiguration {
    let ret: IEidosGridLinkConfiguration;
    let str: string = "";
    if (data.column) {
      let field: string = data.column.dataField;
      str = data.data[field];
    }
    else {
      str = data;
    }

    ret = { visible: true, disabled: false, tooltip: '', text: '', link: "", target: "_black", };

    if (str){

    let s0: string[] = str.toString().split('|');

    s0.forEach(s1 => {
      let prop: string = s1.split('=')[0];
      let val: any = s1.split('=')[1];

      switch (prop.toLowerCase()) {
        case "visible":
          ret.visible = val;
          break;
        case "disabled":
          ret.disabled = val;
          break;
        case "tooltip":
          ret.tooltip = val;
          break;
        case "text":
          ret.text = val;
          break;
        case "target":
          ret.target = val;
          break;
        case "link":
          ret.link = [...s1.split('=')].slice(1).join("=");
          break;
      }
    });
  }

    return ret;
  }

  public handleLinkClick(e: any, link: string | undefined) {
    e.preventDefault()
    this.internalRouting ? this.router.navigate([link]) : window.open(link, '_blank');
  }
}
