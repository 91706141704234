import { Component, OnInit } from '@angular/core';

import { EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';

@Component({
  selector: 'error-module-not-licensed',
  templateUrl: './error-module-not-licensed.component.html',
  styleUrls: ['./error-module-not-licensed.component.scss']
})
export class ErrorModuleNotLicensed implements OnInit {

  constructor(
    private logService: EidosLogService
  ) { }

  ngOnInit(): void {
    this.logService.logDebug(EidosLogSeverity.Log, 'Hello ErrorModuleNotLicensed!');
  }
}
