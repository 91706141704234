<ng-container *ngIf="eidosObjectGridConfiguration && eidosObjectData">
  <div
    class="grid-container"
    [ngClass]="{ 'no-scrollbar': options.hideScrollbar }"
  >
    <dx-data-grid
      class="eidos-grid-normal"
      [dataSource]="dataSource"
      [showBorders]="false"
      [allowColumnResizing]="true"
      [columnAutoWidth]="true"
      [remoteOperations]="true"
    >
      <dxo-filter-row [visible]="true"></dxo-filter-row>

      <dxi-column
        *ngFor="let c of columns"
        [dataField]="c.name"
        [dataType]="c.dataType"
        [allowFiltering]="!!c.dataType && !c.disableFilter"
        [caption]="c.label"
        cellTemplate="cellTemplate"
      >
      </dxi-column>

      <ng-container *dxTemplate="let data of 'cellTemplate'">
        <eidos-field
          [fieldConfig]="getFieldConfig(columns[data.columnIndex].label)"
          [fieldContainer]="eidosObjectGridConfiguration"
          [fieldData]="data.data"
        >
        </eidos-field>
      </ng-container>

      <dxo-paging [(pageSize)]="pageSize"></dxo-paging>
      <dxo-pager
        [visible]="true"
        displayMode="full"
        [showInfo]="true"
        [showNavigationButtons]="true"
      ></dxo-pager>
    </dx-data-grid>
  </div>
</ng-container>
