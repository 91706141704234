import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'eidos-grid-full-text-template',
  templateUrl: './eidos-grid-full-text-template.component.html',
  styleUrls: ['./eidos-grid-full-text-template.component.scss'],
})
export class EidosGridFullTextTemplateComponent implements OnInit {
  constructor() {}

  private _data: any;
  public get data(): any {
    return this._data;
  }
  @Input() public set data(value: any) {
    this._data = value;
    this.value = value?.value ?? '';
  }

  protected value: string = '';

  ngOnInit(): void {}
}
