import { Component } from "@angular/core";
import { AppModule } from '@app/app.module';

import { EidosLogSeverity } from "@common/models/core-constant.model";
import { EidosLogService } from "@common/services/eidos-log.service";
import { EidosUtilityService } from "@common/services/eidos-utility.service";
import { EidosBaseComponent } from "@common/components/eidos-base.component";
import { IEidosChartJSConfiguration, ILevelGaugeConfiguration } from "@common/models/eidos-chart-js.interface";
import { EidosChartType } from "@common/models/eidos-chart-js.model";
import { EidosObject, EidosObjectChartDimension, EidosObjectConfiguration, EidosObjectData, EidosObjectGridConfig, EidosObjectGridField, EidosObjectHtmlConfig, EidosObjectType, IEidosGridValue } from "@common/models/eidos-object.model";
import { IEidosChartConfigurationData, IEidosObjectChartDimensionValue, IEidosScalarChartConfigurationData } from "@common/models/eidos-object.interface";

@Component({ selector: 'eidos-object', template: '' })
export class EidosObjectBaseComponent extends EidosBaseComponent {

  static eidosObjectCounter: number = 0;
  public eidosObject: EidosObject | undefined;
  public eidosObjectConfiguration: EidosObjectConfiguration | undefined;
  public eidosObjectData: EidosObjectData | undefined;
  public eidosObjectId: number;
  /**
   * Object (re)loading flag
   *
   * @type {boolean}
   * @memberof EidosObjectBaseComponent
   */
  public loading: boolean = false;
  public errorMessage: string = '';
  public errorLoading: boolean = false;
  public inDebugMode: boolean = false;

  eidosDataUpdate(): void {
    if (!this.eidosObjectConfiguration) return;

    switch (this.eidosObjectConfiguration.objectType) {
      case EidosObjectType.Chart:
        switch (this.eidosObjectConfiguration.objectDetailType) {
          case EidosChartType.Gauge:
          case EidosChartType.Level:
            this.resolveTitle();
            return this.setScalarValue();
          case EidosChartType.Chartjs:
            this.resolveTitle();
            this.resolveAxisLabels();
            return this.setDimensionsValue();
        }
        break;
      case EidosObjectType.Html:
        return this.resolveTemplate();
      case EidosObjectType.Dashboard:
        return this.setDashboardData();
      case EidosObjectType.Grid:
        return this.setGridData();
    }
  }
  public getEidosObjectId(): number {
    return this.eidosObject?.eidosObjectId || 0;
  }

  getStateObject() {

  }

  resolveTemplate(): void {
    if (!this.eidosObjectData) return;
    if (!this.eidosObjectConfiguration) return;
    if (!this.eidosObjectConfiguration.objectDetails) return;
    const objHtml = this.eidosObjectConfiguration.objectDetails as EidosObjectHtmlConfig;

    objHtml.templateResolved = objHtml.template;
    const dataReplaces: Array<{ key: string, value: string }> = [];
    if (this.eidosObjectData.data.values?.length > 0) {
      Object.entries(this.eidosObjectData.data.values[0]).forEach(
        ([key, value]) => {
          dataReplaces.push({ key: '[[' + key + ']]', value: value })
        });
    }
    dataReplaces.forEach(element => {
      objHtml.templateResolved = objHtml.templateResolved.replace(element.key, element.value);
    });

    objHtml.placeholderValues?.forEach(
      ph => {
        if (!ph.value || !ph.placeholder) return;

        let v = ph.value;
        dataReplaces.forEach(element => {
          v = v.replace(element.key, element.value);
        });
        try {
          objHtml.templateResolved = objHtml.templateResolved.replace('{{' + ph.placeholder + '}}', eval(v));
        }
        catch (e) {
          this.logService.logDebug(EidosLogSeverity.Warning, 'html object: eval error', e);
        }
      }
    );

    //clear unresolved or unused placeholder
    if (!this.inDebugMode) {
      objHtml.templateResolved = objHtml.templateResolved.replace(/{{[\w,\s]*}}/, '').replace(/\[\[[\w,\s]*\]\]/, '');
    }
  }

  setDashboardData(): void {
    if (!this.eidosObjectData) return;
    if (!this.eidosObjectConfiguration) return;
    //TODO load dashbord data
  }

  setGridData(): void {
    if (!this.eidosObjectData) return;
    if (!this.eidosObjectConfiguration) return;

    this.eidosObjectData.currentPage = this.eidosObjectData!.data.pageData.currentPage ?? 1;
    this.eidosObjectData.pageSize = this.eidosObjectData!.data.pageData.pageSize ?? 10;
    this.eidosObjectData.totalPages = this.eidosObjectData!.data.pageData.pages ?? 0;
    this.eidosObjectData.totalRows = this.eidosObjectData!.data.pageData.totalRows ?? 0;
    this.eidosObjectData.value = this.eidosObjectData!.data.values.map((r: any) => {
      const obj: any = {};
      const objDetails = this.eidosObjectConfiguration!.objectDetails as EidosObjectGridConfig;
      objDetails.fields.forEach((f: EidosObjectGridField) => {
        obj[f.name] = this.eidosObjectData!.data.isOldStyle ? r[this.eidosObjectData!.data.columns.indexOf(f.name)] : r[f.name];
        if (f.dataType === 'file') obj[f.fileId] = this.eidosObjectData!.data.isOldStyle ? r[this.eidosObjectData!.data.columns.indexOf(f.fileId)] : r[f.fileId];
      });
      return obj;
    }) as IEidosGridValue;

    let gridValue = this.eidosObjectData.value as IEidosGridValue;

    // Adjust last page
    if (this.eidosObjectData.currentPage === this.eidosObjectData.totalPages) {
      if (gridValue.length < this.eidosObjectData.pageSize) gridValue.length = this.eidosObjectData.pageSize;
    }
  }

  setScalarValue(): void {
    if (!this.eidosObjectData) return;
    if (!this.eidosObjectConfiguration) return;

    if (!this.eidosObjectData.data) {
      this.eidosObjectData.value = 0;
    } else {
      const objDetails = this.eidosObjectConfiguration.objectDetails as IEidosScalarChartConfigurationData;

      if (this.eidosObjectData.data.values?.length > 0 && objDetails?.fieldName) {
        let value = (this.eidosObjectData.data.values[0] as { [key: string]: any })[objDetails.fieldName];
        if (this.eidosObjectConfiguration.objectDetailType == EidosChartType.Level) {
          const config = objDetails.config as ILevelGaugeConfiguration;

          value *= 100 / (config.maxValue ?? 100);
        }
        this.eidosObjectData.value = value;
      }
    }
  }

  /**
   * Resolve ChartJS chart title
   *
   * @private
   * @return {*}  {void}
   * @memberof EidosObjectBaseComponent
   */
  private resolveTitle(): void {
    if (!this.eidosObjectData) return;
    if (!this.eidosObjectConfiguration) return;
    if (!this.eidosObjectConfiguration.objectDetails) return;

    const objDetails = this.eidosObjectConfiguration.objectDetails as IEidosChartConfigurationData;

    if (this.eidosObjectData.data.values?.length > 0 && objDetails.title) {
      let title = objDetails.title || '';
      Object.entries(this.eidosObjectData.data.values[0]).forEach(
        ([key, value]) => {
          title = title.replace('[[' + key + ']]', value);
        });
      (this.eidosObjectConfiguration.objectDetails as IEidosChartConfigurationData).titleResolved = title;
    }
  }

  /**
   * Resolve ChartJS chart axis labels
   *
   * @private
   * @return {*}  {void}
   * @memberof EidosObjectBaseComponent
   */
  private resolveAxisLabels(): void {
    if (!this.eidosObjectData) return;
    if (!this.eidosObjectConfiguration) return;
    if (!this.eidosObjectConfiguration.objectDetails) return;

    const objDetails = this.eidosObjectConfiguration.objectDetails as IEidosChartConfigurationData;
    let config = objDetails.config as IEidosChartJSConfiguration;

    if (this.eidosObjectData.data.values?.length > 0 && config.labelAxis) {
      let xAxesLabel = config.labelAxis.xAxesLabel || '';
      let yAxesLabel = config.labelAxis.yAxesLabel || '';
      Object.entries(this.eidosObjectData.data.values[0]).forEach(
        ([key, value]) => {
          xAxesLabel = xAxesLabel.replace('[[' + key + ']]', value);
          yAxesLabel = yAxesLabel.replace('[[' + key + ']]', value);
        });
      config.labelAxis = {
        xAxesLabel: xAxesLabel,
        yAxesLabel: yAxesLabel
      };
    }
  }

  setDimensionsValue(): void {
    if (this.eidosObjectData && this.eidosObjectConfiguration && this.eidosObjectData.data) {
      const objDetails = this.eidosObjectConfiguration.objectDetails as IEidosChartConfigurationData;
      if (objDetails?.config) {
        const config = objDetails?.config as IEidosChartJSConfiguration;
        this.eidosObjectData.value = config.dimensions.map((s: EidosObjectChartDimension) => {
          return this.eidosObjectData!.data.values.map((p: any) => {
            return {
              x: p[s.xField],
              y: s.yField ? p[s.yField] : undefined,
              z: s.zField ? p[s.zField] : undefined
            } as IEidosObjectChartDimensionValue;
          });
        });
      }
    }
  }

  private eus: EidosUtilityService;
  private logService: EidosLogService;
  constructor(
  ) {
    super();
    EidosObjectBaseComponent.eidosObjectCounter = EidosObjectBaseComponent.eidosObjectCounter + 1;
    this.eidosObjectId = EidosObjectBaseComponent.eidosObjectCounter;
    this.eus = AppModule.injector.get(EidosUtilityService);
    this.logService = AppModule.injector.get(EidosLogService);
    this.eus.inDebugMode.subscribe(debug => this.inDebugMode = debug);
  }
}

