import { DateTime } from "luxon";

import { SafeBaseObject } from "@app/core/models/base-object.models";
import { ResBooking } from "./res-booking.model";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppModule } from "@app/app.module";

export interface IResGetGuestBookedActivitiesParams {
  /**
   * Reservation Booking ID
   *
   * @type {number}
   * @memberof IResGetGuestBookedActivitiesParams
   */
  BkgID: number;
  /**
   * Reservation Guest Code
   *
   * @type {number}
   * @memberof IResGetGuestBookedActivitiesParams
   */
  GuestCod?: number;
  /**
   * Start date to search the guest activities
   *
   * @type {string}
   * @memberof IResGetGuestBookedActivitiesParams
   */
  StartDateTime?: string;
  /**
   * End date to search the guest activities
   *
   * @type {string}
   * @memberof IResGetGuestBookedActivitiesParams
   */
  EndDateTime?: string;
}

export class ReservationBookingActivitiesBooked extends SafeBaseObject {
  restaurantBooked: ReservationBookedRestaurantActivity[] = [];
  shorexBooked: ReservationBookedShorexActivity[] = [];
  spaBooked: ReservationBookedSpaActivity[] = [];

  constructor(data: IReservationApiBookedActivities) {
    super();

    this.restaurantBooked = data.RestaurantBooked ? data.RestaurantBooked.map(x => new ReservationBookedRestaurantActivity(x)).filter(s=>s.bookingStatus.toLowerCase()!=='cancelled') : [];
    this.shorexBooked = data.ShorexBooked ? data.ShorexBooked.map(x => new ReservationBookedShorexActivity(x)).filter(s=>s.bookingStatus.toLowerCase()!=='cancelled') : [];
    this.spaBooked = data.SpaBooked ? data.SpaBooked.map(x => new ReservationBookedSpaActivity(x)).filter(s=>s.bookingStatus.toLowerCase()!=='cancelled') : [];
  }
}

export interface IReservationApiBookedActivities {
  RestaurantBooked: IReservationApiBookedRestaurantActivity[];
  ShorexBooked: IReservationApiBookedShorexActivity[];
  SpaBooked: IReservationApiBookedSpaActivity[];
}

interface IReservationApiActivityGuestBooked {
  ActivityBkgID: number;
  Currency: string;
  FirstName: string;
  GuestCod: number;
  GuestID: number;
  GuestPrice: number;
  LastName: string;
  MiddleName: string;
}

class ReservationActivityGuestBooked extends SafeBaseObject {
  activityBkgID: number = -1;
  currency: string = '';
  firstName: string = '';
  guestCod: number = -1;
  guestID: number = -1;
  guestPrice: number = -1;
  lastName: string = '';
  middleName: string = '';

  constructor(data?: IReservationApiActivityGuestBooked) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
  displayName() {
    return `${this.firstName} ${this.middleName} ${this.lastName}`;
  }
}

export class ReservationBookedShorexActivity extends SafeBaseObject {
  activityBkgID: number = -1;
  bkgID: number = ResBooking.INVALID_BOOKING;
  bookingDateTime: DateTime = DateTime.now();
  bookingStatus: string = '';
  city: string = '';
  cityCod: string = '';
  cityID: number = -1;
  code: string = '';
  currency: string = '';
  duration: number = -1;
  endDateTime: DateTime = DateTime.now();
  guestNum: number = -1;
  kidFriendly: string = '';
  /**
   * Long description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedShorexActivity
   */
  longDescription: string = '';
  macrotime: string = '';
  meal: string = '';
  note: string = '';
  operator: string = '';
  physicalLevel: string = '';
  portCod: string = '';
  reservation: boolean = false;
  restrictions: string = '';
  shorexCategory: Array<{
    category: string;
    categoryID: number;
    description: string;
    shorexID: number;
    sortOrder: number;
  }> = [];
  shorexID: number = -1;
  shorexPictures: Array<{
    photoId: number;
    shorexID: number;
    blbID: number;
    imageType: string;
    imageName: string;
    imageURL: string;
    note: string;
  }> = [];
  shorexPicturesSafeUrl: SafeUrl[] = [];
  shorexPrice: Array<{
    description: string;
    priceID: number;
    retailPrice: number;
    retailPriceCategory: string;
    shorexID: number;
    sortOrder: number;
    validFrom: DateTime;
    validTo: DateTime;
  }> = [];
  shorexType: string = '';
  onHoldQty: number=0;
  /**
   * Short description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedShorexActivity
   */
  shortDescription: string = '';
  onlySingleDescription:boolean = false;
  startDateTime: DateTime = DateTime.now();
  supplierBkgID: number = ResBooking.INVALID_BOOKING;
  time: number = -1;
  title: string = '';
  totalPrice = ResBooking.INVALID_BOOKING;

  shorexBookingGuests: ReservationActivityGuestBooked[] = [];

  private sanitizer: DomSanitizer;

  constructor(data?: IReservationApiBookedShorexActivity) {
    super();
    this.sanitizer = AppModule.injector.get(DomSanitizer)
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiBookedShorexActivity): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('bookingDateTime', data, 'BookingDateTime');
    this.addDateTimeProperty('endDateTime', data, 'EndDateTime');
    this.addDateTimeProperty('startDateTime', data, 'StartDateTime');

    this.shorexPrice = (data.ShorexPrice ?? []).map(item => {
      return {
        description: item.Description,
        priceID: item.PriceID,
        retailPrice: item.RetailPrice,
        retailPriceCategory: item.RetailPriceCategory,
        shorexID: item.ShorexID,
        sortOrder: item.SortOrder,
        validFrom: DateTime.fromISO(item.ValidFrom),
        validTo: DateTime.fromISO(item.ValidTo)
      }
    });

    this.shorexCategory = (data.ShorexCategory ?? []).map(item => {
      return {
        category: item.Category,
        categoryID: item.CategoryID,
        description: item.Description,
        shorexID: item.ShorexID,
        sortOrder: item.SortOrder
      }
    });

    this.shorexPictures = (data.ShorexPictures ?? []).map(item => {
      return {
        photoId: item.PhotoId,
        shorexID: item.ShorexID,
        blbID: item.BlbID,
        imageType: item.ImageType,
        imageName: item.ImageName,
        imageURL: item.ImageURL,
        note: item.Note
      }
    });
    this.shorexPicturesSafeUrl = this.shorexPictures.map(p =>
      this.sanitizer.bypassSecurityTrustResourceUrl(p.imageURL)
    );
    this.shorexBookingGuests = (data.ShorexBookingGuests ?? []).map(item => new ReservationActivityGuestBooked(item));
    this.onlySingleDescription = !this.shortDescription || !this.longDescription;
    if(!this.shortDescription) this.shortDescription = this.longDescription;
  }
}

interface IReservationApiBookedShorexActivity {
  ActivityBkgID: number;
  BkgID: number;
  BookingDateTime: string;
  BookingStatus: string;
  City: string;
  CityCod: string;
  CityID: number;
  Code: string;
  Currency: string;
  Duration: number;
  EndDateTime: string;
  GuestNum: number;
  KidFriendly: string;
  /**
   * Long description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedShorexActivity
   */
  LongDescription: string;
  Macrotime: string;
  Meal: string;
  Note: string;
  Operator: string;
  PhysicalLevel: string;
  PortCod: string;
  Reservation: boolean;
  Restrictions: string;
  ShorexCategory: Array<{
    Category: string;
    CategoryID: number;
    Description: string;
    ShorexID: number;
    SortOrder: number;
  }>;
  ShorexID: number;
  ShorexPictures: Array<{
    PhotoId: number;
    ShorexID: number;
    BlbID: number;
    ImageType: string;
    ImageName: string;
    ImageURL: string;
    Note: string;
  }>;
  ShorexPrice: Array<{
    Description: string;
    PriceID: number;
    RetailPrice: number;
    RetailPriceCategory: string;
    ShorexID: number;
    SortOrder: number;
    ValidFrom: string;
    ValidTo: string;
  }>;
  ShorexType: string;
  /**
   * Short description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedShorexActivity
   */
  ShortDescription: string;
  StartDateTime: string;
  SupplierBkgID: number;
  Time: number;
  Title: string;
  TotalPrice: number;

  ShorexBookingGuests: IReservationApiActivityGuestBooked[];
}

export class ReservationBookedRestaurantActivity extends SafeBaseObject {
  activityBkgID: number = -1;
  bkgID: number = ResBooking.INVALID_BOOKING;
  supplierBkgID: number = -1;
  guestNum: number = -1;
  startDateTime: DateTime = DateTime.now();
  endDateTime: DateTime = DateTime.now();
  duration: number = -1;
  totalPrice: number = -1;
  currency: string = '';
  bookingStatus: string = '';
  bookingDateTime: DateTime = DateTime.now();
  restaurantID: number = -1;
  code: string = '';
  categoryCode: string = '';
  category: string = '';
  styleCode: string = '';
  style: string = '';
  deck: number = -1;
  kitchenCode: string = '';
  kitchen: string = '';
  name: string = '';
  /**
   * Long description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedRestaurantActivity
   */
  longDescription: string = '';
  /**
   * Short description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedRestaurantActivity
   */
  shortDescription: string = '';
  onlySingleDescription:boolean = false;
  note: string = '';
  shipID: number = -1;
  defaultCapacity: number = -1;
  reservation: boolean = false;
  shipName: string = '';

  restaurantPrice: Array<{
    priceID: number;
    restaurantID: number;
    validFrom: DateTime;
    validTo: DateTime;
    retailPrice: number;
    retailPriceCategoryCode: string;
    retailPriceCategory: string;
    retailPricePosition: number;
  }> = [];

  restaurantServiceSlot: Array<{
    restaurantServiceSlotID: number;
    restaurantID: number;
    restaurantSlotID: number;
    restaurantServiceTime: DateTime;
    restaurantServiceEndTime: DateTime;
    serviceCapacity: number;
    waitListLocked: boolean;
  }> = [];

  restaurantBookingGuests: ReservationActivityGuestBooked[] = [];

  constructor(data?: IReservationApiBookedRestaurantActivity) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiBookedRestaurantActivity): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('bookingDateTime', data, 'BookingDateTime');
    this.addDateTimeProperty('endDateTime', data, 'EndDateTime');
    this.addDateTimeProperty('startDateTime', data, 'StartDateTime');

    this.restaurantPrice = (data.RestaurantPrice ?? []).map(item => {
      return {
        priceID: item.PriceID,
        restaurantID: item.RestaurantID,
        validFrom: DateTime.fromISO(item.ValidFrom),
        validTo: DateTime.fromISO(item.ValidTo),
        retailPrice: item.RetailPrice,
        retailPriceCategoryCode: item.RetailPriceCategoryCode,
        retailPriceCategory: item.RetailPriceCategory,
        retailPricePosition: item.RetailPricePosition,
      }
    });

    this.restaurantServiceSlot = (data.RestaurantServiceSlot ?? []).map(item => {
      return {
        restaurantServiceSlotID: item.RestaurantServiceSlotID,
        restaurantID: item.RestaurantID,
        restaurantSlotID: item.RestaurantSlotID,
        restaurantServiceTime: DateTime.fromISO(item.RestaurantServiceTime),
        restaurantServiceEndTime: DateTime.fromISO(item.RestaurantServiceEndTime),
        serviceCapacity: item.ServiceCapacity,
        waitListLocked: item.WaitListLocked
      }
    });

    this.onlySingleDescription = !this.shortDescription || !this.longDescription;
    if(!this.shortDescription) this.shortDescription = this.longDescription;
    this.restaurantBookingGuests = (data.RestaurantBookingGuests ?? []).map(item => new ReservationActivityGuestBooked(item));
  }
}

interface IReservationApiBookedRestaurantActivity {
  ActivityBkgID: number;
  BkgID: number;
  SupplierBkgID: number;
  GuestNum: number;
  StartDateTime: string;
  EndDateTime: string;
  Duration: number;
  TotalPrice: number;
  Currency: string;
  BookingStatus: string;
  BookingDateTime: string;
  RestaurantID: number;
  Code: string;
  CategoryCode: string;
  Category: string;
  StyleCode: string;
  Style: string;
  Deck: number;
  KitchenCode: string;
  Kitchen: string;
  Name: string;
  /**
   * Long description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedRestaurantActivity
   */
  LongDescription: string;
  /**
   * Short description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedRestaurantActivity
   */
  ShortDescription: string;
  Note: string;
  ShipID: number;
  DefaultCapacity: number;
  Reservation: boolean;
  ShipName: string;
  RestaurantPrice: Array<{
    PriceID: number;
    RestaurantID: number;
    ValidFrom: string;
    ValidTo: string;
    RetailPrice: number;
    RetailPriceCategoryCode: string;
    RetailPriceCategory: string;
    RetailPricePosition: number;
  }>;
  RestaurantServiceSlot: Array<{
    RestaurantServiceSlotID: number;
    RestaurantID: number;
    RestaurantSlotID: number;
    RestaurantServiceTime: string;
    RestaurantServiceEndTime: string;
    ServiceCapacity: number;
    WaitListLocked: boolean;
  }>;
  RestaurantBookingGuests: IReservationApiActivityGuestBooked[];
}

export class ReservationBookedSpaActivity extends SafeBaseObject {
  active: boolean = false;
  activityBkgID: number = -1;
  activityDuration: number = -1;
  bkgID: number = ResBooking.INVALID_BOOKING;
  bookable: boolean = false;
  bookingDateTime: DateTime = DateTime.now();
  bookingStatus: string = '';
  cancelNoticeTime: number = -1;
  cancellationFee: string = '';
  categoryId: number = -1;
  categoryName: string = '';
  categoryShortName: string = '';
  categorySortOrder: number = -1;
  code: string = '';
  contraIndications: string = '';
  currency: string = '';
  description: string = '';
  duration: number = -1;
  endDateTime: DateTime = DateTime.now();
  group: string = '';
  guestCount: number = -1;
  guestDuration: number = -1;
  guestNum: number = -1;
  level: number = -1;
  /**
   * Long description as HTML content
   *
   * @type {string}
   * @memberof ReservationApiBookedSpaActivity
   */
  longDescription: string = '';
  minAge: number = -1;
  name: string = '';
  price: number = -1;
  pricesCurrency: string = '';
  printDuration: number = -1;
  productName: string = '';
  productRanking: number = -1;
  ranking: number = -1;
  resourceDuration: number = -1;
  serviceId: number = -1;
  /**
   * Short description as HTML content
   *
   * @type {string}
   * @memberof ReservationApiBookedSpaActivity
   */
  shortDescription: string = '';
  onlySingleDescription:boolean = false;
  staffCount: number = -1;
  startDateTime: DateTime = DateTime.now();
  supplierBkgID: number = ResBooking.INVALID_BOOKING;
  therapistCount: number = -1;
  totalPrice: number = -1;
  transitionLag: number = -1;
  transitionLead: number = -1;
  vesselCode: string = '';

  spaBookingGuests: ReservationActivityGuestBooked[] = [];

  constructor(data: IReservationApiBookedSpaActivity) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IReservationApiBookedSpaActivity): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('bookingDateTime', data, 'BookingDateTime');
    this.addDateTimeProperty('endDateTime', data, 'EndDateTime');
    this.addDateTimeProperty('startDateTime', data, 'StartDateTime');

    this.spaBookingGuests = (data.SpaBookingGuests ?? []).map(item => new ReservationActivityGuestBooked(item));
    this.onlySingleDescription = !this.shortDescription || !this.longDescription;
    if(!this.shortDescription) this.shortDescription = this.longDescription;

  }
}

interface IReservationApiBookedSpaActivity {
  Active: boolean;
  ActivityBkgID: number;
  ActivityDuration: number;
  BkgID: number;
  Bookable: boolean;
  BookingDateTime: string;
  BookingStatus: string;
  CancelNoticeTime: number;
  CancellationFee: string;
  CategoryId: number;
  CategoryName: string;
  CategoryShortName: string;
  CategorySortOrder: number;
  Code: string;
  ContraIndications: string;
  Currency: string;
  Description: string;
  Duration: number;
  EndDateTime: string;
  Group: string;
  GuestCount: number;
  GuestDuration: number;
  GuestNum: number;
  Level: number;
  /**
   * Long description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedSpaActivity
   */
  LongDescription: string;
  MinAge: number;
  Name: string;
  Price: number;
  PricesCurrency: string;
  PrintDuration: number;
  ProductName: string;
  ProductRanking: number;
  Ranking: number;
  ResourceDuration: number;
  ServiceId: number;
  /**
   * Short description as HTML content
   *
   * @type {string}
   * @memberof IReservationApiBookedSpaActivity
   */
  ShortDescription: string;
  StaffCount: number;
  StartDateTime: string;
  SupplierBkgID: number;
  TherapistCount: number;
  TotalPrice: number;
  TransitionLag: number;
  TransitionLead: number;
  VesselCode: string;
  SpaBookingGuests: IReservationApiActivityGuestBooked[];
}
