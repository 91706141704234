import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'eidos-alert-dialog',
  styleUrls: [
    'eidos-alert-dialog.component.scss'
  ],
  templateUrl: 'eidos-alert-dialog.component.html'
})

export class EidosAlertDialogComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<EidosAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }
  
  /**
   * Close the dialog
   *
   * @memberof EidosAlertDialogComponent
   */
  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }
}