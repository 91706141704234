import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { EidosProgressSpinnerComponent } from '../components/eidos-progress-spinner/eidos-progress-spinner.component';

/**
 * Most of this is courtesy of
 * https://christianlydemann.com/four-ways-to-create-loading-spinners-in-an-angular-app/
 *
 * @export
 * @class SpinnerOverlayService
 */
@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {

  private overlayRef: OverlayRef | any = null;
  private counterTracker = 0;

  constructor(
    private overlay: Overlay
  ) {}

  /**
   * Show the spinner in overlay
   *
   * @memberof SpinnerOverlayService
   */
  show(): void {

    if (this.counterTracker === 0) {

      if (!this.overlayRef) {
        this.overlayRef = this.overlay.create();
      }

      // Create ComponentPortal that can be attached to a PortalHost
      const spinnerOverlayPortal = new ComponentPortal(EidosProgressSpinnerComponent);
      // @ts-ignore
      const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
    }

    this.counterTracker++;
  }
  /**
   * Hide the spinner in overlay
   *
   * @memberof SpinnerOverlayService
   */
  hide(): void {

    // This is to ensure multiple calls are handled correctly
    this.counterTracker--;

    if (!!this.overlayRef && (this.counterTracker === 0)) {
      this.overlayRef.detach();
    }
  }
}
