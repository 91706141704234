import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NavigationExtras, Params, Router } from '@angular/router';
import { EidosConfigService } from '../config/eidos-config.service';
import { IEidosModuleConfiguration } from '../config/environment.interface';
import { CoreModuleName, CoreRoute, EidosRoute, ICoreNavigationRoute, ReservationAdminRoute, ReservationRoute } from '../models/core-constant.model';
import { defaultModuleBaseUrl } from '../models/eidos-external-app.interface';
import { EidosSecurityService } from './eidos-security.service';

@Injectable({
  providedIn: 'root'
})
export class CoreModuleRedirectorService {
  public backRoute: BehaviorSubject<ICoreNavigationRoute | undefined> =
  new BehaviorSubject<ICoreNavigationRoute | undefined>(undefined);

  public savedBackRoute?: ICoreNavigationRoute | undefined;

  /**
   * Modules current configs subject
   *
   * @private
   * @type {BehaviorSubject<Array<IEidosModuleConfiguration>>}{
   * @memberof CoreModuleRedirectorService
   */
  private modulesConfig$: BehaviorSubject<Array<IEidosModuleConfiguration>>;

  private moduleBaseUrl = defaultModuleBaseUrl
  private isLocalHost = false
  private isValidForResSetup = true
  private isValidForReservation = true
  private isValidForCoreEidos = true

  constructor(
    private coreConfigService: EidosConfigService,
    private eidosSecurityService: EidosSecurityService,
    private router: Router
  ) {
    this.modulesConfig$ = this.coreConfigService.currentModulesConfig;

    const config = this.coreConfigService.currentConfig.getValue();
    this.moduleBaseUrl = config.moduleBaseUrl;

    this.isLocalHost = window.location.origin.startsWith('http://localhost')
    this.isValidForResSetup = window.location.origin===this.moduleBaseUrl.resAdmin
    this.isValidForReservation = window.location.origin===this.moduleBaseUrl.reservation
    this.isValidForCoreEidos = window.location.origin===this.moduleBaseUrl.eidos

    this.backRoute.subscribe((route) => {
      this.savedBackRoute = route;
    });
  }

  private getModuleBaseUrl(root:CoreModuleName) {
    if(this.isLocalHost) return window.location.origin

    switch(root) {
      case CoreModuleName.Reservation:
        return this.moduleBaseUrl.reservation;
      case CoreModuleName.ReservationSetup:
        return this.moduleBaseUrl.resAdmin;
      case CoreModuleName.Eidos:
        return this.moduleBaseUrl.eidos;
      default:
        return this.moduleBaseUrl.eidos;
    }
  }

  checkModule(name:CoreModuleName) {
    const url = this.router.routerState.snapshot.url.split('/').filter(s=>s!=='')
    const module = url.shift()
    if(!module) return;
    if(name.toLowerCase()===module.toLowerCase()) return
    this.appRedirectorRoute()
  }

  existCurrentModuleValidRoute(url:string[]) {
    console.log(url); //@TODO check vailid url return true if exist
    return false
  }
  getCurrentModuleValidRoute(url:string[]) {
    return url //@TODO return valid url
  }
  addToken(path:string){
    const token = this.eidosSecurityService.getToken();
    if(!token) return path;
    const sep = path.includes('?') ? '&' : '?';
    return `${path}${sep}token=${token}`
  }
  analinezeUrl(url:string) {
    const urlPart = url.split('/').filter(s=>s!=='')
    const protocol = (urlPart.shift()?.toLowerCase() ?? '')
    if(protocol==='') return {
      base: window.location.origin,
      url: url,
      firstSegment: '',
      otherSegments: [],
      segments: [],
      queryStringh: {} as Params,
      isSameDomain: false,
      hasToken: false,
    }
    const domain = (urlPart.shift()?.toLowerCase() ?? '')
    if(domain==='') return {
      base: window.location.origin,
      url: window.location.href,
      firstSegment: '',
      otherSegments: [],
      segments: [],
      queryStringh: {} as Params,
      isSameDomain: false,
      hasToken: false,
    }

    const url0 = (urlPart.shift()?.toLowerCase() ?? '')
    if(url0==='') return {
      base: `${protocol}//${domain}`,
      url: url,
      firstSegment: '',
      otherSegments: [],
      segments: [],
      queryStringh: {} as Params,
      isSameDomain: false,
      hasToken: false,
    }
    const module = Object.entries(CoreModuleName).filter(([_,v])=>v.toLowerCase()===url0.toLowerCase()).map(([_,v])=>v)[0] ?? CoreModuleName.Reservation
    let isValidModuleRoute =false
    const firstChildRoute =((urlPart[0] ?? '').split('?')[0] ?? '').toLowerCase()
    switch(module) {
      case CoreModuleName.Eidos:
        isValidModuleRoute = !!Object.values(CoreRoute).find(v=>v.toLowerCase()===firstChildRoute)
        break
      case CoreModuleName.Reservation:
        isValidModuleRoute = !!Object.values(ReservationRoute).find(v=>v.toLowerCase()===firstChildRoute)
        break
      case CoreModuleName.ReservationSetup:
        isValidModuleRoute = !!Object.values(ReservationAdminRoute).find(v=>v.toLowerCase()===firstChildRoute)
        break
      default:
        isValidModuleRoute = false
        break
    }
    const base = isValidModuleRoute ? `${protocol}//${domain}` : this.getModuleBaseUrl(CoreModuleName.Reservation);
    const params:Params={}
    const lastPart=urlPart.length > 0 ? urlPart[urlPart.length-1].split('?') : []
    let hasToken = false
    let qs = ''
    if(lastPart.length>1) {
      qs = `?${lastPart[1]}`
      urlPart[urlPart.length-1]=lastPart[0]
      lastPart[1].split('&').forEach(s=>{
        const kvp = s.split('=')
        if(kvp.length>1) {
          if(kvp[0].toLowerCase()==='token') hasToken = true
          params[kvp[0]]=kvp[1]
        }
      })
    }

    const segments = isValidModuleRoute ? [url0,...urlPart] : [CoreModuleName.Reservation,ReservationRoute.Home]
    const l_base = this.isLocalHost ? window.location.origin : base
    const isSameDomain = window.location.href.toLowerCase().startsWith(l_base.toLowerCase())
    const l_url = `${l_base}/${segments.join('/')}${qs}`
    return {
      base: l_base,
      url: l_url,
      firstSegment: url0,
      otherSegments: urlPart,
      segments: segments,
      queryString: params,
      isSameDomain: isSameDomain,
      hasToken: hasToken,
    }
  }

  appRedirectorRoute() {
    const urlUnpacked = this.analinezeUrl(window.location.href)
    if(urlUnpacked.isSameDomain) this.router.navigate(urlUnpacked.segments,{queryParams:urlUnpacked.queryString})
    else window.open(urlUnpacked.url, '_self');
  }
  appNavigate(route:Array<ReservationAdminRoute|ReservationRoute|CoreRoute|string|number>,newTab = false, params:Params = {}) {
    const l_route = route.filter(r=>!Object.values(CoreModuleName).find(cmn=>cmn.toLowerCase() === r.toString().toLowerCase()))
    if(l_route.length===0)l_route.push(ReservationRoute.Home)
    const url0 = `${l_route[0]}`.toLowerCase()
    const isAdmRoute = Object.values(ReservationAdminRoute).map(v=>v.toLowerCase()).includes(url0)
    const isResRoute = Object.values(ReservationRoute).map(v=>v.toLowerCase()).includes(url0)
    const isCoreRoute = Object.values(CoreRoute).map(v=>v.toLowerCase()).includes(url0)
    const isEidosRoute = Object.values(EidosRoute).map(v=>v.toLowerCase()).includes(url0)

    let moduleBase = this.moduleBaseUrl.eidos
    let root = ''
    let otherDomain = false

    if (isResRoute) {
      moduleBase = this.moduleBaseUrl.reservation
      root = CoreModuleName.Reservation
      otherDomain = !this.isValidForReservation
    } else if(isAdmRoute) {
      moduleBase = this.moduleBaseUrl.resAdmin
      root = CoreModuleName.ReservationSetup
      otherDomain = !this.isValidForResSetup
    }  else if(isEidosRoute) {
      moduleBase = this.moduleBaseUrl.eidos
      root = CoreModuleName.Eidos
      otherDomain = !this.isValidForCoreEidos

    }  else if (isCoreRoute) {
      moduleBase = this.moduleBaseUrl.core
      otherDomain = !this.isValidForCoreEidos

    } else {
      moduleBase = window.location.origin
      otherDomain = true
    }

    if(root != '' && l_route[0] !== root) l_route.unshift(root)

    if(newTab || (otherDomain && !this.isLocalHost)) {
      this.otherDomainNavigate(newTab,moduleBase,l_route,params)
    } else {
      this.router.navigate(l_route, params);
    }

  }

  private otherDomainNavigate(newTab:boolean,baseUrl:string,route:Array<ReservationAdminRoute|ReservationRoute|CoreRoute|string|number>, params:Params = {}) {
    const queryParams = !!params.queryParams ? params.queryParams : params
    const qs = Object.keys(queryParams).map(k=>`${k}=${queryParams[k]}`)
    const qsSep = qs.length>0 ? '?' : ''
    const url =`${baseUrl}/${route.join('/')}${qsSep}${qs.join('&')}`;
    window.open(url, newTab ? '_blank' : '_self')
  }
  navigateIntegratedUrl(url:string,target:string) {
    const urlUnpacked = this.analinezeUrl(url);
    if(urlUnpacked.isSameDomain && !urlUnpacked.hasToken) {
      let navigationExtras: NavigationExtras = {
        queryParams: urlUnpacked.queryString,
        state: {}
      };
      this.router.navigateByUrl(urlUnpacked.segments.join('/'), navigationExtras)
    } else window.open(urlUnpacked.url, target);
  }

  /**
   * Navigate from fromModule to toModule in route, eventually adding queryParams
   *
   * @param {string} fromModule
   * @param {string} toModule
   * @param {(string | string[])} [route]
   * @param {{ [key: string]: string}} [queryParams]
   * @param {boolean} targetNewWindow
   * @memberof CoreModuleRedirectorService
   */
  navigate(fromModule: string, toModule: string, route?: string | string[], queryParams?: { [key: string]: string | number | boolean | undefined } | string, targetNewWindow?:boolean) {

    if (route != undefined && Array.isArray(route) && route.length > 0) {
      route = route.join('/');
    }

    if (fromModule === toModule) {
      if (queryParams) {
        this.router.navigate(
          (<string>route ?? '').split('/'),
          { queryParams: typeof queryParams === 'string' ? Object.fromEntries(queryParams!.split('&').map(qp => [qp.split('=')[0], qp.split('=')[1]])) : queryParams }
        );
      } else {
        this.router.navigate((<string>route ?? '').split('/'));
      }
      return;
    }

    const modulesConfig = this.modulesConfig$.getValue();
    const startModule = modulesConfig.find(module => module.moduleName === fromModule);

    if (startModule && startModule.crossReferenceURL && toModule in startModule.crossReferenceURL) {

      const endModuleURL = startModule.crossReferenceURL[toModule];

      try {
        new URL(endModuleURL);

        if (route != undefined && Array.isArray(route) && route.length > 0) {
          route = route.join('/');
        }
        const flatQP = typeof queryParams === 'string' ? queryParams : Object.entries(queryParams ?? {}).map(([key, val]) => `${key}=${val}`).join('&');

        if(targetNewWindow===true) {
          window.open(`${endModuleURL}/${route ?? ''}${flatQP ? '?' + flatQP : ''}`,'_blank');
          return;
        }

        window.location.href = `${endModuleURL}/${route ?? ''}${flatQP ? '?' + flatQP : ''}`;
        return;

      } catch (err) {
        // Use below console.error to notify the exception
      }
    }

    console.error(`The URL specified for navigating from ${fromModule} to ${toModule} is not a valid URL`);
  }
  public navigateBack(): void {
    if (!this.savedBackRoute) return;
    this.navigateTo(this.savedBackRoute);
  }

  public setBackRoute(route: ICoreNavigationRoute | undefined): void {
    this.backRoute.next(route);
  }
  public navigateTo(route: ICoreNavigationRoute): void {
    this.appNavigate(route.url,false, {queryParams: route.queryParams});
    // const qs = Object.entries(route.queryParams ?? []).map(
    //               ([key, value]) => `${key}=${value}`
    //             );
    // const url = route.url.join('/')+ qs
    // this.navigateIntegratedUrl(url,'_self');
    // if (!route || (route.url?.length ?? 0) === 0) return;
  }
}
