import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEidosConfirmationDialogComponentConfig } from '@common/models/eidos-dialog.interface';

@Component({
  selector: 'eidos-confirmation-dialog',
  styleUrls: [
    'eidos-confirmation-dialog.component.scss'
  ],
  templateUrl: 'eidos-confirmation-dialog.component.html'
})
export class EidosConfirmationDialogComponent implements OnInit, OnDestroy {
  /**
   * Title to display in the dialog
   *
   * @type {string}
   * @memberof EidosConfirmationDialogComponent
   */
  public title: string = "";
  /**
   * Message to display in the dialog
   *
   * @type {string}
   * @memberof EidosConfirmationDialogComponent
   */
  public message: string = "";
  /**
   * Label (transloco) for confirm button
   *
   * 
   * @type {string}
   * @memberof EidosConfirmationDialogComponent
   */
  public confirmationLabel: string = "";
  /**
   * Label (transloco) for cancel button
   *
   * @type {string}
   * @memberof EidosConfirmationDialogComponent
   */
  public cancelLabel: string = "";
  /**
   * On confirm handler
   *
   * @memberof EidosConfirmationDialogComponent
   */
  public onConfirm?: () => void;
  /**
   * On cancel handler
   *
   * @memberof EidosConfirmationDialogComponent
   */
  public onCancel?: () => void;

  constructor(
    private dialogRef: MatDialogRef<EidosConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public confirmationInfo: IEidosConfirmationDialogComponentConfig) {
  }

  ngOnInit(): void {
    this.title = this.confirmationInfo.title;
    this.message = this.confirmationInfo.message;
    this.confirmationLabel = this.confirmationInfo.confirmationLabel;
    this.cancelLabel = this.confirmationInfo.cancelLabel;
    this.onConfirm = this.confirmationInfo.onConfirm;
    this.onCancel = this.confirmationInfo.onCancel;
  }

  /**
   * Cancel the login and close the dialog
   *
   * @memberof EidosConfirmationDialogComponent
   */
  cancel(): void {
    if (this.onCancel) {
      this.onCancel();
    }
    this.dialogRef.close();
  }
  /**
   * Confirm the logout and close the dialog
   *
   * @memberof EidosConfirmationDialogComponent
   */
  confirm(): void {
    if (this.onConfirm) {
      this.onConfirm();
    }
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
  }
}
