<div class="eidos-filter-with-category-wrapper">
  <div class="search">
    <div class="category-select">
      <!-- category filter -->
      <dx-select-box [items]="categories" displayExpr="Desc" valueExpr="Cod" placeholder="All categories"
        [showClearButton]="false" (onOpened)="toggleCategorySelect()" (onClosed)="toggleCategorySelect()"
        [(value)]="filters['FieldName']">
        <div *dxTemplate="let data of 'dropDownButton'">
          <i *ngIf="!isCategorySelectOpened" class="fa-solid fa-chevron-down"></i>
          <i *ngIf="isCategorySelectOpened" class="fa-solid fa-chevron-up"></i>
        </div>
      </dx-select-box>
    </div>
    <div class="input-search">
      <!-- search filter -->
      <dx-text-box [(value)]="filters['FieldValue']" (onKeyDown)="keyDown($event)" placeholder="Search..."
        [showClearButton]="true"></dx-text-box>
    </div>
    <div class="button-search-wrapper">
      <!-- <res-button stylingMode="outlined"></res-button> -->
      <dx-button class="button-search" stylingMode="contained" text="SEARCH" (click)="handleSearch()">
      </dx-button>
    </div>
  </div>
  <div *ngIf="!!moreFilters" class="buttons-wrapper">
    <dx-button id="toggle-filters" class="button-filters" stylingMode="contained" icon="filter"
      (click)="togglePopover()">
    </dx-button>
    <!-- //(click)="buttonClick.emit($event)" -->
    <dx-button *ngIf="!!buttonText || !!buttonIcon" id="toggle-filters" class="button-dynamic" stylingMode="contained"
      [icon]="buttonIcon" [text]="buttonText" (click)="_NewBtnClick()">
    </dx-button>
  </div>
  <dx-popover container="filter-category" target="#toggle-filters" position="bottom" [(visible)]="isPopoverVisible">
    <div *dxTemplate="let data = model; of: 'content'" class="more-filters-template">
      <div *ngFor="let filterConfiguration of moreFilters" class="eidos-filter-wrapper more-filter">
        <ng-container [ngTemplateOutlet]="eidosFilterTemplate" [ngTemplateOutletContext]="{
            filters: filters,
            filterConfiguration: filterConfiguration,
            keydown: keyDown,
            scope: this,
            searchService: searchService
          }">
        </ng-container>
      </div>
      <!-- <dx-button class="button-apply" stylingMode="contained" text="APPLY" (click)="handleSearch($event)"></dx-button> -->
    </div>
  </dx-popover>
</div>

<ng-template #eidosFilterTemplate let-filterConfiguration="filterConfiguration" let-filters="filters"
  let-searchService="searchService" let-keydown="keydown" let-scope="scope">
  <eidos-filter [(value)]="filters[filterConfiguration.property]" (onKeyDown)="keydown($event, scope)"
    [searchService]="searchService" [config]="filterConfiguration"></eidos-filter>
</ng-template>