import { IEidosIcon } from "@app/core/config/environment.interface";

export enum EidosCommandTypes {
  ButtonBase,
  ButtonIcon,
  ButtonEidos,
  ButtonList,
  ButtonSwitch,
}
export interface IEidosCommandStatus {
  visible:boolean;
  disabled:boolean;
  onWorking:boolean;
  enableBack: boolean;
  enableForward: boolean;
}
export enum EidosCommandErrorTypes {
  Skip,
  Silent,
  Toast,
  Dialog,
}
interface IEidosCommandErrorBehavior {
  errorType:EidosCommandErrorTypes;
  message:string;
  waitingTime:number;
}
export interface IEidosCommandTooltip {
  position?:'top' | 'right' | 'left' | 'bottom';
  icon?:IEidosIcon;
  message:string | (()=>string);
  active?:()=>boolean;
}
export interface IEidosCommandPanelItemAction {

}
export interface IEidosCommandPanelItem {
  _id?:number;
  key:string;
  commandType: EidosCommandTypes;
  callback: (event?:any,self?:IEidosCommandPanelItem)=>void | Promise<boolean>,
  callbackAddParameters?:boolean,
  isDisabled?: ()=>boolean,
  isVisible?: ()=>boolean,
  switchStatus?:()=>boolean,
  title?:string;
  icon?:IEidosIcon;
  position?: 'Left' | 'Right';
  class?: string | object;
  style?: Array<{ key: string, value: string }>;
  errorBehavior?: IEidosCommandErrorBehavior;
  tooltip?: IEidosCommandTooltip;
  badgeTooltip?: IEidosCommandTooltip;
  actionItems?:IEidosCommandPanelItem[];
}
