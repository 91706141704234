<content *ngIf="dashboardConfig">
  <div *ngIf="dashboardConfig.enabled"
    class="eidos-dashboard"
    [style.grid-template-columns]="'repeat('+dashboardConfig.columns+', 1fr)'"
    [style.grid-template-rows]="'repeat('+dashboardConfig.rows+', 1fr)'"

    [ngStyle]="{'column-gap': dashboardConfig.columnsGap + 'pt','row-gap': dashboardConfig.rowsGap + 'pt'}"
    >

    <div *ngFor="let cell of dashboardConfig.eidosObject; let i = index"
      class="eidos-dashboard-cell"
      [class.eidos-dashboard-cell-design]="eidosLogservice.inDebugMode"
      [style.grid-column-start]="cell.column"
      [style.grid-column-end]="'span ' + cell.columnSize"
      [style.grid-row-start]="cell.row"
      [style.grid-row-end]="'span ' + cell.rowSize">
      <eidos-dashboard-item [eidosObject]="cell"></eidos-dashboard-item>
    </div>

    <div *ngFor="let cell of emptyCells; let i = index"
      class="eidos-dashboard-emptycell"
      [class.eidos-dashboard-cell-design]="eidosLogservice.inDebugMode">
      <div *ngIf="eidosLogservice.inDebugMode">
        {{i + 1}}
        <eidos-object-empty class="eidos-empty-cell" [emptyCell]="cell"></eidos-object-empty>
      </div>
    </div>
  </div>
</content>
