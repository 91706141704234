import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ResProduct } from '../models/res-package.model';
import { GeoTreeDestination } from '@app/res-setup/models/resadm-geo.model';

// da spostare in una file model
// interface IProductSearchFilter {
//   companies?: string;
//   productCod?: string[];
//   propertyName?: string;
//   destinations?: string;
//   monthFrom?: string;
//   departureDate?: string;
//   returnDate?: string;
//   currency?: string;
//   priceType?: number[];
//   voyageNumber?: string;
//   ship?: string;
//   packageType?: string;
//   availableCategory?: string;
// }

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  productSearchFilterUserPreferences: any | null = null // product search filters selected by the user
  checkedProductsList =  new BehaviorSubject<ResProduct[]>([]); //FOR POSSIBLE FUTURE USES -> list of all products checked by the user
  checkedCombinableProductsList =  new BehaviorSubject<ResProduct[]>([]); // list of combinable products checked by the user
  isProductSearchFiltersCleaned = new BehaviorSubject<boolean>(false) // subscribe to it to handle when the user click on the clear button in the search bar
  selectedDestinations = new BehaviorSubject<GeoTreeDestination[]>([]);
  constructor() {}

}
