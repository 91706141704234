import { SafeBaseObject } from "@app/core/models/base-object.models";
import { DateTime } from "luxon";
import { ReservationAirService } from "../services/reservation-air.service";
import { BookingGuest, BookingSection } from "./res-booking.model";
import { AppModule } from "@app/app.module";
import { EidosConfigService } from "@app/core/config/eidos-config.service";
import { CoreModuleName } from "@app/core/models/core-constant.model";

export interface IResAirPort {
  Aiport: string;
  AirportID: number;
  IataCod: string;
}

export interface IResAirClass {
  AirClassFrom: string;
  AirClassTo: string;
  AirClassFromGMT: string;
  AirClassToGMT: string;
}

export interface IResAirData {
  AirportFrom: IResAirPort[];
  AirClassFrom: IResAirClass[];
  AirportTo: IResAirPort[];
  AirClassTo: IResAirClass[];
}

export class ResAirData extends SafeBaseObject {
  airportFrom: IResAirPort[] = [];
  airClassFrom: IResAirClass[] = [];
  airportTo: IResAirPort[] = [];
  airClassTo: IResAirClass[] = [];

  constructor(data?: IResAirData) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

  override updateData(data: IResAirData): void {
    this.addMangledProperty(data);
  }

}
export class ResAirCombo {
  airportsFrom: IResAirPort[] = [];
  airClassFrom: IResAirClass[] = [];
  airportsTo: IResAirPort[] = [];
  airClassTo: IResAirClass[] = [];

  private _selectedAirportFrom: string = '';
  get selectedAirportFrom(): string {
    return this._selectedAirportFrom;
  }
  set selectedAirportFrom(value: string) {
    this._selectedAirportFrom = value;
    if (this.syncService) this.airService.selectedAirportFrom = value;
    if (!this.selectedAirportTo || this.selectedAirportTo === 'null') {
      this.selectedAirportTo = value
    }
  }

  private _selectedAirportTo: string = '';
  get selectedAirportTo(): string {
    return this._selectedAirportTo;
  }
  set selectedAirportTo(value: string) {
    this._selectedAirportTo = value;
    if (this.syncService) this.airService.selectedAirportTo = value;
  }

  _selectedAirClassFrom: string = '';
  set selectedAirClassFrom(value: string) {
    this._selectedAirClassFrom = value;
    if (this.syncService) this.airService.selectedAirClassFrom = value;
    if (!this.selectedAirClassTo || this.selectedAirClassTo === 'null') {
      this.selectedAirClassTo = value
    }
  }
  get selectedAirClassFrom(): string {
    return this._selectedAirClassFrom;
  }

  _selectedAirClassTo: string = '';
  set selectedAirClassTo(value: string) {
    this._selectedAirClassTo = value;
    if (this.syncService) this.airService.selectedAirClassTo = value;
  }
  get selectedAirClassTo(): string {
    return this._selectedAirClassTo;
  }

  airService: ReservationAirService;

  syncService: boolean;

  constructor(airService: ReservationAirService, syncService: boolean = true) {
    this.airService = airService;
    this.syncService = syncService

    this.loadAirData();
    this.syncSelectedAirData();
  }

  async loadAirData() {
    const airData = await this.airService.getAvailablesAirData();
    ({ airportFrom: this.airportsFrom, airClassFrom: this.airClassFrom, airportTo: this.airportsTo, airClassTo: this.airClassTo } = airData)
  }

  syncSelectedAirData() {
    if (!this.syncService) return;
    ({ selectedAirportFrom: this.selectedAirportFrom, selectedAirportTo: this.selectedAirportTo, selectedAirClassFrom: this.selectedAirClassFrom, selectedAirClassTo: this.selectedAirClassTo } = this.airService)
  }
}

export class BookingGuestAirData extends SafeBaseObject {
  bkgID: number = 0;
  sectionID: number = 0;
  guestCod: string = '';
  lastName: string = '';
  firstName: string = '';
  airPortFrom: string = '';
  airPortNameFrom: string = '';
  airClassFrom: string = '';
  airPortTo: string = '';
  airPortNameTo: string = '';
  airClassTo: string = '';
  pNRFrom: string = '';
  pNRTo: string = '';

  constructor(data?: IReservationApiBookingGuestAirData) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationApiBookingGuestAirData): void {
    this.addMangledProperty(data);
  }
}

export interface IReservationApiBookingGuestAirData {
  BkgID: number;
  SectionID: number;
  GuestCod: string;
  LastName: string;
  FirstName: string;
  AirPortFrom: string;
  AirPortNameFrom: string;
  AirClassFrom: string;
  AirPortTo: string;
  AirPortNameTo: string;
  AirClassTo: string;
}

export interface IReservationApiUpdateBookingGuestAirData {
  BkgID: number;
  SectionID: number;
  Air: IReservationApiGuestAirData[];
}

export interface IReservationApiGuestAirData {
  GuestID: number;
  AirPortFrom: string;
  AirClassFrom: string;
  AirPortTo: string;
  AirClassTo: string;
}

export class ReservationAirLine extends SafeBaseObject {
  airLine: string = '';
  airLineCod: string = '';
  airLineID: number = 0;

  constructor(data?: IReservationApiAirLine) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationApiAirLine): void {
    this.addMangledProperty(data);
  }
}

export interface IReservationApiAirLine {
  AirLine: string;
  AirLineCod: string;
  AirLineID: number;
}

export interface IReservationApiGetAirResultsParams {
  bookingId: number;
  sailDate: string;
  cabinClass: string;
  contractTypes: string[];
  carriers: string[];
  maxConnectionTime?: number;
  directFlights: boolean;
  noAirportChanges: boolean;
  travelers: IReservationApiGetAirResultsTraveler[];
  segments: IReservationApiGetAirResultsSegment[];
}

export interface IReservationApiGetAirResultsTraveler {
  travelerType: string;
  numTravelers: number;
}

export interface IReservationApiGetAirResultsSegment {
  departureCode: string;
  arrivalCode: string;
  departureDate: string;
}

export interface IReservationApiBookAirResultParams {
  bookingId: number;
  vesselName?: string;
  sailDate?: string;
  passengerPhone?: string;
  passengers: IReservationApiAirResultPassenger[];
  itinerary: IReservationApiAirResult;
}

export interface IReservationApiAirResultPassenger {
  guestCod: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  travelerType: string;
  passport: IReservationApiAirResultPassengerPassport;
}

export interface IReservationApiAirResultPassengerPassport {
  id: string;
  country: string;
  expDate: string;
}

export interface IReservationApiDeleteAirPnrParams {
  bookingId: number;
  bkgID: number;
  pnr: string;
  recordLocator: string;
  skipApiCall: boolean;
  source: string;
  locator?: string;
  provider?: string;
  brand?: string;
}

export interface IReservationApiRefreshAirResultPnrsParams {
  bookingId: number;
  recordLocators: string[];
  sailDate?: string;
  refreshPrice: 'Y' | 'N';
  refreshType?: string;
}

export interface IReservationApiImportPNRParams {
  bkgId?: string;
  pnr?: string;
  officeId?: string;
}

export interface IReservationApiGetAirPnrHistoryParams {
  bookingId: number;
  recordLocator: string;
}

export class ResAirUtility {
  static DEFAULT_MIN_CONNECTION_TIME = 120;
  static DEFAULT_MAX_CONNECTION_TIME = 360;
  static DEFAULT_MIN_TIME_BEFORE_EMBARK = 300;
  static DEFAULT_MIN_TIME_AFTER_DEBARK = 300;
  static DEFAULT_SORTING = 'price';

  static MAX_SHORT_STOPOVER_MINUTES = 120
  static MIN_LONG_STOPOVER_MINUTES = 360

  static CARRIERS_NEEDING_PASSENGER_PASSPORT = ['MU', 'CZ', 'LA', 'UT', 'XL', 'AT', 'KC']

  /**
   * Get minutes from duration string --> 1d 2h 3m --> 1503 minutes
   *
   * @param duration 1d 2h 3m
   * @returns
   */
  static getMinutesFromDurationString(duration: string): number {
    if (duration.startsWith('-')) {
      return -1 * this.getMinutesFromDurationString(duration.substring(1))
    }
    const data = duration.split(' ')
    const day = Number(data.find(o => o.includes('d'))?.replace('d', '') ?? 0)
    const hour = Number(data.find(o => o.includes('h'))?.replace('h', '') ?? 0)
    const minute = Number(data.find(o => o.includes('m'))?.replace('m', '') ?? 0)
    return (day * 24 * 60) + (hour * 60) + minute
  }

  static parseDateToApi(date: DateTime | Date | undefined): string {
    if (!date) return '';
    if (date instanceof Date) date = DateTime.fromJSDate(date);
    return date.toFormat('yyyy-MM-dd');
  }

  static parseDateToString(date: DateTime | undefined): string {
    if (!date) return '';
    return date.toFormat('dd LLL yyyy HH:mm');
  }
}

export class ReservationAirResult extends SafeBaseObject {
  flightItineraryId:number = 0;
  apiResult: IReservationApiAirResult;
  sourceId: number = 0;
  cabinClass: string = '';
  validatingCarrier: string = '';
  tripType: string = '';
  contractType: string = '';
  contractDescription: string = '';
  fareType: string = '';
  fareDescription: string = '';
  lastTicketDate?: DateTime
  eTicket: boolean = false;
  instantPurchase: boolean = false;
  totalCcPrice: number = 0;
  totalCashPrice: number = 0;
  totalCashPriceCurrency: number = 0;
  totalCashPriceDisplay: number = 0;
  totalCashPriceCurrencyDisplay: number = 0;
  airportChange: boolean = false;
  directionalLabel: string = '';
  currencyCod: string = '';
  fares: ReservationAirResultFare[] = [];
  trips: ReservationAirResultTrip[] = [];

  formattedLastTicketDate: string = '';
  formattedTotalCashPriceCurrencyDisplay: string = '';

  constructor(data: IReservationApiAirResult) {
    super();
    if (data) this.updateData(data);

    this.apiResult = data;
  }
  override updateData(data: IReservationApiAirResult): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('lastTicketDate', data, 'lastTicketDate');

    if (this.lastTicketDate) this.formattedLastTicketDate = this.lastTicketDate.toFormat('dd LLL yyyy');

    this.fares = data.fares.map((fare) => new ReservationAirResultFare(fare));
    this.trips = data.trips.map((trip) => new ReservationAirResultTrip(trip));

    this.trips.forEach((trip) => {
      trip.flights = data.flights.filter((flight) => flight.tripId === trip.tripId).map((flight) => new ReservationAirResultFlight(flight));
      trip.stopovers = data.stopovers.filter((stopover) => stopover.tripId === trip.tripId).map((stopover) => new ReservationAirResultStopover(stopover)).sort((a, b) => a.order - b.order);
    });
  }

  get isUSD(): boolean {
    return this.currencyCod?.toLowerCase() === 'usd';
  }

  get numPax(): number {
    return this.fares[0]?.numPax ?? 0;
  }

  get hasOutboundTransferIncluded(): boolean {
    return !!this.fares[0]?.tfrOutIncluded;
  }

  get hasInboundTransferIncluded(): boolean {
    return !!this.fares[0]?.tfrInIncluded;
  }

  get transferIncludedLabel(): string {
    if (this.hasOutboundTransferIncluded && this.hasInboundTransferIncluded) return 'Outbound & Inbound Airport Transfer Included';
    if (this.hasOutboundTransferIncluded) return 'Outbound Airport Transfer Included';
    if (this.hasInboundTransferIncluded) return 'Inbound Airport Transfer Included';
    return '';
  }
}

export interface IReservationApiAirResult {
  flightItineraryId:number,
  sourceId: number;
  cabinClass: string;
  validatingCarrier: string;
  tripType: string;
  contractType: string;
  contractDescription: string;
  fareType: string;
  fareDescription: string;
  lastTicketDate: string;
  eTicket: boolean;
  instantPurchase: boolean;
  totalCcPrice: number;
  totalCashPrice: number;
  totalCashPriceCurrency: number;
  totalCashPriceDisplay: number;
  totalCashPriceCurrencyDisplay: number;
  airportChange: boolean;
  directionalLabel: string;
  currencyCod: string;
  fares: IReservationApiAirResultFare[];
  flights: IReservationApiAirResultFlight[];
  trips: IReservationApiAirResultTrip[];
  stopovers: IReservationApiAirResultStopover[];
}

export class ReservationAirResultTrip extends SafeBaseObject {
  tripId: number = 0;
  tripTime: number = 0;
  duration: string = '';
  flights: ReservationAirResultFlight[] = [];
  stopovers: ReservationAirResultStopover[] = [];

  constructor(data?: IReservationApiAirResultTrip) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiAirResultTrip): void {
    this.addMangledProperty(data);
  }
  get seatNumber() {
    return this.flights.at(0)?.seatNumber ?? '';
  }
  get airLineRecordLocator() {
    return this.flights.at(0)?.airLineRecordLocator ?? '';
  }
  get numStopovers(): number {
    return this.stopovers.length;
  }
  get departureCity(): string {
    return this.flights.at(0)?.departureCity ?? '';
  }
  get departureAirport(): string {
    return this.flights.at(0)?.departureAirport ?? '';
  }
  get departureAirportDate(): DateTime {
    return this.flights.at(0)?.departureAirportDate ?? DateTime.min();
  }
  get formattedDepartureAirportDate(): string {
    return this.flights.at(0)?.formattedDepartureAirportDate ?? '';
  }

  get arrivalCity(): string {
    return this.flights.at(-1)?.arrivalCity ?? '';
  }
  get arrivalAirport(): string {
    return this.flights.at(-1)?.arrivalAirport ?? '';
  }
  get arrivalAirportDate(): DateTime {
    return this.flights.at(-1)?.arrivalAirportDate ?? DateTime.min();
  }
  get formattedArrivalAirportDate(): string {
    return this.flights.at(-1)?.formattedArrivalAirportDate ?? '';
  }

  get durationMinutes(): DateTime {
    const data = this.duration.split(' ')
    const day = Number(data.find(o => o.includes('d'))?.replace('d', '') ?? 0) + 1
    const hour = Number(data.find(o => o.includes('h'))?.replace('h', '') ?? 0)
    const minute = Number(data.find(o => o.includes('m'))?.replace('m', '') ?? 0)
    return DateTime.fromObject({ day, hour, minute })
  }

  get daysDifferenceArrivalDeparture(): number {
    return this.arrivalAirportDate?.startOf('day').diff(this.departureAirportDate?.startOf('day'), 'days').days
  }

  isInbound(section: BookingSection): boolean {
    return this.departureAirportDate && !!section.embarkDate?.isValid && this.departureAirportDate.set({ hour: 0, minute: 0, second: 0 }) > section.embarkDate
  }

  isOutbound(section: BookingSection): boolean {
    return !this.isInbound(section)
  }

  getCruiseTimeDifference(section: BookingSection): string {
    let firstDate, secondDate
    if (this.isInbound(section)) {
      if (!section.debarkDateTime?.isValid && !section.debarkDate?.isValid) return '0m'
      firstDate = this.departureAirportDate!
      secondDate = section.debarkDateTime?.isValid ? section.debarkDateTime : section.debarkDate!.set({ hour: 0, minute: 0, second: 0 })
    } else {
      if (!section.embarkToDateTime?.isValid && !section.embarkDate?.isValid) return '0m'
      firstDate = section.embarkToDateTime?.isValid ? section.embarkToDateTime : section.embarkDate!.set({ hour: 0, minute: 0, second: 0 })
      secondDate = this.arrivalAirportDate!
    }

    const diffDate = firstDate && secondDate ? firstDate.diff(secondDate, ['days', 'hours', 'minutes']).toObject() : { days: 0, hours: 0, minutes: 0 };

    let parsedDiffDate = `${diffDate.days ? diffDate.days + 'd ' : ''}${diffDate.hours ? diffDate.hours + 'h ' : ''}${diffDate.minutes ? diffDate.minutes + 'm ' : ''}`

    if (parsedDiffDate.includes('-')) {
      // @ts-ignore
      parsedDiffDate = '-' + parsedDiffDate.replaceAll('-', '')
    }

    return parsedDiffDate.trim()
  }

  displayCruiseTimeDifference(section: BookingSection): string {
    return this.getCruiseTimeDifference(section).replace('-', '')
  }

  getCruiseTimeDifferenceMinutes(section: BookingSection): number {
    return ResAirUtility.getMinutesFromDurationString(this.getCruiseTimeDifference(section))
  }

  isCruiseTimeDifferenceNegative(section: BookingSection): boolean {
    return this.getCruiseTimeDifference(section).startsWith('-')
  }
}

export interface IReservationApiAirResultTrip {
  tripId: number;
  tripTime: number;
  duration: string;
}

export class ReservationAirResultFare extends SafeBaseObject {
  id: number = 0;
  travelerType: string = '';
  carrierType: string = '';
  fareType: string = '';
  fareDescription: string = '';
  ccPricePerPax: number = 0;
  cashPricePerPax: number = 0;
  cashPricePerPaxDisplay: number = 0;
  cashPricePerPaxCurrency: number = 0;
  cashPricePerPaxCurrencyDisplay: number = 0;
  numPax: number = 0;
  tfrInIncluded: boolean = false;
  tfrOutIncluded: boolean = false;

  constructor(data?: IReservationApiAirResultFare) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IReservationApiAirResultFare): void {
    this.addMangledProperty(data);
  }
}

export interface IReservationApiAirResultFare {
  id: number;
  travelerType: string;
  carrierType: string;
  fareType: string;
  fareDescription: string;
  ccPricePerPax: number;
  cashPricePerPax: number;
  cashPricePerPaxDisplay: number;
  cashPricePerPaxCurrency: number;
  cashPricePerPaxCurrencyDisplay: number;
  numPax: number;
  tfrInIncluded: boolean;
  tfrOutIncluded: boolean;
}

export class ReservationAirResultFlight extends SafeBaseObject {
  tripId: number = 0;
  carrierCode: string = '';
  carrierName: string = '';
  flightNumber: string = '';
  bookingClass: string = '';
  departureAirportDate?: DateTime
  departureUtcDate?: DateTime
  departureAirport: string = '';
  departureCity: string = '';
  arrivalAirportDate?: DateTime
  arrivalUtcDate?: DateTime
  arrivalAirport: string = '';
  arrivalCity: string = '';
  arrivalTerminal: string = '';
  aircraftType: string = '';
  seatNumber = ''
  airLineRecordLocator = ''
  numStops: number = 0;
  duration: string = '';
  codeShareInfo: string = '';
  airClass: string = '';

  formattedDepartureAirportDate: string = '';
  formattedDepartureUtcDate: string = '';
  formattedArrivalAirportDate: string = '';
  formattedArrivalUtcDate: string = '';

  //FE logic
  isStopover: boolean = false;
  isLongStopover: boolean = false;
  isShortStopover: boolean = false;

  constructor(data?: IReservationApiAirResultFlight) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiAirResultFlight): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('departureAirportDate', data, 'departureAirportDate');
    this.addDateTimeProperty('departureUtcDate', data, 'departureUtcDate');
    this.addDateTimeProperty('arrivalAirportDate', data, 'arrivalAirportDate');
    this.addDateTimeProperty('arrivalUtcDate', data, 'arrivalUtcDate');

    if (this.departureAirportDate) this.formattedDepartureAirportDate = ResAirUtility.parseDateToString(this.departureAirportDate);

    if (this.departureUtcDate) this.formattedDepartureUtcDate = ResAirUtility.parseDateToString(this.departureUtcDate);

    if (this.arrivalAirportDate) this.formattedArrivalAirportDate = ResAirUtility.parseDateToString(this.arrivalAirportDate);

    if (this.arrivalUtcDate) this.formattedArrivalUtcDate = ResAirUtility.parseDateToString(this.arrivalUtcDate);
  }

  get durationMinutes(): number {
    return ResAirUtility.getMinutesFromDurationString(this.duration);
  }
}

export interface IReservationApiAirResultFlight {
  tripId: number;
  carrierCode: string;
  carrierName: string;
  flightNumber: string;
  bookingClass: string;
  departureAirportDate: string;
  departureUtcDate: string;
  departureAirport: string;
  departureCity: string;
  arrivalAirportDate: string;
  arrivalUtcDate: string;
  arrivalAirport: string;
  arrivalCity: string;
  arrivalTerminal: string;
  aircraftType: string;
  seatNumber: string;
  airLineRecordLocator: string;
  numStops: number;
  duration: string;
  codeShareInfo: string;
  AirClass: string;
}

export class ReservationAirResultStopover extends SafeBaseObject {
  order: number = 0;
  tripId: number = 0;
  duration: string = '';

  constructor(data?: IReservationApiAirResultStopover) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiAirResultStopover): void {
    this.addMangledProperty(data);
  }

  get durationMinutes(): number {
    return ResAirUtility.getMinutesFromDurationString(this.duration);
  }

  get isLongTerm(): boolean {
    return this.durationMinutes >= ResAirUtility.MIN_LONG_STOPOVER_MINUTES;
  }

  get isShortTerm(): boolean {
    return this.durationMinutes <= ResAirUtility.MAX_SHORT_STOPOVER_MINUTES;
  }
}

export interface IReservationApiAirResultStopover {
  order: number;
  tripId: number;
  duration: string;
}

export class ReservationAirResultTravelAgent extends SafeBaseObject {
  userName: string = '';
  firstName: string = '';
  lastName: string = '';
  address: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  email: string = '';
  phone: string = '';
  iata: string = '';
  agency: string = '';

  constructor(data?: IReservationApiAirResultTravelAgent) {
    super();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiAirResultTravelAgent): void {
    this.addMangledProperty(data);
  }
}

export interface IReservationApiAirResultTravelAgent {
  userName: string;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  iata: string;
  agency: string;
}

export class ReservationAirPnr extends SafeBaseObject {
  id: string = '';
  flightHeaderId: number = 0;
  recordLocator: string = '';
  recordLocatorLink: string = '';
  status: string = '';
  updated?: boolean
  departureDate?: DateTime
  dateReserved?: DateTime
  travelAgent?: ReservationAirResultTravelAgent
  itinerary?: ReservationAirResult
  itineraries?: ReservationAirResult[]
  passengers?: IReservationApiAirResultPassenger[]
  rules?: string
  canBeDeleted: boolean = false;
  fileID=0;
  fileGUID='';
  fileName='';
  skipApiCall: boolean = false;
  source = ''
  brand = ''

  rightRezRecordLocatorUrl=''
  constructor(data?: IReservationApiAirPnr) {
    super();
    const config = AppModule.injector.get(EidosConfigService);
    const cfg = config.currentModulesConfig.getValue().find(m => m.moduleName === CoreModuleName.Reservation);
    this.rightRezRecordLocatorUrl = cfg?.crossReferenceURL?.rightRezRecordLocator ?? 'https://preprodflights.rightrez.com/pnr?locator={recordLocator}&brand={brand}'
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiAirPnr): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('departureDate', data, 'departureDate');
    this.addDateTimeProperty('dateReserved', data, 'dateReserved');

    if (data.travelAgent) this.travelAgent = new ReservationAirResultTravelAgent(data.travelAgent);
    if (data.itinerary) this.itinerary = new ReservationAirResult(data.itinerary);
    if (data.itineraries) this.itineraries = data.itineraries.map(i=>new ReservationAirResult(i));
    if (data.passengers) this.passengers = data.passengers
    if(this.source==='RIGHTREZ') {
      this.recordLocatorLink=this.rightRezRecordLocatorUrl.replace('{recordLocator}',this.recordLocator).replace('{brand}',this.brand);
    }
  }

  get isReserved(): boolean {
    return this.status.toLowerCase() === 'reserved';
  }

  get isTicketed(): boolean {
    return this.status.toLowerCase() === 'ticketed';
  }

  get isCancelled(): boolean {
    return this.status.toLowerCase() === 'cancelled';
  }

  hasInboundSegment(section: BookingSection): boolean {
    return !!this.itinerary?.trips?.some((trip) => trip.isInbound(section));
  }

  hasOutboundSegment(section: BookingSection): boolean {
    return !!this.itinerary?.trips?.some((trip) => trip.isOutbound(section));
  }
}

export interface IReservationApiAirPnr {
  id: number;
  recordLocator: string;
  status: string;
  departureDate?: string;
  dateReserved?: string;
  travelAgent?: IReservationApiAirResultTravelAgent;
  itinerary?: IReservationApiAirResult;
  itineraries?: IReservationApiAirResult[];
  passengers?: IReservationApiAirResultPassenger[];
  rules?: string
  flightHeaderId?:number;
  fileID?:number;
  fileGUID?: string;
  fileName?: string;
  skipApiCall?: string;
}

export class ReservationAirPnrHistory extends ReservationAirPnr {
  requestID: number = 0;
  creationDate: DateTime = DateTime.local();
  updateDate: DateTime = DateTime.local();
  loadingDate: DateTime = DateTime.local();
  versionStatus: string = '';

  formattedCreationDate: string = '';
  formattedLoadingDate: string = '';

  constructor(data?: IReservationApiAirPnrHistory) {
    super(data);
    if (data) {
      super.updateData(data);
      this.addDateTimeProperty('creationDate', data, 'creationDate');
      this.addDateTimeProperty('updateDate', data, 'updateDate');
      this.addDateTimeProperty('loadingDate', data, 'loadingDate');

      if (this.creationDate) this.formattedCreationDate = ResAirUtility.parseDateToString(this.creationDate);
      if (this.loadingDate) this.formattedLoadingDate = ResAirUtility.parseDateToString(this.loadingDate);
      if(!this.itineraries?.length && this.itinerary) this.itineraries = [this.itinerary]
    }
  }

}

export interface IReservationApiAirPnrHistory extends IReservationApiAirPnr {
  creationDate: string;
  updateDate: string;
  versionStatus: string;
}

export interface IReservationAirSegment {
  airportFrom?: string;
  airportTo?: string;
  departureDate?: DateTime;
  isInbound: boolean;
}

export interface IReservationApiChangePnrAirClass {
  bkgID: number;
  recordLocator: string;
  flightItineraryId: number;
  airClass: string;
}

export class ResBkgAirDetail extends SafeBaseObject {
  [key: string]: any;

  constructor(data: IReservationApiBkgAirDetail) {
    super();
    if (data) this.updateData(data);
  }
}

export interface IReservationApiBkgAirDetail {
  [key: string]: any;
}

export class ReservationApiRightRezPassengersPhone {
  Number = ''
  PhoneNumberType = 'Home'
}
export class ReservationApiRightRezPassengers {
  BookingNumber = ''
  DateOfBirth? = DateTime.now().startOf('day').toISO()
  Email = ''
  FirstName = ''
  LastName = ''
  MiddleName = ''
  Gender = ''
  Title = ''
  PhoneNumber?:ReservationApiRightRezPassengersPhone
}
export class ReservationApiRightRezFlightServiceLevelSettings {
  ServiceLevelPreferLevel=''
  ServiceLevel = 'Economy'
}
export class ReservationApiRightRezSchedule {
  ArriveAfter='0001-01-01T00:00:00'
  ArriveBefore='0001-01-01T00:00:00'
  ArriveDateTime:string|undefined
  DepartAfter='0001-01-01T00:00:00'
  DepartBefore='0001-01-01T00:00:00'
  DepartureDateTime:string|undefined
}
export class ReservationApiRightRezFlightComponentRequirements {
  ComponentType = ''
  Program = ''
  Direction = ''
  LocationCodeArrival = ''
  LocationCodeDeparture = ''
  FlightServiceLevelSettings: ReservationApiRightRezFlightServiceLevelSettings = new ReservationApiRightRezFlightServiceLevelSettings()
  Schedule: ReservationApiRightRezSchedule = new ReservationApiRightRezSchedule()
  stopover?: {
    stopoverDateTime: string
    locationCode: string
  }
}
export class ReservationApiRightRezParamsBookingOptions {
  Passengers: ReservationApiRightRezPassengers[] = []
  simulateBooking = true
}
export class ReservationApiRightRezParamsShoppingOptions {
  Brand = ''
  FareType = ''
  FlightComponentRequirements:ReservationApiRightRezFlightComponentRequirements[] = []
  NumberOfPassengers = 0
}
export class ReservationApiRightRezParams {
  Mode = ''
  BkgID = ''
  StartWithSearchPage = false
  IntegrationType: 'Standard' | 'RedirectAfterBooking' | 'RedirectAfterSelection' = 'RedirectAfterBooking'
  RedirectUrl = ''
  CancelShoppingRedirectUrl = ''

  BookingOptions: ReservationApiRightRezParamsBookingOptions = new ReservationApiRightRezParamsBookingOptions()
  ShoppingOptions: ReservationApiRightRezParamsShoppingOptions = new ReservationApiRightRezParamsShoppingOptions()

  static createPayload(section:BookingSection,guests:BookingGuest[],segments:IReservationAirSegment[], otherData:any, mode:string = 'blank', simulated = false):ReservationApiRightRezParams {
    // const config = AppModule.injector.get(EidosConfigService);
    // const cfg = config.currentModulesConfig.getValue().find(m => m.moduleName === CoreModule.Reservation);
    // const url = cfg?.crossReferenceURL?.RightRez ?? `${config.currentConfig.getValue().baseUrl}/reservation/booking`
    console.log('res-air.model::createPayload - otherData', otherData)
    const payload = new ReservationApiRightRezParams()

    payload.Mode = `${mode}`
    payload.BkgID = `${section.bkgID}`
    payload.IntegrationType = 'RedirectAfterBooking'
    payload.BookingOptions.simulateBooking=simulated
    // payload.RedirectUrl = `${url}/${section.bkgID}?air=RR&status=PNR`
    // payload.CancelShoppingRedirectUrl = `${url}/${section.bkgID}?air=RR&status=cancel`

    guests.forEach(g=>{
      const pax = new ReservationApiRightRezPassengers()
      pax.LastName = g.lastName
      pax.MiddleName = g.middleName
      pax.FirstName = g.firstName
      pax.Title = g.title
      pax.Gender = g.gender
      pax.Email = g.email
      if(g.phone) {
        pax.PhoneNumber = new ReservationApiRightRezPassengersPhone()
        pax.PhoneNumber.Number = `${g.phonePrefix_ISO2} ${g.phone}`.trim()
      }
      pax.BookingNumber =  `${section.bkgID}`
      pax.DateOfBirth = g.birthDate && g.birthDate.isValid ? g.birthDate.toFormat('yyyy-LL-dd')+'T00:00:00' : undefined
      payload.BookingOptions.Passengers.push(pax)
    })

    payload.ShoppingOptions.NumberOfPassengers = guests.length
    payload.ShoppingOptions.Brand = 'CrystalCruises'
    payload.ShoppingOptions.FareType = 'Net'

    //.sort((a,b)=>(!a.departureDate || !b.departureDate) ? 0 : a.departureDate.diff(b.departureDate,'hours').hours)

    interface GroupedSegments {
        inbound: IReservationAirSegment[];
        outbound: IReservationAirSegment[];
    }

    const groupedSegments = segments.reduce<GroupedSegments>((acc, segment) => {
      if (segment.isInbound) {
          acc.inbound.push(segment);
      } else {
          acc.outbound.push(segment);
      }
      return acc;
    }, { inbound: [], outbound: [] });

    const processFlightSegments = function (segments: IReservationAirSegment[], direction: string, section: BookingSection, otherData: any, payload: any, dateKey: 'startDate' | 'endDate') {
      if (segments.length === 0) {
          return;
      }

      const fcr = new ReservationApiRightRezFlightComponentRequirements();
      fcr.ComponentType = 'Ship';
      fcr.Program = section.bkgAllSupplements.voyages[0].voyageNumber;

      fcr.LocationCodeDeparture = segments[0].airportFrom!;
      fcr.LocationCodeArrival = segments[segments.length - 1].airportTo!;
      fcr.FlightServiceLevelSettings.ServiceLevelPreferLevel = 'Only';

      let serviceLevel = 'Economy';
      switch ((otherData.airClass ?? '').toLowerCase()) {
          case 'first':
              serviceLevel = 'First';
              break;
          case 'business':
              serviceLevel = 'Business';
              break;
          case 'premium coach':
          case 'economy+':
              serviceLevel = 'Premium';
              break;
          default:
              serviceLevel = 'Economy';
              break;
      }
      fcr.FlightServiceLevelSettings.ServiceLevel = serviceLevel;
      fcr.Direction = direction;

      const dt0 = (segments[0].departureDate ??  (section[dateKey] as DateTime)).startOf('day')


      const dateField = direction === 'Outbound' ? 'ArriveDateTime' : 'DepartureDateTime';
      fcr.Schedule[dateField] = dt0.toISO().substring(0,19)

      if (segments.length > 1 && segments[1].departureDate) {

        const dt1 = segments[1].departureDate.startOf('day')
        if(direction === 'Return') dt1.startOf('day').plus({ days: 1 })

          fcr.stopover = {
              stopoverDateTime: dt0.toISO().substring(0,19),
              locationCode: segments[0].airportTo!
          };
          fcr.Schedule[dateField] = dt1.toISO().substring(0,19)
      }

      payload.ShoppingOptions.FlightComponentRequirements.push(fcr);
  }

    // Process outbound segments
    processFlightSegments(groupedSegments.outbound, 'Outbound', section, otherData, payload, 'startDate');

    // Process inbound segments
    processFlightSegments(groupedSegments.inbound, 'Return', section, otherData, payload, 'endDate');

    return payload
  }
}
export class ReservationApiRightRezResponse extends SafeBaseObject {
  rightRezUrl = ''
  errorMessage = ''
  hasError = false
  constructor(data: any) {
    super();
    if (data) {
      super.updateData(data);
      this.updateData(data)
    }
  }
  updateData(data:any) {
    if(!data) return

    if(data.error && data.error.FoundErrors) {
      this.hasError = true
      this.errorMessage = data.error.ErrorMessage ?? 'RightRez response error'
    }
  }
}
