import { Component, Input } from '@angular/core';
import { IEidosInputNumberPopupOption, IEidosInputNumberPopupValue } from '@common/models/eidos-inputs.models';
import { EidosInputBaseComponent } from '../eidos-input-base.component';

@Component({
  selector: 'eidos-input-number-popup',
  templateUrl: './eidos-input-number-popup.component.html',
  styleUrls: ['./eidos-input-number-popup.component.scss']
})
export class EidosInputNumberPopupComponent extends EidosInputBaseComponent<IEidosInputNumberPopupValue> {
  /**
   * Input label
   *
   * @type {string}
   * @memberof EidosInputNumberPopupComponent
   */
  @Input() public label?: string;
  /**
   * Max value
   *
   * @type {(number | string)}
   * @memberof EidosInputNumberPopupComponent
   */
  @Input() public max?: number;
  /**
   * Min value
   *
   * @type {(number | string)}
   * @memberof EidosInputNumberPopupComponent
   */
  @Input() public min?: number;
  /**
   * Number inpot elements
   *
   * @type {Array<IEidosInputNumberPopupOption>}
   * @memberof EidosInputNumberPopupComponent
   */
  @Input() public elements: Array<IEidosInputNumberPopupOption> = [];

  public prevDateButton = {
    icon: 'spindown',
    stylingMode: 'text'
  };
  /**
   * Keys of input value reflected obj
   *
   * @readonly
   * @memberof EidosInputNumberPopupComponent
   */
  public get valueKeys() {
    return !!this.value ? Object.keys(this.value) : [];
  }
  /**
   * Label of input value reflected obj with key
   *
   * @param {string} key
   * @return {*}
   * @memberof EidosInputNumberPopupComponent
   */
  public getValueLabel(key: string) {
    const prop = this.elements.find(item => item.property === key);
    return prop ? prop.label : key;
  }
  /**
   * Is popup visible flag
   *
   * @type {boolean}
   * @memberof EidosInputNumberPopupComponent
   */
  public isPopupTooltipVisible: boolean = false;
  public focusOnPopUp=false
  /**
   * Gets total value of all input elements label
   *
   * @readonly
   * @private
   * @memberof EidosInputNumberPopupComponent
   */
  private get labelFormat() {
    return this.placeholder ?? this.label;
  }

  constructor() {
    super();
  }
  onFocusOutText() {
    if(this.onFocusOut) this.onFocusOut();
    setTimeout(()=>{
      this.isPopupTooltipVisible = this.focusOnPopUp;
    })
  }
  ngOnInit(): void {

    super.ngOnInit();

    if (!this.cssId) {
      this.cssId = "eidos-input-number-popup-" + Math.random().toString(36).substring(2, 9);
    }
  }
  /**
   * Get text box input message
   *
   * @return {*}  {string}
   * @memberof EidosInputNumberPopupComponent
   */
  public getFormat(): string {
    let tot = 0;
    let value = this.labelFormat ? `${tot} ${this.labelFormat}` : "";
    if (this.elements && !!this.value) {
      const labels = this.elements.map(item => {
        tot += this.value[item.property];
        return `${this.value[item.property]} ${item.label}`;
      });

      value = `${(this.labelFormat ? `${tot} ${this.labelFormat}` : "")} - ${labels.join(" - ")}`;
    }
    return value;
  }
  protected focusOnPopup=false;
  /**
   * Handle clear value button click
   *
   * @param {number} value
   * @param {IEidosInputNumberPopupOption} element
   * @memberof EidosInputNumberPopupComponent
   */
  public isInputValueChanged(value: number, element: IEidosInputNumberPopupOption) {
    if (!value) {
      this.resetValuePropertyToMinOrDefaultValue(element);
    }

    // When a new value is selected for an element, the max value of all elements
    // is re-evaluated based on "Component" max value
    if (this.max) {
      this.elements.forEach(el => {
        const otherElementValues = this.elements.reduce((accumulator, innerEl) => {
          if (innerEl.property === el.property) {
            return accumulator;
          } else {
            return accumulator + this.value[innerEl.property];
          }
        }, 0);
        el.max = (this.max ?? 0) - otherElementValues;
      });
    }

    this.onSelfChangeValue(this.value);
  }
  /**
   * Resets the property in the input value reflected obj to its default/min
   *
   * @private
   * @param {IEidosInputNumberPopupOption} element
   * @param {IEidosInputNumberPopupValue} [value]
   * @param {boolean} [toDefault]
   * @memberof EidosInputNumberPopupComponent
   */
  private resetValuePropertyToMinOrDefaultValue(element: IEidosInputNumberPopupOption, value?: IEidosInputNumberPopupValue, toDefault?: boolean) {
    const values = value ?? this.value;
    if (toDefault && element.default !== undefined) {
      values[element.property] = element.default;
    }
    else {
      values[element.property] = element.min ?? 0;
    }
  }

  public override eventuallySetupInternalValue(val: any): void {
    super.eventuallySetupInternalValue(val);
    this.setupInternalValue();
  }

  private setupInternalValue(): void {
    if (!this.value) {
      let value: IEidosInputNumberPopupValue = {};
      for (const el of this.elements) {
        this.resetValuePropertyToMinOrDefaultValue(el, value, true);
      }
      this.value = { ...value };
    } else {
      Object.keys(this.value).forEach(key => {
        const min = this.elements.find(item => item.property === key)?.min ?? this.min;
        if (min !== undefined && this.value[key] < min) {
          this.value[key] = min;
        }
        const max = this.elements.find(item => item.property === key)?.max ?? this.max;
        if (max !== undefined && this.value[key] > max) {
          this.value[key] = max;
        }
      });
    }
  }
}
