import { IEidosIcon } from "../config/environment.interface";
import { BaseObject } from "./base-object.models";
import { EidosIconType } from "./core-constant.model";

export enum EidosMenuItemType {
  static = 'statico',
  url = 'url',
  urlEidos = 'url-eidos',
}

export class EidosMenuItem {
  id: number;
  label: string;
  parent: number | null;
  order: number;
  type: EidosMenuItemType;
  objectId: number | null;
  options: string | null;
  url: string | null;
  icon: any
  children: Array<EidosMenuItem>;
  /** App associata alla voce di menu (per app esterne) */
  source?: string;
  /** Abilitazione della voce di menu (per app esterne) */
  disabled?: boolean;
  /** Path a cui navigare (per app esterne) */
  action?: string;
  /** Colore della voce di menu (per app esterne) */
  color?: string;
  module?: string;
  isEidosObject: boolean;

  constructor(item?: any) {
    this.id = 0;
    this.label = '';
    this.parent = null;
    this.order = 0;
    this.objectId = null;
    this.options = null;
    this.type = EidosMenuItemType.static;
    this.url = null;
    this.icon = null;
    this.children = [];
    this.disabled = false;
    this.isEidosObject = false;

    if (item) {
      this.id = item.id;
      this.label = item.label;
      this.parent = +item.genitore;
      this.order = +item.ordine;
      this.objectId = +item.object_id || item.objectId;
      this.options = item.options || null;
      this.isEidosObject = item.isEidosObject;
      this.url = item.url;
      this.module = item.Module;

      if (item.icon) {
        this.icon = {
          iconCode: item.icon.iconCode,
          iconType: item.icon.iconType,
          iconSize: 1,
          inverseColor: false
        };
      }
      else if (item.immagine_cod) {
        this.icon = {
          iconType: item.icon_type || EidosIconType.MyBiz,
          iconCode: item.icon_value || item.immagine_cod,
          iconSize: 1,
          inverseColor: false
        };
        //skip for now on DB there are fake data
        if (!item.icon_type || item.icon_type === EidosIconType.MyBiz) {
          this.icon = null;
        }
      }

      this.type = this.getType(item.tipo);
      this.url = item.url;
      item.figli?.forEach((item: any) => this.children.push(new EidosMenuItem(item)));

      this.disabled = item.disabled ?? false;
      this.source = item.source;
      this.action = item.action;
      this.color = item.color ?? "white";
    }
  }

  getSmallIcon(): IEidosIcon | null {
    if (!this.icon) return null;
    let icon = { ...this.icon };
    icon.iconSize = 1;
    return icon;
  }

  hasChild(id: number): boolean {
    return this.id === id || this.children.filter(child => child.hasChild(id)).length > 0;
  }

  getType(type: string): EidosMenuItemType {
    switch (type?.toLowerCase()) {
      case 'url':
        return EidosMenuItemType.url;
      default:
        return EidosMenuItemType.static;
    }
  }
  getBreadCrumbs(): string {
    //TODO get menu path from id
    return '';
  }

  getRoute(externalAppsRoot: string): Array<string> {
    if (this.action) {
      return [externalAppsRoot, this.action];
    } else if (this.objectId) {
      const urlPart = [externalAppsRoot, 'object', this.objectId.toString()];
      if (this.options) urlPart.push(this.options)
      return urlPart;
    }

    return [externalAppsRoot];
  }
}

export class EidosMenu extends BaseObject {
  menuItems: Array<EidosMenuItem> = [];

  constructor(initdata?: any) {
    super(initdata);
    if (initdata) this.updateData(initdata);
  }

  updateData(data: any) {
    this.menuItems = [];
    if(!Array.isArray(data)) return
    data.forEach((item: any) => this.menuItems.push(new EidosMenuItem(item)));
  }

  walk(): Array<EidosMenuItem> {
    function walkItem(items: Array<EidosMenuItem>): Array<EidosMenuItem> {
      let acc: Array<EidosMenuItem> = [];
      items.forEach(item => {
        acc = acc.concat(item).concat(walkItem(item.children));
      });
      return acc;
    }
    return walkItem(this.menuItems);
    //return this.menuItems.reduce((accumulator, item) => accumulator.concat(walkItem(item.children)), this.menuItems);
  }
  getItems(text?: string): Array<EidosMenuItem> {
    if (!text || text.trim() === '') return this.menuItems;
    let normalizedText: string = text?.trim().toLowerCase();

    return this.walk()
      .filter((menuItem) => {
        return menuItem.label.toLowerCase().indexOf(normalizedText) !== -1;
      });
  }
  getItem(objectId: number): EidosMenuItem {
    if (objectId === 0) return new EidosMenuItem();

    return this.walk()
      .filter((menuItem) => {
        return menuItem.objectId === objectId;
      }).shift() || new EidosMenuItem();
  }
}
