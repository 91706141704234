import { Injectable } from '@angular/core';
import { EidosLogSeverity } from '@common/models/core-constant.model';

@Injectable({
  providedIn: 'root'
})
export class EidosLogService {
  /**
   * Last logged error
   *
   * @type {*}
   * @memberof EidosLogService
   */
  lastLoggedError: any;
  /**
   * Debug mode flag
   *
   * @type {boolean}
   * @memberof EidosLogService
   */
  private _inDebugMode: boolean = false;

  set inDebugMode(value: boolean) {
    this._inDebugMode = value;
  }

  get inDebugMode(): boolean {
    return this._inDebugMode;
  }

  constructor() {
  }
  /**
   * Log a message to console if in debug mode
   *
   * @param {EidosLogSeverity} [severity=EidosLogSeverity.Log]
   * @param {...any[]} params
   * @memberof EidosLogService
   */
  public logDebug(severity: EidosLogSeverity = EidosLogSeverity.Log, ...params: any[]): void {
    if (this._inDebugMode) {
      switch (severity) {
        case EidosLogSeverity.Warning:
          console.warn("[EIDOS DEBUG]", ...params, "\r\n", new Error().stack?.substring(6).split("    at ")[2]);
          break;
        case EidosLogSeverity.Error:
          console.error("[EIDOS DEBUG]", ...params, "\r\n", new Error().stack?.substring(6).split("    at ")[2]);
          break;
        case EidosLogSeverity.Log:
        default:
          console.log("[EIDOS DEBUG]", ...params, "\r\n", new Error().stack?.substring(6).split("    at ")[2]);
          break;
      }
    }
  }
}
