import { Component, OnInit } from '@angular/core';
import { EidosConfigService } from '@common/config/eidos-config.service';

import { MyBizFilterType } from '@common/models/mybiz.model';
import { EidosFieldBaseComponent } from '@eidos/components/fields/eidos-field-base/eidos-field-base.component';

@Component({
  selector: 'eidos-field-file',
  templateUrl: './eidos-field-file.component.html',
  styleUrls: ['./eidos-field-file.component.scss']
})
export class EidosFieldFileComponent extends EidosFieldBaseComponent implements OnInit {

  private apiUrl = '';
  private referrerString: string;
  private params: string;
  endpointFile = '/data/file/';
  constructor(
    private eidosConfigService: EidosConfigService
  ) {
    super();
    this.referrerString = '';
    this.params = '';
    this.eidosConfigService.currentConfig.subscribe(config => {
      this.apiUrl = config.urlAPI;
    });
  }
  getUrl(urlType: string): string {
    if (!this.fieldConfig || !this.fieldContainer) return '';
    return this.apiUrl + this.endpointFile + this.fieldContainer.objectId + '/' + urlType + this.referrerString + this.params;
  }

  getUrlView(): string {
    return this.getUrl('view');
  }
  getUrlPreview(): string {
    return this.getUrl('preview');
  }
  ngOnInit(): void {
    if (!this.fieldConfig || !this.fieldContainer) return;

    this.referrerString = '';
    const searchObject = {
      object_id: this.fieldContainer.objectId,
      nome_campo_file: this.fieldConfig.name,
      chiavi_record: Array<any>(),
      docviewer_object_id: null //this.fieldConfig.dmsId
    };
    const data = this.fieldData;
    this.fieldContainer.primaryKeys.map(function (key) {
      searchObject.chiavi_record.push({
        field_name: key.name,
        filter_type: MyBizFilterType.Equal,
        value: data[key.name],
        value2: null,
        is_or_filter: false
      });
    });

    const id = JSON.stringify(searchObject)
    this.params = '?file_request=' + encodeURI(btoa(id));
  }
}
