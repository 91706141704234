import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoreSearchResultsViewConfig } from '@app/core/models/eidos-search.model';
import { EidosBaseComponent } from '../../eidos-base.component';
import { CoreSearchService } from '../core-search.service';

@Component({
  selector: 'eidos-search-paginator',
  templateUrl: './eidos-search-paginator.component.html',
  styleUrls: ['./eidos-search-paginator.component.scss']
})
export class EidosSearchPaginatorComponent extends EidosBaseComponent {
  /**
   * Search component service
   *
   * @type {CoreSearchService}
   * @memberof EidosSearchTabComponent
   */
  @Input() coreSearchService?: CoreSearchService;
  /**
   * Next 0..4 pages indexes
   *
   * @readonly
   * @type {Array<number>}
   * @memberof EidosSearchPaginatorComponent
   */
  public get nextPagesIndexes(): Array<number> {
    return Array.from(
      { length: this.pageTotal - this.resultsPageIndex },
      (_, i) => (i + 1 + this.resultsPageIndex)
    )
      .filter(i => i > 0)
      .slice(0, this.resultsPageIndex == 1 ? 4 : 3);
  }

  get loading(): boolean {
    return this.coreSearchService?.loading ?? false;
  }

  get pageTotal(): number {
    return this.coreSearchService?.currentPaging.pageTotal ?? 1;
  }

  get recordsCount(): number {
    return this.coreSearchService?.currentPaging.recordsCount ?? 1;
  }

  get resultsPageIndex(): number {
    return this.coreSearchService?.currentPaging.pageIndex ?? 1;
  }
  set resultsPageIndex(val: number) {

    const pagingInfo = this.coreSearchService?.currentPaging;
    
    if (pagingInfo && this.coreSearchService && val !== this.coreSearchService?.currentPaging.pageIndex) {
      this.coreSearchService.currentPaging.pageIndex = val;
      this.coreSearchService.searchCommand$.next({});
    }
  }
  /**
   * Current results view config
   *
   * @private
   * @type {CoreSearchResultsViewConfig}
   * @memberof EidosSearchPaginatorComponent
   */
  private _currentResultsView: CoreSearchResultsViewConfig = { id: "rows", icon: "detailslayout" }
  @Input() public get currentResultsView(): CoreSearchResultsViewConfig {
    return this._currentResultsView;
  }
  public set currentResultsView(val: CoreSearchResultsViewConfig) {
    this._currentResultsView = val;
    this.currentResultsViewChange.emit(this._currentResultsView);
  }
  @Output() public currentResultsViewChange: EventEmitter<CoreSearchResultsViewConfig> = new EventEmitter<CoreSearchResultsViewConfig>();
  /**
   * Results views
   *
   * @private
   * @type {Array<{icon: string}>}
   * @memberof EidosSearchPaginatorComponent
   */
  public viewTabs: Array<{ icon: string }> = [];
  /**
   * Results views from config
   *
   * @type {Array<CoreSearchResultsViewConfig>}
   * @memberof EidosSearchPaginatorComponent
   */
  @Input() public resultsViews: Array<CoreSearchResultsViewConfig> = [];
  /**
   * Move more filters to sidebar flag
   *
   * @type {boolean}
   * @memberof EidosSearchPaginatorComponent
   */
  @Input() public isSidebarSearch?: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.viewTabs = this.resultsViews.map(r => {
      return { icon: r.icon };
    });
  }
  /**
   * Select a view
   *
   * @param {*} event
   * @memberof EidosSearchPaginatorComponent
   */
  public selectView(event: any) {
    this.currentResultsView = this.resultsViews[event.itemIndex];
  }
}
