<content *ngIf="iconConfiguration" [ngSwitch]="iconConfiguration.iconType" [ngClass]="{'clickable': iconClickable}">
  <mat-icon *ngSwitchCase="EidosIconType.Material" class="eidos-icon" [style.transform]="iconScale"
    matTooltip="{{ iconTooltip | transloco }}" matBadge="{{iconBadge}}" [matTooltipPosition]="iconTooltipPosition">
    {{ iconConfiguration.iconValue ?? iconConfiguration.iconCode }}</mat-icon>
  <i *ngSwitchCase="EidosIconType.Awesome" class="eidos-icon" matTooltip="{{ iconTooltip | transloco }}" [matTooltipPosition]="iconTooltipPosition"
    class="fa {{ getFAValue() }}"
    [style.transform]="iconScale"></i>
  <mat-icon *ngSwitchCase="EidosIconType.SVG"
    class="eidos-icon eidos-svg-icon"
    [ngClass]="getSvgScale('*')"
    [svgIcon]="iconConfiguration.iconValue ?? iconConfiguration.iconCode" matTooltip="{{ iconTooltip | transloco }}"
    matBadge="{{iconBadge}}" [matTooltipPosition]="iconTooltipPosition"></mat-icon>
  <img *ngSwitchCase="EidosIconType.MyBiz" [src]="iconConfiguration.iconCodeSafeUrl">
  <mat-icon *ngSwitchDefault>{{ iconConfiguration.iconValue ?? iconConfiguration.iconCode }}</mat-icon>
</content>
