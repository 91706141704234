import { Component, Input } from '@angular/core';

import { EidosBaseComponent } from '@app/core/components/eidos-base.component';

@Component({
  selector: 'eidos-modal-title',
  templateUrl: './eidos-modal-title.component.html',
  styleUrls: ['./eidos-modal-title.component.scss']
})
export class EidosModalTitleComponent extends EidosBaseComponent {

  @Input() public title: string = 'Eidos popup';
  @Input() public subtitle: string = '';

  constructor() {
    super();
  }
}
