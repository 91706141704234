import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { EidosExternalApp } from '@common/models/eidos-external-app.model';

@Component({
  selector: 'eidos-configuration-item-viewer',
  templateUrl: './eidos-configuration-item-viewer.component.html',
  styleUrls: ['./eidos-configuration-item-viewer.component.scss']
})
export class EidosConfigurationItemViewerComponent implements OnInit {

  /**
   * Entry of the configuration property
   * [Name of the property, value of the property]
   *
   * @type {([string | number, any])}
   * @memberof EidosConfigurationItemViewerComponent
   */
  @Input() entry: [string | number, any] = ["", null];
  /**
 * Fake configuration property
 *
 * @type {*}
 * @memberof EidosConfigurationItemViewerComponent
 */
  @Input() fakeConfig: any;
  /**
   * Name of the property on fake configuration
   *
   * @type {(string | number)}
   * @memberof EidosConfigurationItemViewerComponent
   */
  protected fakeConfigProp: string | number = "";
  /**
   * Mismatch between current and fake correct configuration
   *
   * @type {(string | undefined)}
   * @memberof EidosConfigurationItemViewerComponent
   */
  protected mismatchMessage: string | undefined;

  protected objectEntries = Object.entries;
  protected typeOf = (item: any) => typeof item;

  constructor(
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    if (this.fakeConfig instanceof Array) {
      this.fakeConfigProp = 0;
    } else {
      if (!isNaN(Number(this.entry[0].toString()))) {
        this.fakeConfigProp = parseInt(this.entry[0].toString());
      } else {
        this.fakeConfigProp = this.entry[0];
      }
    }
    this.mismatchMessage = this.isPropertyMatchingInterface(this.entry[1], this.fakeConfig, this.fakeConfigProp) ?? undefined;
  }

  /**
   * Get truncate value in case of huge strings
   *
   * @return {*} 
   * @memberof EidosConfigurationItemViewerComponent
   */
  public getTruncatedValue() {
    const value = this.entry[1];
    if (this.typeOf(value) === "string") {
      return value.length > 50 ? value.substring(0, 100) + "..." : value;
    } else {
      return value;
    }
  }

  /**
   * Check if the current configuration value is matching with the expected
   *
   * @private
   * @param {*} obj
   * @param {*} sample
   * @param {(string | number)} [property]
   * @return {*}  {(string | void)}
   * @memberof EidosConfigurationItemViewerComponent
   */
  private isPropertyMatchingInterface(obj: any, sample: any, property?: string | number): string | void {
    if (sample !== undefined && property !== undefined) {

      const fakeProperty = (sample instanceof Array) ? sample[0] : sample[property];

      if (obj !== null) {

        // Special rule for external apps with duplicated configuration
        if (obj === true && sample instanceof EidosExternalApp && property === "isDuplicated") {
          return this.translocoService.translate('readme.duplicated_config');
        }

        if (fakeProperty === null || fakeProperty === undefined) {
          return this.translocoService.translate('readme.not_existing_property', { property: property });
        }

        // If we have an array, then each of the items in the o array must be of the same type
        if (obj instanceof Array) {
          if (!(fakeProperty instanceof Array)) {
            return this.translocoService.translate('readme.not_array_property', { property: property });
          };

          let oneSample = fakeProperty[0];
          if (obj.length && obj.length > 0) {
            for (let index in obj) {
              if (typeof obj[index] !== typeof oneSample) {
                return this.translocoService.translate('readme.not_array_th_property', { property: property, index: index });
              }
            }
          }
        }
      }
      else {
        if (fakeProperty != null) {
          return this.translocoService.translate('readme.missing_property', { property: property });
        }
      }
    }
  }
}
