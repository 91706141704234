import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { CoreSearchResultsViewConfig, ICoreSearchTabBaseConfiguration } from '@common/models/eidos-search.model';
import { EidosSearchResultsComponent } from '../eidos-search-results.component';

@Component({
  selector: 'eidos-generic-search-results',
  templateUrl: './eidos-generic-search-results.component.html',
  styleUrls: ['./eidos-generic-search-results.component.scss']
})
export class EidosGenericSearchResultsComponent<E> extends EidosSearchResultsComponent<E> {
  /**
   * Identifier of the unknown results container
   *
   * @type {string}
   * @memberof EidosGenericSearchResultsComponent
   */
  public readonly UNKNOWN_TAB_IDENTIFIER: string = "#";
  /**
   * Title of the unknown results container
   *
   * @type {string}
   * @memberof EidosGenericSearchResultsComponent
   */
  public readonly UNKNOWN_TAB_TITLE: string = "Unknown entity category";
  /**
   * Tab config
   *
   * @type {Array<ICoreSearchTabBaseConfiguration>}
   * @memberof EidosGenericSearchResultsComponent
   */
  @Input() public genericTabConfig: Array<ICoreSearchTabBaseConfiguration> = [];
  /**
   * Entity to tab map function
   *
   * @param {*} result
   * @memberof EidosGenericSearchResultsComponent
   */
  @Input() public resultSpecificMap?: ((result: any) => string | undefined);
  /**
   * Results setter
   *
   * @memberof EidosGenericSearchResultsComponent
   */
  @Input() public override set results(items: Array<E>) {
    this._results = items;
    this.setGenericResults();
  }
  /**
   * Results getter
   *
   * @memberof EidosGenericSearchResultsComponent
   */
  public get results() {
    return this._results;
  }
  /**
   * Generic search results view config setter
   *
   * @memberof EidosGenericSearchResultsComponent
   */
  @Input() public override set config(value: Array<CoreSearchResultsViewConfig>) {
    this._config = value;
    if (value && value.length > 0) {
      this.resultTemplate = value[0].template;
    }
  }
  /**
   * Generic search results view config getter
   *
   * @memberof EidosGenericSearchResultsComponent
   */
  public get config() {
    return this._config;
  }
  /**
   * Emits the selected tab
   *
   * @type {EventEmitter<string>}
   * @memberof EidosGenericSearchResultsComponent
   */
  @Output() public selectTab: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Generic results
   *
   * @type {Array<{ tabConfig: ICoreSearchTabBaseConfiguration, results: Array<E> }>}
   * @memberof EidosGenericSearchResultsComponent
   */
  public genericResults: Array<{ tabConfig: ICoreSearchTabBaseConfiguration, results: Array<E> }> = [];
  /**
   * Generic result template (customTemplate of first resultConfig of the tab)
   *
   * @type {TemplateRef<any>}
   * @memberof EidosGenericSearchResultsComponent
   */
  public resultTemplate?: TemplateRef<any>;

  constructor() {
    super();
  }
  /**
   * Sets generic search results
   *
   * @private
   * @param {Array<E>} results
   * @memberof EidosGenericSearchResultsComponent
   */
  private setGenericResults() {
    this.genericResults = this.genericTabConfig.map(tab => {
      return { tabConfig: tab, results: [] };
    });

    this.results.forEach(item => {
      if (this.resultSpecificMap) {
        const mappedTab = this.resultSpecificMap(item);
        if (mappedTab) {
          const tab = this.genericTabConfig.find(item => item.identifier === mappedTab) || { identifier: this.UNKNOWN_TAB_IDENTIFIER, title: this.UNKNOWN_TAB_TITLE };
          const alreadyExistingTab = this.genericResults.find(result => result.tabConfig.identifier === tab.identifier);
          if (alreadyExistingTab) {
            alreadyExistingTab.results.push(item);
          } else {
            this.genericResults.push({ tabConfig: tab, results: [item] });
          }
        }
      }
    });
  }
  /**
   * Generic result click handler
   *
   * @param {*} event
   * @memberof EidosGenericSearchResultsComponent
   */
  public onGenericResultsClick(event: { itemData: E }) {
    if (event.itemData) {
      this.onResultsClick(event.itemData);
    }
  }
}
