import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { SpinnerOverlayService } from '@common/services/spinner-overlay.service';

import { IEidosDashboardConfig, IEidosDashboardEmptyCell } from '@common/models/eidos-dashboard.interface';
import { EidosDashboardConfig } from '@common/models/eidos-dashboard.model';
import { EidosObject, EidosObjectData } from '@common/models/eidos-object.model';

@Component({
  selector: 'eidos-dashboard',
  templateUrl: './eidos-dashboard.component.html',
  styleUrls: ['./eidos-dashboard.component.scss']
})
export class EidosDashboardComponent implements OnInit, AfterViewInit {
  /**
   * Dashboard visualize user configuration for redering
   *
   * @type {boolean}
   * @memberof EidosDashboardComponent
   */
  @Input()
  public userDashboard: boolean = false;
  /**
   * Dashboard eidos object definition (when userDashboard is false)
   *
   * @type {EidosObject | undefined}
   * @memberof EidosDashboardComponent
   */
  @Input()
  public eidosObject: EidosObject | undefined;
  /**
 * Dashboard eidos object configuration (when userDashboard is false)
 *
 * @type {EidosObjectConfiguration | undefined}
 * @memberof EidosDashboardComponent
 */
  @Input()
  public eidosObjectDashboardConfiguration: EidosDashboardConfig | undefined;
  /**
 * Dashboard eidos object data (when userDashboard is false)
 *
 * @type {EidosObjectData | undefined}
 * @memberof EidosDashboardComponent
 */
  @Input()
  public eidosObjectData: EidosObjectData | undefined;

  /**
   * Dashboard configuration
   *
   * @type {IEidosDashboardConfig}
   * @memberof EidosDashboardComponent
   */
  public dashboardConfig?: IEidosDashboardConfig;
  /**
   *  Dashboard empty cell
   * (Used to make ngFor on in HTML template)
   *
   * @type {Array<number>}
   * @memberof EidosDashboardComponent
   */
  public emptyCells: Array<IEidosDashboardEmptyCell> = [];

  constructor(
    private eidosUtilityService: EidosUtilityService
    , public eidosLogservice: EidosLogService
    , private spinnerOverlayService: SpinnerOverlayService
  ) {
  }

  ngOnInit(): void {

    if (this.userDashboard) {

      this.eidosUtilityService.currentDashboard.subscribe((dashboard: IEidosDashboardConfig | undefined) => {
        this.refresh(dashboard);
      });
    }

    if (!this.userDashboard) {
      this.refresh(this.eidosObjectDashboardConfiguration);
      return;
    }
  }

  ngAfterViewInit(): void {
  }
  
  /**
 * Refresh the dashboard configuration
 *
 * @private
 * @param {IEidosDashboardConfig} [dashboardConfig]
 * @memberof EidosDashboardComponent
 */
  private refresh(dashboardConfig?: IEidosDashboardConfig): void {
    this.dashboardConfig = dashboardConfig;
    if (this.dashboardConfig?.enabled) {
      this.dashboardConfig.eidosObject.forEach(e => { if (e.pageSize == null) e.pageSize = 10; });
      this.spinnerOverlayService.show();
      const cellsMissing = this.dashboardConfig.columns * this.dashboardConfig.rows - this.dashboardConfig.eidosObject.reduce((t, o) => t + o.columnSize * o.rowSize, 0);
      this.emptyCells = Array.from({ length: cellsMissing }, () => {
        return {
          RowCoordinate: 0,
          ColumnCoordinate: 0
        };
      });
      //safe design
      if (this.emptyCells.length > 300) {
        this.emptyCells.length = 300;
      }
      this.spinnerOverlayService.hide();
    }
  }
}
