import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IEidosFiltersRegistry } from "@common/models/eidos-filters.models";
import { EidosFilterComponent } from "@common/components/entities/filters/eidos-filter/eidos-filter.component";

@Component({
  selector: 'eidos-filter-sliderrange',
  templateUrl: './eidos-filter-sliderrange.component.html',
  styleUrls: ['./eidos-filter-sliderrange.component.scss']
})
export class EidosFilterSliderRangeComponent extends EidosFilterComponent {
  /**
   * Current filter value (2-way binding)
   *
   * @type {IEidosFiltersRegistry["sliderrange"]["valueType"]}
   * @memberof EidosFilterSliderRangeComponent
   */
  protected _value: IEidosFiltersRegistry["sliderrange"]["valueType"];
  @Input() public get value(): IEidosFiltersRegistry["sliderrange"]["valueType"] {
    return this._value;
  }
  public set value(val: IEidosFiltersRegistry["sliderrange"]["valueType"]) {
    this._value = val;
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<IEidosFiltersRegistry["sliderrange"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["sliderrange"]["valueType"]>();
  /**
   * Current second filter (for range) value (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterSliderRangeComponent
   */
  protected _otherValue: IEidosFiltersRegistry["sliderrange"]["valueType"];
  @Input() public get otherValue(): IEidosFiltersRegistry["sliderrange"]["valueType"] {
    return this._otherValue;
  }
  public set otherValue(val: IEidosFiltersRegistry["sliderrange"]["valueType"]) {
    this._otherValue = val;
    this.otherValueChange.emit(this._otherValue);
  }
  @Output() public otherValueChange: EventEmitter<IEidosFiltersRegistry["sliderrange"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["sliderrange"]["valueType"]>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["sliderrange"]["config"]}
   * @memberof EidosFilterSliderRangeComponent
   */
  @Input() public config: IEidosFiltersRegistry["sliderrange"]["config"];

  constructor() {
    super();
  }
}
