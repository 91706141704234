import { Component, Input, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'eidos-progress-spinner',
  styleUrls: ['eidos-progress-spinner.component.scss'],
  templateUrl: 'eidos-progress-spinner.component.html'
})
export class EidosProgressSpinnerComponent implements OnInit {

  constructor() {
  }

  @Input()
  public value: number = 100;

  @Input()
  diameter: number = 100;

  @Input()
  mode: ProgressSpinnerMode = 'indeterminate';

  @Input()
  strokeWidth: number = 10;

  @Input()
  overlay: boolean = true;

  @Input()
  color: ThemePalette = 'primary';

  ngOnInit() {
  }
}
