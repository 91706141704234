import { EidosBootstrapAlias } from "./core-constant.model";

/**
 * Core form (eidos-filter-form) mode enum
 *
 * @export
 * @enum {number}
 */
export enum CoreFormMode {
  Search,
  Submit
};

/**
 * Core form commands (eidos-filter-form-commands) settings
 *
 * @export
 * @interface ICoreFormCommandsSettings
 */
export interface ICoreFormCommandsSettings {
  mode?: CoreFormMode;
  searchLabel?: string;
  hideSubmit?: boolean;
  hideClear?: boolean;
  hideMultiselect?: boolean;
  submitCssClass?: string;
  submitCssId?: string;
  submitLabel?: string;
  clearCssClass?: string;
  clearCssId?: string;
  clearIcon?: string;
  clearLabel?: string;
  multiselectCssClass?: string;
  multiselectCssId?: string;
  multiselectIcon?: string;
  multiselectLabel?: string;
  disabledClear?: boolean;
  disabledSubmit?: boolean;
  disabledMultiselect?: boolean;
}

/**
 * Eidos form field config
 *
 * @export
 * @interface IEidosFormElementConfig
 */
export interface IEidosFormElementConfig {
  /**
   * Element identifier
   *
   * @type {string}
   * @memberof IEidosFormElementConfig
   */
  name?: string;
  /**
   * Element type
   *
   * @type {string}
   * @memberof IEidosFormElementConfig
   */
  type: string;
  /**
   * Element hidden computed flag
   *
   * @type {boolean}
   * @memberof IEidosFormElementConfig
   */
  hidden?: (() => boolean);
  /**
   * Element required flag
   *
   * @type {boolean}
   * @memberof IEidosFormElementConfig
   */
  required?: boolean;
  /**
   * Element required message
   *
   * @type {string}
   * @memberof IEidosFormElementConfig
   */
  requiredMessage?: string
  /**
   * Show the an X icon to clear the field
   *
   * @type {boolean}
   * @memberof IEidosFormElementConfig
   */
  showClearButton?: boolean;
  /**
   * Default value for the input
   *
   * @type {*}
   * @memberof IEidosFormElementConfig
   */
  default?: any;
  /**
   * Element disabled flag
   *
   * @type {boolean}
   * @memberof IEidosFormElementConfig
   */
  disabled?: boolean;
  /**
   * Element placeholder
   *
   * @type {string}
   * @memberof IEidosFormElementConfig
   */
  placeholder?: string;
  /**
   * Bootstrap alias classes of the element in the form
   * Es. '['col-lg-3', 'col-sm-12']
   *
   * @type {Array<EidosBootstrapAlias>}
   * @memberof IEidosFormElementConfig
   */
  layoutClasses?: Array<EidosBootstrapAlias>;
  /**
   * CSS classes of the element in the form
   *
   * @type {Array<string>}
   * @memberof IEidosFormElementConfig
   */
  cssClasses?: Array<string>;
  /**
   * Focus in handler
   *
   * @memberof IEidosFormElementConfig
   */
  onFocusIn?(): void;
  /**
   * Focus out handler
   *
   * @memberof IEidosFormElementConfig
   */
  onFocusOut?(): void;
  /**
   * On value change handler
   *
   * @template C
   * @param {*} component Component reference
   * @param {*} value
   * @return {*}  {*}
   * @memberof IEidosFormElementConfig
   */
  onValueChange?: (component: any, value: any) => any;
}