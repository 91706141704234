<div class="configuration-viewer-page">
  <div class="configuration-viewer-side configuration-viewer">
    <mat-list>
      <div class="configuration-viewer-block">
        <div mat-subheader>{{ 'readme.configuration' | transloco }}</div>
        <div class="configuration-item-root" *ngFor="let entry of objectEntries(config)">
          <eidos-configuration-item-viewer [entry]="entry" [fakeConfig]="fakeConfig"></eidos-configuration-item-viewer>
        </div>
      </div>
    </mat-list>
  </div>
  <div class="configuration-viewer-side versions-viewer">
    <mat-list>
      <div class="configuration-viewer-block" *ngIf="apps && apps.length > 0">
        <div mat-subheader>{{ 'readme.configuration' | transloco }}</div>
        <div class="configuration-item-root" *ngFor="let entry of objectEntries(apps)">
          <eidos-configuration-item-viewer [entry]="entry" [fakeConfig]="apps"></eidos-configuration-item-viewer>
        </div>
      </div>
      <div class="configuration-viewer-block">
        <div mat-subheader>{{ 'readme.eidos_version' | transloco }} {{ isDevMode() ? translocoService.translate('readme.info_outofdate') : ''}}</div>
        <div title="{{eventuallyDisplayDateAndTime(entry[1])}}" class="configuration-item-root" *ngFor="let entry of objectEntries(Version)">
          <mat-list-item>
            <div mat-line>
              <b>{{ entry[0] + ': '}}</b>{{ eventuallyDisplayDateAndTime(entry[1]) }}
            </div>
          </mat-list-item>
        </div>
      </div>
      <div class="configuration-viewer-block" *ngIf="myBizVersion">
        <div mat-subheader>{{ 'readme.mybiz_version' | transloco }}</div>
        <content *ngIf="!!myBizVersion.apiVersion">
          {{ 'readme.api_version' | transloco }}
          <div class="configuration-item-root" *ngFor="let entry of objectEntries(myBizVersion.apiVersion)">
            <eidos-configuration-item-viewer [entry]="entry"></eidos-configuration-item-viewer>
          </div>
        </content>
        <content *ngIf="!!myBizVersion.dbVersion">
          {{ 'readme.db_version' | transloco }}
          <div class="configuration-item-root" *ngFor="let entry of objectEntries(myBizVersion.dbVersion)">
            <eidos-configuration-item-viewer [entry]="entry"></eidos-configuration-item-viewer>
          </div>
        </content>
      </div>
      <div class="configuration-viewer-block" *ngIf="versions && versions.length">
        <div mat-subheader>{{ 'readme.external_version' | transloco }}</div>
        <div class="configuration-item-root" *ngFor="let app of versions">
          <mat-list-item>
            <div mat-line>
              <b>{{ app.source + ': '}}</b>{{ app.version }}
            </div>
          </mat-list-item>
        </div>
      </div>
    </mat-list>
  </div>
</div>