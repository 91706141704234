import { state, style, trigger } from '@angular/animations';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, filter } from 'rxjs';

import { EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { IEidosDashbordCellObjectGridOptions } from '@common/models/eidos-dashboard.interface';
import {
  EidosDataObjectFieldFilter,
  EidosDataObjectFieldSort,
  EidosObject,
  EidosObjectConfiguration,
  EidosObjectData,
  EidosObjectEventAction,
  EidosObjectEventActionRequest,
  EidosObjectEventType,
  EidosObjectFieldType,
  EidosObjectGridConfig,
  EidosObjectGridOptions,
  EidosSortType,
  IEidosGridValue,
} from '@common/models/eidos-object.model';

import { EidosObjectBaseComponent } from '@eidos/components/objects/eidos-object-base/eidos-object-base.component';
import { EidosObjectService } from '@eidos/services/eidos-object.service';
import CustomStore from 'devextreme/data/custom_store';
import { MyBizFilterType } from '@common/models/mybiz.model';

@Component({
  selector: 'eidos-object-grid',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: 'auto',
        })
      ),
      state(
        'closed',
        style({
          height: '.1603rem',
        })
      ),
    ]),
  ],
  templateUrl: './eidos-object-grid.component.html',
  styleUrls: ['./eidos-object-grid.component.scss'],
})
export class EidosObjectGridComponent
  extends EidosObjectBaseComponent
  implements OnInit, AfterContentInit
{
  EidosObjectFieldType = EidosObjectFieldType;
  /**
   * Reference to paginator
   *
   * @type {(MatPaginator | undefined)}
   * @memberof EidosObjectGridComponent
   */
  @ViewChild('matpaginator', { static: false, read: MatPaginator })
  paginatorRef: MatPaginator | undefined;
  /**
   * Dashboard eidos object definition (when userDashboard is false)
   *
   * @type {EidosObject | undefined}
   * @memberof EidosObjectGridComponent
   */
  @Input()
  public eidosObject: EidosObject | undefined;
  /**
   * Dashboard eidos object configuration (when userDashboard is false)
   *
   * @type {EidosObjectGridConfig | undefined}
   * @memberof EidosObjectGridComponent
   */
  @Input()
  public eidosObjectConfiguration: EidosObjectConfiguration | undefined;
  /**
   * Grid object configuration
   *
   * @type {(EidosObjectGridConfig | undefined)}
   * @memberof EidosObjectGridComponent
   */
  @Input()
  public eidosObjectGridConfiguration: EidosObjectGridConfig | undefined;
  /**
   * Dashboard eidos object data (when userDashboard is false)
   *
   * @type {EidosObjectData | undefined}
   * @memberof EidosObjectGridComponent
   */
  @Input()
  public eidosObjectData: EidosObjectData | undefined;
  /**
   * Grid options
   *
   * @type {EidosObjectGridOptions}
   * @memberof EidosObjectGridComponent
   */
  public options: EidosObjectGridOptions = new EidosObjectGridOptions();
  /**
   * Grid datasource
   *
   * @type {MatTableDataSource<IEidosGridValue>}
   * @memberof EidosObjectGridComponent
   */
  public dataSource: any;

  public dataSourceValue: any;

  public totalRows: number | undefined;

  public pageSize: number = 0;

  isFooterOpened = false;
  isHeaderOpened = false;
  columndefs: Array<string> = [];
  filterdefs: Array<string> = [];
  footerdefs: Array<any> = [];
  @Input() PageIndex = 1;

  public columns: any = [];

  /**
   * Sorting change subject to debounce event
   *
   * @memberof EidosObjectGridComponent
   */
  public gridSortChangeSubject = new BehaviorSubject<Sort>({
    active: '',
    direction: '',
  });

  constructor(
    private changeDetector: ChangeDetectorRef,
    private eidosObjectService: EidosObjectService,
    private eidosLogService: EidosLogService
  ) {
    super();
    this.dataSource = new CustomStore({
      load: async (loadOptions: any) => {
        return this.buildDataSource(loadOptions);
      },
    });

    this.eidosObjectService.objectEventQueryEmitter
      .pipe(filter((e) => e.actionMessage?.tagetId === this.eidosObjectId))
      .subscribe((e) => {
        if (!e.actionMessage) return;

        let data = {};
        switch (e.actionMessage.action) {
          case EidosObjectEventAction.ToggleFieldFilters:
            if (
              e.actionMessage.request === EidosObjectEventActionRequest.Trigger
            ) {
              this.options.hideColumnFilters = !this.options.hideColumnFilters;
            }
            data = { status: this.options.hideColumnFilters };
            break;
          case EidosObjectEventAction.ReloadData:
            this.eidosObjectService.reloadData(
              this.eidosObjectId,
              this.eidosObject
            );
            break;
          case EidosObjectEventAction.ReloadObject:
            this.eidosObjectService.loadComponent(
              this.eidosObjectId,
              this.eidosObject,
              true
            );
            break;
        }
        this.eidosObjectService.sendResponseEvent(e, data);
      });
  }

  ngOnInit(): void {
    // Debounce sort value to avoid API calls throttle
    this.gridSortChangeSubject.subscribe((sortState) => {
      this.gridSortChange(sortState);
    });

    if (this.eidosObjectGridConfiguration?.options) {
      this.options = this.eidosObjectGridConfiguration?.options;

      // We are sure that if options are not string, they will be a valid options object
      if (
        !!this.eidosObject &&
        !!this.eidosObject.options &&
        typeof this.eidosObject.options !== 'string'
      ) {
        this.options = {
          ...this.options,
          ...(this.eidosObject.options as IEidosDashbordCellObjectGridOptions),
        };
      }
    }
  }

  ngAfterContentInit(): void {}

  ngAfterViewInit(): void {}

  /**
   * build the dataSource when the options are changed
   *
   * @private
   * @param {*} loadOptions
   * @return {*} 
   * @memberof EidosObjectGridComponent
   */
  private async buildDataSource(loadOptions: any) {

    //se sono nel primo render della griglia
    if (!loadOptions.skip && !this.columns?.length) {
      this.loadConfigAndData();
      this.totalRows = this.eidosObjectData?.totalRows || 0;
      this.pageSize = this.eidosObjectData?.pageSize || 0;
    }

    //controllo se sono stati cambiati i filtri
    if (!!loadOptions.filter || !!this.eidosObject?.filters?.length) {
      //se sono stati cambiati i filtri, ricarico il dataSource
      let filterAreChanged: boolean = false;

      if (!!loadOptions.filter) {
        //traduco la sintassi di filtro devexpress in sintassi di eidos
        let activeFilters = this.parseFilters(loadOptions.filter);

        //se sono stati selezionati più filtri
        if (this.eidosObject?.filters?.length && Array.isArray(activeFilters)) {
          //controllo che i filtri siano cambiati
          if (activeFilters.length != this.eidosObject.filters.length) {
            //se è stato aggiunto un filtro
            if (activeFilters.length > this.eidosObject.filters.length) {
              let newFilter = this.getFiltersDiffence(
                activeFilters,
                this.eidosObject.filters
              );
              this.gridFilterAdded(newFilter);
              filterAreChanged = true;
            } else if (activeFilters.length < this.eidosObject.filters.length) {
              //se è stato rimosso un filtro
              let oldFilter = this.getFiltersDiffence(
                this.eidosObject.filters,
                activeFilters
              );
              this.gridFilterRemoved(oldFilter.fieldName);
              filterAreChanged = true;
            }
          } else if (
            !!this.getFiltersDiffence(activeFilters, this.eidosObject.filters)
          ) {
            this.gridFilterAdded(
              this.getFiltersDiffence(activeFilters, this.eidosObject.filters)
            );
            filterAreChanged = true;
          } else {
          }
          //se è stato selezionato un solo filtro
        } else if (!Array.isArray(activeFilters)) {
          //se prima non c'era nessun filtro
          if (!this.eidosObject?.filters?.length) {
            this.gridFilterAdded(activeFilters);
            filterAreChanged = true;
            //se prima c'era un filtro applicato, controllo se sia cambiato
          } else if (
            activeFilters.fieldName == this.eidosObject.filters[0].fieldName &&
            (activeFilters.value != this.eidosObject.filters[0].value ||
              activeFilters.type != this.eidosObject.filters[0].type)
          ) {
            this.gridFilterAdded(activeFilters);
            filterAreChanged = true;
          } else if (this.eidosObject.filters.length > 1) {
            let field = activeFilters.fieldName;
            let oldFilter = this.eidosObject.filters.find(
              (f) => f.fieldName != field
            );

            if (!!oldFilter) this.gridFilterRemoved(oldFilter.fieldName);
            filterAreChanged = true;
          }
        }
      } else if (!!this.eidosObject?.filters?.length) {
        this.gridFilterRemoved(this.eidosObject?.filters[0].fieldName);
        filterAreChanged = true;
      }
      if (filterAreChanged) {
        this.dataSourceValue =
          (await this.getNewDataSourceValue()) || this.dataSourceValue;
      }
    }

    //controllo se è stato cambiato il sorting
    if (!!loadOptions.sort || !!this.eidosObject?.sort?.length) {
      let sortChanged: boolean = false;
      let newSort: Sort = { active: '', direction: '' };
      if (!!loadOptions.sort) {
        newSort = {
          active: loadOptions.sort[0].selector,
          direction: loadOptions.sort[0].desc ? 'desc' : 'asc',
        };

        if (
          this.eidosObject?.sort[0]?.fieldName != newSort.active ||
          (this.eidosObject?.sort[0]?.type == EidosSortType.Ascending
            ? newSort.direction != 'asc'
            : newSort.direction != 'desc')
        ) {
          sortChanged = true;
        }
      } else {
        sortChanged = true;
      }
      if (sortChanged) {
        this.gridSortChangeDebounce(newSort);
        this.dataSourceValue =
          (await this.getNewDataSourceValue()) || this.dataSourceValue;
      }
    }

    //se mi trovo in una nuova pagina
    if (this.PageIndex != (loadOptions.skip / loadOptions.take || 0) + 1) {
      this.pageChanged((loadOptions.skip / loadOptions.take || 0) + 1);

      this.dataSourceValue =
        (await this.getNewDataSourceValue()) || this.dataSourceValue;

      //TODO trovare un modo migliore per essere sicuro che la pagina sia cambiata
      return new Promise<any>((resolve) => {
        // @ts-ignore
        let promise = new Promise<any>((res) => {
          setTimeout(() => {
            resolve({
              data: this.dataSourceValue,
              totalCount: this.totalRows,
            });
            res('');
          }, 100);
        });
      });
    }

    return new Promise<any>((resolve) => {
      resolve({
        data: this.dataSourceValue,
        totalCount: this.totalRows,
      });
    });
  }

  /**
   * Parsing of devexpress fitlers in eidos filters
   *
   * @private
   * @param {*} filters
   * @return {*} 
   * @memberof EidosObjectGridComponent
   */
  private parseFilters(filters: any) {
    if (Array.isArray(filters[0])) {
      let newFilters: EidosDataObjectFieldFilter[] = [];
      //rimuovo gli elementi 'and' che dev express mette tra un filtro e l'altro

      filters
        .filter((f: any) => Array.isArray(f) && f.length > 0)
        .forEach((f: any) => {
          newFilters.push({
            fieldName: f[0],
            value: f[2],
            type: this.parseFilterType(f[1]),
            otherValue: '',
          });
        });

      return newFilters;
    }

    let newFilter: EidosDataObjectFieldFilter = {
      fieldName: filters[0],
      value: filters[2],
      type: this.parseFilterType(filters[1]),
      otherValue: '',
    };
    return newFilter;
  }

  /**
   * Parsing of filters type
   *
   * @private
   * @param {string} type
   * @return {*}  {MyBizFilterType}
   * @memberof EidosObjectGridComponent
   */
  private parseFilterType(type: string): MyBizFilterType {
    switch (type) {
      case 'contains':
        return MyBizFilterType.Contains;
      case 'notcontains':
        return MyBizFilterType.NotContains;
      case '=':
        return MyBizFilterType.Equal;
      case '<>':
        return MyBizFilterType.NotEqual;
      case 'startswith':
        return MyBizFilterType.BeginsWith;
      case 'endswith':
        return MyBizFilterType.EndsWith;
      default:
        return MyBizFilterType.Contains;
    }
  }

  /**
   * Get the differces between 2 arrays of filters
   *
   * @private
   * @param {EidosDataObjectFieldFilter[]} first
   * @param {EidosDataObjectFieldFilter[]} second
   * @return {*}  {EidosDataObjectFieldFilter}
   * @memberof EidosObjectGridComponent
   */
  private getFiltersDiffence(
    first: EidosDataObjectFieldFilter[],
    second: EidosDataObjectFieldFilter[]
  ): EidosDataObjectFieldFilter {
    if (first.length == second.length) {
      return first.filter(
        (f) =>
          second.find((s) => s.fieldName === f.fieldName)?.value != f.value ||
          second.find((s) => s.fieldName === f.fieldName)?.type != f.type
      )[0];
    }

    return first.filter(
      (f) => !second.some((s) => s.fieldName === f.fieldName)
    )[0];
  }

  /**
   *  Get new data source value
   *
   * @return {*}  {Promise<any>}
   * @memberof EidosObjectGridComponent
   */
  public getNewDataSourceValue(): Promise<any> {
    //se waitPageChange è true, significa che è stato richiesto un cambio di pagina
    return new Promise<any>((resolve) => {
      this.eidosObjectService.objectEventEmitter.subscribe((ev) => {
        if (ev.id != this.getEidosObjectId()) return resolve('');

        if (ev.type == EidosObjectEventType.ReloadComplete) {
          const eidosObjectDataGridValue = ev.data?.value as IEidosGridValue;
          this.totalRows = ev.data?.totalRows || 0;
          this.changeDetector.detectChanges();
          resolve(eidosObjectDataGridValue);
        }
        resolve('');
        this.loading = false;
      });
    });
  }

  /**
   * Debounce the sort change
   *
   * @param {Sort} sortState
   * @memberof EidosObjectGridComponent
   */
  public gridSortChangeDebounce(sortState: Sort) {
    this.gridSortChangeSubject.next(sortState);
  }
  /**
   * Handle sort change
   *
   * @private
   * @param {Sort} sortState
   * @return {*}
   * @memberof EidosObjectGridComponent
   */
  private gridSortChange(sortState: Sort) {
    if (!this.eidosObject) return;

    if (!!sortState.direction) {
      this.eidosLogService.logDebug(EidosLogSeverity.Log, 'sorting', sortState);
      const fieldName = sortState.active;
      this.eidosObject.sort = [
        new EidosDataObjectFieldSort(
          fieldName,
          1,
          sortState.direction === 'asc'
            ? EidosSortType.Ascending
            : EidosSortType.Descending
        ),
      ];
    } else {
      this.eidosLogService.logDebug(
        EidosLogSeverity.Log,
        'sort clear',
        sortState
      );
      this.gridSortClear();
    }
    this.eidosObjectService.reloadData(
      this.eidosObject.eidosObjectId,
      this.eidosObject
    );
  }
  /**
   * Clear the sort status
   *
   * @private
   * @return {*}
   * @memberof EidosObjectGridComponent
   */
  private gridSortClear() {
    if (!this.eidosObject) return;
    this.eidosObject.sort = [];
  }

  /**
   * Handle page change
   *
   * @private
   * @param {number} newIndex
   * @return {*}  {void}
   * @memberof EidosObjectGridComponent
   */
  private pageChanged(newIndex: number): void {
    if (!this.eidosObject || !this.eidosObjectData) return;

    this.loading = true;

    this.PageIndex = newIndex;
    this.eidosObject.objectDataPage = newIndex;
    this.eidosObjectService.reloadData(
      this.eidosObject.eidosObjectId,
      this.eidosObject
    );
  }

  /**
   * Grid filtering add handler
   *
   * @param {Array<EidosDataObjectFieldFilter>} filters
   * @memberof EidosObjectGridComponent
   */
  public gridFilterAdded(newFilter: EidosDataObjectFieldFilter) {
    if (this.eidosObject) {
      let currentFilterOnField = this.eidosObject.filters?.find(
        (filter) => filter.fieldName === newFilter.fieldName
      );
      if (currentFilterOnField) {
        currentFilterOnField.value = newFilter.value;
        currentFilterOnField.otherValue = newFilter.otherValue;
        currentFilterOnField.type = newFilter.type;
      } else {
        this.eidosObject.filters
          ? this.eidosObject.filters.push(newFilter)
          : (this.eidosObject.filters = [newFilter]);
      }
      this.eidosObjectService.reloadData(
        this.eidosObject.eidosObjectId,
        this.eidosObject
      );
    }
  }

  /**
   * Grid filtering remove handler
   *
   * @param {Array<EidosDataObjectFieldFilter>} filters
   * @memberof EidosObjectGridComponent
   */
  public gridFilterRemoved(fieldName: string) {
    if (this.eidosObject) {
      // Remove the filter and reload the data only if the filter was existing
      if (this.eidosObject.filters?.find((f) => f.fieldName === fieldName)) {
        this.eidosObject.filters = this.eidosObject.filters?.filter(
          (f) => f.fieldName !== fieldName
        );
        this.eidosObjectService.reloadData(
          this.eidosObject.eidosObjectId,
          this.eidosObject
        );
      }
    }
  }

  /**
   * Refresh the grid configuration
   *
   * @private
   * @param {IEidosDashboardConfig} [dashboardConfig]
   * @memberof EidosDashboardComponent
   */
  private loadConfigAndData(): void {
    if (this.eidosObjectGridConfiguration) {
      // Load columns
      this.columns = this.eidosObjectGridConfiguration?.fields.filter(
        (f) => f.isVisible
      );
      this.columns.forEach((f: any) => {
        f.dataType = this.parseColumnDataType(f.dataType);
      });

      if (this.eidosObjectData) {
        // Load data
        const eidosObjectDataGridValue = this.eidosObjectData
          .value as IEidosGridValue;
        this.dataSourceValue = eidosObjectDataGridValue;
      }
    }
  }

  /**
   * Parse columns data type
   *
   * @private
   * @param {string} dataType
   * @return {*}  {string}
   * @memberof EidosObjectGridComponent
   */
  private parseColumnDataType(dataType: string): string {
    switch (dataType) {
      case 'int':
        return 'string';
      case 'text':
        return 'string';
      case 'bit':
        return 'boolean';
      case 'file':
        return '';
      case 'lookup':
        return '';
      default:
        return 'string';
    }
  }

  /**
   * Get field config from columns name
   *
   * @param {string} fieldName
   * @return {*} 
   * @memberof EidosObjectGridComponent
   */
  public getFieldConfig(fieldName: string) {
    return this.eidosObjectGridConfiguration?.fields.find(
      (f) => f.label == fieldName
    );
  }
}
