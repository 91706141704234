import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

import { EidosBaseComponent } from '../eidos-base.component';
import { IEidosIcon, IEidosMenuAction } from '@common/config/environment.interface';
import { EidosIconType, EidosLogSeverity, CoreRoute, MenuActionType, SecurityCheckRequestType } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosChangePasswordDialogComponent } from '../eidos-dialogs/eidos-change-password-dialog/eidos-change-password-dialog.component';
import { EidosImpersonateUserDialogComponent } from '../eidos-dialogs/eidos-impersonate-user-dialog/eidos-impersonate-user-dialog.component';
import { EidosConfirmationDialogComponent } from '../eidos-dialogs/eidos-confirmation-dialog/eidos-confirmation-dialog.component';
import { EidosSettingsDialogComponent } from '../eidos-dialogs/eidos-settings-dialog/eidos-settings-dialog.component';
import { IEidosConfirmationDialogComponentConfig } from '@common/models/eidos-dialog.interface';
import { CoreCacheService } from '@app/core/services/core-cache.service';
import { SpinnerOverlayService } from '@app/core/services/spinner-overlay.service';

@Component({
  selector: 'eidos-action-menu',
  templateUrl: './eidos-action-menu.component.html',
  styleUrls: ['./eidos-action-menu.component.scss']
})
export class EidosActionMenuComponent extends EidosBaseComponent {

  MenuActionType = MenuActionType;
  /**
   * Action menu is opened
   *
   * @type {boolean}
   * @memberof EidosActionMenuComponent
   */
  public opened: boolean = false;
  /**
   * Displayed menu actions
   *
   * @type {Array<IEidosMenuAction>}
   * @memberof EidosActionMenuComponent
   */
  public actions: Array<IEidosMenuAction> = [];
  /**
   * Action menu icon
   *
   * @type {IEidosIcon}
   * @memberof EidosActionMenuComponent
   */
  public actionMenuIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "more_vert",
    iconSize: 1,
  };
  /**
   * Go to booking ID
   *
   * @type {string}
   * @memberof EidosActionMenuComponent
   */
  public goToBookingID: string = "";
  /**
   * Go to booking ID well-formed flag
   *
   * @readonly
   * @memberof EidosActionMenuComponent
   */
  get legalBookingId() {
    this.goToBookingID = this.goToBookingID.replace(/\D/g, '');
    const returnValue = !!this.goToBookingID && !!parseInt(this.goToBookingID) && !isNaN(parseInt(this.goToBookingID));
    if (returnValue) {
      this.goToBookingID = parseInt(this.goToBookingID).toString();
    }
    return returnValue;
  }

  constructor(
    private eidosUtilityService: EidosUtilityService,
    private eidosSecurityService: EidosSecurityService,
    private eidosLogService: EidosLogService,
    private coreCacheService: CoreCacheService,
    private router: Router,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private spinnerOverlayService: SpinnerOverlayService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.eidosUtilityService.currentActions.subscribe((actions: Array<IEidosMenuAction>) => {
      this.actions = actions;
    });
  }

  /**
   * Launch the specified action handler
   *
   * @param {IEidosMenuAction} action
   * @return {*}  {void}
   * @memberof EidosActionMenuComponent
   */
  async execAction(action: IEidosMenuAction) {
    switch (action.id) {
      case MenuActionType.Readme:
        this.router.navigate([CoreRoute.Readme]);
        break;
      case MenuActionType.Debug:
        this.eidosUtilityService.setDebugMode();
        break;
      case MenuActionType.Impersonate:
        this.dialog.open(EidosImpersonateUserDialogComponent, {
          width: '320px'
        });
        break;
      case MenuActionType.StopImpersonate:
        this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Impersonate', 'Trying to end impersonate user.');
        this.spinnerOverlayService.show();
        this.eidosUtilityService.impersonateEnd().subscribe((result) => {
          if (result) {
            this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Impersonate', 'Successfully end impersonate');
          }
          else {
            this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Impersonate', 'Failed to end impersonate');
          }
          this.spinnerOverlayService.hide();
        });
        break;
      case MenuActionType.Settings:
        this.dialog.open(EidosSettingsDialogComponent, {
          width: '320px'
        });
        break;
      case MenuActionType.ChangePassword:
        this.dialog.open(EidosChangePasswordDialogComponent, {
          width: '480px',
        });
        break;
      case MenuActionType.Logout:

        const logoutConfirmationConfig: IEidosConfirmationDialogComponentConfig = {
          title: 'action_menu.logout',
          message: this.translocoService.translate("action_menu.logout_confirmation"),
          confirmationLabel: 'confirm',
          cancelLabel: 'cancel',
          onConfirm: () => this.eidosUtilityService.logout()
        };

        this.dialog.open(EidosConfirmationDialogComponent, {
          width: '320px',
          data: logoutConfirmationConfig
        });
        break;
      case MenuActionType.Refresh:
        sessionStorage.clear();
        await this.coreCacheService.eraseCache();
        this.eidosSecurityService.checkRequest.emit(SecurityCheckRequestType.User);
        window.location.reload();
        break;
      default:
        return;
    }
  }

  goToBooking(action: IEidosMenuAction) {
    if(!this.legalBookingId) return
    action.onClick?.(this.goToBookingID);
    this.goToBookingID = '';
    if(this.opened) this.opened=false;
  }
}
