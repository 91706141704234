import _ from "lodash";

export type CoreHttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface ICoreBaseApiFileRequestCommandParams {
  type: CoreHttpMethod;
  url: string,
  data?: any;
  reportProgress?:boolean;
}

export interface ICoreBaseApiCommandParams extends ICoreBaseApiFileRequestCommandParams {
  customBaseUrl?: string;
  withCredentials?: boolean;
  useVersion?: boolean;
  customHeaders?: { [header: string]: string | string[] };
}

export class CoreBaseApiCommandParams implements ICoreBaseApiCommandParams {
  type: CoreHttpMethod;
  url: string;
  data: any;
  customBaseUrl: string;
  withCredentials: boolean;
  useVersion: boolean;
  customHeaders: { [header: string]: string | string[] };

  constructor(params: ICoreBaseApiCommandParams) {
    this.type = 'GET';
    this.url = '',
    this.data = {};
    this.customBaseUrl = '';
    this.withCredentials = false;
    this.useVersion = false;
    this.customHeaders = {};

    if (params) {
      this.type = params.type;
      this.url = params.url;
      this.data = params.data ?? {};
      this.customBaseUrl = params.customBaseUrl ?? '';
      this.withCredentials = params.withCredentials ?? false;
      this.useVersion = params.useVersion ?? false;
      this.customHeaders = params.customHeaders ?? {};
    }
  }

  get hasData() {
    return !_.isEmpty(this.data);
  }
}
