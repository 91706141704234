import { AfterViewInit, Component, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';

import { ICoreSearchTabBaseConfiguration, ICoreSearchTabConfiguration, ICoreSearchResultSelection, CoreSearchStandardPagingConfiguration } from '@common/models/eidos-search.model';
import { CoreSafeHtmlPipe } from '@app/standalones/core-safe-html.pipe';
import { EidosEntityService } from '@common/services/eidos-entity.service';
import { CoreDrawableComponentInterface } from '../core-drawable-component.interface';
import { EidosBaseComponent } from '../eidos-base.component';
import { CoreSearchService, ICoreSearchServiceCustomCommandOptions } from './core-search.service';

import { ResSearchTemplateIdentifierDirective } from '@reservation/directives/res-search-template-identifier.directive';

/**
 * Generic entity search component
 * It includes a filter form and results different views
 *
 * @export
 * @class EidosSearchComponent
 * @extends {EidosBaseComponent}
 * @implements {AfterViewInit}
 * @implements {CoreDrawableComponentInterface}
 * @template C Search configuration type
 * @template S Search service type
 */
@Component({
  selector: 'eidos-search',
  templateUrl: './eidos-search.component.html',
  styleUrls: ['./eidos-search.component.scss'],
  providers: [CoreSearchService]
})
export class EidosSearchComponent<C extends ICoreSearchTabConfiguration, S extends EidosEntityService> extends EidosBaseComponent implements AfterViewInit, CoreDrawableComponentInterface {

  C!: C; // Reflects templated type in component html
  /**
   * Element CSS class
   *
   * @type {string}
   * @memberof EidosSearchComponent
   */
  @Input() public cssClass: string = "";
  /**
   * Element ID class
   *
   * @type {string}
   * @memberof EidosSearchComponent
   */
  @Input() public cssId: string = "";
  /**
   * Extended filter template
   *
   * @type {TemplateRef<any>}
   * @memberof EidosSearchComponent
   */
  @Input() public filterTemplate?: TemplateRef<any>;
  /**
   * Current filters
   *
   * @type {*}
   * @memberof EidosSearchComponent
   */
  protected _filters: any;
  public get filters(): any {
    return this._filters;
  }
  @Input() public set filters(value: any) {

    this._filters = value;
    this.filtersChange.emit(this._filters);
  }
  @Output() public filtersChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Configuration setter
   *
   * @memberof EidosSearchComponent
   */
  @Input() public set config(value: C | Array<C>) {
    if (!!value) {
      if (Array.isArray(value) && value.length > 0) {

        // If the configuration is an array, then we'll look for an eventual generic tab
        const hasGenericTab = value.find(item => item.isGenericTab);
        if (hasGenericTab) {
          this.genericTabConfig = value.filter(item => !item.isGenericTab).map(item => {
            return {
              title: item.title,
              identifier: item.identifier ?? item.title
            } as ICoreSearchTabBaseConfiguration;
          });
        }

        // If the configuration is an array with more than 1 element, then we'll build the tabs
        this.hasTabs = value.length > 1;

        // If the configuration is an array with only one element, we'll just take the element as configuration
        this._config = this.hasTabs ? value : value[0];

      } else {

        // If the configuration is an object, we'll just use it as configuration
        this._config = value;
      }

      // Emit config loaded event
      this.onConfigLoaded.emit(this.config);
    }
  }
  /**
   * Configuration loaded event emitter: emits the loaded configuration.
   * The configuration can be edited from ancestors components and automatically binded to the component.
   *
   * @type {(EventEmitter<C | Array<C>>)}
   * @memberof EidosSearchComponent
   */
  @Output() public onConfigLoaded: EventEmitter<C | Array<C>> = new EventEmitter<C | Array<C>>();
  /**
   * Configuration getter
   *
   * @readonly
   * @type {C | Array<C>}
   * @memberof EidosSearchComponent
   */
  public get config(): C | Array<C> {
    return this._config;
  };
  /**
   * Configuration
   *
   * @private
   * @type {C | Array<C>}
   * @memberof EidosSearchComponent
   */
  private _config: C | Array<C> = [];
  /**
   * Generic search tab enabled flag
   *
   * @type {boolean}
   * @memberof EidosSearchComponent
   */
  @Input() public enableGenericSearch: boolean = false;
  /**
   * Selected tab index
   *
   * @type {number}
   * @memberof EidosSearchComponent
   */
  public selectedTab: number = 0;
  /**
   * Is a multi-tab search flag
   *
   * @type {boolean}
   * @memberof EidosSearchComponent
   */
  public hasTabs: boolean = false;
  /**
   * Specific tabs references for the generic tab
   *
   * @type {Array<ICoreSearchTabBaseConfiguration>}
   * @memberof EidosSearchComponent
   */
  public genericTabConfig: Array<ICoreSearchTabBaseConfiguration> = [];
  /**
   * Selected results emitter
   *
   * @type {(EventEmitter<ICoreSearchResultSelection>)}
   * @memberof EidosSearchComponent
   */
  @Output()
  public onResultsSelected: EventEmitter<ICoreSearchResultSelection> = new EventEmitter<ICoreSearchResultSelection>();
  /**
   * Selected results emitter
   *
   * @type {(EventEmitter<ICoreSearchResultSelection>)}
   * @memberof EidosSearchComponent
   */
  @Output() public onSelectionChanged: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Reset filters event emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosSearchComponent
   */
  @Output()
  public onResetFilters: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onButtonClick: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Service to call search handlers
   *
   * @type {S}
   * @memberof EidosSearchComponent
   */
  @Input()
  public searchService?: S;
  /**
   * Results templates
   *
   * @private
   * @type {QueryList<ResSearchTemplateIdentifierDirective>}
   * @memberof EidosSearchComponent
   */
  private _resultsTemplates?: QueryList<ResSearchTemplateIdentifierDirective>;
  /**
   * Results template setter
   *
   * @memberof EidosSearchComponent
   */
  @Input() public set resultsTemplates(value: QueryList<ResSearchTemplateIdentifierDirective> | undefined) {
    if (value !== undefined) {
      this._resultsTemplates = value; if (this.config !== undefined && this._resultsTemplates !== undefined && this._resultsTemplates.length > 0) {
        if (Array.isArray(this.config)) {
          this.config = this.config.map(tab => {
            tab.resultsConfig = tab.resultsConfig.map(resultConfig => {
              const defaultTemplate = this._resultsTemplates!.find(template => template.identifier === tab.identifier && resultConfig.id === template.resultViewType);
              if (defaultTemplate) {
                if (resultConfig.customTemplate) {
                  defaultTemplate.template.elementRef.nativeElement = new CoreSafeHtmlPipe(this.sanitizer).transform(resultConfig.customTemplate);
                }
                resultConfig.template = defaultTemplate.template;
              }
              return resultConfig;
            });
            return tab;
          });
        } else {
          const identifier = this.config.identifier;
          this.config.resultsConfig = this.config.resultsConfig.map(resultConfig => {
            const contextTemplate = this._resultsTemplates!.find(template => resultConfig.id === template.resultViewType && template.identifier === identifier);
            const defaultTemplate = contextTemplate ? contextTemplate : this._resultsTemplates!.find(template => resultConfig.id === template.resultViewType);
            if (defaultTemplate) {
              if (resultConfig.customTemplate) {
                defaultTemplate.template.elementRef.nativeElement = new CoreSafeHtmlPipe(this.sanitizer).transform(resultConfig.customTemplate);
              }
              resultConfig.template = defaultTemplate.template;
            }
            return resultConfig;
          });
        }
      }
    }
  }
  /**
   * Results template getter
   *
   * @readonly
   * @type {(QueryList<ResSearchTemplateIdentifierDirective> | undefined)}
   * @memberof EidosSearchComponent
   */
  public get resultsTemplates(): QueryList<ResSearchTemplateIdentifierDirective> | undefined {
    return this._resultsTemplates;
  }
  /**
   * Must immediatly start search flag
   *
   * @type {boolean}
   * @memberof EidosSearchComponent
   */
  @Input() public mustSearchStart: boolean = false;
  /**
   * Standard paging configuration
   *
   * @type {CoreSearchStandardPagingConfiguration}
   * @memberof EidosSearchComponent
   */
  @Input() searchPagingConfiguration: CoreSearchStandardPagingConfiguration = new CoreSearchStandardPagingConfiguration();

  @Input() public RecalculateFilter: boolean = false;

  @Input() public TabUrlSetted?: any;

  @Output() public onContentReadyGrid: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Result checkability getter
   *
   * @memberof EidosSearchComponent
   */
  @Input() isResultCheckable: (item: any, selected: any[], ...args: any[]) => boolean = () => true;
  /**
   * Emits a custom command from a result grid column
   *
   * @type {EventEmitter<ICoreSearchServiceCustomCommandOptions>}
   * @memberof EidosSearchComponent
   */
  @Output() resultCommand: EventEmitter<ICoreSearchServiceCustomCommandOptions> = new EventEmitter<ICoreSearchServiceCustomCommandOptions>();

  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public coreSearchService: CoreSearchService) {
    super();

    // Propagate result commands outside the component
    this.coreSearchService.resultCommand$.subscribe(ev => this.resultCommand.emit(ev));
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Retrieve start command
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.subscription))
      .subscribe(params => {
        if (!this.mustSearchStart) {
          this.mustSearchStart = params.start;
        }
        this.selectTabFromGenericResults(params.tabId);
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: { start: undefined },
            queryParamsHandling: 'merge'
          }
        );
      });
  }

  ngAfterViewInit(): void {
    if (this.mustSearchStart && this.coreSearchService) {
      this.search();
    }
  }

  search() {
    this.coreSearchService.searchCommand$.next({
      resetPaging: true,
      tabIdentifier: this.hasTabs && Array.isArray(this.config) ? this.config.at(this.selectedTab)?.identifier : undefined
    });
  }

  /**
   * Resets all tabs results
   *
   * @memberof EidosSearchComponent
   */
  resetResults() {
    this.coreSearchService.emptyResultsCommand$.next();
  }

  private previousTab: any;

  /**
   * Select a tab (Generic search tab selection handler)
   *
   * @param {string} productCod
   * @memberof EidosSearchComponent
   */
  public selectTabFromGenericResults(tabIdentifier: string) {
    if (this.hasTabs) {
      if (!!this.TabUrlSetted) {
        const tabToSelectIndex = (<Array<C>>this.config!).findIndex(item => item.identifier === this.TabUrlSetted);
        this.previousTab = (<Array<C>>this.config!).at(this.selectedTab);
        if (tabToSelectIndex !== -1) {
          this.selectedTab = tabToSelectIndex;
          this.updateTabQueryParams(tabIdentifier);
        }
      } else {
        const tabToSelectIndex = (<Array<C>>this.config!).findIndex(item => item.identifier === tabIdentifier);
        this.previousTab = (<Array<C>>this.config!).at(this.selectedTab);
        if (tabToSelectIndex !== -1) {
          this.selectedTab = tabToSelectIndex;
          this.updateTabQueryParams(tabIdentifier);
        }
      }

    }

  }
  /**
   * Handle a tab selection
   *
   * @param {{addedItems: ICoreSearchTabConfiguration}} event
   * @memberof EidosSearchComponent
   */
  public selectTab(event: { addedItems: ICoreSearchTabConfiguration }) {
    const tabToSelectIndex = (<Array<C>>this.config!).at(this.selectedTab);
    if (tabToSelectIndex?.identifier) {
      if (this.RecalculateFilter) this.recalculateFilters(tabToSelectIndex, event);
      this.updateTabQueryParams(tabToSelectIndex.identifier);
    }
  }

  /**
   * Update the current tab query params
   *
   * @private
   * @param {string} tabIdentifier
   * @memberof EidosSearchComponent
   */
  private updateTabQueryParams(tabIdentifier: string) {
    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: { tabId: tabIdentifier },
        queryParamsHandling: 'merge'
      }
    );
  }

  /**
   * Resets the filters
   *
   * @memberof EidosSearchComponent
   */
  public resetFilters() {
    this.onResetFilters.emit();
  }

  public fieldD: { [key: string]: any[] } = {}
  public recalculateFilters(tab: any, event: any) {

    let _tmpKey: string[] = [];
    let _newFilters: string[] = [];

    this.previousTab.filtersConfig.forEach((el: any) => {
      _tmpKey.push(el.name);
    })

    if (event.removedItems.length > 0) {

      _newFilters = Object.keys(this.filters);

      _tmpKey.forEach((el: string) => {
        let index = _newFilters.indexOf(el);
        _newFilters.splice(index, 1);
      });

      _newFilters.forEach((element: string) => {
        delete this.filters[element];
      });

      if (this.fieldD[event.removedItems[0].title] !== undefined) this.fieldD[event.removedItems[0].title] = [];

      this.fieldD[event.removedItems[0].title] = this.filters;
      this.filters = {};
    }

    if (event.addedItems.length > 0) {
      if (this.fieldD[event.addedItems[0].title] !== undefined) {
        this.filters = {};
        this.filters = this.fieldD[event.addedItems[0].title];
      } else {
        this.filters = {};
        if (tab.filtersConfig.length > 0) {
          tab.filtersConfig.forEach((el: any) => {
            if (!!el.defaultValue) {
              let dt: any = {};
              dt[el.name] = el.defaultValue;
              this.filters = dt;
            }
          })
        }
      }
    }

    this.previousTab = (<Array<C>>this.config!).at(this.selectedTab);
  }

  public _onButtonClick(event: any) {
    this.onButtonClick.emit(event);
  }

  public _onContentReady(event: any) {
    this.onContentReadyGrid.emit(event);
  }
}
