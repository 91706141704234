<div class="login-wrapper">
  <img *ngIf="backgroundImagePath" class="app-background" [src]="backgroundImagePath">
  <div class="login-container">
    <div class="login-header">
      <eidos-icon id="mainLogo" class="app-logo" [iconConfiguration]="logo"></eidos-icon>
    </div>
    <mat-card class="auth-block">
      <mat-card-content [ngSwitch]="status">
        <content *ngSwitchCase="EidosResetCredentialsStatus.Entered">
          <p class="reset-credentials-message">{{ 'reset_credentials_confirmation.redirect' | transloco: { timeout: timeout } }}</p>
        </content>
        <content *ngSwitchCase="EidosResetCredentialsStatus.Requested">
          <div class="reset-credentials-icon">
            <mat-spinner class="request-spinner" style="margin:0 auto;"></mat-spinner>
          </div>
          <p class="reset-credentials-message">{{ 'reset_credentials_confirmation.submitting' | transloco }}</p>
        </content>
        <content *ngSwitchCase="EidosResetCredentialsStatus.Ok">
          <div class="reset-credentials-icon">
            <eidos-icon [iconConfiguration]="okIcon"></eidos-icon>
          </div>
          <p class="reset-credentials-message">{{ 'reset_credentials_confirmation.confirmed' | transloco }}</p>
          <button mat-raised-button color="primary" (click)="goToLogin()">{{ 'reset_credentials_confirmation.go_login' | transloco }}</button>
        </content>
        <content *ngSwitchCase="EidosResetCredentialsStatus.Error">
          <div class="reset-credentials-icon">
            <eidos-icon [iconConfiguration]="clearIcon"></eidos-icon>
          </div>
          <p class="reset-credentials-message">{{ 'reset_credentials_confirmation.error' | transloco }} {{ 'reset_credentials_confirmation.redirect' | transloco: { timeout: timeout } }}</p>
        </content>
      </mat-card-content>
    </mat-card>
  </div>
</div>