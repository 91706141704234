import { Component, OnInit } from '@angular/core';

import { EidosFieldBaseComponent } from '@eidos/components/fields/eidos-field-base/eidos-field-base.component';

@Component({
  selector: 'eidos-field-bit',
  templateUrl: './eidos-field-bit.component.html',
  styleUrls: ['./eidos-field-bit.component.scss']
})
export class EidosFieldBitComponent extends EidosFieldBaseComponent implements OnInit {

  constructor() {
    super()
  }

  ngOnInit(): void {
  }
}
