import { DateTime } from "luxon";
import { IEidosIcon } from "../config/environment.interface";
import { BaseObject } from "./base-object.models";
import { EidosAuthType } from "./core-constant.model";
import { IEidosDashboardCell, IEidosDashboardConfig, IEidosDashbordCellObjectGridOptions } from "@common/models/eidos-dashboard.interface";
import { IEidosExternalApp } from "./eidos-external-app.interface";
import { EidosObjectOptions } from "@common/models/eidos-object.interface";
import { EidosObjectActionType, EidosObjectMode } from "@common/models/eidos-object.model";
import { IUserDynamicParameters, IUserAvatar, IUserInfo, IUserPasswordRequirements, IUserCustomThemeColor, IMyBizUserInfo, IMyBizUserInfoParameter } from "./user-info.interface";

/**
 * Eidos main user object
 *
 * @export
 * @class EidosUser
 * @extends {BaseObject}
 * @implements {IUserInfo}
 */
export class EidosUser extends BaseObject implements IUserInfo {
  /**
   * Validity flag
   *
   * @memberof EidosUser
   */
  isValid = false;
  /**
   * Username
   *
   * @memberof EidosUser
   */
  username = '';
  /**
   * User display name
   *
   * @memberof UserInfo
   */
  display_name = '';
  /**
   * User's company name
   *
   * @memberof UserInfo
   */
  nome_azienda = '';
  authType = EidosAuthType.Form;
  /**
   * Admin privileges flag
   *
   * @memberof EidosUser
   */
  isAdmin = false;
  /**
   * Developer privileges flag
   *
   * @memberof EidosUser
   */
  isDeveloper = false;
  isImpersonated = false;
  canEditLayout = false;
  canImpersonate = false;
  token = '';
  gridStripedEven:any;
  gridStripedOdd:any;

  language?: string;
  company = '';
  /**
   * Company logo image code
   *
   * @memberof UserInfo
   */
  companyLogo = '';
  organization = '';
  thousandSeparator = '.';
  decimalSeparator = ',';
  dateFormat = 'dd-MM-yyyy';
  detailsRowCount = 10;
  landingObject = 0;
  useCustomTheme = false;
  customThemeRetro = false;
  /**
   * User must change password (open change password modal)
   *
   * @memberof UserInfo
   */
  mustChangePassword = false;
  /**
   * Default menu pinned setting
   *
   * @memberof UserInfo
   */
  menuPinned = false;
  /**
   * User's custom colors
   *
   * @type {IUserCustomThemeColor}
   * @memberof EidosUser
   */
  customThemeColor: IUserCustomThemeColor = {
    primary: { background: 'white', foreground: 'black' },
    secondary: { background: 'white', foreground: 'black' },
  };
  /**
   * User's avater
   *
   * @type {IUserAvatar}
   * @memberof EidosUser
   */
  avatar: IUserAvatar = {
    badgeColorPrimary: "",
    badgeColorSecondary: "",
    badgeSign: "",
    isAdmin: false,
    isDeveloper: false,
    isImpersonated: false
  }
  /**
   * User's dashboard
   *
   * @type {IEidosDashboardConfig}
   * @memberof EidosUser
   */
  dashboard: IEidosDashboardConfig = {
    enabled: false,
    columns: 10,
    rows: 10,
    columnsGap: 0,
    rowsGap: 0,
    eidosObject: []
  }
  /**
   * User's external apps configuration
   *
   * @type {Array<IEidosExternalApp>}
   * @memberof EidosUser
   */
  externalApps: Array<IEidosExternalApp> = [];
  /**
   * User's dynamic parameters
   *
   * @type {Array<IUserDynamicParameters>}
   * @memberof EidosUser
   */
  otherParameters: Array<IUserDynamicParameters> = [];
  /**
   * User's password requirements
   *
   * @type {IUserPasswordRequirements}
   * @memberof EidosUser
   */
  passwordRequirements: IUserPasswordRequirements = {
    minPasswordLength: 0,
    minPasswordLowerCaseChars: 0,
    minPasswordUpperCaseChars: 0,
    minPasswordSpecialChars: 0,
    minPasswordNumericalChars: 0
  }

  constructor(initdata?: IMyBizUserInfo) {
    super();
    if (initdata) {
      this.updateData(initdata);
    }
  }

  updateData(data: IMyBizUserInfo) {
    this.isValid = true;
    this.addPropertyAutoBinding(data, this);

    this.isAdmin = !!data.is_admin;
    this.isDeveloper = !!data.is_developer;
    this.isImpersonated = !!data.is_impersonated;
    this.mustChangePassword = !!data.must_change_password;
    this.canEditLayout = !!data.can_edit_layout;
    this.canImpersonate = !!data.can_impersonate;

    this.menuPinned = data.menu_pinned;
    this.companyLogo = data.menu_logo;

    this.otherParameters = [];

    // Set custom theme colors
    this.customThemeColor = {
      primary: { background: 'white', foreground: 'black' },
      secondary: { background: 'white', foreground: 'black' },
    }

    // Build the user avatar
    this.avatar = {
      badgeColorPrimary: data.icon_color_background,
      badgeColorSecondary: data.icon_color_foreground,
      badgeSign: this.getSign(),
      isAdmin: this.isAdmin,
      isDeveloper: this.isDeveloper,
      isImpersonated: this.isImpersonated,
      icon: data.immagine_cod
    };

    if (Array.isArray(data.extapps)) {
      this.externalApps = data.extapps;
    }

    this.passwordRequirements = {
      minPasswordLength: data.password_min_length,
      minPasswordLowerCaseChars: data.password_nr_lower_case,
      minPasswordUpperCaseChars: data.password_nr_upper_case,
      minPasswordSpecialChars: data.password_nr_special_char,
      minPasswordNumericalChars: data.password_nr_numerical
    }

    this.buildDashboard(data.dashboard);
    this.getParameters(data.parametri);
    this.getParameters(data.parametri_globali);
  }

  private getMode(apiMode: string): EidosObjectMode {
    const m = apiMode.charAt(0).toUpperCase() + apiMode.slice(1)
    return EidosObjectMode[m as keyof typeof EidosObjectMode];
  }

  /**
   * Returns options for Eidos object in dashboard cell
   *
   * @private
   * @param {(string | null)} options
   * @return {*}  {EidosObjectOptions}
   * @memberof EidosUser
   */
  private getOptions(options: string | null): EidosObjectOptions {
    if (!!options) {

      // Validate valid options with a custom type guard
      const isValidOptions = function (o: any): o is IEidosDashbordCellObjectGridOptions {
        return "hidePager" in o || "hideScrollbar" in o || "hideHeader" in o;
      }

      try {
        const optJson = JSON.parse(options);
        return isValidOptions(optJson) ? optJson : options;
      } catch (e: any) {
        return options;
      }

    } else {
      return options;
    }
  }

  /**
   * Build the user dashboard
   *
   * @private
   * @param {*} data
   * @memberof UserInfo
   */
  private buildDashboard(data: any): void {
    this.dashboard = {
      enabled: false,
      rows: 10,
      columns: 10,
      columnsGap: 0,
      rowsGap: 0,
      eidosObject: []
    };

    if (data) {
      this.dashboard = {
        enabled: !!data.enabled,
        rows: data.rows ?? 10,
        columns: data.columns ?? 10,
        rowsGap: data.vGap ?? 0,
        columnsGap: data.hGap ?? 0,
        eidosObject: data.cells?.map((c: IEidosDashboardCell) => (
          {
            objectId: c.object_id,
            column: c.column,
            row: c.row,
            columnSize: c.columnSize,
            rowSize: c.rowSize,
            pageSize: c.pageSize,
            mode: this.getMode(c.mode),
            icon: (c.icon ? {
              iconType: c.icon.iconType,
              iconCode: c.icon.iconCode,
              iconValue: c.icon.iconValue,
              iconUrl: c.icon.iconUrl,
              inverseColor: c.icon.inverseColor,
              iconSize: c.iconSize ?? 1
            } as IEidosIcon : null),
            foregroundColor: c.foregroundColor ?? 'black',
            backgroundColor: c.backgroundColor ?? 'white',
            borderRadius: c.borderRadius ?? '0',
            objectType: c.object_type as EidosObjectActionType,
            options: this.getOptions(c.options),
            label: c.label,
            tooltip: c.tooltip
          }) ?? []
        )
      };
    }
  }

  /**
   * Get user signature for his avatar
   *
   * @private
   * @return {*} {string}
   * @memberof UserInfo
   */
  private getSign(): string {
    const sign = this.display_name.split(' ').reduce((previousValue: string, _: string, i: number, parts: string[]) =>
      previousValue += parts[i].charAt(0).toUpperCase(), "");
    // Eventually truncate sign
    return sign.substr(0, 3) ?? "??";
  }

  /**
   * Map API response parameters to user obj
   *
   * @private
   * @param {Array<IMyBizUserInfoParameter>} parameters
   * @memberof EidosUser
   */
  private getParameters(parameters: Array<IMyBizUserInfoParameter>): void {
    parameters.forEach(
      p => {
        switch (p.cod) {

          case 'date_format':
            this.dateFormat = p.valore_string;
            break;

          case 'decimal_separator':
            this.decimalSeparator = p.valore_string;
            break;

          case 'details_rows':
            this.detailsRowCount = +p.valore_num;
            break;

          case 'landing_page':
            this.landingObject = +p.valore_num;
            break;

          case 'lingua_corrente':
            this.language = p.valore_string;
            break;

          case 'azienda_corrente':
            this.company = p.valore_string;
            break;

          case 'organization':
            this.organization = p.valore_string;
            break;

          case 'menu_locked':
            this.menuPinned = this.menuPinned === undefined ? (+p.valore_num === 1) : this.menuPinned;
            break;

          case 'thousands_separator':
            this.thousandSeparator = p.valore_string;
            break;

          case 'grid_striped_thema':
            const gridStripedParts = p.valore_string.split(',');
            this.gridStripedEven = {
              background: gridStripedParts[0],
              foreground: gridStripedParts[1]
            };
            this.gridStripedOdd = {
              background: gridStripedParts[2],
              foreground: gridStripedParts[3]
            };
            break;

          case 'custom_theme':
            this.useCustomTheme = +p.valore_num === 1;
            break;

          case 'color_fg_primary':
            this.customThemeColor.primary.foreground = p.valore_string;
            document.documentElement.style.setProperty('--primary-color', this.customThemeColor.primary.foreground);
            break;

          case 'color_bg_primary':
            this.customThemeColor.primary.background = p.valore_string;
            document.documentElement.style.setProperty('--primary-background-color', this.customThemeColor.primary.background);
            break;

          case 'color_fg_secondary':
            this.customThemeColor.secondary.foreground = p.valore_string;
            document.documentElement.style.setProperty('--secondary-color', this.customThemeColor.secondary.foreground);
            break;

          case 'color_bg_secondary':
            this.customThemeColor.secondary.background = p.valore_string;
            document.documentElement.style.setProperty('--secondary-background-color', this.customThemeColor.secondary.background);
            break;

          default:
            let x: IUserDynamicParameters = {
              code: p.cod,
              value: null,
              isForCompany: false,
              isSystems: false,
              label: p.label,
              readonly: false
            }
            // Then a default case for unknown ones (tbh we'd better drop them or even better map them all and throw an error if we get here...)
            switch (p.tipo.toLowerCase()) {
              case 'n':
                x.value = +p.valore_num;
                break;
              case 's':
                x.value = +p.valore_string;
                break;
              case 'd':
                x.value = DateTime.fromISO(p.valore_date).toJSDate();
                break;
            }
            this.otherParameters.push(x);
        }
      }
    )
  }
}
