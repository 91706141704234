
<content class="h-100" *ngIf="config; else missingConfigTemplate">

  <content class="eidos-search-tab-container h-100"
    *ngIf="searchService; else missingSearchServiceTemplate">

    <div class="eidos-search-filters-container"
      [id]="cssId" [ngClass]="cssClass"
      *ngIf="upperFilters.length > 0">
      <eidos-filter-form #eidosFilterForm
        [(filters)]="filters"
        [filtersConfiguration]="upperFilters"
        [filterTemplate]="filterTemplate"
        [commandsSettings]="config.commandsSettings"
        [searchService]="searchService"
        [filterCategoryBtnText]="BtnName"
        [filterCategoryBtnIcon]="BtnIcon"
        [BtnEntity]="BtnEntity"
        (onSearch)="search({resetPaging: true})"
        (onResetFilters)="resetFilters()"
        (onEnableMultiselect)="multiselectEnabled = $event"
        (onSubmitMultiselect)="onMultiResultsSubmit()"
        (onFilterCategoryBtnClick)="handleFilterCategoryBtnClick($event)"
      >
        <eidos-search-paginator *ngIf="!config.isSidebarSearch && searchPagingConfiguration.enabled" class="d-flex justify-content-end col"
          [coreSearchService]="coreSearchService" [(currentResultsView)]="currentResultsView"
          [resultsViews]="config.resultsConfig">
        </eidos-search-paginator>
      </eidos-filter-form>


    </div>

    <dx-drawer
      *ngIf="config.isSidebarSearch; else standardSearchTemplate"
      openedStateMode="shrink"
      class="eidos-search-drawer"
      position="left"
      revealMode="slide"
      template="templateDrawer"
      [opened]="true"
    >

      <div *dxTemplate="let data of 'templateDrawer'" style="min-width: 300px; max-width: 400px; padding: 10px; display: flex; flex-direction: column; justify-content: flex-start; gap: 5px">
        <eidos-search-paginator *ngIf="searchPagingConfiguration.enabled"
          [coreSearchService]="coreSearchService"
          [isSidebarSearch]="true"
          [(currentResultsView)]="currentResultsView"
          [resultsViews]="config.resultsConfig">
        </eidos-search-paginator>
        <eidos-search-sidebar-filters #eidosSidebarFilterForm
          [(filters)]="filters"
          [filtersConfiguration]="sidebarFilters"
          [filterTemplate]="filterTemplate"
          [searchService]="searchService"
          (onSearch)="search({resetPaging: true})"
          (onResetFilters)="resetFilters()"
        >
        </eidos-search-sidebar-filters>
        <div class="eidos-search-results-container-cmd-copy">
          <eidos-filter-form-commands
            (onSubmit)="search({resetPaging: true})"
            (onClear)="resetFilters()">
          </eidos-filter-form-commands>
        </div>
      </div>
      <div class="h-100 eidos-search-drawer-content">
        <div class="eidos-search-results-container-wrapper h-100">
          <div class="eidos-search-results-container h-100" *ngIf="results != undefined && !loading">
            <ng-container [ngTemplateOutlet]="standardSearchResultsTemplate"></ng-container>
          </div>
        </div>
      </div>
    </dx-drawer>

    <ng-template #standardSearchTemplate>
      <div class="eidos-search-results-container-wrapper d-flex flex-column">
        <div class="eidos-search-results-container flex-grow-1 overflow-hidden" *ngIf="results != undefined && !loading && config.searchAPI != ''">
          <ng-container [ngTemplateOutlet]="standardSearchResultsTemplate"></ng-container>
        </div>

        <eidos-button-list
          class="d-inline-block mt-3"
          [buttons]="buttons"
          *ngIf="buttons.length > 0"
          (onButtonClick)="_OnButtonClick($event)">
        </eidos-button-list>

        <dx-popup [(visible)]="PopupVisible">
          <!-- ci metto dentro qualcosa -->
        </dx-popup>
      </div>
    </ng-template>

    <eidos-progress-spinner *ngIf="loading"></eidos-progress-spinner>

    <ng-template #standardSearchResultsTemplate>
      <content *ngIf="results != undefined">
        <ng-container
          [ngTemplateOutlet]="config.isGenericTab ? genericSearchResultsTemplate : searchResultsTemplate">
        </ng-container>

      </content>

      <ng-template #genericSearchResultsTemplate>
        <eidos-generic-search-results #resultsCmp
          [coreSearchService]="coreSearchService"
          [resultSpecificMap]="config.resultSpecificMap"
          [genericTabConfig]="genericTabConfig"
          [results]="results!"
          [config]="config.resultsConfig"
          [resultIdMap]="config.resultIdMap"
          (selectTab)="selectTab.emit($event)"
          [isResultCheckable]="isResultCheckable"
          [currentResultsView]="currentResultsView"
          (onResultsSelected)="onResultsSelected.emit($event)">
        </eidos-generic-search-results>
      </ng-template>

      <ng-template #searchResultsTemplate>
        <eidos-search-results #resultsCmp
          class="h-100"
          [coreSearchService]="coreSearchService"
          [configFull]="config"
          [results]="results!"
          [config]="config.resultsConfig"
          [multiselectEnabled]="multiselectEnabled"
          [resultIdMap]="config.resultIdMap"
          [isResultCheckable]="isResultCheckable"
          [currentResultsView]="currentResultsView"
          (onResultsSelected)="onResultsSelected.emit($event)">
        </eidos-search-results>
      </ng-template>
    </ng-template>

  </content>
  <ng-template #missingSearchServiceTemplate>Missing search service configuration!</ng-template>

  <content *ngFor="let resultConfig of config.resultsConfig">
    <ng-template
      id="template_{{ config.identifier }}_{{ resultConfig.id }}"
      *ngIf="resultConfig.customTemplate"
      [innerHTML]="resultConfig.customTemplate"
    ></ng-template>
  </content>
</content>
<ng-template #missingConfigTemplate>Missing search tab configuration!</ng-template>
