import { BaseObject, SafeBaseObject } from '@common/models/base-object.models'
import { DateTime } from 'luxon'

import { ReservationApiBoolean } from '../../res-common/models/res-constant.model'
import { AppModule } from '@app/app.module'
import { CoreFormatService } from '@app/core/services/core-format.service'

export class ReservationCommission extends BaseObject {
	bkgID?: number
	commission?: number
	commissionType?: string
	commissioneTypeID?: number
	cruiseCommissions?: ReservationCruiseCommission[]
	othersCommissions?: ReservationOtherCommission[]
	valueType?: 'Amount' | 'Percentage'

	get vatCommission(): number {
		return (this.cruiseCommissions ?? [])?.map(c => c.vatCommission ?? 0).reduce((acc, curr,) => curr + acc, 0) + (this.othersCommissions ?? [])?.map(c => c.vatCommission ?? 0).reduce((acc, curr) => curr + acc, 0);
	}

	get netCommission(): number {
		return (this.cruiseCommissions ?? [])?.map(c => c.netCommission ?? 0).reduce((acc, curr,) => curr + acc, 0) + (this.othersCommissions ?? [])?.map(c => c.netCommission ?? 0).reduce((acc, curr) => curr + acc, 0);
	}

	get sBC(): number {
		return this.cruiseCommissions && this.cruiseCommissions[0] ? this.cruiseCommissions[0].sBC! : 0;
	}

	constructor(data: any) {
		super(data);
	}

	override updateData(data: any): void {
		this.addMangledProperty(data);
		this.cruiseCommissions = data.CruiseCommissions?.map((c: any) => new ReservationCruiseCommission(c))
		this.othersCommissions = data.OthersCommissions?.map((c: any) => new ReservationOtherCommission(c))
	}

	isCruiseCommission(): boolean {
		return this.commissionType?.toLowerCase() === 'cruise' || this.commissionType?.toLowerCase() === 'bonus commission';
	}

	isManualCommission(): boolean {
		return this.commissionType?.toLowerCase() === 'manual';
	}

	hasDetails(): boolean {
		return !!this.cruiseCommissions?.length || !!this.othersCommissions?.length
	}
}

class ReservationCruiseCommission extends BaseObject {
	bkgID?: number
	commission?: number
	commissionBaseAmount?: number
	commissionTypeID?: number
	isGty?: boolean
	nCF?: number
	portCharge?: number
	priceCategoryID?: number
	sectionID?: number
	sellingPrice?: number
	sellingPriceCurrency?: string
	serviceTypeName?: string
	suiteCapacityID?: number
	suiteCategoryID?: number
	suiteID?: number
	tax?: number
	travelEndDate?: DateTime
	travelStartDate?: DateTime
	totalCruiseDiscountAmount?: number
	voyageID?: number
	vatCommission?: number;
	netCommission?: number;
	sBC?: number;


	constructor(data: IReservationApiCruiseCommission) {
		super(data)
	}

	override updateData(data: IReservationApiCruiseCommission): void {
		this.addMangledProperty(data)
		this.addBooleanProperty('isGty', data, 'IsGty')
		this.addDateTimeProperty('travelStartDate', data, 'TravelStartDate')
		this.addDateTimeProperty('travelEndDate', data, 'TravelEndDate')
	}
}

interface IReservationApiCruiseCommission {
	BkgID?: number
	Commission?: number
	CommissionBaseAmount?: number
	CommissionPercentage: number
	CommissionTypeID?: number
	IsGty?: string
	NCF?: number
	PortCharge?: number
	PriceCategoryID?: number
	SectionID?: number
	SellingPrice?: number
	SellingPriceCurrency?: string
	ServiceTypeName?: string
	SuiteCapacityID?: number
	SuiteCategoryID?: number
	SuiteID?: number
	Tax?: number
	TravelEndDate?: string
	TravelStartDate?: string
	TotalCruiseDiscountAmount?: number
	VoyageID?: number
	VatCommission?: number;
	NetCommission?: number;
	SBC?: number;
}

class ReservationOtherCommission extends BaseObject {
	bkgID?: number
	commission?: number
	commissionTypeID?: number
	commissionPercentage?: number
	cost?: number
	guestCod?: number
	itineraryDate?: DateTime
	optionID?: number
	optionName?: string
	sectionID?: number
	sellingPrice?: number
	sellingPriceCurrency?: string
	sellingPriceTax?: number
	serviceID?: number
	serviceName?: string
	serviceTypeID?: number
	serviceTypeName?: string
	vatCommission?: number;
	netCommission?: number;

	constructor(data: IReservationApiOtherCommission) {
		super(data)
	}

	override updateData(data: IReservationApiOtherCommission): void {
		this.addMangledProperty(data)
		this.addDateTimeProperty('ItineraryDate', data, 'ItineraryDate')
	}
}

interface IReservationApiOtherCommission {
	BkgID?: number
	Commission?: number
	CommissionTypeID?: number
	Cost?: number
	GuestCod?: number
	ItineraryDate?: string
	OptionID?: number
	OptionName?: string
	SectionID?: number
	SellingPrice?: number
	SellingPriceCurrency?: string
	SellingPriceTax?: number
	ServiceID?: number
	ServiceName?: string
	ServiceTypeID?: number
	ServiceTypeName?: string
	VatCommission?: number;
	NetCommission?: number;
	CommissionPercentage: number;
}

export class ResCommissionPayout extends SafeBaseObject {
	bkgID: number = -1;
	bkgStatus: string = '';
	groupID?: number = 0;
	agencyCod: string = '';
	agencyID: number = -1;
	agencyName: string = '';
	verified: boolean = false;
	prePaidAgency: boolean = false;
	voyageNumber: string = '';
	embarkDate: string = '';
	totalBookingAmount: number = 0;
	totalCommission?: number = 0;
	totalPaid?: number = 0;
	commission?: number = 0;
	commissionPaid: number = 0;
	commissionPayable: number = 0;
	bkgCurrency: string = '';
	batchStatus: string = '';
	timeStamp: string = '';
	invoice: boolean = false;
	multiMonth: boolean = false;
	guest: string = '';
	commissionPayoutDetID?: number;
	unlocked: boolean = false;
	displayResendFinance: boolean = false
	displayUploadPayout: boolean = false
	displaySendToBC: boolean = false
	displaySelfInvoice: boolean = false
	displayUnlock: boolean = false
	displayComplete: boolean = false;
	displayCheckBoxBC: boolean = false;
	totalCommissionPayable?: number = 0;

	constructor(data?: IResApiCommissionPayout, displayFlags?: {
		DisplayResendFinance: ReservationApiBoolean
		DisplayUploadPayout: ReservationApiBoolean
		DisplaySendToBC: ReservationApiBoolean
		DisplaySelfInvoice: ReservationApiBoolean
		DisplayUnlock: ReservationApiBoolean
		DisplayComplete: ReservationApiBoolean
		DisplayCheckBoxBC: ReservationApiBoolean
		TotalCommissionPayable?: number
	}) {
		super();
		data && this.updateData(data, displayFlags);
	}

	override updateData(data: IResApiCommissionPayout, displayFlags?: {
		DisplayResendFinance: ReservationApiBoolean
		DisplayUploadPayout: ReservationApiBoolean
		DisplaySendToBC: ReservationApiBoolean
		DisplaySelfInvoice: ReservationApiBoolean
		DisplayUnlock: ReservationApiBoolean
		DisplayComplete: ReservationApiBoolean
		DisplayCheckBoxBC: ReservationApiBoolean
		TotalCommissionPayable?: number
	}): void {

		super.updateData(data);
		const coreFormatService = AppModule.injector.get(CoreFormatService);
		const embarkDate = DateTime.fromISO(data.EmbarkDate);
		if (embarkDate.isValid) {
			this.embarkDate = embarkDate.toFormat(
				[embarkDate.hour, embarkDate.minute, embarkDate.second].some(n => n !== 0) ?
					coreFormatService.DateFmtWithMonthNameAndTime() : coreFormatService.DateFmtWithMonthName()
			)
		}

		const timestamp = DateTime.fromISO(data.TimeStamp);
		if (timestamp.isValid) {
			this.timeStamp = timestamp.toFormat(
				[timestamp.hour, timestamp.minute, timestamp.second].some(n => n !== 0) ?
					coreFormatService.DateFmtWithMonthNameAndTime() : coreFormatService.DateFmtWithMonthName()
			)
		}

		if (displayFlags) {
			this.addBooleanProperty('displayResendFinance', displayFlags, 'DisplayResendFinance');
			this.addBooleanProperty('displayUploadPayout', displayFlags, 'DisplayUploadPayout');
			this.addBooleanProperty('displaySendToBC', displayFlags, 'DisplaySendToBC');
			this.addBooleanProperty('displaySelfInvoice', displayFlags, 'DisplaySelfInvoice');
			this.addBooleanProperty('displayUnlock', displayFlags, 'DisplayUnlock');
			this.addBooleanProperty('displayComplete', displayFlags, 'DisplayComplete');
			this.addBooleanProperty('displayCheckBoxBC', displayFlags, 'DisplayCheckBoxBC');
			this.totalCommissionPayable = displayFlags.TotalCommissionPayable;
		}
	}
}

export interface IResApiCommissionPayout {
	BkgID: number;
	AgencyCod: number;
	AgencyID: number;
	AgencyName: string;
	BatchStatus: string;
	BkgCurrency: string;
	BkgStatus: string;
	Commission?: number;
	CommissionPaid?: number;
	CommissionPayable?: number;
	CommissionPayoutDetID?: number;
	EmbarkDate: string;
	GroupID?: number;
	Guest: string;
	Invoice: ReservationApiBoolean;
	MultiMonth: ReservationApiBoolean;
	PrePaidAgency: ReservationApiBoolean;
	Verified: string;
	TimeStamp: string;
	TotalBookingAmount: number;
	TotalPaid: number;
	Unlocked: ReservationApiBoolean;
	VoyageNumber: string;
}

export enum ResCommissionPayoutBatchStatus {
	BatchCreated = 'batch created',
	SentToFinance = 'sent to finance',
	PaymentUploaded = 'payment uploaded',
	PaymentSentToBC = 'payment sent to bc',
	Completed = 'completed',
}

export class ResCommissionPayoutBatch extends SafeBaseObject {
	batchID: number = -1;
	canDelete: boolean = false;
	batchName: string = '';
	commissionTypeDesc: string = '';
	description: string = '';
	groupID?: number;
	created: string = '';
	createdBy: string = '';
	batchStatus: string = '';

	constructor(data?: IResApiCommissionPayoutBatch) {
		super()
		data && this.updateData(data);
	}

	override updateData(data?: IResApiCommissionPayoutBatch): void {
		if (data) {
			const coreFormatService = AppModule.injector.get(CoreFormatService);
			super.updateData(data);
			const createdDate = DateTime.fromISO(data.Created);
			if (createdDate.isValid) {
				this.created = createdDate.toFormat(
					[createdDate.hour, createdDate.minute, createdDate.second].some(n => n !== 0) ?
						coreFormatService.DateFmtWithMonthNameAndTime() : coreFormatService.DateFmtWithMonthName()
				)
			}
		}
	}

	get isCreated(): boolean {
		return this.batchStatus?.toLowerCase() === ResCommissionPayoutBatchStatus.BatchCreated.toLowerCase()
	}
	get isSentToFinance(): boolean {
		return this.batchStatus?.toLowerCase() === ResCommissionPayoutBatchStatus.SentToFinance.toLowerCase()
	}
	get isPaymentUploaded(): boolean {
		return this.batchStatus?.toLowerCase() === ResCommissionPayoutBatchStatus.PaymentUploaded.toLowerCase()
	}
	get isPaymentSentToBC(): boolean {
		return this.batchStatus?.toLowerCase() === ResCommissionPayoutBatchStatus.PaymentSentToBC.toLowerCase()
	}
	get isCompleted(): boolean {
		return this.batchStatus?.toLowerCase() === ResCommissionPayoutBatchStatus.Completed.toLowerCase()
	}
}

export interface IResApiCommissionPayoutBatch {
	BatchID: number;
	CanDelete: ReservationApiBoolean;
	BatchName: string;
	BatchStatus: string;
	CommissionTypeDesc: string;
	Created: string;
	CreatedBy: string;
	Description: string;
	GroupID?: number;
}

export interface IResApiGetCommissionPayoutParams {
	commissionTypeCod?: string
	embarkFrom?: DateTime
	embarkTo?: DateTime
	currencyCod?: string
	agencyCod?: string
	voyageID?: string
	grpID?: number
	bkgStatus?: string
	bkgID?: number,
	companyIDList?: number
}

export interface IResApiCreateCommissionPayoutBatchParams {
	batchName?: string
	commissionTypeCod?: string
	description?: string
	embarkFrom?: DateTime
	embarkTo?: DateTime
	currencyCod?: string
	agencyCod?: string
	voyageID?: string
	grpID?: number
	bkgList?: { bkgID: number; commissionPayable: number }[]
}

export interface IResApiGetCommissionPayoutBatchesParams {
	batchID?: number
	batchName?: string
	commissionTypeCod?: string
	description?: string,
	bkgID?: number,
	created?: DateTime
	createdBy?: string
	grpID?: number
	companyIDList?: number
}

export interface IResApiOpenCommissionPayoutBatchParams {
	BatchID?: number
	// RF+AM 10.10.2023: Rimosso perchè sempre ALL, su richiesta di Carlo
	// AgencyType?: 'ALL' | 'ONLY PAYABLE' | 'ONLY PREPAID'
	AgencyCod?: { agencyCod: string }[];
	BatchStatusID?: number,
	BkgID?: number
	CompanyIDList?: number
}

export interface IResApiManageCommissionPayoutBatchParams {
	batchID?: number
	batchName?: string
	description?: string
	status?: 'A' | 'D'
}

export interface IResApiDeleteCommissionPayoutParams {
	delete?: { commissionPayoutDetID: number }[]
}

export interface IResApiCheckCommissionPayoutBatchParams {
	batchID?: number
}

export interface IResApiSendCommissionPayoutBatchToFinanceParams {
	batchID?: number
}

export interface IResApiSendCommissionPayoutEmailSpecs {
	Body?: string
	BCC?: string
	CC?: string
	DisplayFrom?: string
	DisplayTo?: string
	Subject?: string
	Email?: string
	FileName?: string
}

export interface IResApiUploadCommissionPayoutBatchPaymentParams {
	batchID?: number,
	bkgList?: { bkgID: number }[],
	paymentDate?: DateTime,
	sendInvoice: ReservationApiBoolean,
	sendToBC: ReservationApiBoolean
}

export interface IResApiGenerateCommissionPayoutBatchSelfInvoiceParams {
	batchID?: number,
	sendToBC: ReservationApiBoolean,
	bkgList?: { bkgID: number }[]
}

export interface IResApiUnlockCommissionPayoutBkgsParams {
	batchID?: number,
	bkgList?: { bkgID: number }[]
}

export interface IResApiSendCommissionPayoutBatchToBcParams extends IResApiUnlockCommissionPayoutBkgsParams {
	sendInvoice: ReservationApiBoolean,
}

export type IResApiCommissionPayoutSectionType = 'PayUpl' | 'SelfInv' | 'SendBc' | 'Release' | 'Search'

export interface IResApiGetCommissionPayoutAgenciesParams {
	batchID?: number
	section?: IResApiCommissionPayoutSectionType

}

export interface IResApiGetCommissionPayoutBkgListParams extends IResApiGetCommissionPayoutAgenciesParams {
	agencyCod?: { agencyCod: string }[];
	BkgID: string;
}


export class ResCommissionCompanies extends BaseObject {
	companyID = 0;
	company = '';
	isDefault = false;

	constructor(data?: any) {
		super();
		if (data) this.updateData(data);
	}

	override updateData(data: any): void {
		this.addMangledProperty(data);
	}

}
