import { EventEmitter, Injectable } from '@angular/core';
import { filter, forkJoin, of, take, timeout } from 'rxjs';

import { SecurityCheckRequestType, SessionStorageKeys } from '@common/models/core-constant.model';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { IEidosDataRequestOptions } from '@common/models/eidos-object.interface';
import { EidosObject, EidosObjectApiResponse, EidosObjectConfiguration, EidosObjectData, EidosObjectDataApiResponse, EidosObjectEvent, EidosObjectEventType, EidosDataObjectPagination, EidosObjectEventAction, EidosObjectActionMessage, EidosObjectEventActionRequest } from '@common/models/eidos-object.model';

@Injectable({
  providedIn: 'root'
})
export class EidosObjectService {
  /**
   * Object loaded event emitter
   *
   * @memberof EidosObjectService
   */
  public objectEventEmitter = new EventEmitter<EidosObjectEvent>();
  /**
   * Object loaded event emitter
   *
   * @memberof EidosObjectService
   */
  public objectEventQueryEmitter = new EventEmitter<EidosObjectEvent>();
  /**
   * Object loaded event emitter
   *
   * @memberof EidosObjectService
   */
  public objectEventResponseEmitter = new EventEmitter<EidosObjectEvent>();

  constructor(
    private utilityService: EidosUtilityService,
    private securityService: EidosSecurityService
  ) {
    this.securityService.checkRequest.subscribe(
      check => {
        switch (check) {
          case SecurityCheckRequestType.User:
            const event = new EidosObjectEvent(EidosObjectEventType.ReloadRequired);
            this.objectEventEmitter.emit(event);
            break;
        }
      });
  }

  /**
   * Send query message to object component with optional data end source id
   * and return a observable that emit a response
   *
   * @param {number} tagerId
   * @param {any} data
   * @param {number | undefined} sourceId
   * @memberof EidosObjectService
   */
  public sendQueryEvent(tagerId: number, action: EidosObjectEventAction, request: EidosObjectEventActionRequest, data: any, sourceId?: number): Promise<EidosObjectEvent> {
    const event = new EidosObjectEvent(EidosObjectEventType.QueryMessage, tagerId)
    event.actionMessage = new EidosObjectActionMessage(action, request, tagerId)
    event.actionMessage.data = data;
    event.actionMessage.sourceId = sourceId;
    const self = this;
    return new Promise(function (resolve) {
      self.objectEventResponseEmitter.pipe(timeout(1000), filter(e => e.actionMessage?.guid === event.actionMessage!.guid), take(1)).subscribe(e => {
        resolve(e);
      });
      self.objectEventQueryEmitter.emit(event);
    })
  }
  /**
   * Send response message from object component with optional data end source id
   *
   * @param {number} tagerId
   * @param {any} data
   * @param {number | undefined} sourceId
   * @memberof EidosObjectService
   */
  public sendResponseEvent(queryEvent: EidosObjectEvent, data: any = {}): void {
    if (!queryEvent.actionMessage) return;
    const event = new EidosObjectEvent(EidosObjectEventType.ResponseMessage, queryEvent.actionMessage.sourceId)
    event.actionMessage = new EidosObjectActionMessage(queryEvent.actionMessage.action, EidosObjectEventActionRequest.Unknown, queryEvent.actionMessage.tagetId)
    event.actionMessage.guid = queryEvent.actionMessage.guid;
    event.actionMessage.data = data;
    event.actionMessage.sourceId = queryEvent.actionMessage.tagetId;
    this.objectEventResponseEmitter.emit(event);
  }

  /**
   * Load the component by retreiving object data and config
   *
   * @param {number} eidosObjectId
   * @param {(EidosObject | undefined)} eidosObject
   * @param {boolean} [forceReloading]
   * @memberof EidosObjectService
   */
  public loadComponent(eidosObjectId: number, eidosObject?: EidosObject, forceReloading?: boolean): void {
    if (eidosObject) {
      eidosObject.loading = true;
      const cachedObjectDefinition = this.getCachedObjectDefinition(eidosObject.objectId);
      const eidosObjectConfigurationRetriever = cachedObjectDefinition && !forceReloading ? of(cachedObjectDefinition) : this.utilityService.getObject(eidosObject.objectId);
      const cachedObjectData = cachedObjectDefinition?.baseData.DataObjectId === 0 ? new EidosObjectDataApiResponse() : this.getCachedObjectData(eidosObject.objectId, eidosObject.objectDataPage ?? 1);
      const options: IEidosDataRequestOptions = {
        pagination: (cachedObjectDefinition?.paginationData ?? new EidosDataObjectPagination(1, 0, (eidosObject.pageSize ?? 20), 0)) as EidosDataObjectPagination
      };
      const eidosObjectDataRetriever = cachedObjectData && !forceReloading ? of(cachedObjectData) : this.utilityService.getDataObject(eidosObject.objectId, eidosObject.objectDataPage, options);

      forkJoin([
        eidosObjectConfigurationRetriever,
        eidosObjectDataRetriever
      ]).subscribe({
        next: responseList => {
          const eidosObjectConfiguration = new EidosObjectConfiguration(responseList[0]);
          eidosObjectConfiguration.pageSize = options.pagination.pageSize;
          const eidosObjectData = new EidosObjectData(responseList[1]);
          this.loadComplete(eidosObjectId, eidosObjectConfiguration, eidosObjectData);
          if (!cachedObjectDefinition || !cachedObjectData) {
            this.cacheObjectDefinitionAndData(eidosObject.objectId, responseList[0], responseList[1], eidosObject.objectDataPage ?? 1);
          }
        },
        error: (error) => {
          this.loadError(eidosObjectId, error);
        },
        complete: () => {
          eidosObject.loading = false;
        }
      });
    }
  }
  private loadError(id: number, error: any): void {
    const event = new EidosObjectEvent(EidosObjectEventType.LoadError, id);
    switch (typeof error) {
      default:
        event.message = error.toString();
        break;
    }
    this.objectEventEmitter.emit(event);
  }
  /**
   * Propagates loaded object config and data to components
   *
   * @private
   * @param {number} id
   * @param {EidosObjectConfiguration} config
   * @param {EidosObjectData} data
   * @memberof EidosObjectService
   */
  private loadComplete(id: number, config: EidosObjectConfiguration, data: EidosObjectData): void {
    const event = new EidosObjectEvent(EidosObjectEventType.LoadComplete, id);
    event.config = config;
    event.data = data;
    this.objectEventEmitter.emit(event);
  }
  /**
   * Reload the component by retreiving object data
   *
   * @param {number} eidosObjectId
   * @param {(EidosObject | undefined)} eidosObject
   * @memberof EidosObjectService
   */
  public reloadData(eidosObjectId: number, eidosObject?: EidosObject): void {
    if (eidosObject) {
      eidosObject.loading = true;
      const options: IEidosDataRequestOptions = {
        pagination: new EidosDataObjectPagination(eidosObject.objectDataPage ?? 1, 0, eidosObject.pageSize ?? 20, 0) as EidosDataObjectPagination,
        sort: eidosObject.sort,
        filters: eidosObject.filters
      };
      this.utilityService.getDataObject(eidosObject.objectId, eidosObject.objectDataPage, options)
        .subscribe({
          next: response => {
            const eidosObjectData = new EidosObjectData(response);
            this.reloadComplete(eidosObjectId, eidosObject.objectId, eidosObjectData);
            this.cacheObjectData(eidosObject.objectId, response, eidosObject.objectDataPage);
          },
          complete: () => { eidosObject.loading = false; }
        });
    }
  }
  /**
   * Propagates reloaded object data to components
   *
   * @private
   * @param {number} id
   * @param {EidosObjectData} data
   * @memberof EidosObjectService
   */
  private reloadComplete(eidosObjectId: number, objectId: number, data: EidosObjectData): void {
    const event = new EidosObjectEvent(EidosObjectEventType.ReloadComplete, eidosObjectId);
    const ar = this.getCachedObjectDefinition(objectId);
    if (ar) {
      event.config = new EidosObjectConfiguration(ar);
    }
    event.data = data;
    this.objectEventEmitter.emit(event);
  }
  /**
   * Caches loaded object data and config to prevent further fetches
   *
   * @private
   * @param {number} objectId
   * @param {EidosObjectConfiguration} config
   * @param {EidosObjectData} data
   * @memberof EidosObjectService
   */
  private cacheObjectDefinitionAndData(objectId: number, config: EidosObjectApiResponse, data: EidosObjectDataApiResponse, page: number): void {
    sessionStorage.setItem(SessionStorageKeys.OBJECT_DEFINITION.replace('{id}', objectId.toString()), JSON.stringify(config));
    this.cacheObjectData(objectId, data, page);
  }
  /**
 * Caches loaded object data to prevent further fetches
 *
 * @private
 * @param {number} objectId
 * @param {EidosObjectConfiguration} config
 * @param {EidosObjectData} data
 * @memberof EidosObjectService
 */
  private cacheObjectData(objectId: number, data: EidosObjectDataApiResponse, page: number): void {
    const p = page ?? 1;
    sessionStorage.setItem(SessionStorageKeys.OBJECT_DATA.replace('{id}', objectId.toString()).replace('{page}', p.toString()), JSON.stringify(data));
  }
  /**
   * Retreives cached object definition
   *
   * @private
   * @param {number} objectId
   * @return {*}  {(EidosObjectApiResponse | undefined)}
   * @memberof EidosObjectService
   */
  private getCachedObjectDefinition(objectId: number): EidosObjectApiResponse | undefined {
    const rawDefinition = sessionStorage.getItem(SessionStorageKeys.OBJECT_DEFINITION.replace('{id}', objectId.toString()));
    return rawDefinition ? Object.assign(new EidosObjectApiResponse(), JSON.parse(rawDefinition)) : undefined;
  }
  /**
   * Retreives cached object data
   *
   * @private
   * @param {number} objectId
   * @return {*}  {(EidosObjectDataApiResponse | undefined)}
   * @memberof EidosObjectService
   */
  private getCachedObjectData(objectId: number, page: number): EidosObjectDataApiResponse | undefined {
    const rawData = sessionStorage.getItem(SessionStorageKeys.OBJECT_DATA.replace('{id}', objectId.toString()))?.replace('{page}', page.toString());
    return rawData ? Object.assign(new EidosObjectDataApiResponse(), JSON.parse(rawData)) : undefined;
  }
}
