import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CoreBaseMainPageComponent } from '@common/core-base-main-page.component';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosIcon } from '@common/config/environment.interface';
import { EidosIconType } from '@common/models/core-constant.model';

import { ErrorService } from '@error/services/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends CoreBaseMainPageComponent {

  EidosIconType = EidosIconType;

  public logo?: IEidosIcon;
  public backgroundImage?: string;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    protected coreConfigService: EidosConfigService,
    protected coreUtilityService: EidosUtilityService,
    private errorService: ErrorService
  ) {
    super(coreConfigService, coreUtilityService);
    this.moduleName = "error";

    //Standard SVG Icons
    this.matIconRegistry.addSvgIcon(
      'logo_square_1c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos_logo_square_1colore.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_square_2c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos_logo_square_2colori.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_wide_1c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos_logo_wide_1colore.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_wide_2c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos_logo_wide_2colori.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_4.0_1c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos4.0_logo_wide_1colore.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_4.0n_1c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos4.0new_logo_wide_1colore.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_4.0n_1c_v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos4.0new_logo_wide_1colore_v2.svg')
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.coreConfigService.currentConfig.subscribe(config => {
      this.backgroundImage = config.errorBackground;
      this.logo = config.logo;
    });
  }
  /**
   * Go to return URL handler
   *
   * @memberof ErrorComponent
   */
  public goToReturnUrl(): void {
    window.location.href = this.errorService.returnUrl;
  }
}
