import { Component, EventEmitter, Input, Output } from "@angular/core";
import { debounceTime, Subject } from "rxjs";

import { CoreDrawableComponentInterface } from "../../core-drawable-component.interface";
import { EidosBaseComponent } from "../../eidos-base.component";


/**
 * Eidos input base component:
 *
 * @export
 * @abstract
 * @class EidosInputBaseComponent
 * @extends {EidosBaseComponent}
 * @implements {CoreDrawableComponentInterface}
 * @template T
 */
@Component({
  selector: 'eidos-input-base', template: ''
})
export abstract class EidosInputBaseComponent<T = any> extends EidosBaseComponent implements CoreDrawableComponentInterface {
  /**
   * Show input clear button flag
   *
   * @type {boolean}
   * @memberof EidosInputBaseComponent
   */
  @Input() public showClearButton: boolean = false;
  /**
   * Search enabled flag
   *
   * @type {boolean}
   * @memberof EidosInputBaseComponent
   */
  @Input() public searchEnabled: boolean = true;
  /**
   * Element CSS class
   *
   * @type {string}
   * @memberof EidosInputBaseComponent
   */
  @Input() public cssClass: string = "";
  /**
   * Element ID class
   *
   * @type {string}
   * @memberof EidosInputBaseComponent
   */
  @Input() public cssId: string = "";
  /**
   * Input placeholder
   *
   * @type {string}
   * @memberof EidosInputBaseComponent
   */
  @Input()
  public placeholder?: string;
  /**
   * Input two-way binded value
   *
   * @type {T}
   * @memberof EidosInputBaseComponent
   */
  protected _value: T;
  public get value(): T {
    return this._value;
  }
  @Input() public set value(val: T) {
    this._value = val;
    this.eventuallySetupInternalValue(val);
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<T> = new EventEmitter<T>();
  /**
   * Input two-way binded other value
   *
   * @type {T}
   * @memberof EidosInputBaseComponent
   */
  protected _otherValue: T;
  public get otherValue(): T {
    return this._otherValue;
  }
  @Input() public set otherValue(val: T) {
    this._otherValue = val;
    this.otherValueChange.emit(this._otherValue);
  }
  @Output() public otherValueChange: EventEmitter<T> = new EventEmitter<T>();
  /**
   * Debounce value change flag
   *
   * @private
   * @type {boolean}
   * @memberof EidosInputBaseComponent
   */
  @Input("debounce-change")
  public debounceChange: boolean = false;
  /**
   * Debounce time in ms
   *
   * @private
   * @type {number}
   * @memberof EidosInputBaseComponent
   */
  @Input("debounce-time")
  public debounceTime: number = 0;
  /**
   * Disabled flag
   *
   * @type {boolean}
   * @memberof EidosInputBaseComponent
   */
  @Input()
  public disabled: boolean = false;
  /**
   * Required flag
   *
   * @type {boolean}
   * @memberof EidosInputBaseComponent
   */
  @Input() public required: boolean = false;
  /**
   * Required message
   *
   * @type {string}
   * @memberof EidosInputBaseComponent
   */
  @Input() public requiredMessage?: string = "Field is required";
  /**
   * Devexpress validation group name
   *
   * @type {string}
   * @memberof EidosInputBaseComponent
   */
  @Input() public validationGroupName: string = "validation-group";
  /**
   * Input class in DOM
   *
   * @type {string}
   * @memberof EidosInputBaseComponent
   */
  @Input("input-class")
  public inputClass: string = "";
  /**
   * Input id in DOM
   *
   * @type {string}
   * @memberof EidosInputBaseComponent
   */
  @Input("input-id")
  public inputId: string = "";
  /**
   * On focus in handler
   *
   * @memberof EidosInputBaseComponent
   */
  @Input() onFocusIn?: () => any;
  /**
   * On focus out handler
   *
   * @memberof EidosInputBaseComponent
   */
  @Input() onFocusOut?: () => any;
  /**
   * On value change handler
   *
   * @memberof EidosInputBaseComponent
   */
  @Input() onValueChange?: (component: any, value: any) => any;
  /**
   * Debounce value change subject
   *
   * @private
   * @memberof EidosInputBaseComponent
   */
  private valueChangedSubject = new Subject();
  /**
   * Value changed handler
   *
   * @param {T} event
   * @memberof EidosInputBaseComponent
   */
  public valueChanged(event: T) {
    if (this.debounceChange) {
      this.valueChangedSubject.next(null);
    } else {
      this.valueChange.emit(event);
    }
  }

  /**
   * Debounce other value change subject
   *
   * @private
   * @memberof EidosInputBaseComponent
   */
  private otherValueChangedSubject = new Subject();
  /**
   * Other value changed handler
   *
   * @param {T} event
   * @memberof EidosInputBaseComponent
   */
  public otherValueChanged(event: T) {
    if (this.debounceChange) {
      this.otherValueChangedSubject.next(null);
    } else {
      this.otherValueChange.emit(event);
    }
  }

  /**
   * Set a default value
   *
   * @type {T}
   * @memberof EidosInputBaseComponent
   */
  private _defaultValue: T;
  public get defaultValue(): T {
    return this._defaultValue;
  }
  @Input()
  public set defaultValue(value: T) {
    this._defaultValue = value;

    if (this.value == null) this.value = value;
  }

  /**
   * Key press event emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosInputBaseComponent
   */
  @Output() public keyPress: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Trigger update logic on value change flag
   *
   * @type {boolean}
   * @memberof EidosInputBaseComponent
   */
  @Input() public skipUpdateEnvOnValueChange: boolean = false;

  ngOnInit(): void {

    super.ngOnInit();

    // Subscribe for debounce subject emits
    if (this.debounceChange) {
      this.valueChangedSubject
        .pipe(
          debounceTime(this.debounceTime)
        )
        .subscribe(() => {
          this.valueChange.emit(this.value);
        });

      this.otherValueChangedSubject
        .pipe(
          debounceTime(this.debounceTime)
        )
        .subscribe(() => {
          this.otherValueChange.emit(this.otherValue);
        });
    }
  }

  onSelfChangeValue(val: any) {
    if (this.onValueChange != undefined) {
      this.onValueChange(this, val);
    }
  }

  protected eventuallySetupInternalValue(_: T): void {
  }
}
