<content
  *ngIf="eidosObjectConfiguration && eidosObjectData"
  [ngSwitch]="eidosObjectConfiguration.objectType">

  <!-- <mat-icon class="eidos-object-context-menu">more_vert</mat-icon> -->
  <ng-template [ngSwitchCase]="EidosObjectType.Chart">
    <span class="eidos-object-context-menu">
      <mat-spinner *ngIf="eidosObject?.loading" [diameter]="20"></mat-spinner>
      <eidos-context-menu *ngIf="!eidosObject?.loading" [eidosObjectId]="eidosObjectId" [eidosObject]="eidosObjectConfiguration" [eidosData]="eidosObjectData"></eidos-context-menu>
    </span>
    <eidos-object-chart *ngIf="eidosObjectConfiguration.objectDetails"
      [eidosChartConfiguration]="eidosObjectChartConfiguration"
      [eidosChartData]="eidosObjectDataChartValue"
    >
    </eidos-object-chart>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectType.Html">
    <span class="eidos-object-context-menu">
      <mat-spinner *ngIf="eidosObject?.loading" [diameter]="20"></mat-spinner>
      <eidos-context-menu *ngIf="!eidosObject?.loading" [eidosObjectId]="eidosObjectId" [eidosObject]="eidosObjectConfiguration" [eidosData]="eidosObjectData"></eidos-context-menu>
    </span>
    <eidos-object-html *ngIf="eidosObjectConfiguration.objectDetails"
      [eidosHtmlConfiguration]="eidosObjectHTMLConfiguration"
      [eidosObjectData]="eidosObjectData"
    >
    </eidos-object-html>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectType.Dashboard">
    <eidos-dashboard
      class="dashboard"
      [eidosObjectDashboardConfiguration]="eidosObjectDashboardConfiguration"
      [eidosObjectData]="eidosObjectData"
     >
    </eidos-dashboard>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectType.Grid">
    <eidos-object-grid
    [eidosObject]="eidosObject"
    [eidosObjectConfiguration]="eidosObjectConfiguration"
    [eidosObjectGridConfiguration]="eidosObjectGridConfiguration"
    [eidosObjectData]="eidosObjectData"
     >
    </eidos-object-grid>
  </ng-template>

  <ng-template [ngSwitchDefault]>
    <eidos-object-empty></eidos-object-empty>
  </ng-template>

</content>
