import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { v4 as uuid } from 'uuid';

import { EidosBaseComponent } from '@common/components/eidos-base.component';
import { IEidosGridButtonConfiguration, IEidosGridCallableMethod, IEidosGridColumnConfiguration, IEidosGridConfiguration } from '@common/models/eidos-grid.models';
import _ from 'lodash';

@Component({
  selector: 'eidos-grid',
  templateUrl: './eidos-grid.component.html',
  styleUrls: ['./eidos-grid.component.scss']
})
export class EidosGridComponent<T> extends EidosBaseComponent {
  /**
   * Dx grid reference
   *
   * @type {DxDataGridComponent}
   * @memberof EidosGridComponent
   */
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid?: DxDataGridComponent;
  /**
   * Grid datasource
   *
   * @type {Array<T>}
   * @memberof EidosGridComponent
   */
  @Input() public dataSource: DataSource | Store | string | CustomStore | Array<T> = [];
  /**
   * Grid config
   *
   * @type {(IEidosGridConfiguration | undefined)}
   * @memberof EidosGridComponent
   */
  @Input() public config: IEidosGridConfiguration | undefined;

  @Input() public applyFilterTypes: any;
  /**
   * Current filter
   *
   * @type {*}
   * @memberof EidosGridComponent
   */
  public currentFilter: any;
  /**
   * Allowed page size
   *
   * @type {(Array<number | string>)}
   * @memberof EidosGridComponent
   */
  @Input() readonly allowedPageSizes: Array<number | string> = [5, 10, 'all'];
  /**
   * Allowed display mode
   *
   * @type {(Array<{text: string, value: 'full' | 'compact' }>)}
   * @memberof EidosGridComponent
   */
  @Input() readonly displayModes: Array<{ text: string, value: 'full' | 'compact' }> = [
    { text: "Display Mode 'full'", value: 'full' },
    { text: "Display Mode 'compact'", value: 'compact' }
  ];
  /**
   * Current display mode
   *
   * @type {('full' | 'compact')}
   * @memberof EidosGridComponent
   */
  public currentDisplayMode: 'full' | 'compact' = 'full';
  /**
   * Show size selector flag
   *
   * @type {boolean}
   * @memberof EidosGridComponent
   */
  @Input() public showPageSizeSelector: boolean = true;
  /**
   * Show page navigation info flag
   *
   * @type {boolean}
   * @memberof EidosGridComponent
   */
  @Input() public showPageNavigationInfo: boolean = true;
  /**
   * Show page navigation buttons flag
   *
   * @type {boolean}
   * @memberof EidosGridComponent
   */
  @Input() public showPageNavButtons: boolean = true;

  //#region Eventi Esposti di Griglia

  @Output() public onContentReady: EventEmitter<T> = new EventEmitter<T>();
  @Input() public ContentReady?: () => void;

  /**
     * On row clicked emitter
     *
     * @type {EventEmitter<T>}
     * @memberof EidosGridComponent
     */
  @Output() public onRowClick: EventEmitter<T> = new EventEmitter<T>();
  @Input() public RowClick?: () => void;

  /**
   * On row Double clicked emitter
   *
   * @type {EventEmitter<T>}
   * @memberof EidosGridComponent
   */
  @Output() public onRowDblClick: EventEmitter<T> = new EventEmitter<T>();
  @Input() public RowDblClick?: () => void;

  /**
   *
   *
   * @type {EventEmitter<T>}
   * @memberof EidosGridComponent
   */
  @Output() public onCustomButtonClick: EventEmitter<T> = new EventEmitter<T>();
  @Input() public CustomButtonClick?: (event: any) => void;

  protected gridIdAttribute = uuid();
  protected gridClassAttribute = 'eidos-grid-normal';

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];

    this.currentFilter = this.applyFilterTypes[0].key;

    if (this.config?.resetStyle) {
      this.gridClassAttribute = '';
    }

    if (this.config?.cssID) {
      this.gridIdAttribute = this.config.cssID;
    }

    if (this.config?.cssClass) {
      this.gridClassAttribute = this.config.cssClass;
    }
  }

  /**
   * Clear header filtering
   *
   * @memberof EidosGridComponent
   */
  public clearFilter() {
    this.dataGrid!.instance.clearFilter();
  }

  /**
   *
   */
  public refresh() {
    this.dataGrid!.instance.refresh();
  }

  //#region Eventi di Griglia

  /**
   * On content ready handler
   *
   * @param {*} eveny
   * @memberof EidosGridComponent
   */
  public _onContentReady(event: any) {
    if (this.ContentReady) {
      this.ContentReady();
    }
    this.onContentReady.emit(event);
  }

  /**
   * On row click handler
   *
   * @param {*} event
   * @memberof EidosGridComponent
   */
  public _onRowClick(event: any) {
    if (this.RowClick) {
      this.RowClick();
    }
    this.onRowClick.emit(event);
  }

  /**
   * On row double click handler
   *
   * @param {*} event
   * @memberof EidosGridComponent
   */
  public _onRowDblClick(event: any) {
    if (this.RowDblClick) {
      this.RowDblClick();
    }
    this.onRowDblClick.emit(event.data);
  }

  /**
   * On Custom Button Click
   *
   * @param {*} event
   * @memberof EidosGridComponent
   */
  public _onCustomButtonClick(event: any) {
    if (this.CustomButtonClick) {
      this.CustomButtonClick(event);
    }

    this.onCustomButtonClick.emit(event);
  }

  public switchFieldType(column: IEidosGridColumnConfiguration): string {
    switch (column?.rendering?.toLowerCase()) {
      case 'eidos':
        return 'eidos';
      default:
        switch (column?.fieldType?.toLowerCase()) {
          case 'button':
          case 'command':
            return 'buttonCellTemplate';
          case 'link':
            return 'linkCellTemplate';
          case 'full-visible-text':
            return 'fullVisibleTextCellTemplate';
          default:
            return '';
        }
    }
  }
  // public switchEditFieldType(column: IEidosGridColumnConfiguration): string {
  //   switch (column?.rendering?.toLowerCase()) {
  //     case 'eidos':
  //       return 'eidosEdit';
  //     default:
  //       switch (column?.fieldType?.toLowerCase()) {
  //         case 'button':
  //         case 'command':
  //           return 'buttonCellTemplate';
  //         case 'link':
  //           return 'linkCellTemplate';
  //         case 'full-visible-text':
  //           return 'fullVisibleTextCellTemplate';
  //         default:
  //           return '';
  //       }
  //   }
  // }
  getColumnConfig(column: any): IEidosGridColumnConfiguration | undefined {
    if (!this.config) return;
    if (!this.config.columns) return;
    return this.config.columns.find(c => c.dataField === column.dataField);
  }
  getColumnButtonConfig(cellInfo: any): Array<IEidosGridButtonConfiguration> {
    if (!cellInfo.column) return [];
    const c = this.getColumnConfig(cellInfo.column);
    if (!c) return [];
    return c.buttonsConfig ?? [];
  }
  getCallback(name: string): ((params: Array<any>, cellInfo: any) => void) | undefined {
    if (!this.config) return;
    if (!this.config.methods) return;
    return this.config.methods.callbacks.find(c => c.name === name)?.fun;
  }
  getCallbackInfo(name: string): IEidosGridCallableMethod | undefined {
    if (!this.config) return;
    if (!this.config.methods) return;
    return this.config.methods.callbacks.find(c => c.name === name);
  }
  getRenderingClass(cellInfo: any) {
    let renderingInfo: any = {};
    if (!cellInfo.column) return renderingInfo;
    if (cellInfo.column.alignment) renderingInfo[`text-${cellInfo.column.alignment}`] = true;
    const c = this.getColumnConfig(cellInfo.column);
    if (c) {
      if (c.link) renderingInfo['link-behaviour'] = true;
      if (c.cssClass) {
        c.cssClass.split(' ').forEach(item => renderingInfo[item] = true)
      }
    }
    return renderingInfo;
  }
  hasRederingFunction(cellInfo: any) {
    if (!cellInfo.column) return false;
    const c = this.getColumnConfig(cellInfo.column);
    if (c && c.rederingFunction)  return true;
    return false;
  }
  getRederingValue(cellInfo: any) {
    if (!cellInfo.column) return cellInfo.value;
    const c = this.getColumnConfig(cellInfo.column);
    if (c && c.rederingFunction)  return c.rederingFunction(cellInfo.value);
    return cellInfo.value;
  }
  onClickEidosCell(cellInfo: any) {
    console.log(cellInfo);
    if (!cellInfo.column) return;
    const c = this.getColumnConfig(cellInfo.column);
    if (!c) return;
    if (c.link && c.link.template) {
      const link = _.template(c.link.template ?? '')({ value: cellInfo.value });
      window.open(link.replace('~', window.location.origin), c.link.target ?? '_blank');
      return;
    }
    if (c.action) {
      switch ((c.action.type ?? '').toLowerCase()) {
        case "method":
          if (!c.action.key) break;
          const f = this.getCallback(c.action.key);
          if (!_.isFunction(f)) break;
          const p = c.action.parameters ?? [];
          f(cellInfo, p);
      }
    }
  }
  onClickEidosCellButton(button: IEidosGridButtonConfiguration, cellInfo: any) {
    if (cellInfo.column) {
      const c = this.getColumnConfig(cellInfo.column);
      if (c) {
        const cb = this.getCallbackInfo(button.name);
        if (cb) {
          const f = cb?.fun;
          if (!_.isFunction(f)) return;
          const p: Array<any> = [c];
          if (cb.parameters) p.concat(cb.parameters);
          f(cellInfo, p);
        }

      }

    }
  }

  paymentTooltip(cellInfo: any) {
    if (cellInfo.data.mouseOverDescription) {
      return cellInfo.data.mouseOverDescription;
    }
    return '';
  }
}
