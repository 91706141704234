import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IEidosFiltersRegistry } from "@common/models/eidos-filters.models";
import { EidosFilterComponent } from "@common/components/entities/filters/eidos-filter/eidos-filter.component";

@Component({
  selector: 'eidos-filter-checkbox',
  templateUrl: './eidos-filter-checkbox.component.html',
  styleUrls: ['./eidos-filter-checkbox.component.scss']
})
export class EidosFilterCheckboxComponent extends EidosFilterComponent {
  /**
   * Current filter value (2-way binding)
   *
   * @type {IEidosFiltersRegistry["checkbox"]["valueType"]}
   * @memberof EidosFilterCheckboxComponent
   */
  protected _value: IEidosFiltersRegistry["checkbox"]["valueType"];
  @Input() public get value(): IEidosFiltersRegistry["checkbox"]["valueType"] {
    return this._value;
  }
  public set value(val: IEidosFiltersRegistry["checkbox"]["valueType"]) {
    this._value = val;
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<IEidosFiltersRegistry["checkbox"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["checkbox"]["valueType"]>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["checkbox"]["config"]}
   * @memberof EidosFilterCheckboxComponent
   */
  @Input() public config: IEidosFiltersRegistry["checkbox"]["config"];

  constructor() {
    super();
  }
}
