import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';

import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';
import { EidosBaseComponent } from '@common/components/eidos-base.component';
import { CoreRoute } from '@common/models/core-constant.model';
import { EidosUtilityService } from '../services/eidos-utility.service';

@Component({
  selector: 'eidos-external-app',
  templateUrl: 'eidos-external-app.component.html'
})
export class EidosExternalAppComponent extends EidosBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  source: string = '';
  id: string = '';
  parameters: string[] = [];
  options: any;

  constructor(
    private route: ActivatedRoute,
    protected coreUtilityService: EidosUtilityService,
    private router: Router
    , private externalAppService: EidosExternalAppService
  ) {
    super();
    this.coreUtilityService.hideTopbar.next(false);
    this.coreUtilityService.hideSidebar.next(false);
  }

  ngOnInit() {
    this.route.url
      .pipe(takeUntil(this.subscription))
      .subscribe((segments: UrlSegment[]) => {
        if (segments.length > 1 && segments[0].path.toLowerCase() == 'app') {
          const prms = segments.map(u => u.path);
          this.externalAppService.apps
            .pipe(
              filter(apps => !!apps),
              takeUntil(this.subscription),
              take(1)
            )
            .subscribe(() => {
              if (this.externalAppService.isValidAction(prms[1])) {
                this.goToExternalApp(prms);
              }
            });
        }
      });
  }
  /**
   * Move related external appì view to correct route
   *
   * @private
   * @param {string[]} prms
   * @memberof EidosExternalAppComponent
   */
  private goToExternalApp(prms: string[]) {
    const app = this.externalAppService.getAppReferenceByAction(prms[1]);
    if (app) {
      this.source = app.source;
      this.id = prms.length > 2 ? prms[2] : '';
      this.parameters = prms.length > 3 ? prms.slice(3) : [];
      if (!app.authorized) {
        this.externalAppService.sendToken(this.source);
      }
      this.externalAppService.applicationGoto(this.source, this.id, this.parameters);
    } else {
      this.router.navigate([CoreRoute.Home]);
    }
  }

  ngAfterViewInit(): void {
    this.externalAppService.applicationShow(this.source);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.externalAppService.applicationHide(this.source);
  }
}
