import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import {Tooltip} from 'bootstrap';

export interface IEidosTooltip {
  text?:string;
  htmlText?:string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}
@Directive({
  selector: '[eidosTooltip]'
})
export class EidosTooltipDirective implements OnInit {
  /**
   * tooltip configuration
   *
   * @type {string}
   * @memberof EidosTooltipDirective
   */
  @Input() eidosTooltip: string | IEidosTooltip;

  constructor(
    private _elementRef: ElementRef
    , private _renderer: Renderer2
  ) {
    this.eidosTooltip='';
  }

  ngOnInit() {
    if(typeof this.eidosTooltip == 'string' ) {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'title', this.eidosTooltip);
    } else if(this.eidosTooltip.htmlText ?? this.eidosTooltip.text ?? '' !== '') {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'title', this.eidosTooltip.htmlText ?? this.eidosTooltip.text ?? '');
      if(this.eidosTooltip.htmlText) this._renderer.setAttribute(this._elementRef.nativeElement, 'data-bs-html', 'true');
      if(this.eidosTooltip.position) this._renderer.setAttribute(this._elementRef.nativeElement, 'data-bs-placement', this.eidosTooltip.position);
    }
    new Tooltip(this._elementRef.nativeElement);
  }

}
