import { Component, EventEmitter, Input, Output, } from "@angular/core";

import { EidosBaseComponent } from "@common/components/eidos-base.component";
import { CoreDrawableComponentInterface } from '@common/components/core-drawable-component.interface';
import { EidosEntityService } from "@common/services/eidos-entity.service";

@Component({
  selector: 'eidos-filter',
  templateUrl: './eidos-filter.component.html',
  styleUrls: []
})
export class EidosFilterComponent<S extends EidosEntityService = EidosEntityService> extends EidosBaseComponent implements CoreDrawableComponentInterface {
  /**
   * Service to call search handlers
   *
   * @type {S}
   * @memberof EidosFilterComponent
   */
  @Input() public searchService?: S;
  /**
   * Element CSS class
   *
   * @type {string}
   * @memberof EidosFilterComponent
   */
  @Input() public cssClass: string = "";
  /**
   * Element ID class
   *
   * @type {string}
   * @memberof EidosFilterComponent
   */
  @Input() public cssId: string = "";
  /**
   * Current filter value (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterComponent
   */
  protected _value: any;
  public get value(): any {
    return this._value;
  }
  @Input() public set value(val: any) {
    this._value = val;
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Current second filter (for range) value (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterComponent
   */
  protected _otherValue: any;
  public get otherValue(): any {
    return this._otherValue;
  }
  @Input() public set otherValue(val: any) {
    this._otherValue = val;
    this.otherValueChange.emit(this._otherValue);
  }
  @Output() public otherValueChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Filter configuration
   *
   * @type {*}
   * @memberof EidosFilterComponent
   */
  @Input() public config: any;
  /**
   * On key down event emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosFilterComponent
   */
  @Output() public onKeyDown: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  public keyDown(event: any) {
    this.onKeyDown.emit(event);
  }

  public reset() {
    this.config = Object.assign({},this.config)
  }
}
