import { AppModule } from '@app/app.module';
import { SafeBaseObject } from '@app/core/models/base-object.models';
import { CoreFormatService } from '@app/core/services/core-format.service';
import { DateTime } from 'luxon';

export class ResInsurance extends SafeBaseObject {
  public bkgID: number = 0;
  public sectionID: number = 0;
  public guestCod: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public itineraryDate: DateTime = DateTime.min();
  public countryCod: string = '';
  public stateCod: string = '';
  public optionID: number = 0;
  public serviceID: number = 0;
  public optionName: string = '';
  public serviceName: string = '';
  public price: number = 0;
  public cost: number = 0;
  public agencyCommission: number = 0;
  public insMessage: string = '';
  public insuranceStatusID: number = 0;
  public sellingPrice: number = 0;
  public currencyCod: string = '';
  public filePath: string = '';

  get formattedSellingPrice(): string {
    return this.formatService.CurrencyAmount(this.sellingPrice, this.currencyCod);
  }

  formatService:CoreFormatService;

  constructor(data?: IReservationApiInsurance) {
    super();
    this.formatService = AppModule.injector.get(CoreFormatService);
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiInsurance): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('itineraryDate', data, 'ItineraryDate');
  }

  isDeclined(): boolean {
    return this.insMessage?.toLowerCase() == 'declined';
  }
}

export interface IReservationApiInsurance {
  BkgID?: number;
  SectionID?: number;
  GuestCod?: string;
  FirstName?: string;
  LastName?: string;
  ItineraryDate?: string;
  CountryCod?: string;
  StateCod?: string;
  OptionID?: number;
  ServiceID?: number;
  OptionName?: string;
  ServiceName?: string;
  Price?: number;
  SellingPrice?: number;
  Cost?: number;
  AgencyCommission?: number;
  InsMessage?: string;
  InsuranceStatusID?: number;
  FilePath?: string;
}

export interface IReservationApiDeclineInsurance {
  BkgID?: number;
  SectionID?: number;
  GuestCod?: string;
  Action?: 'Decline' | 'Restore';
}

export enum ResInsuranceAction {
  Add = 'add',
  Decline = 'decline',
  Available = 'available',
}




