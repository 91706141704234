import { Params } from "@angular/router";
import { IEidosIcon } from "../config/environment.interface";

/**
 * External app interface from User Info API or config
 *
 * @export
 * @interface IEidosExternalApp
 */
export interface IEidosExternalApp {
  /**
   * App id
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  id: string,
  /**
   * MyBiz app object id
   *
   * @type {number}
   * @memberof IEidosExternalApp
   */
  objectId: number,
  /**
   * App source
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  source: string,
  /**
   * App raw URL
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  url: string,
  /**
   * Pattern to identify app route
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  pattern: string,
  /**
   * Required token for auth flag
   *
   * @type {boolean}
   * @memberof IEidosExternalApp
   */
  useToken: boolean,
  /**
   * App action
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  action: string,
  /**
   * Use Eidos loader for app loading
   *
   * @type {boolean}
   * @memberof IEidosExternalApp
   */
  useLoader: boolean,
  /**
   * Indica se includere l'action nella costruzione dell'URL
   *
   * @type {boolean}
   * @memberof IEidosExternalApp
   */
  actionInUrl: boolean,
  /**
   * menu label Label da visualizzare nel menu
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  label: string,
  /**
    * Display app in menu flag
    *
    * @type {boolean}
    * @memberof IEidosExternalApp
    */
  addToMenu?: boolean,
  /**
   * External app menu item color
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  color?: string,
  /**
   * External app icon
   *
   * @type {IEidosIcon}
   * @memberof IEidosExternalApp
   */
  icon?: IEidosIcon | null,
  /**
   * App origin
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  origin?: string
  /**
   * App segment
   *
   * @type {string[]}
   * @memberof IEidosExternalApp
   */
  segments?: string[]
}

export interface IEidosModuleBaseUrl {
    reservation: string;
    resAdmin: string;
    eidos: string;
    core: string;
}
export const defaultModuleBaseUrl:IEidosModuleBaseUrl = {
  reservation: window.location.origin,
  resAdmin: window.location.origin,
  eidos: window.location.origin,
  core: window.location.origin
}
export const defaultAppRoute = window.location.origin+'/reservation/home'
/**
 * Interface of a message post to external app
 *
 * @export
 * @interface IEidosExternalAppPostMessage
 */
export interface IEidosExternalAppPostMessage {
  /**
   * Type of message
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessage
   */
  type: string,
  /**
   * Type of command of the message
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessage
   */
  command: string
  /**
   * Target app source
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessage
   */
  source?: string,
  /**
   * Target app action
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessage
   */
  action?: string,

  /**
   * Token for authentication (in case of command == token)
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessage
   */
  token?: string,

  /**
   * Route ID (in case of command == route)
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessage
   */
  id?: string
  /**
   * Route parameters (in case of command == route/goto)
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessage
   */
  parameters?: Array<string>
  /**
   * Query string parameters (in case of command == route/goto)
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessage
   */
  queryString?: Params
}

/**
 * Interface of a queued message post to external app
 *
 * @export
 * @interface IEidosExternalAppPostMessageQueue
 */
export interface IEidosExternalAppPostMessageQueue {
  /**
   * Target app source
   *
   * @type {string}
   * @memberof IEidosExternalAppPostMessageQueue
   */
  source: string;
  /**
   * Message
   *
   * @type {IEidosExternalAppPostMessage}
   * @memberof IEidosExternalAppPostMessageQueue
   */
  message: IEidosExternalAppPostMessage;
}

/**
 * Interface of a command from external app
 *
 * @export
 * @interface IEidosExternalAppCommandData
 */
export interface IEidosExternalAppCommandData {
  /**
   * Type of command
   *
   * @type {string}
   * @memberof IEidosExternalAppCommandData
   */
  type: string;
  /**
   * Command
   *
   * @type {string}
   * @memberof IEidosExternalAppCommandData
   */
  command: string;
  id: string;
  segments: string[];
  parameters?: any;
}

/**
 * Interface of an event from external app
 *
 * @export
 * @interface IEidosExternalAppEventData
 */
export interface IEidosExternalAppEventData {
  source: string,
  name: string;
  id:string;
  segments: string[];
  parameters?: IEidosExternalAppEventPatametersAuth | IEidosExternalAppVersion | any;
  url?: string
  queryString: Params
}

/**
 * Interface of a version response from external app
 *
 * @export
 * @interface IEidosExternalAppVersion
 */
export interface IEidosExternalAppVersion {
  source: string,
  version?: string
}

/**
 *
 *
 * @export
 * @interface IEidosExternalAppEventPatametersAuth
 */
interface IEidosExternalAppEventPatametersAuth {
  token: string;
  userInfo: any;
}
