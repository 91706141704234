import { Pipe, PipeTransform } from '@angular/core';

/**
 * Cast super type into type using generics
 * Return Type obtained by optional @param type OR assignment type.
 *
 * @export
 * @class CoreTemplateCastPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'cast' })
export class CoreTemplateCastPipe implements PipeTransform {
    /**
     * Cast (S: SuperType) into (T: Type)
     *
     * @template S
     * @template T
     * @param {S} value (S: SuperType) obtained from input type.
     * @param {T} [type] (T CastingType)
     * @return {*}  {T}
     * @memberof CoreTemplateCastPipe
     */
    // @ts-ignore
    transform<S, T extends S>(value: S, type: T): T {
        return <T>value;
    }
}