/**
 * Interface of a Dynamic API response (Wonderland API)
 *
 * @export
 * @interface IDynamicApiResponse
 */
export interface IDynamicApiResponse {
  ApiVersion: number;
  Body: any;
  Errors: IDynamicApiErrorResponse[];
  PageNumber: number;
  PageTotal: number;
  RecordsCount: number;
  StatusCod: number;
  StatusMessage: string;
}
/**
 * Checks if obj implements IDynamicApiResponse interface
 *
 * @export
 * @param {*} obj
 * @return {boolean} {obj is IDynamicApiResponse}
 */
export function instanceOfIDynamicApiResponse(obj: any): obj is IDynamicApiResponse {
  if (obj != undefined) {
    // Check some properties as discriminators
    return "Body" in obj && "ApiVersion" in obj && "Errors" in obj;
  } else {
    return false;
  }
}
/**
 * Dynamic API (Wonderland API) response model
 *
 * @export
 * @class DynamicApiResponse
 */
export class DynamicApiResponse {

  body: any;
  apiVersion: number = 1;
  errors: Array<IDynamicApiErrorResponse> = [];
  pageNumber = 1;
  pageTotal = 1;
  recordsCount = 1;
  statusCod: number = 0;
  statusMessage: string = '';

  constructor(data: any) {

    if (data) {

      let apiResponse: IDynamicApiResponse | undefined;

      if (data.response && data.response instanceof Response) {
        // ottengo i dati dalla responce
        apiResponse = (<any>data.response.json());
      } else if (instanceOfIDynamicApiResponse(data)) {
        // risposta del httpclient ng5+
        apiResponse = data as IDynamicApiResponse;
      } else if (instanceOfIDynamicApiResponse(data.response)) {
        // risposta del httpclient ng5+
        apiResponse = data.response as IDynamicApiResponse;
      } else if (data.error && data.error.Body && data.error.ApiVersion && data.error.Errors) {
        // risposta errore API dinamiche
        apiResponse = data.error;
      }

      if (apiResponse != undefined) {

        this.body = apiResponse.Body;
        this.apiVersion = apiResponse.ApiVersion;
        this.errors = apiResponse.Errors;
        this.pageNumber = apiResponse.PageNumber;
        this.pageTotal = apiResponse.PageTotal;
        this.recordsCount = apiResponse.RecordsCount;
        this.statusCod = apiResponse.StatusCod;
        this.statusMessage = apiResponse.StatusMessage;

        if (Array.isArray(this.errors) && this.errors.length > 0) {

          // Throw the source interfaces as error
          const errorResponse: IDynamicApiResponse = {
            ApiVersion: this.apiVersion,
            Body: this.body,
            Errors: this.errors,
            PageNumber: this.pageNumber,
            PageTotal: this.pageTotal,
            RecordsCount: this.recordsCount,
            StatusCod: this.statusCod,
            StatusMessage: this.statusMessage
          }
          throw errorResponse;
        }
      }
    }
  }

  isOk(): boolean {
    return !this.errors || this.errors.length <= 0;
  }
}
/**
 * Interface of a Dynamic api error (Wonderland API)
 *
 * @export
 * @interface DynamicApiErrorResponse
 */
export interface IDynamicApiErrorResponse {
  ErrorCod: string;
  ErrorDesc: string;
}

/**
 * Dynamic API (Wonderland API) paged request model
 *
 * @export
 * @class DynamicApiRequestPaginatedParams
 * @implements {IDynamicApiRequestPaginatedParams}
 */
export class DynamicApiRequestPaginatedParams implements IDynamicApiRequestPaginatedParams {
  PageNum: number = 1;
  PageSize: number = 10;

  constructor(pageNum: number = 1, pageSize: number = 10) {
    this.PageNum = pageNum;
    this.PageSize = pageSize;
  }

  serialize(): IDynamicApiRequestPaginatedParams {
    return {
      PageNum: this.PageNum,
      PageSize: this.PageSize
    };
  }
}
/**
 * Dynamic API (Wonderland API) paged request interface
 *
 * @export
 * @interface IDynamicApiRequestPaginatedParams
 */
export interface IDynamicApiRequestPaginatedParams {
  PageNum: number;
  PageSize: number;
}

export interface IDataSourcePaginatedModel {
  data: any[];
  totalCount: number
}

export class DynamicApiResponsePaginatedModel<T> {
  content: Array<T> = [];
  pageNumber = 1;
  pageTotal = 1;
  recordsCount = 1;

  constructor(apiResponse: DynamicApiResponse) {
    this.pageNumber = apiResponse.pageNumber;
    this.pageTotal = apiResponse.pageTotal;
    this.recordsCount = apiResponse.recordsCount;
    this.content = apiResponse.body as T[];
  }

  static emptyResponse: DynamicApiResponsePaginatedModel<any> = { content: [], pageNumber: 1, pageTotal: 0, recordsCount: 0 }
}
