import { Component, Input, OnInit } from '@angular/core';

import { IEidosInputSliderTooltipConfig } from '@common/models/eidos-inputs.models';
import { EidosInputBaseComponent } from '@common/components/entities/inputs/eidos-input-base.component';

@Component({
  selector: 'eidos-input-slider',
  templateUrl: './eidos-input-slider.component.html',
  styleUrls: ['./eidos-input-slider.component.scss']
})
export class EidosInputSliderComponent extends EidosInputBaseComponent<number> implements OnInit {
  /**
   * Slider min value
   *
   * @type {number}
   * @memberof EidosInputSliderComponent
   */
  @Input() public min: number;
  /**
   * Slider max value
   *
   * @type {number}
   * @memberof EidosInputSliderComponent
   */
  @Input() public max: number;
  /**
   * Slider tooltip
   *
   * @type {IEidosInputSliderTooltipConfig}
   * @memberof EidosInputSliderComponent
   */
  @Input() public tooltip: IEidosInputSliderTooltipConfig;

  constructor() {
    super();
  }
}