import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CoreFormatService } from '@app/core/services/core-format.service';
import { DxDateBoxComponent } from 'devextreme-angular';
import { Format } from 'devextreme/localization';
import { DateTime } from 'luxon';

import { EidosInputBaseComponent } from '../eidos-input-base.component';

@Component({
  selector: 'eidos-input-datetime',
  templateUrl: './eidos-input-datetime.component.html',
  styleUrls: ['./eidos-input-datetime.component.scss']
})
export class EidosInputDateTimeComponent extends EidosInputBaseComponent<DateTime | undefined> implements OnInit {
  /**
   * Internal value getter, returns the value as DX expected object
   * 
   * NB: The real type of the internal value to pass to DX component is (Date | string | number | null)
   * Assigning this type to the variable results in compilation error because DX does not accepts null or undefined
   * (but the default value is null, see docs https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/#value)
   * We workaround using the fuc*ing any type
   *
   * @type {*}
   * @memberof EidosInputDateTimeComponent
   */
  public get internalValue(): any {
    if (this.value != undefined) {
      return this.value instanceof Date || typeof this.value === "string" ? this.value : this.value.toJSDate();
    } else {
      return null;
    }
  }
  /**
   * Internal value setter, set the value as DX expected object
   *
   * @memberof EidosInputDateTimeComponent
   */
  public set internalValue(val: any) {
    if (val != undefined) {
      if (typeof val === "string") {
        this.value = DateTime.fromISO(val)
      } else if (typeof val === "number") {
        this.value = DateTime.fromMillis(val);
      } else {
        this.value = DateTime.fromJSDate(val);
      }
    } else {
      this.value = undefined;
    }      
  }
  /**
   * Specifies the type of the date/time picker. 'calendar' is default.
   * 
   * "native" The picker type depends on the current platform or web browser.
   * "calendar" The date box uses the Calendar UI component to pick data. This value applies only when the type property is set to "date" or "datetime".
   * "rollers" The UI component uses rollers to pick the date and time.
   * "list" The UI component uses the list of time values to pick the time. This value applies only when the type property is set to "time".
   *
   * @type {('calendar' | 'list' | 'native' | 'rollers')}
   * @memberof EidosInputDateTimeComponent
   */
  @Input() public pickerType: 'calendar' | 'list' | 'native' | 'rollers' = 'calendar';
  /**
   * A format used to display date/time information. 'date' is default.
   * 
   * date - the UI component displays only the date;
   * time - the UI component displays only the time;
   * datetime - the UI component displays both the date and time.
   *
   * @type {('date' | 'datetime' | 'time')}
   * @memberof EidosInputDateTimeComponent
   */
  @Input() public dateValueType: 'date' | 'datetime' | 'time' = 'date';
  /**
   * The function must specify if a date is allowed to be picked
   * Es. isBeforeToday(value: { component: DxDateBoxComponent, date: Date, view: "month" | "year" | "decade" | "century" }) => value.date.valueOf() < Date.now()
   *
   * @memberof EidosInputDateTimeComponent
   */
  @Input() public disabledDates?: Array<Date> | ((value: { component: DxDateBoxComponent, date: Date, view: "month" | "year" | "decade" | "century" }) => boolean) = [];
  /**
   * Min date allowed in the picker
   *
   * @type {Date}
   * @memberof EidosInputDateTimeComponent
   */
  @Input() public min?: Date = new Date(0);
  /**
   * Max date allowed in the picker
   *
   * @type {Date}
   * @memberof EidosInputDateTimeComponent
   */
  @Input() public max?: Date = new Date(8640000000000000);
  /**
   * Date picked display format
   *
   * @type {(Format | string)}
   * @memberof EidosInputDateTimeComponent
   */
  displayFormat: Format | string = 'dd/MM/yyyy';
  @Input('displayFormat') inputDisplayFormat?: Format | string;
  /**
   * Reference to the component
   *
   * @type {(DxDateBoxComponent | undefined)}
   * @memberof EidosInputDateTimeComponent
   */
  @ViewChild(DxDateBoxComponent, { static: false }) public datetimeInput: DxDateBoxComponent | undefined;

  constructor(private coreFormatService: CoreFormatService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Always set picker pickerType = 'list' if dateValueType === 'time
    if (this.dateValueType === 'time') {
      this.pickerType = 'list';
    } else if (this.pickerType === 'list') {
      /**
       * Always set picker pickerType = 'calendar' if dateValueType != 'time
       * and pickerType specified == 'list'
       */
      this.pickerType = 'calendar';
    }

    if (!this.inputDisplayFormat) {
      switch (this.dateValueType) {
        case 'date':
          this.displayFormat = this.coreFormatService.DateFmtWithMonthName();
          break;
        case 'time':
          this.displayFormat = "hh:mm a";
          this.pickerType = 'list'
          break;
        case 'datetime':
        default:
          this.displayFormat = this.coreFormatService.DateAndTimeFmt();
          break;
      }
    } else {
      this.displayFormat = this.inputDisplayFormat;
    }
  }
  /**
   * Open the component
   *
   * @memberof EidosInputDateTimeComponent
   */
  public open() {
    this.datetimeInput?.instance.open();
  }

  public onClosed(event: any) {
    if (!!this.disabledDates && this.disabledDates instanceof Function) {
      event.component?.repaint();
    }
  }
}