import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';

import { EidosUser } from '@common/models/user-info.model';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosBaseComponent } from '@common/components/eidos-base.component';

@Component({
  selector: 'eidos-error-dialog',
  styleUrls: [
    'eidos-error-dialog.component.scss'
  ],
  templateUrl: 'eidos-error-dialog.component.html'
})
export class EidosErrorDialogComponent extends EidosBaseComponent {
  /**
   * Now!
   *
   * @protected
   * @type {Date}
   * @memberof EidosErrorDialogComponent
   */
  protected now: Date = new Date();
  /**
   * User agent
   *
   * @protected
   * @type {string}
   * @memberof EidosErrorDialogComponent
   */
  protected userAgent: string = navigator.userAgent;
  /**
   * Current URL
   *
   * @protected
   * @type {string}
   * @memberof EidosErrorDialogComponent
   */
  protected url: string = window.location.pathname;
  /**
   * Current logged user
   *
   * @protected
   * @type {EidosUser}
   * @memberof EidosErrorDialogComponent
   */
  protected user: EidosUser = new EidosUser();
  /**
   * Logged user subscription
   *
   * @protected
   * @type {Subject<any>}
   * @memberof EidosErrorDialogComponent
   */
  protected currentLoggedUserSubscription: Subject<any> = new Subject();

  constructor(
    private coreSecurityService: EidosSecurityService,
    private dialogRef: MatDialogRef<EidosErrorDialogComponent>,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) protected error: Error) {
    super();

    this.coreSecurityService.currentLoggedUser.pipe(
      takeUntil(this.subscription)
    ).subscribe(user => {
      this.user = user;
    });

    if (this.error.stack) {
      this.error.stack = this.error.stack.replace(/\n/g, "<br />");
    }

    if (!this.error.name) {
      this.error.name = 'ERROR';
    }
  }
  /**
   * Close the dialog
   *
   * @memberof EidosErrorDialogComponent
   */
  close(): void {
    this.ngZone.run(() => this.dialogRef.close());
  }
}