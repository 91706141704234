import { ChartType } from "chart.js";
import { BaseObject } from "./base-object.models";
import { IEidosChartJSConfiguration } from "./eidos-chart-js.interface";
import { IEidosObjectChartDimensionValue, IEidosObjectFilterData } from "./eidos-object.interface";
import { EidosObjectChartDimension } from "./eidos-object.model";

export type IEidosChartValue = IEidosChartDataset | number;
export type IEidosChartDataset = Array<Array<IEidosObjectChartDimensionValue>>;
export type EidosChartJsType = ChartType | 'horizontalBar';

/**
 * Configuration of ChartJS chart in IEidos
 *
 * @export
 * @class EidosChartJSConfiguration
 * @extends {BaseObject}
 * @implements {IEidosChartJSConfiguration}
 */
export class EidosChartJSConfiguration extends BaseObject implements IEidosChartJSConfiguration {
  /**
   * Chart title resolved
   *
   * @type {string}
   * @memberof EidosChartJSConfiguration
   */
  public titleResolved: string;
  /**
   * Datasets to show in the chart canva
   *
   * @type {Array<EidosChartJSDataset>}
   * @memberof EidosChartJSConfiguration
   */
  public propertyBags: Array<{ name: string, value: string }>;
  /**
   * Chart type
   *
   * @type {ChartType}
   * @memberof EidosChartJSConfiguration
   */
  public type: ChartType | 'horizontalBar';
  /**
  * Chart dimensions
  *
  * @type {Array<EidosObjectChartDimension>}
  * @memberof EidosChartJSConfiguration
  */
  public dimensions: Array<EidosObjectChartDimension>;
  /**
   * Display axis labels
   *
   * @type {boolean}
   * @memberof EidosChartJSConfiguration
   */
  public labelAxis: { xAxesLabel: string, yAxesLabel: string } | undefined;
  /**
   * Object filters
   *
   * @type {Array<IEidosObjectFilterData>}
   * @memberof EidosChartJSConfiguration
   */
  public filters: Array<IEidosObjectFilterData>;

  constructor(initdata?: any, xAxesLabel?: string, yAxesLabel?: string) {
    super();
    if (!!xAxesLabel || !!yAxesLabel) {
      this.labelAxis = {
        xAxesLabel: xAxesLabel ?? "",
        yAxesLabel: yAxesLabel ?? ""
      };
    } else {
      this.labelAxis = undefined;
    }
    this.titleResolved = '';
    this.propertyBags = [];
    this.type = 'line';
    this.dimensions = [];
    this.filters = [];
    if (initdata) {
      this.updateData(initdata);
    }
  }

  public updateData(initdata: any): void {
    this.addPropertyAutoBinding(initdata, this);
  }
}

export enum EidosChartType {
  Empty,
  Gauge,
  Level,
  Chartjs
}