import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";
import { SafeBaseObject } from "@app/core/models/base-object.models";
import _ from "lodash";
import { DateTime } from "luxon";

import { ICoreSearchConfiguration } from "@common/models/eidos-search.model";
import { AppModule } from "@app/app.module";

import { ReservationApiBoolean } from "../../res-common/models/res-constant.model";

export enum ResSearchMode {
  Search = 'search',
  Lookup = 'lookup',
  MultiSelectionLookup = 'multi-selection-lookup',
  ExtendJourney = 'extend-journey',
  ChangeVoyage = 'change-voyage',
  CopyBooking = 'copy-booking'

}
/**
 * Interface of the reservation product search in dialog
 *
 * @export
 * @interface IResSearchDialogConfig
 */
export interface IResSearchDialogConfig {
  /**
   * Filters object
   *
   * @type {*}
   * @memberof IResSearchDialogConfig
   */
  filters: any;
  /**
   * Custom configuration
   * Overrides the configuration retrieved from external sources.
   *
   * @type {ICoreSearchConfiguration}
   * @memberof IResSearchDialogConfig
   */
  config?: ICoreSearchConfiguration;
}

export interface IResVoyageSearchRequiredParams {
  Id: string;
  Currency: string;
  PriceTypeId: number;
  GrpID?: number;
  PackageId?: number;
  PromoID?: string;
  isInventory?: string;
  PackageTravelDateID?: number;
}

export interface IResPackagePricesHistoryParams {
  PackageID: number
	PackageTravelDateID: number
	PriceTypeID: number
	AgencyID?: number
  Type?: string
}
export interface IResPackageSearchParams {
  DestinationIdList?: string;
  CityTABLEID?: string;
  MonthList?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  Adult?: number;
  Child?: number;
  PackageName?: string;
  Currency?: string;
  PriceTypeID?: number;
  IsRetail?: ReservationApiBoolean;
  PackageTypeID?: string;
  PackageTypeIDNot?: string;
  AgencyID?: number;
  DisplayPackageName?: string;
  PackageID?: number;
  PackageTypeList?: string
}
export interface IResPackageSnglSuppParams {
  PackageID: number;
  PriceTypeID?: number;
  Currency?: string;
  TravelDateID?: number;
  AgencyID?: number;
  PackageTravelDateID?: number;
}


export interface IResProductSearchParams {
  DestinationID?: string;
  CountryID?: string;
  CityID?: string;
  ProductCod?:string;
  DestinationIdList?: string;
  MonthList?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  Adult?: number;
  Child?: number;
  Name?: string;
  Currency?: string;
  PriceTypeID?: number;
  ServiceTypeID?: number;
  IsRetail?: ReservationApiBoolean;
  ShipID?: number | string;
  SuiteCategoryID?:number;
  Night?:number;
  BkgDate?: DateTime
  PageSize?:number
  PageNum?: number;
  VoyageID?: number;
  PackageTypeID?: number;
  PackageID?: number;
  VoyageNumber?: string;
  AvailableCategories?: string;
  AgencyID?: number;
  IndividualID?: number;

  PriceTypes?: string | number | number[];

  ElemNum?: number;
  ProductIDNot?: string | number | number[];
  TypeID?:number | string;
  CompaniesID?:string;
  CitiesID?:string;
  Countries?:string;
  SupplierIDList?:string;
  BkgID?: number;
  IsCharter?: ReservationApiBoolean;
}

export interface IResPackageSelectParams {
  PackageID: number|string;
  IsRetail?: ReservationApiBoolean;
  Currency?: string;
  PriceTypeID?: number;
  AgencyID?: number;
  PageNum?: number;
  PageSize?: number;
}

export interface IResCampSelectParams {
  CampID: number,
  Currency: string;
  PriceTypeID: number;
  AgencyID?:number
}

export interface IResProductServiceSelectParams {
  ServiceID: number
  Currency: string
  PriceTypeID: number
  AgencyID?:number
  DateFrom?: Date | string
  DateTo?: Date | string
}
export interface IResSuiteDeckplanParams {
  ShipCod: string;
  EntryDate: Date;
  DeckPlanStartFrom: Date | undefined;
}

interface IReservationApiCruiseSuiteDeckplanConfiguration {
  ShipCod: string;
  Ship: string;
  DeckPlanOn: boolean;
  GridOn: boolean;
  DeckPlanPath: string;
  Deck: string;
}
interface IReservationApiCruiseSuiteDeckplanSuiteInfo {
  EntIns: string;
  Name: string;
  Image1: string;
  Image2: string;
  Image3: string;
  Image4: string;
  Amenities: string;
  Services: string;
  StateroomConfiguration: string;
  SuiteCategoryCod: string;
  SuiteCategory: string;
}
interface IReservationApiCruiseSuiteDeckplanDeckInfo {
  DeckID: number;
  DeckNumber: number;
  DeckName: string;
}
export interface IReservationApiCruiseSuiteDeckplanModel {
  Configuration: IReservationApiCruiseSuiteDeckplanConfiguration;
  SuiteInfo: Array<IReservationApiCruiseSuiteDeckplanSuiteInfo>;
  DeckInfo: Array<IReservationApiCruiseSuiteDeckplanDeckInfo>;
}

export class ReservationCruiseSuiteDeckplanConfiguration extends SafeBaseObject {
  shipCod: string = '';
  ship: string = '';
  deckPlanOn: boolean = false;
  gridOn: boolean = false;
  deckPlanPath: string = '';
  deck: string = '';
  decks: number[] = [];

  get hasDeckPlan() {
    return (this.deckPlanPath ?? '').trim() !== '' && this.decks.length>0;
  }
  get hasDocument() {
    return (this.deckPlanPath ?? '').trim() !== '';
  }

  constructor(data?: IReservationApiCruiseSuiteDeckplanConfiguration) {
    super();
    if (data) {
      super.updateData(data);
      this.decks = this.deck.split(',').map(d => +d);
    }
  }
}
export class ReservationCruiseSuiteDeckplanSuiteInfo extends SafeBaseObject {

  entIns: string = '';
  name: string = '';
  image1: string = '';
  image2: string = '';
  image3: string = '';
  image4: string = '';
  images: Array<SafeResourceUrl> = [];
  amenities: string = '';
  services: string = '';
  stateroomConfiguration: string = '';
  amenitiesSH: SafeHtml = '';
  servicesSH: SafeHtml = '';
  stateroomConfigurationSH: SafeHtml = '';
  categoryCode: string = '';
  suiteCategoryCod: string = '';
  suiteCategory: string = '';
  title: string = '';

  constructor(data?: IReservationApiCruiseSuiteDeckplanSuiteInfo) {
    super();
    if (data) {
      super.updateData(data);
      const sanitizer = AppModule.injector.get(DomSanitizer);
      if (this.image1) this.images.push(sanitizer.bypassSecurityTrustResourceUrl(this.image1));
      if (this.image2) this.images.push(sanitizer.bypassSecurityTrustResourceUrl(this.image2));
      if (this.image3) this.images.push(sanitizer.bypassSecurityTrustResourceUrl(this.image3));
      if (this.image4) this.images.push(sanitizer.bypassSecurityTrustResourceUrl(this.image4));
      if (this.amenities) this.amenitiesSH = sanitizer.bypassSecurityTrustHtml(this.amenities);
      if (this.services) this.servicesSH = sanitizer.bypassSecurityTrustHtml(this.services);
      if (this.stateroomConfiguration) this.stateroomConfigurationSH = sanitizer.bypassSecurityTrustHtml(this.stateroomConfiguration);
      this.categoryCode = this.suiteCategoryCod;
      this.title = this.suiteCategory ?? '';
    }
  }
}

export class ReservationCruiseSuiteDeckplanDeckInfo extends SafeBaseObject {
  deckID: number = 0;
  deckNumber: number = 0;
  deckName: string = '';

  constructor(data?: IReservationApiCruiseSuiteDeckplanDeckInfo) {
    super();
    if (data) {
      super.updateData(data);
    }
  }
}
export class ReservationCruiseSuiteDeckplanModel extends SafeBaseObject {
  configuration: ReservationCruiseSuiteDeckplanConfiguration = new ReservationCruiseSuiteDeckplanConfiguration();
  suiteInfo: Array<ReservationCruiseSuiteDeckplanSuiteInfo> = [];
  deckInfo: Array<ReservationCruiseSuiteDeckplanDeckInfo>= [];
  constructor(data?: IReservationApiCruiseSuiteDeckplanModel) {
    super();
    if (data) {
      super.updateData(data);
      this.configuration = new ReservationCruiseSuiteDeckplanConfiguration(data.Configuration);
      if (_.isArray(data.SuiteInfo)) this.suiteInfo = data.SuiteInfo.map(si => new ReservationCruiseSuiteDeckplanSuiteInfo(si));
      if (_.isArray(data.DeckInfo)) this.deckInfo = data.DeckInfo.map(di => new ReservationCruiseSuiteDeckplanDeckInfo(di));
    }
  }
}

export interface IResProductCod {
  ProductCod: string;
  ProductDesc: string;
  ProductOrder: number;
  IsDefault: boolean;
}

export class ResProductCod extends SafeBaseObject {
  productCod: string = '';
  productDesc: string = '';
  productOrder: number = 0;
  isDefault: boolean = false;

  constructor(data?: IResProductCod) {
    super();
    if (data) {
      super.updateData(data);
    }
  }
}
