import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosBaseList } from '@common/models/eidos-base-list.model';
import { Observable, take } from 'rxjs';
import { EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { TranslocoService } from '@ngneat/transloco';
import { EidosConfigService } from '@app/core/config/eidos-config.service';

@Component({
  selector: 'eidos-settings-dialog',
  styleUrls: [
    'eidos-settings-dialog.component.scss'
  ],
  templateUrl: 'eidos-settings-dialog.component.html'
})

export class EidosSettingsDialogComponent implements OnInit, OnDestroy {

  public form = new UntypedFormGroup({
    language: new UntypedFormControl('', Validators.required),
    company: new UntypedFormControl('', Validators.required),
    organization: new UntypedFormControl('')
  });

  public availableLanguages$: Observable<Array<EidosBaseList>> = this.utility.getAvailableLanguages();

  public availableCompanies$: Observable<Array<EidosBaseList>> = this.utility.getAvailableCompanies();

  public availableOrganizations$: Observable<Array<EidosBaseList>> = this.utility.getAvailableOrganizations();

  protected enableOrganization: boolean=false;

  constructor(
    private dialogRef: MatDialogRef<EidosSettingsDialogComponent>
    , private utility: EidosUtilityService
    , private eidosLogService: EidosLogService
    , private translocoService: TranslocoService
    , private eidosConfigService: EidosConfigService
  ) {
  }

  ngOnInit(): void {
    let user = this.utility.getCurrentUser();
    this.form.get('language')?.setValue(user?.language);
    this.form.get('company')?.setValue(user?.company);
    this.form.get('organization')?.setValue(user?.organization);
    this.eidosConfigService.currentConfig
      .pipe(take(1))
      .subscribe(config => this.enableOrganization = config.enableOrganization ?? false);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    const language = this.form.get('language')?.value;
    const company = this.form.get('company')?.value;
    const organization = this.form.get('organization')?.value;

    const payload = { companyCode: company, languageCode: language };
    this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Eidos: update current settings:', payload);

    this.utility.updateCurrentSetting({ companyCode: company, languageCode: language, organizaionCode: organization }).subscribe(result => {
      if (result) {
        this.translocoService.setActiveLang(language);
        this.dialogRef.close();
      } else {
        this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Eidos: update current settings fail');
      }
    });
  }

  ngOnDestroy() {
  }
}
