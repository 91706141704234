// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material.module';
import { TranslocoRootModule } from '@app/transloco-root.module';
import { EidosRoutingModule } from './eidos-routing.module';
import { CoreModule } from '@common/core.module';

// Components
import { EidosComponent } from './eidos.component';
import { EidosDashboardComponent } from '@eidos/components/eidos-dashboard/eidos-dashboard.component';
import { EidosDashboardItemComponent } from '@eidos/components/eidos-dashboard-item/eidos-dashboard-item.component';
import { EidosContextMenuComponent } from '@eidos/components/eidos-context-menu/eidos-context-menu.component';

import { EidosObjectComponent } from '@eidos/components/objects/eidos-object/eidos-object.component';
import { EidosObjectGridComponent } from '@eidos/components/objects/eidos-object-grid/eidos-object-grid.component';
import { EidosObjectHtmlComponent } from '@eidos/components/objects/eidos-object-html/eidos-object-html.component';
import { EidosObjectEmptyComponent } from '@eidos/components/objects/eidos-object-empty/eidos-object-empty.component';
import { EidosObjectUiComponent } from '@eidos/components/objects/eidos-object-ui/eidos-object-ui.component';
import { EidosObjectChartComponent } from '@eidos/components/objects/eidos-object-chart/eidos-object-chart.component';
import { EidosObjectActionComponent } from '@eidos/components/objects/eidos-object-action/eidos-object-action.component';

import { EidosFieldBitComponent } from '@eidos/components/fields/eidos-field-bit/eidos-field-bit.component';
import { EidosFieldBaseComponent } from '@eidos/components/fields/eidos-field-base/eidos-field-base.component';
import { EidosFieldFileComponent } from '@eidos/components/fields/eidos-field-file/eidos-field-file.component';
import { EidosFieldLookupComponent } from '@eidos/components/fields/eidos-field-lookup/eidos-field-lookup.component';
import { EidosFieldHtmlComponent } from '@eidos/components/fields/eidos-field-html/eidos-field-html.component';
import { EidosFieldDateTimeComponent } from '@eidos/components/fields/eidos-field-datetime/eidos-field-datetime.component';
import { EidosFieldComponent } from '@eidos/components/fields/eidos-field/eidos-field.component';
import { EidosFieldFilterComponent } from '@eidos/components/fields/eidos-field-filter/eidos-field-filter.component';
import { EidosFieldRowCommandComponent } from '@eidos/components/fields/eidos-field-row-command/eidos-field-row-command.component';
import { FormsModule } from '@angular/forms';
import { DxDataGridModule } from 'devextreme-angular';
import { CoreSafeHtmlPipe } from '@app/standalones/core-safe-html.pipe';

@NgModule({
  declarations: [
    EidosComponent,
    EidosContextMenuComponent,
    EidosDashboardComponent,
    EidosDashboardItemComponent,

    EidosObjectComponent,
    EidosObjectActionComponent,
    EidosObjectChartComponent,
    EidosObjectUiComponent,
    EidosObjectEmptyComponent,
    EidosObjectHtmlComponent,
    EidosObjectGridComponent,

    EidosFieldBitComponent,
    EidosFieldBaseComponent,
    EidosFieldFileComponent,
    EidosFieldLookupComponent,
    EidosFieldHtmlComponent,
    EidosFieldDateTimeComponent,
    EidosFieldComponent,
    EidosFieldFilterComponent,
    EidosFieldRowCommandComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslocoRootModule,
    CoreModule,
    EidosRoutingModule,
    DxDataGridModule,
    CoreSafeHtmlPipe,
  ],
})
export class EidosModule {}
