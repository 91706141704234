<div class="eidos-container"
  [class]="mainClass"
  [class.eidos-container-mobile]="mobileQuery.matches && isAuthenticated">
  <eidos-topbar class="eidos-header"
    *ngIf="isAuthenticated && !hideTopbar"
    (hamburgerClickEvent)="toggleSidebar()">
  </eidos-topbar>

  <div class="eidos-body">
    <dx-drawer
      class="eidos-body-wrapper"
      openedStateMode="shrink"
      position="left"
      revealMode="slide"
      template="drawerTemplate"
      [(opened)]="isDrawerOpen"
      [closeOnOutsideClick]="true"
    >
      <div
        class="px-3 drawer-template"
        *dxTemplate="let data of 'drawerTemplate'"
      >
        <div *ngIf="!hideSidebar" class="drawer-sidebar">
          <div class="d-flex justify-content-end my-3 drawer-sidebar-buttons">
            <dx-button
              [hint]="
                isSearchBarVisible
                  ? 'Hide the searchbar'
                  : 'Active the searchbar'
              "
              stylingMode="contained"
              [icon]="isSearchBarVisible ? 'close' : 'search'"
              type="normal"
              (onClick)="toggleSearchBar()"
            ></dx-button>
            <dx-button
              hint="Sorts menu items"
              class="mx-2"
              stylingMode="contained"
              [icon]="
                menuOrder == 'az'
                  ? 'sortdowntext'
                  : menuOrder == 'za'
                  ? 'sortuptext'
                  : 'sorted'
              "
              type="normal"
              (onClick)="menuOrderChange()"
            ></dx-button>
            <dx-button
              [hint]="isLocked ? 'Unpin menu' : 'Pin menu'"
              stylingMode="contained"
              [icon]="isLocked ? 'pin' : 'unpin'"
              type="normal"
              (onClick)="toggleLock()"
            ></dx-button>
          </div>
          <dx-tree-view
            id="menu-treeview"
            [class.visible-searchbar]="isSearchBarVisible"
            [items]="orderedDisplayedMenuItems"
            [width]="300"
            displayExpr="label"
            itemsExpr="children"
            [searchEnabled]="true"
            searchMode="contains"
            [(searchValue)]="searchBarMenuValue"
            (onItemClick)="selectItem($event)"
            (onOptionChanged)="optionChanged($event)"
            (onContentReady)="onContentReady($event)"
            itemTemplate="itemTemplate"
          >
            <div *dxTemplate="let i of 'itemTemplate'">
              <div class="d-flex justify-content-between align-items-center">
                <div>{{ i.label }}</div>
                <div *ngIf="i.icon">
                  <eidos-icon
                    class="menu-list-item-icon"
                    [iconConfiguration]="i.getSmallIcon()"
                  ></eidos-icon>
                </div>
              </div>
            </div>
          </dx-tree-view>
        </div>
      </div>

      <div id="content" class="h-100"
        [ngClass]="{ 'eidos-logged-in-main-container': isAuthenticated && !hideTopbar}">
        <eidos-external-app-container [hidden]="!extAppMode"></eidos-external-app-container>
        <eidos-progress-spinner *ngIf="onPrepareEnvironment"></eidos-progress-spinner>
        <router-outlet></router-outlet>
      </div>
    </dx-drawer>
  </div>

  <eidos-command-panel class="eidos-footer"></eidos-command-panel>
</div>

<eidos-modal></eidos-modal>
