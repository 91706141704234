import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IEidosFiltersRegistry } from "@common/models/eidos-filters.models";
import { EidosFilterComponent } from "@common/components/entities/filters/eidos-filter/eidos-filter.component";

@Component({
  selector: 'eidos-filter-switch',
  templateUrl: './eidos-filter-switch.component.html',
  styleUrls: ['./eidos-filter-switch.component.scss']
})
export class EidosFilterSwitchComponent extends EidosFilterComponent {
  /**
   * Current filter value (2-way binding)
   *
   * @type {IEidosFiltersRegistry["switch"]["valueType"]}
   * @memberof EidosFilterSwitchComponent
   */
  protected _value: IEidosFiltersRegistry["switch"]["valueType"];
  @Input() public get value(): IEidosFiltersRegistry["switch"]["valueType"] {
    return this._value;
  }
  public set value(val: IEidosFiltersRegistry["switch"]["valueType"]) {
    this._value = val;
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<IEidosFiltersRegistry["switch"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["switch"]["valueType"]>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["switch"]["config"]}
   * @memberof EidosFilterSwitchComponent
   */
  @Input() public config: IEidosFiltersRegistry["switch"]["config"];

  constructor() {
    super();
  }
}
