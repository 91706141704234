<content *ngIf="fieldData && fieldConfig">
  <content [ngSwitch]="fieldConfig.eidosFieldType">
    <ng-template [ngSwitchCase]="EidosObjectFieldType.Date">
      <div class="eidos-field">{{fieldData[fieldConfig.name] | date: 'dd/MM/yyyy'}}</div>
    </ng-template>

    <ng-template [ngSwitchDefault]>
      <div class="eidos-field">{{fieldData[fieldConfig.name]}}</div>
    </ng-template>

  </content>



</content>
