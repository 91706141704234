import { Injectable } from "@angular/core";
import { v4 as uuid } from 'uuid';
import * as d3ext from '@assets/js/d3-ext/liquid-fill-gauge.js';
import * as d3extGs from '@assets/js/d3-ext/gs-gauge.js';

import { IGsGaugeConfiguration, ILevelGaugeConfiguration } from "@common/models/eidos-chart-js.interface";
import { EidosChartJSConfiguration, EidosChartType, IEidosChartValue } from "@common/models/eidos-chart-js.model";
import { IEidosIcon } from "@common/config/environment.interface";

import { IEidosOrderBy, IEidosObjectBaseData,  IEidosObjectChartDimension, IEidosObjectConfiguration, IEidosObjectFilterData, IEidosObjectProperty, IEidosObject, IEidosFilter, IEidosHtmlConfigurationData, IEidosChartConfigurationData, IEidosScalarChartConfigurationData, IMyBizGridHeader, IMyBizGridFieldConfig, EidosObjectOptions, IEidosObjectPlaceholder } from "@common/models/eidos-object.interface";
import { MyBizApiResponse, MyBizFilterType } from "@common/models/mybiz.model";
import { IEidosDashbordCellObjectGridOptions } from "@common/models/eidos-dashboard.interface";
import { EidosDashboardConfig } from "@common/models/eidos-dashboard.model";

export type IEidosGridValue = Array<any>;

@Injectable()
export class EidosObject implements IEidosObject {
  objectId: number = 0;
  column: number = 0;
  row: number = 0;
  columnSize: number = 0;
  rowSize: number = 0;
  mode: EidosObjectMode = EidosObjectMode.None;
  eidosObjectId: number = -1;
  icon: IEidosIcon | null = null;
  foregroundColor: string | null = null;
  backgroundColor: string | null = null;
  borderRadius: string | null = null;
  objectType: EidosObjectActionType = EidosObjectActionType.Eidos;
  /**
   * Eidos object options
   *
   * @type {EidosObjectOptions}
   * @memberof EidosObject
   */
  options: EidosObjectOptions = null;
  label: string | null = null;
  tooltip: string | null = null;
  pageSize: number | null = null;

  loading = false;
  objectDataPage: number = 1;
  /**
   * Sorting applied to object
   *
   * @type {Array<EidosDataObjectFieldSort>}
   * @memberof EidosObject
   */
  sort: Array<EidosDataObjectFieldSort> = [];
  /**
   * Filters applied to object
   *
   * @type {Array<EidosDataObjectFieldFilter>}
   * @memberof EidosObject
   */
  filters: Array<EidosDataObjectFieldFilter> = [];

  /**
   * Create a new Eidos object
   *
   * @static
   * @param {(string | null)} id
   * @return {*}
   * @memberof EidosObject
   */
  static CreateEidosObject(id: string | null) {
    const obj = new EidosObject();
    obj.objectId = +(id || 0);
    obj.mode = EidosObjectMode.Object;
    return obj;
  }
}


class EidosObjectChartConfig {

  chartType: string;
  chartSubType?: string;
  propertyBags: IEidosObjectProperty[];
  dimension: Array<EidosObjectChartDimension>;

  constructor(data: [any], config: [any], dimension: [IEidosObjectChartDimension]) {
    this.chartType = '';
    this.propertyBags = [];
    this.dimension = [];

    if (data && Array.isArray(data) && data.length > 0) {
      this.chartType = data[0].chartType;
      this.chartSubType = data[0].chartSubType;

      if (config && Array.isArray(config)) {
        //TODO resule property functio/sp ... etc
        //....
        this.propertyBags = config.map(cfg => {
          return {
            name: cfg.configName,
            value: cfg.configValues
          };
        });
      }

      if (dimension && Array.isArray(dimension)) {
        this.dimension = dimension.map(d => new EidosObjectChartDimension(d));
      }
    }
  }
}

export class EidosObjectChartDimension {
  /**
   * Property of dataobject as X dimension
   *
   * @type {string}
   * @memberof EidosObjectChartDimension
   */
  xField: string;
  /**
   * Property of dataobject as Y dimension
   *
   * @type {string}
   * @memberof EidosObjectChartDimension
   */
  yField?: string;
  /**
   * Property of dataobject as Z dimension
   *
   * @type {string}
   * @memberof EidosObjectChartDimension
   */
  zField?: string;
  /**
   * Format of property of dataobject as X dimension
   *
   * @type {string}
   * @memberof EidosObjectChartDimension
   */
  xFieldFormat?: string;
  /**
   * Format of property of dataobject as Y dimension
   *
   * @type {string}
   * @memberof EidosObjectChartDimension
   */
  yFieldFormat?: string;
  /**
   * Format of property of dataobject as Y dimension
   *
   * @type {string}
   * @memberof EidosObjectChartDimension
   */
  zFieldFormat?: string;
  /**
   * Type of property of dataobject as X dimension
   *
   * @type {EidosChartJSDataType}
   * @memberof EidosObjectChartDimension
   */
  xFieldType?: EidosChartJSDataType;
  /**
   * Type of property of dataobject as X dimension
   *
   * @type {EidosChartJSDataType}
   * @memberof EidosObjectChartDimension
   */
  yFieldType?: EidosChartJSDataType;
  /**
   * Type of property of dataobject as Z dimension
   *
   * @type {EidosChartJSDataType}
   * @memberof EidosObjectChartDimension
   */
  zFieldType?: EidosChartJSDataType;

  constructor(d?: IEidosObjectChartDimension) {
    this.xField = "";
    this.yField = "";
    if (d) {
      this.xField = d.xField,
        this.yField = d.yField,
        this.zField = d.zField,
        this.xFieldFormat = d.xFieldFormat,
        this.yFieldFormat = d.yFieldFormat,
        this.zFieldFormat = d.zFieldFormat,
        this.xFieldType = d.xFieldType ? <EidosChartJSDataType>d.xFieldType : undefined,
        this.yFieldType = d.yFieldType ? <EidosChartJSDataType>d.yFieldType : undefined,
        this.zFieldType = d.yFieldType ? <EidosChartJSDataType>d.zFieldType : undefined
    }
  }
}

export class EidosObjectHtmlConfig implements IEidosHtmlConfigurationData {
  template: string;
  placeholderValues: Array<EidosObjectPlaceholder>;
  templateResolved: string;
  filters: IEidosObjectFilterData[];

  constructor(data: [any], details: [any]) {
    this.template = '';
    this.templateResolved = '';
    this.placeholderValues = [];
    this.filters = [];
    if (data && Array.isArray(data) && data.length > 0) {
      this.template = data[0].template;
    }
    if (details && Array.isArray(details) && details.length > 0) {
      details.forEach(d => this.placeholderValues.push(new EidosObjectPlaceholder(d)));
    }
  }
}

class EidosObjectPlaceholder implements IEidosObjectPlaceholder {
  placeholder: string;
  value: string;

  constructor(data: any) {
    this.placeholder = '';
    this.value = '';

    if (data) {
      this.placeholder = data.placeholder;
      this.value = data.value;
    }
  }
}

/**
 * Eidos grid object options
 * (Implements interface of options retrieved by dashboard cell configuration)
 *
 * @export
 * @class EidosObjectGridOptions
 * @implements {IEidosDashbordCellObjectGridOptions}
 */
export class EidosObjectGridOptions implements IEidosDashbordCellObjectGridOptions {
  label: string;
  tooltip: string;
  inlineInsert: boolean;
  inlineDelete: boolean;
  inlineUpdate: boolean;
  canExport: boolean;
  canImport: boolean;
  canOrder: boolean;
  /**
   * Enable Update in form row command
   *
   * @type {boolean}
   * @memberof EidosObjectGridOption
   */
  canUpdate: boolean;
  /**
   * Enable Delete row command
   *
   * @type {boolean}
   * @memberof EidosObjectGridOption
   */
  canDelete: boolean;
  detailsOpenOnLoad: boolean;
  hideButtonsColumn: boolean;
  /**
   * Hide grid header filters
   *
   * @type {boolean}
   * @memberof EidosObjectGridOption
   */
  hideFilters: boolean;
  /**
   * Hide column filters in grid flag
   *
   * @type {boolean}
   * @memberof EidosObjectGridOption
   */
  hideColumnFilters: boolean;
  reloadOnInsert: boolean;
  reloadOnDelete: boolean;
  reloadOnUpdate: boolean;
  showAggregate: boolean;
  minimizePager: boolean;
  pageSize: number;
  /**
   * Hides grid header (retrieved from dashboard cell configuration)
   *
   * @type {boolean}
   * @memberof EidosObjectGridOption
   */
  hideHeader: boolean;
  /**
   * Hides grid paginator (retrieved from dashboard cell configuration)
   *
   * @type {boolean}
   * @memberof EidosObjectGridOption
   */
  hidePager: boolean;
  /**
   * Hides grid scrollbar (retrieved from dashboard cell configuration)
   *
   * @type {boolean}
   * @memberof EidosObjectGridOption
   */
  hideScrollbar: boolean;

  constructor(data?: IMyBizGridHeader) {
    this.canDelete = false;
    this.canUpdate = false;

    this.canExport = data?.can_export == true;
    this.canImport = data?.can_import == true;
    this.canOrder = data?.can_order == true;
    this.detailsOpenOnLoad = data?.details_open_on_load == true;
    this.hideButtonsColumn = data?.hide_buttons_column == true;
    this.hideColumnFilters = data?.hide_columns_filters == true || false;
    this.hideFilters = data?.hide_filters == true || false;
    this.inlineDelete = data?.inline_delete == true || false;
    this.inlineInsert = data?.inline_insert == true || false;
    this.inlineUpdate = data?.inline_update == true || false;
    this.label = data?.label || '';
    this.reloadOnDelete = data?.reload_on_delete == true;
    this.reloadOnInsert = data?.reload_on_insert == true;
    this.reloadOnUpdate = data?.reload_on_update == true;
    this.showAggregate = data?.show_aggregate == true;
    this.tooltip = data?.tooltip || '';
    this.minimizePager = /*data?. ||*/ false;
    this.pageSize = data?.pageSize || 10;
    this.hideHeader = false;
    this.hidePager = false;
    this.hideScrollbar = false;
  }
}

export class EidosObjectGridField {
  id: string;
  name: string;
  fileId: string;
  isVisible: boolean;
  isPrimaryKey: boolean;
  position: number;
  label: string;
  dataType: string;
  dataSubtype: string;
  size: string;
  eidosFieldType: EidosObjectFieldType;

  constructor(data: IMyBizGridFieldConfig, public index: number) {
    this.name = data.nome_campo;
    this.label = data.label;
    this.position = data.posizione || index;
    this.id = `${this.name}-${this.position}`;
    this.isVisible = data.visibile ?? true;
    this.isPrimaryKey = data.chiave;
    this.dataType = data.tipi_campo_cod;
    this.dataSubtype = data.variante_cod;
    this.fileId = data.campo_nome_file;
    this.size = data.size_cod ?? 'm';

    switch (this.dataType) {
      case EidosObjectFieldTypeCode.Bit:
        this.eidosFieldType = EidosObjectFieldType.Boolean;
        break;
      case EidosObjectFieldTypeCode.Int:
        switch (this.dataSubtype) {
          case EidosObjectFieldTypeVariant.NumberWithoutSeparators:
            this.eidosFieldType = EidosObjectFieldType.Integer;
            break;
          default:
            this.eidosFieldType = EidosObjectFieldType.IntegerWithSeparators;
            break;
        }
        break;
      case EidosObjectFieldTypeCode.Dec:
        switch (this.dataSubtype) {
          case EidosObjectFieldTypeVariant.Percentage:
            this.eidosFieldType = EidosObjectFieldType.Percentage;
            break;
          default:
            this.eidosFieldType = EidosObjectFieldType.Decimal;
            break;
        }
        break;
      case EidosObjectFieldTypeCode.Date:
        switch (this.dataSubtype) {
          case EidosObjectFieldTypeVariant.DateTmz:
            this.eidosFieldType = EidosObjectFieldType.DateTmz;
            break;
          default:
            this.eidosFieldType = EidosObjectFieldType.Date;
            break;
        }
        break;
      case EidosObjectFieldTypeCode.Time:
        switch (this.dataSubtype) {
          case EidosObjectFieldTypeVariant.TimeTmz:
            this.eidosFieldType = EidosObjectFieldType.TimeTmz;
            break;
          default:
            this.eidosFieldType = EidosObjectFieldType.Time;
            break;
        }
        break;
      case EidosObjectFieldTypeCode.Lookup:
        this.eidosFieldType = EidosObjectFieldType.Lookup;
        break;
      case EidosObjectFieldTypeCode.Text:
        switch (this.dataSubtype) {
          case EidosObjectFieldTypeVariant.Html:
            this.eidosFieldType = EidosObjectFieldType.Html;
            break;
          case EidosObjectFieldTypeVariant.Textarea:
            this.eidosFieldType = EidosObjectFieldType.TextMultiLine;
            break;
          case EidosObjectFieldTypeVariant.Icon:
            this.eidosFieldType = EidosObjectFieldType.Icon;
            break;
          default:
            this.eidosFieldType = EidosObjectFieldType.Text;
            break;
        }
        break;
        break;
      case EidosObjectFieldTypeCode.Combo:
        this.eidosFieldType = EidosObjectFieldType.Combo;
        break;
      case EidosObjectFieldTypeCode.File:
        switch (this.dataSubtype) {
          case EidosObjectFieldTypeVariant.Image:
            this.eidosFieldType = EidosObjectFieldType.Image;
            break;
          case EidosObjectFieldTypeVariant.Drawing:
            this.eidosFieldType = EidosObjectFieldType.Drawing;
            break;
          default:
            this.eidosFieldType = EidosObjectFieldType.File;
            break;
        }
        break;
      case EidosObjectFieldTypeCode.FileDms:
        this.eidosFieldType = EidosObjectFieldType.Document;
        break;
      case EidosObjectFieldTypeCode.Object:
        this.eidosFieldType = EidosObjectFieldType.Object;
        break;
      case EidosObjectFieldTypeCode.Multiselection:
        this.eidosFieldType = EidosObjectFieldType.Multiselection;
        break;
      default:
        switch (this.dataSubtype) {
          case EidosObjectFieldTypeVariant.Html:
            this.eidosFieldType = EidosObjectFieldType.Html;
            break;
          case EidosObjectFieldTypeVariant.Textarea:
            this.eidosFieldType = EidosObjectFieldType.TextMultiLine;
            break;
          case EidosObjectFieldTypeVariant.Icon:
            this.eidosFieldType = EidosObjectFieldType.Icon;
            break;
          default:
            this.eidosFieldType = EidosObjectFieldType.Text;
            break;
        }
        break;
    }
  }
}
class EidosFieldTag {
  constructor(public name: string, public index: number) { }
}
export class EidosObjectGridConfig {
  /**
   * Grid object id
   *
   * @type {number}
   * @memberof EidosObjectGridConfig
   */
  objectId?: number;
  /**
   * (Or next_object_id)
   * Object for edit in form
   *
   * @type {number}
   * @memberof EidosObjectGridConfig
   */
  childId?: number;
  options: EidosObjectGridOptions;
  fields: Array<EidosObjectGridField>;
  filters: Array<any>;
  primaryKeys: Array<EidosFieldTag>;

  constructor(grid: IMyBizGridHeader | undefined, fields: [IMyBizGridFieldConfig], childId?: number) {
    this.objectId = grid?.object_id;
    this.childId = childId;
    this.options = new EidosObjectGridOptions(grid);
    this.fields = [];
    this.filters = [];
    this.primaryKeys = [];
    if (fields && Array.isArray(fields)) {
      this.fields = fields.map((f, idx) => new EidosObjectGridField(f, idx));
      this.primaryKeys = this.fields.filter(f => f.isPrimaryKey).map(f => new EidosFieldTag(f.name, f.index));
    }
  }
}

export class EidosObjectApiResponse {
  baseData: IEidosObjectBaseData;
  objectData?: EidosObjectChartConfig | EidosObjectHtmlConfig | EidosObjectGridConfig | EidosDashboardConfig;
  detailsData?: [any];
  paginationData?: [any];

  constructor(data?: any) {
    this.baseData = {
      Description: '',
      LabelDefault: '',
      ObjectType: 'unknown',
      objectRefreshCod: 'none',
      interval: 0,
      create: false,
      delete: false,
      update: false,
      isAdmin: false,
      read: false,
      filter: [],
      pageSize: null
    };
    if (data && data.DT0 && Array.isArray(data.DT0) && data.DT0.length > 0) {
      this.baseData = data.DT0[0];
      this.baseData.filter = data.DT1;
      switch (this.baseData.ObjectType) {
        case 'chart':
          this.objectData = new EidosObjectChartConfig(data.DT2, data.DT3, data.DT4);
          break;
        case 'html':
          this.objectData = new EidosObjectHtmlConfig(data.DT2, data.DT3);
          break;
        case 'dashboard':
          const dashboard = data.DT2?.length > 0 ? data.DT2[0] : null;
          this.objectData = new EidosDashboardConfig(dashboard, data.DT3);
          break;
        case 'grid':
          const grid = data.DT2.length > 0 ? data.DT2[0] as IMyBizGridHeader : undefined;
          this.objectData = new EidosObjectGridConfig(grid, data.DT3, this.baseData.ChildId);
          break;
      }
    }
  }
}

export class EidosObjectDataApiResponse {
  isValid: boolean;
  isOldStyle: boolean;
  columns: string[];
  values: Array<Array<any> | { [key: string]: number }>;
  pageData: EidosDataObjectPagination;
  constructor(response?: MyBizApiResponse) {
    this.columns = [];
    this.values = Array<Array<any>>();
    this.isOldStyle = false;
    this.isValid = false;
    this.pageData = new EidosDataObjectPagination(1, 1, 0, 0); //no pagination

    if (response && response.data) {
      if (Array.isArray(response.data)) {
        //EIDOS STYLE
        if (response.data.length > 0) this.columns = Object.keys(response.data[0]);
        response.data.forEach((d: any) => {
          if (d.pageSize && d.page && d.pages) {
            this.pageData = new EidosDataObjectPagination(d.page, d.pages, d.pageSize, d.righe_totali);
          } else {
            this.values.push(d)
          }
        });
      } else {
        //MYBIZ STYLE
        this.pageData = new EidosDataObjectPagination(response.data.page, response.data.pagine_totali, response.data.rows_per_page, response.data.righe_totali)
        this.isOldStyle = true;
        this.columns = response.data.colonne;
        response.data.righe.forEach((d: any) => {
          this.values.push(d)
        });
      }
      this.isValid = true;
    }
  }
}

export class EidosObjectConfiguration implements IEidosObjectConfiguration {
  type = 'config';
  /**
   * Object type
   *
   * @type {EidosObjectType}
   * @memberof EidosObjectConfiguration
   */
  public objectType: EidosObjectType = EidosObjectType.Unknown;
  /**
   * Object detail type
   *
   * @type {EidosChartType}
   * @memberof EidosObjectConfiguration
   */
  public objectDetailType: EidosChartType = EidosChartType.Empty;
  /**
   * Object refresh policy
   *
   * @type {EidosObjectRefreshType}
   * @memberof EidosObjectConfiguration
   */
  public refreshType: EidosObjectRefreshType = EidosObjectRefreshType.None;
  /**
   * Object data refresh interval (for Timer policy)
   *
   * @memberof EidosObjectConfiguration
   */
  public refreshInterval?: number;
  /**
   * Object data page size (for grid or other objec that need pagination)
   *
   * @memberof EidosObjectConfiguration
   */
  public pageSize: number | null = null;
  /**
   * Object filters
   *
   * @type {Array<IEidosObjectFilterData>}
   * @memberof EidosObjectConfiguration
   */
  public filters: Array<IEidosObjectFilterData> = [];
  /**
   * Object description for user UI
   *
   * @memberof EidosObjectConfiguration
   */
  public description: string;

  public objectDetails?: IEidosChartConfigurationData | IEidosScalarChartConfigurationData | EidosDashboardConfig | EidosObjectGridConfig | IEidosHtmlConfigurationData;

  constructor(data: EidosObjectApiResponse) {
    this.description = '';
    if (data.baseData) {
      const apiType = data.baseData.ObjectType.charAt(0).toUpperCase() + data.baseData.ObjectType.slice(1);
      this.objectType = EidosObjectType[apiType as keyof typeof EidosObjectType];
      if (data.baseData.objectRefreshCod) {
        const refreshType = data.baseData.objectRefreshCod.charAt(0).toUpperCase() + data.baseData.objectRefreshCod.slice(1);
        this.refreshType = EidosObjectRefreshType[refreshType as keyof typeof EidosObjectRefreshType];
        switch (this.refreshType) {
          case EidosObjectRefreshType.Timer:
            this.refreshInterval = data.baseData.interval ?? 0;
            break;
          default:
            break;
        }
      }
      this.pageSize = data.baseData.pageSize;
      this.filters = data.baseData.filter;
    }

    switch (this.objectType) {
      case EidosObjectType.Chart:
        if (data.objectData) {
          const objData = data.objectData as EidosObjectChartConfig;
          const apiType = objData.chartType.charAt(0).toUpperCase() + objData.chartType.slice(1);
          this.objectDetailType = EidosChartType[apiType as keyof typeof EidosChartType];
          switch (this.objectDetailType) {
            case EidosChartType.Level:
              {
                let config: ILevelGaugeConfiguration = d3ext.liquidFillGaugeDefaultSettings() as ILevelGaugeConfiguration;
                config.circleThickness = 0.1;
                config.circleFillGap = 0.1;
                config.textVertPosition = 0.8;
                config.waveAnimateTime = 5000;
                config.waveHeight = 0.15;
                config.waveAnimate = true;
                config.waveOffset = 0.25;
                config.valueCountUp = false;
                config.displayPercent = false;

                objData.propertyBags.forEach(p => {
                  switch (p.name) {
                    case 'max':
                      config.maxValue = +p.value;
                      break;
                    case 'title':
                      config.title = p.value;
                      break;
                  }
                });

                this.objectDetails = {
                  title: config.title,
                  type: EidosChartType.Level,
                  config: config,
                  values: 0,
                  fieldName: objData.dimension.length > 0 ? objData.dimension[0].xField : undefined
                } as IEidosScalarChartConfigurationData;

              }
              break;
            case EidosChartType.Gauge:
              {
                let config: IGsGaugeConfiguration = d3extGs.GsGaugeConfiguration() as IGsGaugeConfiguration;

                objData.propertyBags.forEach(p => {
                  switch (p.name) {
                    case 'title':
                    case 'label':
                      config[p.name] = p.value;
                      break;
                    case 'greenZones':
                    case 'yellowZones':
                    case 'redZones':
                      // TODO: capire come verrebbero passate dal server 2+ zone di ugual colore
                      const values = p.value.split(',').map(val => parseInt(val));
                      config[p.name] = [{
                        "from": +values[0],
                        "to": +values[1],
                      }];
                      break;
                    case 'minorTicks':
                    case 'size':
                    case 'max':
                      config[p.name] = +p.value;
                      break;
                  }
                });

                this.objectDetails = {
                  title: config.title,
                  type: EidosChartType.Gauge,
                  config: d3extGs.GsGaugeConfiguration(config) as IGsGaugeConfiguration,
                  values: 0,
                  fieldName: objData.dimension.length > 0 ? objData.dimension[0].xField : undefined
                } as IEidosScalarChartConfigurationData;

                // this.objectDetails.config.filters = this.filters;
              }
              break;
            case EidosChartType.Chartjs:
              const xAxisLabel = objData.propertyBags.find(prop => prop.name === "xAxisLabel")?.value;
              const yAxisLabel = objData.propertyBags.find(prop => prop.name === "yAxisLabel")?.value;
              this.objectDetails = {
                type: EidosChartType.Chartjs,
                title: data.baseData.LabelDefault,
                config: new EidosChartJSConfiguration({
                  propertyBags: objData.propertyBags,
                  dimensions: objData.dimension,
                  type: objData.chartSubType,
                  filters: this.filters
                }, xAxisLabel, yAxisLabel)
              };
              break;
            default:
              this.objectDetails = {
                type: EidosChartType.Empty,
                config: {}
              };
              break;
          }
        }
        break;
      case EidosObjectType.Html:
        if (data.objectData) {
          const objData = data.objectData as EidosObjectHtmlConfig;
          this.objectDetails = {
            template: objData.template,
            placeholderValues: objData.placeholderValues,
            filters: this.filters
          } as IEidosHtmlConfigurationData;
        }
        break;
      case EidosObjectType.Grid:
        if (data.objectData) {
          let config = data.objectData as EidosObjectGridConfig;
          if (data.baseData.pageSize) {
            config.options.pageSize = data.baseData.pageSize;
          }
          config.options.canUpdate = data.baseData.update;
          config.options.canDelete = data.baseData.delete;
          this.objectDetails = config;
        }
        break;
      case EidosObjectType.Dashboard:
        if (data.objectData) {
          this.objectDetails = data.objectData as EidosDashboardConfig;
          this.objectDetails.enabled = true;
        }
        break;
    }
  }
}

export class EidosObjectActionMessage {
  public guid: string;
  public data: any;
  public sourceId?: number;
  constructor(
    public action: EidosObjectEventAction
    , public request: EidosObjectEventActionRequest
    , public tagetId: number
  ) {
    this.guid = uuid();
  }

}
export class EidosObjectEvent {
  public message: string;
  public actionMessage?: EidosObjectActionMessage
  public data?: EidosObjectData;
  public config?: EidosObjectConfiguration;
  constructor(
    public type: EidosObjectEventType
    , public id?: number
  ) {
    this.message = '';
  }
}

export class EidosObjectData {
  currentPage: number;
  totalPages: number;
  totalRows: number;
  pageSize: number;
  data: EidosObjectDataApiResponse;
  value: IEidosChartValue | IEidosGridValue;
  constructor(data: EidosObjectDataApiResponse) {
    this.currentPage = 1;
    this.pageSize = 10;
    this.data = data;
    this.value = 0;
    this.totalPages = 1;
    this.totalRows = 0;
  }
}

/**
 * Type of Eidos object events enum
 *
 * @export
 * @enum {number}
 */

export enum EidosObjectEventType {
  LoadComplete,
  ReloadComplete,
  ReloadRequired,
  ReloadData,
  RefreshRequired,
  LoadError,
  Message,
  QueryMessage,
  ResponseMessage
}

/**
 * Type of Eidos object event action enum
 *
 * @export
 * @enum {number}
 */

export enum EidosObjectEventAction {
  ToggleHeaderFilters,
  ToggleFieldFilters,
  ReloadData,
  ReloadObject
}
/**
 * Type of requeste on the Eidos object event action enum
 *
 * @export
 * @enum {number}
 */
export enum EidosObjectEventActionRequest {
  Unknown,
  Enquery,
  Trigger
}
export enum EidosObjectType {
  Unknown,
  Action,
  Chart,
  Html,
  Grid,
  Dashboard
}

export enum EidosObjectFieldType {
  Boolean,
  Integer,
  IntegerWithSeparators,
  Decimal,
  Percentage,
  Date,
  DateTmz,
  Time,
  TimeTmz,
  Lookup,
  Text,
  Html,
  TextMultiLine,
  Combo,
  File,
  Image,
  Drawing,
  Document,
  Object,
  Multiselection,
  Icon
}

enum EidosObjectFieldTypeCode {
  Bit = 'bit',
  Int = 'int',
  Dec = 'decimal',
  Date = 'date',
  Time = 'time',
  Lookup = 'lookup',
  Text = 'text',
  Combo = 'combo',
  File = 'file',
  FileDms = 'filedms',
  Object = 'object',
  Multiselection = 'multi'
}

enum EidosObjectFieldTypeVariant {
  //Date
  DateTmz = 'date_fuso',
  //Time
  TimeTmz = 'time_fuso',
  //Decimal
  Percentage = 'percent',
  //File
  Image = 'image',
  Drawing = 'drawing',
  //Int
  NumberWithoutSeparators = 'no_separator',
  //Text
  Textarea = 'textarea',
  Html = 'html',
  Icon = 'icon',
}

export enum EidosObjectRefreshType {
  None = 'none',
  Push = 'push',
  Timer = 'timer'
}

export enum EidosObjectChartType {
  Unknown,
  Chart,
  Level
}

/**
 * Object main type
 *
 * @export
 * @enum {number}
 */
export enum EidosObjectMode {
  None,
  Action,
  Object
}

/**
 * Action object subtype
 *
 * @export
 * @enum {number}
 */
export enum EidosObjectActionType {
  Mybiz = "mybiz",
  Extapp = "extapp",
  Eidos = "eidos",
  Home = "home",
  Route = "route"
}

/**
 * (From MyBiz API) Model of Eidos object data request
 *
 * @export
 * @class EidosDataRequest
 */
export class EidosDataRequest {
  /**
   * ?
   *
   * @type {boolean}
   * @memberof EidosDataRequest
   */
  public isOldDataObject: boolean = true;
  /**
   * ID dell'object
   *
   * @type {number}
   * @memberof EidosDataRequest
   */
  public objectId?: number;
  /**
   * ID del data_object. Da usare in alternativa all'object_id
   *
   * @type {number}
   * @memberof EidosDataRequest
   */
  public dataObjectId?: number;
  /**
   * Elenco dei campi
   *
   * @type {string[]}
   * @memberof EidosDataRequest
   */
  public fields: string[] = [];
  /**
   * Definizione dei campi per l'ordinamento
   *
   * @type {IEidosOrderBy[]}
   * @memberof EidosDataRequest
   */
  public orderby: IEidosOrderBy[] = [];
  /**
   * Definizione dei filtri
   *
   * @type {IEidosFilter[]}
   * @memberof EidosDataRequest
   */
  public filters: IEidosFilter[] = [];
  /**
   * Pagina da restituire
   *
   * @type {number}
   * @memberof EidosDataRequest
   */
  public page: number = 1;
  /**
   * Dimensione di paginazione
   *
   * @type {number}
   * @memberof EidosDataRequest
   */
  public pageSize: number = 50;
  /**
   * Creates an instance of EidosDataRequest.
   * @memberof EidosDataRequest
   */
  constructor() {
  }
}

/**
 * Eidos data object pagination representation
 *
 * @export
 * @class EidosDataObjectPagination
 */
export class EidosDataObjectPagination {
  /**
   * Creates an instance of EidosDataObjectPagination.
   * @param {number} currentPage
   * @param {number} pages
   * @param {number} pageSize
   * @param {number} totalRows
   * @memberof EidosDataObjectPagination
   */
  constructor(public currentPage: number, public pages: number, public pageSize: number, public totalRows: number) { }
}


/**
 * Eidos data object field sorting representation
 *
 * @export
 * @class EidosDataObjectFieldSort
 */
export class EidosDataObjectFieldSort {
  /**
   * Creates an instance of EidosDataObjectFieldSort.
   * @param {string} fieldName
   * @param {number} priority
   * @param {EidosSortType} [type=EidosSortType.Ascending]
   * @memberof EidosDataObjectFieldSort
   */
  constructor(public fieldName: string, public priority: number, public type: EidosSortType = EidosSortType.Ascending) { }
}

/**
 * Eidos data object field filtering representation
 *
 * @export
 * @class EidosDataObjectFieldFilter
 */
export class EidosDataObjectFieldFilter implements IEidosFilter {
  /**
   * Creates an instance of EidosDataObjectFieldFilter.
   * @param {string} fieldName
   * @param {*} value
   * @param {*} otherValue
   * @param {MyBizFilterType} type
   * @memberof EidosDataObjectFieldFilter
   */
  constructor(public fieldName: string, public value: any, public otherValue: any, public type: MyBizFilterType) { }
}

/**
 * Eidos sorting type enum
 *
 * @export
 * @enum {number}
 */
export enum EidosSortType {
  Ascending,
  Descending
}

/**
 * Eidos ChartJS type of data enum
 *
 * @export
 * @enum {number}
 */
export enum EidosChartJSDataType {
  String = 'S',
  Number = 'N',
  DateTime = 'D'
}

/**
 * Eidos grid object row command type enum
 *
 * @export
 * @enum {number}
 */
export enum EidosFieldRowCommandType {
  /**
   * Update in form
   */
  UpdateInForm,
  /**
   * Delete a form
   */
  Delete,
  /**
   * Update in line
   */
  UpdateInLine,
}
