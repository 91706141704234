import { Injectable } from '@angular/core';
import { ITicketingModel, ITicketingSendData } from '../models/TicketingData.model';
import { BehaviorSubject } from 'rxjs';
import { PopupTicketing } from '../models/PopupTicketing.model';
import { ActivatedRoute } from '@angular/router';
import { ModuleEnum, ModuleType, CalcModuleType } from '../models/module.type';
@Injectable({
  providedIn: 'root'
})
export class TicketingService {

  constructor(private ActivatedRoute: ActivatedRoute) { }

  private _moduleName: ModuleType = ModuleEnum.Generic;
  public set ModuleName(value: string | null) {
    this._moduleName = CalcModuleType(value);
  };
  public get ModuleName(): ModuleEnum {
    return this._moduleName
  }

  public EntityId?: string;
  public EntityType?: string;
  public Section?: string;
  public SubSection?: string;
  public UrlFrame: string = '';
  public FrameOrigin: string = '';
  public PopupConfiguration?: PopupTicketing;
  public CaptureScreenPermission: boolean = true;
  public TicketSubject: BehaviorSubject<ITicketingModel | undefined> = new BehaviorSubject<ITicketingModel | undefined>(undefined);
  public DataConfig?: any;

  public OpenTicket(form: ITicketingSendData | null = null, openPopup: boolean = true, visiblePopup: boolean = true): void {

    switch (this.ModuleName) {
      case ModuleEnum.EntityMaster:
        this.ActivatedRoute.queryParams.subscribe((params: any) => {
          this.EntityType = params.entity.toUpperCase();
          this.EntityId = params.id;
        });
        break;
    }

    let sendData: ITicketingModel | undefined;

    if (form == null) {
      form = {
        TicketingID: undefined,
        FormValues: null,
        CaptureScreen: undefined,
        ModuleName: null
      }
    }

    if (!!form.FormValues && form.FormValues !== null) {
      form.FormValues.EntityType = (!!form.FormValues.EntityType && (form.FormValues.EntityType !== null || form.FormValues.EntityType !== '')) ? form.FormValues.EntityType : !!this.EntityType ? this.EntityType : '';
      form.FormValues!.EntityId = (!!form.FormValues.EntityId && (form.FormValues.EntityId !== null || form.FormValues.EntityId !== '')) ? form.FormValues.EntityId : !!this.EntityId ? this.EntityId : '';
      form.FormValues!.Section = (!!form.FormValues.Section && (form.FormValues.Section !== null || form.FormValues.Section !== '')) ? form.FormValues.Section : !!this.Section ? this.Section : '';
      form.FormValues!.SubSection = (!!form.FormValues.SubSection && (form.FormValues.SubSection !== null || form.FormValues.SubSection !== '')) ? form.FormValues.SubSection : !!this.SubSection ? this.SubSection : '';
    } else {
      form.FormValues = {
        EntityType: !!this.EntityType ? this.EntityType : '',
        EntityId: !!this.EntityId ? this.EntityId : '',
        Section: !!this.Section ? this.Section : '',
        SubSection: !!this.SubSection ? this.SubSection : ''
      }
    }

    form.ModuleName = !!this.ModuleName ? this.ModuleName : ModuleEnum.Generic;
    form.FormValues.MoreData = JSON.stringify(this.CalcDataConfig());

    sendData = {
      UrlFrame: this.UrlFrame,
      FrameOrigin: this.FrameOrigin,
      RenderTicketing: visiblePopup,
      Visible: openPopup,
      PopupConfiguration: this.PopupConfiguration,
      CaptureScreenPermission: this.CaptureScreenPermission,
      ConfigTicket: form!
    }

    this.TicketSubject.next(sendData);

  }

  public CloseTicket() {
    let sendData: ITicketingModel = {
      UrlFrame: this.UrlFrame,
      FrameOrigin: this.FrameOrigin,
      RenderTicketing: false,
      Visible: false,
      PopupConfiguration: undefined,
      CaptureScreenPermission: this.CaptureScreenPermission,
      ConfigTicket: {
        TicketingID: undefined,
        FormValues: undefined,
        CaptureScreen: undefined
      }
    }

    this.TicketSubject.next(sendData);
  }

  private CalcDataConfig(): any {
    let _conf = {
      Browser: {
        clipboard: navigator.clipboard,
        credentials: navigator.credentials,
        doNotTrack: navigator.doNotTrack,
        geolocation: navigator.geolocation,
        maxTouchPoints: navigator.maxTouchPoints,
        mediaCapabilities: navigator.mediaCapabilities,
        mediaDevices: navigator.mediaDevices,
        mediaSession: navigator.mediaSession,
        permissions: navigator.permissions,
        serviceWorker: navigator.serviceWorker,
        userAgent: navigator.userAgent,
        language: navigator.language,
        languages: navigator.languages,
        storage: navigator.storage,
        webDriver: navigator.webdriver
      },
      Link: window.location.href,
      Detail: !!this.DataConfig ? this.DataConfig : null
    };

    return _conf;
  }
}
