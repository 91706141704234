interface CoreRegisterAccountApiConfigGeneral {
  DataObjectId: number;
}
interface CoreRegisterAccountApiConfigFilter { }
interface CoreRegisterAccountApiConfigForm {
  struttura: string
}
interface CoreRegisterAccountApiConfigField {
  tipi_campo_cod: string;
  nome_campo: string;
  chiave: boolean;
  visible_insert: boolean;
  visible_update: boolean;
  sola_lettura_insert: boolean;
  sola_lettura_insert1: boolean;
  sola_lettura_update: boolean;
  sola_lettura_update1: boolean;
  variante_cod: string;
  numero_decimali: number;
  valuta: boolean;
  campo_nome_file: string;
  immagine: boolean;
  label_id: number;
  min: number;
  max: number;
  obbligatorio: boolean;
  relazione_id: number;
  order_by: number;
  always_hide: boolean;
  default_cod: string;
  dms_tag_field: string;
  html_template: string;
  drawing_width: number;
  drawing_height: number;
  label: string;
}

interface CoreRegisterAccountApiConfig {
  HasError: boolean;
  DT0: Array<CoreRegisterAccountApiConfigGeneral>;
  DT1: Array<CoreRegisterAccountApiConfigFilter>;
  DT2: Array<CoreRegisterAccountApiConfigForm>;
  DT3: Array<CoreRegisterAccountApiConfigField>;
}

class CoreRegisterAccountConfigField {
  type = ''
  name = '';
  isKey = false;
  insertVisibility = false;
  updateVisibility = false;
  insertReadOnly = false;
  updateReadOnly = false;
  variant = '';
  decimalDigit = 0;
  isMoney = false;
  isImage = false;
  fileName = '';
  numberMin = 0;
  numberMax = 0;
  required = false;
  order = 0;
  hidden = false;
  defaultValue = '';
  htmlTemplate = '';
  drawingWidth = 0;
  drawingHeight = 0;
  caption = '';
  value: any;
  lookupValues: any[] = []

  constructor(data: CoreRegisterAccountApiConfigField) {
    this.type = data.tipi_campo_cod;
    this.name = data.nome_campo;
    this.isKey = data.chiave;
    this.insertVisibility = data.visible_insert;
    this.updateVisibility = data.visible_update;
    this.insertReadOnly = data.sola_lettura_insert || data.sola_lettura_insert1;
    this.updateReadOnly = data.sola_lettura_update || data.sola_lettura_update1;
    this.variant = data.variante_cod;
    this.decimalDigit = data.numero_decimali;
    this.isMoney = data.valuta;
    this.isImage = data.immagine;
    this.fileName = data.campo_nome_file;
    this.numberMin = data.min;
    this.numberMax = data.max;
    this.required = data.obbligatorio;
    this.order = data.order_by;
    this.hidden = data.always_hide;
    this.defaultValue = data.default_cod;
    this.htmlTemplate = data.html_template;
    this.drawingWidth = data.drawing_width;
    this.drawingHeight = data.drawing_height;
    this.caption = data.label;
    this.value = '';
  }
}
interface CoreRegisterAccountApiFormField {
  tipo: string;
  altezza: number;
  nome_campo: string;
  fieldHash: string
}
interface CoreRegisterAccountApiFormFieldSet {
  tipo: string;
  larghezza: number;
  isRoot: boolean;
  columnHash: string;
  colonne: Array<CoreRegisterAccountApiFormFieldSet | CoreRegisterAccountApiFormField>
  contenuto: Array<CoreRegisterAccountApiFormFieldSet | CoreRegisterAccountApiFormField>
}
interface CoreRegisterAccountApiFormSection {
  visible: boolean;
  id: number;
  gruppoHash: string;
  posizione: number;
  label: string;
  contenuto: Array<CoreRegisterAccountApiFormFieldSet>;
}
interface CoreRegisterAccountApiFormTab {
  id: number;
  posizione: number;
  tabHash: string;
  label: string;
  visible: boolean;
  gruppi: Array<CoreRegisterAccountApiFormSection>
}
interface CoreRegisterAccountApiFormStruct {
  tabs: Array<CoreRegisterAccountApiFormTab>;
}
export class CoreRegisterAccountFormField {
  name = '';
  height = 1;
  hash: string;
  constructor(data: CoreRegisterAccountApiFormField) {
    this.name = data.nome_campo;
    this.height = data.altezza;
    this.hash = data.fieldHash;
  }
}
class CoreRegisterAccountFormFieldSet {
  columns: Array<CoreRegisterAccountFormFieldSet | CoreRegisterAccountFormField> = [];
  isRoot = false;
  width?: number;
  hash?: string;

  constructor(data: CoreRegisterAccountApiFormFieldSet) {
    if (!data) return;

    this.width = data.larghezza;
    this.hash = data.columnHash;
    this.isRoot = data.isRoot;

    switch (data.tipo) {
      case 'contenitore':
        if (data.colonne) {
          data.colonne.forEach(c => this.columns.push(new CoreRegisterAccountFormFieldSet(c as CoreRegisterAccountApiFormFieldSet)));
        }
        break;
      default:
        //Colonne del filedset
        if (data.contenuto) {
          data.contenuto.forEach(c => {
            switch (c.tipo) {
              case 'campo':
                this.columns.push(new CoreRegisterAccountFormField(c as CoreRegisterAccountApiFormField));
                break;
              case 'contenitore':
                this.columns.push(new CoreRegisterAccountFormFieldSet(c as CoreRegisterAccountApiFormFieldSet));
                break;
            }
          });
        }
        break;
    }

  }
}
export class CoreRegisterAccountFormSection {
  id = 0;
  position = 0;
  hash = '';
  caption = ''
  isVisible = false;
  fieldSets: Array<CoreRegisterAccountFormFieldSet> = [];
  constructor(data: CoreRegisterAccountApiFormSection) {
    if (!data) return;
    this.id = data.id;
    this.position = data.posizione;
    this.hash = data.gruppoHash;
    this.caption = data.label;
    this.isVisible = data.visible;
    data.contenuto.forEach(fs => this.fieldSets.push(new CoreRegisterAccountFormFieldSet(fs)));
  }
}
export class CoreRegisterAccountFormTab {
  id = 0;
  position = 0;
  hash = '';
  caption = ''
  isVisible = false;
  sections: Array<CoreRegisterAccountFormSection> = []
  constructor(data: CoreRegisterAccountApiFormTab) {
    this.id = data.id;
    this.position = data.posizione;
    this.hash = data.tabHash;
    this.caption = data.label;
    this.isVisible = data.visible;
    data.gruppi.forEach(g => this.sections.push(new CoreRegisterAccountFormSection(g)))
  }
}
class CoreRegisterAccountFormStruct {
  tabs: Array<CoreRegisterAccountFormTab> = [];
  constructor(data: CoreRegisterAccountApiFormStruct) {
    if (!data) return;

    data.tabs.forEach(t => this.tabs.push(new CoreRegisterAccountFormTab(t)));
  }
}
export class CoreRegisterAccountConfig {
  hasError = false;
  errorMessage = '';
  dataObjectId = 0;
  formStruct: CoreRegisterAccountFormStruct = new CoreRegisterAccountFormStruct({ tabs: [] });
  fields: Array<CoreRegisterAccountConfigField> = [];

  constructor(data?: CoreRegisterAccountApiConfig) {
    if (!data) return this.setError('empty data costructor');
    if (data.HasError) return this.setError('error data costructor');
    const generalInfo = data.DT0.shift();
    if (!generalInfo) return this.setError('invalid object');
    const formInfo = data.DT2.shift();
    if (!formInfo) return this.setError('invalid object');

    this.dataObjectId = generalInfo.DataObjectId;
    this.formStruct = new CoreRegisterAccountFormStruct(JSON.parse(formInfo.struttura) as CoreRegisterAccountApiFormStruct);

    data.DT3.forEach(f => this.fields.push(new CoreRegisterAccountConfigField(f)));

    //TODO rimuovere post aggiunta valori lookup
    for (let f of this.fields) {
      if (f.type !== 'lookup') continue
      f.lookupValues = [{ label: 'test', value: 'test' }, { label: 'test2', value: 'test2' }, { label: 'test3', value: 'test3' }, { label: 'test4', value: 'test4' }];
    }
  }
  setError(error: string): CoreRegisterAccountConfig {
    this.hasError = true;
    this.errorMessage = error;
    return this;
  }
  isValid(): boolean {
    return !this.hasError;
  }
}

interface CoreApiParameter {
  Name: string;
  Type: string;
  Number: number;
  Datatime: Date;
  Text: string;
}

export interface CoreRegisterAccountParameters {
  fields: Array<CoreApiParameter>;
}
export class CoreRegisterAccountResult {
  constructor() {
  }
}

