/**
 * Error routes enum
 *
 * @export
 * @enum {string}
 */
export enum ErrorRoute {
    NotFound = '404',
    ModuleNotLicensed = 'module-not-licensed',
    Payment = 'payment'
}