<form [formGroup]="form">
  <h1 mat-dialog-title fxLayoutAlign="center">{{ "change_password.title" | transloco }}</h1>

  <div mat-dialog-content>

    <div class="password-requirements">
      <mat-label *ngIf="requirements.mustChangePassword">{{ "change_password.password_change_required" | transloco }}
      </mat-label>

      <div *ngIf="requirements.minPasswordLength > 0">
        <mat-icon class="password-requirements-error-icon"
          *ngIf="form.controls.newPassword.value.length < requirements.minPasswordLength || form.controls.newPassword.errors && form.controls.newPassword.errors.minlength">
          error</mat-icon>
        <mat-icon class="password-requirements-ok-icon"
          *ngIf="form.controls.newPassword.value.length >= requirements.minPasswordLength && !(form.controls.newPassword.errors && form.controls.newPassword.errors.minlength)">
          check</mat-icon>
        <mat-label>{{ "change_password.min_password_length" | transloco: { value: requirements.minPasswordLength } }}
        </mat-label>
        <br />
      </div>

      <div *ngIf="requirements.minPasswordLowerCaseChars > 0">
        <mat-icon class="password-requirements-error-icon"
          *ngIf="form.controls.newPassword.errors && form.controls.newPassword.errors.minPasswordLowerCaseChars">error
        </mat-icon>
        <mat-icon class="password-requirements-ok-icon"
          *ngIf="!(form.controls.newPassword.errors && form.controls.newPassword.errors.minPasswordLowerCaseChars)">
          check</mat-icon>
        <mat-label>{{ "change_password.min_password_lower" | transloco: { value: requirements.minPasswordLowerCaseChars
          } }}</mat-label>
        <br />
      </div>

      <div *ngIf="requirements.minPasswordUpperCaseChars > 0">
        <mat-icon class="password-requirements-error-icon"
          *ngIf="form.controls.newPassword.errors && form.controls.newPassword.errors.minPasswordUpperCaseChars">error
        </mat-icon>
        <mat-icon class="password-requirements-ok-icon"
          *ngIf="!(form.controls.newPassword.errors && form.controls.newPassword.errors.minPasswordUpperCaseChars)">
          check</mat-icon>
        <mat-label>{{"change_password.min_password_upper" | transloco: { value: requirements.minPasswordUpperCaseChars }
          }}</mat-label>
        <br />
      </div>

      <div *ngIf="requirements.minPasswordNumericalChars > 0">
        <mat-icon class="password-requirements-error-icon"
          *ngIf="form.controls.newPassword.errors && form.controls.newPassword.errors.minPasswordNumericalChars">error
        </mat-icon>
        <mat-icon class="password-requirements-ok-icon"
          *ngIf="!(form.controls.newPassword.errors && form.controls.newPassword.errors.minPasswordNumericalChars)">
          check</mat-icon>
        <mat-label>{{"change_password.min_password_numerical" | transloco: { value:
          requirements.minPasswordNumericalChars } }}</mat-label>
        <br />
      </div>

      <div *ngIf="requirements.minPasswordSpecialChars > 0">
        <mat-icon class="password-requirements-error-icon"
          *ngIf="form.controls.newPassword.errors && form.controls.newPassword.errors.minPasswordSpecialChars">error
        </mat-icon>
        <mat-icon class="password-requirements-ok-icon"
          *ngIf="!(form.controls.newPassword.errors && form.controls.newPassword.errors.minPasswordSpecialChars)">check
        </mat-icon>
        <mat-label>{{"change_password.min_password_special" | transloco: { value: requirements.minPasswordSpecialChars }
          }}</mat-label>
        <br />
      </div>
    </div>

    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{ "change_password.current_password" | transloco }}</mat-label>
      <input type="password" formControlName="oldPassword" matInput required>
      <div *ngIf="form.controls.oldPassword.invalid && form.controls.oldPassword.errors">
        <mat-error *ngIf="form.controls.oldPassword.errors.required">
          {{ "fill_field" | transloco }}
        </mat-error>
      </div>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ "change_password.new_password" | transloco }}</mat-label>
      <input type="password" formControlName="newPassword" matInput required>
      <div *ngIf="form.controls.newPassword.invalid && form.controls.newPassword.errors">
        <mat-error *ngIf="form.controls.newPassword.errors.required">
          {{ "fill_field" | transloco }}
        </mat-error>
      </div>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ "change_password.retype_new_password" | transloco }}</mat-label>
      <input type="password" formControlName="retypedNewPassword" matInput required>
      <div *ngIf="form.controls.retypedNewPassword.invalid && form.controls.retypedNewPassword.errors">
        <mat-error *ngIf="form.controls.retypedNewPassword.errors.required">
          {{ "fill_field" | transloco }}
        </mat-error>
      </div>
    </mat-form-field>

    <mat-error
      *ngIf="form.invalid && form.errors && form.errors['notSame'] && form.controls.newPassword.dirty && form.controls.retypedNewPassword.dirty">
      {{ "change_password.new_password_mismatch" | transloco }}
    </mat-error>


    <mat-error *ngIf="responseError">{{ responseError }}</mat-error>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end">

    <button mat-button type="button" [disabled]="requirements.mustChangePassword" (click)="onCancelClick()">{{ "cancel"
      | transloco }}</button>
    <button mat-flat-button type="submit" color="primary" (click)="onSetPasswordClick()" [disabled]="form.invalid">
      {{ loading ? "" : "submit" | transloco }}
      <mat-spinner *ngIf="loading" class="change-password-request-spinner" style="margin:0 auto;" [color]="'accent'"
        [diameter]="20"></mat-spinner>
    </button>
  </div>
</form>