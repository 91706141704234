<mat-tab-group [(selectedIndex)]="selectedTabIndex" mat-align-tabs="start" [ngClass]="{'invisible-tabs': hideTabHeader() }">
  <mat-tab>
    <ng-template mat-tab-label class="eidos-menu-tab-label">
      <eidos-icon [iconConfiguration]="menuTabIcon" [clickable]="true"></eidos-icon>
    </ng-template>
    <div class="menu-items-container">
      <mat-nav-list>
        <!-- While searching, we display tooltip about parents-->
        <eidos-menu-item *ngFor="let menuItem of displayedMenuItems" [menuItem]="menuItem"
          [selectedId]="selectedId" [matTooltip]="menuItem.getBreadCrumbs()" matTooltipPosition="after">
        </eidos-menu-item>
      </mat-nav-list>
    </div>
  </mat-tab>
  <mat-tab *ngIf="enableNotificationsTab">
    <ng-template mat-tab-label class="eidos-menu-tab-label">
      <eidos-icon [iconConfiguration]="notificationsTabIcon" [clickable]="true"></eidos-icon>
    </ng-template>
    <eidos-notifications-viewer></eidos-notifications-viewer>
  </mat-tab>
</mat-tab-group>
<div class="search-menu-item">
  <content class="search-menu-item-content" *ngIf="!searching">
    <eidos-icon [iconConfiguration]="menuPinIcon" [class]="menuPinIconClass" [tooltip]="menuPinIconLabel" [rotate]="this.isLocked ? 0 : 45"
      [clickable]="true" (click)="toggleLock()"></eidos-icon>
    <eidos-icon [iconConfiguration]="menuOrderAlphaIcon" [tooltip]="menuOrderIconLabel" [clickable]="true" (click)="toggleOrder()"></eidos-icon>
    <eidos-icon *ngIf="order === EidosMenuOrderStatus.AZ" [iconConfiguration]="menuAlphaOrderIcon" [class]="'eidos-menu-order-icon'"
      [tooltip]="menuOrderIconLabel" [clickable]="true" (click)="toggleOrder()"></eidos-icon>
    <eidos-icon *ngIf="order === EidosMenuOrderStatus.ZA" [iconConfiguration]="menuReverseAlphaOrderIcon" [class]="'eidos-menu-order-icon'"
      [tooltip]="menuOrderIconLabel" [clickable]="true" (click)="toggleOrder()"></eidos-icon>
    <eidos-icon [iconConfiguration]="searchIcon" [tooltip]="'menu.search'" [clickable]="true" (click)="searching = true"></eidos-icon>
  </content>
  <form class="example-container" [style.fontSize.px]="12" *ngIf="searching">        
    <mat-form-field appearance="outline" [@searchFormAnimation]="searching" color="light-primary-text">
      <input class="side-menu-search" matInput placeholder="{{ 'search' | transloco }}" [formControl]="sideMenuSearch" (input)="onSearch()">
      <!-- We don'use eidos-icon component here but mat-icon, because of its compatibility with mat-form-field -->
      <mat-icon matSuffix (click)="clearSearch()" matTooltip="{{ 'clear' | transloco }}">clear</mat-icon>
    </mat-form-field>
    <eidos-icon [iconConfiguration]="closeSearchIcon" [clickable]="true" [class]="'eidos-menu-ui-icon'" (click)="searching = false;"></eidos-icon>
  </form>
</div>