import { AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Params, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';

import { EidosBaseComponent } from '@common/components/eidos-base.component';
import { EidosExternalApp } from '@common/models/eidos-external-app.model';
import { IEidosExternalAppEventData, IEidosExternalAppPostMessage, IEidosExternalAppPostMessageQueue } from '@common/models/eidos-external-app.interface';
import { IEidosDynObject } from '@common/models/base-object.models';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';

@Component({
  selector: 'eidos-external-app-container',
  templateUrl: './eidos-external-app-container.component.html',
  styleUrls: ['./eidos-external-app-container.component.scss']
})
export class EidosExternalAppContainerComponent extends EidosBaseComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  /**
   * Reference to MyBiz iframe
   *
   * @type {(ElementRef | undefined)}
   * @memberof EidosExternalAppContainerComponent
   */
  @ViewChildren('iframeMyBiz', { read: ElementRef }) iframeMyBiz: QueryList<ElementRef> = new QueryList<ElementRef>();
  /**
   * Reference to Embedded URL iframe
   *
   * @type {(ElementRef | undefined)}
   * @memberof EidosExternalAppContainerComponent
   */
  @ViewChildren('iframeEmbeddedUrl', { read: ElementRef }) iframeEmbeddedUrl: QueryList<ElementRef> = new QueryList<ElementRef>();
  /**
   * References to external apps iframes
   *
   * @type {QueryList<ElementRef>}
   * @memberof EidosExternalAppContainerComponent
   */
  @ViewChildren('iframeExternalApp', { read: ElementRef }) iframeExternalApp: QueryList<ElementRef> = new QueryList<ElementRef>();
  /**
   * External apps for MyBiz to build iframes
   *
   * @type {EidosExternalApp}
   * @memberof EidosExternalAppContainerComponent
   */
  public appMyBiz: EidosExternalApp | undefined;
  /**
   * External apps for embedded url to build iframes
   *
   * @type {EidosExternalApp}
   * @memberof EidosExternalAppContainerComponent
   */
  public appEmbedded: EidosExternalApp | undefined;
  /**
   * External apps to build iframes
   *
   * @type {Array<IEidosExternalApp>}
   * @memberof EidosExternalAppContainerComponent
   */
  public apps: Array<EidosExternalApp> = [];
  /**
   * Enqueued messages while waiting for iframes init
   *
   * @private
   * @type {Array<IEidosExternalAppPostMessageQueue>}
   * @memberof EidosExternalAppContainerComponent
   */
  private messageQueue: Array<IEidosExternalAppPostMessageQueue> = [];
  /**
   * Save last url of iframe
   *
   * @private
   * @type {Array<IEidosExternalAppPostMessageQueue>}
   * @memberof EidosExternalAppContainerComponent
   */
  private externalSrc: IEidosDynObject = {};

  constructor(
    private eidosSecurityService: EidosSecurityService
    , private externalAppService: EidosExternalAppService
    , private router: Router
    , private eidosLogService: EidosLogService) {
    super();
    this.appMyBiz = this.externalAppService.appMyBiz;
    this.appEmbedded = this.externalAppService.appEmbeddedUrl;

    this.iframeMyBiz.changes
      .subscribe(() => {
        if (this.appMyBiz) {
          this.dequeueMessages(this.appMyBiz.source);
          const el = this.getNativeElement(this.appMyBiz.source);
          if (el !== null) {
            el.src = this.getNativeElementUrl(this.appMyBiz.source);
          }
        }
      });

    this.iframeEmbeddedUrl.changes
      .subscribe(() => {
        if (this.appEmbedded) {
          this.dequeueMessages(this.appEmbedded.source);
          const el = this.getNativeElement(this.appEmbedded.source);
          if (el !== null) {
            el.src = this.getNativeElementUrl(this.appEmbedded.source);
          }
        }
      });

    this.eidosLogService.logDebug(EidosLogSeverity.Log, 'iframe master subscribe event');
    this.externalAppService.ExternalApplicationEvent
      .pipe(
        takeUntil(this.subscription)
      ).subscribe((event: IEidosExternalAppEventData) => {
        this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master event: name=${event.name}`);
        switch (event.name) {
          case 'goto':
            // if (event.segments) {
            this.applicationGoto(event.source, event.id, event.segments, event.queryString);
            // }
            break;
          case 'show':
            this.applicationShow(event.source);
            break;
          case 'hide':
            this.applicationHide(event.source);
            break;
          case 'token':
            this.applicationAuthentication(event.source);
            break;
          case 'redirect':
            this.router.navigate([event.source]);
            break;
          case 'versionRequest':
            this.applicationRequireVersion(event.source);
            break;
        }
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  ngAfterContentInit(): void {

    this.externalAppService.apps
      .pipe(
        takeUntil(this.subscription)
      ).subscribe((apps) => {
        this.initApps(apps ?? []);
      });

    // Subscribe for futures (de)authentications
    this.eidosSecurityService.authenticated
      .subscribe((isAuthenticated: boolean | undefined) => {
        if (isAuthenticated !== undefined) {
          if (isAuthenticated === true) {
            this.authenticateAllApps();
          }
          else {
            this.deauthenticateAllApps();

            // Destroy external apps
            this.apps = [];
          }
        }
      });

  }
  /**
   * Inits external apps and authenticates them
   *
   * @private
   * @param {EidosExternalApp[]} apps
   * @memberof EidosExternalAppContainerComponent
   */
  private initApps(apps: EidosExternalApp[]) {
    this.apps = apps;
    this.apps.filter(app => app.useToken).forEach(app => this.applicationAuthentication(app.source));
  }
  /**
   * Authenticates all external apps + MyBiz
   *
   * @private
   * @memberof EidosExternalAppContainerComponent
   */
  private authenticateAllApps() {
    if (this.appMyBiz) {
      this.applicationAuthentication(this.appMyBiz.source);
    }
    this.apps.filter(app => app.useToken).forEach(app => this.applicationAuthentication(app.source));
  }
  /**
   * Deauthenticates all external apps + MyBiz
   *
   * @private
   * @memberof EidosExternalAppContainerComponent
   */
  private deauthenticateAllApps() {
    if (this.appMyBiz) {
      this.applicationLogout(this.appMyBiz.source);
    }
    this.apps.filter(app => app.useToken).forEach(app => this.applicationLogout(app.source));
  }
  /**
   * Get app related iframe element to post message
   *
   * @private
   * @param {string} source
   * @return {*}  {*}
   * @memberof EidosExternalAppContainerComponent
   */
  private getNativeElement(source: string): any {
    // Looking for native element in the correct viewchild reference
    if (this.appMyBiz?.source === source) {
      if (this.iframeMyBiz.length > 0) {
        const el = this.iframeMyBiz.get(0);
        if (el) return el.nativeElement;
      }
      return null;
    }

    if (this.appEmbedded?.source === source) {
      if (this.iframeEmbeddedUrl.length > 0) {
        const el = this.iframeEmbeddedUrl.get(0);
        if (el) return el.nativeElement;
      }
      return null;
    }

    if (this.iframeExternalApp.length > 0) {
      const el = this.iframeExternalApp.find(iframe => iframe.nativeElement.id === 'iframe-' + source);
      if (el) return el.nativeElement;
    }
    return null;
  }
  setNativeElementUrl(source: string, url: string): void {
    this.externalSrc[source] = url;
  }
  getNativeElementUrl(source: string): string {
    return this.externalSrc[source];
  }
  /**
   * Post a message to app related iframe
   *
   * @private
   * @param {EidosExternalApp} app
   * @param {*} msgData
   * @return {*}  {void}
   * @memberof EidosExternalAppContainerComponent
   */
  private postMessage(app: EidosExternalApp, msgData: IEidosExternalAppPostMessage): void {

    // If the message is an auth message, I must send it also if app is not authenticated
    const isAuthMessage = !!msgData.command && msgData.command === 'token';

    // Try to send the message only if app is authorized or trying to authenticate
    if (app && app.origin) {

      // If queue is not empty, app is not loaded yet
      if (this.messageQueue.filter(m => m.source === app.source).length === 0) {
        let el = this.getNativeElement(app.source);
        if (el !== null) {

          const contentWindow = el.contentWindow;

          if (contentWindow) {
            // Add infos about app
            msgData.source = app.source;
            msgData.action = app.action;

            contentWindow.postMessage(msgData);
            if (isAuthMessage && app.useToken) {
              app.tokenSended = true;
            }
          }

          return;
        }
      }

      // Queue this message, waiting for authentication
      this.messageQueue.push({ source: app.source, message: msgData });
    }
  }
  /**
   * Try to dequeue messages enqueued because of app iframe loading
   *
   * @private
   * @param {string} source
   * @memberof EidosExternalAppContainerComponent
   */
  private dequeueMessages(source: string): void {
    const app = this.externalAppService.getAppReferenceBySource(source);
    if (app) {
      // Extract app's messages from queue
      let messages = this.messageQueue.filter(m => m.source === source).sort(m => m.message.command === 'token' ? 0 : 1);
      this.messageQueue = this.messageQueue.filter(m => m.source !== source);

      if (messages) {
        // Post first token messages than others
        messages.forEach(m => this.postMessage(app, m.message));
      }
    }
  }
  /**
   * Performs app authentication
   *
   * @private
   * @param {string} source
   * @memberof EidosExternalAppContainerComponent
   */
  private applicationAuthentication(source: string) {
    let app = this.externalAppService.getAppReferenceBySource(source);
    if (app && app.useToken) {
      let token = this.eidosSecurityService.getToken();
      let user = this.eidosSecurityService.getUser();
      if (user && token) {
        this.postMessage(app, { type: 'command', command: 'token', token: token });
        this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master: send/enqueue token: user=${user?.username} token=${token} to app ${source}`);
      }
    }
  }
  /**
   * Performs app route change
   *
   * @private
   * @param {string} source
   * @param {string} id
   * @param {string[]} [segments]
   * @memberof EidosExternalAppContainerComponent
   */
  private applicationGoto(source: string, id: string, segments: string[],queryString: Params) {
    const app = this.externalAppService.getAppReferenceBySource(source);
    if (app) {
      if(app.remoteRouting) {
        this.postMessage(app, { type: 'command', 'command': 'goto', parameters: segments, queryString:queryString });
        return
      }

      this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master source=${source}: goto object=${segments?.join(',')}`);
      let urlSegments = [app.actionInUrl ? app.url + "/" + app.action : app.url, id];
      if (segments && segments.length > 0) {
        urlSegments = urlSegments.concat(segments);
      }
      const url = urlSegments.filter(segment => !!segment).join("/");
      this.setNativeElementUrl(app.source, url);

      // Hard redirect: iframe is showing something we don't know
      const el = this.getNativeElement(app.source);
      if (el !== null) {

        el.src = urlSegments.filter(segment => !!segment).join("/");
        this.applicationShow(app.source);
      } else {
        this.eidosLogService.logDebug(EidosLogSeverity.Log, 'iframe not ready', app);
        setTimeout(() => {
          this.dequeueMessages(app.source);
          const el = this.getNativeElement(app.source);
          if (el !== null) el.src = this.getNativeElementUrl(app.source);
        });
      }
    } else {
      this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master can't find iframe of source=${source}`);
    }
  }

  retryGoto(source: string) {
    const src = this.getNativeElementUrl(source);
    //    if(src)
    this.dequeueMessages(source);
    const el = this.getNativeElement(source);
    if (el !== null) el.src = src;
  }
  /**
   * Performs application display
   *
   * @private
   * @param {string} source
   * @memberof EidosExternalAppContainerComponent
   */
  private applicationShow(source: string) {
    let app = this.externalAppService.getAppReferenceBySource(source);
    if (app) {

      // Hide all other apps
      let sourceToHide = this.apps.filter(app => app.source !== source).map(app => app.source);

      if (this.appMyBiz && source !== this.appMyBiz.source) {
        sourceToHide.push(this.appMyBiz.source);
      }

      if (this.appEmbedded && source !== this.appEmbedded.source) {
        sourceToHide.push(this.appEmbedded.source);
      }

      sourceToHide.forEach(source => this.applicationHide(source));

      // Set current as visible
      if (!app.isVisible) {
        app.isVisible = true;
      }
    }
  }
  /**
   * Performs application hide
   *
   * @private
   * @param {string} source
   * @memberof EidosExternalAppContainerComponent
   */
  private applicationHide(source: string) {
    let app = this.externalAppService.getAppReferenceBySource(source);
    if (app) {
      app.isVisible = false;
    }
  }
  /**
   * Performs application logout
   *
   * @private
   * @param {string} source
   * @memberof EidosExternalAppContainerComponent
   */
  private applicationLogout(source: string) {
    let app = this.externalAppService.getAppReferenceBySource(source);
    if (app && app.useToken) {
      this.eidosLogService.logDebug(EidosLogSeverity.Log, 'iframe master: send logout');
      this.postMessage(app, { type: 'command', command: 'logout' });
      app.tokenSended = !app.useToken;
      app.authorized = !app.useToken;
    }
  }
  /**
   * After loading app iframe handler
   *
   * @param {EidosExternalApp} app
   * @memberof EidosExternalAppContainerComponent
   */
  public applicationDynamicOnAfterLoad(app: EidosExternalApp): void {
    this.applicationOnAfterLoad(app);
    // Must wait for external app creation in DOM
    this.iframeExternalApp.changes
      .pipe(
        filter((iframe: ElementRef) => iframe.nativeElement && iframe.nativeElement.id === 'iframe-' + app.source),
        take(1))
      .subscribe(() => {
        this.dequeueMessages(app.source);
      });
  }
  /**
   * After loading app iframe handler
   *
   * @param {EidosExternalApp} app
   * @memberof EidosExternalAppContainerComponent
   */
  public applicationOnAfterLoad(app: EidosExternalApp): void {
    if (app) {
      app.loaded = true;
      this.dequeueMessages(app.source);
    }
  }
  /**
   * Post message to external apps to require their versions
   *
   * @private
   * @param {string} source
   * @memberof EidosExternalAppContainerComponent
   */
  private applicationRequireVersion(source: string) {
    const app = this.externalAppService.getAppReferenceBySource(source);
    if (app && app.useToken) {
      this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master: require ${source} version`);
      this.postMessage(app, { type: 'command', command: 'version' });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eidosLogService.logDebug(EidosLogSeverity.Log, 'iframe unsubscribe event');
  }
}


