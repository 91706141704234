import { SafeBaseObject } from '@app/core/models/base-object.models';
import { DateTime } from 'luxon';

export class ResBkgDocument extends SafeBaseObject {
  public bkgID: number;
  public requestType: string;
  public emailTo: string;
  public process: string;
  public uRL: string;
  public guestCopyOnly: boolean;
  public created: DateTime;
  constructor(data?: IReservationApiBkgDocument) {
    super();
    this.bkgID = 0
    this.requestType = '';
    this.emailTo = '';
    this.process = '';
    this.uRL = '';
    this.guestCopyOnly = false;
    this.created = DateTime.local();
    if (data) this.updateData(data);
  }

  override updateData(data: IReservationApiBkgDocument): void {
    this.addMangledProperty(data);
    this.addDateTimeProperty('requestDate', data, 'RequestDate');
    this.addBooleanProperty('guestCopyOnly', data, 'GuestCopyOnly');
    this.addDateTimeProperty('created', data, 'Created');

    /* if (data.RequestTime) {
      const hour = Number(data.RequestTime.toString().substring(0, 2));
      const minute = Number(data.RequestTime.toString().substring(2));
      if (isNaN(hour) || isNaN(minute)) return

      this.requestTime = DateTime.fromObject({ minute, hour })

      if (!this.requestTime.isValid) return

      this.formattedRequestDate = DateTime.fromObject({ year: this.requestDate.year, month: this.requestDate.month, day: this.requestDate.day, hour: this.requestTime.hour, minute: this.requestTime.minute, second: this.requestTime.second }).toLocaleString(DateTime.DATETIME_SHORT)
    } */
  }
}

export interface IReservationApiBkgDocument {
  BkgID: number;
  RequestType: string;
  EmailTo: string;
  Process: string;
  URL: string;
  GuestCopyOnly: string;
  RequestDate: number;
  RequestTime: number;
}

export interface IReservationApiSearchBkgDocumentParams {
  BkgID: number;
  // SectionID: number;
  RequestType?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
}

export interface IReservationApiCreateBkgDocumentParams {
  BkgID: number;
  SectionID: number;
  GuestRemarks?: string;
  AgencyRemarks?: string;
  AirRemarks?: string;
  DocType?: string;
  EmailTo?: string;
  EmailCC?: string;
  GuestCopyOnly?: string;
  ItineraryFlag?: string;
  Company?: string;
}
