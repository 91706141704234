import { BaseObject } from "@common/models/base-object.models";
import { DateTime } from "luxon";

/**
 * Interface from Booking -> Custom package navigation
 *
 * @export
 * @interface IResBookingSectionCustomRequestParams
 */
export interface IResBookingSectionCustomRequestParams {
  sectionId: number,
  dateStart: string,
  dateEnd: string,
  delete?: number,
  edit?: number
}

export class ReservationCustomRequest extends BaseObject {
  requestId?: number;
  bkgId?: number;
  requestName?: string;
  note?: string;
  cost?: number;
  sellingPrice?: number;
  currency?: string;
  details: ReservationCustomRequestDetail[] | undefined;

  constructor(data: IReservationApiCustomRequest) {
    super(data);
  }

  override updateData(data: IReservationApiCustomRequest): void {
    this.addProperty('requestId', data, 'RequestID');
    this.addProperty('bkgId', data, 'BkgID');
    this.addProperty('requestName', data, 'RequestName');
    this.addProperty('note', data, 'Note');
    this.addProperty('cost', data, 'Cost');
    this.addProperty('sellingPrice', data, 'SellingPrice');
    this.addProperty('currency', data, 'Currency');
    this.details = data.Details?.map(d => new ReservationCustomRequestDetail(d)) ?? [];
  }

  public mapToSavePayload(sectionId: number, deleted?: boolean): IReservationApiSaveCustomRequest {
    return {
      RequestID: this.requestId ?? null,
      BkgID: this.bkgId ?? -1,
      HeaderName: this.requestName,
      HeaderNote: this.note,
      HeaderStatus : !!deleted ? 'D' : 'A',
      Details: this.details?.map(d => d.mapToSavePayload()) ?? [],
      SectionID: sectionId
    };
  }
}

export interface IReservationApiSaveCustomRequest {
  RequestID: null | number;
  BkgID: number;
  HeaderName?: string;
  HeaderNote?: String
  Details: IReservationApiSaveCustomRequestDetail[];
  HeaderStatus?: string;
  SectionID: number;
}

interface IReservationApiCustomRequest {
  RequestID?: number;
  BkgID?: number;
  RequestName?: string;
  Note?: string;
  Cost?: number;
  SellingPrice?: number;
  Currency?: string;
  Details?: IReservationApiCustomRequestDetail[];
}

export class ReservationCustomRequestDetail extends BaseObject {
  position?: string;
  date?: DateTime;
  startTime?: DateTime;
  endTime?: DateTime;
  departureCity?: string;
  arrivalCity?: string;
  allDay?: boolean;
  assignedTo?: string;
  bkgReference?: string;
  cityID?: number;
  city?: string;
  companyID?: number;
  companyName?: string;
  cost?: number;
  costCurrency?: string;
  costROE?: number;
  detailID?: number;
  groupOwner?: string;
  guests?: number[]
  note?: string;
  owner?: string;
  owners?: ReservationCustomRequestDetailOwner[]
  requestID?: number;
  requestStatusID?: number;
  requestStatus?: string;
  statusName?: string;
  requestTypeID?: number;
  requestType?: string;
  sellingCurrency?: string;
  sellingPrice?: number;
  sellingROE?: number;
  siteID?: number;
  siteName?: string;
  statuses?: ReservationCustomRequestDetailStatus[];
  user?: string;
  sectionID?: number;

  deleted: boolean = false;

  constructor(data: any) {
    super(data);
  }

  override updateData(data: IReservationApiCustomRequestDetail): void {

    this.addMangledProperty(data);

    this.addBooleanProperty('allDay', data, 'AllDay');
    this.addDateTimeProperty('date', data, 'Date');
    this.addDateTimeProperty('startTime', data, 'StartTime');
    this.addDateTimeProperty('endTime', data, 'EndTime');

    if (this.date?.isValid) {
      if (this.startTime) {
        this.startTime = this.startTime.set({ day: this.date.day, month: this.date.month, year: this.date.year });
      }
      if (this.endTime) {
        this.endTime = this.endTime.set({ day: this.date.day, month: this.date.month, year: this.date.year });
      }
    }

    this.guests = data.Guests?.map(g => g.GuestCod);
    this.owners = data.Owners?.map(o => new ReservationCustomRequestDetailOwner(o));
    this.statuses = data.Statuses?.map(s => new ReservationCustomRequestDetailStatus(s));
  }

  public mapToSavePayload(): IReservationApiSaveCustomRequestDetail {
    return {
      Note: this.note,
      Owner: this.owner,
      Date: this.date,
      StartTime: this.startTime,
      EndTime: this.endTime,
      GuestsCod: this.guests?.join(',') ?? "",
      CityID: this.cityID,
      SiteID: this.siteID,
      CompanyID: this.companyID,
      RequestTypeID: this.requestTypeID,
      AllDay: !!this.allDay ? "Y" : "N",
      RequestStatusID: this.requestStatusID ?? 1,
      DetailID: this.detailID,
      Status: !!this.deleted ? 'D' : 'A',
      SectionID: this.sectionID
    };
  }
}

interface IReservationApiSaveCustomRequestDetail {
  Note?: string;
  Date?: DateTime;
  StartTime?: DateTime;
  EndTime?: DateTime;
  CityID?: number;
  SiteID?: number;
  CompanyID?: number;
  RequestTypeID?: number;
  AllDay?: string;
  Owner?: string;
  RequestStatusID: number;
  DetailID?: number;
  Status?: string;
  GuestsCod: string;
  SectionID?: number;
}

export interface IReservationApiCustomRequestDetail {
  Position: string;
  Date: string;
  StartTime: string;
  EndTime: string;
  DepartureCity: string;
  ArrivalCity: string;
  RequestTypeId: string;
  AllDay: string;
  AssignedTo: string;
  BkgReference: string; //?
  CityID: number;
  CompanyID: number;
  Cost: number;
  CostCurrency: string;
  CostROE: number;
  DetailID: number;
  GroupOwner: string;
  Guests: IReservationApiCustomRequestDetailGuest[]
  Note: string;
  Owner: string;
  Owners: IReservationApiCustomRequestDetailOwner[]
  RequestID: number;
  RequestStatus: string;
  RequestStatusID: number;
  RequestTypeID: number;
  SellingCurrency: string;
  SellingPrice: number;
  SellingROE: number;
  SiteID: number;
  Statuses: IReservationApiCustomRequestDetailStatus[];
  User: string;
  SectionID: number;
}

export class ReservationCustomRequestDetailGuest extends BaseObject {
  public requestID?: number;
  public detailID?: number;
  public bkgID?: number;
  public guestID?: number;
  public guestCod?: number;

  constructor(data?: IReservationApiCustomRequestDetailGuest) {
    super(data);
  }

  override updateData(data?: IReservationApiCustomRequestDetailGuest): void {
    this.addMangledProperty(data);
  }
}

export interface IReservationApiCustomRequestDetailGuest {
  RequestID: number;
  DetailID: number;
  BkgID: number;
  GuestCod: number;
  GuestID: number;
}

class ReservationCustomRequestDetailOwner extends BaseObject {
  requestID?: number;
  detailID?: number;
  bkgID?: number;

  constructor(data: IReservationApiCustomRequestDetailOwner) {
    super(data);
  }

  override updateData(data: IReservationApiCustomRequestDetailOwner): void {
    this.addMangledProperty(data);
  }
}

interface IReservationApiCustomRequestDetailOwner {
  RequestID: number;
  DetailID: number;
  BkgID: number;
}

class ReservationCustomRequestDetailStatus extends BaseObject {
  requestID?: number;
  detailID?: number;
  bkgID?: number;
  prevAssignedTo?: string;
  actualAssignedTo?: string;
  created?: string;

  constructor(data: IReservationApiCustomRequestDetailStatus) {
    super(data);
  }

  override updateData(data: IReservationApiCustomRequestDetailStatus): void {
    this.addMangledProperty(data);
  }
}

interface IReservationApiCustomRequestDetailStatus {
  RequestID: number;
  DetailID: number;
  PrevAssignedTo: string;
  ActualAssignedTo: string;
  Created: string;
}

