import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { IEidosIcon } from "../config/environment.interface";
import { BaseObject } from "./base-object.models"
import { IEidosExternalApp } from "./eidos-external-app.interface"
import { EidosObjectOptions } from "./eidos-object.interface";

/**
 * Eidos external app
 *
 * @export
 * @class EidosExternalApp
 * @extends {BaseObject}
 * @implements {IEidosExternalApp}
 */
export class EidosExternalApp extends BaseObject implements IEidosExternalApp {
  /**
   * App ID
   *
   * @type {string}
   * @memberof EidosExternalApp
   */
  id: string;
  /**
   * MyBiz app object id
   *
   * @type {number}
   * @memberof EidosExternalApp
   */
  objectId: number;
  /**
   * MyBiz app object id
   *
   * @type {EidosObjectOptions}
   * @memberof EidosExternalApp
   */
  options: EidosObjectOptions;
  /**
  * App source
  *
  * @type {string}
  * @memberof EidosExternalApp
  */
  source: string;
  /**
   * App raw URL
   *
   * @type {string}
   * @memberof EidosExternalApp
   */
  url: string;
  /**
   * Pattern to identify app route
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  pattern: string;
  /**
   * Required token for auth flag
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  useToken: boolean;
  /**
   * App action
   *
   * @type {string}
   * @memberof EidosExternalApp
   */
  action: string;
  /**
   *
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  useLoader: boolean;
  /**
   * Indica se includere l'action nella costruzione dell'url
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  actionInUrl: boolean;
  /**
   * menu label Label da visualizzare nel menu
   *
   * @type {string}
   * @memberof IEidosExternalApp
   */
  label: string;
  /**
   * Display app in menu flag
   *
   * @type {boolean}
   * @memberof IEidosExternalApp
   */
  addToMenu?: boolean;
  /**
   * External app icon
   *
   * @type {IEidosIcon}
   * @memberof IEidosExternalApp
   */
  icon?: IEidosIcon | null;
  /**
    * External app menu item color
    *
    * @type {string}
    * @memberof IEidosExternalApp
    */
  color?: string;
  /**
   * App origin
   *
   * @type {string}
   * @memberof EidosExternalApp
   */
  origin?: string;
  /**
   * App segments
   *
   * @type {string[]}
   * @memberof EidosExternalApp
   */
  segments?: string[];
  /**
   * App sanitized URL
   *
   * @type {SafeResourceUrl}
   * @memberof EidosExternalApp
   */
  safeUrl: SafeResourceUrl;
  /**
   * App visible in app container flag
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  isVisible: boolean;
  /**
   * Token already sended flag
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  tokenSended?: boolean;
  /**
   * App authorized flag
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  authorized: boolean;
  /**
   * Related iframe loaded flag
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  loaded: boolean;
  /**
   *
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  isDuplicated: boolean;

  /**
   * App visible in app container flag
   *
   * @type {boolean}
   * @memberof EidosExternalApp
   */
  remoteRouting: boolean;

  constructor(sanitizer: DomSanitizer, app?: IEidosExternalApp) {
    super();

    // Default values
    this.id = '';
    this.objectId = 0;
    this.options = null;
    this.source = '';
    this.url = '';
    this.pattern = '';
    this.useToken = true;
    this.action = '';
    this.actionInUrl = false;
    this.useLoader = false;
    this.label = '';
    this.origin = '';
    this.segments = [''];
    this.color = '';
    this.safeUrl = sanitizer.bypassSecurityTrustResourceUrl("");
    this.addToMenu = false;
    this.isVisible = false;
    this.tokenSended = false;
    this.authorized = false;
    this.loaded = false;
    this.isDuplicated = false;
    this.remoteRouting = false

    // Inherit from IEidosExternalApp
    if (app) {
      this.safeUrl = sanitizer.bypassSecurityTrustResourceUrl(app.url);
      this.updateData(app);
    }
  }

  updateUrl(url: string, sanitizer: DomSanitizer): void {
    this.url = url;
    if (this.url) {
      this.safeUrl = sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
  }

  updateData(app: IEidosExternalApp) {
    if (app.url) {
      try {
        let domain = (new URL(app.url));
        this.origin = `${domain.protocol}//${domain.hostname}` + (domain.port && domain.port !== '' ? `:${domain.port}` : '');
      }
      catch (errorUrl) {
        console.log('EidosExternalApp: Invalid URL', app.url, app, errorUrl);
        console.log('window.location', window.location);
      }
    }
    this.isValid = true;
    this.authorized = !this.useToken;

    this.addPropertyAutoBinding(app, this);
  }
}
