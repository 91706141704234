import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EidosMenu, EidosMenuItem } from '@common/models/eidos-menu.model';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosIconType, EidosMenuOrderStatus, LocalStorageKeys } from '@common/models/core-constant.model';
import { IEidosIcon } from '@common/config/environment.interface';
import { animate, style, transition, trigger } from '@angular/animations';
import { filter, take } from 'rxjs';
import { EidosUser } from '@common/models/user-info.model';
import { EidosExternalApp } from '@common/models/eidos-external-app.model';
import { EidosExternalAppService } from '../../eidos-external-app/eidos-external-app.service';

const searchFormAnimation = trigger('searchFormAnimation', [
  transition(':leave', [
    style({ left: '0px' }),
    animate('0.5s ease-in-out', style({ left: '-1000px' })),
  ]),
  transition(':enter', [
    style({ left: '-1000px' }),
    animate('0.25s ease-in-out', style({ left: '0px' })),
  ])
]);

@Component({
  selector: 'eidos-menu',
  templateUrl: './eidos-menu.component.html',
  animations: [searchFormAnimation],
  styleUrls: ['./eidos-menu.component.scss']
})
export class EidosMenuComponent implements OnInit {
  /**
   * Reflected menu order status enum
   *
   * @memberof EidosMenuComponent
   */
  public EidosMenuOrderStatus = EidosMenuOrderStatus;
  /**
   * Window current width
   *
   * @type {number}
   * @memberof EidosMenuComponent
   */
  public innerWidth: number = 0;
  /**
 * index of selected tab in menu
 *
 * @type {number}
 * @memberof EidosMenuComponent
 */
  public selectedTabIndex: number = 0;
  /**
   * Search mode flag
   *
   * @memberof EidosMenuComponent
   */
  public searching = false;
  /**
   * Search icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosMenuComponent
   */
  public searchIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "search",
    iconSize: 0.8,
  };
  /**
   * Close search icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosMenuComponent
   */
  public closeSearchIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "navigate_before",
    iconSize: 0.8,
  };
  /**
   * Menu tab icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosMenuComponent
   */
  public menuTabIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "menu_open",
    iconSize: 0.8,
  };
  /**
   * Notification service enabling flag
   *
   * @memberof EidosMenuComponent
   */
  public enableNotificationsTab = false;
  /**
   * Notification tab icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosMenuComponent
   */
  public notificationsTabIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "notifications",
    iconSize: 0.8,
  };
  /**
   * Current menu
   *
   * @type {(EidosMenu | null)}
   * @memberof EidosMenuComponent
   */
  public menu: EidosMenu | null = null;
  /**
   * Currently displayed menu items
   *
   * @type {Array<EidosMenuItem>}
   * @memberof EidosMenuComponent
   */
  public displayedMenuItems: Array<EidosMenuItem> = [];
  /**
   * Currently selected menu item id
   *
   * @type {number}
   * @memberof EidosMenuComponent
   */
  public selectedId: number = 0;
  /**
   * Search form
   *
   * @type {FormControl}
   * @memberof EidosMenuComponent
   */
  public sideMenuSearch: UntypedFormControl = new UntypedFormControl();
  /**
   * Current menu ordering status key
   *
   * @private
   * @memberof EidosMenuComponent
   */
  private menuOrderStatusOptions = 0;
  /**
   * Current menu ordering status
   *
   * @type {EidosMenuOrderStatus}
   * @memberof EidosMenuComponent
   */
  public order: EidosMenuOrderStatus = EidosMenuOrderStatus.Unset;
  /**
   * Menu pinned flag
   *
   * @type {boolean}
   * @memberof EidosMenuComponent
   */
  public isLocked: boolean = false;
  /**
   * Menu alpha order icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosMenuComponent
   */
  public menuOrderAlphaIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "sort_by_alpha",
    iconSize: 0.8,
  };
  /**
   * Menu order icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosMenuComponent
   */
  public menuAlphaOrderIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "arrow_downward",
    iconSize: 0.8,
  };
  /**
* Menu order icon configuration
*
* @type {IEidosIcon}
* @memberof EidosMenuComponent
*/
  public menuReverseAlphaOrderIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "arrow_upward",
    iconSize: 0.8,
  };
  /**
   * Get the current menu ordering status label
   *
   * @readonly
   * @type {string}
   * @memberof EidosMenuComponent
   */
  get menuOrderIconLabel(): string {
    switch (this.order) {
      case EidosMenuOrderStatus.AZ:
        return "menu.alpha_sorted";
      case EidosMenuOrderStatus.ZA:
        return "menu.reverse_alpha_sorted";
      default:
      case EidosMenuOrderStatus.Unset:
        return "menu.alpha_sort";
    }
  }
  /**
   * Menu pin icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosMenuComponent
   */
  public menuPinIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "push_pin",
    iconSize: 0.8,
  };
  /**
   * Getter of pin/unpin icon tooltip
   *
   * @readonly
   * @memberof EidosMenuComponent
   */
  public get menuPinIconLabel() {
    return this.isLocked ? 'menu.unpin' : 'menu.pin';
  }
  /**
   * Getter of pin/unpin icon class
   *
   * @readonly
   * @memberof EidosMenuComponent
   */
  public get menuPinIconClass() {
    return this.isLocked ? "lock-icon" : "lock-icon lock-icon-unpinned";
  }

  constructor(
    private externalAppEventService: EidosExternalAppService
    , private eidosUtilityService: EidosUtilityService
    , private securityService: EidosSecurityService
    , private eidosConfigService: EidosConfigService
  ) {
  }

  ngOnInit(): void {

    this.securityService.currentLoggedUser
      .subscribe(user => {
        this.initMenu(user);
      });

    this.eidosUtilityService.currentMenu
      .subscribe(menu => {
        this.menu = menu;
        this.buildDisplayMenuItem();
      });

    this.eidosConfigService.currentConfig.subscribe(config => this.enableNotificationsTab = config.notificationConfiguration.enabled);

    let keys: Array<number> = [];
    Object.keys(EidosMenuOrderStatus).filter(value => isNaN(Number(value)) === false).forEach(key => keys.push(+key));
    this.menuOrderStatusOptions = keys.length;
  }
  /**
   * Init the menu, basing on user info configuration
   *
   * @private
   * @param {EidosUser} user
   * @memberof EidosMenuComponent
   */
  private initMenu(user: EidosUser): void {

    this.innerWidth = window.innerWidth;

    const cachedLocked = localStorage.getItem(LocalStorageKeys.PINNED_MENU);
    if (cachedLocked) {
      this.isLocked = cachedLocked === "true";
    } else {
      this.isLocked = user.menuPinned;
    }
  }
  /**
   * Returns if the tab header must be hidden
   * The tab has to be shown when other functionalities are active
   * (not just the classic menu)
   *
   * @return {*}
   * @memberof EidosMenuComponent
   */
  public hideTabHeader() {
    return !(
      this.enableNotificationsTab
      // || ... Add future functionalities
    );
  }
  /**
   * Menu search handler
   *
   * @param {string} text
   * @memberof EidosMenuComponent
   */
  public onSearch(): void {
    this.buildDisplayMenuItem();
  }
  /**
   * Clear menu search handler
   *
   * @memberof EidosMenuComponent
   */
  public clearSearch(): void {
    this.sideMenuSearch.setValue('');
    this.buildDisplayMenuItem();
  }
  /**
   * Order menu handler
   *
   * @memberof EidosMenuComponent
   */
  public toggleOrder(): void {
    const nextValue = this.order + 1;
    this.order = nextValue === this.menuOrderStatusOptions ? 0 : nextValue;
    if (this.order !== EidosMenuOrderStatus.Unset) {
      this.onSort(this.displayedMenuItems);
    } else {
      this.buildDisplayMenuItem();
    }
  }
  /**
   * Sort the displayed menu
   *
   * @private
   * @param {Array<EidosMenuItem>} items
   * @return {*}  {Array<EidosMenuItem>}
   * @memberof EidosMenuComponent
   */
  private onSort(items: Array<EidosMenuItem>): Array<EidosMenuItem> {
    switch (this.order) {
      case EidosMenuOrderStatus.AZ:
        items = items.sort((a, b) => {
          return b.label > a.label ? -1 : 1;
        });
        break;
      case EidosMenuOrderStatus.ZA:
        items = items.sort((a, b) => {
          return a.label > b.label ? -1 : 1;
        });
        break;
      case EidosMenuOrderStatus.Unset:
        return items;
    }
    items.forEach(item => {
      if (item.children.length !== 0) {
        this.onSort(item.children);
      }
    });
    return items;
  }
  /**
   * Build the menu basing on MyBiz and external apps
   *
   * @private
   * @memberof EidosMenuComponent
   */
  private buildDisplayMenuItem(): void {

    let items: Array<EidosMenuItem> = [];

    if (this.menu) {
      const menuItems = this.menu.getItems(this.sideMenuSearch.value);
      items = [...menuItems];

      // Preserve menu ordering after search
      this.onSort(items);

      this.externalAppEventService.apps
        .pipe(
          filter((apps: EidosExternalApp[] | undefined) => !!apps && apps.length > 0),
          take(1)
        )
        .subscribe(apps => {
          if (apps) {
            for (let menuItem of apps.filter(app => app.addToMenu).map(app => new EidosMenuItem(app))) {
              menuItem.label = menuItem.label.toUpperCase();
              const indexOfApp = items.findIndex(x => x.id === menuItem.id);
              if (indexOfApp !== -1) {
                items[indexOfApp] = menuItem;
              } else {
                items.unshift(menuItem);
              }
            }
          }
        });
    }

    this.displayedMenuItems = items;
  }
  /**
   * Lock/unlock the menu to keep it open
   *
   * @memberof EidosMenuComponent
   */
  public toggleLock(): void {
    this.isLocked = !this.isLocked;
    this.eidosUtilityService.setMenuPin(this.isLocked);
  }
  /**
   * Set the current window size
   *
   * @private
   * @param {Event} event
   * @memberof EidosMenuComponent
   */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
