import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEidosFiltersRegistry } from '@common/models/eidos-filters.models';
import { EidosFilterComponent } from '../eidos-filter/eidos-filter.component';

@Component({
  selector: 'eidos-filter-number-popup',
  templateUrl: './eidos-filter-number-popup.component.html',
  styleUrls: ['./eidos-filter-number-popup.component.scss']
})
export class EidosFilterNumberPopupComponent extends EidosFilterComponent {
  /**
   * Current filter value (2-way binding)
   *
   * @type {IEidosFiltersRegistry["number"]["valueType"]}
   * @memberof EidosFilterNumberComponent
   */
  protected _value: IEidosFiltersRegistry["numberpopup"]["valueType"];
  public get value(): IEidosFiltersRegistry["numberpopup"]["valueType"] {
    return this._value;
  }
  @Input() public set value(val: IEidosFiltersRegistry["numberpopup"]["valueType"]) {
    this._value = val;
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<IEidosFiltersRegistry["numberpopup"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["numberpopup"]["valueType"]>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["number"]["config"]}
   * @memberof EidosFilterNumberComponent
   */
  @Input() public config: IEidosFiltersRegistry["numberpopup"]["config"];

  constructor() {
    super();
  }
}
