import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslocoService } from "@ngneat/transloco";

/**
 * Utility service necessary to translate the mat-paginator
 * References:
 * https://material.angular.io/components/paginator/overview
 * https://stackoverflow.com/questions/46869616/how-to-use-matpaginatorintl
 *
 * @export
 * @class EidosPaginatorI18n
 * @extends {MatPaginatorIntl}
 */
@Injectable()
export class EidosPaginatorI18n extends MatPaginatorIntl {

  /**
   * Creates an instance of EidosPaginatorI18n
   * 
   * @param {TranslocoService} translocoService
   * @memberof EidosPaginatorI18n
   */
  constructor(private translocoService: TranslocoService) {

    super();

    // React whenever the language is changed
    this.translocoService.langChanges$.subscribe(() => {
      this.translateLabels();
    });

    // Initialize the translations once at construction time
    this.translateLabels();
  }

  public injectTranslateService(translate: TranslocoService): void {
    this.translocoService = translate;

    // React whenever the language is changed
    this.translocoService.langChanges$.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  /**
   * Get translated range label
   *
   * @private
   * @param {number} page
   * @param {number} pageSize
   * @param {number} length
   * @memberof EidosPaginatorI18n
   */
  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = this.translocoService.translate("paginator.of");

    if (length === 0 || pageSize === 0) {
      return "0 " + of + " " + length;
    }
    
    length = Math.max(length, 0);
    
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);
    
    return startIndex + 1 + " - " + endIndex + " " + of + " " + length;
  };

  /**
   * Refresh translated labels
   *
   * @memberof EidosPaginatorI18n
   */
  public translateLabels(): void {

    this.firstPageLabel = this.translocoService.translate("paginator.first_page");
    this.itemsPerPageLabel = this.translocoService.translate("paginator.items_per_page");
    this.lastPageLabel = this.translocoService.translate("paginator.last_page");
    this.nextPageLabel = this.translocoService.translate("paginator.next_page");
    this.previousPageLabel = this.translocoService.translate("paginator.previous_page");

    // Fire a change event to make sure that the labels are refreshed
    this.changes.next(); 
  }
}