<div class="eidos-filter-wrapper eidos-number-filter-wrapper">
  <p class="eidos-filter-label eidos-number-filter-label" *ngIf="config.label">{{ config.label }}</p>

  <eidos-input-number [cssClass]="cssClass" [cssId]="cssId" [min]="config.min" [max]="config.max"
    [required]="!!config.required" [requiredMessage]="config.requiredMessage"
    [(value)]="value" (valueChange)="config.onValueChange"
    (onKeyDown)="keyDown($event)"
    [disabled]="!!config.disabled" [placeholder]="config.placeholder" [format]="config.format"
    (onFocusIn)="(!!config.onFocusIn && config.onFocusIn())"
    (onFocusOut)="(!!config.onFocusOut && config.onFocusOut())"></eidos-input-number>
</div>
