import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

import { IEidosGridColumnConfiguration } from '@app/core/models/eidos-grid.models';

/**
 * Generic command options in core search
 *
 * @export
 * @interface ICoreSearchServiceCommandOptions
 */
export interface ICoreSearchServiceCommandOptions {
  /**
   * Tab where trigger search
   * (all tabs will be triggered if tabIdentifier is undefined)
   *
   * @type {string}
   * @memberof ICoreSearchServiceCommandOptions
   */
  tabIdentifier?: string;
}

/**
 * Custom command options (from command columns) in core search
 *
 * @export
 * @interface ICoreSearchServiceCustomCommandOptions
 * @extends {ICoreSearchServiceCommandOptions}
 */
export interface ICoreSearchServiceCustomCommandOptions extends ICoreSearchServiceCommandOptions {
  /**
   * Command identifier
   *
   * @type {string}
   * @memberof ICoreSearchServiceCustomCommandOptions
   */
  command: string;
  /**
   * Command raiser column
   *
   * @type {IEidosGridColumnConfiguration}
   * @memberof ICoreSearchServiceCustomCommandOptions
   */
  column?: IEidosGridColumnConfiguration;
  /**
   * Command params
   *
   * @type {*}
   * @memberof ICoreSearchServiceCustomCommandOptions
   */
  params: any;
}

/**
 * Interface of a core search command options
 *
 * @export
 * @interface ICoreSearchServiceSearchCommandOptions
 */
export interface ICoreSearchServiceSearchCommandOptions extends ICoreSearchServiceCommandOptions {
  /**
   * Reset the paging before search
   *
   * @type {boolean}
   * @memberof ICoreSearchServiceSearchCommandOptions
   */
  resetPaging?: boolean;
}

/**
 * Model of core search pagination
 *
 * @export
 * @class CoreSearchServicePagingInfo
 */
export class CoreSearchServicePaging {
  /**
   * Current page index
   *
   * @type {number}
   * @memberof CoreSearchServicePaging
   */
  pageIndex: number = 1;
  /**
   * Total pages of the search
   *
   * @type {number}
   * @memberof CoreSearchServicePaging
   */
  pageTotal: number = 0;
  /**
   * Total records of the search
   *
   * @type {number}
   * @memberof CoreSearchServicePaging
   */
  recordsCount: number = 0;

  constructor() {
  }
}

@Injectable()
export class CoreSearchService {

  // #region Status

  /**
   * Is search loading config or results
   *
   * @type {boolean}
   * @memberof CoreSearchService
   */
  loading: boolean = false;
  /**
   * Current paging info
   *
   * @type {CoreSearchServicePaging}
   * @memberof CoreSearchService
   */
  currentPaging: CoreSearchServicePaging = new CoreSearchServicePaging();

  // #endregion Status

  // #region Commands

  /**
   * Emits a results invalidation request
   *
   * @type {Subject<void>}
   * @memberof CoreSearchService
   */
  emptyResultsCommand$: Subject<void> = new Subject<void>();
  /**
   * Emits a search command
   *
   * @type {Subject<ICoreSearchServiceSearchCommandOptions>}
   * @memberof CoreSearchService
   */
  searchCommand$: Subject<ICoreSearchServiceSearchCommandOptions> = new Subject<ICoreSearchServiceSearchCommandOptions>();
  /**
   * Emits a custom command from a result grid column
   *
   * @type {Subject<ICoreSearchServiceCustomCommandOptions>}
   * @memberof CoreSearchService
   */
  resultCommand$: Subject<ICoreSearchServiceCustomCommandOptions> = new Subject<ICoreSearchServiceCustomCommandOptions>();

  // #endregion Commands

  constructor() {
  }
}
