import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CoreSearchResultsViewConfig, ICoreSearchResultsRowTilesViewConfig, ICoreSearchResultsGridViewConfig, ICoreSearchTabConfiguration, ICoreSearchResultSelection } from '@common/models/eidos-search.model';
import { EidosBaseComponent } from '../../eidos-base.component';
import { CoreSearchService } from '../core-search.service';
import { IEidosGridButtonCellClickEvent } from '@app/core/models/eidos-grid.models';

@Component({
  selector: 'eidos-search-results',
  templateUrl: './eidos-search-results.component.html',
  styleUrls: ['./eidos-search-results.component.scss'],
  providers: [CoreSearchService]
})
export class EidosSearchResultsComponent<E> extends EidosBaseComponent {

  ICoreSearchResultsRowTilesViewConfig!: ICoreSearchResultsRowTilesViewConfig;
  ICoreSearchResultsGridViewConfig!: ICoreSearchResultsGridViewConfig;
  /**
   * Search component service
   *
   * @type {CoreSearchService}
   * @memberof EidosSearchResultsComponent
   */
  @Input() coreSearchService?: CoreSearchService;
  /**
   * Current search results
   *
   * @private
   * @type {Array<E>}
   * @memberof EidosSearchResultsComponent
   */
  protected _results: Array<E> = [];
  @Input() get results(): Array<E> {
    return this._results;
  }
  set results(value: Array<E>) {
    this._results = value;
    this.collapsedDisplayedResults = this.results;
  }

  protected collapsedDisplayedResults: E[] = [];

  get isResultsCollapsed(): boolean {
    return this.results?.length > 0 && this.results.length !== this.collapsedDisplayedResults.length;
  }

  /**
   * Search results view config
   *
   * @protected
   * @type {Array<CoreSearchResultsViewConfig>}
   * @memberof EidosSearchResultsComponent
   */
  protected _config: Array<CoreSearchResultsViewConfig> = [];
  /**
   * Search results view config setter
   *
   * @type {Array<CoreSearchResultsViewConfig>}
   * @memberof EidosSearchResultsComponent
   */
  @Input() public set config(value: Array<CoreSearchResultsViewConfig>) {
    this._config = value;
  }
  /**
   * Search results view config getter
   *
   * @readonly
   * @memberof EidosSearchResultsComponent
   */
  public get config() {
    return this._config;
  }

  private _configFull: ICoreSearchTabConfiguration | undefined = undefined;
  @Input() public set configFull(value: ICoreSearchTabConfiguration | undefined) {
    this._configFull = value;
  }
  public get EntityType() {
    if (this._configFull)
      return this._configFull.EntityType;

    return undefined;
  }
  /**
   * Current results view config
   *
   * @type {CoreSearchResultsViewConfig}
   * @memberof EidosSearchResultsComponent
   */
  @Input() public currentResultsView: CoreSearchResultsViewConfig = { id: "rows", icon: "detailslayout" };
  /**
   * Selected results emitter
   *
   * @type {(EventEmitter<ICoreSearchResultSelection>)}
   * @memberof EidosSearchResultsComponent
   */
  @Output() public onResultsSelected: EventEmitter<ICoreSearchResultSelection> = new EventEmitter<ICoreSearchResultSelection>();
  /**
   * Entity to its id map function
   *
   * @param {*} result
   * @memberof EidosSearchResultsComponent
   */
  @Input() public resultIdMap?: ((result: any) => string | undefined);
  /**
   * Is results multiselect enabled
   *
   * @type {boolean}
   * @memberof EidosSearchResultsComponent
   */
  @Input() multiselectEnabled: boolean = false;
  /**
   * Multiselected results
   *
   * @type {E[]}
   * @memberof EidosSearchResultsComponent
   */
  resultsSelected: E[] = [];
  /**
   * IDs of multiselected results
   *
   * @type {any[]}
   * @memberof EidosSearchResultsComponent
   */
  resultsIDSelected: any[] = [];
  /**
   * Result checkability getter
   *
   * @memberof EidosSearchResultsComponent
   */
  @Input() isResultCheckable: (item: E, selected: E[], ...args: any[]) => boolean = () => true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.coreSearchService!.emptyResultsCommand$.subscribe(() => this.results = []);

    // Attach handler of command columns to their buttons
    this.config.forEach(c => {
      if (c.id === 'grid') {
        const config = (c as ICoreSearchResultsGridViewConfig).config;
        config.columns.filter(col => col.fieldType === 'command' && !!col.command && (col.buttonsConfig ?? []).length > 0).forEach(col => {
          col.buttonsConfig![0].onClick = (event: IEidosGridButtonCellClickEvent) => {
            this.coreSearchService?.resultCommand$.next({
              tabIdentifier: this._configFull?.identifier ?? '',
              command: col.command!,
              column: col,
              params: event.data,
            })
          }
        });
      }
    });
  }
  /**
   * Row tiles result click handler
   *
   * @param {{addedItems: Array<any>}} event
   * @memberof EidosSearchResultsComponent
   */
  public onRowTileResultsClick(event: { addedItems: Array<any> }) {
    if (event && Array.isArray(event.addedItems) && event.addedItems.length > 0) {
      this.onResultsClick(event.addedItems[0]);
    }
  }
  /**
   * Result from grid click handler
   *
   * @template T
   * @param {T} event
   * @memberof EidosSearchResultsComponent
   */
  protected onGridResultsClick<T extends { data: E }>(event: T) {

    if (this.currentResultsView!.id === 'grid') {
      const gridConfig = this.config.find(c => c.id === 'grid') as ICoreSearchResultsGridViewConfig;
      if (!!gridConfig?.config.collapseRowsAfterRowClick) {
        this.collapsedDisplayedResults = [event.data];
      }
    }    
    
    this.onResultsClick(event.data);
  }

  protected resetCollapsedDisplayedResults() {
    this.collapsedDisplayedResults = this.results;
  }

  /**
   * Result click handler
   *
   * @param {E} event
   * @memberof EidosSearchResultsComponent
   */

  public onResultsClick(event: E, scope: any = this, additionalProperties?: any) {
    const id = scope.resultIdMap(event);

    /**
     * Merdone enorme
     * Necessario creare un servizio per
     * - gestire gli eventi di selezione da checkbox e selezione per procedere
     * - raggruppare eventi di ricerca, popolamento e cambio filtri
     * - store degli elementi selezionati per evitare di doverli riscaricare successivamente
     */
    if (scope.resultsSelected.find((item: E) => scope.resultIdMap(item) !== id) != undefined) {

      const event: ICoreSearchResultSelection = {
        items: scope.resultsSelected ?? [],
        id: (scope.resultsSelected ?? []).map((item: E) => scope.resultIdMap ? scope.resultIdMap(item) : (<any>item)?.id),
        additionalProperties: {
          tabIdentifier: scope._configFull?.identifier ?? ''
        }
      };

      scope.onResultsSelected.emit(event);

    } else {
      let resultEmitter: ICoreSearchResultSelection = {
        items: event,
        id: scope.resultIdMap !== undefined && scope.resultIdMap(event) !== undefined ? scope.resultIdMap(event) : (<any>event)?.id,
      };

      if (additionalProperties) {
        resultEmitter.additionalProperties = additionalProperties;
      }

      scope.onResultsSelected.emit(resultEmitter);
    }
  }
  /**
   * Result selection handler
   *
   * @param {E} event
   * @param {boolean} isSelected
   * @param {*} [scope=this]
   * @memberof EidosSearchResultsComponent
   */
  public onResultsCheck(event: E, isSelected: boolean, scope: any = this) {
    if (scope.resultIdMap !== undefined && scope.resultIdMap(event) !== undefined) {
      scope.resultsSelected = scope.resultsSelected.filter((item: E) => scope.resultIdMap(item) !== scope.resultIdMap(event));
    } else {
      scope.resultsSelected = scope.resultsSelected.filter((item: E) => (<any>item).id !== (<any>event).id);
    }

    if (isSelected) {
      scope.resultsSelected.push(event);
    }
  }

  public CustomButtonClick(event: any) {
    // da generalizzare con la key della configurtrazione della griglia
    let iid = event.data.individual_id;
    let resultId: ICoreSearchResultSelection = {
      items: event.data,
      id: iid + ''
    };

    this.onResultsSelected.emit(resultId);
  }

  getTemplateData(result: any) {
    const self = this;
    return {
      item: result,
      click: self.onResultsClick,
      check: self.onResultsCheck,
      checkedResults: self.resultsSelected,
      checkable: self.multiselectEnabled,
      checkEnabled: self.isResultCheckable(result, self.resultsSelected, this),
      scope: this
    };
  }
}
