import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'eidos-filter-category',
  templateUrl: './eidos-filter-category.component.html',
  styleUrls: ['./eidos-filter-category.component.scss'],
})
export class EidosFilterCategoryComponent implements OnInit {
  @Input() public moreFilters?: any[];

  @Input() public set filters(value: any) {
    this._filters = value;
    this.filtersChange.emit(value);
  }

  @Output() public filtersChange: EventEmitter<any> = new EventEmitter<any>();

  private _filters: any;

  public get filters(): any {
    return this._filters;
  }

  @Input() public filterConfiguration: any;

  @Input() public searchService: any;

  @Input() public buttonText: string = '';
  @Input() public buttonIcon: string = '';
  @Input() public buttonEntity: string = '';

  public isCategorySelectOpened: boolean = false;

  public isPopoverVisible: boolean = false;

  @Output() public search: EventEmitter<any> = new EventEmitter<any>();

  private _categories: { Cod: string; Desc: string; isDefault: boolean }[] = [];

  public get categories(): { Cod: string; Desc: string; isDefault: boolean }[] {
    return this._categories;
  }

  public set categories(value: { Cod: string; Desc: string; isDefault: boolean }[]) {
    this._categories = value;
    !this.filters.FieldName && this.selectDeafultCategory();
  }

  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.loadCategories();
  }

  public toggleCategorySelect(): void {
    this.isCategorySelectOpened = !this.isCategorySelectOpened;
  }

  public togglePopover(): void {
    this.isPopoverVisible = !this.isPopoverVisible;
  }

  public handleSearch(): void {
    let filters = { ...this.filters };
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null) {
        filters[key] = '';
      }
    });
    this.filters = filters;
    this.search.emit();
    this.isPopoverVisible = false;
  }

  public keyDown(event: any, scope: any = this): void {
    if (event.event.keyCode == 13) {
      event.component.blur();
      scope.handleSearch();
    }
  }

  public loadCategories(): void {
    if (this.searchService && this.searchService.getDynamicAPI && this.filterConfiguration && this.filterConfiguration.api) {
      this.searchService.getDynamicAPI(this.filterConfiguration.api).subscribe((response: any) => {
        this.categories = response.body;
      });
    }
  }

  public _NewBtnClick() {
    // aggiungo i parametri
    this.buttonClick.emit();
  }

  public selectDeafultCategory(): any {
    let d = this.categories.find((itm) => {
      return itm.isDefault;
    });
    if (!d) return;
    this.filters.FieldName = d.Cod;
  }
}
