<content *ngIf="fieldData && fieldConfig" [ngSwitch]="fieldConfig.eidosFieldType">
  <ng-template [ngSwitchCase]="EidosObjectFieldType.Date">
    {{ fieldData.disabled ? value : ""}}
    <eidos-input-datetime *ngIf="false" [(value)]="_value!"></eidos-input-datetime>
  </ng-template>
  <ng-template [ngSwitchCase]="EidosObjectFieldType.DateTmz">
    {{ fieldData.disabled ? value : ""}}
    <eidos-input-datetime *ngIf="false" [(value)]="_value!"></eidos-input-datetime>
  </ng-template>
  <ng-template [ngSwitchCase]="EidosObjectFieldType.Time">
    <p *ngIf="fieldData.disabled"> {{ value }} </p>
    <eidos-input-datetime *ngIf="false" [dateValueType]="'time'" [(value)]="_value!"></eidos-input-datetime>
  </ng-template>
  <ng-template [ngSwitchCase]="EidosObjectFieldType.TimeTmz">
    <p *ngIf="fieldData.disabled"> {{ value }} </p>
    <eidos-input-datetime *ngIf="false" [dateValueType]="'time'" [(value)]="_value!"></eidos-input-datetime>
  </ng-template>
</content>