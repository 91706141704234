<div class="eidos-filter-wrapper"
  [ngClass]="!!config.placeholder ? 'eidos-checkbox-filter-wrapper' : 'eidos-checkbox-filter-horizontal-wrapper'">
  <p class="eidos-filter-label eidos-checkbox-filter-label" *ngIf="!!config.label || !!config.placeholder">
    {{ !!config.placeholder ? config.placeholder : config.label }}
  </p>
  <eidos-input-checkbox
    [(value)]="value" (valueChange)="config.onValueChange" [disabled]="!!config.disabled"
    (onFocusIn)="(!!config.onFocusIn && config.onFocusIn())"
    (onFocusOut)="(!!config.onFocusOut && config.onFocusOut())">
  </eidos-input-checkbox>
</div>