import { Component, OnDestroy, OnInit } from "@angular/core";
import { Params } from "@angular/router";
import { Subject } from "rxjs";
import { v4 as uuid } from 'uuid';

/**
 * Eidos base component, includes:
 * - Unsubscriptions handling
 *
 * @export
 * @abstract
 * @class EidosBaseComponent
 * @implements {OnDestroy}
 */
@Component({ selector: 'eidos-base', template: '' })
export abstract class EidosBaseComponent implements OnInit, OnDestroy {
  /**
   * Component lifetime subscriptions subject
   *
   * @protected
   * @type {Subject<unknown>}
   * @memberof EidosBaseComponent
   */
  protected subscription: Subject<unknown> = new Subject();
  eidosId:string

  constructor() {
    this.eidosId = uuid()
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // Destroy all subscriptions
    this.subscription.next(null);
    this.subscription.complete();
    this.subscription.unsubscribe();
  }

  getQueryParams(params:Params,name:string) {
    const key = Object.keys(params).find(p=>(''+p).toLowerCase()===name.toLowerCase())
    if(!key) return undefined
    return params[key]
  }

  consoleLog(e:any,where?:string) {
    if(where) console.log(`************${where}`)
    console.log(e)
  }
}
