import { Injectable, TemplateRef, ViewChild, isDevMode } from '@angular/core';
import { IEidosModalConfig, IEidosModalInfoConfirm, IEidosModalInfoData, IEidosModalInfoSendEmail, IEidosModalType } from './eidos-modal.component';
import { BehaviorSubject } from 'rxjs';
import { uniqueId } from 'lodash';
import { EidosLightFormFieldType } from '../eidos-light-form/eidos-light-form.component';
import validationEngine from 'devextreme/ui/validation_engine';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class EidosModalService {

  openModals: BehaviorSubject<IEidosModalConfig[]> = new BehaviorSubject<IEidosModalConfig[]>([]);

  @ViewChild('iframeTemplate') iframeTemplate!: TemplateRef<any>;

  constructor(private sanitizer: DomSanitizer) {
    window.addEventListener("message", (event) => {
      if(isDevMode() && event?.origin !== window.origin) {
        if(!!event.data) this.actionFrame(event?.data);
      } else if(!isDevMode() && event?.origin === window.origin) {
        if(!!event.data) this.actionFrame(event?.data);
      }

    }, false);
  }

  actionFrame(cmd:any) {
    switch(cmd?.action?.toLowerCase()) {
      case 'close':
        this.closeCurrentModal();
        break;
      case 'titlepopup':
        this.updateCurrentTitle(cmd?.data);
        break;
    }
  }
  updateCurrentTitle(title:string) {
    const l = this.openModals.value.length;
    if(l<1) return;
    const cfg = this.openModals.value[l-1]
    cfg.title = () => title;
  }
  openModal(modalConfig: IEidosModalConfig, _isOpenPromoCard?: boolean): number {
    modalConfig.modalID = Math.max(...[0, ...this.openModals.value.map((modal: IEidosModalConfig) => modal.modalID!)]) + 1;
    modalConfig.open = true;
    this.openModals.next([...this.openModals.value, modalConfig]);

    return modalConfig.modalID;
  }

  openModalPromise(modalConfig: IEidosModalConfig): Promise<any> {
    modalConfig.modalID = Math.max(...[0, ...this.openModals.value.map((modal: IEidosModalConfig) => modal.modalID!)]) + 1;
    modalConfig.open = true;
    const onclose = modalConfig.onClosed ?? ((_e: any) => { });
    const self = this;
    return new Promise<boolean>(resolve => {
      modalConfig.onClosed = (event) => {
        resolve(event)
        onclose(event);
      },
        self.openModal(modalConfig);
    }).then(choose => choose);

  }
  // called automatically when the modal opened property is set to false
  closeModalByID(modalID: number) {
    const modal = this.openModals.value.find((modal: IEidosModalConfig) => modal.modalID === modalID);
    if (!modal) return
    const index = this.openModals.value.indexOf(modal)

    // remove all modals after the one we want to close because i use a stack logic
    !modal.doNotUseStackLogic && this.openModals.next(this.openModals.value.slice(0, index));
  }

  // to call if you want to close a modal from outside the modal component
  closeModal(modalKey: string) {
    if (!modalKey) return;
    const modal = this.openModals.value.find((modal: IEidosModalConfig) => modal.modalKey === modalKey);
    if (modal) modal.open = false;
  }
  closeCurrentModal() {
    const l = this.openModals.value.length;
    if(l<1) return;
    this.closeModal(this.openModals.value[l-1].modalKey)
  }
  isModalOpen(modalKey: string): boolean {
    return this.openModals.value.some((modal: IEidosModalConfig) => modal.modalKey === modalKey && modal.open);
  }

  applyModalValidation(modalConfig: IEidosModalConfig): boolean {
    return !modalConfig.formFields?.length || !!validationEngine.validateGroup(modalConfig.modalKey).isValid;
  }

  infoMessage(data: IEidosModalInfoData): Promise<void> {
    return new Promise<void>(resolve => {
      const modalConfig: IEidosModalConfig = {
        modalKey: uniqueId('modalEidosInfoDialog'),
        type: IEidosModalType.Basic,
        width: data.width ?? '80%',
        height: data.height ?? '100%',
        position: data.position ?? 'center',
        closeOnOutsideClick: false,
        doNotUseStackLogic: true,
        title: () => data.title,
        subtitle: () => data.subtitle ?? '',
        body: () => data.message ?? '',
        onClosed: () => resolve(),
        cssClass: 'modal-eidos-dialog modal-eidos-dialog-info',
      }
      this.openModal(modalConfig);
    });
  }
  async warningMessage(data: IEidosModalInfoData): Promise<void> {
    return new Promise<void>(resolve => {
      const modalConfig: IEidosModalConfig = {
        modalKey: uniqueId('modalEidosWarningDialog'),
        type: IEidosModalType.Basic,
        width: data.width ?? '80%',
        height: data.height ?? '100%',
        position: data.position ?? 'center',
        closeOnOutsideClick: false,
        doNotUseStackLogic: true,
        title: () => data.title,
        subtitle: () => data.subtitle ?? '',
        body: () => data.message ?? '',
        onClosed: () => resolve(),
        cssClass: 'modal-eidos-dialog modal-eidos-dialog-warning',
      }
      this.openModal(modalConfig);
    });
  }
  errorMessage(data: IEidosModalInfoData): Promise<void> {
    return new Promise<void>(resolve => {
      const modalConfig: IEidosModalConfig = {
        modalKey: uniqueId('modalEidosErrorDialog'),
        type: IEidosModalType.Basic,
        width: data.width ?? '80%',
        height: data.height ?? '100%',
        position: data.position ?? 'center',
        closeOnOutsideClick: false,
        doNotUseStackLogic: true,
        title: () => data.title,
        subtitle: () => data.subtitle ?? '',
        body: () => data.message ?? '',
        onClosed: () => resolve(),
        cssClass: 'modal-eidos-dialog modal-eidos-dialog-error',
      }
      this.openModal(modalConfig);
    });
  }
  confirmMessage(data: IEidosModalInfoConfirm): Promise<boolean> {
    const self = this;
    return new Promise<boolean>(resolve => {
      const modalConfig: IEidosModalConfig = {
        modalKey: uniqueId('modalEidosConfirmDialog'),
        type: IEidosModalType.Basic,
        width: data.width ?? '60%',
        height: data.height ?? '70%',
        position: data.position ?? 'center',
        doNotUseStackLogic: true,
        title: () => data.title,
        subtitle: () => data.subtitle ?? '',
        body: () => data.message ?? '',
        closeOnOutsideClick: false,
        cssClass: 'modal-eidos-dialog modal-eidos-dialog-confirm',
        buttonsConfig: [
          {
            text: () => data.captionCancel ?? 'CANCEL',
            onClick: () => {
              self.closeModal(modalConfig.modalKey);
              resolve(false);
            },
            position: 'center',
            stylingMode: 'contained-danger',
          },
          {
            text: () => data.captionOk ?? 'OK',
            onClick: () => {
              self.closeModal(modalConfig.modalKey);
              resolve(true);
            },
            position: 'center',
            stylingMode: 'contained-success',
          },

        ],
      }
      self.openModal(modalConfig);
    }).then(choose => choose);

  }
  sendEmail(data: IEidosModalInfoSendEmail): Promise<any> {
    const self = this;
    return new Promise<boolean>(resolve => {
      const modalConfig: IEidosModalConfig = {
        modalKey: uniqueId('modalEidosSendEmailDialog'),
        type: IEidosModalType.Basic,
        width: data.width ?? '60%',
        height: data.height ?? '70%',
        position: data.position ?? 'center',
        doNotUseStackLogic: true,
        title: () => data.title,
        subtitle: () => data.subtitle ?? '',
        formFields: [
          {
            field: 'to',
            caption: 'To',
            type: EidosLightFormFieldType.Email,
            showClearButton: true,
            required: true,
            /* selectizeInputConfig: {
              type: 'selectize',
              items: [],
              searchField: ['email'],
              displayField: 'email',
              valueField: 'email',
              keyField: 'email',
              minSearchLength: 0,
              maxItems: 999,
              acceptCustomValue: true,
            } */
          },
          {
            field: 'cc',
            caption: 'Cc',
            type: EidosLightFormFieldType.Email,
            showClearButton: true,
          },
          {
            field: 'ccn',
            caption: 'Ccn',
            type: EidosLightFormFieldType.Email,
            showClearButton: true,
          },
          {
            field: 'subject',
            caption: 'Subject',
            type: EidosLightFormFieldType.Text,
            showClearButton: true,
            required: true,
          },
          {
            field: 'template',
            caption: 'Template',
            type: EidosLightFormFieldType.Text,
            showClearButton: true,
          },
          {
            field: 'message',
            caption: 'Message',
            type: EidosLightFormFieldType.Html,
            height: '100px',
            width: 'col-100'
          },
          {
            field: 'attachments',
            caption: 'Attachments',
            type: EidosLightFormFieldType.File,
            width: 'col-100'
          }
        ],
        formValues: { ...data },
        closeOnOutsideClick: false,
        cssClass: 'modal-eidos-dialog modal-eidos-dialog-send-email',
        buttonsConfig: [
          {
            text: () => data.captionCancel ?? 'CANCEL',
            onClick: () => {
              self.closeModal(modalConfig.modalKey);
              resolve(false);
            },
            position: 'center',
            stylingMode: 'contained-danger',
          },
          {
            text: () => data.captionOk ?? 'SEND',
            onClick: () => {
              if (!self.applyModalValidation(modalConfig)) return

              self.closeModal(modalConfig.modalKey);
              resolve(modalConfig.formValues);
            },
            position: 'center',
            stylingMode: 'contained-success',
          },

        ],
      }
      self.openModal(modalConfig);
    }).then(choose => choose);

  }

  openIframe(data: IEidosModalInfoData): Promise<void> {
    return new Promise<void>(resolve => {
      const modalConfig: IEidosModalConfig = {
        modalKey: uniqueId('modalEidosIframeDialog'),
        type: IEidosModalType.Basic,
        width: data.width ?? '80%',
        height: data.height ?? '100%',
        position: data.position ?? 'right',
        closeOnOutsideClick: true,
        doNotUseStackLogic: true,
        iframeUrl: data.iframeUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(data.iframeUrl) : '',
        title: () => data.title,
        onClosed: () => {
          data.closeCallback?.();
          resolve()
        },
        buttonsConfig: [
          {
            text: () => 'CANCEL',
            onClick: () => this.closeModal(modalConfig.modalKey),
            position: 'left',
            stylingMode: 'contained-danger',
          },
        ]
      }
      this.openModal(modalConfig);
    })
  }
}
