import { AppModule } from '@app/app.module';
import { DateTime } from 'luxon';
import { CoreFormatService } from '../services/core-format.service';


function dateStringNormalized(s: string) {
  //trasform string in iso datetime
  return s;
}
export interface IEidosDynObject {
  [key: string]: string
}
export class SafeBaseObject {

  constructor() {
  }

  updateData(data: any) {
    if (data) {
      this.addMangledProperty(data);
    }
  }

  protected camelCase(name: string): string {
    if (name.length === 0) return name;
    return name.charAt(0).toLowerCase() + name.slice(1);
  }
  protected pascalCase(name: string): string {
    if (name.length === 0) return name;
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  mangledPropertyName(name: string): string {
    if (name.length === 0) return name;

    // const dynClass = this as any;
    // if(dynClass[name]) return name;

    return this.camelCase(name.split('_').map(s => this.pascalCase(s)).join(''));
  }
  addMangledProperty(from: any, keepNulls?: boolean) {
    if(!from) return
    const dynClass = this as any;
    Object.keys(from).forEach(property => {
      if (from.hasOwnProperty(property)) {
        const name = this.mangledPropertyName(property);

        if(typeof dynClass[name] === 'boolean') {
          this.addBooleanProperty(name,from,property)
          return;
        }

        if (dynClass[name] instanceof DateTime) {
          this.addDateTimeProperty(name, from, property, keepNulls)
          return
        }

        if(typeof dynClass[name] === 'string') {
          dynClass[name] = `${from[property] ?? dynClass[name]}`.trim()
          return;
        }

        dynClass[name] = from[property] ?? dynClass[name]

        /* switch (typeof dynClass[name]) {
          case 'boolean':
            this.addBooleanProperty(name, from, property)
            break;
          default:
            dynClass[this.mangledPropertyName(property)] = from[property];
            break;
        } */
      }
    });
  }

  formatDateTimeProperties(coreFormatService?: CoreFormatService) {
    if (!coreFormatService) coreFormatService = AppModule.injector.get(CoreFormatService);

    const dynClass = this as any;

    Object.keys(dynClass).forEach(property => {
      const name = this.mangledPropertyName(property);
      if (dynClass[property] instanceof DateTime) dynClass[`formatted${this.pascalCase(name)}`] = dynClass[name]?.isValid ? dynClass[name].toFormat(coreFormatService?.DateFmtWithMonthName() || 'dd MMM yyyy') : '';
    });
  }

  formatCurrencyAmountProperties(properties: string[], currency: string, defaultValues?: {[property: string]: string}, coreFormatService?: CoreFormatService) {
    if (!coreFormatService) coreFormatService = AppModule.injector.get(CoreFormatService);

    const dynClass = this as any;

    properties
			.filter(property => !isNaN(dynClass[property]))
			.forEach(property => {
				const name = this.mangledPropertyName(property)
				if (dynClass[name] !== undefined) dynClass[`formatted${this.pascalCase(name)}`] = dynClass[name] == null && defaultValues?.[property] ? defaultValues[property] : coreFormatService?.CurrencyAmount(dynClass[name], currency)
			})
	}

  addProperty(property: string, from: any, fromProperty: string) {
    const dynClass = this as any;
    if (from.hasOwnProperty(fromProperty)) {
      dynClass[property] = from[fromProperty];
    }
  }

  addDateTimeProperty(property: string, from: any, fromProperty: string, keepNulls?: boolean) {
    const dynClass = this as any;
    if (from.hasOwnProperty(fromProperty) && (keepNulls || !!from[fromProperty])) {
      dynClass[property] = DateTime.fromISO(dateStringNormalized(from[fromProperty]));
    }
  }

  addBooleanProperty(property: string, from: any, fromProperty: string) {
    const dynClass = this as any;
    if (from.hasOwnProperty(fromProperty)) {
      const s = (`${from[fromProperty] ?? ''}`).toUpperCase();
      dynClass[property] = +from[fromProperty] === 1 || s === 'Y' || s === 'YES' || s === 'S' || s === 'SI' || s === '1' || s === 'T' || s === 'TRUE';
    }
  }

  addCurrencyAmountProperty(property: string, from: any, fromProperty: string, currency: string) {
    const dynClass = this as any;
    if (from.hasOwnProperty(fromProperty)) {
      dynClass[property] = AppModule.injector.get(CoreFormatService).CurrencyAmount(from[fromProperty], currency);
    }
  }

  getGenericPropertyValue(from: any, to: any): any {
    if (Array.isArray(from)) {
      return [...from];
    } else {
      switch (typeof to) {
        case 'object':
          if (to instanceof DateTime) return DateTime.fromISO(dateStringNormalized(from));
          if (to instanceof Date) return DateTime.fromISO(dateStringNormalized(from)).toJSDate();
          break;
        case 'boolean':
          return from === 1 || from === true;
        case 'string':
          return from;
        case 'number':
        case 'bigint':
          return +from;
        default:
          return null; //???
      }

    }
  }
  snakeToCamel(str: string) {
    return str.replace(
      /([-_][a-z])/g,
      (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );
  };
  addPropertyAutoBinding(data: any, model: any) {
    const dynClass = this as any;
    Object.keys(data).forEach(property => {
      if (data.hasOwnProperty(property)) {
        if (model.hasOwnProperty(property)) {
          dynClass[property] = this.getGenericPropertyValue(data[property], model[property]);
        } else {
          const localProperty = this.snakeToCamel(property);
          if (model.hasOwnProperty(localProperty)) {
            dynClass[localProperty] = this.getGenericPropertyValue(data[property], model[localProperty]);
          }
        }
      }
    });
  }
}
export class BaseObject {
  [key: string]: any;

  constructor(initdata?: any) {
    if (initdata) {
      this.updateData(initdata);
    }
  }

  updateData(_: any) {
    // To override in each subclass
  }

  private camelCase(name: string): string {
    if (name.length === 0) return name;
    return name.charAt(0).toLowerCase() + name.slice(1);
  }
  private pascalCase(name: string): string {
    if (name.length === 0) return name;
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  mangledPropertyName(name: string): string {
    if (name.length === 0) return name;
    return this.camelCase(name.split('_').map(s => this.pascalCase(s)).join(''));
  }
  addMangledProperty(from: any) {
    Object.keys(from).forEach(property => {
      if (from.hasOwnProperty(property)) {
        this[this.mangledPropertyName(property)] = from[property];
      }
    });
  }
  addProperty(property: string, from: any, fromProperty: string) {
    if (from.hasOwnProperty(fromProperty)) {
      this[property] = from[fromProperty];
    }
  }

  addDateTimeProperty(property: string, from: any, fromProperty: string) {
    if (from.hasOwnProperty(fromProperty)) {
      this[property] = DateTime.fromISO(dateStringNormalized(from[fromProperty]));
    }
  }

  addBooleanProperty(property: string, from: any, fromProperty: string) {
    if (from.hasOwnProperty(fromProperty)) {
      const s = (`${from[fromProperty] ?? ''}`).toUpperCase();
      this[property] = +from[fromProperty] === 1 || s === 'Y' || s === 'YES' || s === 'S' || s === 'SI' || s === '1' || s === 'T' || s === 'TRUE';
    }
  }

  getGenericPropertyValue(from: any, to: any): any {
    if (Array.isArray(from)) {
      return [...from];
    } else {
      switch (typeof to) {
        case 'object':
          if (to instanceof DateTime) return DateTime.fromISO(dateStringNormalized(from));
          if (to instanceof Date) return DateTime.fromISO(dateStringNormalized(from)).toJSDate();
          break;
        case 'boolean':
          return from === 1 || from === true;
        case 'string':
          return from;
        case 'number':
        case 'bigint':
          return +from;
        default:
          return null; //???
      }

    }
  }
  snakeToCamel(str: string) {
    return str.replace(
      /([-_][a-z])/g,
      (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );
  };
  addPropertyAutoBinding(data: any, model: any) {
    Object.keys(data).forEach(property => {
      if (data.hasOwnProperty(property)) {
        if (model.hasOwnProperty(property)) {
          this[property] = this.getGenericPropertyValue(data[property], model[property]);
        } else {
          const localProperty = this.snakeToCamel(property);
          if (model.hasOwnProperty(localProperty)) {
            this[localProperty] = this.getGenericPropertyValue(data[property], model[localProperty]);
          }
        }
      }
    });
  }
}

