import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  /**
   * Current return url in home button
   *
   * @private
   * @memberof ErrorService
   */
  private _returnUrl = window.location.origin;
  public get returnUrl(): string {
    return this._returnUrl;
  }
  public set returnUrl(url: string) {
    this._returnUrl = url;
  }

  constructor() {
  }
}