import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxButtonComponent } from 'devextreme-angular';
import { assign } from 'lodash';

import { CoreFormMode, ICoreFormCommandsSettings } from '@app/core/models/eidos-form.models';
import { EidosBaseComponent } from '@common/components/eidos-base.component';
@Component({
  selector: 'eidos-filter-form-commands',
  templateUrl: './eidos-filter-form-commands.component.html',
  styleUrls: ['./eidos-filter-form-commands.component.scss']
})
export class EidosFilterFormCommandsComponent extends EidosBaseComponent {

  static DEFAULT_CONFIG: ICoreFormCommandsSettings = {
    mode: CoreFormMode.Search,
    hideSubmit: false,
    hideClear: false,
    hideMultiselect: true,
    submitCssClass: '',
    submitCssId: '',
    searchLabel: 'Search',
    submitLabel: 'Submit',
    clearCssClass: '',
    clearCssId: '',
    clearLabel: 'Clear filters',
    clearIcon: '',
    multiselectCssClass: '',
    multiselectCssId: '',
    multiselectLabel: 'Enable results selection',
    multiselectIcon: '',
    disabledClear: false,
    disabledSubmit: false,
    disabledMultiselect: true
  };

  CoreFormMode = CoreFormMode;
  /**
   * Commands config
   *
   * @private
   * @type {ICoreFormCommandsSettings}
   * @memberof EidosFilterFormCommandsComponent
   */
  private _config?: ICoreFormCommandsSettings = EidosFilterFormCommandsComponent.DEFAULT_CONFIG;
  @Input() set config(val: ICoreFormCommandsSettings | undefined) {
    if (val) {
      this._config = val;
    }
  }
  get config(): ICoreFormCommandsSettings | undefined {
    return this._config;
  }

  /**
   * Clear event emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosFilterFormCommandsComponent
   */
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Submit event emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosFilterFormCommandsComponent
   */
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Toggle multiselect event emitter
   *
   * @type {EventEmitter<boolean>}
   * @memberof EidosFilterFormCommandsComponent
   */
  @Output() onEnableMultiselect: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Toggle multiselect event emitter
   *
   * @type {EventEmitter<void>}
   * @memberof EidosFilterFormCommandsComponent
   */
  @Output() onSubmitMultiselect: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Submit icon basing on mode
   *
   * @type {string}
   * @memberof EidosFilterFormCommandsComponent
   */
  private _submitIcon?: string;
  get submitIcon(): string {
    if (this._submitIcon != null) return this._submitIcon
    return this.config!.mode === CoreFormMode.Search ? 'search' : 'check';
  }
  @Input() set submitIcon(value: string | undefined) {
    this._submitIcon = value;
  }

  /**
   * Submit label basing on mode
   *
   * @readonly
   * @type {string}
   * @memberof EidosFilterFormCommandsComponent
   */
  public get submitLabel(): string {
    return this.config!.mode === CoreFormMode.Search ? (this.config!.searchLabel ?? '') : (this.config!.submitLabel ?? '');
  }
  /**
   * Submit label basing on mode
   *
   * @readonly
   * @type {string}
   * @memberof EidosFilterFormCommandsComponent
   */
  public get multiselectLabel(): string {
    return this.multiselectEnabled ? (this.config!.submitLabel ?? '') : (this.config!.multiselectLabel ?? '');
  }

  @ViewChild(DxButtonComponent, { static: false })
  public buttonSubmit?: DxButtonComponent;
  /**
   * Is multiselect enabled flag
   *
   * @type {boolean}
   * @memberof EidosFilterFormCommandsComponent
   */
  private _multiselectEnabled: boolean = false;
  public get multiselectEnabled(): boolean {
    return this._multiselectEnabled;
  }
  public set multiselectEnabled(value: boolean) {
    this._multiselectEnabled = value;
    this.onEnableMultiselect.emit(this.multiselectEnabled);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.config = assign({ ...EidosFilterFormCommandsComponent.DEFAULT_CONFIG }, this.config);
    if (!this.config.disabledMultiselect && !this.config.hideMultiselect) {
      this.multiselectEnabled = true;
    }
  }
  /**
   * Click on multiselect handler
   *
   * @memberof EidosFilterFormCommandsComponent
   */
  onMultiselectClick() {
    this.multiselectEnabled ? this.onSubmitMultiselect.emit() : this.multiselectEnabled = true;
  }
}
