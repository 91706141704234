import { Component, Input, OnInit } from '@angular/core';

import { EidosContainer, EidosFieldConfig } from '@eidos/models/eidos-field.model';
import { EidosObjectFieldType } from '@common/models/eidos-object.model';

@Component({
  selector: 'eidos-field-base',
  templateUrl: './eidos-field-base.component.html',
  styleUrls: ['./eidos-field-base.component.scss']
})
export class EidosFieldBaseComponent implements OnInit {

  EidosObjectFieldType = EidosObjectFieldType;
  /**
   * field configuration
   *
   * @type {(EidosFieldConfig)}
   * @memberof EidosFieldBaseComponent
   */
  @Input()
  public fieldConfig: EidosFieldConfig;
  /**
   * field data
   *
   * @type {(any)}
   * @memberof EidosFieldBaseComponent
   */
  @Input()
  public fieldData: any;
  /**
   * object container configuration
   *
   * @type {(EidosObjectGridConfig | undefined)}
   * @memberof EidosFieldBaseComponent
   */
  @Input()
  public fieldContainer: EidosContainer;

  constructor() { }

  ngOnInit(): void {
  }
}
