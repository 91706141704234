<div *ngIf="!loadingForGrantAccess; else loadingForGrantAccessTemplate" class="login-wrapper">
  <div class="login-wallpaper">
    <img *ngIf="backgroundImagePath" class="app-background" [src]="backgroundImagePath">
  </div>
  <div class="login-container">
    <div class="login-header p-t-35" *ngIf="!logoImagePath">
      <eidos-icon id="mainLogo" class="app-logo" [iconConfiguration]="logo"></eidos-icon>
      <!--<a *ngIf="!loggingIn" class="register-credential" (click)="registerCredentials()">{{ 'login.register_account' | transloco }}</a>-->
    </div>
    <div class="login-header p-t-35" *ngIf="logoImagePath">
      <img id="mainLogo" class="app-logo" src="{{logoImagePath}}">
      <!--<a *ngIf="!loggingIn" class="register-credential" (click)="registerCredentials()">{{ 'login.register_account' | transloco }}</a>-->
    </div>
    <div class="login-header">
      <label class="login-error" *ngIf="serverError">{{serverError}}</label>
    </div>
    <mat-card class="auth-block">
      <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="formAuthentication()" (capsLockActive)="toggleCapsLock($event)">
          <mat-form-field class="full-width-input">
            <input matInput placeholder="{{ 'login.username' | transloco }}" formControlName="username" required>
            <mat-error>
              {{ 'login.invalid_field' | transloco: { value: 'username' } }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="{{ 'login.password' | transloco }}" formControlName="password" required>
            <mat-error>
              {{ 'login.invalid_field' | transloco: { value: 'password' } }}
            </mat-error>
          </mat-form-field>
          <label class="caps-lock-message" *ngIf="capsLockActive">{{ 'login.caps_lock' | transloco }}</label>
          <button *ngIf="!loggingIn" mat-raised-button color="primary">{{ 'login.log_in' | transloco }}</button>
          <mat-spinner *ngIf="loggingIn === EidosAuthType.Form" diameter="20" style="margin: 0 auto;"></mat-spinner>
          <!--<a *ngIf="!loggingIn" class="password-reset-message" (click)="resetCredentials()">{{ 'login.lost_credentials' | transloco }}</a>-->
        </form>
      </mat-card-content>
    </mat-card>
    <div *ngIf="windowsAuthEnabled">
      <mat-card class="auth-block">
        <mat-card-content>
          <img class="login-image" src="assets/images/windows.png"><br />
          <button *ngIf="!loggingIn" mat-raised-button color="primary" (click)="windowsAuthentication()">{{ 'login.log_in' | transloco }}</button>
          <mat-spinner *ngIf="loggingIn === EidosAuthType.Windows" diameter="20" style="margin: 0 auto;"></mat-spinner>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="microsoftAuthEnabled">
      <mat-card class="auth-block">
        <mat-card-content>
          <img class="login-image" src="assets/images/microsoft-id.png"><br />
          <button *ngIf="!loggingIn" mat-raised-button color="primary" (click)="oAuth2Authentication(EidosAuthType.Microsoft)">{{ 'login.log_in' | transloco }}</button>
          <mat-spinner *ngIf="loggingIn === EidosAuthType.Microsoft" diameter="20" style="margin: 0 auto;">
          </mat-spinner>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="googleAuthEnabled">
      <mat-card class="auth-block">
        <mat-card-content>
          <img class="login-image" src="assets/images/google.png"><br />
          <button *ngIf="!loggingIn" mat-raised-button color="primary" (click)="oAuth2Authentication(EidosAuthType.Google)">{{ 'login.log_in' | transloco }}</button>
          <mat-spinner *ngIf="loggingIn === EidosAuthType.Microsoft" diameter="20" style="margin: 0 auto;">
          </mat-spinner>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="login-container-mobile">
    <div class="login-header">
      <eidos-icon id="mainLogo" class="app-logo-mobile" [iconConfiguration]="logo"></eidos-icon>
      <!--<a *ngIf="!loggingIn" class="register-credential" (click)="registerCredentials()">{{ 'login.register_account' | transloco }}</a>-->
    </div>
    <div class="login-header">
      <label class="login-error" *ngIf="serverError">{{serverError}}</label>
    </div>
    <mat-card class="auth-block">
      <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="formAuthentication()" (capsLockActive)="toggleCapsLock($event)">
          <mat-form-field class="full-width-input">
            <input matInput placeholder="{{ 'login.username' | transloco }}" formControlName="username" required>
            <mat-error>
              {{ 'login.invalid_field' | transloco: { value: 'username' } }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="{{ 'login.password' | transloco }}" formControlName="password" required>
            <mat-error>
              {{ 'login.invalid_field' | transloco: { value: 'password' } }}
            </mat-error>
          </mat-form-field>
          <label class="caps-lock-message" *ngIf="capsLockActive">{{ 'login.caps_lock' | transloco }}</label>
          <button *ngIf="!loggingIn" class="login-button" mat-raised-button color="primary">{{ 'login.log_in' | transloco }}</button>
          <mat-spinner *ngIf="loggingIn === EidosAuthType.Form" diameter="20" style="margin: 0 auto;"></mat-spinner>
          <!--<a *ngIf="!loggingIn" class="password-reset-message" (click)="resetCredentials()">{{ 'login.lost_credentials' | transloco }}</a>-->
        </form>
      </mat-card-content>
    </mat-card>
    <div *ngIf="windowsAuthEnabled">
      <mat-card class="auth-block">
        <mat-card-content>
          <img class="login-image" src="assets/images/windows.png"><br />
          <button *ngIf="!loggingIn" mat-raised-button color="primary" (click)="windowsAuthentication()">{{ 'login.log_in' | transloco }}</button>
          <mat-spinner *ngIf="loggingIn === EidosAuthType.Windows" diameter="20" style="margin: 0 auto;"></mat-spinner>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="microsoftAuthEnabled">
      <mat-card class="auth-block">
        <mat-card-content>
          <img class="login-image" src="assets/images/microsoft-id.png"><br />
          <button *ngIf="!loggingIn" mat-raised-button color="primary" (click)="oAuth2Authentication(EidosAuthType.Microsoft)">{{ 'login.log_in' | transloco }}</button>
          <mat-spinner *ngIf="loggingIn === EidosAuthType.Microsoft" diameter="20" style="margin: 0 auto;">
          </mat-spinner>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="googleAuthEnabled">
      <mat-card class="auth-block">
        <mat-card-content>
          <img class="login-image" src="assets/images/google.png"><br />
          <button *ngIf="!loggingIn" mat-raised-button color="primary" (click)="oAuth2Authentication(EidosAuthType.Google)">{{ 'login.log_in' | transloco }}</button>
          <mat-spinner *ngIf="loggingIn === EidosAuthType.Microsoft" diameter="20" style="margin: 0 auto;">
          </mat-spinner>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loadingForGrantAccessTemplate>
  <div class="redirection-waiting">
    <h1>Waiting for redirection to home page....</h1>
    <div class="my-3">
      <eidos-progress-spinner [diameter]="50"></eidos-progress-spinner>
    </div>
  </div>
</ng-template>
