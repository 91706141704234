<content *ngIf="eidosChartConfiguration"
  [ngSwitch]="eidosChartConfiguration.type">
  <ng-template [ngSwitchCase]="EidosChartType.Gauge">
    <eidos-gs-gauge
      [Config]="eidosChartScalarConfiguration"
      [Data]="eidosChartScalarData"
    >
    </eidos-gs-gauge>
    <content *ngIf="eidosChartConfiguration.title">
      <div [innerHtml]="eidosChartConfiguration.titleResolved"></div>
    </content>
  </ng-template>
  <ng-template [ngSwitchCase]="EidosChartType.Level">
    <eidos-level-chart
      [Config]="eidosChartScalarConfiguration"
      [Data]="eidosChartScalarData"
    >
    </eidos-level-chart>
    <content *ngIf="eidosChartConfiguration.title">
      <div [innerHtml]="eidosChartConfiguration.titleResolved"></div>
    </content>
  </ng-template>
  <ng-template [ngSwitchCase]="EidosChartType.Chartjs">
    <eidos-chart-js
      [Config]="eidosChartJSConfiguration"
      [Data]="eidosChartDimensionData"
    >
    </eidos-chart-js>
  </ng-template>
  <ng-template [ngSwitchDefault]>
    <p>unknown chart</p>
  </ng-template>
</content >
