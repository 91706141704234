import { Pipe, PipeTransform } from '@angular/core';

/**
 * Cast super type into type using generics
 * Return Type obtained by optional @param type OR assignment type.
 *
 * @export
 * @class CoreTemplateCastPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'cast_iterable' })
export class CoreTemplateCastIterablePipe implements PipeTransform {
    /**
     * Cast (S: SuperType) into (T[]: Array<Type>)
     * 
     * 
     * !! Warning!: T[] isn't a subtype of S, because it implements also Array type.
     * !! The only way to do the cast S->T is to previously cast value to any.
     * !! Please use the pipe CAREFULLY!
     *
     * @template S
     * @template T
     * @param {S} value
     * @param {T} type
     * @return {*}  {(T | T[])}
     * @memberof CoreTemplateCastPipe
     */
    // @ts-ignore
    transform<S, T extends S>(value: S, type: T): T[] {
        return <T[]>(<any>value);
    }
}