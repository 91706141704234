import { EidosBaseList } from "./eidos-base-list.model";

export class EidosCompany extends EidosBaseList {

   constructor(item?: any) {
    super();

    if (item) {
      this.code = item.cod;
      this.description = item.nome;
    }
  }
}
