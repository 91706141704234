import { SafeBaseObject } from "@app/core/models/base-object.models";
import { DateTime } from "luxon";

export class ResManifest extends SafeBaseObject {
  iD: number = 0;
  [key: string]: any;

  constructor(data?: IResApiManifest) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IResApiManifest): void {
    this.addMangledProperty(data);

    if (this['pre Remark'] != undefined) this.formattedPreRemark = this.formatFullManifestRemark(this['pre Remark']);
    if (this['post Remark'] != undefined) this.formattedPostRemark = this.formatFullManifestRemark(this['post Remark']);
  }

  formatFullManifestRemark(remark: string): string {
    if (!remark) return '';
    return '<ul>' +
      remark.split('remark-separator').filter((r: string) => r.includes(': ')).map((r: string) => {
        const [option, remark] = r.split(': ');
        return `<li class="mb-3"><h6>${option}:</h6> <br>${remark}</li>`;
      }).join('')
      + '</ul>';
  }
}

export interface IResApiManifest {
  ID: number;
}

export class ResSavedManifestHeader extends SafeBaseObject {
  iD: number = 0;
  [key: string]: any;

  constructor(data?: IResApiSavedManifestHeader) {
    super();
    if (data) this.updateData(data);
  }
  override updateData(data: IResApiSavedManifestHeader): void {
    this.addMangledProperty(data);
  }
}

export interface IResApiSavedManifestHeader {
  ID: number;
}

export interface IResApiManifestVoyage {
  VoyageID: number;
  VoyageNumber: string;
}

export interface IResApiGetManifestParams {
  VoyageID?: number;
  ManifestType: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  BkgStatus?: string;
  PrePost?: string;
}

export interface IResApiGetSavedManifestsParams extends IResApiGetManifestParams {
  FileName?: string;
  CreatedBy?: string;
  Note?: string;
}

export interface IResApiOpenSavedManifestParams {
  ManifestSavedHdrID: number;
  BkgStatus?: string;
  PrePost?: string;
}

export interface IResApiUpdateManifestHeaderParams {
  ManifestSavedHdrID: number;
  fileName: string;
  note: string;
}

export interface IResApiSaveManifestParams {
  VoyageID: number;
  ManifestType: string;
  FileName: string;
  Note?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
}

export interface IResApiUpdateManifestParams {
  ManifestSavedHdrID: number;
}

export interface IResApiDeleteManifestParams {
  ManifestSavedHdrID: number;
}

export interface IResApiCompareManifestParams {
  ManifestSavedHdrID: number;
  CompareType: 'DELTA' | 'FULL';
  BkgStatus?: string;
  PrePost?: string;
}

export interface IResApiCompareTwoManifestsParams {
  ManifestType: string;
  ManifestOneHdrID: number;
  ManifestTwoHdrID: number;
  CompareType: 'DELTA' | 'FULL';
  BkgStatus?: string;
  PrePost?: string;
}

export enum ResSavedManifestSearchMode {
  Delta = 'DELTA',
  Full = 'FULL'
}

export interface IResApiGetFullManifestParams {
  VoyageID?: number;
  DateFrom?: DateTime;
  DateTo?: DateTime;
  BkgStatus?: string;
  PrePost?: string;
}

export interface IResApiGetSavedFullManifestsParams extends IResApiGetFullManifestParams {
  FileName?: string;
  CreatedBy?: string;
  Note?: string;
}

export interface IResApiOpenSavedFullManifestParams {
  FullManifestSavedHdrID: number;
  BkgStatus?: string;
  PrePost?: string;
}

export interface IResApiUpdateFullManifestHeaderParams {
  FullManifestSavedHdrID: number;
  fileName: string;
  note: string;
}

export interface IResApiSaveFullManifestParams {
  VoyageID: number;
  FileName: string;
  Note?: string;
  DateFrom?: DateTime;
  DateTo?: DateTime;
}

export interface IResApiUpdateFullManifestParams {
  FullManifestSavedHdrID: number;
}

export interface IResApiDeleteFullManifestParams {
  FullManifestSavedHdrID: number;
}