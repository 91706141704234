import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";
import { CoreFormatService } from "../services/core-format.service";

@Pipe({ name: 'formatCurrency' })
export class CoreFormatCurrencyPipe implements PipeTransform {
  constructor(
    private coreFormatService: CoreFormatService
  ) { }

  transform(amount: number, currency?: string, format?: string, inverted: boolean = false) {
    return this.coreFormatService.CurrencyAmount(amount, currency, format, inverted);
  }
}
@Pipe({ name: 'formatDateTime' })
export class CoreFormatDateTimePipe implements PipeTransform {
  constructor(
    private coreFormatService: CoreFormatService
  ) { }

  transform(datetime: DateTime, format?: string) {
    if(!datetime.isValid) return '';
    if(format) return datetime.toFormat(format);
    return datetime.toFormat(this.coreFormatService.DateAndTimeFmt());
  }
}
@Pipe({ name: 'formatDate' })
export class CoreFormatDatePipe implements PipeTransform {
  constructor(
    private coreFormatService: CoreFormatService
  ) { }

  transform(date: Date, format?: string) {
    const datetime = DateTime.fromJSDate(date);
    if(!datetime.isValid) return '';
    if(format) return datetime.toFormat(format);
    return datetime.toFormat(this.coreFormatService.DateFmtBase());
  }
}
