<content *ngIf="config" [ngSwitch]="config.type">
  <!-- Switch cases must be verbose until multiple cases will be released https://github.com/angular/angular/issues/14659 -->

  <ng-template ngSwitchCase="text">
    <!-- Free Text -->
    <eidos-filter-text  [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value" (onKeyDown)="keyDown($event)"></eidos-filter-text>
  </ng-template>

  <ng-template ngSwitchCase="daterange">
    <eidos-filter-daterange [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value" [(otherValue)]="otherValue"></eidos-filter-daterange>
  </ng-template>

  <ng-template ngSwitchCase="sliderrange">
    <!-- 2 numbers range slider -->
    <eidos-filter-sliderrange [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value" [(otherValue)]="otherValue"></eidos-filter-sliderrange>
  </ng-template>

  <ng-template ngSwitchCase="slider">
    <!-- numeric slider -->
    <eidos-filter-slider [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value"></eidos-filter-slider>
  </ng-template>

  <ng-template ngSwitchCase="checkbox">
    <eidos-filter-checkbox [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value"></eidos-filter-checkbox>
  </ng-template>

  <ng-template ngSwitchCase="switch">
    <!-- Boolean switch -->
    <eidos-filter-switch [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value"></eidos-filter-switch>
  </ng-template>

  <ng-template ngSwitchCase="selectize">
    <eidos-filter-selectize [config]="config" [searchService]="searchService" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value"></eidos-filter-selectize>
  </ng-template>

  <ng-template ngSwitchCase="number">
    <eidos-filter-number [config]="config" [cssClass]="cssClass" [cssId]="cssId"  (onKeyDown)="keyDown($event)" [(value)]="value"></eidos-filter-number>
  </ng-template>

  <ng-template ngSwitchCase="numberpopup">
    <!-- N numerics input grouped in a generic box -->
    <eidos-filter-number-popup [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value"></eidos-filter-number-popup>
  </ng-template>

  <ng-template ngSwitchCase="datetime">
    <eidos-filter-datetime [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value"></eidos-filter-datetime>
  </ng-template>

  <ng-template ngSwitchCase="popuplistselector">
    <eidos-filter-popup-list-selector [config]="config" [cssClass]="cssClass" [cssId]="cssId" [(value)]="value"></eidos-filter-popup-list-selector>
  </ng-template>

  <ng-template ngSwitchCase="unknown">
    Unknown filter configuration
  </ng-template>

  <ng-template ngSwitchDefault></ng-template>
</content>

<!-- DO NOT DELETE: it is used to project custom filters from other modules -->
<ng-content></ng-content>
