<content *ngIf="visible">
  <button class="res-button" [ngClass]="cssClasses" (click)="!isDisabled && btnClick.emit($event)" [disabled]="isDisabled ? 'disabled' : ''">
    <div style="display: flex; flex-direction: row; align-items: center;">
      <eidos-icon *ngIf="iconConfiguration && iconPosition === 'left'"
        [iconConfiguration]="iconConfiguration"
        [ngClass]="{'res-button-icon-left': text}">
      </eidos-icon>
      <div>{{ text }}</div>
      <eidos-icon *ngIf="iconConfiguration && iconPosition === 'right'"
        [iconConfiguration]="iconConfiguration"
        [ngClass]="{'res-button-icon-right': text}">
      </eidos-icon>
    </div>
    <div *ngIf="badgeConfiguration"
      class="res-button-badge"
      (mouseenter)="onMouseEnter($event)"
      (mouseleave)="onMouseLeave()">
      <ng-container *ngTemplateOutlet="badgeConfiguration['text'] ? text : icon">
      </ng-container>

      <ng-template #text>
        {{ badgeConfiguration.text }}
      </ng-template>

      <ng-template #icon>
        <eidos-icon *ngIf="badgeConfiguration?.icon"
          [iconConfiguration]="badgeConfiguration.icon">
        </eidos-icon>
        <dx-tooltip *ngIf="badgeConfiguration?.tooltipText" [target]="tooltipTarget" [(visible)]="tooltipDefaultVisible"
          [position]="badgeConfiguration.tooltipPosition ?? 'top'" contentTemplate="content">
          <div *dxTemplate="let data = data of 'content'">{{ badgeConfiguration.tooltipText }}</div>
        </dx-tooltip>
      </ng-template>

    </div>
  </button>
</content>
