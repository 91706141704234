import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, Observable, of, throwError } from 'rxjs';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosEnvironment } from '@common/config/environment.interface';
import { LocalStorageKeys } from '@common/models/core-constant.model';
import { DynamicApiResponse, IDynamicApiResponse, instanceOfIDynamicApiResponse } from '@common/models/dynamic-api-response.model';
import { IMyBizApiResponse, MyBizApiResponse } from '@common/models/mybiz.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { CoreFormatService } from './core-format.service';
import _ from 'lodash';
import { CoreBaseApiCommandParams, CoreHttpMethod, ICoreBaseApiFileRequestCommandParams } from '../models/core-base-api.model';
import { DateTime } from 'luxon';

interface HttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class EidosBaseApiService {
  callMillisec = 0

  /**
   * Current configuration
   *
   * @private
   * @type {(IEidosEnvironment | undefined)}
   * @memberof EidosApiService
   */
  protected config: IEidosEnvironment | undefined;
  /**
   * App base URL
   *
   * @protected
   * @type {string}
   * @memberof EidosBaseApiService
   */
  protected baseUrl: string;
  /**
   * API URL
   *
   * @protected
   * @type {string}
   * @memberof EidosBaseApiService
   */
  public urlAPI: string;
  /**
   * Static API URL (optional)
   *
   * @protected
   * @type {string}
   * @memberof EidosBaseApiService
   */
  protected staticUrlAPI?: string;
  /**
   * Plugin API URL (optional)
   *
   * @protected
   * @type {string}
   * @memberof EidosBaseApiService
   */
  protected pluginUrlAPI?: string;
  /**
   * API version
   *
   * @private
   * @type {string}
   * @memberof EidosBaseApiService
   */
  protected apiVersion: string = "v1";
  /**
   * AGENCY CRYSTAL CRUISES URL (optional)
   *
   * @private
   * @type {string}
   * @memberof EidosBaseApiService
   */
  protected urlAgencyCC?: string;
  /**
   * Headers for API calls
   *
   * @protected
   * @type {*}
   * @memberof EidosBaseApiService
   */
  protected headers: any = {};

  constructor(
    public coreFormatService: CoreFormatService,
    public configService: EidosConfigService,
    public eidosLogService: EidosLogService,
    public dialog: MatDialog,
    public eidosSecurityService: EidosSecurityService,
    public http: HttpClient
  ) {

    //TODO for no login version implement not expire token on configuration file
    //this.headers = {authToken:config.getConfig().authToken};
    this.baseUrl = this.configService.DEFAULT_CONFIG.baseUrl;
    this.urlAPI = this.configService.DEFAULT_CONFIG.urlAPI;
    this.configService.currentConfig.subscribe(config => {
      this.config = config;
    });
  }

  /**
   * Send HTTP requests to APIs
   *
   * @protected
   * @param {CoreHttpMethod} type Method
   * @param {string} url Endpoint
   * @param {*} [data] Data
   * @return {*}  {Observable<any>}
   * @memberof EidosBaseApiService
   */
  protected sendFileRequest(params: ICoreBaseApiFileRequestCommandParams): Observable<any> {
    if (this.config && params.url) {

      var headers = this.getHeaders(null);
      if (params.data instanceof ArrayBuffer) {
        headers.delete('Content-Type');
      }

      if (this.config.tokenOnHeader) {
        var token = localStorage.getItem(LocalStorageKeys.TOKEN);
        if (token) headers = headers.set(LocalStorageKeys.TOKEN, token);
      }

      let apiUrl = `${this.config.urlAPI}/${params.url}`;

      this.eventuallyConvertData(params.data);

      if (params.data instanceof FormData) {
        return this.http.post<Response>(apiUrl, params.data, {headers: headers});
      }

      return this.http.request<Response>(params.type.toUpperCase(), apiUrl, {
        body: params.data,
        headers: headers,
        reportProgress: !!params.reportProgress,
        observe: 'events'
      });
    } else {
      return throwError(() => 'Current config has not been retrieved yet');
    }
  };
  public camelCaseObject(obj: any): any {
    let nobj:any = {};
    Object.keys(obj).forEach((key) => {
      nobj[this.camelCase(key)] = obj[key];
    })
    return nobj
  }
  public pascalCaseObject(obj: any): any {
    let nobj:any = {};
    Object.keys(obj).forEach((key) => {
      nobj[this.pascalCase(key)] = obj[key];
    })
    return nobj
  }
  private pascalCase(name: string): string {
    if (name.length === 0) return name;
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  private camelCase(name: string): string {
    if (name.length === 0) return name;
    return name.charAt(0).toLowerCase() + name.slice(1);
  }
  /**
   * Send HTTP requests to APIs
   *
   * @protected
   * @param {CoreBaseApiCommandParams} params
   * @return {*}  {Observable<any>}
   * @memberof EidosBaseApiService
   */
  protected sendCommand(params: CoreBaseApiCommandParams): Observable<any> {

    if (this.config && !_.isEmpty(params.url)) {

      var headers = this.getHeaders(params.customHeaders);
      if (params.data instanceof ArrayBuffer) {
        headers.delete('Content-Type');
      }

      if (this.config.tokenOnHeader) {
        var token = localStorage.getItem(LocalStorageKeys.TOKEN);
        if (token) headers = headers.set(LocalStorageKeys.TOKEN, token);
      }

      let options: HttpOptions = {
        headers: headers
      };

      if (params.withCredentials) {
        options.withCredentials = true;
      }

      let apiUrl = params.useVersion ? `${this.urlAPI}/${this.apiVersion}/${params.url}` : `${this.urlAPI}/${params.url}`;

      if (!_.isEmpty(params.customBaseUrl)) {
        apiUrl = `${params.customBaseUrl}/${params.url}`;
      }

      this.eventuallyConvertData(params.data);

      let dataAsQueryString = "";

      switch (params.type.toUpperCase()) {
        case 'GET':
          if (params.hasData) {
            dataAsQueryString = "?" + Object.keys(params.data).filter(key => params.data[key] != undefined).map(key => `${key}=${encodeURIComponent(params.data[key])}`).join("&");
          }
          return this.http.get<Response>(apiUrl + dataAsQueryString, options);

        case "DELETE":
          if (params.hasData) {
            dataAsQueryString = "?" + Object.keys(params.data).filter(key => params.data[key] != undefined).map(key => `${key}=${encodeURIComponent(params.data[key])}`).join("&");
          }
          return this.http.delete<Response>(apiUrl + dataAsQueryString, options);

        case 'POST':
          return this.http.post<Response>(apiUrl, params.data, options);

        case 'PUT':
          return this.http.put<Response>(apiUrl, params.data, options);

        default:
          return throwError(() => 'Method not implemented');
      };
    } else {
      return throwError(() => 'Current config has not been retrieved yet');
    }
  };

  /**
   * Convert data to send to server from FE model to API model
   * WARNING: this method intercepts ALL the calls to API. Use carefully.
   * RF 28.11.2022: promoted to protected because, I don't know why,
   * some services directly call the APIs without using EidosBaseApiService.sendCommand()
   *
   * @protected
   * @param {*} data
   * @memberof EidosBaseApiService
   */
  protected eventuallyConvertData(data: any) {

    data = this.coreFormatService.deeplyConvertDateTimeOfObject(data);

    return data;
  }

  protected getEndPoint(type: string, controller: string, action: string) {
    return `${this.urlAPI}/${type}/${this.apiVersion}/${controller}/${action}`;
  }

  protected getHeaders(headers: any) {
    return new HttpHeaders({ ...headers, ...this.headers });
  }

  protected getErrorMessage(dynamicResponse: DynamicApiResponse) {
    return dynamicResponse.errors.map(err => {
      let strErr = '';
      if (err.ErrorCod) {
        strErr += '<span class="bold">' + err.ErrorCod + ':</span> ';
      }
      if (err.ErrorCod) {
        strErr += err.ErrorDesc;
      }
      return strErr;
    }).join('<br/>');
  }
  /**
   * Handles generic API error
   *
   * @protected
   * @param {(HttpErrorResponse | any)} error
   * @return {*}  {Observable<any>}
   * @memberof EidosBaseApiService
   */
  protected handleGenericError(error: HttpErrorResponse | IDynamicApiResponse | any): Observable<any> {

    if (error instanceof HttpErrorResponse) {

      // if (error.status !== 200 && error.status !== 401) {
      //   this.displayError(error.message);
      // }

      // If 401 error must clear authentication
      if (error.status === 401) {
        this.eidosSecurityService.clearAuthentication();
      }

    } else if (typeof error === 'string' || error instanceof String) {

      if (error.toLowerCase().startsWith('unauthorized')) {
        // Auth error: clear the authentication
        this.eidosSecurityService.clearAuthentication();
      }

    } else if (instanceOfIDynamicApiResponse(error)) {

      /**
       * if error is instance of IDynamicApiResponse
       * then call specific dynamic error handler
       */
      return this.handleDynamicError(error);
    }

    return throwError(() => error.error ?? error);
  }
  /**
   * Handles Dynamic API (Wonderland API) error
   *
   * @protected
   * @param {IDynamicApiResponse} errorDynResponse
   * @return {*}  {Observable<IDynamicApiResponse>}
   * @memberof EidosBaseApiService
   */
  protected handleDynamicError(errorDynResponse: IDynamicApiResponse): Observable<IDynamicApiResponse> {
    // this.dialog.open(EidosErrorDialogComponent, {
    //   data: {
    //     id: "",
    //     error: {
    //       name: errorDynResponse.statusCod,
    //       message: errorDynResponse.statusMessage,
    //       stack: this.getErrorMessage(errorDynResponse)
    //     }
    //   }
    // });
    return throwError(() => errorDynResponse);
  }

  // private displayError(errorMessage: string): void {
  //   this.dialog.open(EidosAlertDialogComponent, {
  //     data: {
  //       info: errorMessage
  //     }
  //   });
  //   this.eidosLogService.logDebug(EidosLogSeverity.Error, errorMessage);
  // }

  // private handleMyBizError(errorResponse: MyBizApiResponse, skipModalMessage = false): Observable<MyBizApiResponse> {
  //   if (errorResponse.error !== null) {
  //     // this.displayError(errorResponse.error);
  //   }
  //   return throwError(() => errorResponse);
  // }

  /**
   * Calls a dynamic API (Wonderland), handle the response obj and
   * returns the HTTP observable in Wonderland API structure
   *
   * @param {string} api
   * @param {*} [data]
   * @param {CoreHttpMethod} [method="GET"]
   * @return {*}
   * @memberof EidosBaseApiService
   */
  public callDynamicAPI(api: string, data?: any, method: CoreHttpMethod = "GET") {

    this.callMillisec = DateTime.now().toMillis()
    return this.sendCommand(new CoreBaseApiCommandParams({
      url: api,
      data: data,
      type: method,
      useVersion: true
    })).pipe(
      /**
       * If Wonderland API request goes ok, it returns:
       * IDynamicApiResponse in case of successful request
       * IMyBizApiResponse in case of unauthorized request (the HttpStatusCode will be 200!)
       *
       */
      map<IMyBizApiResponse | IDynamicApiResponse, DynamicApiResponse>((response) => {
        // If response is instance of IDynamicApiResponse, then return DynamicApiResponse model constructed from it
        if (instanceOfIDynamicApiResponse(response)) {
          return new DynamicApiResponse(response);
        } else {
          /**
           * else there is probably an auth error in IMyBizApiResponse form
           * then build a MyBizApiResponse and throw the related error
           */
          throw new MyBizApiResponse(response).error;
        }
      }),
      /**
       * If Wonderland API request goes wrong, it returns:
       * 400 - BadRequest in case of SqlException
       * 500 - InternalServerError otherwise
       * HttpErrorResponse is provided in that cases
       *
       * If Wonderland API do not return any HTTP errors
       * then the error is logic and it will be throwed as IDynamicApiResponse
       * by the DynamicApiResponse costructor
       *
       * We also include any as possible error type...you never know
       */
      catchError((error: HttpErrorResponse | IDynamicApiResponse | any) => this.handleGenericError(error))
    );
  }
  /**
   * Calls a dynamic API (Wonderland) in GET verb
   * Returns the HTTP observable in Wonderland API structure
   *
   * @param {string} api
   * @param {*} [data]
   * @return {*}
   * @memberof EidosBaseApiService
   */
  public getDynamicAPI(api: string, data?: any) {
    return this.callDynamicAPI(api, data, "GET");
  }
  /**
   * Calls a dynamic API (Wonderland) in POST verb
   * Returns the HTTP observable in Wonderland API structure
   *
   * @param {string} api
   * @param {*} data
   * @return {*}
   * @memberof EidosBaseApiService
   */
  public postDynamicAPI(api: string, data: any) {
    return this.callDynamicAPI(api, data, "POST");
  }
  /**
   * Calls a dynamic API (Wonderland) in PUT verb
   * Returns the HTTP observable in Wonderland API structure
   *
   * @param {string} api
   * @param {*} data
   * @return {*}
   * @memberof EidosBaseApiService
   */
  public putDynamicAPI(api: string, data: any) {
    return this.callDynamicAPI(api, data, "PUT");
  }
  /**
   * Calls a static API
   * The method returns the HTTP observable only if the staticAPIUrl is defined for the module
   *
   * @param {string} api
   * @param {(CoreHttpMethod)} method
   * @param {*} [data]
   * @return {*}
   * @memberof EidosBaseApiService
   */
  public getStaticAPI(api: string, method: CoreHttpMethod, data?: any) {
    if (!!this.staticUrlAPI) {

      const commandParams = new CoreBaseApiCommandParams({
        url: api,
        data: data,
        type: method,
        customBaseUrl: this.staticUrlAPI,
        useVersion: true
      });

      return this.sendCommand(commandParams)
        .pipe(
          catchError((error: HttpErrorResponse) => this.handleGenericError(error))
        );
    } else {
      return of(undefined);
    }
  }
  /**
   * Calls general API without authentication
   * The method returns the HTTP observable only if the generalUrlAPI is defined for the module
   *
   * @param {string} api
   * @param {('GET' | 'DELETE' | 'POST' | 'PUT')} method
   * @param {*} [data]
   * @return {*}
   * @memberof EidosBaseApiService
   */
  public getPublicAPI(api: string, method: 'GET' | 'DELETE' | 'POST' | 'PUT', data?: any) {

    const commandParams = new CoreBaseApiCommandParams({
      url: "general/" + api,
      data: data,
      type: method,
    });

    return this.sendCommand(commandParams)
      .pipe(catchError((error: HttpErrorResponse) => this.handleGenericError(error)));
  }
  /**
   * Calls external API
   * The method returns the HTTP observable only if the generalUrlAPI is defined for the module
   *
   * @param {string} api
   * @param {('GET' | 'DELETE' | 'POST' | 'PUT')} method
   * @param {*} [data]
   * @return {*}
   * @memberof EidosBaseApiService
   */
   public getExternalAPI(api: string, method: 'GET' | 'DELETE' | 'POST' | 'PUT', data?: any) {

    const commandParams = new CoreBaseApiCommandParams({
      customBaseUrl: this.config?.urlAPI,
      url: api,
      data: data,
      type: method,
    });

    return this.sendCommand(commandParams)
      .pipe(catchError((error: HttpErrorResponse) => this.handleGenericError(error)));
  }
}
