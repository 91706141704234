import { Component, Input, OnInit } from '@angular/core';

import { IUserAvatar } from '@common/models/user-info.interface';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosDefaultProfileBadge, IEidosIcon } from '@common/config/environment.interface';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosIconType, EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { SpinnerOverlayService } from '@app/core/services/spinner-overlay.service';

@Component({
  selector: 'eidos-user-avatar',
  templateUrl: './eidos-user-avatar.component.html',
  styleUrls: ['./eidos-user-avatar.component.scss']
})
export class EidosUserAvatarComponent implements OnInit {

  @Input()
  public userAvatar: IUserAvatar | undefined;

  public defaultProfileBadge: IEidosDefaultProfileBadge = {
    defaultBadgeColorPrimary: "",
    defaultBadgeColorSecondary: ""
  };
  /**
   * Eidos debug mode flag
   *
   * @type {boolean}
   * @memberof EidosUserAvatarComponent
   */
  public inDebugMode: boolean = false;
  /**
   * Debug icon configuration
   * 
   * @type {IEidosIcon}
   * @memberof EidosUserAvatarComponent
   */
  public debugIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "adb",
    iconSize: 1
  }
  /**
   * End Impersonate icon configuration
   * 
   * @type {IEidosIcon}
   * @memberof EidosUserAvatarComponent
   */
  public userImpersonationIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "account_circle",
    iconSize: 1
  }

  constructor(
    private eidosConfigService: EidosConfigService
    , private utilityService: EidosUtilityService
    , private eidosLogService: EidosLogService
    , private spinnerOverlayService: SpinnerOverlayService
  ) {
  }

  ngOnInit(): void {
    this.eidosConfigService.currentConfig.subscribe(config => this.defaultProfileBadge = config.defaultProfileBadge);
    this.utilityService.inDebugMode.subscribe(debug => this.inDebugMode = debug);
  }

  public getBadgePrimaryColor(): string {
    if (this.userAvatar?.badgeColorPrimary) {
      return this.userAvatar.badgeColorPrimary;
    } else {
      return this.defaultProfileBadge.defaultBadgeColorPrimary;
    }
  }

  public getBadgeSecondaryColor(): string {
    if (this.userAvatar?.badgeColorSecondary) {
      return this.userAvatar.badgeColorSecondary;
    } else {
      return this.defaultProfileBadge.defaultBadgeColorSecondary;
    }
  }

  /**
   * Return user profile picture URL
   *
   * @return {*}  {string}
   * @memberof EidosUserAvatarComponent
   */
  public getProfileImage(): string {
    return `url('${this.userAvatar!.profilePicture}')`;
  }

  /**
   * End user impersionation, handler of impersonate icon
   *
   * @memberof EidosUserAvatarComponent
   */
  public endImpersonation(): void {
    this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Impersonate', 'Trying to end impersonate user.');
    this.spinnerOverlayService.show();
    this.utilityService.impersonateEnd().subscribe((result) => {
      if (result) {
        this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Impersonate', 'Successfully end impersonate');
      }
      else {
        this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Impersonate', 'Failed to end impersonate');
      }
      this.spinnerOverlayService.hide();
    });
  }

  /**
   * End the debug mode, handler of debug icon
   *
   * @memberof EidosUserAvatarComponent
   */
  public endDebugMode(): void {
    this.utilityService.setDebugMode(false);
  }
}
