import { Component, OnInit } from '@angular/core';
import {
  CoreRegisterAccountConfig,
  CoreRegisterAccountFormField,
  CoreRegisterAccountFormSection,
  CoreRegisterAccountFormTab,
} from '@app/core/models/core-register-account-config.model';
import { EidosApiService } from '@app/core/services/eidos-api.service';
import { IEidosLightFormField } from '../eidos-light-form/eidos-light-form.component';

@Component({
  selector: 'app-core-register-account',
  templateUrl: './core-register-account.component.html',
  styleUrls: ['./core-register-account.component.scss'],
})
export class CoreRegisterAccountComponent implements OnInit {
  constructor(private api: EidosApiService) {}

  public data: CoreRegisterAccountConfig = new CoreRegisterAccountConfig();

  public formFields: any;

  public formValues: any = {};

  public tabs: CoreRegisterAccountFormTab[] = [];

  public selectedTabIndex: number = 0;

  ngOnInit(): void {
    this.api.getRegisterAccountConfig().subscribe({
      next: (cfg) => {
        this.data = cfg;
        this.tabs = cfg.formStruct.tabs;
        this.formFields = this.mapFormFields(cfg);
      },
    });
  }

  public useTabs(): boolean {
    return this.data.isValid() && !!this.tabs.length;
  }
  public handleFormSubmit(): void {
    this.api.submitRegisterAccount(this.formValues).subscribe({
      next: (success) => {
        console.log(success);
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  public mapFormFields(cfg: CoreRegisterAccountConfig): IEidosLightFormField[] {
    if (!cfg.fields?.length) return [];
    let structFields: CoreRegisterAccountFormField[] = this.getStructFields(cfg);

    let fields: IEidosLightFormField[] = [];
    for (let f of cfg.fields) {
      let mappedField: any = Object.assign({}, f);

      let structField: any = structFields.find((ff) => ff.name === f.name);
      if (!!structField) {
        mappedField.section = structField.section;
        mappedField.sectionPos = structField.sectionPos;
        mappedField.openedSection = structField.openedSection;
        mappedField.tab = structField.tab;
        mappedField.width = 'col-' + (structField.width || '3');
      }

      if (mappedField.type == 'lookup') {
        mappedField.type = 'select';
        mappedField.options = mappedField.lookupValues
        delete mappedField.lookupValues;
      }
      if (mappedField.name) {
        mappedField.field = mappedField.name;
        delete mappedField.name;
      }
      if (mappedField.insertReadOnly != null) {
        mappedField.readOnly = mappedField.insertReadOnly;
        delete mappedField.insertReadOnly;
      }
      fields.push(mappedField);
    }
    let tabsFields: any = {};
    for (let t of this.tabs) {
      tabsFields[t.id ?? 0] = fields.filter((f: any) => f.tab?.id === t.id);
    }

    return tabsFields;
  }

  public getStructFields(cfg: CoreRegisterAccountConfig): CoreRegisterAccountFormField[] {
    let fields: CoreRegisterAccountFormField[] = [];
    for (let tab of cfg.formStruct.tabs) {
      for (let s of tab.sections) {
        fields.push(...this.recursiveSearchFieldsInFieldSets(s.fieldSets, s, tab));
      }
    }
    return fields;
  }

  public recursiveSearchFieldsInFieldSets(
    fieldSets: any,
    section: CoreRegisterAccountFormSection,
    tab: CoreRegisterAccountFormTab
  ): CoreRegisterAccountFormField[] {
    let fields: CoreRegisterAccountFormField[] = [];
    for (let fieldSet of fieldSets) {
      if (!!fieldSet.columns.length && fieldSet.columns[0].name == null) {
        fields.push(...this.recursiveSearchFieldsInFieldSets(fieldSet.columns, section, tab));
      } else if (!!fieldSet.columns.length) {
        for (let f of fieldSet.columns) {
          f.width = fieldSet.width;
          f.section = section.caption;
          f.sectionPos = section.position;
          f.openedSection = true
          f.tab = tab;
          fields.push(f);
        }
      }
    }
    return fields;
  }

 
}
