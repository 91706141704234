import { Directive, EventEmitter, HostListener, Output } from "@angular/core";
/**
 * Directive to track the caps lock status
 *
 * @export
 * @class EidosCapsLockDirective
 */
@Directive({ selector: '[capsLockActive]' })
export class EidosCapsLockDirective {
    /**
     * Emitter to notify the caps lock status
     *
     * @memberof EidosCapsLockDirective
     */
    @Output('capsLockActive')
    public capsLockEmitter = new EventEmitter<boolean>();
    /**
     * Attach emit to key down event
     *
     * @param {KeyboardEvent} event
     * @memberof EidosCapsLockDirective
     */
    @HostListener('window:keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        this.capsLockEmitter.emit(event.getModifierState && event.getModifierState('CapsLock'));
    }
    /**
     * Attach emit to key up event
     *
     * @param {KeyboardEvent} event
     * @memberof EidosCapsLockDirective
     */
    @HostListener('window:keyup', ['$event'])
    public onKeyUp(event: KeyboardEvent): void {
        this.capsLockEmitter.emit(event.getModifierState && event.getModifierState('CapsLock'));
    }
}