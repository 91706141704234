<dx-text-box #inputNumberElement [ngClass]="cssClass" id="{{cssId}}" [value]="getFormat()"
  (onFocusIn)="isPopupTooltipVisible = true; (onFocusIn && onFocusIn())" (onFocusOut)="onFocusOutText()"
  placeholder="{{ (placeholder | transloco) ?? placeholder }}" [readOnly]="true"
  >
  <dxi-button name="guests" location="after" [options]="prevDateButton"></dxi-button>
</dx-text-box>

<dx-tooltip [target]="'#' + cssId " position="bottom" [(visible)]="isPopupTooltipVisible">
  <div *dxTemplate="let data = data; of: 'content'">

    <div class="dx-field" *ngFor="let element of elements">
      <div class="dx-field-label">{{element.label}}</div>
      <div class="dx-field-value">
        <dx-number-box
          [(value)]="value[element.property]"
          [min]="element.min ?? 0"
          [max]="element.max ?? 9999999"
          (onFocusIn)="focusOnPopUp = true"
          (onFocusOut)="focusOnPopUp = false"
          [showSpinButtons]="true"
          [showClearButton]="true"
          (valueChange)="isInputValueChanged($event, element)"
        ></dx-number-box>
      </div>
    </div>
  </div>
</dx-tooltip>
