<div *ngIf="(status$ | async)?.visible"
  class="eidos-command-panel">

  <div class="ecp-container">
    <div *ngIf="(status$ | async)?.onWorking" class="ecp-overlay">
      <mat-spinner class="ecp-spinner"
        color="accent"
        [diameter]="80"
      ></mat-spinner>
    </div>
    <div class="ecp-container-action">
      <div class="ecp-container-action-nav">
        <i *ngIf="(status$ | async)?.enableBack"
          [ngClass]="{disabled:historyBackDisabled}"
          class="fas fa-arrow-left"
          (click)="execBack()"
        ></i>
        <i *ngIf="(status$ | async)?.enableForward"
          [ngClass]="{disabled:historyForwardDisabled}"
          class="fas fa-arrow-right"
          (click)="execForward()"
        ></i>
      </div>
      <div *ngIf="title && titlePosition==='Left'">
        <span [title]="titleTooltip" class="ecp-title" [innerHTML]="title"></span>
      </div>
      <div class="ecp-container-action-left">
        <ng-container *ngFor="let a of (leftActions$ | async); let idx=index">

          <ng-container [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{a:a,idx:idx,dropdownitem:false}"
          ></ng-container>

        </ng-container>
      </div>
      <div *ngIf="title && titlePosition==='Middle'">
        <span [title]="titleTooltip" class="ecp-title" [innerHTML]="title"></span>
      </div>

      <div class="ecp-container-action-right">
        <ng-container *ngFor="let a of (rightActions$ | async); let idx=index">

          <ng-container [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{a:a,idx:idx,dropdownitem:false}"
          ></ng-container>

        </ng-container>

      </div>
      <div *ngIf="title && titlePosition==='Right'">
        <span [title]="titleTooltip" class="ecp-title" [innerHTML]="title"></span>
      </div>

    </div>

  </div>

</div>
<ng-template #actionButton  let-a="a" let-idx="index" let-dropdownitem="dropdownitem">
  <div *ngIf="!a.isVisible || a.isVisible()">
    <ng-container [ngSwitch]="a.commandType">

      <button *ngSwitchCase="EidosCommandTypes.ButtonBase"
        type="button"
        class="ecp-command"
        [ngClass]="getClasses(a,dropdownitem)"
        [ngStyle]="a.style ?? {}"
        (click)="execCallback($event,a)"
      >
        {{a.title ?? a.key}}
      </button>

      <div *ngSwitchCase="EidosCommandTypes.ButtonSwitch"
        class="form-check form-switch ecp-command ecp-command-switch"
        [ngClass]="getClasses(a)"
        [ngStyle]="a.style ?? {}"
      >
        <input id="ecp-button-switchCheckChecked"
          class="form-check-input"
          type="checkbox"
          (click)="execCallback($event,a)"
          [checked]="a.switchStatus()" />
        <span>{{a.title ?? a.key}}</span>
        <!-- <label class="form-check-label"
          for="ecp-button-switchCheckChecked"
        >
          {{a.title ?? a.key}}
        </label> -->
      </div>

      <div *ngSwitchCase="EidosCommandTypes.ButtonList" class="btn-group dropup" role="group">
        <button [id]="'ecp-ddr-'+idx" type="button" class="btn ecp-command dropdown-toggle"
          [ngClass]="getClasses(a)"
          [ngStyle]="a.style ?? {}"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{a.title ?? a.key}}
        </button>
        <div class="dropdown-menu" [attr.aria-labelledby]="'ecp-ddr-'+idx">
          <ng-container *ngFor="let a of (a.actionItems) let idx=index">

            <ng-container [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{a:a,idx:idx,dropdownitem:true}"
            ></ng-container>

          </ng-container>
        </div>
      </div>

      <div *ngSwitchDefault
        class="ecp-command"
        [ngClass]="a.class ?? {}"
        [ngStyle]="a.style ?? {}"
        (click)="a.callback($event,a)"
      >
        {{a.title ?? a.key}}
      </div>
    </ng-container>
    <div *ngIf="a.badgeTooltip && (a.badgeTooltip.active ? a.badgeTooltip.active() : true)"
      class="badge"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      [title]="getTooltipMessage(a.badgeTooltip)"
    ><eidos-icon [iconConfiguration]="a.badgeTooltip.icon ?? defaultIcon"></eidos-icon>
    </div>

  </div>

</ng-template>
