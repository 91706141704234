<div class="eidos-filter-wrapper eidos-daterange-filter-wrapper">
    <p class="eidos-filter-label eidos-daterange-filter-label" *ngIf="config.label">{{ config.label }}</p>

    <dx-text-box [ngClass]="cssClass" [id]="cssId" placeholder="{{ (config.placeholder ?? '') | transloco }}"
        [showClearButton]="!!config.showClearButton" [disabled]="!!config.disabled" (onKeyDown)="keyDown($event)"
        [(value)]="value" (valueChange)="config.onValueChange"
        (onFocusIn)="(!!config.onFocusIn && config.onFocusIn())"
        (onFocusOut)="(!!config.onFocusOut && config.onFocusOut())">
        <dxi-validation-rule *ngIf="!!config.required"
            type="required" [message]="config.requiredMessage ?? 'Field is required'">
        </dxi-validation-rule>
    </dx-text-box>
</div>
