import { Component, OnInit, Input } from '@angular/core';
import { EidosInputBaseComponent } from '../eidos-input-base.component';

@Component({
  selector: 'eidos-input-phone',
  templateUrl: './eidos-input-phone.component.html',
  styleUrls: ['./eidos-input-phone.component.scss']
})
export class EidosInputPhoneComponent extends EidosInputBaseComponent<string> implements OnInit {
  /**
   * Does the input allow the phone prefix flag
   *
   * @type {boolean}
   * @memberof EidosInputPhoneComponent
   */
  @Input() allowPrefix: boolean = true;
  /**
   * Input flag
   *
   * @protected
   * @type {string}
   * @memberof EidosInputPhoneComponent
   */
  protected pattern: string = '+?[0-9]*';

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.allowPrefix) {
      this.pattern = '[0-9]*';
    }
  }
  /**
   * Clean the value from not allowed characters
   *
   * @protected
   * @memberof EidosInputPhoneComponent
   */
  protected cleanValue() {
    if (!/^\d+$/.test(this.value)) {
      this.value = this.value.replace(this.allowPrefix ? /[^0-9+]/g : /[^0-9]/g, '');
    }
  }
}