import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppModule } from "@app/app.module";
import { IEidosIcon } from "@app/core/config/environment.interface";
import _ from "lodash";

export class ResCard {
  public title: string = '';
  public lines: Array<ResCardLine> = [];
}

export enum ResCardLineOptionType {
  AlignLeft,
  AlignRight,
  AlignRightAndIndentCaption,
  Subtitle,
  Total
}

export interface IResCardLineOptionsHyperlink {
  url: string;
  safeUrl?: SafeUrl;
  target?: string;
  parameters: object;
}

export interface IResCardLineOptionsActions {
  icon: IEidosIcon,
  callback: (params?:any) => void
}
export interface IResCardLineOptions {
  optionRender?: ResCardLineOptionType;
  status?: string;
  hyperlink?: IResCardLineOptionsHyperlink;
  actions?: IResCardLineOptionsActions[];
  ishighlighted?: boolean;
  isFinancialRecapTotal?: boolean;
  editConfig?: {
    editable: () => boolean,
    onEditedCallback: (value: any) => void
  }
}
export class ResCardLine {
  static sanitizer?: DomSanitizer;
  public caption: string;
  public value: string;
  public optionRender: ResCardLineOptionType;
  public status?: string;
  public hyperlink?: IResCardLineOptionsHyperlink;
  public ishighlighted?: boolean;
  public actions:IResCardLineOptionsActions[]=[]
  editConfig?: {
    editable: () => boolean,
    onEditedCallback: (value: any) => void
  }

  constructor(caption: string, value: string, options?: IResCardLineOptions) {
    this.caption = caption;
    this.value = value;
    this.optionRender = options?.optionRender ?? ResCardLineOptionType.AlignLeft;
    this.status = options?.status;
    if (options?.hyperlink && (options?.hyperlink?.url ?? '').trim() !== '') {
      this.hyperlink = options!.hyperlink;
      const safeUrl = _.template(this.hyperlink.url)(this.hyperlink.parameters);
      this.hyperlink.safeUrl = ResCardLine.getSanitizer().bypassSecurityTrustUrl(safeUrl);
    }
    if (options?.actions) {
      this.actions = options.actions;
    }
    this.ishighlighted = options?.ishighlighted;
    this.editConfig = options?.editConfig;
  }
  static createSubtitle(text: string): ResCardLine {
    return new ResCardLine('', text, { optionRender: ResCardLineOptionType.Subtitle } as IResCardLineOptions);
  }
  static getSanitizer(): DomSanitizer {
    if (ResCardLine.sanitizer) return ResCardLine.sanitizer;
    ResCardLine.sanitizer = AppModule.injector.get(DomSanitizer);
    return ResCardLine.sanitizer;
  }
}

export class BookingSectionResCard {
  sectionID: number;
  card: ResCard;
  constructor(sectionID: number, card: ResCard) {
    this.sectionID = sectionID;
    this.card = card;
  }
}
