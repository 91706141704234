import { DateTime } from "luxon";
import { BaseObject } from "./base-object.models";
import { IEidosNotification } from "./eidos-notification.interface";

/**
 * Notification in Eidos menu
 *
 * @export
 * @class EidosNotification
 * @extends {BaseObject}
 * @implements {IEidosNotification}
 */
export class EidosNotification extends BaseObject implements IEidosNotification {
  /**
   * Notification Id
   *
   * @type {string}
   * @memberof IEidosNotification
   */
  public id: string;
  /**
   * Notification text
   *
   * @type {string}
   * @memberof EidosNotification
   */
  public text: string;
  /**
   * Notification title
   *
   * @type {string}
   * @memberof EidosNotification
   */
  public title: string;
  /**
   * Notification timestamp
   *
   * @type {DateTime}
   * @memberof EidosNotification
   */
  public timestamp: DateTime;
  /**
   * Notification read flag
   *
   * @type {boolean}
   * @memberof EidosNotification
   */
  public read: boolean;
  /**
   * Notification sender
   *
   * @type {string}
   * @memberof IEidosNotification
   */
  public sender?: string;
  
  constructor(initdata?: any) {
    super();
    this.id = "-1";
    this.title = "";
    this.text = "";
    this.sender = "";
    this.read = false;
    this.timestamp = DateTime.now();
    if (initdata) {
      this.updateData(initdata);
    }
  }
}
