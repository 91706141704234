import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { SpinnerOverlayService } from '../services/spinner-overlay.service';
import { EidosApiService } from '../services/eidos-api.service';
import { EidosExternalAppService } from '../eidos-external-app/eidos-external-app.service';
import { EidosSecurityService } from '../services/eidos-security.service';
import { EidosTokenGuard } from './eidos-token.guard';
import { EidosConfigService } from '../config/eidos-config.service';
import { CoreRoute } from '../models/core-constant.model';

@Injectable({
  providedIn: 'root'
})
export class CoreProductionGuard extends EidosTokenGuard implements CanActivate {

  constructor(
    public router: Router
    , public eidosSecurityService: EidosSecurityService
    , public externalAppService: EidosExternalAppService
    , public spinnerOverlayService: SpinnerOverlayService
    , public eidosApiService: EidosApiService
    , private coreConfigService: EidosConfigService
    , public eidosConfigService: EidosConfigService
  ) {
    super(router, eidosSecurityService, externalAppService, spinnerOverlayService, eidosApiService, eidosConfigService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Promise<boolean | UrlTree> {

    return new Promise<boolean | UrlTree>(resolve => {
      super.canActivate(route).then((value: boolean | UrlTree) => {
        if (value instanceof UrlTree) {

          // Not authenticated, must resolve login path
          resolve(value);

        } else {
          // Logic of this guard
          if (!this.coreConfigService.currentConfig.getValue().production) {
            return resolve(true);
          } else {
            return resolve(this.router.parseUrl(CoreRoute.Home));
          }
        }
      });
    });
  }

}
