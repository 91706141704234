import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import { EidosBaseComponent } from '../eidos-base.component';
import { EidosModalService } from '../eidos-modal/eidos-modal.service';
import { IEidosModalConfig, IEidosModalType } from '../eidos-modal/eidos-modal.component';
import { CoreCacheService } from '@app/core/services/core-cache.service';


export interface ICorePopupListSelectorConfigValueChangedEvent {
  component: CorePopupListSelectorComponent,
  config: CorePopupListSelectorConfig,
  value: any[]
}

export interface ICorePopupListSelectorConfig {
  items?: any[];
  asyncItems?: () => Observable<any[]>;
  onValueChanged?: (e: ICorePopupListSelectorConfigValueChangedEvent) => void;
  loadingMode: 'hardCoded' | 'server' | 'cached';
  /**
   * Used only if loadingMode === 'cached'
   * Entity cached
   * (Used api as property name to keep compatibility with selectize)
   *
   * @type {string}
   * @memberof ICorePopupListSelectorConfig
   */
  api?: string;
  disabled?: boolean | (() => boolean);
  selectorDisplayField: string;
  selectedDisplayedField: string | ((item: any) => string);
  keyField: string;
  searchField: string[];
  searchEnabled?: boolean;
  selectionMode: 'multiple' | 'single' | 'all' | 'none';
  showSelectionControls?: boolean;
  showCheckBoxesMode?: string;
  showScrollbar?: 'onScroll' | 'onHover';
  selectionModeAll?: 'page' | 'allPages';
  title?: string;
  subtitle?: string;
  /**
   * Max selectable items
   *
   * @type {number}
   * @memberof ICorePopupListSelectorConfig
   */
  maxItems?: number;
  /**
   * Show "<first_item> and more" if selected items are more
   *
   * @type {number}
   * @memberof ICorePopupListSelectorConfig
   */
  showMoreLabelAfterTotItems?: number;
}

export class CorePopupListSelectorConfig implements ICorePopupListSelectorConfig {
  disabled?: boolean | (() => boolean) = false;
  onValueChanged: ((e: ICorePopupListSelectorConfigValueChangedEvent) => void) = (_) => { return; };
  loadingMode: 'hardCoded' | 'server' | 'cached' = 'hardCoded';
  api?: string;
  items: any[] = [];
  selectorDisplayField: string = 'Desc';
  selectedDisplayedField: string | ((item: any) => string) = 'Desc';
  keyField: string = 'Cod';
  searchField: string[] = ['Desc'];
  searchEnabled: boolean = true;
  selectionMode: 'multiple' | 'single' | 'all' | 'none' = 'multiple';
  showSelectionControls: boolean = true;
  showCheckBoxesMode?: string;
  showScrollbar: 'onScroll' | 'onHover' = 'onHover';
  selectionModeAll: 'page' | 'allPages' = 'allPages';
  title: string = 'Select';
  subtitle?: string;
  maxItems?: number;
  showMoreLabelAfterTotItems?: number;

  get internalDisabled(): boolean {
    return !!this.disabled && typeof this.disabled === 'function' ? this.disabled() : !!this.disabled;
  }

  constructor(data?: ICorePopupListSelectorConfig) {
    if (data) {
      if (data.disabled != undefined) {
        this.disabled = data.disabled;
      }
      this.items = data.items ?? [];
      this.loadingMode = data.loadingMode;

      if (this.loadingMode === 'server' && !!data.asyncItems) {
        data.asyncItems().subscribe(items => this.items = items);
      }

      if (this.loadingMode === 'cached' && !!data.api) {
        this.api = data.api;
      }

      if (data.onValueChanged) {
        this.onValueChanged = data.onValueChanged;
      }

      this.selectorDisplayField = data.selectorDisplayField;
      this.selectedDisplayedField = data.selectedDisplayedField;
      this.keyField = data.keyField;
      this.searchField = data.searchField;
      if (data.searchEnabled != undefined) {
        this.searchEnabled = !!data.searchEnabled;
      }
      this.selectionMode = data.selectionMode;
      if (data.showSelectionControls != undefined) {
        this.showSelectionControls = !!data.showSelectionControls;
      }
      if (data.showCheckBoxesMode) {
        this.showCheckBoxesMode = data.showCheckBoxesMode;
      }
      if (data.showScrollbar) {
        this.showScrollbar = data.showScrollbar;
      }
      this.maxItems = data.maxItems;
      this.showMoreLabelAfterTotItems = data.showMoreLabelAfterTotItems;
    }
  }

  async rebuildCachedItems(service: CoreCacheService) {
    if (this.loadingMode === 'cached' && this.api)
      this.items = await service.getCachedData<unknown>(this.api as any);
  }
}

@Component({
  selector: 'core-popup-list-selector',
  templateUrl: './core-popup-list-selector.component.html',
  styleUrls: ['./core-popup-list-selector.component.scss']
})
export class CorePopupListSelectorComponent extends EidosBaseComponent {

  private _config: CorePopupListSelectorConfig = new CorePopupListSelectorConfig();

  @Input() set config(value: ICorePopupListSelectorConfig) {
    this._config = new CorePopupListSelectorConfig(value);
  }
  get config(): CorePopupListSelectorConfig {
    return this._config;
  }

  protected internalValue: any[] = [];

  private _value: any[] = [];
  @Input() set value(v: any[]) {
    if (v === undefined || v === null) {
      this._value = [];
    } else if (!Array.isArray(v)) {
      this._value = [v];
    } else {
      this._value = v;
    }
    this.valueChange.emit(this._value);
  }
  get value(): any[] {

    return this._value;
  }
  @Output() valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output() onValueChanged: EventEmitter<{ value: any[] }> = new EventEmitter<{ value: any[] }>();

  @ViewChild('corePopupListTemplate') corePopupListTemplate?: TemplateRef<any>;

  private corePopupConfig: IEidosModalConfig = {
    modalKey: 'deletePriceModal',
    type: IEidosModalType.Custom,
    width: '40%',
    height: '80%',
    position: 'center',
    title: () => this.config.title,
    subtitle: () => this.config.subtitle ?? '',
    template: () => this.corePopupListTemplate!,
    onClosed: () => {
    },
    onOpened: () => {
      this.setupInternalValue();
    }
  }
  protected closePopupSelector() {
    this.eidosModalService.closeModal(this.corePopupConfig.modalKey);
  }
  protected openPopupSelector() {
    this.setupInternalValue();
    this.eidosModalService.openModal(this.corePopupConfig);
  }

  protected maxSelectedItemsOver: boolean = false;

  constructor(
    private eidosModalService: EidosModalService,
    private coreCacheService: CoreCacheService
  ) {
    super();
  }

  async ngOnInit() {
    super.ngOnInit();
    await this.config.rebuildCachedItems(this.coreCacheService);
    if (!this.value) {
      this.value = [];
    }
    this.setupInternalValue();
  }

  private setupInternalValue() {
    this.internalValue = this.value ? [...this.value] : [];
  }

  protected getLabelOfItem(item: any) {
    if (typeof this.config.selectedDisplayedField === 'function') {
      return this.config.selectedDisplayedField(item);
    } else if (typeof this.config.selectedDisplayedField === 'string' && this.config.selectedDisplayedField in item) {
      return item[this.config.selectedDisplayedField];
    } else return 'N/D';
  }

  protected removeItem(index: number) {
    if (this.value.at(index)) {
      if (index === 0) {
        this.value.shift();
      } else if (index === this.value.length - 1) {
        this.value.pop();
      } else {
        this.value.splice(index, 1);
      }
    }

    this.setupInternalValue();
  }

  protected checkMaxItems() {
    this.maxSelectedItemsOver = !!this.config.maxItems && this.internalValue.length > this.config.maxItems;
  }

  protected onSave(event?: any): void {
    const isValid = event?.validationGroup ? event.validationGroup.validate().isValid : true;
    if (isValid && !this.maxSelectedItemsOver) {
      this.value = [...this.internalValue];
      this.closePopupSelector();

      const e = {
        component: this,
        config: this.config,
        value: this.value
      };

      this.config.onValueChanged(e);
      this.onValueChanged.emit(e);
    }
  }
}