<content class="h-100" *ngIf="config && config.length > 0; else missingConfigTemplate">
  <content class="h-100" *ngIf="(results.length > 0 ) || !!EntityType; else noResultsTemplate">
    <div class="eidos-product-search-results-container h-100" [ngSwitch]="currentResultsView!.id">

      <dx-scroll-view [classList]="['eidos-search-results-wrapper']">
        <content *ngSwitchCase="'tiles'">
          <content *ngIf="currentResultsView!.template; else missingTemplate">
            <div class="row">
              <div *ngFor="let result of results" class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                style="margin-bottom: 24px;">
                <ng-template [ngTemplateOutletContext]="getTemplateData(result)"
                  [ngTemplateOutlet]="currentResultsView!.template">
                </ng-template>
              </div>
            </div>
          </content>
        </content>

        <content *ngSwitchCase="'rows'">
          <content *ngIf="currentResultsView!.template; else missingTemplate">
            <dx-list *ngIf="results.length > 0" [dataSource]="results" height="100%"
              [searchEnabled]="!!(currentResultsView | cast: ICoreSearchResultsRowTilesViewConfig).searchExpr"
              [searchExpr]="(currentResultsView | cast: ICoreSearchResultsRowTilesViewConfig).searchExpr ?? []"
              itemTemplate="row-tiles-item"
              [searchMode]="(currentResultsView | cast: ICoreSearchResultsRowTilesViewConfig).searchMode ?? 'contains'"
              selectionMode="none" (onSelectionChanged)="onRowTileResultsClick($event)">
              <ng-template *dxTemplate="let item of 'row-tiles-item'" [ngTemplateOutletContext]="getTemplateData(item)"
                [ngTemplateOutlet]="currentResultsView!.template">
              </ng-template>
            </dx-list>
          </content>
        </content>

        <content class="d-flex" *ngSwitchCase="'grid'" style="gap: 5px;">
          <eidos-grid [config]="(currentResultsView | cast: ICoreSearchResultsGridViewConfig).config"
            (onCustomButtonClick)="onGridResultsClick($any($event));"
            (onRowClick)="onGridResultsClick($any($event))"
            [dataSource]="collapsedDisplayedResults" style="flex: 1">
          </eidos-grid>
          <div *ngIf="isResultsCollapsed" (click)="resetCollapsedDisplayedResults()"
            class="d-flex flex-column justify-content-center align-items-center eidos-search-results-expand-command">
            <i class="fa fa-expand-alt"></i>
          </div>
        </content>

        <content *ngSwitchCase="'map'">
          TO DO
        </content>
      </dx-scroll-view>

      <ng-template #missingTemplate>No template found for this view.</ng-template>
    </div>
  </content>
  <ng-template #noResultsTemplate>
    <div class="eidos-product-search-results-empty mt-3">No results found.</div>
  </ng-template>
</content>
<ng-template #missingConfigTemplate>Missing or empty search results view configuration!</ng-template>
