import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { filter } from 'rxjs';
import { IEidosChartValue } from '@common/models/eidos-chart-js.model';

import { EidosObjectBaseComponent } from '@eidos/components/objects/eidos-object-base/eidos-object-base.component';
import { IEidosChartConfigurationData, IEidosHtmlConfigurationData } from '@common/models/eidos-object.interface';
import { EidosObject, EidosObjectConfiguration, EidosObjectData, EidosObjectEventAction, EidosObjectGridConfig, EidosObjectType } from '@common/models/eidos-object.model';
import { EidosDashboardConfig } from '@common/models/eidos-dashboard.model';
import { EidosObjectService } from '@eidos/services/eidos-object.service';

@Component({
  selector: 'eidos-object-ui',
  templateUrl: './eidos-object-ui.component.html',
  styleUrls: ['./eidos-object-ui.component.scss']
})
export class EidosObjectUiComponent extends EidosObjectBaseComponent implements OnInit, AfterContentInit {

  EidosObjectType = EidosObjectType;

  @Input()
  public eidosObject: EidosObject | undefined;
  @Input()
  public eidosObjectConfiguration: EidosObjectConfiguration | undefined;

  public get eidosObjectChartConfiguration(): IEidosChartConfigurationData {
    return this.eidosObjectConfiguration?.objectDetails as IEidosChartConfigurationData;
  }

  public get eidosObjectHTMLConfiguration(): IEidosHtmlConfigurationData {
    return this.eidosObjectConfiguration?.objectDetails as IEidosHtmlConfigurationData;
  }

  public get eidosObjectDashboardConfiguration(): EidosDashboardConfig {
    return this.eidosObjectConfiguration?.objectDetails as EidosDashboardConfig;
  }

  public get eidosObjectGridConfiguration(): EidosObjectGridConfig {
    return this.eidosObjectConfiguration?.objectDetails as EidosObjectGridConfig;
  }
  @Input()
  public eidosObjectData: EidosObjectData | undefined;

  public get eidosObjectDataChartValue(): IEidosChartValue {
    return this.eidosObjectData?.value as IEidosChartValue;
  }

  constructor(private eidosObjectService: EidosObjectService
  ) {
    super();
    this.eidosObjectService.objectEventQueryEmitter.pipe(filter(e => e.actionMessage?.tagetId === this.eidosObjectId),).subscribe(
      e => {
        if (!e.actionMessage) return;

        let data = {};
        switch (e.actionMessage.action) {
          case EidosObjectEventAction.ReloadData:
            this.eidosObjectService.reloadData(this.eidosObjectId, this.eidosObject);
            break;
          case EidosObjectEventAction.ReloadObject:
            this.eidosObjectService.loadComponent(this.eidosObjectId, this.eidosObject, true);
            break;
        }
        this.eidosObjectService.sendResponseEvent(e, data);
      }
    )
  }

  ngAfterContentInit(): void {
  }

  ngOnInit(): void {
  }
}

