import { EventEmitter, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationExtras, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { EidosExternalAppAction, EidosIframeBehaviourType, EidosIframeEventType, EidosLogSeverity, CoreRoute } from '@common/models/core-constant.model';
import { IEidosExternalApp, IEidosExternalAppCommandData, IEidosExternalAppEventData, IEidosExternalAppVersion } from '@common/models/eidos-external-app.interface';
import { EidosExternalApp } from '@common/models/eidos-external-app.model';
import { EidosUser } from '@common/models/user-info.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { SpinnerOverlayService } from '@common/services/spinner-overlay.service';

@Injectable({
  providedIn: 'root'
})
export class EidosExternalAppService {
  /**
   * External apps events to propagate in Eidos
   *
   * @memberof EidosExternalAppService
   */
  public ExternalApplicationEvent = new EventEmitter();

  /**
   * External apps (MyBiz excluded)
   *
   * @memberof EidosExternalAppService
   */
  public apps = new BehaviorSubject<Array<EidosExternalApp> | undefined>(undefined);

  /**
   * External apps successful authentication event
   *
   * @memberof EidosExternalAppService
   */
  public ExternalApplicationAuthorizedEvent = new EventEmitter<EidosExternalApp>();
  /**
   * Base URL of application
   *
   * @private
   * @type {string}
   * @memberof EidosExternalAppService
   */
  private baseUrl: string;
  /**
  * External config apps
  *
  * @type {Array<IEidosExternalApp>}
  * @memberof EidosExternalAppService
  */
  public configApps: Array<IEidosExternalApp> = [];
  /**
   * MyBiz legacy external app config
   *
   * @type {(EidosExternalApp)}
   * @memberof EidosExternalAppService
   */
  public appMyBiz: EidosExternalApp | undefined;
  /**
   * MyBiz legacy external app config
   *
   * @type {(EidosExternalApp)}
   * @memberof EidosExternalAppService
   */
  public appEmbeddedUrl: EidosExternalApp | undefined;
  /**
   * External apps local override flag
   *
   * @type {boolean}
   * @memberof EidosExternalAppService
   */
  public overrideExternalApps: boolean = false;
  /**
   * Root URL for external apps routing
   *
   * @type {string}
   * @memberof EidosExternalAppService
   */
  public externalAppsRoot: string = "";

  constructor(
    private eidosConfigService: EidosConfigService
    , private spinnerOverlayService: SpinnerOverlayService
    , private sanitizer: DomSanitizer
    , private router: Router
    , private eidosLogService: EidosLogService
    , private eidosSecurityService: EidosSecurityService
  ) {

    this.baseUrl = this.eidosConfigService.DEFAULT_CONFIG.baseUrl;

    this.eidosConfigService.currentConfig.subscribe(config => {
      // Setup MyBiz and embedded Iframe
      this.externalAppsRoot = config.externalAppsRoot;

      if (config.myBizLegacyConfiguration && config.myBizLegacyConfiguration.id != '') {
        config.myBizLegacyConfiguration.url = config.myBizLegacyConfiguration.url.replace('~', config.baseUrl)
        this.appMyBiz = new EidosExternalApp(sanitizer, config.myBizLegacyConfiguration);
      }

      if (config.embeddedUrl) {
        config.embeddedUrl.url = config.embeddedUrl.url.replace('~', config.baseUrl)
        this.appEmbeddedUrl = new EidosExternalApp(sanitizer, config.embeddedUrl);
      }

      this.baseUrl = config.baseUrl;
    });

    // Eventually get local external apps override
    this.eidosConfigService.currentlocalConfigExternalApps.subscribe(customExternalApps => {
      if (customExternalApps && customExternalApps.length > 0) {
        this.overrideExternalApps = true;
        this.configApps = customExternalApps;
      }
    });

    this.eidosSecurityService.currentLoggedUser
      .subscribe((user: EidosUser) => {
        if (user.isValid) {
          if (this.overrideExternalApps) {
            this.buildExternalAppsIframes(this.configApps);
          } else {
            this.buildExternalAppsIframes(user.externalApps);
          }
        } else {
          this.apps.next(undefined);
        }
      });

    // Add listener for external apps iframes events
    window.addEventListener("message", (event) => {

      // The sender app is now retrieved by source field in event.data
      if (event.data.source) {
        const app = this.getAppReferenceBySource(event.data.source);

        // SECURITY: Check if the sender origin is equal to app origin
        if (app && app.origin === event.origin) {

          // Handle the message
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `child ${app.source} message ${event.data.type}`);
          switch (event.data.type) {
            case EidosIframeEventType.Command:
              this.handleAppCommand(event, app);
              break;
            case EidosIframeEventType.Version:
              this.handleAppVersions({ source: app.source, version: event.data.version });
              break;
            case EidosIframeEventType.Event:
              this.handleAppEvent(event, app);
              break;
          }
        }
      }
    }, false);
  }
  /**
   * Hides all apps when entering other routes of application
   *
   * @memberof EidosExternalAppService
   */
  public hideAll(): void {
    this.apps.value?.forEach(a => a.isVisible = false);
  }
  /**
   * Handles command messages from external apps
   *
   * @private
   * @param {*} event
   * @param {IEidosExternalApp} app
   * @memberof EidosExternalAppService
   */
  private handleAppCommand(event: any, app: IEidosExternalApp) {
    let data: IEidosExternalAppCommandData = event.data;
    switch (data.command) {
      case 'goto':
        if (data.segments) {
          this.applicationGoto(app.source, data.id, data.segments);
        }
        break;
    }
  }
  /**
   * Handles Version messages from external apps
   *
   * @param {IEidosExternalAppVersion} data
   * @memberof EidosExternalAppService
   */
  public handleAppVersions(data: IEidosExternalAppVersion) {
    let event = this.createEventSimple(data.source, "versionResponse");
    event.parameters = data;
    this.ExternalApplicationEvent.emit(event);
  }
  /**
   * Handles Event messages from external apps
   *
   * @private
   * @param {*} event
   * @param {IEidosExternalApp} app
   * @memberof EidosExternalAppService
   */
  private handleAppEvent(event: any, app: EidosExternalApp) {
    let data: IEidosExternalAppEventData = event.data;
    switch (data.name) {
      case EidosIframeBehaviourType.Dataloaded:
        this.spinnerOverlayService.hide();
        this.applicationShow(app.source);
        break;
      case EidosIframeBehaviourType.LoginTokenSuccessful:
        this.applicationAuthorizing(app, true);
        break;
      case EidosIframeBehaviourType.RefreshToken:
        // External app is requiring a token refresh
        this.sendToken(app.source);
        break;
      case EidosIframeBehaviourType.LoginTokenError:
        // this.applicationAuthorizing(app, false);
        break;
      case EidosIframeBehaviourType.Route:
        const appCod = this.router.routerState.snapshot.url.split('/')[3];
        const appReference = this.getAppReferenceByAction(appCod);

        data.url = decodeURI(data.url || "");  // this.sanitizeUrl(data.url);

        /** Se non ho referenze ad un app esterna o non è quella corrente, scarto il messaggio */
        if (appReference?.url && data.url?.indexOf(appReference?.url) !== -1) {
          const params = data.url?.split(appReference?.pattern)[1].split('/');
          if (params.length > 0 && params[0] === '') params.shift();

          if (params) {
            let appSegments = [this.externalAppsRoot, appCod];
            const uriSeg = params.filter(p => !appReference.actionInUrl || p.toLowerCase() !== appReference.action.toLowerCase());

            uriSeg.forEach(s => {
              appSegments.push(s);
            });

            this.router.navigate(appSegments);
          }
        }
        break;
      case EidosIframeBehaviourType.Pong:
        //all it's OK
        this.applicationShow(app.source);
        this.applicationAuthorizing(app, true);
        break;
    }
  }
  /**
   * Transforms apps configuration in theirs iframes
   *
   * @private
   * @param {IEidosExternalApp[]} rawExternalApps
   * @memberof EidosExternalAppService
   */
  private buildExternalAppsIframes(rawExternalApps: IEidosExternalApp[]): void {

    // Build rebased external apps url
    let builtApps = rawExternalApps.map(
      app => {
        app.url = app.url.replace('~', this.baseUrl);
        this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master ${app.source} costructor: legacy url=${app.url}`);
        return new EidosExternalApp(this.sanitizer, app);
      });

    // Filter built apps to avoid duplicates and create iframes
    const apps = Object.assign([], builtApps.filter((item, index) => {
      const indexOfItem = builtApps.findIndex(otherItem => item.source === otherItem.source || item.action === otherItem.action);
      const itemIsUnique = indexOfItem === index;
      if (!itemIsUnique) {
        builtApps[indexOfItem].isDuplicated = true;
      }
      return itemIsUnique;
    }));

    this.apps.next(apps);
  }

  /**
   * Return an external app basing on its action
   *
   * @param {string} action
   * @return {*}  {(IEidosExternalApp | null)}
   * @memberof EidosExternalAppService
   */
  public setAppEidosEmbeddedUrl(url: string): void {
    const app = this.getAppReferenceByAction(EidosExternalAppAction.EmbeddedUrl);
    if (!app) return;
    app.updateUrl(url, this.sanitizer);
  }

  /**
   * Return true if this action is Mybiz ext app
   *
   * @param {string} action
   * @return {Boolean}
   * @memberof EidosExternalAppService
   */
  public isMyBizAction(action: string): boolean {
    return this.appMyBiz?.action === action;
  }
  /**
   * Return true if this action is an EmbaddedUrl
   *
   * @param {string} action
   * @return {Boolean}
   * @memberof EidosExternalAppService
   */
  public isEmbeddedUrlAction(action: string): boolean {
    return this.appEmbeddedUrl?.action === action;
  }
  /**
   * Return true if is action vali or must wait load apps configuration
   *
   * @param {string} action
   * @return {Boolean}
   * @memberof EidosExternalAppService
   */
  public isValidAction(action: string): boolean {
    return this.isMyBizAction(action) || this.isEmbeddedUrlAction(action) || !!this.apps.getValue() && !!this.apps.getValue()?.find(app => app.action === action);
  }

  /**
   * Return the external app with action passed as parameter
   *
   * @param {string} action
   * @return {*}  {(IEidosExternalApp | null)}
   * @memberof EidosExternalAppService
   */
  public getAppReferenceByAction(action: string): EidosExternalApp | null | undefined {
    if (this.appMyBiz?.action === action) return this.appMyBiz;
    if (this.appEmbeddedUrl?.action === action) return this.appEmbeddedUrl;

    const apps = this.apps.value;

    if (apps !== undefined) {
      const app = apps.find(a => a.action === action);
      !app && this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master: postMessage unknown app with action=${action}`);
      return app ? app : null;
    } else {
      return undefined;
    }
  }

  /**
   * Return the external app with source passed as parameter
   *
   * @param {string} action
   * @return {*}  {(IEidosExternalApp | null)}
   * @memberof EidosExternalAppService
   */
  public getAppReferenceBySource(source: string): EidosExternalApp | null | undefined {
    if (this.appMyBiz?.source === source) return this.appMyBiz;
    if (this.appEmbeddedUrl?.source === source) return this.appEmbeddedUrl;

    const apps = this.apps.value;

    if (apps !== undefined) {
      const app = apps.find(a => a.source === source);
      !app && this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master: postMessage unknown app=${source}`);
      return app ? app : null;
    } else {
      return undefined;
    }
  }

  /**
   * Return the external app with id passed as parameter
   *
   * @param {number} id
   * @return {*}  {(EidosExternalApp | null | undefined)}
   * @memberof EidosExternalAppService
   */
  public getAppReferenceById(id: number): EidosExternalApp | null | undefined {
    if (this.appMyBiz?.id === id.toString()) return this.appMyBiz;
    if (this.appEmbeddedUrl?.source === id.toString()) return this.appEmbeddedUrl;

    const apps = this.apps.value;

    if (apps !== undefined) {
      const app = apps.find(a => a.id == id.toString());
      !app && this.eidosLogService.logDebug(EidosLogSeverity.Log, `iframe master: action object item unknown app=${id}`);
      return app ? app : null;
    } else {
      return undefined;
    }
  }

  /**
   * Handle external app authorization response
   *
   * @private
   * @param {IEidosExternalApp} app
   * @param {boolean} auth
   * @memberof EidosExternalAppService
   */
  private applicationAuthorizing(app: EidosExternalApp, auth: boolean) {
    app.authorized = auth;
    this.ExternalApplicationAuthorizedEvent.emit(app);
  }
  /**
   * Handle external app route change event
   *
   * @param {string} source
   * @param {string} id
   * @param {Array<string>} segments
   * @memberof EidosExternalAppService
   */
  public applicationGoto(source: string, id: string, segments: Array<string>, queryParams: Params = {}) {
    const app = this.getAppReferenceBySource(source);
    if (app) {
      this.ExternalApplicationEvent.emit(this.createEventIdEvent(source, 'goto', id, segments, queryParams));
    } else {
      this.router.navigate([CoreRoute.Home]);
    }
  }
  /**
   * Handle external app route change event
   *
   * @param {string} source
   * @param {string} id
   * @param {Array<string>} segments
   * @memberof EidosExternalAppService
   */
  public applicationSwap(app: IEidosExternalApp, params: any) {
    if (app) {
      const s = app.action.split('/')
      const options:NavigationExtras = {queryParams:params}
      this.router.navigate([this.externalAppsRoot].concat(s),options);
    } else {
      this.router.navigate([CoreRoute.Home]);
    }
  }
  private createEventIdEvent(source: string, name: string, id: string, parameters: string[], queryString: Params): IEidosExternalAppEventData {
    return { source: source, name: name, id: id, segments: parameters, queryString: queryString };
  }

  /**
   * Trigger event to emit a post message to external app to require their versions
   *
   * @memberof EidosExternalAppService
   */
  public requireAppVersions() {

    const apps = this.apps.value;

    if (apps !== undefined) {
      apps.forEach(app => {
        // Exclude mybiz from requiring version: it is retrieved by calling API
        if (app.source && app.source !== 'mybiz') {
          this.ExternalApplicationEvent.emit(this.createVersionUpdateRequest(app.source));
        }
      });
    }
  }
  private createVersionUpdateRequest(source: string): IEidosExternalAppEventData {
    return this.createEventSimple(source, "versionRequest");
  }

  applicationShow(source: string) {
    this.ExternalApplicationEvent.emit(this.createEventSimple(source, 'show'));
  }
  applicationHide(source: string) {
    this.ExternalApplicationEvent.emit(this.createEventSimple(source, 'hide'));
  }
  redirect(path: string) {
    this.ExternalApplicationEvent.emit(this.createEventSimple(path, 'redirect'));
  }
  sendToken(source: string) {
    this.ExternalApplicationEvent.emit(this.createEventSimple(source, 'token'));
  }
  sendPing(source: string) {
    this.applicationHide(source);
    this.ExternalApplicationEvent.emit(this.createEventSimple(source, 'ping'));
  }
  private createEventSimple(source: string, name: string): IEidosExternalAppEventData {
    return { source: source, name: name, id: '', segments: [], queryString:{} };
  }
}
