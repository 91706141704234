import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from '@app/error/services/error.service';
import { take } from 'rxjs';

@Component({
  selector: 'error-payment',
  templateUrl: './error-payment.component.html',
  styleUrls: ['./error-payment.component.scss']
})
export class ErrorPaymentComponent implements OnInit {
  /**
   * Dictionary of all possible error after a payment
   *
   * @private
   * @type {{[key: string]: string}}
   * @memberof ErrorPaymentComponent
   */
  #errorMap: { [key: string]: string } = {
    ERRCHECKSUM: "Invalid checksum error. Contact your administrator.",
    ERRSPRESPONSE: "The payment process failed. Contact your administrator."
  };
  /**
   * Decoded error
   *
   * @type {string}
   * @memberof ErrorPaymentComponent
   */
  public error: string = "";
  /**
   * Dictionary of all possible return sites after a payment
   *
   * @private
   * @type {{[key: string]: string}}
   * @memberof ErrorPaymentComponent
   */
  private returnSiteNames: { [key: string]: string } = {
    RES: "https://www.crystalcruises.com/",
    CCS: "https://www.crystalcruises.com/"
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {

    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {

        // The param ?e=<errorCode> or ?E=<errorCode> specifies the error code
        const errorCode = params.e ?? params.E;
        if (!!errorCode && !!this.#errorMap && (errorCode in this.#errorMap)) {
          // Look for a error in dictionary matching with the param e
          this.error = this.#errorMap[errorCode];
        }

        // The param ?s=<site_name> or ?S=<site_name> specifies the URL for postpayment redirection
        const siteName = params.s ?? params.S;
        if (!!siteName && !!this.returnSiteNames && (siteName in this.returnSiteNames)) {
          // Look for a return url in dictionary matching with the param s
          this.errorService.returnUrl = this.returnSiteNames[siteName];
        } else {
          this.errorService.returnUrl = this.returnSiteNames["RES"];
        }

        // Remove all sensible params from URL to avoid accidental reloadings
        this.router.navigate([],
          {
            relativeTo: this.route,
            queryParams: {
              e: undefined,
              E: undefined,
              s: undefined,
              S: undefined
            },
            queryParamsHandling: 'merge'
          }
        );
      });
  }
}
