import { SafeUrl } from '@angular/platform-browser';
import { IEidosIcon } from '@app/core/config/environment.interface';

export enum IReservationHomeItemActions {
  URL = 'url',
}

export interface IReservationHomeCategory {
  icon: IEidosIcon;
  title: String;
  description: String;
  children: IReservationHomeItem[];
  isFavourites: boolean;
}

export interface IReservationHomeItem {
  id: number;
  icon: IEidosIcon;
  title: String;
  description: String;
  action: IReservationHomeItemActions | undefined;
  actionData: IReservationHomeActionData;
  children?: IReservationHomeItem[];
}
export interface IReservationHomeActionData {
  url?: string;
  safeUrl?: SafeUrl;
  urlRelative?: boolean;
  target?: string;
  module?: string;
}

export interface IReservationHomeItemClickEvent {
  item?: IReservationHomeItem,
  event: Event
}