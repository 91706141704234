<div class="login-wrapper">

  <img *ngIf="backgroundImagePath" class="app-background" [src]="backgroundImagePath">
  <div class="login-container">
    <div class="login-header" *ngIf="logo">
      <eidos-icon class="login-header-logo" [iconConfiguration]="logo"></eidos-icon>
    </div> 
    <mat-card class="auth-block">
      <mat-card-content>
        <div class="login-token-icon">
          <eidos-icon *ngIf="isOnError" [iconConfiguration]="okIcon"></eidos-icon>
          <mat-spinner *ngIf="!isOnError" class="request-spinner" style="margin:0 auto;"></mat-spinner>
        </div>
        <h4 *ngIf="isOnError" class="login-token-message">{{ 'token_login.'+message | transloco }}</h4>
        <h4 *ngIf="!isOnError" class="login-token-message">Logging in...</h4>
      </mat-card-content>
    </mat-card>
    <button *ngIf="isOnError" mat-raised-button color="primary" (click)="goToLogin()">{{ 'token_login.go_login' | transloco }}</button>
  </div>
</div>