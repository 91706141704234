import { Component, OnInit } from '@angular/core';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosEnvironment } from '@common/config/environment.interface';

@Component({
  selector: 'eidos-home-page',
  templateUrl: './eidos-home-page.component.html',
  styleUrls: ['./eidos-home-page.component.scss']
})
export class EidosHomePageComponent implements OnInit {

  /**
   * Background path
   *
   * @type {string}
   * @memberof EidosHomePageComponent
   */
  public background: string | undefined;

  constructor(
    private eidosConfigService: EidosConfigService
  ) {
  }

  ngOnInit(): void {
    this.eidosConfigService.currentConfig.subscribe((config: IEidosEnvironment) => {
      if (config.applicationBackground) {
        this.background = 'url(' + config.applicationBackground + ')';
      }
    });
  }
}
