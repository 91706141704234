import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { ILevelGaugeConfiguration } from '@common/models/eidos-chart-js.interface';
import { IEidosChartValue } from '@common/models/eidos-chart-js.model';
import { IEidosScalarChartConfigurationData } from '@common/models/eidos-object.interface';
import { EidosObjectChartType } from '@common/models/eidos-object.model';
import * as d3ext from '@assets/js/d3-ext/liquid-fill-gauge.js';

@Component({
  selector: 'eidos-level-chart',
  templateUrl: './level-chart.component.html',
  styleUrls: ['./level-chart.component.css']
})
export class LevelChartComponent implements OnInit, AfterViewInit {
  type: EidosObjectChartType = EidosObjectChartType.Level;

  @ViewChild('fillLevelChart')
  private svg: ElementRef | null;

  private levelChart: any;

  private data: number;
  @Input()
  public set Data(value: IEidosChartValue) {
    this.data =  typeof value === 'number' ? value : 0;
  }

  private config: ILevelGaugeConfiguration = {};
  @Input()
  public set Config(config: IEidosScalarChartConfigurationData) {
    this.config = config.config as ILevelGaugeConfiguration;
  }

  constructor() {
    this.svg = null;
    this.data = 0;
    this.config = d3ext.liquidFillGaugeDefaultSettings() as ILevelGaugeConfiguration;
  }

  ngAfterViewInit(): void {
    this.levelChart = d3ext.loadLiquidFillGauge(this.svg?.nativeElement, this.data, this.config);
  }

  ngOnChanges() {
    if (!this.levelChart) return;
    this.levelChart.update(this.data);
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
  }
}
