import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IEidosFiltersRegistry } from "@common/models/eidos-filters.models";
import { EidosFilterComponent } from "@common/components/entities/filters/eidos-filter/eidos-filter.component";

@Component({
  selector: 'eidos-filter-selectize',
  templateUrl: './eidos-filter-selectize.component.html',
  styleUrls: ['./eidos-filter-selectize.component.scss']
})
export class EidosFilterSelectizeComponent extends EidosFilterComponent {
  /**
   * Current filter value (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterSelectizeComponent
   */
  public _value: any;
  /**
   * Filter value getter
   * Returns the value joined to the filter if required
   *
   * @readonly
   * @type {*}
   * @memberof EidosFilterSelectizeComponent
   */
  public get value(): any {
    // If val is an array and the configuration requires to join, join the array and emit the string
    return Array.isArray(this._value) && this._joinTheResult ? this._value?.join(',') : this._value;
  }
  /**
   * Filter value setter
   * Split the value to pass to the input if required
   *
   * @memberof EidosFilterSelectizeComponent
   */
  @Input() public set value(val: any) {

    if (Array.isArray(val)) {
      // Val is an array, we don't need to do anythinb because it is already a legal internal value
      this._value = val;
    } else {
      // Val is not an array (and it is a string therefore), we need to split it if the configuration requires it or we are in case of 1 item select
      if (this._joinTheResult) {
        // If val is an empty string, return an empty array
        this._value = !!val ? val.split(',').map((item: string) => isNaN(parseInt(item)) ? item : parseInt(item)) : [];
      } else {
        this._value = val ?? (this.config?.maxItems === 1 ? "" : []);
      }
    }

    // Calls the getter to retrieve the value in the correct format
    val = this.value;

    this.valueChange.emit(val);
  }
  @Output() public valueChange: EventEmitter<IEidosFiltersRegistry["selectize"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["selectize"]["valueType"]>();

  /**
   * Current real input value (2-way binding) to pass to the filter value setter
   *
   * @type {*}
   * @memberof EidosFilterSelectizeComponent
   */
  public get selectizeValue(): any {
    return this._value;
  }
  public set selectizeValue(val: any) {
    // Calls the setter to retrieve the value in the correct format
    this.value = val;
    this.selectizeValueChange.emit(this._value);
  }
  @Output() public selectizeValueChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["selectize"]["config"]}
   * @memberof EidosFilterSelectizeComponent
   */
  @Input() public config: IEidosFiltersRegistry["selectize"]["config"];
  /**
   * Checks if the input value must be joined to a string
   *
   * @readonly
   * @private
   * @type {boolean}
   * @memberof EidosFilterSelectizeComponent
   */
  private get _joinTheResult(): boolean {
    return (this.config?.joinResult && this.config?.maxItems !== 1) ?? false;
  }

  constructor() {
    super();
  }


  interpolateTemplate:string='';
  displayDescriptionValue(_v:any): string {
    return eval(this.interpolateTemplate);
  }

  ngOnInit() {
    if( (this.config?.displayField ?? '').toString().startsWith('`') ) {
      this.interpolateTemplate = this.config.displayField.toString();
      this.config.displayField = this.displayDescriptionValue.bind(this);
    }
  }

}
