import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosBaseComponent } from '../../eidos-base.component';
import { EidosAlertDialogComponent } from '../eidos-alert-dialog/eidos-alert-dialog.component';
import { SpinnerOverlayService } from '@app/core/services/spinner-overlay.service';

@Component({
  selector: 'eidos-impersonate-user-dialog',
  styleUrls: [
    'eidos-impersonate-user-dialog.component.scss'
  ],
  templateUrl: 'eidos-impersonate-user-dialog.component.html'
})
export class EidosImpersonateUserDialogComponent extends EidosBaseComponent {

  protected form = new UntypedFormGroup({
    usernameToImpersonate: new UntypedFormControl('', Validators.required)
  });

  constructor(
    private dialogRef: MatDialogRef<EidosImpersonateUserDialogComponent>
    , private utilityService: EidosUtilityService
    , private eidosLogService: EidosLogService
    , private dialog: MatDialog
    , private spinnerOverlayService: SpinnerOverlayService
  ) {
    super();
  }

  protected onCancelClick(): void {
    this.dialogRef.close();
  }

  protected onSaveClick(): void {
    let u = this.form.get('usernameToImpersonate')?.value;

    this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Impersonate', 'Trying to impersonate user ' + u);

    const errorHandler = (error: string) => {
      this.eidosLogService.logDebug(EidosLogSeverity.Warning, 'Impersonate', `Failed to impersonate user ${u}: ${error}`);

        this.dialog.open(EidosAlertDialogComponent, {
          width: '320px',
          data: {
            info: `Failed to impersonate user ${u}: ${error}`
          }
        });
      
        this.spinnerOverlayService.hide();
    };

    this.spinnerOverlayService.show();

    this.utilityService.impersonateStart(u).subscribe({
      next: (result) => {
        if (result === '') {
          this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Impersonate', 'Successfully impersonated user ' + u);
          this.dialogRef.close();
          this.spinnerOverlayService.hide();

        } else {
          errorHandler(result)
        }
      },
      error: (err) => errorHandler(err)
    });
  }
}
