import { Component, Input, OnInit } from '@angular/core';
import { EidosObjectBaseComponent } from '../eidos-object-base/eidos-object-base.component';
import { takeUntil } from 'rxjs/operators';

import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosLogService } from '@common/services/eidos-log.service';
import { IUserInfo } from '@common/models/user-info.interface';
import { EidosLogSeverity } from '@common/models/core-constant.model';
import { IEidosHtmlConfigurationData } from '@common/models/eidos-object.interface';
import { EidosObjectData } from '@common/models/eidos-object.model';

@Component({
  selector: 'eidos-object-html',
  templateUrl: './eidos-object-html.component.html',
  styleUrls: ['./eidos-object-html.component.scss']
})
export class EidosObjectHtmlComponent extends EidosObjectBaseComponent implements OnInit {

  @Input()
  public eidosHtmlConfiguration: IEidosHtmlConfigurationData | undefined;
  @Input()
  public eidosObjectData: EidosObjectData | undefined;

  constructor(
    private eidosUtilityService: EidosUtilityService
    , private eidosSecurityService: EidosSecurityService
    , private eidosLogService: EidosLogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.eidosSecurityService.currentLoggedUser
      .pipe(takeUntil(this.subscription))
      .subscribe((user: IUserInfo) => {
        if (this.eidosHtmlConfiguration) {
          this.eidosHtmlConfiguration.user = user;
        }
      });

    if (this.eidosHtmlConfiguration && !this.eidosHtmlConfiguration.user) {
      const user = this.eidosUtilityService.getCurrentUser();
      if (user !== null) this.eidosHtmlConfiguration.user = user;
    }
    this.eidosLogService.logDebug(EidosLogSeverity.Log, `EidosObjHTML init`, this.eidosHtmlConfiguration);
  }

}
