<div *ngIf="!!fields?.length" class="eidos-light-form-wrapper w-100 d-flex flex-column"
  [ngClass]="fullHeight ? 'h-100 ' + wrapperClass : wrapperClass">
  <h3 *ngIf="!!title">{{ title }}</h3>
  <dx-scroll-view *ngIf="!!scrollable && !oneLineForm; else lightFormTemplate" [scrollByThumb]="true"
    [scrollByContent]="true" [showScrollbar]="showScrollbar">
    <ng-container [ngTemplateOutlet]="lightFormTemplate"> </ng-container>
  </dx-scroll-view>
</div>
<div *ngIf="!fields?.length" class="eidos-light-form-wrapper d-flex justify-content-between">
  <h3 *ngIf="!!title">{{ title }}</h3>
</div>

<ng-template #filtersTemplate let-fields="fields">
  <div *ngFor="let f of fields"
    [ngClass]="f.hidden && f.hidden() ? 'd-none' : singleFieldWidth ? f.width || 'col-3' : 'col'">

    <div *ngIf="!f.hidden || !f.hidden()" class="dx-field ms-0" [ngClass]="{'flex-row': f.horizontalLabel}">

      <div *ngIf="!f.hideLabel" class="dx-field-label">
        <content *ngIf="f.htmlCaption; else plainTextCaptionTemplate" [innerHTML]="getHtmlCaption(f)"></content>
        <ng-template #plainTextCaptionTemplate>
          {{ f.caption ?? parseFieldLabel(f.field) | uppercase }}{{ checkIfFieldIsRequired(f) ? ' *' : ''}}
        </ng-template>

        <ng-container *ngIf="f.popoverMessage">
          <i (mouseenter)="isFieldPopoverVisible[f.field] = true" (mouseleave)="isFieldPopoverVisible[f.field] = false"
            class="fa-solid fa-circle-info popover-message-icon" [id]="'popover-message-id-'+f.field"></i>

          <dx-popover [target]="'#popover-message-id-'+f.field" position="top"
            [(visible)]="isFieldPopoverVisible[f.field]">
            <dxo-animation>
              <dxo-show type="slide" [from]="{ opacity: 0 }" [to]="{ opacity: 1 }"></dxo-show>
              <dxo-hide type="fade" [from]="{ opacity: 1 }" [to]="{ opacity: 0 }"></dxo-hide>

            </dxo-animation>
            <div *dxTemplate="let data = model; of: 'content'">
              {{ f.popoverMessage }}
            </div>
          </dx-popover>
        </ng-container>

      </div>

      <div [ngSwitch]="f.type?.toLowerCase()">

        <div *ngSwitchCase="EidosLightFormFieldType.Text">
          <dx-text-box [(value)]="formValues[f.field]" [showClearButton]="!!f.showClearButton" [valueChangeEvent]="f.triggerChangeOnKeyUp ? 'keyup blur' : 'change'"
            [disabled]="!f.notDisabled && !!f.readOnly && f.readOnly()" [maxLength]="f.maxLength"
            [readOnly]="f.notDisabled && !!f.readOnly && f.readOnly()" [placeholder]="f.placeholder"
            class="dx-field-value" (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Html">
          <angular-editor [(ngModel)]="formValues[f.field]" [config]="HTMLEditorConfigs[f.field]" (ngModelChange)="onValueChanged(f, $event)">
          </angular-editor>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.HtmlDE">
          <dx-html-editor style="background-color: white;" 
           [height]="f.height" [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly())" (onValueChanged)="onValueChanged(f, $event)">
            <dxo-toolbar [multiline]="true">
              <dxi-item name="undo"></dxi-item>
              <dxi-item name="redo"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
                [options]="{ inputAttr: { 'aria-label': 'Font size' } }"></dxi-item>
              <dxi-item name="font" [acceptedValues]="[
                          'Arial',
                          'Tahoma',
                          'Times New Roman',
                          'Verdana'
                        ]" [options]="{ inputAttr: { 'aria-label': 'Font family' } }"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="bold"></dxi-item>
              <dxi-item name="italic"></dxi-item>
              <dxi-item name="strike"></dxi-item>
              <dxi-item name="underline"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="alignLeft"></dxi-item>
              <dxi-item name="alignCenter"></dxi-item>
              <dxi-item name="alignRight"></dxi-item>
              <dxi-item name="alignJustify"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="orderedList"></dxi-item>
              <dxi-item name="bulletList"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="link"></dxi-item>
              <dxi-item name="clear"></dxi-item>
              <dxi-item name="separator"></dxi-item>
            </dxo-toolbar>
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'"></dxi-validation-rule>
            </dx-validator>
          </dx-html-editor>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Textarea">
          <dx-text-area [(value)]="formValues[f.field]" [disabled]="!f.notDisabled && !!f.readOnly && f.readOnly()"
            [readOnly]="f.notDisabled && !!f.readOnly && f.readOnly()" [autoResizeEnabled]="!!f.autoResizeEnabled"
            [minHeight]="f.minHeight" class="dx-field-value" [height]="f.height"
            (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'"></dxi-validation-rule>
            </dx-validator>
          </dx-text-area>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Email">
          <dx-text-box [(value)]="formValues[f.field]" [showClearButton]="!!f.showClearButton"
            [disabled]="(!!f.readOnly && f.readOnly())" class="dx-field-value"
            (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'"></dxi-validation-rule>
              <dxi-validation-rule type="email" message="EMAIL is invalid"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Phone">
          <eidos-input-phone [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly())"
            [required]="checkIfFieldIsRequired(f)" [allowPrefix]="!!f.allowPrefix" [placeholder]="f.placeholder ?? ''"
            [requiredMessage]="f.requiredMessage ?? 'Field is required'" [validationGroupName]="validationGroupName"
            (valueChange)="onValueChanged(f, $event)"></eidos-input-phone>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Number">
          <eidos-input-number [min]="f.min" [max]="f.max" [(value)]="formValues[f.field]" [placeholder]="f.placeholder"
            [disabled]="(!!f.readOnly && f.readOnly())" [required]="checkIfFieldIsRequired(f)"
            [showSpinButtons]="!!f.showSpinButtons" [showClearButton]="!!f.showClearButton" [format]="f.format"
            [requiredMessage]="f.requiredMessage ?? 'Field is required'" [validationGroupName]="validationGroupName"
            (valueChange)="onValueChanged(f, $event)"></eidos-input-number>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Percentage">
          <eidos-input-number [min]="f.min ?? 0" [max]="f.max ?? 100" [(value)]="formValues[f.field]"
            [disabled]="(!!f.readOnly && f.readOnly())" [required]="checkIfFieldIsRequired(f)"
            [showSpinButtons]="!!f.showSpinButtons" [showClearButton]="!!f.showClearButton" [format]="f.format"
            [requiredMessage]="f.requiredMessage ?? 'Field is required'" [validationGroupName]="validationGroupName"
            (valueChange)="onValueChanged(f, $event)"></eidos-input-number>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Checkbox">
          <eidos-input-checkbox [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly())"
            [defaultValue]="false" (valueChange)="onValueChanged(f, $event)">
          </eidos-input-checkbox>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Select">
          <dx-select-box *ngIf="checkIfStructuredOptions(f)" [showClearButton]="!!f.showClearButton"
            [placeholder]="f.placeholder" [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly())"
            [dataSource]="f.options ?? []" valueExpr="value" displayExpr="label"
            (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
          <dx-select-box *ngIf="!checkIfStructuredOptions(f)" [showClearButton]="!!f.showClearButton"
            [placeholder]="f.placeholder" [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly())"
            [items]="f.options ?? []" (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Radio">
          <dx-radio-group *ngIf="checkIfStructuredOptions(f)" [(value)]="formValues[f.field]"
            [disabled]="(!!f.readOnly && f.readOnly())" layout="horizontal" [dataSource]="f.options ?? []"
            valueExpr="value" displayExpr="label" (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
          <dx-radio-group *ngIf="!checkIfStructuredOptions(f)" [(value)]="formValues[f.field]"
            [disabled]="(!!f.readOnly && f.readOnly())" layout="horizontal" [items]="f.options ?? []"
            (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'"></dxi-validation-rule>
            </dx-validator>
          </dx-radio-group>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Date">
          <eidos-input-datetime [placeholder]="f.placeholder ?? ''" [disabled]="(!!f.readOnly && f.readOnly())"
            [dateValueType]="f.format ?? 'date'" [min]="f.minDate ?? defaultMinDate" [max]="f.maxDate ?? defaultMaxDate"
            [disabledDates]="f.disabledDates ?? []" [showClearButton]="f.showClearButton"
            [(value)]="formValues[f.field]" (valueChange)="onValueChanged(f, $event)"
            [validationGroupName]="validationGroupName" [required]="checkIfFieldIsRequired(f)"
            [requiredMessage]="f.requiredMessage ?? 'Field is required'">
          </eidos-input-datetime>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.DateTime">
          <dx-date-box [disabled]="(!!f.readOnly && f.readOnly())" [type]="'datetime'" displayFormat="yyyy/MM/dd HH:mm"
            [(value)]="formValues[f.field]" (valueChange)="onValueChanged(f, $event)" >
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'">
              </dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Time">
          <dx-date-box [disabled]="(!!f.readOnly && f.readOnly())" [type]="'time'" displayFormat="HH:mm"
            [(value)]="formValues[f.field]" (valueChange)="onValueChanged(f, $event)" >
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required"
                [message]="f.requiredMessage ?? 'Field is required'">
              </dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.DateMonths">
        <dx-tag-box [dataSource]="monthFromList" displayExpr="displayValue" valueExpr="value" [placeholder]="f.placeholder" [searchEnabled]="true" [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly())"></dx-tag-box >
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.DateRange">
          <eidos-input-datetimerange layoutDirection="horizontal" [(value)]="formValues[f.field.split(',')[0].trim()]"
            [(otherValue)]="formValues[f.field.split(',')[1].trim()]" [from]="getDateRangeConfig(f, 'from')"
            [to]="getDateRangeConfig(f, 'to')" [min]="f.minDate" [max]="f.maxDate" [showClearButton]="f.showClearButton"
            [required]="f.required" [disabled]="(!!f.readOnly && f.readOnly())"
            (valueChange)="onValueChanged(f, $event, f.field.split(',')[0].trim())"
            (otherValueChange)="onValueChanged(f, $event, f.field.split(',')[1].trim())"
            [requiredMessage]="f.requiredMessage ?? 'Field is required'" [validationGroupName]="validationGroupName">
          </eidos-input-datetimerange>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Selectize">
          <eidos-input-selectize *ngIf="f.selectizeInputConfig" class="dx-field-value w-100"
            [loadingMode]="f.selectizeInputConfig.loadingMode" [items]="f.selectizeInputConfig.items"
            [api]="f.selectizeInputConfig.api" [asyncItems]="f.selectizeInputConfig.asyncItems"
            [(value)]="formValues[f.field]" [onValueChange]="f.selectizeInputConfig.onValueChange"
            [keyField]="f.selectizeInputConfig.keyField" [valueField]="f.selectizeInputConfig.valueField"
            [minSearchLength]="f.selectizeInputConfig.minSearchLength!"
            [showClearButton]="!!f.selectizeInputConfig.showClearButton"
            [searchFields]="f.selectizeInputConfig.searchField" [maxItems]="f.selectizeInputConfig.maxItems"
            [displayField]="f.selectizeInputConfig.displayField"
            [displayFieldTag]="f.selectizeInputConfig.displayFieldTag"
            [displayFieldTagTooltip]="f.selectizeInputConfig.displayFieldTagTooltip"
            [acceptCustomValue]="f.selectizeInputConfig.acceptCustomValue"
            [customItemCreate]="f.selectizeInputConfig.customItemCreate" [disabled]="(!!f.readOnly && f.readOnly())"
            [required]="checkIfFieldIsRequired(f)" [validationGroupName]="validationGroupName"
            [requiredMessage]="f.requiredMessage ?? 'Field is required'"
            [placeholder]="f.selectizeInputConfig.placeholder ?? ''" [config]="f.selectizeInputConfig">
          </eidos-input-selectize>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.PopupListSelector">
          <core-popup-list-selector *ngIf="f.popupListSelectorConfig" class="dx-field-value w-100"
            (onValueChanged)="onValueChanged(f, $event)" [config]="f.popupListSelectorConfig"
            [(value)]="formValues[f.field]">
          </core-popup-list-selector>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Country">
          <dx-select-box [showClearButton]="!!f.showClearButton" [placeholder]="f.placeholder"
            [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly())" [dataSource]="countries"
            [searchEnabled]="true" displayExpr="Country" valueExpr="CountryCod"
            (onValueChanged)="onValueChanged(f, $event)">

            <div *dxTemplate="let data of 'item'">
              <div [title]="data.Country">{{ data.Country }}</div>
            </div>

            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required" message="COUNTRY is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.State">
          <dx-select-box [showClearButton]="!!f.showClearButton" [placeholder]="f.placeholder"
            [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly()) || !states.length"
            [dataSource]="states" [searchEnabled]="true" displayExpr="State" valueExpr="StateISOCod"
            (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required" message="STATE is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.County">
          <dx-select-box *ngIf="!!counties.length" [showClearButton]="!!f.showClearButton" [placeholder]="f.placeholder"
            [(value)]="formValues[f.field]" [disabled]="(!!f.readOnly && f.readOnly())" [dataSource]="counties"
            [searchEnabled]="true" displayExpr="County" valueExpr="County" (onValueChanged)="onValueChanged(f, $event)">
            <dx-validator [validationGroup]="validationGroupName">
              <dxi-validation-rule *ngIf="checkIfFieldIsRequired(f)" type="required" message="COUNTY is required">
              </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
          <dx-text-box *ngIf="!counties.length" [(value)]="formValues[f.field]" [showClearButton]="!!f.showClearButton"
            [maxLength]="f.max" [disabled]="(!!f.readOnly && f.readOnly()) || !formValues.countryCod"
            (onValueChanged)="onValueChanged(f, $event)"></dx-text-box>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.ServiceType">
          <eidos-input-selectize [items]="serviceTypesSelectizeConfig.items"
            [loadingMode]="serviceTypesSelectizeConfig.loadingMode"
            [asyncItems]="serviceTypesSelectizeConfig.asyncItems"
            [searchFields]="serviceTypesSelectizeConfig.searchField"
            [displayField]="serviceTypesSelectizeConfig.displayField" [maxItems]="serviceTypesSelectizeConfig.maxItems"
            [disabled]="(!!f.readOnly && f.readOnly())" [checkUnique]="!!serviceTypesSelectizeConfig.checkUnique"
            [minSearchLength]="serviceTypesSelectizeConfig.minSearchLength ?? 0"
            [keyField]="serviceTypesSelectizeConfig.keyField"
            [onValueChange]="serviceTypesSelectizeConfig.onValueChange"
            [showClearButton]="serviceTypesSelectizeConfig.showClearButton ?? false"
            [placeholder]="serviceTypesSelectizeConfig.placeholder" [(value)]="formValues.serviceType">
          </eidos-input-selectize>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Service">
          <eidos-input-selectize [items]="servicesSelectizeConfig.items"
            [loadingMode]="servicesSelectizeConfig.loadingMode" [asyncItems]="servicesSelectizeConfig.asyncItems"
            [searchFields]="servicesSelectizeConfig.searchField" [displayField]="servicesSelectizeConfig.displayField"
            [maxItems]="servicesSelectizeConfig.maxItems"
            [minSearchLength]="servicesSelectizeConfig.minSearchLength ?? 0"
            [keyField]="servicesSelectizeConfig.keyField" [onValueChange]="servicesSelectizeConfig.onValueChange"
            [showClearButton]="servicesSelectizeConfig.showClearButton ?? false"
            [placeholder]="servicesSelectizeConfig.placeholder" [(value)]="formValues.service">
          </eidos-input-selectize>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Option">
          <eidos-input-selectize [items]="optionsSelectizeConfig.items"
            [loadingMode]="optionsSelectizeConfig.loadingMode" [asyncItems]="optionsSelectizeConfig.asyncItems"
            [searchFields]="optionsSelectizeConfig.searchField" [displayField]="optionsSelectizeConfig.displayField"
            [maxItems]="optionsSelectizeConfig.maxItems" [disabled]="!!optionsSelectizeConfig.disabled"
            [minSearchLength]="optionsSelectizeConfig.minSearchLength ?? 0" [keyField]="optionsSelectizeConfig.keyField"
            [onValueChange]="optionsSelectizeConfig.onValueChange"
            [showClearButton]="optionsSelectizeConfig.showClearButton ?? false"
            [placeholder]="optionsSelectizeConfig.placeholder" [(value)]="formValues.option"
            [config]="optionsSelectizeConfig">
          </eidos-input-selectize>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.File">
          <eidos-upload-file [multiple]="true" [autoUpload]="false" [(files)]="formValues[f.field]"></eidos-upload-file>
        </div>

        <div *ngSwitchCase="EidosLightFormFieldType.Custom">
          <ng-container *ngIf="f.customTemplate?.()" [ngTemplateOutlet]="f.customTemplate?.()"
            [ngTemplateOutletContext]="{formValues, field: f}"></ng-container>
        </div>
        <div *ngSwitchCase="EidosLightFormFieldType.NumberPopup">
          <eidos-input-number-popup [min]="f.numberPopupConfig.min" [max]="f.numberPopupConfig.max"
            [elements]="f.numberPopupConfig.elements" [label]="f.numberPopupConfig.label"
            [value]="buildSelectedValues(f.numberPopupConfig.elements)"
            [onValueChange]="f.numberPopupConfig.onValueChange" width="100%" [disabled]="true">
          </eidos-input-number-popup>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #actionButtonsTemplate>

  <div class="d-flex align-items-end" [ngClass]="{ 'flex-row-reverse': reverseButtons }">

    <div *ngIf="!hideButtons && !submitButtonHidden" class="res-header-popup-search" [ngClass]="submitButtonClass"
      [ngStyle]="{ cursor: submitButtonDisabled ? 'not-allowed' : 'pointer' }">
      <dx-button id="eidos-light-form-submit-button" [useSubmitBehavior]="true" stylingMode="contained"
        [text]="submitButtonText" type="normal" [icon]="submitButtonIcon" [disabled]="submitButtonDisabled">
      </dx-button>
    </div>

    <div *ngIf="!hideButtons && !createButtonHidden" class="res-header-popup-plus" [ngClass]="createButtonClass || submitButtonClass">
      <dx-button stylingMode="contained" [icon]="createButtonIcon" [text]="createButtonText"
        [disabled]="createButtonDisabled" (click)="onCreateBtnClick.emit()">
      </dx-button>
    </div>

    <div *ngIf="!hideButtons && !clearButtonHidden" class="res-header-popup-clear" [ngClass]="clearButtonClass">
      <dx-button stylingMode="contained" icon="clearformat" (click)="clearForm()"> </dx-button>
    </div>

  </div>

</ng-template>

<ng-template #lightFormTemplate>
  <form (submit)="handleFormSubmit($event)" class="eidos-light-form"
    [ngClass]="{ 'overflow-visible': !scrollable || !!oneLineForm }">
    <div [ngSwitch]="mode">
      <div *ngSwitchCase="'default'"
        [ngClass]="oneLineForm ? 'eidos-light-form-one-line' : singleFieldWidth ? 'row' : 'row ' + colsClasses">
        <ng-container [ngTemplateOutlet]="filtersTemplate" [ngTemplateOutletContext]="{
            fields: fields
          }">
        </ng-container>
        <div *ngIf="!oneLineForm && !checkIfAllButtonsAreHidden(); else actionButtonsTemplate"
          class="w-100 d-flex justify-content-end pt-2 action-buttons-wrapper">
          <ng-container [ngTemplateOutlet]="actionButtonsTemplate"> </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'section-accordion'" class="pt-3">
        <dx-accordion *ngIf="!!sections?.length" #accordion [dataSource]="sections" [collapsible]="true"
          [multiple]="true" [animationDuration]="300" [selectedItems]="openedSections" id="accordion-container">
          <div *dxTemplate="let s of 'title'">
            <h1>{{ s }}</h1>
          </div>
          <div *dxTemplate="let s of 'item'" class="row" [ngClass]="!singleFieldWidth ? colsClasses : ''">
            <ng-container [ngTemplateOutlet]="filtersTemplate" [ngTemplateOutletContext]="{
                fields: getFieldsBySection(s)
              }">
            </ng-container>
          </div>
        </dx-accordion>
        <div *ngIf="!checkIfAllButtonsAreHidden()" class="d-flex justify-content-end pt-2">
          <ng-container [ngTemplateOutlet]="actionButtonsTemplate"> </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'section'" class="pt-3">
        <dx-scroll-view [scrollByThumb]="true" [scrollByContent]="true" [showScrollbar]="showScrollbar">
          <div *ngFor="let s of sections" class="py-4">
            <h3 *ngIf="!isEmptyOrWhiteSpace(s)">{{ s | uppercase }}</h3>
            <div class="row" [ngClass]="!singleFieldWidth ? colsClasses : ''">
              <ng-container [ngTemplateOutlet]="filtersTemplate" [ngTemplateOutletContext]="{
                  fields: getFieldsBySection(s)
                }">
              </ng-container>
            </div>
          </div>
        </dx-scroll-view>

        <div *ngIf="!checkIfAllButtonsAreHidden()" class="d-flex justify-content-end pt-2">
          <ng-container [ngTemplateOutlet]="actionButtonsTemplate"> </ng-container>
        </div>
      </div>
      <div *ngSwitchCase="'section-with-separator'" class="pt-3">
        <dx-scroll-view [scrollByThumb]="true" [scrollByContent]="true" [showScrollbar]="showScrollbar">
          <div *ngFor="let s of sections; index as i">
            <div *ngIf="i != 0" class="section-separator"></div>
            <div class="row" [ngClass]="!singleFieldWidth ? colsClasses : ''">
              <ng-container [ngTemplateOutlet]="filtersTemplate" [ngTemplateOutletContext]="{
                  fields: getFieldsBySection(s)
                }">
              </ng-container>
            </div>
          </div>
        </dx-scroll-view>

        <div *ngIf="!checkIfAllButtonsAreHidden()" class="d-flex justify-content-end pt-2">
          <ng-container [ngTemplateOutlet]="actionButtonsTemplate"> </ng-container>
        </div>
      </div>

      <div *ngSwitchCase="'section-with-themed-section-title'">
        <dx-scroll-view [scrollByThumb]="true" [scrollByContent]="true" [showScrollbar]="showScrollbar">
          <div style="margin-bottom: 8px;" *ngFor="let s of sections; index as i">
            <!-- <div *ngIf="i != 0" class="section-separator"></div> -->
            <h4 style="padding: 5px; color: #d9b57f; background-color: #1f1d1e;" *ngIf="!isEmptyOrWhiteSpace(s)">{{ s | uppercase }}</h4>
            <div class="row" [ngClass]="!singleFieldWidth ? colsClasses : ''">
              <ng-container [ngTemplateOutlet]="filtersTemplate" [ngTemplateOutletContext]="{
                  fields: getFieldsBySection(s)
                }">
              </ng-container>
            </div>
          </div>
        </dx-scroll-view>

        <div *ngIf="!checkIfAllButtonsAreHidden()" class="d-flex justify-content-end pt-2">
          <ng-container [ngTemplateOutlet]="actionButtonsTemplate"> </ng-container>
        </div>
      </div>

    </div>
  </form>
</ng-template>
