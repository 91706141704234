<span class="d-flex align-items-center gap-2" *ngIf="user.isValid">
  <span class="eidos-user-bar">
    <span class="eidos-user-bar-text">
      <label>
        {{user.display_name}}
      </label>
    </span>
  </span>
  <eidos-user-avatar class="d-inline-block" [userAvatar]="user.avatar"></eidos-user-avatar>
  <img *ngIf="tempLogo != ''" [src]="tempLogo" height="30px" />
</span>
<eidos-progress-spinner *ngIf="loading"></eidos-progress-spinner>
