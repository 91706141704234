import { IEidosIcon } from "@common/config/environment.interface";
import { IEidosDashboardConfig } from "@common/models/eidos-dashboard.interface";
import { IEidosObject } from "@common/models/eidos-object.interface";
import { EidosObjectActionType, EidosObjectMode } from "@common/models/eidos-object.model";

/**
 * Model of an Eidos dashboard
 *
 * @export
 * @class EidosDashboardConfig
 * @implements {IEidosDashboardConfig}
 */
export class EidosDashboardConfig implements IEidosDashboardConfig {
  enabled: boolean;
  rows: number;
  columns: number;
  columnsGap: number;
  rowsGap: number;
  eidosObject: IEidosObject[];

  constructor(dashboard: any, cells: [any]) {
      this.enabled = false;
      this.rows = 10;
      this.columns = 10;
      this.columnsGap = 3;
      this.rowsGap = 3;
      this.eidosObject = [];
      if (dashboard) {
          this.enabled = true;
          this.columns = dashboard.columns || 10;
          this.rows = dashboard.rows || 10;
          this.columnsGap = dashboard.hGap || 3;
          this.rowsGap = dashboard.vGap || 3;
      }
      if (cells && Array.isArray(cells)) {
          cells.forEach(c => {
              //prepare ne cell
              const objType = c.object_type.charAt(0).toUpperCase() + c.object_type.slice(1);
              const objMode = c.mode.charAt(0).toUpperCase() + c.mode.slice(1);
              this.eidosObject.push({
                  eidosObjectId: -1,
                  mode: EidosObjectMode[objMode as keyof typeof EidosObjectMode],
                  objectType: EidosObjectActionType[objType as keyof typeof EidosObjectActionType],
                  column: c.column,
                  columnSize: c.columnSize,
                  row: c.row,
                  rowSize: c.rowSize,
                  objectId: c.object_id,
                  options: c.options,
                  icon: {
                      iconType: c.iconType || c.IconType,
                      iconCode: c.iconCod,
                      iconSize: c.iconSize,
                      iconValue: c.iconValue || c.IconValue
                  } as IEidosIcon,
                  foregroundColor: c.foregroundColor,
                  backgroundColor: c.backgroundColor,
                  borderRadius: c.borderRadius ?? '0',
                  label: c.label || c.Label,
                  tooltip: c.tooltip || c.Tooltip,
                  loading: false,
                  objectDataPage: 1,
                  pageSize: null,
                  sort: [],
                  filters: []
              });
          });
      }
  }
}