<content *ngIf="config && config.length > 0; else missingConfigTemplate">
  <content *ngIf="results.length > 0; else noResultsTemplate">
    <div class="res-generic-search-results-container row">
      <div class="res-generic-search-product-results-container col-xl-6 col-lg-6 col-sm-12 col-md-12"
        *ngFor="let genericResult of genericResults">

        <content *ngIf="genericResult.tabConfig.identifier !== UNKNOWN_TAB_IDENTIFIER; else unknownTabTemplate">
          <div class="res-generic-search-product-results-wrapper">
            <div class="eidos-generic-search-product-results-header">
              <h1 [style.width]="'90%'">{{genericResult.tabConfig.title}}</h1>
              <dx-button icon="fa fa-angles-right" (click)="selectTab.emit(genericResult.tabConfig.identifier)"></dx-button>
            </div>

            <content *ngIf="resultTemplate; else missingResultTemplate">
              <div class="res-generic-search-product-results-gallery">
                <!-- <dx-gallery width="100%" height="400" id="gallery_{{ genericResult.tabConfig.title}}"
                  [dataSource]="genericResult.results" [initialItemWidth]="200" [showIndicator]="false"
                  [showNavButtons]="true" (onItemClick)="onGenericResultsClick($event)">
                  <ng-template *dxTemplate="let item of 'item'" [ngTemplateOutletContext]="{ item: item }" [ngTemplateOutlet]="resultTemplate">
                  </ng-template>
                </dx-gallery> -->
              </div>
            </content>
            <ng-template #missingResultTemplate>Missing template for generic search results.</ng-template>
          </div>
        </content>

        <ng-template #unknownTabTemplate>
          <div class="res-generic-search-product-results-wrapper">
            <div class="eidos-generic-search-product-results-header">
              <h1 [style.width]="'90%'">{{UNKNOWN_TAB_TITLE}}</h1>
            </div>
            <div class="res-generic-search-product-results-gallery">
              {{ genericResult.results.length }} unknown results.
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </content>
  <ng-template #noResultsTemplate>No results found.</ng-template>
</content>
<ng-template #missingConfigTemplate>Missing or empty search results view configuration!</ng-template>


