import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Paths
import { ErrorRoute } from '@error/models/error-constant.model';

// Pages
import { ErrorComponent } from '@error/error.component';
import { ErrorModuleNotLicensed } from '@error/pages/error-module-not-licensed/error-module-not-licensed.component';
import { ErrorPageNotFoundComponent } from '@error/pages/error-page-not-found/error-page-not-found.component';
import { ErrorPaymentComponent } from '@error/pages/error-payment/error-payment.component';

const routes: Routes = [
  {
    path: '',
    component: ErrorComponent,
    pathMatch: 'prefix',
    children: [
      {
        path: ErrorRoute.ModuleNotLicensed,
        component: ErrorModuleNotLicensed,
        pathMatch: 'full'
      },
      {
        path: ErrorRoute.NotFound,
        component: ErrorPageNotFoundComponent,
        pathMatch: 'full'
      },
      {
        path: ErrorRoute.Payment,
        component: ErrorPaymentComponent,
        pathMatch: 'full'
      },
      {
        // Redirect to ModuleNotLicensed (probably went from lazy module loading error)
        path: '**',
        redirectTo: ErrorRoute.ModuleNotLicensed,
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorRoutingModule { }
