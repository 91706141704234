import { Component, OnInit } from '@angular/core';

import { EidosUtilityService } from '@common/services/eidos-utility.service';

import { EidosFieldBaseComponent } from '@eidos/components/fields/eidos-field-base/eidos-field-base.component';

@Component({
  selector: 'eidos-field-lookup',
  templateUrl: './eidos-field-lookup.component.html',
  styleUrls: ['./eidos-field-lookup.component.scss']
})
export class EidosFieldLookupComponent extends EidosFieldBaseComponent implements OnInit {

  constructor(
    private eidosUtilityService: EidosUtilityService
  ) {
    super()
  }

  ngOnInit(): void {
  }

  getColumnLookupValue(): string {
    if (this.fieldData[this.fieldConfig!.name] !== undefined) {
      const value = this.fieldData[this.fieldConfig!.name];
      if (value == null) return '';

      if (Array.isArray(value)) {
        const lookup = value as Array<any>;
        if (lookup.length == 0) return '';
        const values = lookup.map(l => this.fieldData[l.primary_field_name])
        return values.join('</br>');
      }
      return value;
    };

    if (this.eidosUtilityService.inDebugMode.value) {
      return '???' + this.fieldConfig!.name;
    } else {
      return '';
    }
  }
}
