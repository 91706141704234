import { Component, Input, TemplateRef } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { combineLatest, lastValueFrom, map, Observable, of } from 'rxjs';

import { EidosEntityService } from '@common/services/eidos-entity.service';
import { EidosInputBaseComponent } from '@common/components/entities/inputs/eidos-input-base.component';
import { CoreCacheService } from '@app/core/services/core-cache.service';

import { isValidCachedDataType } from '@app/reservation/models/res-cached-data.models';
import { IEidosInputSelectizeConfig } from '@app/core/models/eidos-inputs.models';

@Component({
  selector: 'eidos-input-selectize',
  templateUrl: './eidos-input-selectize.component.html',
  styleUrls: ['./eidos-input-selectize.component.scss'],
})
export class EidosInputSelectizeComponent<
  S extends EidosEntityService = EidosEntityService
> extends EidosInputBaseComponent<Array<any> | any> {
  /**
   * Service to call search handlers
   *
   * @type {S}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public searchService?: S;
  /**
   * Datasource
   *
   * @type {(Array<any> | DataSource)}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public items: Array<any> | DataSource = [];
  /**
   * Datasource items getter
   *
   * @readonly
   * @type {Array<any>}
   * @memberof EidosInputSelectizeComponent
   */
  get selectizeItems(): Array<any> {
    if (this.items) {
      return Array.isArray(this.items) ? this.items : this.items.items();
    } else {
      return [];
    }
  }
  /**
   * Fields of the datasource obj to search
   *
   * @type {Array<string>}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public searchFields: Array<string> = [];
  /**
   * Field of the datasource obj/Function to display
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public displayField: string | ((item: any) => string) = '';
  /**
   * Field of the datasource obj/Function to display
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public displayFieldTag?: string | ((item: any) => string) = '';
  @Input() public displayFieldTagTooltip?: string | ((item: any) => string) = '';

  /**
   * Field of the datasource obj/Function to sort datasource
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public sortField: string | ((itemA: any, itemB: any) => number) = '';
  /**
   * Value of the datasource obj to search
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public valueField?: string;
  /**
   * Max items selectable
   *
   * @type {number}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public maxItems?: number;
  /**
   * Symbols to type to trigger search
   *
   * @type {number}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public minSearchLength: number = 0;
  /**
   * Symbols to type to trigger search not reached message
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public searchTextTooShortText: string = 'Write to search...';
  /**
   * Loading message
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public loadingText: string = 'Loading';
  /**
   * No items found message
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public noItemsFoundText: string = 'No items found';
  /**
   * Used to retrieve datasource from the server
   * It will be used as incremental when isIncrementalSearch = true
   *
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public asyncItems?: (search?: string, id?: any) => Observable<Array<any>>;
  /**
   * Key field of items collection
   * (required if loadingMode = incremental)
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public keyField?: string = '';
  /**
   * API endpoint to retrieve data
   *
   * @private
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public api?: string;
  /**
   * Specifies if the items must be retrieved from the server and the mode
   * server: the items are retrieved by asyncItems or API at component init
   * incremental: the items are retrieved by API at each search
   * (if incremental, requires keyField to be set)
   *
   * @type {'server' | 'incremental' | 'cached'}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public loadingMode?: 'server' | 'incremental' | 'cached';
  /**
   * No data text
   *
   * @type {string}
   * @memberof EidosInputSelectizeComponent
   */
  public noDataText: string = this.searchTextTooShortText;
  /**
   * Used to handle error on retrieving datasource from the server
   * It will be used on loadingMode = 'server' | 'incremental'
   *
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public asyncItemsError?: (error?: any) => void;
  @Input() public groupTemplate?: TemplateRef<any>;
  @Input() public itemTemplate?: TemplateRef<any>;
  /**
   * Checks if the display property is computed
   *
   * @readonly
   * @memberof EidosInputSelectizeComponent
   */
  public get isDisplayFieldComputed() {
    return typeof this.displayField === 'function' || typeof this.displayFieldTag === 'function';
  }
  getDisplayFieldTagTooltip(data:any) {
    return (typeof this.displayFieldTagTooltip === 'function' ? this.displayFieldTagTooltip(data) : this.displayFieldTagTooltip) ?? '';
  }
  displayTag(item:any):string {
  const def = this.isDisplayFieldTagDefined ? this.displayFieldTag! : this.displayField
   if(typeof def === 'function') return def(item)
   return item[def]
  }
  /**
   * Checks if the display property is computed
   *
   * @readonly
   * @memberof EidosInputSelectizeComponent
   */
  public get isDisplayFieldTagComputed() {
    return this.displayFieldTag ? typeof this.displayFieldTag === 'function' : this.isDisplayFieldComputed;
  }
  /**
   * Checks if the display property is computed
   *
   * @readonly
   * @memberof EidosInputSelectizeComponent
   */
  public get isDisplayFieldTagDefined() {
    return this.displayFieldTag && this.displayFieldTag != '';
  }
  public isItemObject(val: unknown) {
    return val != null && typeof val === 'object';
  }

  /**
   * If active, custom values will be allowed
   *
   * @type {boolean}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public acceptCustomValue: boolean = false;

  /**
   * If active, first and unique value is seleted
   *
   * @type {boolean}
   * @memberof EidosInputSelectizeComponent
   */
  @Input() public checkUnique: boolean = false

  /**
   * Callback for custom value creation
   *
   * @type {EventEmitter<any>}
   * @memberof EidosInputSelectizeComponent
   */
  private _customItemCreate: (e: any) => void = (e: any) => {
    if (!e?.text) return;
    if (this.valueField) {
      e.customItem = { [this.valueField]: e.text };
      return;
    }
    e.customItem = e.text;
  };

  public get customItemCreate(): (e: any) => void {
    return this._customItemCreate;
  }

  @Input()
  public set customItemCreate(value: ((e: any) => void) | undefined) {
    if (value != undefined) {
      this._customItemCreate = value;
    }
  }

  /**
   *
   *
   * @memberof EidosInputSelectizeComponent
   */
  @Input() config?: IEidosInputSelectizeConfig

  @Input() customData: any = undefined;
  /**
   * Selectize customized on value change handler
   *
   * @memberof EidosInputSelectizeComponent
   */
  @Input() override onValueChange?: (component: any, value: any, previousValue?: any) => any;

  itemToPush: any
  /**
   * Items pagination activated flag
   *
   * @private
   * @type {boolean}
   * @memberof EidosInputSelectizeComponent
   */
  private paginateItems: boolean = true;
  /**
   * Items pagination size
   *
   * @private
   * @type {number}
   * @memberof EidosInputSelectizeComponent
   */
  private pageSize: number = 10;

  constructor(protected coreCacheService: CoreCacheService) {
    super();
  }

  interpolateTemplate:string='';
  displayDescriptionValue(_v:any): string {
    return eval(this.interpolateTemplate);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.config) {
      this.config.refresh = () => this.refreshItems();
      this.pageSize = this.config.pageSize ?? 10;
      this.paginateItems = this.config.paginateItems === false ? false : true;
    }

    if( (this.config?.displayField ?? '').toString().startsWith('`') ) {
      this.interpolateTemplate = this.config!.displayField.toString();
      this.config!.displayField = this.displayDescriptionValue.bind(this);
    }

    this.refreshItems();
  }

  refreshItems() {
    switch (this.loadingMode) {
      case 'server':
        if (!!this.asyncItems) {
          // If an async items getter is provided, use it
          this.asyncItems().subscribe({
            next: (response) => {
              this.items = this.sortNewItems(response);
              this.setUniqueValue(this.items);
            },
            error: (error) => {
              if (this.asyncItemsError) {
                this.asyncItemsError(error);
              }
            }
          });
        } else if (!!this.api) {
          // If a dynamic items getter is provided, use it
          if (this.searchService && this.searchService.getDynamicAPI) {
            //MODIFICATO IO
            let dataG: any = undefined;
            if (this.customData !== undefined) {
              dataG = this.customData;
            }
            this.searchService.getDynamicAPI(this.api, dataG).subscribe({
              next: (response) => {
                this.items = this.sortNewItems(response.body);
                this.setUniqueValue(this.items);
              },
              error: (error) => {
                if (this.asyncItemsError) {
                  this.asyncItemsError(error);
                }
              }
            });
          }
        }
        break;
      case 'incremental':
        // If isIncrementalSearch is triggered, keyField must be set and asyncItems or api must be set
        if (!!this.keyField && (!!this.asyncItems || !!this.api)) {
          // Configure incremental items loading
          this.items = new DataSource(
            new CustomStore({
              useDefaultSearch: true,
              key: this.keyField,
              byKey: (key: any) =>
                new Promise<any>(async (resolve) => {

                  const doResolve = async (val: any) => {
                    const selectedItem = !val ? null : !Array.isArray(val) ? val : val.length > 0 ? val[0] : null

                    if (selectedItem && this.maxItems === 1) {
                      this.itemToPush = selectedItem;
                      await (<DataSource>this.items).load()
                    }

                    this.onSelfChangeValue({ value: key })
                    resolve(selectedItem)
                  }

                  if (key !== undefined && ((Array.isArray(key) && key.length > 0) || !Array.isArray(key))) {
                    if (Array.isArray(key) && key.length > 0) {
                      key = key[0];
                    }

                    // If an async items getter is provide, use it
                    if (!!this.asyncItems) {
                      this.asyncItems(undefined, key).subscribe({
                        next: (response) => {
                          doResolve(response);
                        },
                        error: (error) => {
                          if (this.asyncItemsError) {
                            this.asyncItemsError(error);
                          }
                        }
                      });
                    } else if (!!this.api) {
                      if (this.searchService && this.searchService.getDynamicAPI) {
                        this.searchService.getDynamicAPI(this.api, { Id: key }).subscribe({
                          next: (response) => {
                            doResolve(response.body);
                          },
                          error: (error) => {
                            if (this.asyncItemsError) {
                              this.asyncItemsError(error);
                            }
                          }
                        });
                      }
                    }
                  } else {
                    doResolve(null);
                  }
                }),
              load: (loadOptions: LoadOptions) => {
                if (this.itemToPush) {
                  const itemToPush = Object.assign({}, this.itemToPush);
                  this.itemToPush = undefined
                  return new Promise<any>((resolve) => resolve([itemToPush]));
                }
                return this.filterItems(loadOptions.filter);
              },
              onLoading: () => {
                this.noDataText = this.searchTextTooShortText;
              },
              onLoaded: () => {
                this.noDataText = this.noItemsFoundText;
              },
            })
          );
        }

        break;
      case 'cached':
        if (!!this.api && isValidCachedDataType(this.api)) {
          this.coreCacheService.getCachedData<any>(this.api).then(items => {
            this.items = new DataSource({
              store: items,
              paginate: this.paginateItems,
              pageSize: this.pageSize
            });
            this.setUniqueValue(this.items);
          });
        }
        break;
      default:
        // Items are provided by code
        if (Array.isArray(this.items)) {
          this.items = new DataSource({
            store: this.items,
            paginate: this.paginateItems,
            pageSize: this.pageSize
          });
        }
        break;
    }

  }
  /**
   * Sort items retrieved from loading using sortField
   *
   * @private
   * @param {Array<any>} newItems
   * @return {*}
   * @memberof EidosInputSelectizeComponent
   */
  private sortNewItems(newItems: Array<any>) {
    if (this.sortField !== '' && newItems.length > 0) {
      if (typeof this.sortField === "string" && this.sortField in newItems[0]) {
        const sortField = <string>this.sortField;
        newItems.sort((a, b) => {
          if (a[sortField] < b[sortField]) return -1;
          if (a[sortField] > b[sortField]) return 1;
          return 0;
        });
      }
      if (typeof this.sortField === "function") {
        newItems.sort(this.sortField);
      }
    }

    return newItems;
  }
  /**
   * Retrieve items from the server by incremental search
   *
   * @private
   * @param {Array<string>} filter
   * @return {*}
   * @memberof EidosInputSelectizeComponent
   */
  private filterItems(filter: Array<string>) {
    if (filter && Array.isArray(filter) && filter.length > 0) {
      this.noDataText = this.loadingText; // Loading

      /**
       * loadOptions.filter is an array with one item like
       * [ "destinationName", "contains", "las vegas" ]
       * we are interested in the 3rd element of the tuple
       */
      if (filter[0] === this.keyField || filter[0] === this.valueField) {
        if (!!this.asyncItems) {
          // If an async items getter is provided, use it
          return lastValueFrom(this.asyncItems(undefined, filter[2]));
        } else if (!!this.api) {
          if (this.searchService && this.searchService.getDynamicAPI) {
            const valueSubscription = this.searchService
              .getDynamicAPI(this.api, { ID: filter[2] })
              .subscribe({
                next: (response) => response.body,
                error: (error) => {
                  if (this.asyncItemsError) {
                    this.asyncItemsError(error);
                  }
                }
              });

            return lastValueFrom(of(valueSubscription));
          }
        }
      } else {
        const idFilters = filter.filter((f) => f[0] === this.keyField || f[0] === this.valueField);

        if (idFilters.length > 0) {
          let idPromises: Observable<any>[] = [];

          if (!!this.asyncItems) {
            idPromises = idFilters.map((f) => this.asyncItems!(undefined, f[2]));
          } else if (!!this.api && this.searchService) {
            idPromises = idFilters.map((f) =>
              of(
                this.searchService!.getDynamicAPI(this.api!, { Id: f[2] }).subscribe({
                  next: (response) => response.body,
                  error: (error) => {
                    if (this.asyncItemsError) {
                      this.asyncItemsError(error);
                    }
                  }
                })
              )
            );
          }

          return lastValueFrom(combineLatest(idPromises).pipe(map((geoTrees) => geoTrees.flat())));
        } else {
          if (filter[0][2].length >= this.minSearchLength) {
            const filterValue = filter[0][2];

            if (!!this.asyncItems) {
              // If an async items getter is provided, use it
              return lastValueFrom(this.asyncItems(filterValue));
            } else if (!!this.api) {
              if (this.searchService && this.searchService.getDynamicAPI) {
                const valueSubscription = this.searchService
                  .getDynamicAPI(this.api, { SearchText: filterValue })
                  .subscribe({
                    next: (response) => response.body,
                    error: (error) => {
                      if (this.asyncItemsError) {
                        this.asyncItemsError(error);
                      }
                    }
                  });

                return lastValueFrom(of(valueSubscription));
              }
            }
          }
        }
      }
    }
    else if(this.minSearchLength===0 && !!this.asyncItems) {
      return lastValueFrom(this.asyncItems(undefined,undefined));
    }
    this.noDataText = this.searchTextTooShortText;
    return [];
  }
  /**
   * Checks if max selectable items is reached
   *
   * @param {(Array<any> | string)} event
   * @memberof EidosInputSelectizeComponent
   */
  public checkMaxTagsReached(event: Array<any> | string) {
    if (!!event && Array.isArray(event) && this.maxItems !== undefined && event.length > this.maxItems) {
      this.value.shift();
    }
  }

  public setUniqueValue(items: any): void {
    if (this.checkUnique && (items?.length ?? 0) === 1) {
      const itm = items[0];
      let value = !!this.valueField ? itm[this.valueField] : itm;
      this.value = this.maxItems === 1 ? value : (this.maxItems ?? 0) > 1 ? [value] : null
    }
  }
  /**
   * Selectize customized on self change value
   *
   * @template E
   * @param {E} event
   * @memberof EidosInputSelectizeComponent
   */
  override onSelfChangeValue<E extends { value: any, previousValue?: any }>(event: E) {
    if (this.onValueChange != undefined) {
      this.onValueChange(this, event.value, event.previousValue);
    }
  }
}
