<content *ngIf="fieldConfig && fieldData"
  [ngSwitch]="fieldConfig.eidosFieldType">
  <ng-template [ngSwitchCase]="EidosObjectFieldType.Boolean">
    <eidos-field-bit
      [fieldConfig]="fieldConfig"
      [fieldContainer]="fieldContainer"
      [fieldData]="fieldData">
    </eidos-field-bit>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectFieldType.Lookup">
   <eidos-field-lookup
    [fieldConfig]="fieldConfig"
    [fieldContainer]="fieldContainer"
    [fieldData]="fieldData">
  </eidos-field-lookup>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectFieldType.File">
    <eidos-field-file
     [fieldConfig]="fieldConfig"
     [fieldContainer]="fieldContainer"
     [fieldData]="fieldData">
    </eidos-field-file>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectFieldType.Html">
    <eidos-field-html
      [fieldConfig]="fieldConfig"
      [fieldContainer]="fieldContainer"
      [fieldData]="fieldData">
    </eidos-field-html>
  </ng-template>

  <!-- Switch cases must be verbose until multiple cases will be released https://github.com/angular/angular/issues/14659 -->

  <ng-template [ngSwitchCase]="EidosObjectFieldType.Date">
    <eidos-field-datetime
     [fieldConfig]="fieldConfig"
     [fieldContainer]="fieldContainer"
     [fieldData]="fieldData">
    </eidos-field-datetime>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectFieldType.DateTmz">
    <eidos-field-datetime
     [fieldConfig]="fieldConfig"
     [fieldContainer]="fieldContainer"
     [fieldData]="fieldData">
    </eidos-field-datetime>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectFieldType.Time">
    <eidos-field-datetime
     [fieldConfig]="fieldConfig"
     [fieldContainer]="fieldContainer"
     [fieldData]="fieldData">
    </eidos-field-datetime>
  </ng-template>

  <ng-template [ngSwitchCase]="EidosObjectFieldType.TimeTmz">
    <eidos-field-datetime
     [fieldConfig]="fieldConfig"
     [fieldContainer]="fieldContainer"
     [fieldData]="fieldData">
    </eidos-field-datetime>
  </ng-template>

  <ng-template [ngSwitchDefault]>
    <eidos-field-base
     [fieldConfig]="fieldConfig"
     [fieldContainer]="fieldContainer"
     [fieldData]="fieldData">
    </eidos-field-base>
  </ng-template>
</content>
