<div class="eidos-filter-form-commands-wrapper">
  <div *ngIf="!config?.hideSubmit">
    <dx-button #buttonSubmit [ngClass]="[config?.submitCssClass ?? '', 'eidos-filter-form-commands-search-command']"
      [id]="config?.submitCssId"
      [icon]="submitIcon" type="normal" hint="{{ submitLabel | transloco}}"
      (onClick)="onSubmit.emit($event)" [disabled]="!!config?.disabledSubmit">
    </dx-button>
  </div>
  <div *ngIf="!config?.hideClear">
    <dx-button [ngClass]="[config?.clearCssClass ?? '']" [id]="config?.clearCssId"
      icon="clearformat" type="normal" hint="{{ config?.clearLabel ?? 'Clear filters' | transloco }}"
      (onClick)="onClear.emit($event)" [disabled]="!!config?.disabledClear">
    </dx-button>
  </div>
  <div *ngIf="!config?.hideMultiselect">
    <dx-button [ngClass]="[config?.multiselectCssClass ?? '', multiselectEnabled ? 'eidos-filter-form-commands-multiselect-enabled-command' : '']"
      [id]="config?.multiselectCssId" type="normal" stylingMode="outlined"
      text="C" hint="{{ multiselectLabel | transloco }}"
      (onClick)="onMultiselectClick()"
      [disabled]="!!config?.disabledMultiselect">
    </dx-button>
  </div>
</div>
