import { Component, Input } from '@angular/core';
import { IEidosChartJSConfiguration } from '@common/models/eidos-chart-js.interface';
import { EidosChartType, IEidosChartDataset, IEidosChartValue } from '@common/models/eidos-chart-js.model';
import { EidosBaseComponent } from '@common/components/eidos-base.component';

import { IEidosChartConfigurationData, IEidosScalarChartConfigurationData } from '@common/models/eidos-object.interface';

@Component({
  selector: 'eidos-object-chart',
  templateUrl: './eidos-object-chart.component.html',
  styleUrls: ['./eidos-object-chart.component.scss']
})
export class EidosObjectChartComponent extends EidosBaseComponent {
  EidosChartType = EidosChartType;

  @Input()
  public eidosChartConfiguration: IEidosChartConfigurationData | undefined;

  public get eidosChartJSConfiguration(): IEidosChartJSConfiguration {
    if (this.eidosChartConfiguration?.config && this.eidosChartConfiguration.titleResolved) {
      (this.eidosChartConfiguration.config as IEidosChartJSConfiguration).titleResolved = this.eidosChartConfiguration.titleResolved;
    }
    return this.eidosChartConfiguration?.config as IEidosChartJSConfiguration;
  }

  public get eidosChartScalarConfiguration(): IEidosScalarChartConfigurationData {
    return this.eidosChartConfiguration as IEidosScalarChartConfigurationData;
  }

  @Input()
  public eidosChartData: IEidosChartValue | undefined;

  public get eidosChartScalarData(): number {
    return this.eidosChartData as number;
  }

  public get eidosChartDimensionData(): IEidosChartDataset {
    return this.eidosChartData as IEidosChartDataset;
  }

  constructor() {
    super();
  }
}
