<dx-data-grid [id]="gridIdAttribute" [ngClass]="gridClassAttribute" [dataSource]="dataSource" [keyExpr]="config.itemsKey"
  [showBorders]="!!config.showBorders" [rowAlternationEnabled]="!!config.rowAlternationEnabled"
  [showColumnLines]="!!config.showColumnLines" [showRowLines]="!!config.showRowLines"
  [allowColumnReordering]="!!config.allowColumnReordering" [remoteOperations]="true" [columnAutoWidth]="!!config.columnAutoWidth"
  [allowColumnResizing]="!!config.allowColumnResizing" (onRowClick)="_onRowClick($event)"
  (onContentReady)="_onContentReady($event)" (onRowDblClick)="_onRowDblClick($event)" height="100%" width="100%"
  *ngIf="!!config">

  <dxo-scrolling mode="standard"></dxo-scrolling>

  <dxo-load-panel [enabled]="config.enabledLoadPanel ? 'true' : 'false'"></dxo-load-panel>

  <dxo-sorting [mode]="config.sortingMode ?? 'normal'"></dxo-sorting>

  <dxo-selection [mode]="config.selectionMode" *ngIf="!!config.selectionMode"></dxo-selection>

  <dxo-paging [pageSize]="config.pageSize" [enabled]="!config.disablePaging"></dxo-paging>

  <dxo-pager
    [visible]="!config.hidePager" [showPageSizeSelector]="!!config.showPageSizeSelector"
    [showInfo]="!config.hidePagerInfo"
    [allowedPageSizes]="config.allowedPageSizes"> </dxo-pager>

  <!-- Filtri Nello Header -->
  <dxo-header-filter [visible]="!!config.showHeaderFilter"></dxo-header-filter>

  <!-- Filtri di colonna -->
  <dxo-filter-row [visible]="!!config.allowFiltering" [applyFilter]="currentFilter"></dxo-filter-row>

  <!-- Pulsante di Export -->
  <dxo-export [enabled]="!!config.export"></dxo-export>

  <!-- Footer di Summary-->
  <dxo-summary>
    <!-- <dxi-total-item column="CityID" summaryType="sum"></dxi-total-item>
    <dxi-total-item column="CityCmd" summaryType="count"></dxi-total-item> -->
  </dxo-summary>

  <dxi-column *ngFor="let column of config.columns" [dataField]="column.dataField" [caption]="column.caption"
    [alignment]="column.alignment" [format]="column.format" [visible]="column.visible" [dataType]="column.dataType"
    [width]="column.width" [cssClass]="column.cssClass" [fixed]="column.fixed" [fixedPosition]="column.fixedPosition"
    [allowExporting]="column.allowExporting" [allowFiltering]="column.allowFiltering" [allowResizing]="column.allowResizing"
    headerCellTemplate="headerTemplate"
    [cellTemplate]="switchFieldType(column)"
    [editCellTemplate]="switchFieldType(column)">

    <div *dxTemplate="let cellInfo of 'headerTemplate'">
      <div class="eidos-grid-column-header" [innerHTML]="cellInfo.column.caption | safeHtml"></div>
    </div>

    <div *dxTemplate="let cellInfo of 'eidos'">
      <div class="eidos-grid-column-container">
        <div [ngClass]="getRenderingClass(cellInfo)" (click)="onClickEidosCell(cellInfo)">
          <div *ngIf="hasRederingFunction(cellInfo)" [innerHtml]="getRederingValue(cellInfo)"></div>

          <ng-container *ngIf="!hasRederingFunction(cellInfo)">
            <div [ngSwitch]="cellInfo.column.dataType?.toLowerCase()">
              <div *ngSwitchCase="'percentage'">
                {{cellInfo.value}} %
              </div>
              <div *ngSwitchCase="'number'">
                {{cellInfo.value | number: cellInfo.column.format ?? '1.0-2'}}
              </div>
              <div *ngSwitchCase="'date'">
                {{cellInfo.value | date: cellInfo.column.format ?? 'dd MMM yyyy'}}
              </div>
              <div *ngSwitchCase="'boolean'">
                {{cellInfo.value === 1 || cellInfo.value === true || cellInfo.value === 'Y' ? '✓' : ''}}
              </div>
              <div *ngSwitchDefault [title]="paymentTooltip(cellInfo)">
                {{cellInfo.value}}
              </div>
            </div>
          </ng-container>

        </div>
        <div>
          <ng-container *ngFor="let btn of getColumnButtonConfig(cellInfo)">
            <i class="far {{btn.icon}}" [eidosTooltip]="btn.tooltip" style="padding-right: 5px;" (click)="onClickEidosCellButton(btn,cellInfo)"></i>
          </ng-container>
        </div>
      </div>
    </div>
    
    <div *dxTemplate="let cellInfo of 'eidosEdit'">
      <div [ngSwitch]="cellInfo.column.dataType?.toLowerCase()">
        <div *ngSwitchCase="'text'">
          <dx-text-box [(value)]="cellInfo.value"
            class="dx-field-value"
            [showClearButton]="!!cellInfo.column.options?.showClearButton"
            [disabled]="!!cellInfo.column.options?.readOnly"
          ></dx-text-box>
        </div>
        <div *ngSwitchCase="'number'">
          <eidos-input-number [(value)]="cellInfo.value"
            [min]="cellInfo.column.options?.min"
            [max]="cellInfo.column.options?.max"
            [disabled]="!!cellInfo.column.options?.readOnly"
            [showSpinButtons]="cellInfo.column.options?.showSpinButtons"
            [showClearButton]="cellInfo.column.options?.showClearButton"
            [format]="cellInfo.column.options?.format"
          ></eidos-input-number>
        </div>
        <div *ngSwitchCase="'percentage'">
          <eidos-input-number  [(value)]="cellInfo.value"
            [min]="0"
            [max]="100"
            [disabled]="!!cellInfo.column.options?.readOnly"
            [showSpinButtons]="cellInfo.column.options?.showSpinButtons"
            [showClearButton]="cellInfo.column.options?.showClearButton"
            [format]="cellInfo.column.options?.format"
          ></eidos-input-number>
        </div>
        <div *ngSwitchDefault>
          <dx-text-box [(value)]="cellInfo.value"
            class="dx-field-value"
            [disabled]="!!cellInfo.column.options?.readOnly"
          ></dx-text-box>
        </div>
      </div>

    </div>

    <div *dxTemplate="let data of 'buttonCellTemplate'">
      <eidos-grid-button-cell-template
        [data]="data" [buttonsConfig]="config.columns.at(data.columnIndex)?.buttonsConfig ?? []"
        (onButtonClick)="_onCustomButtonClick($event)">
      </eidos-grid-button-cell-template>
    </div>

    <div *dxTemplate="let data of 'linkCellTemplate'">
      <eidos-grid-link-cell-template [data]="data"></eidos-grid-link-cell-template>
    </div>

    <div *dxTemplate="let data of 'fullVisibleTextCellTemplate'">
      <eidos-grid-full-text-template [data]="data"></eidos-grid-full-text-template>
    </div>
  </dxi-column>

  <dxi-column *ngIf="!config.hideSelectorButton" width="50px" cellTemplate="chooserCellTemplate"></dxi-column>
  <div *dxTemplate="let data of 'chooserCellTemplate'">
    <dx-button icon="chevronright" width="100%" hint="Open entity detail" (onClick)="_onCustomButtonClick(data);">
    </dx-button>
  </div>

  <!-- Template x la funzione di Master-Detail -->
  <!-- TODO: Verificare se si può utilizzare ng-content per evitare  scrivere i nuovi detail quan dentro -->
  <dxo-master-detail [enabled]="config.masterDetailEnabled" [template]="config.masterDetailTemplate">
  </dxo-master-detail>
  <div *dxTemplate="let masterDetailItem of 'masterDetailGrid'">
    <eidos-grid *ngIf="!!config.masterDetailGridKey" [config]="config.masterDetailGridConfig"
      [dataSource]="masterDetailItem.data[config.masterDetailGridKey] ?? []">
    </eidos-grid>
  </div>
</dx-data-grid>
