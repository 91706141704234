import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosEnvironment, IEidosIcon, IEidosLoginConfiguration } from '@common/config/environment.interface';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosIconType, EidosLogSeverity, CoreRoute } from '@common/models/core-constant.model';
import { EidosResetCredentialsStatus } from '@common/models/eidos-dialog.interface';

@Component({
  selector: 'eidos-reset-credentials',
  templateUrl: './eidos-reset-credentials.component.html',
  styleUrls: ['./eidos-reset-credentials.component.scss']
})
export class EidosResetCredentialsComponent implements OnInit, OnDestroy, AfterViewInit {
  EidosResetCredentialsStatus = EidosResetCredentialsStatus;
  /**
   * Login configuration
   *
   * @private
   * @type {IEidosLoginConfiguration}
   * @memberof EidosResetCredentialsComponent
   */
  private loginConfiguration: IEidosLoginConfiguration;
  /**
   * Logo icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosTopbarComponent
   */
  public logo: IEidosIcon;
  /**
  * Path to login logo image
  *
  * @type {string}
  * @memberof EidosResetCredentialsComponent
  */
  public logoImagePath: string = '';
  /**
   * Path to login wallpaper image
   *
   * @type {string}
   * @memberof EidosResetCredentialsComponent
   */
  public backgroundImagePath: string = '';
  /**
   * Current status
   *
   * @type {EidosResetCredentialsStatus}
   * @memberof EidosResetCredentialsComponent
   */
  public status: EidosResetCredentialsStatus = EidosResetCredentialsStatus.Entered;
  /**
   * Ok icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosResetCredentialsComponent
   */
  public okIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "check",
    iconSize: 7,
  };
  /**
   * Error icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosResetCredentialsComponent
   */
  public clearIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "clear",
    iconSize: 7,
  };
  /**
   * Time elapsed
   *
   * @type {number}
   * @memberof EidosResetCredentialsComponent
   */
  public timeout: number = 5;
  /**
   * Timeout interval
   *
   * @private
   * @type {(NodeJS.Timeout | undefined)}
   * @memberof EidosResetCredentialsComponent
   */
  private resetCredentialsTimeout: NodeJS.Timeout | undefined;

  constructor(
    private eidosConfigService: EidosConfigService
    , private route: ActivatedRoute
    , private router: Router
    , private eidosUtilityService: EidosUtilityService
    , private eidosLogService: EidosLogService
  ) {
    this.logo = this.eidosConfigService.DEFAULT_CONFIG.logo;
    this.loginConfiguration = this.eidosConfigService.DEFAULT_CONFIG.loginConfiguration;
    this.setupLoginByConfig(this.eidosConfigService.DEFAULT_CONFIG);
  }

  ngOnInit(): void {

    this.route.params
      .pipe(take(1))
      .subscribe(() => {
        this.timeout = -100;
        const resetId = this.route.snapshot.paramMap.get('resetId');
        if (resetId) {
          this.status = EidosResetCredentialsStatus.Requested;
          this.eidosUtilityService.confirmResetCredentials(resetId).subscribe(result => {
            if (!!result) {
              this.status = EidosResetCredentialsStatus.Ok;
              if (this.resetCredentialsTimeout !== undefined) {
                clearInterval(this.resetCredentialsTimeout);
              }
            } else {
              // Log failure
              this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Eidos: password reset confirmation fail for API error');
              this.status = EidosResetCredentialsStatus.Error;
              this.timeout = 5;
            }
          });
        }
      });

    this.eidosConfigService.currentConfig.subscribe(config => {
      this.setupLoginByConfig(config);
    });

    this.resetCredentialsTimeout = setInterval(() => {
      this.timeout = this.timeout > 0 ? this.timeout - 1 : this.timeout;
      if (this.timeout === 0) {
        this.goToLogin();
      }
    }, 1000);
  }

  /**
   * Go to login handler
   *
   * @memberof EidosResetCredentialsComponent
   */
  public goToLogin() {
    this.router.navigate([CoreRoute.Login]);
  }

  /**
   * Setup login component by current config
   *
   * @private
   * @param {IEidosEnvironment} config
   * @memberof EidosResetCredentialsComponent
   */
  private setupLoginByConfig(config: IEidosEnvironment) {
    this.logo = config.logo;

    if (config.loginConfiguration) {
      this.loginConfiguration = config.loginConfiguration;
      this.setupLoginAssets();
    }
  }
  /**
   * Setup custom login images
   *
   * @private
   * @memberof EidosResetCredentialsComponent
   */
  private setupLoginAssets(): void {
    if (this.loginConfiguration?.logoName?.length > 0) {
      this.logoImagePath = this.loginConfiguration.assetsPath + this.loginConfiguration.logoName;
    }
    const homeWallpaper = this.loginConfiguration.wallpaperNames;
    if (homeWallpaper) {
      if (Array.isArray(homeWallpaper)) {
        this.backgroundImagePath = this.loginConfiguration.assetsPath + homeWallpaper[Math.floor(Math.random() * homeWallpaper.length)];
      }
      switch (typeof homeWallpaper) {
        case "string":
          this.backgroundImagePath = this.loginConfiguration.assetsPath + homeWallpaper;
          break;
        default:
          break;
      }
    }
  }

  async ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.resetCredentialsTimeout !== undefined) {
      clearInterval(this.resetCredentialsTimeout);
    }
  }
}