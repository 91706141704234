import { Component } from '@angular/core';
import { EidosUtilityService } from '@common/services/eidos-utility.service';

import { IEidosDashboardConfig } from '@common/models/eidos-dashboard.interface';
import { CoreBaseMainPageComponent } from '@common/core-base-main-page.component';
import { EidosConfigService } from '@common/config/eidos-config.service';

@Component({
  selector: 'app-eidos',
  templateUrl: './eidos.component.html',
  styleUrls: ['./eidos.component.scss']
})
export class EidosComponent extends CoreBaseMainPageComponent {
  /**
   * Dashboard configuration
   *
   * @type {IEidosDashboardConfig}
   * @memberof EidosComponent
   */
  public dashboardConfig: IEidosDashboardConfig | undefined;
  /**
   * Eidos debug mode flag
   *
   * @type {boolean}
   * @memberof EidosComponent
   */
  public inDebugMode: boolean = false;

  constructor(
    protected coreConfigService: EidosConfigService,
    protected coreUtilityService: EidosUtilityService,
  ) {
    super(coreConfigService, coreUtilityService);
    this.moduleName = "eidos";

    this.coreUtilityService.currentDashboard.subscribe((dashboard: IEidosDashboardConfig | undefined) => this.dashboardConfig = dashboard);
    this.coreUtilityService.inDebugMode.subscribe(debug => this.inDebugMode = debug);
  }

  /**
   * Show undefined dashboard info in debug mode
   *
   * @return {*}  {boolean}
   * @memberof EidosHomePageComponent
   */
  public showUndefinedDashboardInfo(): boolean {
    return this.dashboardConfig! && this.dashboardConfig.enabled === false && this.inDebugMode;
  }
}
