import { Component, OnInit } from '@angular/core';
import { EidosInputBaseComponent } from '../eidos-input-base.component';

@Component({
  selector: 'eidos-input-boolean',
  templateUrl: './eidos-input-boolean.component.html',
  styleUrls: ['./eidos-input-boolean.component.scss']
})
export class EidosInputBooleanComponent extends EidosInputBaseComponent<boolean> implements OnInit {
  
  constructor() {
    super();
  }
}