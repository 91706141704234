import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { IEidosObjectMenuAction } from '@common/config/environment.interface';

import { EidosObjectConfiguration, EidosObjectData, EidosObjectEventAction, EidosObjectEventActionRequest, EidosObjectType } from '@common/models/eidos-object.model';
import { EidosObjectService } from '@eidos/services/eidos-object.service';

/**
 * Enumaration of context actions
 *
 * @export
 * @enum {string}
 */
enum EidosContextMenuActionType {
  ReloadObject = 'reloadObject',
  RefreshData = 'refresData',
  ChangeFilters = 'changeFilters',
  ShowHideFilters = 'showHideFilters',
}

@Component({
  selector: 'eidos-context-menu',
  templateUrl: './eidos-context-menu.component.html',
  styleUrls: ['./eidos-context-menu.component.scss']
})
export class EidosContextMenuComponent implements OnInit, AfterContentInit {

  @Input()
  eidosObjectId: number | undefined;

  @Input()
  eidosObject: EidosObjectConfiguration | undefined;

  @Input()
  eidosData: EidosObjectData | undefined;

  /**
   * TODO get label from backend labels
   * (RF 18.02.2022: Labels are taken from i18n at today)
   */
  private basicAction: Array<IEidosObjectMenuAction> = [
    {
      id: EidosContextMenuActionType.ChangeFilters,
      icon: 'filter_alt',
      caption: 'filter',
      objectFilters: ['*'],
      enabled: true,
      status: {}
    },
    {
      id: EidosContextMenuActionType.RefreshData,
      icon: 'refresh',
      caption: 'refresh',
      objectFilters: ['*'],
      enabled: true,
      status: {}
    },
    {
      id: EidosContextMenuActionType.ReloadObject,
      icon: 'published_with_changes',
      caption: 'reload_def',
      objectFilters: ['*'],
      enabled: true,
      status: {}
    }
  ];

  private showHideFiltersAction: IEidosObjectMenuAction = {
    id: EidosContextMenuActionType.ShowHideFilters,
    icon: 'visibility_off',
    caption: 'hide_column_filters',
    iconOff: 'visibility_on',
    captionOff: 'show_column_filters',
    objectFilters: ['Grid'],
    enabled: true,
    status: {}
  }
  /**
  * Displayed menu actions
  *
  * @type {Array<IEidosObjectMenuAction>}
  * @memberof ContextMenuComponent
  */
  public actions: Array<IEidosObjectMenuAction> = [];

  constructor(private eidosObjectService: EidosObjectService) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    if (!this.eidosObject) return;
    if (!this.eidosObjectId) return;

    this.actions.length = 0;
    this.basicAction.filter(a =>
      a.objectFilters.filter(f =>
        f === '*' || EidosObjectType[f as keyof typeof EidosObjectType] === this.eidosObject!.objectType).length > 0)
      .forEach(a =>
        this.actions.push({
          id: a.id,
          icon: a.icon,
          caption: a.caption,
          objectFilters: [],
          enabled: a.enabled,
          status: {}
        })
      );

    switch (this.eidosObject.objectType) {
      case EidosObjectType.Grid:
        this.eidosObjectService
          .sendQueryEvent(this.eidosObjectId, EidosObjectEventAction.ToggleFieldFilters, EidosObjectEventActionRequest.Enquery, {})
          .then(e => {
            if (e.actionMessage) {
              const a = this.showHideFiltersAction;
              const status = e.actionMessage.data?.status ? true : false;
              this.setOnOffMenuItem(status, {
                id: a.id,
                icon: status && a.iconOff ? a.iconOff : a.icon,
                caption: status && a.captionOff ? a.captionOff : a.caption,
                objectFilters: [],
                enabled: a.enabled,
                status: {}
              });
            }
          });
        break;
    }
  }

  private setOnOffMenuItem(status: boolean, action: IEidosObjectMenuAction) {
    const item =
    {
      id: action.id,
      icon: status && action.iconOff ? action.iconOff : action.icon,
      caption: status && action.captionOff ? action.captionOff : action.caption,
      objectFilters: [],
      enabled: action.enabled,
      status: {}
    }
    const index = this.actions.findIndex((a) => a.id === item.id);

    if (index === -1) {
      this.actions.push(item);
    } else {
      this.actions[index] = item;
    }
  }

  execAction(action: IEidosObjectMenuAction) {
    if (!this.eidosObjectId) return;

    switch (action.id) {
      case EidosContextMenuActionType.ShowHideFilters:
        action.status.hideColumnFilter = !action.status.hideColumnFilter;
        this.eidosObjectService.sendQueryEvent(this.eidosObjectId, EidosObjectEventAction.ToggleFieldFilters, EidosObjectEventActionRequest.Trigger, {})
          .then(e => {
            if (e.actionMessage) {
              const a = this.showHideFiltersAction;
              const status = e.actionMessage.data?.status ? true : false;
              this.setOnOffMenuItem(status, {
                id: a.id,
                icon: status && a.iconOff ? a.iconOff : a.icon,
                caption: status && a.captionOff ? a.captionOff : a.caption,
                objectFilters: [],
                enabled: a.enabled,
                status: {}
              });
            }
          });
        break;
      case EidosContextMenuActionType.RefreshData:
        this.eidosObjectService.sendQueryEvent(this.eidosObjectId, EidosObjectEventAction.ReloadData, EidosObjectEventActionRequest.Trigger, {});
        break;
      case EidosContextMenuActionType.ReloadObject:
        this.eidosObjectService.sendQueryEvent(this.eidosObjectId, EidosObjectEventAction.ReloadObject, EidosObjectEventActionRequest.Trigger, {});
        break;
    }
  }
}
