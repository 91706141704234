<div class="eidos-filter-wrapper eidos-number-popup-filter-wrapper">
  <p class="eidos-filter-label eidos-number-popup-filter-label" *ngIf="config.label">{{ config.label }}</p>

  <eidos-input-number-popup [cssClass]="cssClass" [cssId]="cssId"
    [min]="config.min" [max]="config.max"
    [elements]="config.elements"
    [(value)]="value" (valueChange)="config.onValueChange"
    [disabled]="!!config.disabled" [placeholder]="config.placeholder"
    (onFocusIn)="(!!config.onFocusIn && config.onFocusIn())"
    (onFocusOut)="(!!config.onFocusOut && config.onFocusOut())"></eidos-input-number-popup>
</div>