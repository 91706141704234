import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoreFormatService } from '@app/core/services/core-format.service';
import { IEidosFiltersRegistry } from '@common/models/eidos-filters.models';
import { EidosFilterComponent } from '../eidos-filter/eidos-filter.component';

@Component({
  selector: 'eidos-filter-datetime',
  templateUrl: './eidos-filter-datetime.component.html',
  styleUrls: ['./eidos-filter-datetime.component.scss']
})
export class EidosFilterDateTimeComponent extends EidosFilterComponent {
  /**
   * Current filter value (2-way binding)
   *
   * @type {*}
   * @memberof EidosFilterDateTimeComponent
   */
  public _value: any;
  @Input() public get value(): any {
    return !!this.config?.formatDateToApi ? CoreFormatService.FormatDateToApi(this._value) : this._value;
  }
  public set value(val: any) {
    if (typeof val === "string" && this.config.formatDateToApi) {
      //if (typeof val === "string" ) {
      this._value = CoreFormatService.ApiFormatToDate(val);
    } else {
      this._value = val;
    }
    this.valueChange.emit(this.value);
  }

  @Output()
  public valueChange: EventEmitter<IEidosFiltersRegistry["date"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["date"]["valueType"]>();
  /**
   * Current real input value (2-way binding) to pass to the filter value setter
   *
   * @type {*}
   * @memberof EidosFilterSelectizeComponent
   */
  public get realValue(): any {
    return this._value;
  }
  public set realValue(val: any) {
    // Calls the setter to retrieve the value in the correct format
    this.value = val;
    this.realValueChange.emit(this._value);
  }
  @Output()
  public realValueChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["daterange"]["config"]}
   * @memberof EidosFilterDateTimeComponent
   */
  @Input()
  public config: IEidosFiltersRegistry["date"]["config"];

  constructor() {
    super();
  }
}
