import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosIconType, EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { EidosResetCredentialsSnackbarData, EidosResetCredentialsStatus } from '@common/models/eidos-dialog.interface';
import { IEidosIcon } from '@common/config/environment.interface';

@Component({
  selector: 'eidos-reset-credentials-dialog',
  styleUrls: [
    'eidos-reset-credentials-dialog.component.scss'
  ],
  templateUrl: 'eidos-reset-credentials-dialog.component.html'
})
export class EidosResetCredentialsDialogComponent implements OnInit, OnDestroy {
  EidosResetCredentialsStatus = EidosResetCredentialsStatus;
  /**
   * Form
   *
   * @memberof EidosResetCredentialsDialogComponent
   */
  public form = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.required)
  });
  /**
   * Current status
   *
   * @type {EidosResetCredentialsStatus}
   * @memberof EidosResetCredentialsDialogComponent
   */
  public status: EidosResetCredentialsStatus = EidosResetCredentialsStatus.Entered;
  /**
 * Ok icon configuration
 *
 * @type {IEidosIcon}
 * @memberof EidosResetCredentialsDialogComponent
 */
  public okIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "check",
    iconSize: 2,
  };
  /**
   * Error icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosResetCredentialsDialogComponent
   */
  public clearIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "clear",
    iconSize: 2,
  };

  constructor(
    private dialogRef: MatDialogRef<EidosResetCredentialsDialogComponent>
    , private eidosUtilityService: EidosUtilityService
    , private eidosLogService: EidosLogService
  ) {
  }

  ngOnInit() {
  }

  /**
   * Cancel button handler
   *
   * @memberof EidosResetCredentialsDialogComponent
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }

  /**
   * Submit handler
   *
   * @return {*}  {void}
   * @memberof EidosResetCredentialsDialogComponent
   */
  onSubmit(): void {
    let username = this.form.get('username');

    if (!!username?.value) {
      this.eidosLogService.logDebug(EidosLogSeverity.Log, "Eidos: requested password reset for user ", username);
      this.status = EidosResetCredentialsStatus.Requested;
      this.eidosUtilityService.resetCredentials(username.value).subscribe(result => {
        if (!!result) {
          this.status = EidosResetCredentialsStatus.Ok;

          // this.snackBar.openFromComponent(EidosResetCredentialsSnackbarComponent, {
          //   duration: 5000,
          //   data: {
          //     response: this.translocoService.translate("login.reset_credentials.requested")
          //   } as EidosResetCredentialsSnackbarData
          // });
          // this.dialogRef.close();

        } else {
          // Log failure
          this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Eidos: password reset fail for API error (Maybe too many request from user?)');
          this.status = EidosResetCredentialsStatus.Error;
        }
      });
    }
  }
  /**
   * Retry to reset credentials after fail
   *
   * @memberof EidosResetCredentialsDialogComponent
   */
  public onRetry(): void {
    let username = this.form.get('username');
    username?.reset();
    this.status = EidosResetCredentialsStatus.Entered;
  }

  ngOnDestroy() {
  }
}

/**
 * SnackBar for credentials request confirmation
 *
 * @export
 * @class EidosResetCredentialsSnackbarComponent
 */
@Component({
  selector: 'eidos-reset-credentials-snackbar-component',
  template: '<content [innerHTML]="data.response"></content>',
})
export class EidosResetCredentialsSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: EidosResetCredentialsSnackbarData) { }
}