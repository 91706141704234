import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EidosInputBaseComponent } from '../eidos-input-base.component';

/**
 * https://js.devexpress.com/Documentation/ApiReference/Common/Object_Structures/format/#type
 */
export type ICoreInputNumberFormatType = 'billions' | 'currency' | 'day' | 'decimal' | 'exponential' | 'fixedPoint' | 'largeNumber' | 'longDate' | 'longTime' | 'millions' | 'millisecond' | 'month' | 'monthAndDay' | 'monthAndYear' | 'percent' | 'quarter' | 'quarterAndYear' | 'shortDate' | 'shortTime' | 'thousands' | 'trillions' | 'year' | 'dayOfWeek' | 'hour' | 'longDateLongTime' | 'minute' | 'second' | 'shortDateShortTime';

@Component({
  selector: 'eidos-input-number',
  templateUrl: './eidos-input-number.component.html',
  styleUrls: ['./eidos-input-number.component.scss']
})
export class EidosInputNumberComponent extends EidosInputBaseComponent<number> implements OnInit {
  /**
   * Input label
   *
   * @type {string}
   * @memberof EidosInputNumberComponent
   */
  @Input() public label?: string;
  /**
   * Input format
   *
   * @type {(string | ICoreInputNumberFormatType)}
   * @memberof EidosInputNumberComponent
   */
  @Input() public format?: string | ICoreInputNumberFormatType | ((value: number) => string);
  /**
   * Max value
   *
   * @type {(number | string)}
   * @memberof EidosInputNumberComponent
   */
  @Input()
  public max?: number;
  /**
   * Min value
   *
   * @type {(number | string)}
   * @memberof EidosInputNumberComponent
   */
  @Input()
  public min?: number;

  @Input() public showSpinButtons: boolean = false;

  @Output() public onKeyDown: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }
  public keyDown(event: any) {
    this.onKeyDown.emit(event);
  }
}
