import { DateTime } from "luxon";
import { interval, map, Subscription, take } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { ReservationApiService } from "../services/reservation-api.service";
import { ReservationService } from "../services/reservation.service";
import { CoreUtilityDialog, EidosUtilityDialogDialogType } from "@app/core/services/eidos-utility.service";
import { DynamicApiResponse } from "@app/core/models/dynamic-api-response.model";
import { SafeBaseObject } from "@app/core/models/base-object.models";
import { ResBooking } from "./res-booking.model";

interface IReservationApiLock {
  Data: IReservationApiLockData;
  Timing: IReservationApiLockTiming;
}

interface IReservationApiLockData {
  BkgID: number;
  Action: string;
  User: string;
  Status: string;
  DateLock?: DateTime;
  DateUnlock?: DateTime;
  Result: string;
  Message: string;
}
interface IReservationApiLockTiming {
  SecondsLeft: number;
}
class ReservationBookingLockResponse extends SafeBaseObject {

  bkgID: number = ResBooking.INVALID_BOOKING;
  action: string = '';
  user: string = '';
  status: string = '';
  dateLock?: DateTime;
  dateUnlock?: DateTime;
  result: string = '';
  message: string = '';
  secondsLeft: number = 0;

  isValid(): boolean {
    return this.bkgID != ResBooking.INVALID_BOOKING;
  }

  constructor(response?: IReservationApiLock) {
    super();
    if (response) {
      this.updateData(response);
    }
  }

  override updateData(response: IReservationApiLock): void {
    this.addMangledProperty(response.Data);
    this.addMangledProperty(response.Timing);
    this.addDateTimeProperty('dateLock', response.Data, 'DateLock');
    this.addDateTimeProperty('dateUnlock', response.Data, 'DateUnlock');
  }
}

export class ReservationBookingLock {
  /**
   * Set variable as true to reduce lock duration for test purposes
   *
   * @private
   * @type {boolean}
   * @memberof ReservationBookingLock
   */
  static isTest = false; // reduce lock duration for test purposes
  static EXPIRE_REQUEST_THRESHOLD = 60 * 5;
  static DURATION_TEST = 60 * 1;
  static EXPIRE_REQUEST_THRESHOLD_TEST = 30;
  /**
   * Lock timer subscription
   *
   * @private
   * @type {(Subscription | undefined)}
   * @memberof ReservationBookingLock
   */
  private timer: Subscription | undefined;
  /**
   * Lock timer Synchronization
   *
   * @private
   * @type {(Subscription | undefined)}
   * @memberof ReservationBookingLock
   */
  private timerSynchronization: Subscription | undefined;

  private extendLockEnabled = false;
  /**
   * Current booking ID
   *
   * @memberof ReservationBookingLock
   */
  public bkgID = ResBooking.INVALID_BOOKING;
  /**
   * The booking has been retrieved with anonymous access
   * Skip lock in anon access
   *
   * @type {boolean}
   * @memberof ReservationBookingLock
   */
  public bookingRetrievedInAnonymous: boolean = false;
  /**
   * The booking has been retrieved by a read only user
   * Skip lock in read only mode
   *
   * @type {boolean}
   * @memberof ReservationBookingLock
   */
  public bookingRetrievedInReadOnly: boolean = false;
  /**
   * The lock must be automatically extended
   *
   * @private
   * @type {boolean}
   * @memberof ReservationBookingLock
   */
  private toBeAutoExtended: boolean = false;
  /**
   * Unlock current message
   *
   * @type {BehaviorSubject<string>}
   * @memberof ReservationBookingLock
   */
  public unlockMessage: BehaviorSubject<string> = new BehaviorSubject<string>("Unlocked");
  /**
   * Lock time left
   *
   * @private
   * @type {number}
   * @memberof ReservationBookingLock
   */
  private _secondsLeft: number = 0;
  public get secondsLeft(): number {
    return this._secondsLeft;
  }
  public set secondsLeft(val: number) {
    this._secondsLeft = val;
    this.lockTime.next(this.secondsLeft);
  }
  /**
   * Moment of lock start
   *
   * @private
   * @type {(Date | undefined)}
   * @memberof ReservationBookingLock
   */
  private _timeStart: Date | undefined = undefined;
  /**
   * Lock visibility
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof ReservationBookingLock
   */
  public lockVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Lock current status
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof ReservationBookingLock
   */
  public lockStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Lock current status
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof ReservationBookingLock
   */
  public lockByOther: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
  * Lock current time
  *
  * @type {BehaviorSubject<number>}
  * @memberof ReservationBookingLock
  */
  public lockTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  /**
   * Lock loading
   *
   * @type {BehaviorSubject<boolean>}
   * @memberof ReservationBookingLock
   */
  public lockLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Can the booking be locked by the user
   *
   * @readonly
   * @type {boolean}
   * @memberof ReservationBookingLock
   */
  get isUserAllowedToLock(): boolean {
    return !this.bookingRetrievedInReadOnly && !this.bookingRetrievedInAnonymous;
  }

  constructor(
    public resApiService: ReservationApiService,
    public dialogs: CoreUtilityDialog,
    public reservationService: ReservationService,
  ) {
  }
  /**
   * Check if locked is currently active and if the booking locked is current
   *
   * @param {number} [bkgID]
   * @return {*}  {boolean}
   * @memberof ReservationBookingLock
   */
  isLocked(bkgID?: number): boolean {
    if (bkgID && bkgID !== this.bkgID) {
      return false;
    } else {
      return this.isUserAllowedToLock ? this.lockStatus.getValue() : false;
    }
  }
  /**
 * Check if locked is currently active and if the booking locked is current
 *
 * @param {number} [bkgID]
 * @return {*}  {boolean}
 * @memberof ReservationBookingLock
 */
  isLockedByOther(bkgID?: number): boolean {
    if (bkgID && bkgID !== this.bkgID) {
      return false;
    } else if (this.isUserAllowedToLock && !this.lockStatus.getValue()) {
      return this.lockByOther.getValue();
    }
    return false;
  }
  /**
   * Show the lock viewer
   *
   * @memberof ReservationBookingLock
   */
  show() {
    this.lockVisibility.next(true);
  }
  /**
   * Hide the lock viewer
   *
   * @memberof ReservationBookingLock
   */
  hide() {
    this.unlock(); //if not visible is not locked
    this.lockVisibility.next(false);
  }
  /**
   * Relock the current booking or lock a booking which lock is expired
   *
   * @param {number} [bkgID] ID of Booking to relock after expiration
   * @memberof ReservationBookingLock
   */
  relock(bkgID?: number, force?: boolean) {

    if (!bkgID) {
      bkgID = this.bkgID;
    }

    this.lock(bkgID, force);
  }
  /**
   * Lock or relock a booking
   *
   * @param {number} [bkgID] ID of booking to lock
   * @memberof ReservationBookingLock
   */
  lock(bkgID: number, force?: boolean) {

    this.bkgID = bkgID;

    if (bkgID != ResBooking.INVALID_BOOKING && this.isUserAllowedToLock) {

      const prms = { BkgID: bkgID, Action: 'H' } as any;
      if (force) {
        prms.SuperUserFlg = 'Y';
        prms.Action = 'R';
      }
      this.lockLoading.next(true);
      this.resApiService.postDynamicAPI('booking/lock', prms).pipe(
        map<DynamicApiResponse, ReservationBookingLockResponse>((response) => {
          return new ReservationBookingLockResponse(response.body);
        })
      ).subscribe({
        next: (response: ReservationBookingLockResponse) => {

          if (response.result === 'OK') {
            this.lockStatus.next(true);
            this.lockByOther.next(false);
            this.start(response);
          } else {
            if (response.result === 'KO' && response.message.includes('locked')) {
              this.lockByOther.next(true);
            }
            this.stop(response.message);
          }
        },
        error: () => {
          this.stop();
          this.lockLoading.next(false);
        },
        complete: () => {
          this.lockLoading.next(false);
        }
      });
    }
  }
  /**
   * Extend the lock of the current booking
   *
   * @memberof ReservationBookingLock
   */
  extend() {
    if (this.bkgID == ResBooking.INVALID_BOOKING) {
      this.bkgID = this.reservationService.currentBooking.bkgID;
    }
    if (this.bkgID != ResBooking.INVALID_BOOKING && this.isUserAllowedToLock) {

      this.lockLoading.next(true);

      this.resApiService.postDynamicAPI('booking/lock', { BkgID: this.bkgID, Action: 'U' }).pipe(
        map<DynamicApiResponse, ReservationBookingLockResponse>((response) => {
          return new ReservationBookingLockResponse(response.body);
        })
      ).subscribe({
        next: (response: ReservationBookingLockResponse) => {

          if (response.result === 'OK') {

            this.secondsLeft = response.secondsLeft;
            this.extendLockEnabled = true;

            if (!this.lockStatus.getValue()) {
              this.lockStatus.next(true);
              this.lockByOther.next(false);
              this.start(response);
            }
          } else {
            this.unlockMessage.next(response.message);
          }
        },
        error: () => {
          this.stop("Error in lock setting");
          this.lockLoading.next(false);
        },
        complete: () => {
          this.lockLoading.next(false);
        }
      });
    }
  }
  /**
   * Unlock the current booking
   *
   * @memberof ReservationBookingLock
   */
  unlock() {
    if (this.bkgID != ResBooking.INVALID_BOOKING && this.isUserAllowedToLock) {

      // Reset current locked booking ID
      const bkgID = this.bkgID;
      this.bkgID = ResBooking.INVALID_BOOKING;

      this.lockLoading.next(true);

      this.resApiService.postDynamicAPI('booking/lock', { BkgID: bkgID, Action: 'R' }).pipe(
        map<DynamicApiResponse, ReservationBookingLockResponse>((response) => {
          return new ReservationBookingLockResponse(response.body);
        })
      ).subscribe({
        next: (response: ReservationBookingLockResponse) => {
          if (response.result === 'OK') {
            this.lockStatus.next(false);
            this.stop("Unlocked");
          }
        },
        error: () => {
          this.stop("Error in lock setting");
          this.lockLoading.next(false);
        },
        complete: () => {
          this.lockLoading.next(false);
        }
      });
    }
  }
  /**
   * Start the booking lock after a successful response
   *
   * @private
   * @param {ReservationBookingLockResponse} response
   * @memberof ReservationBookingLock
   */
  private start(response: ReservationBookingLockResponse) {

    this.secondsLeft = ReservationBookingLock.isTest ? ReservationBookingLock.DURATION_TEST : response.secondsLeft;
    this.bkgID = response.bkgID;
    this.extendLockEnabled = true;
    this.timerSynchronization?.unsubscribe();
    this.timer?.unsubscribe();

    const lockSeconds = this.secondsLeft;

    this._timeStart = new Date();

    // Synchronize the timer every (lockSeconds / 4) seconds
    this.timerSynchronization = interval(Math.floor(lockSeconds / 4) * 1000).pipe(take(5)).subscribe(() => {
      const count = +(new Date()) - +(this._timeStart!);
      this.secondsLeft = lockSeconds - (Math.floor(count / 1000));
    });

    this.timer = interval(1000).subscribe(() => {
      this.secondsLeft -= 1;
      if (this.extendLockEnabled && this.secondsLeft < (ReservationBookingLock.isTest ? ReservationBookingLock.EXPIRE_REQUEST_THRESHOLD_TEST : ReservationBookingLock.EXPIRE_REQUEST_THRESHOLD)) {
        this.extendLockEnabled = false;
        this.dialogs.confirm({
          dialogType: EidosUtilityDialogDialogType.Warning,
          message: 'Do you want to extend booking lock time?',
          title: 'LOCK EXTEND REQUEST'
        }).then(confirm => {
          if (confirm) {
            this.extend();
          }
        });
      }
      if (this.secondsLeft < 1) {
        this.extendLockEnabled = true;

        if (this.toBeAutoExtended) {
          this.toBeAutoExtended = false;
          this.start(response);
        } else {
          this.lockStatus.next(false);
          this.stop("Unlocked");
        }
      }
    });
  }
  /**
   * Stop the lock after time expiration or an error response
   *
   * @private
   * @param {string} [stopMessage]
   * @memberof ReservationBookingLock
   */
  private stop(stopMessage?: string) {
    this.secondsLeft = 0;
    this.bkgID = ResBooking.INVALID_BOOKING;
    if (this.lockStatus.getValue()) {
      this.lockStatus.next(false);
    }
    if (stopMessage) {
      this.unlockMessage.next(stopMessage);
    }
    this.timer?.unsubscribe();
    this.timerSynchronization?.unsubscribe();
    this.timer = undefined;
    this.timerSynchronization = undefined;
    this._timeStart = undefined;
    this.extendLockEnabled = false;
  }
}
