<content *ngIf="config; else missingConfigTemplate">
  <content *ngIf="searchService; else missingSearchServiceTemplate">
    <content *ngIf="hasTabs; else singleTabSearch">
      <dx-tab-panel
        height="100%"
        [(selectedIndex)]="selectedTab"
        (onSelectionChanged)="selectTab($event)"
      >
        <dxi-item
          *ngFor="let tab of config | cast_iterable: C"
          [title]="tab.title"
          [icon]="tab.icon"
          height="100%"
        >
          <div *dxTemplate class="h-100">
            <eidos-search-tab
              #searchTab
              [coreSearchService]="coreSearchService"
              [filterTemplate]="filterTemplate"
              [genericTabConfig]="genericTabConfig"
              [searchService]="searchService"
              [(filters)]="filters"
              [config]="tab"
              (onContentReady)="_onContentReady($event)"
              (onSelectionChanged)="onSelectionChanged.emit($event)"
              (onButtonClick)="_onButtonClick($event)"
              (onResetFilters)="resetFilters()"
              (selectTab)="selectTabFromGenericResults($event)"
              [searchPagingConfiguration]="searchPagingConfiguration"
              [isResultCheckable]="isResultCheckable"
              (onResultsSelected)="onResultsSelected.emit($event)"
            >
            </eidos-search-tab>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </content>
  </content>

  <ng-template #singleTabSearch>
    <eidos-search-tab
      [coreSearchService]="coreSearchService"
      [cssClass]="cssClass"
      [cssId]="cssId"
      [filterTemplate]="filterTemplate"
      [config]="config | cast: C"
      [(filters)]="filters"
      (onContentReady)="_onContentReady($event)"
      (onResetFilters)="resetFilters()"
      (onButtonClick)="_onButtonClick($event)"
      [searchService]="searchService"
      [searchPagingConfiguration]="searchPagingConfiguration"
      [isResultCheckable]="isResultCheckable"
      (onSelectionChanged)="onSelectionChanged.emit($event)"
      (onResultsSelected)="onResultsSelected.emit($event)"
    >
    </eidos-search-tab>
  </ng-template>
  <ng-template #missingSearchServiceTemplate
    >Missing search service configuration!</ng-template
  >
</content>

<ng-template #missingConfigTemplate>Missing search configuration!</ng-template>
