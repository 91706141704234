import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { TooltipPosition } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosIcon } from '@common/config/environment.interface';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosIconType } from '../../models/core-constant.model';
import { EidosBaseComponent } from '../eidos-base.component';

@Component({
  selector: 'eidos-icon',
  templateUrl: './eidos-icon.component.html',
  styleUrls: ['./eidos-icon.component.scss']
})
export class EidosIconComponent extends EidosBaseComponent implements OnInit {

  EidosIconType = EidosIconType;

  /**
   * Icon configuration
   *
   * @type {(IEidosIcon | null)}
   * @memberof EidosIconComponent
   */
  @Input() set iconConfiguration(value: IEidosIcon | null) {
    this._iconConfiguration = value;
    this.setMyBizIcon();
  }

  get iconConfiguration(): IEidosIcon | null {
    return this._iconConfiguration;
  }

  private _iconConfiguration: IEidosIcon | null = null;

  /**
   * Icon tooltip
   *
   * @type {string}
   * @memberof EidosIconComponent
   */
  @Input('tooltip')
  public iconTooltip: string | undefined;
  /**
   * Position of the tooltip
   *
   * @memberof EidosIconComponent
   */
  @Input('tooltipPosition')
  public iconTooltipPosition: TooltipPosition = "below";
  /**
   * Icon badge real value
   *
   * @type {(string | number | undefined)}
   * @memberof EidosIconComponent
   */
  @Input('badge')
  public _iconBadge: string | number | undefined;
  /**
   * Icon badge displayed value
   * (used to handle 0 as undefined)
   *
   * @readonly
   * @type {(number | undefined)}
   * @memberof EidosIconComponent
   */
  public get iconBadge(): string | number | undefined {
    return !this._iconBadge ? undefined : this._iconBadge;
  }
  /**
   * Icon clickable flag
   *
   * @type {(boolean | undefined)}
   * @memberof EidosIconComponent
   */
  @Input('clickable')
  public iconClickable: boolean | undefined;
  /**
   * Icon rotation degrees
   *
   * @type {(number | undefined)}
   * @memberof EidosIconComponent
   */
  @Input('rotate')
  public iconRotate: number = 0;

  /**
   * Get icon size as string
   *
   * @deprecated Use getter method for X-Y scale + rotation transform
   * @return {*}  {string}
   * @memberof EidosIconComponent
   */
  getIconSize(): string {
    const scale = (this.iconConfiguration?.iconSize || 1);
    return scale + 'em';
  }

  /**
   * Get Material icon size as string
   *
   * @return {*}  {string}
   * @memberof EidosIconComponent
   */
  get iconScale(): string {
    const rotation = (this.iconRotate !== 0 ? this.iconRotate : 0);
    const scale = (this.iconConfiguration?.iconSize || 1);
    return `rotate(${rotation}deg) scaleX(${scale}) scaleY(${scale})`;
  }

  /**
  * Get Font Awesome icon size as string
  *
  * @deprecated Use generic method for X-Y scale + rotation transform
  * @return {*}  {string}
  * @memberof EidosIconComponent
  */
  getFAValue(): string {
    const v = this.iconConfiguration?.iconValue ?? this.iconConfiguration?.iconCode ?? '';
    return v.startsWith('fa') ? v : `fa-${v}`;
  }
  /**
   * Get Font Awesome icon size as string
   *
   * @deprecated Use generic method for X-Y scale + rotation transform
   * @return {*}  {string}
   * @memberof EidosIconComponent
   */
  getFAIconScale(): string {
    const scale = this.iconConfiguration?.iconSize || 1;
    return 'scale(' + scale + ')';
  }

  /**
   * Get SVG icon size as string
   *
   * @param {string} responsiveType
   * @return {*}  {string}
   * @memberof EidosIconComponent
   */
  getSvgScale(responsiveType: string): string {
    const size = this.iconConfiguration?.iconSize || 1;
    let zoomFactor = 1;
    switch (responsiveType) {
      case 'xs':
        zoomFactor = 3;
        break;
      case 'sm':
        zoomFactor = 1.5;
        break;
      default:
        zoomFactor = 1;
    }
    return 'eidos-icon-scale' + Math.ceil(size / zoomFactor);
  }

  constructor(
    private matIconRegistry: MatIconRegistry
    , private domSanitizer: DomSanitizer
    , private eidosUtilityService: EidosUtilityService
    , private eidosConfig: EidosConfigService
  ) {
    super();

    //Standard SVG Icons
    this.loadStandardIcons();
  }

  private loadStdIconsDefaults() {
    this.matIconRegistry.addSvgIcon(
      'logo_square_1c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos_logo_square_1colore.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_square_2c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos_logo_square_2colori.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_wide_1c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos_logo_wide_1colore.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_wide_2c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos_logo_wide_2colori.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_4.0_1c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos4.0_logo_wide_1colore.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_4.0n_1c',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos4.0new_logo_wide_1colore.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo_4.0n_1c_v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/eidos4.0new_logo_wide_1colore_v2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logo-ak',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/logo-ak.svg')
    );
  }

  private loadStandardIcons(): void {

    this.eidosConfig.currentConfig.subscribe({
      next: (cfg) => {
        if (cfg.externalIcons.length == 0) {
          this.loadStdIconsDefaults();
          return;
        }
        cfg.externalIcons.forEach(icon => {
          const typedir = icon.type === 'svg' ? 'svg' : 'images';
          const url = icon.absoluteUrl ? icon.url : `assets/${typedir}/${icon.url}`;
          this.matIconRegistry.addSvgIcon(
            icon.code,
            this.domSanitizer.bypassSecurityTrustResourceUrl(url)
          );
        });
      },
      error: () => {
        this.loadStdIconsDefaults();
      },
      complete: () => { }
    });
  }
  ngOnInit(): void {
    this.setMyBizIcon();
  }

  /**
   * Eventually set safe url of MyBiz icon
   *
   * @private
   * @memberof EidosIconComponent
   */
  private setMyBizIcon() {

    switch (this._iconConfiguration?.iconType) {
      case this.EidosIconType.SVG:
        const url = this._iconConfiguration.iconUrl ?? "";
        if (url) {
          this.matIconRegistry.addSvgIcon(
            this._iconConfiguration.iconCode ?? url.split('/').pop(),
            this.domSanitizer.bypassSecurityTrustResourceUrl(url)
          );
        }
        break;
      case this.EidosIconType.MyBiz:
        if (this._iconConfiguration.iconCode) {
          this.eidosUtilityService.getMyBizImageUrl(this._iconConfiguration.iconCode).subscribe(url => {
            if (this._iconConfiguration) {
              this._iconConfiguration.iconCodeSafeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
            }
          })
        }
        break;
      default:
        break;
    }
  }
}
