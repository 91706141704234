import { SafeBaseObject } from "@common/models/base-object.models";

export class ReservationShorex extends SafeBaseObject {

  public dateAvailability: number | undefined;
  public options: ReservationShorexOption[] | undefined;
  public serviceLongName: string | undefined;
  public serviceID: number | undefined;

  constructor(shorex?: IReservationApiShorex) {
    super();
    if(shorex) this.updateData(shorex);
  }
  override updateData(shorex: IReservationApiShorex): void {
    this.addMangledProperty(shorex);
    this.addDateTimeProperty('dateAvailability', shorex, 'DateAvailability');
    if(Array.isArray(shorex.Options) && shorex.Options.length > 0) this.options = shorex.Options.map(option => new ReservationShorexOption(option));
  }
}

interface IReservationApiShorex {
  DateAvailability: string;
  ServiceID: number;
  ServiceLongName: string;
  Options: Array<IReservationApiShorexOption>;
}

export class ReservationShorexOption extends SafeBaseObject {
  //	OptionMinPax	OptionMaxMax	OptionType	OptionCostPerDayID	CostTypeID	CostTypeName	CostTypeCod	Cost	Currency

  public packageID: number | undefined;
  public serviceID: number | undefined;
  public serviceLongName: string | undefined;
  public optionID: number | undefined;
  public optionName: string | undefined;
  public optionShortName: string | undefined;
  public optionDesc: string | undefined;
  public optionType: string | undefined;
  public alternative: boolean | undefined;
  public minPax: number | undefined;
  public maxPax: number | undefined;
  public description: string | undefined;
  public durationTime: number | undefined;
  public durationTimeUDM: number | undefined;
  public distance: number | undefined;
  public distanceUDM: number | undefined;
  public priceTypeID: number | undefined;
  public contractID: number | undefined;
  public chargingPolicyID: number | undefined;
  public sellingPrice: number | undefined;
  public sellingTax: number | undefined;
  public child: number | undefined;
  public single: number | undefined;
  public currency: string | undefined;
  public ROE: number | undefined;
  public margin: number | undefined;
  public quantity: number | undefined;
  public cost: number | undefined;
  public priceSetUpID: number | undefined;

  public dateAvailability: string | undefined;
  public priceSetUp: string | undefined;
  public priceTypeName: string | undefined;

  constructor(option: IReservationApiShorexOption) {
    super();
    if(option) this.updateData(option);
  }
  override updateData(option: IReservationApiShorexOption): void {
    this.addMangledProperty(option);

    // Redefine property
    this.addBooleanProperty('alternative', option, 'Alternative');
    this.addDateTimeProperty('dateAvailability', option, 'DateAvailability');
    this.addProperty('ROE', option, 'ROE');
  }
}

export interface IReservationApiShorexOption {

  DateAvailability: string;
  PriceSetUp: string;
  PriceTypeName: string;

  PackageID: number;
  ServiceID: number;
  ServiceLongName: string;
  OptionID: number;
  OptionName: string;
  OptionShortName: string;
  OptionType: string;
  OptionDesc: string;
  Alternative: boolean;
  MinPax: number;
  MaxPax: number;
  Description: string;
  DurationTime: number;
  DurationTimeUDM: number;
  Distance: number;
  DistanceUDM: number;
  PriceTypeID: number;
  ContractID: number;
  ChargingPolicyID: number;
  SellingPrice: number;
  SellingTax: number;
  Child: number;
  Single: number;
  Currency: string;
  ROE: number;
  Margin: number;
  Quantity: number;
  Cost: number;
  PriceSetUpID: number;
}

//PRICES
export class ReservationShorexPrice extends SafeBaseObject {
  public packageID: number=0;
  public start: Date | undefined;
  public end: Date | undefined;
  public occupancy: number = 100;
  public minPax: number = 0;
  public maxPax: number = 0;
  public escortInc: boolean = false;
  public driverInc: boolean = false;
  public guideInc: boolean = false;

  public items: Array<ReservationShorexOption> | undefined;

  constructor(data: IReservationApiShorexPrice) {
    super();
    if(data) this.updateData(data);
  }
  override updateData(data: IReservationApiShorexPrice): void {
    //defaults
    this.addMangledProperty(data);
    //redefine property
    this.addDateTimeProperty('start', data, 'Start');
    this.addDateTimeProperty('end', data, 'End');

    this.items = [];
    data.Service.forEach(item => this.items!.push(new ReservationShorexOption(item)));
  }
}
export interface IReservationApiShorexPriceParameters {

}
export interface IReservationApiShorexPrice {
  PackageID: number;
  Start: Date;
  End: Date;
  MinPax: number;
  MaxPax: number;
  EscordInc: boolean;
  DriverInc: boolean;
  GuideInc: boolean;
  Occupancy: number;
  Service: Array<IReservationApiShorexOption>;
}
