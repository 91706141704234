import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EidosTokenGuard } from '@common/guards/eidos-token.guard';
import { EidosObjectComponent } from '@eidos/components/objects/eidos-object/eidos-object.component';
import { EidosComponent } from '@eidos/eidos.component';

const routes: Routes = [
  {
    path: '',
    component: EidosComponent,
    pathMatch: 'prefix',
  },
  {
    path: 'object/:objectId',
    component: EidosObjectComponent,
    canActivate: [EidosTokenGuard],
    pathMatch: 'prefix'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EidosRoutingModule { }
