import { ElementRef, Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { EidosLogSeverity } from '@common/models/core-constant.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosObject, EidosObjectData, EidosObjectEventType, EidosObjectMode, EidosObjectRefreshType } from '@common/models/eidos-object.model';

import { EidosObjectService } from '@eidos/services/eidos-object.service';
import { EidosObjectBaseComponent } from '@eidos/components/objects/eidos-object-base/eidos-object-base.component';

@Component({
  selector: 'eidos-object',
  templateUrl: './eidos-object.component.html',
  styleUrls: ['./eidos-object.component.scss']
})
export class EidosObjectComponent extends EidosObjectBaseComponent implements OnInit, OnDestroy {

  EidosObjectMode = EidosObjectMode;

  private intervalIdRefreshPolice: NodeJS.Timeout | undefined;
  @Input()
  public eidosObject: EidosObject | undefined;

  public getObjectId(): number {
    return this.eidosObject?.objectId || 0;
  }
  public getPageSize(): number {
    return this.eidosObject?.pageSize || 20;
  }

  constructor(
    private eidosObjectService: EidosObjectService
    , private eidosLogService: EidosLogService
    , private route: ActivatedRoute
    , private elementRef: ElementRef
  ) {
    super();

    this.eidosObjectService.objectEventEmitter.subscribe(ev => {
      if (ev.id && ev.id !== this.getEidosObjectId()) return;

      switch (ev.type) {
        case EidosObjectEventType.LoadComplete:
          this.eidosObjectConfiguration = ev.config;
          switch (this.eidosObjectConfiguration?.refreshType) {
            case EidosObjectRefreshType.Timer:
              if (this.eidosObjectConfiguration.refreshInterval! > 0) {
                if (this.intervalIdRefreshPolice) clearInterval(this.intervalIdRefreshPolice);
                this.intervalIdRefreshPolice = setInterval(() => this.reload(this.eidosObject?.objectDataPage ?? 1), this.eidosObjectConfiguration.refreshInterval! * 1000);
              }
          }
          this.setData(ev.data);
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `EidosObj${this.getEidosObjectId()}`, 'Object config and data loaded');
          break;
        case EidosObjectEventType.ReloadComplete:
          if (ev.config) this.eidosObjectConfiguration = ev.config;
          this.setData(ev.data);
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `EidosObj${this.getEidosObjectId()}`, 'Object data reloaded');
          break;
        case EidosObjectEventType.ReloadRequired:
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `EidosObj${this.getEidosObjectId()}`, 'Object reload required');
          this.load(this.eidosObject?.objectDataPage ?? 1, true);
          break;
        case EidosObjectEventType.ReloadData:
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `EidosObj${this.getEidosObjectId()}`, 'Object reload data');
          this.reload(this.eidosObjectData?.currentPage ?? 1);
          break;
        case EidosObjectEventType.LoadError:
          this.eidosLogService.logDebug(EidosLogSeverity.Log, `EidosObj${this.getEidosObjectId()}`, 'Object error loading');
          this.loading = false;
          this.errorLoading = true;
          this.errorMessage = ev.message;
          this.loadError();
          break;
      }
    });
  }

  ngOnInit(): void {
    if (!this.eidosObject) {
      this.route.params
        .pipe(takeUntil(this.subscription))
        .subscribe(() => {
          const objId = this.route.snapshot.paramMap.get('objectId');
          if (objId) {
            this.eidosObject = EidosObject.CreateEidosObject(objId);
            this.load();
          }
        });
    }

    if (this.eidosObject) {
      this.eidosObject.eidosObjectId = this.eidosObjectId;
      this.load();
    }
  }

  private loadError(): void {
  }
  /**
   * Setup the object configuration and data
   *
   * @private
   * @memberof EidosObjectComponent
   */
  private load(_: number = 1, forceReloading: boolean = false): void {
    if (this.eidosObject && this.eidosObject.mode != EidosObjectMode.Action) {
      this.loading = true;
      this.eidosObject.objectDataPage = 1;
      this.eidosObjectService.loadComponent(this.getEidosObjectId(), this.eidosObject, forceReloading);
    }
  }
  /**
   * Reload the object data
   *
   * @private
   * @memberof EidosObjectComponent
   */
  private reload(page: number = 1): void {
    if (this.eidosObject && this.eidosObject.mode != EidosObjectMode.Action) {
      this.loading = true;
      this.eidosObject.objectDataPage = page;
      this.eidosObjectService.reloadData(this.getEidosObjectId(), this.eidosObject);
    }
  }
  /**
   * Set object data
   *
   * @private
   * @param {(EidosObjectData | undefined)} data
   * @memberof EidosObjectComponent
   */
  private setData(data: EidosObjectData | undefined) {
    this.eidosObjectData = data;
    this.eidosDataUpdate();
    this.loading = false;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eidosObject = undefined;
    this.elementRef.nativeElement?.remove();
    if (this.intervalIdRefreshPolice) {
      clearInterval(this.intervalIdRefreshPolice);
    }
  }
}
