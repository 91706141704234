<!-- <ng-container *ngIf="eidosInjector"
  [ngComponentOutlet]="component"
  [ngComponentOutletInjector]="eidosInjector">
</ng-container> -->
<mat-spinner *ngIf="loading" diameter="50" style="margin:0 auto;"></mat-spinner>
<content *ngIf="errorLoading">
  <h1>ERROR LOADING OBJECT DESCRIPTION</h1>
  <p>{{errorMessage}}</p>
</content>
<content *ngIf="eidosObject && !loading" [ngSwitch]="eidosObject.mode">
  <ng-template [ngSwitchCase]="EidosObjectMode.Object">
    <eidos-object-ui [eidosObject]="eidosObject" [eidosObjectConfiguration]="eidosObjectConfiguration"
      [eidosObjectData]="eidosObjectData"></eidos-object-ui>
  </ng-template>
  <ng-template [ngSwitchCase]="EidosObjectMode.Action">
    <eidos-object-action [eidosObject]="eidosObject"></eidos-object-action>
  </ng-template>
  <ng-template [ngSwitchDefault]>
    <p>unknown object</p>
  </ng-template>
</content>
