import { Component, ViewChild, ElementRef, Input, OnChanges, AfterViewInit } from '@angular/core';
import { IGsGaugeConfiguration } from '@common/models/eidos-chart-js.interface';
import { IEidosChartValue } from '@common/models/eidos-chart-js.model';
import { IEidosScalarChartConfigurationData } from '@common/models/eidos-object.interface';
import { EidosObjectType } from '@common/models/eidos-object.model';
import * as d3ext from '@assets/js/d3-ext/gs-gauge.js';

@Component({
  selector: 'eidos-gs-gauge',
  templateUrl: './gs-gauge.component.html',
  styleUrls: ['./gs-gauge.component.css']
})
export class GsGaugeComponent implements AfterViewInit, OnChanges {
  type: EidosObjectType = EidosObjectType.Chart;

  @ViewChild('fillGsGauge')
  private svg: ElementRef | null;

  private gauge: any;

  private data: number;
  @Input()
  public set Data(value: IEidosChartValue) {
    this.data = typeof value === 'number' ? value : 0;
  }

  private config: IGsGaugeConfiguration = {};
  @Input()
  public set Config(config: IEidosScalarChartConfigurationData) {
    this.config = config.config as IGsGaugeConfiguration;
  }

  constructor(
  ) {
    this.data = 0;
    this.config = d3ext.GsGaugeConfiguration() as IGsGaugeConfiguration;
    this.svg = null;
  }

  ngAfterViewInit(): void {
    this.gauge = new d3ext.GsGauge(this.svg?.nativeElement, this.config);
    this.gauge.render();
    this.gauge.redraw(this.data);
  }

  ngOnChanges() {
    if (!this.gauge) { return; }
    this.gauge.configure(this.config);
    this.gauge.render();
    this.gauge.redraw(this.data);
  }
}
