import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReservationPermission } from '@app/res-common/models/reservation-permission.model';
import { RescomService } from '@app/res-common/services/rescom.service';

interface iEidosButton {
  BtnText: string;
  BtnIcon?: string;
  GridCommandId: number;
  // PermissionCod?: string;
  // APIEnabled?: string;
  Enabled?: boolean;
  Visible?: boolean;
  SelectionRequired?:String;
}

@Component({
  selector: 'eidos-button-list',
  templateUrl: './eidos-button-list.component.html',
  styleUrls: ['./eidos-button-list.component.scss'],
})
export class EidosButtonListComponent implements OnInit {

  protected _buttons: iEidosButton[] = [];

  @Input()
  public set buttons(value: iEidosButton[]) {
    this._buttons = value;
  }
  public get buttons(): iEidosButton[] {
    return this._buttons;
  }

  public permission: ReservationPermission =
    ReservationPermission.emptyPermission();

  @Output()
  public onButtonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private rescomService: RescomService,
  ) {
    this.rescomService.currentReservationPermission.subscribe(
      (permission) => (this.permission = permission)
    );
  }

  ngOnInit(): void {
  }

  public _onClick(event: any, GridCommand: iEidosButton) {
    let evn = {
      event: event,
      GridCommand: GridCommand,
    };
    //alert('1bbb');
    this.onButtonClick.emit(evn);
  }

  public ChangeButtonEnabled(GridCommandId:number, Enable:boolean, changeVisible:boolean = false):void {
    let index = this._buttons.findIndex(el => el.GridCommandId == GridCommandId);
    this._buttons[index].Enabled = Enable;
    if(changeVisible) this._buttons[index].Visible = Enable;
  }

  public ChangeButtonVisible(GridCommandId:number, Visible:boolean):void {
    let index = this._buttons.findIndex(el => el.GridCommandId == GridCommandId);
    this._buttons[index].Visible = Visible;
  }

}
