import '@angular/common/locales/global/it';
import '@angular/common/locales/global/en';

// Modules
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { DevExpressModule } from '@app/devexpress.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from '@app/transloco-root.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { NgChartsModule } from 'ng2-charts';
import { CoreModule } from '@common/core.module';

// Components
import { AppComponent } from '@app/app.component';

// Custom adapters
import { MatLuxonDateAdapterOptions, MatLuxonDateModule, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
// import { EidosLuxonAdapter } from '@common/models/eidos-luxon-adapter';
import { CoreRegisterAccountComponent } from './core/components/core-register-account/core-register-account.component';

const CUSTOM_FIRST_DAY_OF_WEEK: MatLuxonDateAdapterOptions = {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material components like DatePicker output dates.
   * {@default false}
   */
  useUtc: false,
  /**
   * Sets the first day of week.
   * Changing this will change how Angular Material components like DatePicker shows start of week.
   * {@default 0}
   */
  firstDayOfWeek: 1
}

@NgModule({
  declarations: [
    AppComponent,
    CoreRegisterAccountComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DevExpressModule,
    HttpClientModule,
    NgChartsModule,
    MatLuxonDateModule,
    TranslocoRootModule,
    CoreModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: CUSTOM_FIRST_DAY_OF_WEEK,
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
