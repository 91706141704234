import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EidosBaseComponent } from '@common/components/eidos-base.component';
import { IEidosIcon } from '@common/config/environment.interface';

export interface IButtonBadgeConfiguration {
  text?: string;
  icon: IEidosIcon | null,
  tooltipText?: string;
  tooltipPosition?: string;
}

@Component({
  selector: 'eidos-button',
  templateUrl: './eidos-button.component.html',
  styleUrls: ['./eidos-button.component.scss'],
})
export class EidosButtonComponent extends EidosBaseComponent {
  private _iconConfiguration: IEidosIcon | null = null;
  private _cssClasses: Array<string> = [];
  private _tooltipTarget: any;
  /**
   * Button styling mode, 'contained' or 'outlined'
   *
   * @type {string}
   * @memberof EidosButtonComponent
   */
  @Input() stylingMode: string = 'contained';
  /**
   * Button size, 'small' or 'big'
   *
   * @type {string}
   * @memberof EidosButtonComponent
   */
  @Input() size: string = 'small';
  /**
   * Icon position, 'left' or 'right'
   *
   * @type {string}
   * @memberof EidosButtonComponent
   */
  @Input() iconPosition: string = 'left';
  /**
   * Badge configuration
   *
   * @type {IButtonBadgeConfiguration}
   * @memberof EidosButtonComponent
   */
  @Input() badgeConfiguration: IButtonBadgeConfiguration | null = null;
  /**
   * Button text
   *
   * @type {string}
   * @memberof EidosButtonComponent
   */
  @Input() text: string = '';
  /**
   * Button disabled
   *
   * @type {boolean}
   * @memberof EidosButtonComponent
   */
  @Input() isDisabled: boolean = false;
  /**
   * Click event emitter
   *
   * @memberof EidosButtonComponent
   */
  @Output() btnClick = new EventEmitter();
  /**
   * Icon configuration
   *
   * @type {(IEidosIcon | null)}
   * @memberof EidosButtonComponent
   */
  @Input() set iconConfiguration(value: IEidosIcon | null) {
    this._iconConfiguration = value;
  }
  /**
   * Button visibility
   *
   * @type {string}
   * @memberof EidosButtonComponent
   */
  @Input() visible: boolean = true;

  public tooltipDefaultVisible: boolean = false;

  get tooltipTarget(): any {
    return this._tooltipTarget;
  }

  get iconConfiguration(): IEidosIcon | null {
    return this._iconConfiguration;
  }

  get cssClasses(): Array<string> {
    return this._cssClasses;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {

    super.ngOnInit();

    this._cssClasses = [];
    switch (this.stylingMode) {
      case 'contained':
        this._cssClasses.push('eidos-button-contained');
        break;
      case 'contained-success':
        this._cssClasses.push('eidos-button-contained-success');
        break;
      case 'contained-danger':
        this._cssClasses.push('eidos-button-contained-danger');
        break;
      case 'outlined':
        this._cssClasses.push('eidos-button-outlined');
        break;
    }

    switch (this.size) {
      case 'big':
        this._cssClasses.push('eidos-button-big');
        break;
      case 'normal':
        this._cssClasses.push('eidos-button-normal');
        break;
      case 'small':
        this._cssClasses.push('eidos-button-small');
        break;
    }

    if (this.badgeConfiguration) {
      this._cssClasses.push('eidos-button-margin-badge');
    }
  }

  onMouseEnter(event: MouseEvent) {
    this._tooltipTarget = event.target;
    this.tooltipDefaultVisible = true;
  }

  onMouseLeave() {
    this.tooltipDefaultVisible = false;
  }
}
