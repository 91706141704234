import { Injectable } from "@angular/core";
import _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { IResAirClass, IResAirPort, ResAirData } from "../models/res-air.model";
import { ReservationApiService } from "./reservation-api.service";

@Injectable({
    providedIn: 'root',
})
export class ReservationAirService {

    constructor(
        private resApiService: ReservationApiService,
    ) { }

    allAirports: BehaviorSubject<Array<string>> | undefined;
    

    get selectedAirportFrom(): string {
        return sessionStorage.getItem('selectedAirportFrom')!!;
    }
    set selectedAirportFrom(value: string) {
        sessionStorage.setItem('selectedAirportFrom', value);
    }
    get selectedAirClassFrom(): string {
        return sessionStorage.getItem('selectedAirClassFrom')!!;
    }
    set selectedAirClassFrom(value: string) {
        sessionStorage.setItem('selectedAirClassFrom', value);
    }
    get selectedAirportTo(): string {
        return sessionStorage.getItem('selectedAirportTo')!!;
    }
    set selectedAirportTo(value: string) {
        sessionStorage.setItem('selectedAirportTo', value);
    }
    get selectedAirClassTo(): string {
        return sessionStorage.getItem('selectedAirClassTo')!!;
    }
    set selectedAirClassTo(value: string) {
        sessionStorage.setItem('selectedAirClassTo', value);
    }

    async getAvailablesAirportsFrom(): Promise<Array<IResAirPort>> {
        if (this.airDataCache?.airportFrom) return this.airDataCache.airportFrom;
        this.eventuallyLoadAirData();
        return (await this.airDataPromise!).airportFrom;
    }
    async getAvailablesAirClassFrom(): Promise<Array<IResAirClass>> {
        if (this.airDataCache?.airClassFrom) return this.airDataCache.airClassFrom;
        this.eventuallyLoadAirData()
        return (await this.airDataPromise!).airClassFrom;
    }
    async getAvailablesAirportsTo(): Promise<Array<IResAirPort>> {
        if (this.airDataCache?.airportTo) return this.airDataCache.airportTo;
        this.eventuallyLoadAirData()
        return (await this.airDataPromise!).airportTo;
    }
    async getAvailablesAirClassTo(): Promise<Array<IResAirClass>> {
        if (this.airDataCache?.airClassTo) return this.airDataCache.airClassTo;
        this.eventuallyLoadAirData()
        return (await this.airDataPromise!).airClassTo;
    }

    async getAvailablesAirData(): Promise<ResAirData> {
        if (this.airDataCache) return this.airDataCache;
        this.eventuallyLoadAirData()
        return (await this.airDataPromise!);
    }

    eventuallyLoadAirData() {
        if (!this.airDataPromise) this.airDataPromise = this.loadAirData();
    }

    private airDataPromise?: Promise<ResAirData>;
    
    private airDataCache?: ResAirData

    clearSelected() {
        this.selectedAirportFrom = '';
        this.selectedAirClassFrom = '';
        this.selectedAirportTo = '';
        this.selectedAirClassTo = '';
    }
    
    loadAirData(): Promise<ResAirData> {
        return new Promise((resolve, reject) => {
            this.resApiService.getAirData().subscribe({
                next: (airData: ResAirData) => {
                    this.airDataCache = airData;
                    resolve(airData);
                },
                error: (e) => {
                    reject(e);
                }
            });
        });

        
    }

}