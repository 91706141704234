import { Component, EventEmitter, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { takeUntil } from 'rxjs';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { IEidosIcon } from '@common/config/environment.interface';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosSecurityService } from '@common/services/eidos-security.service';
import { EidosIconType, CoreRoute } from '@common/models/core-constant.model';
import { EidosNotificationService } from '@common/services/eidos-notification.service';
import { EidosUser } from '@common/models/user-info.model';
import { EidosBaseComponent } from '../eidos-base.component';
import { CoreModuleRedirectorService } from '@app/core/services/core-module-redirector.service';

@Component({
  selector: 'eidos-topbar',
  templateUrl: './eidos-topbar.component.html',
  styleUrls: ['./eidos-topbar.component.scss']
})
export class EidosTopbarComponent extends EidosBaseComponent {
  /**
   *
   *
   * @type {EventEmitter<any>}
   * @memberof EidosTopbarComponent
   */
  @Output() hamburgerClickEvent: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Menu icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosTopbarComponent
   */
  protected menuIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "menu",
    iconSize: 1
  };
  /**
   * Company logo icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosTopbarComponent
   */
  protected companyLogo: IEidosIcon | null = null;
  /**
   * Number of unread notifications
   * (Menu icon badge)
   *
   * @type {(number | undefined)}
   * @memberof EidosTopbarComponent
   */
  protected unreadNotifications: number | undefined;
  /**
   * Logo icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosTopbarComponent
   */
  protected logo: IEidosIcon;
  /**
   * Hide Company Name from configuration
   *
   * @type {boolean}
   * @memberof EidosTopbarComponent
   */
  protected hideCompanyName: boolean = false;
  protected hideMyBizMenu = false
  /**
   * Logo icon tooltip position
   *
   * @type {TooltipPosition}
   * @memberof EidosTopbarComponent
   */
  protected logoIconTooltipPosition: TooltipPosition = "right";
  /**
   * Current user
   *
   * @type {EidosUser}
   * @memberof EidosTopbarComponent
   */
  protected user: EidosUser | undefined;
  /**
   * Menu closed status
   *
   * @type {boolean}
   * @memberof EidosTopbarComponent
   */
  private isMenuClosed: boolean = false;
  /**
   * Return menu icon label basing on its status
   *
   * @readonly
   * @type {string}
   * @memberof EidosTopbarComponent
   */
  protected get menuIconTooltip(): string {
    return this.isMenuClosed ? 'topbar.open_menu' : 'topbar.close_menu';
  }
  /**
   * Landing page
   *
   * @private
   * @type {string}
   * @memberof EidosTopbarComponent
   */
  private landingPage?: string;

  constructor(
    private eidosConfigService: EidosConfigService
    , private eidosSecurityService: EidosSecurityService
    , private coreModuleRedirectorService: CoreModuleRedirectorService
    , private eidosUtilityService: EidosUtilityService
    , private eidosNotificationService: EidosNotificationService
  ) {
    super();
    this.logo = this.eidosConfigService.DEFAULT_CONFIG.logo;
  }

  ngOnInit(): void {

    super.ngOnInit();

    this.eidosConfigService.currentConfig
      .pipe(takeUntil(this.subscription))
      .subscribe(config => {
        this.logo = config.logo;
        if (config.hideCompanyName) {
          this.hideCompanyName = config.hideCompanyName;
          this.hideMyBizMenu = !!config.hideMyBizMenu
        }
        if (config.landingPage) {
          this.landingPage = config.landingPage;
        }
      });

    this.unreadNotifications = this.eidosNotificationService.notifications.length;

    this.eidosNotificationService.newNotification
      .pipe(takeUntil(this.subscription))
      .subscribe(() => {
        if (this.unreadNotifications) {
          this.unreadNotifications = this.unreadNotifications + 1;
        } else {
          this.unreadNotifications = 1;
        }
      });

    this.eidosNotificationService.readNotification
      .pipe(takeUntil(this.subscription))
      .subscribe(() => {
        if (this.unreadNotifications && this.unreadNotifications > 0) {
          this.unreadNotifications = this.unreadNotifications - 1;
        }
      });

    this.eidosSecurityService.currentLoggedUser
      .pipe(takeUntil(this.subscription))
      .subscribe(user => {
        this.loadNewUser(user);
      });

    this.eidosUtilityService.isMenuClosed
      .pipe(takeUntil(this.subscription))
      .subscribe(isClosed => {
        this.isMenuClosed = isClosed;
      });
  }
  /**
   * Load a new user in the topbar
   *
   * @private
   * @param {EidosUser} user
   * @memberof EidosTopbarComponent
   */
  private loadNewUser(user: EidosUser) {
    this.user = user;

    if (user.landingObject) {
      // TODO
    }

    if (!!user.companyLogo) {
      this.companyLogo = {
        iconType: EidosIconType.MyBiz,
        iconCode: user.companyLogo,
        iconSize: 1
      };
    } else {
      this.companyLogo = null;
    }
  }
  /**
   * Redirect user to homepage while pressing on main logo
   *
   * @private
   * @memberof EidosTopbarComponent
   */
  protected navigateToHome(): void {
    const landingPage = !!this.landingPage ? this.landingPage : CoreRoute.Home;
    this.coreModuleRedirectorService.appNavigate([landingPage]);
  }
  /**
   * Toggle the Eidos menu
   *
   * @memberof EidosTopbarComponent
   */
  protected toggleEidosMenu() {
    this.hamburgerClickEvent.emit();
    this.eidosUtilityService.toggleMenuStatus();
  }
}
