<h1 mat-dialog-title fxLayoutAlign="center">{{ error.name }}</h1>
<div mat-dialog-content>
  <h6>{{ now | date:'dd/MM/YYYY HH:mm' }} - {{ !!url ? url : '' }}</h6>
  <h6>User: {{ user.username }} {{ user.isImpersonated ? '(Impersonated)' : '' }} - {{ user.company }}<br />{{ user.token }}</h6>
  <h4 class="core-error-dialog-message">{{ error.message }}</h4>
  <div *ngIf="error.stack" [innerHtml]="error.stack"></div>
  <p>{{ userAgent }}</p>
  <p>In no info are provided, try to see browser debugger console, try to reload the page.</p>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-button type="button" [cdkCopyToClipboard]="error.name + '<br />' + error.message + '<br />' + error.stack">{{ 'copy_info' | transloco }}</button>
  <button mat-flat-button type="button" color="primary" (click)="close()">{{ 'close' | transloco }}</button>
</div>