import { Component, OnInit } from '@angular/core';

import { EidosFieldBaseComponent } from '@eidos/components/fields/eidos-field-base/eidos-field-base.component';

@Component({
  selector: 'eidos-field-html',
  templateUrl: './eidos-field-html.component.html',
  styleUrls: ['./eidos-field-html.component.scss']
})
export class EidosFieldHtmlComponent extends EidosFieldBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
