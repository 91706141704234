import { Component, OnInit } from '@angular/core';
import { EidosFieldBaseComponent } from '@eidos/components/fields/eidos-field-base/eidos-field-base.component';

@Component({
  selector: 'eidos-field',
  templateUrl: './eidos-field.component.html',
  styleUrls: ['./eidos-field.component.scss']
})
export class EidosFieldComponent extends EidosFieldBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
