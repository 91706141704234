<mat-card class="eidos-notification" *ngFor="let notification of getNotificationToDisplay()">
  <mat-card-title>
    {{ notification.title }}
    <eidos-icon
      class="eidos-notification-close-icon"
      [iconConfiguration]="readIcon"
      (click)="readNotification(notification)"
      [tooltip]="'menu.set_as_read'"
      [clickable]="true"></eidos-icon>
  </mat-card-title>
  <mat-card-subtitle>
    {{ notification.sender }} - {{ notification.timestamp.toFormat('dd/MM/yyyy HH:mm') }}
  </mat-card-subtitle>
  <mat-card-content><p>{{ notification.text }}</p></mat-card-content>
</mat-card>
<p class="no-notifications-message" *ngIf="getNotificationToDisplay().length === 0 ">{{ 'menu.no_notifications' | transloco }}</p>