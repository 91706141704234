import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger, state } from '@angular/animations';
import { EidosMenuItem, EidosMenuItemType } from '@common/models/eidos-menu.model';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { Clipboard } from '@angular/cdk/clipboard';

import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosConfigService } from '@common/config/eidos-config.service';
import { EidosBaseComponent } from '../eidos-base.component';
import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';
import { EidosExternalAppAction } from '@common/models/core-constant.model';


const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('30ms', animate('300ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

const itemAnimation = trigger('indicatorRotate', [
  state('collapsed', style({ transform: 'rotate(0deg)' })),
  state('expanded', style({ transform: 'rotate(180deg)' })),
  transition('expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
  )
]);

@Component({
  selector: 'eidos-menu-item',
  styleUrls: [
    'eidos-menu-item.component.scss'
  ],
  animations: [itemAnimation, listAnimation],
  templateUrl: 'eidos-menu-item.component.html'
})
export class EidosMenuItemComponent extends EidosBaseComponent implements OnInit {

  @Input()
  public menuItem: EidosMenuItem;

  @Input()
  depth: number = 0;

  @Input()
  selectedId: number = 0;
  /**
   * Expanded flag for item childrens
   *
   * @type {boolean}
   * @memberof EidosMenuItemComponent
   */
  public expanded: boolean = false;

  /**
   * External apps root URL
   *
   * @private
   * @type {string}
   * @memberof EidosMenuItemComponent
   */
  private externalAppsRoot: string;


  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, { static: true }) matRightMenuTrigger: MatMenuTrigger | undefined;;
  // we create an object that contains coordinates
  menuTopLeftPosition = { x: '0', y: '0' }

  /**
   * Method called when the user click with the right button
   * @param event MouseEvent, it contains the coordinates
   * @param item Our data contained in the row of the table
   */
  onRightClick(event: MouseEvent, item: EidosMenuItem) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    if (!this.matRightMenuTrigger) return;
    this.matRightMenuTrigger.menuData = { item: item }

    // we open the menu
    this.matRightMenuTrigger.openMenu();

  }

  constructor(
    private eidosConfigService: EidosConfigService
    , private router: Router
    , private utilityService: EidosUtilityService
    , private externalAppService: EidosExternalAppService
    , private clipboard: Clipboard
  ) {
    super();
    this.menuItem = new EidosMenuItem();
    this.externalAppsRoot = this.eidosConfigService.DEFAULT_CONFIG.externalAppsRoot;
  }

  ngOnInit() {
    this.expanded = this.menuItem?.hasChild(this.selectedId) || false;
    this.eidosConfigService.currentConfig.subscribe(config => this.externalAppsRoot = config.externalAppsRoot);
  }

  menuItemToggle() {
    this.expanded = !this.expanded;
  }

  private getMenuPath(): string | null {
    if (this.menuItem.disabled) return null;

    const path = [window.location.origin];
    switch (this.menuItem.type) {
      case EidosMenuItemType.url:
        path.push(this.externalAppsRoot);
        path.push(EidosExternalAppAction.EmbeddedUrl);
        break;
      default:
        const id = this.menuItem.objectId ? this.menuItem.objectId.toString() : null;
        if (this.menuItem.action) {
          path.push(this.externalAppsRoot);
          path.push(this.menuItem.action);
        } else if (this.menuItem.isEidosObject) {
          path.push(EidosExternalAppAction.Eidos);
          if (id) path.push(id);
        } else {
          path.push(this.externalAppsRoot);
          path.push(EidosExternalAppAction.MyBiz);
          if (id) path.push(id);
        }
        break;
    }
    return path.join('/');
  }

  openInNewTab() {
    const path = this.getMenuPath();
    if (path) window.open(path, '_blank');
  }
  copyToClipboard() {
    const path = this.getMenuPath();
    if (path) this.clipboard.copy(path);
  }
  /**
   * Menu item selection handler
   *
   * @memberof EidosMenuItemComponent
   */
  public selectItem() {
    this.selectedId = this.menuItem.id;
    if (!this.menuItem.disabled) {

      switch (this.menuItem.type) {
        case EidosMenuItemType.url:
          if(this.menuItem.isEidosObject) {
            this.router.navigate([this.menuItem.url]);
          }
          else {
            this.externalAppService.setAppEidosEmbeddedUrl(this.menuItem.url ?? '');
            this.router.navigate([this.externalAppsRoot, EidosExternalAppAction.EmbeddedUrl]);
          }
          break;
        default:
          if (!!this.menuItem.url) {
            this.router.navigate([this.menuItem.url]);
          } else if (this.menuItem.action) {
            this.router.navigate([this.externalAppsRoot, this.menuItem.action]);
          } else if (this.menuItem.isEidosObject) {
            this.router.navigate([EidosExternalAppAction.Eidos, this.menuItem.objectId]);
          } else {
            this.router.navigate([this.externalAppsRoot, EidosExternalAppAction.MyBiz, this.menuItem.objectId]);
          }
          break;
      }
      this.utilityService.toggleMenuStatusIfNotPinned(true);
    }
  }
}
