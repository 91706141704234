import { Component, Input, OnInit } from '@angular/core';
import { EidosInputBaseComponent } from '@common/components/entities/inputs/eidos-input-base.component';

@Component({
  selector: 'eidos-input-switch',
  templateUrl: './eidos-input-switch.component.html',
  styleUrls: ['./eidos-input-switch.component.scss']
})
export class EidosInputSwitchComponent extends EidosInputBaseComponent<boolean> implements OnInit {
  /**
   * Label to display on ON status
   *
   * @type {string}
   * @memberof EidosInputSwitchComponent
   */
  @Input()
  public switchOnLabel: string = "ON";
  /**
   * Label to display on off status
   *
   * @type {string}
   * @memberof EidosInputSwitchComponent
   */
  @Input()
  public switchOffLabel: string = "OFF";

  constructor() {
    super();
  }
}