import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

import { EidosConfigService } from '@common/config/eidos-config.service';
import { EidosExternalAppService } from '@common/eidos-external-app/eidos-external-app.service';
import { EidosExternalAppAction, EidosLogSeverity, CoreRoute } from '@common/models/core-constant.model';
import { EidosExternalApp } from '@common/models/eidos-external-app.model';
import { EidosMenu, EidosMenuItem } from '@common/models/eidos-menu.model';
import { EidosObjectOptions } from '@common/models/eidos-object.interface';
import { EidosObject, EidosObjectActionType } from '@common/models/eidos-object.model';
import { EidosLogService } from '@common/services/eidos-log.service';
import { EidosUtilityService } from '@common/services/eidos-utility.service';
import { EidosObjectBaseComponent } from '../eidos-object-base/eidos-object-base.component';

@Component({
  selector: 'eidos-object-action',
  templateUrl: './eidos-object-action.component.html',
  styleUrls: ['./eidos-object-action.component.scss']
})
export class EidosObjectActionComponent extends EidosObjectBaseComponent implements OnInit, AfterContentInit {
  EidosObjectActionType = EidosObjectActionType;

  @Input()
  public eidosObject: EidosObject | undefined;
  /**
   * Link to related app (External app case)
   *
   * @type {(IEidosExternalAppRoute | undefined)}
   * @memberof EidosObjectActionComponent
   */
  public app: EidosExternalApp | null | undefined;
  /**
   * Link to related menu item (MyBiz case)
   *
   * @type {(EidosMenuItem | undefined)}
   * @memberof EidosObjectActionComponent
   */
  public item: EidosMenuItem | undefined;
  /**
   * Returns the margin top attribute for action label
   *
   * @readonly
   * @type {string}
   * @memberof EidosObjectActionComponent
   */
  get labelMarginTop(): string {
    const icon = this.eidosObject!.icon;
    return icon ? `${icon.iconSize * icon.iconSize}px` : "";
  }
  /**
   * External apps root URL
   *
   * @private
   * @type {string}
   * @memberof EidosObjectActionComponent
   */
  private externalAppsRoot: string;

  constructor(
    private router: Router
    , private eidosConfigService: EidosConfigService
    , private eidosLogService: EidosLogService
    , private externalAppService: EidosExternalAppService
    , private eidosUtilityService: EidosUtilityService
  ) {
    super();
    this.externalAppsRoot = this.eidosConfigService.DEFAULT_CONFIG.externalAppsRoot;
  }

  ngOnInit(): void {

    this.eidosConfigService.currentConfig.subscribe(config => this.externalAppsRoot = config.externalAppsRoot);

    if (this.eidosObject) {
      if (this.eidosObject.objectType !== undefined) {
        switch (this.eidosObject.objectType) {
          case EidosObjectActionType.Mybiz:
            // this.item = this.utilityService.getCurrentMenu().getItem(this.eidosObject.objectId);
            this.eidosUtilityService.currentMenu.subscribe((menu: EidosMenu) => this.item = menu.getItem(this.eidosObject!.objectId));
            break;
          case EidosObjectActionType.Extapp:

            this.externalAppService.apps
              .pipe(
                filter((apps: EidosExternalApp[] | undefined) => !!apps && apps.length > 0),
                takeUntil(this.subscription)
              )
              .subscribe(() => {
                this.app = this.externalAppService.getAppReferenceById(this.eidosObject!.objectId);
                if (this.app) {
                  this.app.options = this.eidosObject!.options;
                }
              });
            break;
          default:
            // New Eidos mode
            break;
        }
      }
    }
  }

  ngAfterContentInit(): void {
  }

  /**
   * Dashboard tile click handler
   * Navigate to external app
   *
   * @memberof EidosHomePageComponent
   */
  public navigate(): void {
    switch (this.eidosObject?.objectType) {
      case EidosObjectActionType.Route:
        if (this.eidosObject.options) {
          const paths = ['eidos', ...this.eidosObject.options?.toString().split('/')];
          this.router.navigate(paths);
        }
        break;
      case EidosObjectActionType.Mybiz:
        this.navigateMybiz();
        break;
      case EidosObjectActionType.Extapp:
        this.navigateExtApp(this.eidosObject.options);
        break;
      case EidosObjectActionType.Eidos:
        this.navigateEidos();
        break;
      case EidosObjectActionType.Home:
        this.router.navigate([CoreRoute.Home]);
        break;
    }
  }

  private navigateEidos() {
    if (this.eidosObject && this.eidosObject.objectId) {
      this.router.navigate(['eidos/object', this.eidosObject.objectId]);
      this.eidosUtilityService.toggleMenuStatusIfNotPinned(true);
    } else {
      this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Eidos page not correct (' + this.eidosObject?.objectId + ')');
    }
  }

  private navigateMybiz() {
    if (this.eidosObject?.objectId) {
      this.router.navigate([this.externalAppsRoot, EidosExternalAppAction.MyBiz, this.eidosObject?.objectId]);
      this.eidosUtilityService.toggleMenuStatusIfNotPinned(true);
    } else if (this.item && this.item.disabled !== true) {
      this.router.navigate(this.item.getRoute(this.externalAppsRoot));
      this.eidosUtilityService.toggleMenuStatusIfNotPinned(true);
    } else {
      this.eidosLogService.logDebug(EidosLogSeverity.Log, 'Mybiz page not correct (' + this.eidosObject?.objectId + ')');
    }
  }

  private navigateExtApp(options: EidosObjectOptions) {
    if (this.app && this.app.loaded) {
      const urlFragments = [this.externalAppsRoot, this.app.action];
      if (!!options && typeof options === "string") {
        options.split('/').forEach(item => urlFragments.push(item));
      }
      this.router.navigate(urlFragments);
      this.eidosUtilityService.toggleMenuStatusIfNotPinned(true);
    } else if (this.item && this.item.disabled !== true) {
      this.router.navigate(this.item.getRoute(this.externalAppsRoot));
      this.eidosUtilityService.toggleMenuStatusIfNotPinned(true);
    }
    else {
      this.eidosLogService.logDebug(EidosLogSeverity.Log, this.app!.action + ' action app is not loaded')
    }
  }
}
