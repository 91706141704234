import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IEidosFiltersRegistry } from '@common/models/eidos-filters.models';
import { EidosFilterComponent } from '../eidos-filter/eidos-filter.component';

@Component({
  selector: 'eidos-filter-text',
  templateUrl: './eidos-filter-text.component.html',
  styleUrls: ['./eidos-filter-text.component.scss']
})
export class EidosFilterTextComponent extends EidosFilterComponent {

  protected _value: IEidosFiltersRegistry["text"]["valueType"];
  @Input() public get value(): IEidosFiltersRegistry["text"]["valueType"] {
    return this._value;
  }
  public set value(val: IEidosFiltersRegistry["text"]["valueType"]) {
    this._value = val;
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<IEidosFiltersRegistry["text"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["text"]["valueType"]>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["number"]["config"]}
   * @memberof EidosFilterNumberComponent
   */
  @Input() public config: IEidosFiltersRegistry["text"]["config"];

  constructor() {
    super();
  }

  public keyDown(event: any) {
    this.onKeyDown.emit(event);
  }
}
