import { Component, Input, OnInit } from '@angular/core';

import { IEidosInputSliderTooltipConfig } from '@common/models/eidos-inputs.models';
import { EidosInputBaseComponent } from '@common/components/entities/inputs/eidos-input-base.component';

@Component({
  selector: 'eidos-input-sliderrange',
  templateUrl: './eidos-input-sliderrange.component.html',
  styleUrls: ['./eidos-input-sliderrange.component.scss']
})
export class EidosInputSliderRangeComponent extends EidosInputBaseComponent<number> implements OnInit {
  /**
   * Slider min value
   *
   * @type {number}
   * @memberof EidosInputSliderRangeComponent
   */
  @Input() public min: number;
  /**
   * Slider max value
   *
   * @type {number}
   * @memberof EidosInputSliderRangeComponent
   */
  @Input() public max: number;
  /**
   * Slider tooltip
   *
   * @type {IEidosInputSliderTooltipConfig}
   * @memberof EidosInputSliderRangeComponent
   */
  @Input() public tooltip: IEidosInputSliderTooltipConfig;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.min) {
      this.value = this.min;
    }
    if (this.max) {
      this.otherValue = this.max;
    }
  }
}