import { SafeBaseObject } from "@app/core/models/base-object.models";
import { merge } from "lodash";


export interface IReservationHotelBrand {
  HotelBrandID?: number;
  HotelBrandName?: string;
}

export class ReservationHotelBrand extends SafeBaseObject {
  hotelBrandID = 0;
  hotelBrandName = '';
  status?: string = 'A'

  constructor(data?: IReservationHotelBrand) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationHotelChain {
  HotelChainID?: number;
  HotelChainName?: string;
}

export class ReservationHotelChain extends SafeBaseObject {
  hotelChainID = 0;
  hotelChainName = '';
  status?: string = 'A'

  constructor(data?: IReservationHotelChain) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationAPIGetHotelBrand {
  HotelBrandID?: number;
  HotelBrandName?: string;
}

export interface IReservationAPIManageHotelBrand {
  HotelBrandID?: number;
  HotelBrandName?: string;
  Status?: string;
}

export interface IReservationAPIGetHotelChain {
  HotelChainID?: number;
  HotelChainName?: string;
}
export interface IReservationAPIManageHotelChain {
  HotelChainID?: number;
  HotelChainName?: string;
  Status?: string;
}

export class ReservationHotelUnique extends SafeBaseObject {
  hotelUniqueID = 0
  hotelBrandID = 0
  hotelBrandName = ''
  hotelChainID = 0
  hotelChainName = ''
  hotelDisplayName = ''
  hotelCod = ''
  hotelName = ''
  countryCod = ''
  country = ''
  cityID = 0
  city = ''
  geoTreeID = 0
  geoTreeName = ''
  address = ''
  address2 = ''
  zip = ''
  zip2 = ''
  mail = ''
  phonePrefix = ''
  phone = ''
  latitude = 0
  longitude = 0
  mainServiceID = 0
  serviceLongName = ''
  description = ''
  longDescription = ''
  hotelLevel = 3
  status?: string = 'A'
  services: ReservationhotelUniqueService[] = []

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
      if (data.Services) this.services = data.Services.map((s: any) => new ReservationhotelUniqueService(s))
    }
  }
}

export class ReservationhotelUniqueService extends SafeBaseObject {
  hotelUniqueID: number = 0
  serviceID: number = 0
  serviceLongName: string = ''
  serviceShortName: string = ''
  serviceDescription: string = ''
  supplierName: string = ''
  tradingCompany: string = ''

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}


export interface IReservationAPIGetHotelUnique {
  HotelUniqueID?: number
  HotelCod?: string
  HotelName?: string
  HotelBrand?: string
  HotelChain?: string
  CountryCod?: string
  Country?: string
  CityID?: number
  City?: string
  GeoTreeID?: number
  Address?: string
  Latitude?: number
  Longitude?: number
  PackageID?: number
}

export interface IReservationAPIManageHotelUnique {
  HotelUniqueID?: number
  HotelCod?: string
  HotelName?: string
  HotelBrandID?: number
  HotelChainID?: number
  CountryCod?: string
  CityID?: number
  GeoTreeID?: number
  Address?: string
  Address2?: string
  Zip?: string
  Zip2?: string
  Mail?: string
  PhonePrefix?: string
  Phone?: string
  Latitude?: number
  Longitude?: number
  MainServiceID?: number
  HotelLevel?: number
  Description?: string
  LongDescription?: string
  Status?: string;
}

export interface IReservationTSHotelSetup {
  HotelUniqueSourceID?: number
  CompanyID?: number
  CompanyName?: string
  SiteID?: number
  Currency?: string
  Source?: string
  SourceID?: number
  ServiceID?: number
  SupplierName?: string
  SupplierID_Link?: number
  SupplierID?: number
  ServiceName?: string
  Zip?: string
  Address?: string
  City?: string
  CityID?: number
  ServiceID_Link?: number
  ServiceName_Link?: string
}

export class ReservationTSHotelSetup extends SafeBaseObject {
  hotelUniqueSourceID = 0
  companyID = 0
  companyName = ''
  currency = ''
  siteID = 0
  source = ''
  sourceID = 0
  supplierName = ''
  supplierIDLink = 0
  supplierID = 0
  serviceID = 0
  serviceName = ''
  zip = ''
  address = ''
  city = ''
  cityID = 0
  serviceIDLink = 0
  serviceNameLink = ''

  constructor(data?: IReservationTSHotelSetup) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}

export interface IReservationAPIGetHotelUniqueSource {
  HotelUniqueSourceID?: number;
  CompanyName?: number;
  Source?: number;
  SourceID?: number;
  SupplierName?: number;
  ServiceName?: number;
  City?: number;
  Address?: number;
  Zip?: number;
  PackageID?: number;
}
export interface IReservationAPIManageHotelUniqueSource {
  HotelUniqueSourceID?: number
  Status?: string
}

export class ReservationSupplier implements IReservationApiSupplier {
  SupplierID: number = -1;
  SupplierName: string = '';
  Phone?: number;
  Email?: string;
  MainContact?: string;
  CountryCod?: string;
  CountyID?: number;
  StateID?: number;
  SupplierAccountCod?: string;
  Buyer?: string;
  Status: string = 'A';
  Country?: string;
  Address?: string;
  Currency?: string;
  CityID?: number;
  City?: string;

  get CityAndSupplierName(): string {
    return (!!this.City ? this.City + ' - ' : '') + this.SupplierName;
  }

  constructor(data?: IReservationApiSupplier) {
    if (data) {
      merge(this, data);
    }
  }
}

export interface IReservationApiSupplier {
  SupplierID: number;
  SupplierName: string;
  CityAndSupplierName: string;
  Phone?: number;
  Email?: string;
  MainContact?: string;
  CountryCod?: string;
  CountyID?: number;
  StateID?: number;
  SupplierAccountCod?: string;
  Buyer?: string;
  Status: string;
  Country?: string;
  Address?: string;
  Currency?: string;
  CityID?: number;
  City?: string;
}

export class ReservationGeotreeCitySelect extends SafeBaseObject {
  city: string = '';
  cityID: number = 0;
  citySearchable: boolean = true;
  country: string = '';
  countryID: number = 0;
  geoTreeID: number = 0;
  geoTreeName: string = '';
  isCity: boolean = true;
  isPOI = false
  isPort = false;
  portCod = ''

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
      this.addBooleanProperty('isPort', data, 'IsPort');
    }
  }
}

export interface IReservationApiGeotreeCitySelectParams {
  City?: string;
  CountryID?: number;
  PageSize?: number;
  PageNum?: number;
}

export class ReservationCityLikedGeoTree extends SafeBaseObject {
  cityID = 0
  departmentID = 0
  geoTreeID = 0
  geoTreeName = ''

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }
}
export class ReservationGeotreeCity extends SafeBaseObject {
  city: string | null = '';
  cityID: number | null = 0;
  citySearchable: boolean = true;
  country: string = '';
  countryID: number | null = 0;
  isCity: boolean = true;
  cityCity: string = '';
  cityRgn: string = '';
  cityCruise: string = '';
  cityCod: string = '';
  portCod: string = '';
  cityStatusID: number = 0;
  cityTypeID: number = 0;
  latitude: number = 0;
  longitude: number = 0;
  circleLatitude: number = 0;
  circleLongitude: number = 0;
  mainIataCod: string = '';
  isPOI: boolean = false;
  geoTree: ReservationCityLikedGeoTree[] = []


  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
      if (data.GeoTree) this.geoTree = data.GeoTree.map((g: any) => new ReservationCityLikedGeoTree(g))
    }
  }
}

