<div class="eidos-modals">
    <dx-popup *ngFor="let modal of openModals" [width]="modal.width ?? '40%'" [height]="modal.height ?? '40%'"
        [position]="modal.position ?? 'center'" [dragEnabled]="false" [showTitle]="false"
        [closeOnOutsideClick]="modal.closeOnOutsideClick ?? true" [showCloseButton]="false"
        [container]="'.eidos-modals'" [visible]="modal.open!" (onShown)="modal.onOpened?.($event)"
        (onHidden)="onModalClosed(modal.modalID!, $event)"
        [wrapperAttr]="{ class: 'eidos-modal-popup ' + (modal.cssClass ?? '') }">

        <div *dxTemplate="let data of 'content'" class="h-100">
            <ng-container *ngIf="modal">
                <ng-container [ngSwitch]="modal.type">

                    <ng-container *ngSwitchCase="IEidosModalType.Basic">
                        <ng-container [ngTemplateOutlet]="basicTemplate"
                            [ngTemplateOutletContext]="{modal}"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="IEidosModalType.Structured">
                        <ng-container [ngTemplateOutlet]="structuredTemplate"
                            [ngTemplateOutletContext]="{modal}"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="IEidosModalType.Custom">
                        <ng-container [ngTemplateOutlet]="modal.template?.() ?? noTemplateMessage"
                            [ngTemplateOutletContext]="{modal}"></ng-container>
                    </ng-container>

                    <ng-template #noTemplateMessage>
                        <div class="d-flex flex-column">
                            <h2 class="flex-grow-1">No template provided for modal, please provide a template or use the
                                basic modal type.</h2>

                            <eidos-button class="text-center" [size]="'normal'" (btnClick)="modal.open = false"
                                [stylingMode]="'contained'" [text]="'OK'">
                            </eidos-button>
                        </div>
                    </ng-template>

                </ng-container>
            </ng-container>
        </div>

    </dx-popup>
</div>

<ng-template #basicTemplate let-modal="modal">
    <div *ngIf="modal" class="basic-modal d-flex flex-column">

        <h2 class="basic-modal-title" [innerHTML]="(modal.title?.() ?? '') | safeHtml"></h2>

        <p [innerHTML]="(modal.body?.() ?? '') | safeHtml"></p>

        <div class="overflow-hidden">
            <ng-container *ngIf="modal.formFields?.length" [ngTemplateOutlet]="lightFormTemplate"
                [ngTemplateOutletContext]="{modal}"></ng-container>
        </div>

        <iframe *ngIf="modal.open && modal.iframeUrl" [src]="modal.iframeUrl" class="h-100 w-100 pb-3"></iframe>

        <div *ngIf="modal.buttonsConfig?.length; else defaultButtonTemplate" class="d-flex justify-content-between">

            <ng-container [ngTemplateOutlet]="buttonsTemplate"
                [ngTemplateOutletContext]="{modal, buttons: getButtonsConfig(modal, 'left')}"></ng-container>
            <ng-container [ngTemplateOutlet]="buttonsTemplate"
                [ngTemplateOutletContext]="{modal, buttons: getButtonsConfig(modal, 'center')}"></ng-container>
            <ng-container [ngTemplateOutlet]="buttonsTemplate"
                [ngTemplateOutletContext]="{modal, buttons: getButtonsConfig(modal, 'right')}"></ng-container>

        </div>

        <ng-template #defaultButtonTemplate>
            <eidos-button class="text-center" [size]="'normal'" (btnClick)="modal.open = false"
                [stylingMode]="'contained'" [text]="'OK'">
            </eidos-button>
        </ng-template>

    </div>
</ng-template>

<ng-template #structuredTemplate let-modal="modal">
    <div *ngIf="modal" class="structured-modal d-flex flex-column">

        <eidos-modal-title class="structured-modal-title py-3" [title]="modal.title?.() ?? ''"
            [subtitle]="modal.subtitle?.() ?? ''"></eidos-modal-title>

        <div class="structured-modal-body">
            <ng-container *ngIf="modal.template" [ngTemplateOutlet]="modal.template()"
                [ngTemplateOutletContext]="{modal}">
            </ng-container>

            <div class="overflow-hidden">
                <ng-container *ngIf="modal.formFields?.length" [ngTemplateOutlet]="lightFormTemplate"
                    [ngTemplateOutletContext]="{modal}"></ng-container>
            </div>
        </div>

        <div class="structured-modal-footer pt-3">

            <ng-container *ngIf="modal.buttonsConfig?.length; else defaultButtonsTemplate">

                <ng-container [ngTemplateOutlet]="buttonsTemplate"
                    [ngTemplateOutletContext]="{modal, buttons: getButtonsConfig(modal, 'left')}"></ng-container>
                <ng-container [ngTemplateOutlet]="buttonsTemplate"
                    [ngTemplateOutletContext]="{modal, buttons: getButtonsConfig(modal, 'center')}"></ng-container>
                <ng-container [ngTemplateOutlet]="buttonsTemplate"
                    [ngTemplateOutletContext]="{modal, buttons: getButtonsConfig(modal, 'right')}"></ng-container>

            </ng-container>

            <ng-template #defaultButtonsTemplate>

                <eidos-button [size]="'normal'" (btnClick)="modal.open = false" [stylingMode]="'contained-danger'"
                    [text]="'CANCEL'">
                </eidos-button>

                <!-- <eidos-button class="ms-2" [size]="'normal'" [stylingMode]="'contained-success'" [text]="'CONFIRM'">
                </eidos-button> -->

            </ng-template>

        </div>

    </div>
</ng-template>



<ng-template #buttonsTemplate let-modal="modal" let-buttons="buttons">

    <div class="d-flex">

        <div *ngFor="let button of buttons; let i = index" [ngClass]="{'ms-2': i != 0}">

            <eidos-button *ngIf="!button.hidden?.()" [stylingMode]="button.stylingMode ?? 'contained'"
                [size]="button.size ?? 'normal'" [text]="button.text?.() ?? 'OK'" [isDisabled]="button.disabled?.()"
                [iconConfiguration]="button.iconConfiguration ?? null"
                (btnClick)="button.onClick ? button.onClick() : modal.open = false">
            </eidos-button>

        </div>

    </div>

</ng-template>

<ng-template #lightFormTemplate let-modal="modal">

    <eidos-light-form [validationGroupName]="modal.modalKey" [fields]="modal.formFields" colsClasses="row-cols-4"
        submitButtonClass="d-none" clearButtonClass="d-none" [(formValues)]="modal.formValues">
    </eidos-light-form>

</ng-template>

<ng-template #iframeTemplate>
</ng-template>
