import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { debounceTime, filter, takeUntil } from 'rxjs';

import { ICoreSearchTabBaseConfiguration, ICoreSearchTabConfiguration, ICoreSearchResultSelection, CoreSearchResultsViewConfig, CoreSearchStandardPagingConfiguration } from '@common/models/eidos-search.model';
import { EidosEntityService } from '@common/services/eidos-entity.service';
import { CoreDrawableComponentInterface } from '../../core-drawable-component.interface';
import { EidosBaseComponent } from '../../eidos-base.component';
import { EidosFilterFormComponent } from '../../eidos-filter-form/eidos-filter-form.component';
import { DynamicApiResponsePaginatedModel } from '@app/core/models/dynamic-api-response.model';
import { EidosFilterConfiguration, IEidosFilterDateRangeConfig } from '@app/core/models/eidos-filters.models';
import { EidosButtonListComponent } from '../../eidos-button-list/eidos-button-list.component';
import { EidosSearchResultsComponent } from '../eidos-search-results/eidos-search-results.component';
import { CoreSearchService, ICoreSearchServiceSearchCommandOptions } from '../core-search.service';

@Component({
  selector: 'eidos-search-tab',
  templateUrl: './eidos-search-tab.component.html',
  styleUrls: ['./eidos-search-tab.component.scss'],
})
export class EidosSearchTabComponent<E, C extends ICoreSearchTabConfiguration, S extends EidosEntityService> extends EidosBaseComponent implements CoreDrawableComponentInterface {
  /**
   * Search component service
   *
   * @type {CoreSearchService}
   * @memberof EidosSearchTabComponent
   */
  @Input() coreSearchService?: CoreSearchService;

  @ViewChild(EidosFilterFormComponent, { static: false })
  eidosFilterForm?: EidosFilterFormComponent<S>;

  @ViewChild(EidosButtonListComponent, { static: false })
  eidosButtonList?: EidosButtonListComponent;

  public buttons: any[] = [];

  /**
   * Element CSS class
   *
   * @type {string}
   * @memberof EidosSearchComponent
   */
  @Input() public cssClass: string = '';
  /**
   * Element ID class
   *
   * @type {string}
   * @memberof EidosSearchComponent
   */
  @Input() public cssId: string = '';
  /**
   * Extended filter template
   *
   * @type {TemplateRef<any>}
   * @memberof EidosSearchTabComponent
   */
  @Input() public filterTemplate?: TemplateRef<any>;
  /**
   * Search config
   *
   * @type {C}
   * @memberof EidosSearchTabComponent
   */
  protected _config: C;
  public get config(): C {
    return this._config;
  }
  @Input() public set config(value: C) {

    this._config = value;
    this.buttons = [];

    if (this._config) {

      if (this._config.button) {
        this.buttons = [...this._config.button];
      }

      if (this._config.filtersConfig.length == 0) {
        this.search();
      } else {

        if (this._config.isSidebarSearch) {
          this.upperFilters = this._config.filtersConfig.filter(f => !f.showOnMoreFilters);
          this.sidebarFilters = this._config.filtersConfig.filter(f => !!f.showOnMoreFilters);
        } else {
          this.upperFilters = this._config.filtersConfig;
        }

        this._config.filtersConfig.map((itm: any) => {

          if (itm.type.toUpperCase() == 'DATERANGE') {

            let a: IEidosFilterDateRangeConfig = itm as IEidosFilterDateRangeConfig;
            if (!a.layoutDirection) {
              a.layoutDirection = 'horizontal';
            }
            if (!a.from) {
              a.from = {
                displayFormat: 'yyyy-MM-dd'
                , pickerType: 'calendar'
                , dateValueType: 'date'
                , type: ''
              }
            };

            if (!a.to) {
              a.to = {
                displayFormat: 'yyyy-MM-dd'
                , pickerType: 'calendar'
                , dateValueType: 'date'
                , type: ''
              }
            };

          }
          return itm;
        });
      }
    }
  }
  /**
   * Filters to show in standard filter form
   *
   * @type {EidosFilterConfiguration[]}
   * @memberof EidosSearchTabComponent
   */
  public upperFilters: EidosFilterConfiguration[] = [];
  /**
   * Filters to show in sidebar
   *
   * @type {EidosFilterConfiguration[]}
   * @memberof EidosSearchTabComponent
   */
  public sidebarFilters: EidosFilterConfiguration[] = [];
  /**
   * Current filters (2-way binding)
   *
   * @type {*}
   * @memberof EidosSearchTabComponent
   */
  protected _filters: any;
  public get filters(): any {
    return this._filters;
  }
  @Input()
  public set filters(value: any) {

    this._filters = value;
    this.filtersChange.emit(this._filters);
  }
  @Output() public filtersChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Search results
   *
   * @type {Array<E>}
   * @memberof EidosSearchTabComponent
   */
  public results?: Array<E>;
  /**
   * Reset filters emitter
   *
   * @type {EventEmitter<any>}
   * @memberof EidosSearchTabComponent
   */
  @Output()
  public onResetFilters: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Generic search tab configuration
   *
   * @type {Array<ICoreSearchTabBaseConfiguration>}
   * @memberof EidosSearchTabComponent
   */
  @Input()
  public genericTabConfig: Array<ICoreSearchTabBaseConfiguration> = [];
  /**
   * Loading flag
   *
   * @type {boolean}
   * @memberof EidosSearchTabComponent
   */
  get loading(): boolean {
    return this.coreSearchService?.loading ?? false;
  }
  /**
   * Emits the selected tab
   *
   * @type {EventEmitter<string>}
   * @memberof EidosSearchTabComponent
   */
  @Output()
  public selectTab: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Service to call search handler
   *
   * @type {S}
   * @memberof EidosSearchTabComponent
   */
  @Input()
  public searchService?: S;
  /**
   * Results views templates
   *
   * @type {QueryList<TemplateRef<any>>}
   * @memberof EidosSearchTabComponent
   */
  @ViewChildren(TemplateRef)
  resultsTemplates: QueryList<TemplateRef<any>> = new QueryList<
    TemplateRef<any>
  >();
  /**
   * Selected results emitter
   *
   * @type {(EventEmitter<ICoreSearchResultSelection>)}
   * @memberof EidosSearchTabComponent
   */
  @Output() public onResultsSelected: EventEmitter<ICoreSearchResultSelection> = new EventEmitter<ICoreSearchResultSelection>();
  /**
   * Enable standard paging
   *
   * @type {CoreSearchStandardPagingConfiguration}
   * @memberof EidosSearchTabComponent
   */
  @Input() searchPagingConfiguration: CoreSearchStandardPagingConfiguration = new CoreSearchStandardPagingConfiguration();
  /**
   * Current results view config
   *
   * @private
   * @type {CoreSearchResultsViewConfig}
   * @memberof EidosSearchTabComponent
   */
  private _currentResultsView: CoreSearchResultsViewConfig = { id: "rows", icon: "detailslayout" }
  @Input() public get currentResultsView(): CoreSearchResultsViewConfig {
    return this._currentResultsView;
  }
  public set currentResultsView(val: CoreSearchResultsViewConfig) {
    this._currentResultsView = val;
    this.currentResultsViewChange.emit(this._currentResultsView);
  }
  @Output() public currentResultsViewChange: EventEmitter<CoreSearchResultsViewConfig> = new EventEmitter<CoreSearchResultsViewConfig>();

  @Output()
  public onButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onCustomButtonClickFromSP: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onCustomButtonClickFromEntity: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onSelectionChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onRowPrepared: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onCellPrepared: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onEditorPreparing: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onContentReady: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public OnGroupButtonClick: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Results component ref
   *
   * @type {(EidosSearchResultsComponent<E> | undefined)}
   * @memberof EidosSearchTabComponent
   */
  @ViewChild('resultsCmp', { static: false }) searchResultsTemplate: EidosSearchResultsComponent<E> | undefined;

  public BtnName: string = '';
  public BtnIcon: string = '';
  public BtnEntity: string = '';
  /**
   * Is multiselect enabled
   *
   * @type {boolean}
   * @memberof EidosSearchTabComponent
   */
  multiselectEnabled: boolean = false;
  /**
   * Result checkability getter
   *
   * @memberof EidosSearchTabComponent
   */
  @Input() isResultCheckable: (item: E, selected: E[], ...args: any[]) => boolean = () => true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.config?.resultsConfig[0]) this.currentResultsView = this.config.resultsConfig[0];

    this.coreSearchService?.searchCommand$
      .pipe(
        takeUntil(this.subscription),
        debounceTime(1000),
        filter((options: ICoreSearchServiceSearchCommandOptions) => !options.tabIdentifier || options.tabIdentifier === this.config.identifier)
      )
      .subscribe((options: ICoreSearchServiceSearchCommandOptions) => this.search(options));
  }
  /**
   * Calls configurated search handler
   *
   * @memberof EidosSearchTabComponent
   */
  public search(options?: ICoreSearchServiceSearchCommandOptions): void {

    if (this.config && this.searchService && this.coreSearchService) {
      try {

        // Calls the configured of inputed service to retrieve entities
        if ((<any>this.searchService)[this.config.searchAPI]) {

          this.coreSearchService.loading = true;

          // Remove undefined or '' properties from filter object
          const cleanFilter = this.filters ? Object.keys(this.filters)
            .filter((key) => !['', undefined, 'undefined'].includes(key))
            .reduce((obj: any, key) => {

              if (this.filters[key] != undefined) {
                if (Array.isArray(this.filters[key])) {
                  if (this.filters[key].length > 0) {
                    obj[key] = this.filters[key]
                  }
                } else {
                  obj[key] = this.filters[key]
                }
              }

              return obj;
            }, {}) : {};

          if (this.searchPagingConfiguration.enabled) {
            cleanFilter.PageNum = options?.resetPaging ? 1 : this.coreSearchService.currentPaging.pageIndex ?? 1;
            cleanFilter.PageSize = this.searchPagingConfiguration.pageSize;
          }

          this.results = [];

          (<any>this.searchService)[this.config.searchAPI](cleanFilter).subscribe({
            next: (results: Array<E> | DynamicApiResponsePaginatedModel<E>) => {
              if (this.searchPagingConfiguration.enabled && !Array.isArray(results)) {

                this.results = results.content;

                this.coreSearchService!.currentPaging.pageIndex = results.pageNumber;
                this.coreSearchService!.currentPaging.pageTotal = results.pageTotal;
                this.coreSearchService!.currentPaging.recordsCount = results.recordsCount;
              } else {
                this.results = Array.isArray(results) ? results : [];
              }
            },
            error: (err:any) => {
              console.log(err)
              this.results = [];
              this.coreSearchService!.loading = false;
            },
            complete: () => {
              this.coreSearchService!.loading = false;
            }
          });
        }
      } catch (e: unknown) {
        throw e;
      }
    }
  }
  /**
   * Handler multiselection submit
   *
   * @memberof EidosSearchTabComponent
   */
  public onMultiResultsSubmit(): void {

    const event: ICoreSearchResultSelection = {
      items: this.searchResultsTemplate?.resultsSelected ?? [],
      id: (this.searchResultsTemplate?.resultsSelected ?? []).map(item => this.config.resultIdMap ? this.config.resultIdMap(item) : (<any>item).id),
      additionalProperties: {
        tabIdentifier: this.config.identifier ?? ''
      }
    };

    this.onResultsSelected.emit(event);
  }


  public _onContentReady(event: any) {
    this.onContentReady.emit(event);
  }

  public resetFilters() {
    this.onResetFilters.emit();
  }

  public _onCustomButtonClick(event: any) {
    let myEvent: any = event;

    // Ho cliccato un bottone generato dal codice della SP
    if (event.btnName) {
      myEvent = event.event;
    }

    const keys: string = myEvent.component.option('keyExpr');
    const value: string = myEvent.data[keys].toString();

    let resultId: ICoreSearchResultSelection = { id: '', items: undefined };

    if (event.URLFiled) {
      const newURL: string = myEvent.data[event.URLFiled].toString();

      if (newURL) {
        resultId = {
          id: '',
          items: undefined,
          additionalProperties: { URLFiled: newURL, event: event },
        };
      } else {
        resultId = {
          id: '',
          items: undefined,
          // Versione funzionate a regime
          // additionalProperties: {[keys]:value},
          // Versione x continuare ancora un po'
          additionalProperties: { id: value, event: event },
        };
      }
    } else {
      resultId = {
        id: '',
        items: undefined,
        // Versione funzionate a regime
        // additionalProperties: {[keys]:value},
        // Versione x continuare ancora un po'
        additionalProperties: { id: value, event: event },
      };
    }

    this.onResultsSelected.emit(resultId);
  }

  public _onSelectionChanged(event: any) {
    this.onSelectionChanged.emit(event);
  }

  public _onRowPrepared(event: any) {
    this.onRowPrepared.emit(event);
  }

  public _onCellPrepared(event: any) {
    this.onCellPrepared.emit(event);
  }

  public _onEditorPreparing(event: any) {
    this.onEditorPreparing.emit(event);
  }

  public handleFilterCategoryBtnClick(event: any) {
    this.onCustomButtonClickFromEntity.emit(event);
  }

  protected _onCustomButtonClickFromSP(event: any) {
    this.onCustomButtonClickFromSP.emit(event);
  }

  protected _PopupVisible: boolean = false;

  @Input()
  public set PopupVisible(value: boolean) {
    this._PopupVisible = value;
  }
  public get PopupVisible(): boolean {
    return this._PopupVisible;
  }

  protected _OnButtonClick(event: any) {
    this.onButtonClick.emit(event);
  }

  _onGroupButtonClick(event: any) {
    this.OnGroupButtonClick.emit(event);
  }
}
