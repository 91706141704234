<ng-template #menuItemContent>
  <eidos-icon class="menu-list-item-icon" [iconConfiguration]="menuItem.getSmallIcon()"></eidos-icon>
  {{menuItem.label}}
  <span class="d-flex" *ngIf="menuItem.children && menuItem.children.length">
        <span class="d-flex"></span>
        <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
          expand_more
        </mat-icon>
  </span>
</ng-template>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div style="visibility: hidden; position: fixed;"
[style.left]="menuTopLeftPosition.x"
[style.top]="menuTopLeftPosition.y"
[matMenuTriggerFor]="rightMenu">
</div>

<!-- context menu activable with rigth click -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item
    (click)="openInNewTab()">{{'context_menu.open_in_new_tab' | transloco}}</button>
    <button mat-menu-item
    (click)="copyToClipboard()">{{'context_menu.copy_in_clipboard' | transloco}}</button>
  </ng-template>
</mat-menu>

<a mat-list-item
   *ngIf="menuItem.children.length === 0"
   [ngStyle]="{'padding-left': (depth * 10) + 'px', 'color': menuItem.color, 'cursor': menuItem.disabled ? 'not-allowed' : 'pointer'}"
   (click)="selectItem()"
   uiSrefActive="active"
   [ngClass]="{'expanded': expanded}"
   (contextmenu)="onRightClick($event, menuItem)"
   class="menu-list-item">
  <ng-template [ngTemplateOutlet]="menuItemContent"></ng-template>
</a>

<a mat-list-item
   *ngIf="menuItem.children.length > 0"
   [ngStyle]="{'padding-left': (depth * 10) + 'px'}"
   [ngClass]="{'expanded': expanded}"
   class="menu-list-item"
   (click)="menuItemToggle()">
  <ng-template [ngTemplateOutlet]="menuItemContent"></ng-template>
</a>

<div *ngIf="expanded" [@listAnimation]="menuItem.children.length">
  <eidos-menu-item *ngFor="let child of menuItem.children"
                   [menuItem]="child"
                   [depth]="depth+1"
                   [selectedId]="selectedId"
                   class="menu-list-child-item">
  </eidos-menu-item>
</div>
