import { Component } from "@angular/core";

import { EidosConfigService } from "./config/eidos-config.service";
import { IEidosMenuAction } from "./config/environment.interface";
import { MenuActionType } from "./models/core-constant.model";
import { EidosUtilityService } from "./services/eidos-utility.service";

@Component({
  selector: 'app-main',
  template: ''
})
export class CoreBaseMainPageComponent {
  /**
   * Module name
   *
   * @protected
   * @type {string}
   * @memberof CoreBaseMainPageComponent
   */
  protected moduleName: string = "";

  constructor(
    protected coreConfigService: EidosConfigService,
    protected coreUtilityService: EidosUtilityService
  ) {
  }

  ngOnInit(): void {

    this.coreConfigService.currentModulesConfig
      .subscribe(modules => {
        const module = modules.find(module => module.moduleName === this.moduleName);
        this.coreUtilityService.hideTopbar.next(!!(module?.hideTopbar));
        this.coreUtilityService.hideSidebar.next(!!(module?.hideSidebar));
      });
  }
  /**
   * Return current module menu actions
   *
   * @protected
   * @param {IEidosMenuAction[]} originalActions Core actions
   * @param {IEidosMenuAction[]} moduleActions Additional custom module actions
   * @param {*} actionsEnum Enum of custom module actions ids
   * @param {string[]} [toRemoveCoreMenuActions=[]] Core actions to remove ids
   * @return {*}  {IEidosMenuAction[]}
   * @memberof CoreBaseMainPageComponent
   */
  protected addModuleMenuActions(originalActions: IEidosMenuAction[], moduleActions: IEidosMenuAction[], actionsEnum: any, toRemoveCoreMenuActions: string[] = []): IEidosMenuAction[] {

    return originalActions
      // Remove core actions which are not desired
      .filter((action) => !toRemoveCoreMenuActions.includes(action.id))
      // Concat module actions
      .concat(moduleActions)
      // Remove actions which are not from core or current module
      .filter((elem) =>
        Object.keys(actionsEnum).map(x => (<any>actionsEnum)[x]).includes(elem.id) ||
        Object.keys(MenuActionType).map(x => (<any>MenuActionType)[x]).includes(elem.id)
      )
      // Remove duplicates
      .filter((elem, index, self) => !(self.findIndex((i) => i.id == elem.id) !== index))
      // Sort
      .sort((a, b) => b.order - a.order);
  }
}