<content *ngIf="filtersConfiguration && !!filters; else missingConfigTemplate">
  <content
    *ngIf="filtersConfiguration.length > 0 && !isSearchWithCategoryConfig"
  >
    <div
      class="row eidos-filter-form-wrapper"
      [ngClass]="horizontalAlignCSS"
      [ngClass]="cssClass"
      [id]="cssId"
    >
      <div
        class="eidos-filter-wrapper"
        [ngClass]="
          (filterConfiguration.cssClasses ?? []).concat(
            filterConfiguration.layoutClasses ?? 'col-12'
          )
        "
        [hidden]="!!filterConfiguration.hidden && filterConfiguration.hidden()"
        *ngFor="let filterConfiguration of alwaysShownFilters"
      >
        <ng-container
          [ngTemplateOutlet]="filterTemplate ?? eidosFilterTemplate"
          [ngTemplateOutletContext]="{
            filters: filters,
            filterConfiguration: filterConfiguration,
            keydown: keyDown,
            scope: this,
            searchService: searchService
          }"
        >
        </ng-container>
      </div>
      <!--
          Add there parameters to eidos-filter-form-commands
          [disabledSearch]="!isDirty" [disabledClear]="!isDirty"
          to disable search without filters touching
        -->

      <eidos-filter-form-commands
        #eidosFilterFormCommand
        class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-1"
        [config]="commandsSettings"
        (onSubmit)="search($event)"
        (onEnableMultiselect)="onEnableMultiselect.emit($event)"
        (onSubmitMultiselect)="onSubmitMultiselect.emit()"
        (onClear)="resetFilters()">
      </eidos-filter-form-commands>

      <eidos-filter-form-show-more
        *ngIf="hasMoreFilters"
        [(moreFilters)]="showMoreFilters"
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 eidos-filter-form-show-more"
      >
      </eidos-filter-form-show-more>

      <div
        [hidden]="!showMoreFilters"
        *ngFor="let filterConfiguration of moreFilters"
        class="eidos-filter-wrapper"
        [ngClass]="
          (filterConfiguration.cssClasses ?? []).concat(
            filterConfiguration.layoutClasses ?? 'col-12'
          )
        "
      >
        <ng-container
          [ngTemplateOutlet]="filterTemplate ?? eidosFilterTemplate"
          [ngTemplateOutletContext]="{
            filters: filters,
            filterConfiguration: filterConfiguration,
            keydown: keyDown,
            scope: this,
            searchService: searchService
          }"
        >
        </ng-container>
      </div>

      <ng-content></ng-content>

    </div>

    <ng-template #propertyMissingTemplate
      >The filter property is missing in the filter object</ng-template
    >
  </content>

  <content *ngIf="filtersConfiguration.length == 0">
    <eidos-filter-form-commands
      #eidosFilterFormCommand
      class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-1"
      [config]="commandsSettings"
      (onSubmit)="search($event)"
      (onClear)="resetFilters()">
    </eidos-filter-form-commands>
  </content>

  <content *ngIf="isSearchWithCategoryConfig">
    <eidos-filter-category
      [moreFilters]="moreFilters"
      [(filters)]="filters"
      [filterConfiguration]="isSearchWithCategoryConfig"
      [searchService]="searchService"
      [buttonText]="filterCategoryBtnText"
      [buttonIcon]="filterCategoryBtnIcon"
      (search)="search($event)"
      (buttonClick)="_buttonClick()"
    >
    </eidos-filter-category>
  </content>
</content>

<ng-template #missingConfigTemplate
  >Missing filter form or object configuration!</ng-template
>

<ng-template
  #eidosFilterTemplate
  let-filterConfiguration="filterConfiguration"
  let-filters="filters"
  let-searchService="searchService"
  let-keydown="keydown"
  let-scope="scope"
>
  <eidos-filter
    #eidosFilter
    [(value)]="filters[filterConfiguration.property]"
    [(otherValue)]="filters[filterConfiguration.otherProperty]"
    (onKeyDown)="keydown($event, scope)"
    [searchService]="searchService"
    [(otherValue)]="filters[filterConfiguration.otherProperty ?? '']"
    [config]="filterConfiguration"
  ></eidos-filter>
</ng-template>
