import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";
import { AppModule } from "@app/app.module";
import { DateTime } from "luxon";
import { IReservationApiProductCalendar, IReservationApiProductItinerary, IReservationApiServiceImages, IReservationApiServiceOptions, IReservationApiSuggestedItems } from "./res-package.model";
import { SafeBaseObject } from "@app/core/models/base-object.models";

export interface IReservationApiPackageInfoParameters {
  PackageID: number;
  PackageTravelDateID: number
}
export interface IReservationApiPackageInfo {
  EcmData: Array<IReservationApiPackageEcmData>;
  CalendarDate: Array<IReservationApiProductCalendar>;
  Itineraries: Array<IReservationApiProductItinerary>;
  ServiceOptions: Array<IReservationApiServiceOptions>;
  ServiceImages: Array<IReservationApiServiceImages>;
  SuggestedItems: Array<IReservationApiSuggestedItems>;
  Categories?: {
    CategoryCod: string;
    Category: string;
  }[];
  Companies?: {
    CompanyID: number;
    CompanyName: string;
  }[];
}
export interface IReservationApiPackageEcmData {
  PackageID?: number;
  PackageDayID?: number;
  VoyageID?: number;
  ServiceID?: number;
  FldValue: string;
  EntID: number;
  FldCod: string;
  Context?: string
}

class ReservationBaseEcmData {

  public title: string;
  public description: string;
  public descriptionSafe: SafeHtml;
  public inclusion: string;
  public inclusionSafe: SafeHtml;
  public exclusion: string;
  public exclusionSafe: SafeHtml;
  public importantNotes: string;
  public importantNotesSafe: SafeHtml;

  public itinearyPdfSafe: SafeResourceUrl;
  public itinearyPdf: string;
  public imageMapSafe: SafeUrl;
  public imageMap2:boolean;
  public imageMap: string;
  public imageSrcSafe: SafeUrl;
  public imageSrc: string;
  public imagesSafe: Array<SafeUrl>;
  public images: Array<string>;
  public services: Array<ReservationServiceEcmData>;
  public calendarDays: Array<ReservationCalendarEcmData>;

  constructor() {
    this.title = '';
    this.description = '';
    this.descriptionSafe = '';
    this.itinearyPdf = '';
    this.itinearyPdfSafe = '';
    this.imageMapSafe = '';
    this.imageMap2 = false;
    this.imageMap = '';
    this.imageSrcSafe = '';
    this.imageSrc = '';
    this.imagesSafe = [];
    this.images = [];
    this.services = [];
    this.calendarDays = [];
    this.inclusion = '';
    this.inclusionSafe = '';
    this.exclusion = '';
    this.exclusionSafe = '';
    this.importantNotes = '';
    this.importantNotesSafe = '';
  }
}
class ReservationServiceEcmData {
  public serviceID: number;
  public serviceName: string;
  public serviceTypeID?: number;
  public serviceTypeName?: string;
  public ecmaData: ReservationBaseEcmData;
  constructor(serviceID: number) {
    this.serviceID = serviceID;
    this.serviceName = '';
    this.ecmaData = new ReservationBaseEcmData();
  }
}
class ReservationDayEcmData {
  public packageDayID: number;
  public packageID: number;
  public ecmaData: ReservationBaseEcmData;
  constructor(packageDayID: number, packageID: number) {
    this.packageDayID = packageDayID;
    this.packageID = packageID;
    this.ecmaData = new ReservationBaseEcmData();
  }
}
class ReservationCalendarEcmData {
  public calendarDay: DateTime;
  public ecmaData: ReservationBaseEcmData;
  constructor() {
    this.calendarDay = DateTime.invalid('not initialized');
    this.ecmaData = new ReservationBaseEcmData();
  }
}

export class ReservationPackageEcmData extends ReservationBaseEcmData {
  public loaded: boolean;
  public details: Array<ReservationDayEcmData> = [];

  private static sanitaizer: DomSanitizer | null = null;
  constructor() {
    super();
    this.loaded = false;
  }
  static mapEcmData(data: Array<IReservationApiPackageEcmData>, serviceID?: number): ReservationPackageEcmData {
    const ecmData = new ReservationPackageEcmData();
    if (this.sanitaizer === null) this.sanitaizer = AppModule.injector.get(DomSanitizer);

    const self = this;
    function setEcmData(d: IReservationApiPackageEcmData, ecmData: ReservationBaseEcmData): void {
      switch ((d.FldCod ?? '').toLowerCase()) {
        case 'title':
          ecmData.title = d.FldValue;
          break;
        case 'itinerary':
          ecmData.itinearyPdf = d.FldValue;
          if (self.sanitaizer !== null) ecmData.itinearyPdfSafe = self.sanitaizer.bypassSecurityTrustResourceUrl(ecmData.itinearyPdf);
          break;
        case 'mapsvg':
          break;
        case 'map':
          if(!ecmData.imageMap || ecmData.imageMap === '') {
            ecmData.imageMap2 = true;
            ecmData.imageMap = d.FldValue ?? '/assets/images/reservation/cruises-search/CruiseHeader01.jpg';
            if (self.sanitaizer !== null && !ecmData.imageMap.endsWith('.pdf')) ecmData.imageMapSafe = self.sanitaizer.bypassSecurityTrustUrl(ecmData.imageMap);
          }
          break;
        case 'mappng':
          ecmData.imageMap = d.FldValue;
          if (self.sanitaizer !== null) ecmData.imageMapSafe = self.sanitaizer.bypassSecurityTrustUrl(ecmData.imageMap);
          break;
        case 'mappng2':
          break;
        case 'mappng3':
          break;
        case 'description':
          ecmData.description = d.FldValue;
          if (self.sanitaizer !== null) ecmData.descriptionSafe = self.sanitaizer.bypassSecurityTrustHtml(ecmData.description);
          break;
        case 'inclusion':
          ecmData.inclusion = d.FldValue;
          if (self.sanitaizer !== null) ecmData.inclusionSafe = self.sanitaizer.bypassSecurityTrustHtml(ecmData.inclusion);
          break;
        case 'exclusion':
          ecmData.exclusion = d.FldValue;
          if (self.sanitaizer !== null) ecmData.exclusionSafe = self.sanitaizer.bypassSecurityTrustHtml(ecmData.exclusion);
          break;
        case 'importantnotes':
          ecmData.importantNotes = d.FldValue;
          if (self.sanitaizer !== null) ecmData.importantNotesSafe = self.sanitaizer.bypassSecurityTrustHtml(ecmData.importantNotes);
          break;

        case '':
          //no ope ... invalid
          break

        default:
          if (d.FldCod.toLowerCase().startsWith('image')) {

            if (d.FldCod.toLowerCase() === 'image1') {
              ecmData.imageSrc = d.FldValue;
              if (self.sanitaizer !== null) ecmData.imageSrcSafe = self.sanitaizer.bypassSecurityTrustUrl(ecmData.imageSrc);
            }

            ecmData.images.push(d.FldValue);
            if (self.sanitaizer !== null) ecmData.imagesSafe.push(self.sanitaizer.bypassSecurityTrustUrl(d.FldValue));
          }
          break;
      }
    }
    //mapping generic package media
    data.filter(item => !item.PackageDayID).filter(d =>
      (!!serviceID && d.ServiceID == serviceID)                         //service media
      ||
      (!serviceID && (d.ServiceID == d.PackageID)                       //package media
        ||
        (!d.ServiceID))                                                   //package media in booking context
    ).forEach(d => setEcmData(d, ecmData));

    //mapping generic details package media
    data.filter(item => !!item.PackageDayID && item.PackageID)
      .forEach(d => {
        let detail = ecmData.details.find(det => det.packageDayID == d.PackageDayID && det.packageID === d.PackageID);
        const newDetail = !detail;
        if (newDetail) detail = new ReservationDayEcmData(+d.PackageDayID!, d.PackageID!);
        setEcmData(d, detail!.ecmaData);
        if (newDetail) ecmData.details.push(detail!);
      });

    if (!serviceID) {
      //mapping service package media
      data.filter(d => !!d.ServiceID && d.ServiceID !== d.PackageID).map(d => {
        const services = ecmData.services.filter(s => s.serviceID === d.ServiceID);
        if (services.length == 0) {
          services.push(new ReservationServiceEcmData(d.ServiceID!));
          ecmData.services.push(services[0]);
        }
        const service = services[0];
        service.ecmaData = this.mapEcmData(data, service.serviceID)
      });
      ecmData.loaded = true;
    }

    return ecmData;

  }
}

export class ResServiceEMCDetails extends SafeBaseObject {
  serviceTypeID = 0
  serviceTypeName = ''
  serviceID = 0
  serviceName = ''
  serviceDescription = ''
  serviceImage1 = ''
  serviceImage2 = ''
  serviceImage3 = ''
  lobby1 = ''
  lobby2 = ''
  lobby3 = ''
  restaurant1 = ''
  restaurant2 = ''
  restaurant3 = ''
  pool1 = ''
  pool2 = ''
  pool3 = ''
  spa1 = ''
  spa2 = ''
  spa3 = ''
  terrace1 = ''
  terrace2 = ''
  terrace3 = ''
  fitness1 = ''
  fitness2 = ''
  fitness3 = ''
  bar1 = ''
  bar2 = ''
  bar3 = ''

  constructor(data?: any) {
    super();
    if (data) {
      this.updateData(data);
    }
  }

}
