<div class="eidos-filter-wrapper eidos-selectize-filter-wrapper">
  <p class="eidos-filter-label eidos-selectize-filter-label" *ngIf="config.label">{{ config.label }}</p>

  <eidos-input-selectize
    [searchService]="searchService" [api]="config.api" [keyField]="config.keyField"
    [asyncItems]="config.asyncItems" [asyncItemsError]="config.asyncItemsError"
    [loadingMode]="config.loadingMode" [items]="config.items" [cssClass]="cssClass" [cssId]="cssId"
    [(value)]="selectizeValue" (valueChange)="config.onValueChange"
    [showClearButton]="!!config.showClearButton" [placeholder]="config.placeholder ? config.placeholder : ''"
    [disabled]="!!config.disabled" [maxItems]="config.maxItems" [minSearchLength]="config.minSearchLength ?? 0"
    [searchFields]="config.searchField" [sortField]="config.sortField ?? ''"
    [displayField]="config.displayField" [displayFieldTag]="config.displayFieldTag" [valueField]="config.valueField"
    [noItemsFoundText]="config.noItemsFoundText ?? 'No items found'" [searchTextTooShortText]="config.searchTextTooShortText ?? 'Write to search...'"
    [checkUnique]="config.checkUnique ?? false" [customData]="config.apiParameter ?? undefined"
    (onFocusIn)="(!!config.onFocusIn && config.onFocusIn())" (onFocusOut)="(!!config.onFocusOut && config.onFocusOut())"

    >
  </eidos-input-selectize>
</div>
