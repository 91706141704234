import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EidosApiService } from '@app/core/services/eidos-api.service';
import { Subscription } from 'rxjs';

export interface IEidosUploadFileMethod {
  init:(fileName:string)=>void;
  reset:()=>void;
  getValue:()=>string;
}

export class EidosUploadFileMethod implements IEidosUploadFileMethod {
  init=(_:string)=>{}
  reset=()=>{}
  getValue=()=>{return ''}
}
@Component({
  selector: 'eidos-upload-file',
  templateUrl: './eidos-upload-file.component.html',
  styleUrls: ['./eidos-upload-file.component.scss']
})
export class EidosUploadFileComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload?: ElementRef;

  static AcceptMediaFileType = ['audio/*', 'video/*', 'image/*'];
  static AcceptImageFileType = ['image/*'];

  @Input()
  requiredFileType: Array<string> = [];

  @Input()
  overwrite?: boolean;

  @Input()
  versionSuffix?: string;

  @Input()
  versionMinDigit?: number;

  @Input()
  multiple: boolean = false

  @Input()
  autoUpload: boolean = true

  @Input()
  caption: string = 'choose file';

  @Input()
  callback?: (f:File[])=> void;

  private _files: File[] = [];
  get files(): File[] {
    return this._files;
  }
  @Input()
  set files(value: File[]) {
    this._files = value;
    this.filesChange.emit(value);
    this.fileName = value.map(f => f.name).join(', ');
  }

  @Output() filesChange: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Input()
  set method(value:IEidosUploadFileMethod) {
    value.init = this.methodInit.bind(this);
    value.reset = this.methodReset.bind(this);
    value.getValue = this.methodGetValue.bind(this);
  }


  fileName = '';
  uploadProgress = 0;
  uploadSub: Subscription | null = null;

  constructor(private eidosApiService: EidosApiService,) {
  }

  methodInit(fileName:string){
    fileName = fileName;
    this.setInputValue(fileName);
  }
  methodReset(){
    this.setInputValue('');
  }
  methodGetValue():string {
    const fp = this.getInputValue().replace(/\\/g,'/').split('/');
    if(fp.length==0) return '';
    return fp[fp.length-1];
  }

  getInputValue() {
    if(!this.fileUpload) return '';
    this.fileName=this.fileUpload.nativeElement.value;
    return this.fileName;
  }
  setInputValue(value:string) {
    if(!this.fileUpload) return;
    this.fileName=value;
    if(this.fileName==='') this.fileUpload.nativeElement.value = this.fileName;
  }
  ngOnInit(): void {
  }

  uploadFile(files: File[]) {
    if (files.length) {
      this.files = files;

      if (!this.autoUpload) return

      if(this.callback!==undefined) {
        this.callback(this.files);
        return;
      }

      const options = {
        overwrite: this.overwrite ?? true,
        versionSuffix: this.versionSuffix ?? '.ver#',
        versionMinDigit: this.versionMinDigit ?? 3
      };
      this.uploadSub = this.eidosApiService.uploadFiles(this.files, options).subscribe(
        {
          next: (response) => {
            if (response.type == HttpEventType.UploadProgress) {
              this.uploadProgress = Math.round(100 * (response.loaded / (response.total ?? response.loaded * 2)));
            }
          },
          complete: () => this.resetUpload()
        }
      );
    }

  }

  onFileSelected(event: Event) {
    event.stopImmediatePropagation();
    const target = event.target as HTMLInputElement;
    if (target && target.files && target.files.length > 0) {
      this.uploadFile(Array.from(target.files));
    }
  }

  cancelUpload() {
    if (this.uploadSub) this.uploadSub.unsubscribe();
    this.resetUpload();
  }

  resetUpload() {
    this.uploadProgress = 0;
    this.uploadSub = null;
  }
}
