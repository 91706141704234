import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEidosFiltersRegistry } from '@common/models/eidos-filters.models';
import { EidosFilterComponent } from '../eidos-filter/eidos-filter.component';

@Component({
  selector: 'eidos-filter-number',
  templateUrl: './eidos-filter-number.component.html',
  styleUrls: ['./eidos-filter-number.component.scss']
})
export class EidosFilterNumberComponent extends EidosFilterComponent {
  /**
   * Current filter value (2-way binding)
   *
   * @type {IEidosFiltersRegistry["number"]["valueType"]}
   * @memberof EidosFilterNumberComponent
   */
  protected _value: IEidosFiltersRegistry["number"]["valueType"];
  @Input() public get value(): IEidosFiltersRegistry["number"]["valueType"] {
    return this._value;
  }
  public set value(val: IEidosFiltersRegistry["number"]["valueType"]) {
    this._value = val;
    this.valueChange.emit(this._value);
  }
  @Output() public valueChange: EventEmitter<IEidosFiltersRegistry["number"]["valueType"]> = new EventEmitter<IEidosFiltersRegistry["number"]["valueType"]>();
  /**
   * Filter configuration
   *
   * @type {IEidosFiltersRegistry["number"]["config"]}
   * @memberof EidosFilterNumberComponent
   */
  @Input() public config: IEidosFiltersRegistry["number"]["config"];

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
  public keyDown(event: any) {
    this.onKeyDown.emit(event);
  }
}
