import { EventEmitter, Injectable } from '@angular/core';
import { EidosConfigService } from '../config/eidos-config.service';
import { IEidosNotificationConfiguration } from '../config/environment.interface';
import { IEidosNotification } from '../models/eidos-notification.interface';
import { EidosNotification } from '../models/eidos-notification.model';

@Injectable({
  providedIn: 'root'
})
export class EidosNotificationService {
  /**
   * Service configuration
   *
   * @private
   * @type {IEidosNotificationConfiguration}
   * @memberof EidosNotificationService
   */
  private notificationConfiguration: IEidosNotificationConfiguration;
  /**
   * New notification event
   *
   * @memberof EidosNotificationService
   */
  public newNotification = new EventEmitter<IEidosNotification>();
  /**
   * Notification reading event
   *
   * @memberof EidosNotificationService
   */
  public readNotification = new EventEmitter<string>();
  /**
   * Received notifications
   *
   * @private
   * @type {Array<IEidosNotification>}
   * @memberof EidosNotificationService
   */
  private _notifications: Array<IEidosNotification> = [];
  public get notifications(): Array<IEidosNotification> { return this._notifications; }

  /**
   * Production flag (for test purposes)
   *
   * @private
   * @memberof EidosNotificationService
   */
  private production = false;

  constructor(
    private eidosConfigService: EidosConfigService
  ) {

    this.notificationConfiguration = this.eidosConfigService.DEFAULT_CONFIG.notificationConfiguration;

    this.eidosConfigService.currentConfig.subscribe(config => {

      this.notificationConfiguration = config.notificationConfiguration;
      this.production = !!config.production;

      if (this.notificationConfiguration.enabled) {

        this.readNotification.subscribe((id: string) => {
          this.setNotificationAsRead(id);
        });

        this.retrieveNotifications();

        setInterval(() => {
          this.retrieveNotifications();
        }, this.notificationConfiguration.pollingInterval * 1000);
      }
    })
  }

  /**
   * Retrieves notifications from API
   *
   * @private
   * @memberof EidosNotificationService
   */
  private retrieveNotifications(): void {
    // TODO: retrieve notifications from server
    let newNotifications: Array<IEidosNotification> = [];

    if (!this.production) {
      // Get a fake new notification every polling interval
      let fakeNotification = new EidosNotification();
      fakeNotification.text = "Content";
      fakeNotification.title = "Title";
      fakeNotification.sender = "admin";
      newNotifications.push(fakeNotification);
    }

    newNotifications.forEach(notification => {
      this._notifications.unshift(notification);
      this.newNotification.emit(notification);
    });
  }

  /**
   * Set notification as read calling API
   *
   * @param {string} id
   * @memberof EidosNotificationService
   */
  setNotificationAsRead(_: string) {
    // TODO: set notification as read on service
  }
}
