import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
   * App title
   *
   * @memberof AppComponent
   */
  public title = 'Eidos 4.0';

  constructor(private translocoService: TranslocoService) {
    const lang = navigator.language; 
    this.translocoService.setActiveLang(lang ?? 'en-US');
  }
}
