<button *ngIf="eidosObject" class="eidos-object-action"
  [ngStyle]="{'color':eidosObject.foregroundColor,'background-color':eidosObject.backgroundColor,'border-radius':eidosObject.borderRadius}"
  (click)="navigate()">

  <content class="eidos-object-action-content" *ngIf="eidosObject && !loading" [ngSwitch]="eidosObject.objectType">
    <ng-template [ngSwitchCase]="EidosObjectActionType.Mybiz">
      <eidos-icon class="eidos-action-icon" *ngIf="eidosObject.icon" [iconConfiguration]="eidosObject.icon" [tooltipPosition]="'right'" [tooltip]="eidosObject.tooltip ?? ''"></eidos-icon>
      <h2 class="eidos-action-label" *ngIf="item?.label || eidosObject.label" [style.justify-content]="eidosObject.icon ? 'flex-start' : 'center'">{{item?.label || eidosObject.label}}</h2>
    </ng-template>

    <ng-template [ngSwitchCase]="EidosObjectActionType.Extapp">
      <eidos-icon class="eidos-action-icon" *ngIf="app && app.loaded && eidosObject.icon" [iconConfiguration]="eidosObject.icon" [tooltipPosition]="'right'" [tooltip]="eidosObject.tooltip ?? ''"></eidos-icon>
      <mat-spinner *ngIf="app && !app.loaded" diameter="50" style="margin:0 auto;"></mat-spinner>
      <h2 class="eidos-action-label" *ngIf="app && app.label" [style.justify-content]="eidosObject.icon ? 'flex-start' : 'center'">{{app.label}}</h2>
    </ng-template>

    <ng-template [ngSwitchCase]="EidosObjectActionType.Eidos">
      <eidos-icon class="eidos-action-icon" *ngIf="eidosObject.icon" [iconConfiguration]="eidosObject.icon" [tooltipPosition]="'right'" [tooltip]="eidosObject.tooltip ?? ''"></eidos-icon>
      <h2 class="eidos-action-label" *ngIf="eidosObject.label" [style.justify-content]="eidosObject.icon ? 'flex-start' : 'center'">{{eidosObject.label}}</h2>
    </ng-template>

    <ng-template [ngSwitchCase]="EidosObjectActionType.Route">
      <eidos-icon class="eidos-action-icon" *ngIf="eidosObject.icon" [iconConfiguration]="eidosObject.icon" [tooltipPosition]="'right'" [tooltip]="eidosObject.tooltip ?? ''"></eidos-icon>
      <h2 class="eidos-action-label" *ngIf="eidosObject.label" [style.justify-content]="eidosObject.icon ? 'flex-start' : 'center'">{{eidosObject.label}}</h2>
    </ng-template>

    <ng-template [ngSwitchCase]="EidosObjectActionType.Home">
      <eidos-icon class="eidos-action-icon" *ngIf="eidosObject.icon" [iconConfiguration]="eidosObject.icon" [tooltipPosition]="'right'" [tooltip]="eidosObject.tooltip ?? ''"></eidos-icon>
      <h2 class="eidos-action-label" [style.justify-content]="eidosObject.icon ? 'flex-start' : 'center'">{{eidosObject.label || 'Home'}}</h2>
    </ng-template>

    <ng-template [ngSwitchDefault]>
      <p>Unknown action</p>
    </ng-template>
  </content>
</button>
