import { IEidosModuleAPIConfiguration } from '@app/core/config/environment.interface'
import { SafeBaseObject } from '@app/core/models/base-object.models'
import { DateTime } from 'luxon'
import { ReservationActionBooking } from './reservation-permission.model'

export interface IReservationApiGetSuppliers {
	SupplierID?: number
	SupplierName?: string
	AllSuppliers?: string
	ServiceTypeID?: number
	companyID_OVERWRITE?: number
	PageSize?: number
}

export class ResVilla extends SafeBaseObject {
	static INVALID_VILLAS_ID = 0

	villaID = ResVilla.INVALID_VILLAS_ID
	name = ''
	shortName = ''
	cityID = 0
	city = ''
	supplierID = 0
	supplierName = ''
	description = ''
	currencyCod = ''
	startDate = DateTime.invalid('empty')
	endDate = DateTime.invalid('empty')
	webSiteVisibilityFrom = DateTime.invalid('empty')
	webSiteVisibilityTo = DateTime.invalid('empty')
	isReservationOpen = false
	isWebSiteOpen = false

	internalType = 'villa'

	constructor(data?: any) {
		super()
		this.updateData(data)
	}

	override updateData(data: any): void {
		if (!data) return
		super.updateData(data)
	}

	get isValid(): boolean {
		return this.villaID !== ResVilla.INVALID_VILLAS_ID
	}
}
export class ResVillaDetails extends SafeBaseObject {
	villaDetails?: ResVillaSetupDetails
	villaDatePrices: ResVillaSetupDatePrices[] = []

	constructor(data?: any) {
		super()
		this.updateData(data)

		this.villaDetails = new ResVillaSetupDetails(data.DT0[0])
		data.DT1.forEach((villaDatePrice: any) => {
			const mappedVillaDatePrice = new ResVillaSetupDatePrices(villaDatePrice)
			this.villaDatePrices.push(mappedVillaDatePrice)
		})
	}
}

export class ResVillaSetupDetails extends SafeBaseObject {
	villaID = 0
	name = ''
	shortName = ''
	description = ''
	maxOccupancy = 0
	bedrooms = 0
	cityID = 0
	city = ''
	currencyCod = ''
	startDate = DateTime.invalid('empty')
	endDate = DateTime.invalid('empty')
	webSiteVisibilityFrom = DateTime.invalid('empty')
	webSiteVisibilityTo = DateTime.invalid('empty')
	supplierID = 0
	supplierName = ''

	constructor(data?: any) {
		super()
		this.updateData(data)
	}
}

export class ResVillaSetupDatePrices extends SafeBaseObject {
	villaPriceID = 0
	date = DateTime.invalid('empty')
	duration = 0
	sellingPrice = 0
	currency = ''
	priceTypeID = 0
	priceTypeName = ''
	startDate = DateTime.invalid('empty')
	endDate = DateTime.invalid('empty')
	isReservationOpen = false
	isWebSiteOpen = false

	constructor(data?: any) {
		super()
		this.updateData(data)
	}
}

export interface IReservationModuleAPIConfiguration extends IEidosModuleAPIConfiguration {
  /**
   * shared key for aquire key
   *
   * @type {string}
   * @memberof IReservationModuleAPIConfiguration
   */
  shared?: string;
  /**
   * Payment callback API URL
   *
   * @type {string}
   * @memberof IReservationModuleAPIConfiguration
   */
  paymentCallbackAPI?: string;
  /**
   * Send AMEX data to gateway payment
   *
   * @type {string}
   * @memberof IReservationModuleAPIConfiguration
   */
   useAmexData: boolean;
  /**
   * Payment API URL
   *
   * @type {string}
   * @memberof IReservationModuleAPIConfiguration
   */
  paymentAPI: string;
  paymentGateway: string;
  paymentGatewayCallback: string;
  usePaymentGateway: boolean;
  /**
   * Ticketing API URL
   *
   * @type {string}
   * @memberof IReservationModuleAPIConfiguration
   */
  ticketingUrl: string;
  /**
   * Frame ticketing path
   *
   * @type {string}
   * @memberof IReservationModuleAPIConfiguration
   */
  frameTicketingPath: string;
  /**
   * Send email logic app URL
   *
   * @type {string}
   * @memberof IReservationModuleAPIConfiguration
   */
  sendEmailUrl: string;
}

export interface IResVillaAvailabilityParams {
	VillaID: number
	Currency?: string
	PriceTypeID?: number
	DateFrom?: DateTime
	DateTo?: DateTime
	MonthList?: string
}

export class ResVillaAvailability extends SafeBaseObject {
	villaID!: number
	ship!: string
	paxNum!: number
	description!: string
	dateFrom = DateTime.local()
	dateTo = DateTime.local()
	duration!: number
	durationUDM!: string
	sellingPrice!: number
	currency!: string
	priceTypeID!: number
	priceTypeName!: string

	formattedDateFrom!: string
	formattedDateTo!: string
	formattedSellingPrice!: string

	constructor(data: Partial<ResVillaAvailability>) {
		super()
		this.updateData(data)
		this.formatDateTimeProperties()
	}

	get is7Days(): boolean {
		return this.duration === 7
	}

	get is14Days(): boolean {
		return this.duration === 14
	}
}
export interface IReservationApiDepartment {
  DepartmentID:number
  DepartmentName:string
}

export interface IReservationGeoTree {
  DestinationID: number;
  DestinationTypeName: string;
  DestinationShortName: string;
  DestinationName: string;
  CountryID: number;
  CountryCod: string;
  Country: string;
  CityID: number;
  CityCod: string;
  PortCod: string;
  City: string;
  Latitude: number;
  Longitude: number;
  GeoTreeID: number;
  GeoTreeName: string;
  CompanyID: number;
}

export interface IResBookingActionCommand {
  action: ReservationActionBooking;
  sectionID?: number;
  actionParams: any[];
}

export interface IResBookingAction {
  id: string;
  icon?: string;
  caption: string;
  disabled: boolean;
  order: number;
  tooltip: string;
}

export interface IReservationUploadBlobResponse {
  fileId?: number;
  fileGuid?: string;
  fileName?: string;
}

export class ReservationUploadBlobResponse extends SafeBaseObject {
  fileId = 0;
  fileGuid = '';
  fileName = '';

  constructor(data: IReservationUploadBlobResponse) {
    super()
    this.updateData(data);
  }
}

export enum ResServiceSetupComponentTab {
  Suppliers = 'suppliers',
  ServiceTypes = 'service types',
  Services = 'services',
  ServicesOptions = 'services options',
  Options = 'options',
  ServicesInclusive = 'inclusive options',
  // decommenta fra sequential discount
  SequentialDiscount = 'seq. discount',
}
