import { Component, OnInit } from '@angular/core';
import { DateTime } from "luxon";

import { EidosObjectFieldType } from '@common/models/eidos-object.model';
import { EidosFieldBaseComponent } from '@eidos/components/fields/eidos-field-base/eidos-field-base.component';

@Component({
  selector: 'eidos-field-datetime',
  templateUrl: './eidos-field-datetime.component.html',
  styleUrls: ['./eidos-field-datetime.component.scss']
})
export class EidosFieldDateTimeComponent extends EidosFieldBaseComponent implements OnInit {
  /**
   * Field value in datetime
   *
   * @type {DateTime}
   * @memberof EidosFieldDateTimeComponent
   */
  public _value?: DateTime;
  /**
   * Get formatted datetime value
   *
   * @readonly
   * @type {string}
   * @memberof EidosFieldDateTimeComponent
   */
  get value(): string {
    if (this._value?.isValid) {
      switch (this.fieldConfig?.eidosFieldType) {
        case EidosObjectFieldType.Date:
        case EidosObjectFieldType.DateTmz:
          return this._value.toFormat("dd/MM/yyyy");
        case EidosObjectFieldType.Time:
        case EidosObjectFieldType.TimeTmz:
          return this._value.toFormat("hh:mm");
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {

    // Value for test purposes
    this.fieldData.disabled = true;

    this._value = DateTime.fromISO(this.fieldData[this.fieldConfig!.name]);
  }
}