import { Component, OnInit } from '@angular/core';
import { IEidosIcon } from '@common/config/environment.interface';
import { EidosIconType } from '@common/models/core-constant.model';
import { IEidosNotification } from '@common/models/eidos-notification.interface';
import { EidosNotificationService } from '@common/services/eidos-notification.service';

@Component({
  selector: 'eidos-notifications-viewer',
  templateUrl: './eidos-notifications-viewer.component.html',
  styleUrls: ['./eidos-notifications-viewer.component.scss']
})
export class EidosNotificationsViewerComponent implements OnInit {
  /**
   * Notifications
   *
   * @type {Array<IEidosNotification>}
   * @memberof EidosNotificationsViewerComponent
   */
  public notifications: Array<IEidosNotification> = [];
  /**
   * Read notification icon configuration
   *
   * @type {IEidosIcon}
   * @memberof EidosNotificationsViewerComponent
   */
  public readIcon: IEidosIcon = {
    iconType: EidosIconType.Material,
    iconCode: "close",
    iconSize: 1
  }

  constructor(
    private eidosNotificationService: EidosNotificationService
  ) {
  }

  ngOnInit(): void {
    this.notifications = this.eidosNotificationService.notifications;
    this.eidosNotificationService.newNotification.subscribe((event: IEidosNotification) => {
      this.notifications.unshift(event);
    });
  }

  /**
   * Returns unread notifications
   *
   * @return {*}  {Array<IEidosNotification>}
   * @memberof EidosNotificationsViewerComponent
   */
  public getNotificationToDisplay = () => this.notifications.filter(notification => !notification.read);

  /**
   * Set a notification as read and emit event to update topbar badge
   *
   * @param {EidosNotification} notification
   * @memberof EidosMenuComponent
   */
  public readNotification(notification: IEidosNotification) {
    notification.read = true;
    this.eidosNotificationService.readNotification.emit(notification.id);
  }
}
