<content *ngIf="buttons.length > 0" class="eidos-button-list">
  <content *ngFor="let btn of buttons">
    <dx-button
      [text]="btn.BtnText"

      (onClick)="_onClick($event, btn)"
      [disabled]="btn.Enabled == undefined ? false : !btn.Enabled"
      [visible]="btn.Visible == undefined ? true : btn.Visible"

    >
    </dx-button>
  </content>
</content>

<!--
BtnIcon: null
BtnText: "New Touch
CmdBtnField: "B"
CmdMsg: null
CmdName: "touch-edit"
CmdType: "NEW"
GridCommandId: 4
GvName: "GV_Customer_Detail_TouchSearch"
ReloadGrid: true -->
