// Refer to https://app.quicktype.io/ to quickly generate these interfaces from JSON

import { IEidosIcon } from "@common/config/environment.interface";
import { CoreSearchResultsViewType, ICoreSearchConfiguration, ICoreSearchTabConfiguration, JSONToConfigurationParser, SearchElementsConfigurationParser } from "@common/models/eidos-search.model";
import { EidosBootstrapAlias } from "@common/models/core-constant.model";
import { ICoreFormCommandsSettings } from "./eidos-form.models";

export interface SearchTabsConfiguration {
  $schema: string;
  tabs: SearchTabConfiguration[];
}

interface SearchTabConfiguration {
  id: string;
  title: Label;
  isSidebarSearch?: boolean;
  commandsSettings?: ICoreFormCommandsSettings;
  isGenericTab?: boolean;
  icon: IEidosIcon | null;
  filtersConfig: FiltersConfig[];
  resultsConfig: ResultsConfig[];
  searchHandler: string;
  resultSpecificMap: GenericResultMapper;
  resultIdMap: GenericResultMapper;
  EntityType?: string;
  GVName?: string;
  GVLabel?: string;
  button: any;
}

interface FiltersConfig {
  name?: string;
  filterType: string;
  label: Label;
  placeholder?: Label;
  property: string;
  layoutClasses?: EidosBootstrapAlias[];
  cssClasses?: string[];
  minSearchLength?: number;
  otherProperty?: string;
  from?: DatePicker;
  to?: DatePicker;
  min?: number;
  max?: number;
  tooltip?: Tooltip;
  showOnMoreFilters?: boolean;
  showClearButton?: boolean;
  disabled?: boolean;
  hidden: boolean;
  elements?: NumberPopupElement[];
  layoutDirection?: 'horizontal' | 'vertical';
  selectizeItems?: Array<any>;
  maxItems?: number;
  displayField?: string;
  displayFieldTag?: string;
  searchField?: Array<string>;
  searchTextTooShortText?: string;
  loadingText?: string;
  noItemsFoundText?: string;
  valueField?: string;
  sortField?: string;
  api?: string,
  joinResult?: boolean,
  loadingMode?: 'server' | 'incremental',
  keyField?: string,
  formatDateToApi: boolean,
  defaultValue: any,
  CheckUnique: boolean,

  selectorDisplayField?: string,
  selectedDisplayedField?: string,
}

interface NumberPopupElement {
  property: string;
  label: Label;
  max: number;
  min: number;
  default: number;
}

interface DatePicker {
  layoutClasses: any[];
  disabledDates: DatePickerDisabledDate[];
  displayFormat: string;
  pickerType: 'calendar' | 'list' | 'native' | 'rollers';
  type: 'date' | 'datetime' | 'time';
  placeholder: Label;
}

interface DatePickerDisabledDate {
  operator: "<" | ">" | "<=" | ">=" | "<>" | "=";
  value: string;
}

interface Label {
  labelType: LabelTypeEnum;
  value: string;
}

enum LabelTypeEnum {
  Literal = "literal",
  MultiLanguage = "multilanguage",
}

interface Tooltip {
  enabled: boolean;
  format: TooltipFormat;
  position: string;
  showMode: string;
}

interface TooltipFormat {
  formatType: string;
  value?: string;
}

interface GenericResultMapper {
  mapType: string;
  value: string;
}

interface ResultsConfig {
  resultType: CoreSearchResultsViewType;
  icon: IEidosIcon | null;
  template?: string;
  gridConfig?: GridConfig;
  searchExpr?: string[];
  searchMode?: 'contains' | 'startswith' | 'equals';
}

interface GridConfig {
  itemsKey: string;
  columns: GridColumnConfig[];
  showFilterRow: boolean;
  showHeaderFilter?: boolean;
  showColumnChooser?: boolean;
  showColumnFixing?: boolean;
  showBorders?: boolean;
  rowAlternationEnabled?: boolean;
  searchPanel?: GridColumnSearchPanelConfig;
}

export interface GridColumnConfig {
  dataField: string;
  visible?: boolean;
  caption: Label;
  headerFilter?: GridColumnHeaderFilterConfig;
  dataType?: string;
  fieldType?: string;
  align?: string;
  format?: string;
  width?: string;
  command?: string;
  buttonConfig?: {
    text?: string;
    textFunction?: string;
    stylingMode?: string;
    tooltip?: string;
    icon?: string;
    hidden?: boolean;
  },
  disableFilter?:boolean
}

interface GridColumnHeaderFilterConfig {
  visible: boolean;
}

interface GridColumnSearchPanelConfig {
  width: number;
  placeholder: Label;
}

/**
 * Converts JSON strings to/from your types
 * and asserts the results of JSON.parse at runtime
 *
 * @export
 * @class SearchTabsConfigurationParser
 * @extends {JSONToConfigurationParser<SearchTabsConfiguration, ICoreSearchConfiguration>}
 */
export class SearchTabsConfigurationParser extends JSONToConfigurationParser<SearchTabsConfiguration, ICoreSearchConfiguration> {
  constructor() {
    super();
  }

  public mapConfigToModel(value: SearchTabsConfiguration): ICoreSearchConfiguration {
    return value.tabs.map(tab => {

      // Creates a function which maps the entity to its related tab
      // Takes tabId if no property is specified
      let resultSpecificMap: (result: any) => any;

      if (tab.resultSpecificMap?.mapType === "property") {
        resultSpecificMap = (result: any) => result?.[tab.resultSpecificMap.value];
      } else {
        resultSpecificMap = (result: any) => result?.["tabId"];
      }

      // Creates a function which maps the entity to its id
      // Takes id if no property is specified
      let resultIdMap: (result: any) => any;

      if (tab.resultIdMap?.mapType === "property") {
        resultIdMap = (result: any) => result?.[tab.resultIdMap.value];
      } else {
        resultIdMap = (result: any) => result?.["id"];
      }

      let tabConfig: ICoreSearchTabConfiguration = {
        identifier: tab.id,
        title: SearchElementsConfigurationParser.mapConfigToLabel(tab.title),
        icon: tab.icon?.iconCode, // TODO Change interface to get an icon
        isGenericTab: tab.isGenericTab,
        searchAPI: tab.searchHandler,

        button: tab.button,

        EntityType: tab.EntityType,
        GridViewName: tab.GVName,
        isSidebarSearch: tab.isSidebarSearch,
        commandsSettings: tab.commandsSettings,
        Label: tab.GVLabel,

        resultSpecificMap: resultSpecificMap,
        resultIdMap: resultIdMap,
        filtersConfig: tab.filtersConfig?.map(filterConfig => {

          let mappedFilterConfig: any = {
            name: filterConfig.name ?? filterConfig.property,
            type: filterConfig.filterType,
            hidden: () => !!filterConfig.hidden,
            label: SearchElementsConfigurationParser.mapConfigToLabel(filterConfig.label),
            property: filterConfig.property,
            otherProperty: filterConfig.otherProperty,
            disabled: filterConfig.disabled,
            showOnMoreFilters: filterConfig.showOnMoreFilters,
            layoutClasses: filterConfig.layoutClasses ?? ['col-12'],
            cssClasses: filterConfig.cssClasses,
            min: filterConfig.min,
            max: filterConfig.max,
            tooltip: filterConfig.tooltip ?? { enabled: false },
            placeholder: SearchElementsConfigurationParser.mapConfigToLabel(filterConfig.placeholder),
            layoutDirection: filterConfig.layoutDirection,
            minSearchLength: filterConfig.minSearchLength,
            showClearButton: filterConfig.showClearButton,
            maxItems: filterConfig.maxItems,
            searchTextTooShortText: filterConfig.searchTextTooShortText,
            loadingText: filterConfig.loadingText,
            noItemsFoundText: filterConfig.noItemsFoundText,
            searchField: filterConfig.searchField,
            displayField: filterConfig.displayField,
            displayFieldTag: filterConfig.displayFieldTag,
            valueField: filterConfig.valueField,
            sortField: filterConfig.sortField,
            items: filterConfig.selectizeItems,
            api: filterConfig.api,
            joinResult: filterConfig.joinResult,
            loadingMode: filterConfig.loadingMode,
            keyField: filterConfig.keyField,
            selectorDisplayField: filterConfig.selectorDisplayField,
            selectedDisplayedField: filterConfig.selectedDisplayedField,
            formatDateToApi: filterConfig.formatDateToApi,
            defaultValue: filterConfig.defaultValue,
            checkUnique: filterConfig.CheckUnique
          };

          if (filterConfig.elements) {
            mappedFilterConfig.elements = SearchElementsConfigurationParser.mapConfigToNumberPopupElements(filterConfig.elements);
          } else {
            mappedFilterConfig.elements = [];
          }

          if (filterConfig.from) {
            mappedFilterConfig.from = SearchElementsConfigurationParser.mapConfigToDatePicker(filterConfig.from);
          }

          if (filterConfig.to) {
            mappedFilterConfig.to = SearchElementsConfigurationParser.mapConfigToDatePicker(filterConfig.to);
          }

          return mappedFilterConfig;
        }),

        resultsConfig: tab.resultsConfig?.map(resultConfig => {
          let mappedResultConfig: any = {
            id: resultConfig.resultType,
            icon: resultConfig.icon?.iconCode ?? "test", // TODO Change interface to get an icon
            // customTemplate: resultConfig.template
            searchExpr: resultConfig.searchExpr,
            searchMode: resultConfig.searchMode,
          };

          if (resultConfig.gridConfig) {
            mappedResultConfig.config = SearchElementsConfigurationParser.mapConfigToGridConfig(resultConfig.gridConfig);
          }

          return mappedResultConfig;

        }),
      }
      return tabConfig;
    });
  }
}
